import type { IdentifiedColumnDef } from '@tanstack/react-table';

import { TextInput, NumberInput, type TextInputProps } from '@mantine/core';
import React from 'react';

import { useTableStore } from './store';

type TextInputCellOptions = { type: 'text' } | { type: 'number'; decimals?: number };

type ICell<TData, TValue> = IdentifiedColumnDef<TData, TValue>['cell'];

export const createTextInputCell: (options: TextInputCellOptions) => ICell<any, any> = (options) =>
	React.memo(function TextInputCell({ cell, column, row, table }) {
		const validationError = useTableStore((s) => s.validationErrors[row.id]);

		const setValidationError = useTableStore((s) => s.setValidationError);
		const setEditedRowValue = useTableStore((s) => s.setEditedRowValue);
		const rowValue = row.getValue(column.id);

		const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
			const currentValue = event.currentTarget.value;

			// TODO: also reset edited items if they are no longer edited

			if (rowValue === currentValue) return;

			// const validationError = !validateRequired(event.currentTarget.value) ? 'Required' : undefined;
			// setValidationError(row.id, validationError);

			setEditedRowValue(row.id, { [column.id]: currentValue });
		};

		const sharedProps = {
			defaultValue: cell.getValue() as string,
			error: validationError,
			name: cell.id,
			onBlur: handleBlur,
			required: false,
			styles: {
				input: {
					border: 'none',
				},
			},
			variant: 'unstyled',
		} as const satisfies TextInputProps;

		return options.type === 'text' ? (
			<TextInput {...sharedProps} />
		) : (
			<NumberInput
				{...sharedProps}
				decimalScale={2}
				styles={{
					input: {
						...sharedProps.styles.input,
						textAlign: 'right',
					},
				}}
			/>
		);
	});

// export const createSelectCell: (data: string[]) => MRT_ColumnDef<IRowType>['Edit'] = (data) =>
// 	function SelectCell({ cell, column, row, table }) {
// 		const setEditedRowValue = useProductsTableStore((s) => s.setEditedRowValue);
// 		const rowValue = row.getValue(column.id);

// 		const handleBlur = (currentValue: string | null) => {
// 			// TODO: also reset edited items if they are no longer edited

// 			if (rowValue === currentValue) return;
// 			setEditedRowValue(row.id, { [column.id]: currentValue });
// 		};

// 		return <Select data={data} defaultValue={cell.getValue() as string} onChange={handleBlur} variant="unstyled" />;
// 	};
