import React from 'react';
import { useDeepCompareMemo } from 'use-deep-compare';

interface IFrachterLocksContext {
	setLock: (id: string, requestorId: string) => void;
	unsetLock: (id: string, requestorId: string) => void;
}

export const FrachterLocksContext = React.createContext<IFrachterLocksContext>({
	setLock: () => {},
	unsetLock: () => {},
});

/**
 * Used to avoid that the same lock is being requested multiple times.
 *
 * Being used by our `useRequestLock` hook.
 */
export const FrachterLocksContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
	/**
	 * This state is used to track the locks that are currently held by this organization.
	 * We need it to avoid that two hooks request the same lock at the same time.
	 *
	 * key: lock id, value: React.useId()
	 */
	// const [locks, setLocks] = React.useState<Record<string, string>>({});

	const locksRef = React.useRef<Record<string, string>>({});

	const setLock = React.useCallback((id: string, requestorId: string) => {
		if (locksRef.current[id] && locksRef.current[id] !== requestorId) {
			throw new Error('Lock already requested');
		}

		locksRef.current[id] = requestorId;
		return locksRef.current;
	}, []);

	const unsetLock = React.useCallback((id: string, requestorId: string) => {
		if (!locksRef.current[id]) return;

		if (locksRef.current[id] !== requestorId) {
			throw new Error('Lock race condition');
		}

		delete locksRef.current[id];
	}, []);

	const contextValue: IFrachterLocksContext = useDeepCompareMemo(
		() => ({
			setLock,
			unsetLock,
		}),
		[setLock, unsetLock],
	);

	return <FrachterLocksContext.Provider value={contextValue}>{children}</FrachterLocksContext.Provider>;
};
