import type { CellContext } from '@tanstack/table-core';

import { ActionIcon, Box, Group, Popover, Text } from '@mantine/core';
import { IconCircleArrowRightFilled as IconCircleArrowRight, IconLockFilled as IconLock } from '@tabler/icons-react';
import { useNavigate, useParams, useRouter } from '@tanstack/react-router';
import React from 'react';

import type { IRowType } from '../types';

export const ActionColumn = (props: CellContext<IRowType, unknown>) => {
	const hasItems = props.row.original.count > 0;

	const lockedBy = null; // { firstname: 'Bastian', lastname: 'Kistner' };

	const navigate = useNavigate();

	const routeParams = useParams({ strict: false });

	const navigateToDetails = React.useCallback(() => {
		void navigate({
			params: {
				...(routeParams as any),
				key: props.row.original.id,
			},
			to: '/org/$slug/quickship/$marketId/$merchantId/$key',
		});
	}, [routeParams, props.row.original.id, navigate]);

	return (
		<fieldset disabled={!hasItems}>
			<Group align="center" justify="center">
				{lockedBy && (
					<Popover position="left" shadow="md" width={200} withArrow>
						<Popover.Target>
							<Box
								style={{
									display: 'grid',
									height: 'var(--mantine-spacing-md)',
									placeContent: 'center',
									width: 'auto',
								}}
							>
								<IconLock />
							</Box>
						</Popover.Target>
						<Popover.Dropdown>
							<Text size="xs">This is uncontrolled popover, it is opened when button is clicked</Text>
						</Popover.Dropdown>
					</Popover>
				)}
				<ActionIcon disabled={!!lockedBy} onClick={navigateToDetails} size="lg" variant="transparent">
					{/* TODO: show LOCK info here in case the bundle (or one of its orders) is locked ! */}
					<IconCircleArrowRight />
				</ActionIcon>
			</Group>
		</fieldset>
	);
};
