'use client';

import { ActionIcon, Box, MantineColor, rem, useMantineColorScheme } from '@mantine/core';
import { IconMoon as IconDark, IconSun as IconLight } from '@tabler/icons-react';
import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { match } from 'ts-pattern';

import { constants } from '@/data/constants';

export function ColorSchemeToggle({
	color = 'text',
	size = rem(20),
	stroke = 1.5,
}: {
	color?: MantineColor;
	size?: string | number;
	stroke?: number;
}) {
	const { colorScheme, setColorScheme } = useMantineColorScheme({
		keepTransitions: true,
	});

	useEffect(() => {
		Cookies.set(constants.COOKIE_NAME_COLOR_SCHEME, colorScheme, { expires: 365 });
	}, [colorScheme]);

	return (
		<Box>
			{match(colorScheme)
				.with('light', () => (
					<ActionIcon
						color={color}
						onClick={() => setColorScheme('dark')}
						style={{ display: 'grid', placeContent: 'center' }}
						variant="subtle"
					>
						<IconLight stroke={stroke} style={{ height: size, width: size }} />
					</ActionIcon>
				))
				.with('dark', () => (
					<ActionIcon
						color={color}
						onClick={() => setColorScheme('light')}
						style={{ display: 'grid', placeContent: 'center' }}
						variant="subtle"
					>
						<IconDark stroke={stroke} style={{ height: size, width: size }} />
					</ActionIcon>
				))
				.otherwise(() => null)}
		</Box>
	);
}
