import { Effect } from 'effect';

import { ClientIdError, LockError } from './errors';
import { createLockId } from './program-create-lock-id';

export const getClientId = Effect.gen(function* () {
	const randomId = crypto.randomUUID();

	const lock = yield* createLockId(randomId, { mode: 'shared' });

	if (lock.status === 'unavailable') {
		yield* Effect.dieMessage('client id could not be retrieved');
	}

	const clientId = yield* Effect.tryPromise({
		catch: (error) =>
			error instanceof DOMException ? new LockError({ name: error.name as any }) : new ClientIdError({ cause: error }),
		try: () =>
			navigator.locks.query().then((v) => {
				const ownLock = (v.held ?? []).find((l) => l.name === randomId);

				if (!ownLock?.clientId) {
					throw new Error('client id not found');
				}

				return ownLock.clientId;
			}),
	});

	return clientId;
});
