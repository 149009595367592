import {
	ActionIcon,
	Box,
	Button,
	Divider,
	Group,
	HoverCard,
	Loader,
	Stack,
	Text,
	useMantineTheme,
} from '@mantine/core';
import { type messages } from '@packages/lib/schema/realtime';
import {
	IconExclamationCircle as IconError,
	IconFileSearch as IconPreview,
	IconPrinter,
	IconCircleCheckFilled as IconSuccess,
} from '@tabler/icons-react';
import { differenceInCalendarDays } from 'date-fns';
import { InferSelectModel } from 'drizzle-orm';
import React from 'react';
import { match } from 'ts-pattern';

import type { dbs } from '@/drizzle/pglite';

import { useMutationPrint } from '@/hooks/use-mutation-print';

type TransactionState = 'success' | 'error' | 'pending';

const StatusInfo: React.FC<React.PropsWithChildren<{ status: TransactionState }>> = ({ children, status }) => {
	return (
		<Box
			style={{ alignItems: 'center', display: 'grid' }}
			c={match(status)
				.with('success', () => 'success')
				.with('error', () => 'danger')
				.otherwise(() => 'success')}
		>
			{children}
		</Box>
	);
};

export type CloudprintQueueItemData = messages.PrintJobRequestDataSchema['jobs'][number] &
	Partial<Pick<InferSelectModel<typeof dbs.printjobs>, 'status' | 'createdAt' | 'hostname' | 'printerName'>> & {
		transactionState: 'success' | 'error' | 'pending';
	};

export interface CloudprintQueueItemProps {
	data: CloudprintQueueItemData;
	disableReprint?: boolean;
	hasPrinter: boolean;
	isPreviewOpen: boolean;
	onPreviewClick: (data: CloudprintQueueItemData) => void;
}

const FIRST_COLUMN_WIDTH = `30px`;

export const CloudprintQueueItem: React.FC<CloudprintQueueItemProps> = (props) => {
	const theme = useMantineTheme();

	const { buttonProps } = useMutationPrint({
		eid: props.data.eid,
		eid2: props.data.eid2,
		id: props.data.id + '-r',
		pagesToPrint: props.data.pagesToPrint,
		url: props.data.url,
	});

	const data: messages.PrintJobRequestDataSchema['jobs'][number] &
		Partial<Pick<InferSelectModel<typeof dbs.printjobs>, 'status' | 'createdAt' | 'hostname' | 'printerName'>> & {
			transactionState: TransactionState;
		} = {
		...props.data,
		transactionState: 'status' in props.data ? (props.data.status === 'OK' ? 'success' : 'error') : 'pending',
	};

	// if ('status' in props.data) {
	// 	data = {
	// 		...props.data,
	// 	};
	// } else {
	// 	data = {
	// 		...props.data,
	// 		url: props.data.url,
	// 	};
	// }

	const daysAgoText = React.useMemo(() => {
		if (!data.createdAt) return null;

		const date = new Date(data.createdAt);
		const today = new Date();

		const daysAgo = differenceInCalendarDays(today, date);

		return match(daysAgo)
			.with(0, () => 'Heute')
			.with(1, () => 'Gestern')
			.otherwise(() => `vor ${daysAgo} Tagen`);
	}, [data.createdAt]);

	const isPendingItem = data.transactionState === 'pending';

	const statusIndicator = match(data?.transactionState)
		.with('success', () => (
			<IconSuccess color={theme.colors.ok[5]} size={18} style={{ marginLeft: -1, marginTop: -1 }} />
		))
		.with('error', () => (
			<HoverCard position="left" shadow="md">
				<HoverCard.Target>
					<ActionIcon
						color="danger"
						size="sm"
						variant="transparent"
						style={{
							marginLeft: -3,
							marginTop: 0,
							pointerEvents: isPendingItem ? 'none' : 'all',
							visibility: isPendingItem ? 'hidden' : 'visible',
						}}
					>
						<IconError size={18} />
					</ActionIcon>
				</HoverCard.Target>
				<HoverCard.Dropdown>
					<Group gap="xs">
						<Text size="sm">Fehler:</Text>
						<Text c="dimmed" size="sm">
							{match(data.status)
								.with('ERROR_TIMEOUT', () => `Zeitüberschreitung bei der Verbindung.`)
								.with('ERROR_NO_PRINTER', () => `Es war kein Drucker ausgewählt.`)
								.with('ERROR_ABORTED', () => `Druckauftrag wurde abgebrochen.`)
								.with('ERROR_INVALID_CONTENT_TYPE', () => `Ungültiges Dateiformat.`)
								.with('ERROR_NOT_FOUND', () => `Dokument nicht gefunden.`)
								.with('ERROR_INVALID_RESPONSE', () => `Ungültige Antwort bei der Abfrage des Dokuments.`)
								// .with('ERROR_SYSTEM', () => `Ein Systemfehler ist aufgetreten.`)
								.otherwise(() => null)}
						</Text>
					</Group>
				</HoverCard.Dropdown>
			</HoverCard>
		))
		.with('pending', () => <Loader size={18} />)
		.otherwise(() => null);

	const cloudprintDeviceIndicator = (
		<HoverCard position="left" shadow="md">
			<HoverCard.Target>
				<ActionIcon
					color="color-mix(in srgb, var(--mantine-color-text) 40%, transparent)"
					size="sm"
					variant="transparent"
					style={{
						marginLeft: -3,
						marginTop: 1,
						pointerEvents: isPendingItem ? 'none' : 'all',
						visibility: isPendingItem ? 'hidden' : 'visible',
					}}
				>
					<IconPrinter size={16} />
				</ActionIcon>
			</HoverCard.Target>
			<HoverCard.Dropdown>
				<Group gap="xs">
					<Text size="sm">{data.hostname}</Text>
					<Text c="dimmed" size="sm">
						{data.printerName}
					</Text>
				</Group>
			</HoverCard.Dropdown>
		</HoverCard>
	);

	return (
		<Box
			p="md"
			pl="calc(var(--mantine-spacing-md) - 5px)"
			pr="xl"
			style={{
				borderLeft: props.isPreviewOpen ? '5px solid var(--mantine-color-brand-5)' : '5px solid transparent',
				transition: 'border-color 0.3s ease-in-out',
			}}
		>
			<Stack gap="xs">
				<Box
					style={{
						alignItems: 'center',
						display: 'grid',
						gap: '0.5rem',
						gridTemplateColumns: `${FIRST_COLUMN_WIDTH} auto 1fr`,
					}}
				>
					{statusIndicator && <StatusInfo status={data.transactionState}>{statusIndicator}</StatusInfo>}

					<Group gap="xs">
						{data.createdAt ? (
							<>
								<Text ff="monospace" fw={600} size="sm">
									{data.createdAt.toLocaleString()}
								</Text>
								<Text c="dimmed" ff="monospace" fw={500} size="sm">
									({daysAgoText})
								</Text>
							</>
						) : (
							<Text ff="monospace" fw={600} size="sm">
								Wird an Drucker gesendet...
							</Text>
						)}
					</Group>

					<Group style={{ justifySelf: 'end' }}>
						{props.disableReprint ? null : (
							<Button
								{...buttonProps}
								disabled={Boolean(!props.hasPrinter || buttonProps.disabled)}
								size="xs"
								variant="subtle"
							>
								Drucken
							</Button>
						)}
						<ActionIcon
							color={props.isPreviewOpen ? 'brand' : 'sky'}
							onClick={() => props.onPreviewClick(data)}
							size="md"
							variant="subtle"
						>
							<IconPreview size={16} />
						</ActionIcon>
					</Group>
				</Box>
				<Box
					style={{
						alignItems: 'center',
						display: 'grid',
						gap: '0.5rem',
						gridTemplateColumns: `${FIRST_COLUMN_WIDTH} 1fr`,
					}}
				>
					{cloudprintDeviceIndicator}
					<Group justify="start">
						<Text ff="monospace" fw="400" miw={120} size="sm">
							{data.eid}
						</Text>
						{data.eid2 && (
							<>
								<Divider orientation="vertical" />
								<Text ff="monospace" fw="400" size="sm">
									{data.eid2}
								</Text>
							</>
						)}
					</Group>
				</Box>
			</Stack>
		</Box>
	);
};
