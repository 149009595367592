import { Box, Skeleton, Text } from '@mantine/core';
import React from 'react';

import { ConnectionEstablishedListItem } from './ConnectionEstablishedListItem';

export type { ConnectionEstablishedListItemProps } from './ConnectionEstablishedListItem';

interface ConnectionEstablishedListProps {
	children: React.ReactNode;
	loading?: boolean;
	subject: 'market' | 'carrier';
}

export function ConnectionEstablishedList({ children, loading, subject }: ConnectionEstablishedListProps) {
	if (loading) {
		return <ConnectionEstablishedList.Fallback />;
	}

	if (React.Children.count(children) === 0) {
		return <ConnectionEstablishedListItemEmpty subject={subject} />;
	}

	return <Box style={{ display: 'grid', gap: '0.5rem', gridAutoFlow: 'row' }}>{children}</Box>;
}

function ConnectionEstablishedListItemEmpty({ subject }: Pick<ConnectionEstablishedListProps, 'subject'>) {
	return (
		<Box
			p="lg"
			style={{
				backgroundColor: 'color-mix(in srgb, var(--mantine-color-text) 5%, transparent)',
				borderRadius: 6,
				textAlign: 'center',
			}}
		>
			<Text c="dimmed" size="sm">
				Du hast noch keine {subject === 'market' ? 'Marktplätze' : 'Versanddienstleister'} mit Frachter verbunden
			</Text>
		</Box>
	);
}

ConnectionEstablishedList.Item = ConnectionEstablishedListItem;

ConnectionEstablishedList.Fallback = function Fallback() {
	return Array.from({ length: 3 }).map((_, index) => <Skeleton key={index} height={102} radius="md" />);
};
