import { organizationAddressSchema, OrganizationAddressSchema } from '@packages/lib/schema/organization';
import { z } from 'zod';

export const organizationSettingsFormSchema = z.object({
	companyAddress: organizationAddressSchema.nullable(),
	hasReturnAddress: z.boolean().optional(),
	hasShippingAddress: z.boolean().optional(),
	returnAddress: organizationAddressSchema.nullable(),
	shippingAddress: organizationAddressSchema.nullable(),
});

export type OrganizationSettingsFormSchema = z.infer<typeof organizationSettingsFormSchema>;

export const createAddressDefaultValues = (data?: Partial<OrganizationAddressSchema> | null) => ({
	city: data?.city ?? '',
	company: data?.company ?? '',
	countryCode: data?.countryCode ?? 'DE',
	email: data?.email ?? '',
	firstName: data?.firstName ?? '',
	gender: data?.gender ?? 'unknown',
	house: data?.house ?? '',
	lastName: data?.lastName ?? '',
	phone: data?.phone ?? '',
	street: data?.street ?? '',
	zip: data?.zip ?? '',
});

export const createOrganizationSettingsFormDefaultValues = (
	values: OrganizationSettingsFormSchema,
): OrganizationSettingsFormSchema => {
	const createAddressDefaultValues = (data: OrganizationAddressSchema | null) => ({
		city: data?.city ?? '',
		company: data?.company ?? '',
		countryCode: data?.countryCode ?? 'DE',
		email: data?.email ?? '',
		firstName: data?.firstName ?? '',
		gender: data?.gender ?? 'unknown',
		house: data?.house ?? '',
		lastName: data?.lastName ?? '',
		phone: data?.phone ?? '',
		street: data?.street ?? '',
		zip: data?.zip ?? '',
	});

	return {
		companyAddress: createAddressDefaultValues(values.companyAddress),
		hasReturnAddress: values.hasReturnAddress ?? false,
		hasShippingAddress: values.hasShippingAddress ?? false,
		returnAddress: createAddressDefaultValues(values.returnAddress),
		shippingAddress: createAddressDefaultValues(values.shippingAddress),
	};
};

export const organizationSettingsMetaFormSchema = z.object({
	hasReturnAddress: z.boolean().default(false),
	hasShippingAddress: z.boolean().default(false),
});

export type OrganizationSettingsMetaFormSchema = z.infer<typeof organizationSettingsMetaFormSchema>;
