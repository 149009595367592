export function getAuthUrl(includeOrigin: boolean = false) {
	const url = new URL('/auth/sign-in', window.location.origin);
	url.searchParams.set('next', window.location.href);

	if (includeOrigin) {
		return url.toString();
	}

	return url.toString().replace(window.location.origin, '');
}
