import { produce, enableMapSet } from 'immer';
import { throttle } from 'lodash-es';
enableMapSet();
export function createExternalStore(initial, throttleMs = 0) {
    const subscribers = new Set();
    const dispatch = throttle(() => {
        subscribers.forEach((callback) => callback());
    }, throttleMs, // set 0 to ensure we batch all updates in the current frame
    { leading: false, trailing: true });
    let store = initial;
    return {
        dispatch,
        getSnapshot: () => store,
        subscribe: (callback) => {
            subscribers.add(callback);
            return () => subscribers.delete(callback);
        },
        subscribers,
        update: (producerOrNextStore) => {
            let next;
            if (typeof producerOrNextStore === 'function') {
                const up = producerOrNextStore;
                next = produce(store, up);
            }
            else {
                next = produce(store, () => producerOrNextStore);
            }
            if (!Object.is(store, next)) {
                store = next;
                dispatch();
            }
            return store;
        },
    };
}
