import Spaces, { Lock, SpaceMember, type Space } from '@ably/spaces';
import ably, { ErrorInfo, InboundMessage } from 'ably';
import isEqual from 'react-fast-compare';
import { match } from 'ts-pattern';

import { createExternalStore } from '@/utils/createExternalStore';

import { IMessage, IStore, LockAcquisitionHandler, LockLostHandler } from './types';

const ablyApiKey = `oKsmig.FhOfIA:vfFNXmodqXQRntoBlvn4GlTA7V5ZZWUGK314X8wFQAY`;

// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace RealtimeClient {
	export interface Message {
		/**
		 * this should be a message directly published to a client channel that holds a lock
		 */
		ForceAcquireLock: {
			name: 'force_acquire_lock';
			data: { id: string };
		};

		/**
		 * those should be messages for the OrganizationSpace
		 */
		Hello: {
			data: { friend: string };
			name: 'hello';
		};
	}
}

declare global {
	interface Window {
		__ably: {
			clients: RealtimeClient[];
			spaces: Space[];
		};
		__getStoreSnapshop: () => IStore;
	}
}

window.__ably ??= {
	clients: [],
	spaces: [],
};

export class RealtimeClient {
	client!: ably.Realtime;

	spaces!: Spaces;

	defaultSpace?: Space;

	private lockLostHandlers: Map<string, Set<any /* LockLostHandler */>> = new Map();

	readonly pendingLockAcquisitions: Map<string, LockAcquisitionHandler[]> = new Map();
	readonly pendingLockReleases: Set<string> = new Set();

	/**
	 * our store that we can subscribe to with `useExternalStore`
	 * and use a specific selector with a particular attribute of the `getSnapshot()` function
	 */
	store = createExternalStore<IStore>({
		connectionState: 'initialized',
		isSpaceReady: false,
		locks: {
			all: [],
			others: [],
			self: [],
		},
		members: {
			all: [],
			others: [],
			self: null,
		},
		// TODO: we need an `isConnected` attribute
		// TODO: we need an error attribute (e.g. for an unrecoverable connection)
	});

	static #instance: RealtimeClient;

	static getInstance() {
		if (RealtimeClient.#instance) {
			console.log('instance exists .... return 🟢');
			return RealtimeClient.#instance;
		}

		RealtimeClient.#instance = new RealtimeClient();
		return RealtimeClient.#instance;
	}

	constructor() {
		if (RealtimeClient.#instance) {
			console.log('instance exists .... return 🟢');
			return RealtimeClient.#instance;
		}

		RealtimeClient.#instance = this;

		// window.__getStoreSnapshot = this.store.getSnapshot;
		window.__ably.clients.push(this);

		let clientId = sessionStorage.getItem('clientId');
		if (!clientId) {
			clientId = crypto.randomUUID();
			sessionStorage.setItem('clientId', clientId);
		}

		this.client = new ably.Realtime({
			clientId,
			disconnectedRetryTimeout: 1000,
			echoMessages: false,
			key: ablyApiKey,
			logLevel: 0,
			recover: (details, callback) => {
				// console.log('recovery details:', details);
				callback(true);
			},
			transportParams: {
				heartbeatInterval: 5000,
				remainPresentFor: 10_000,
			},
		});
		this.client.connection.on(this.handleConnectionStateChange.bind(this));
		this.spaces = new Spaces(this.client);

		void this.initDefaultSpace();
	}

	async initDefaultSpace() {
		this.defaultSpace = await this.spaces.get('org:default', { offlineTimeout: 10_000 });

		await this.defaultSpace.enter();

		window.__ably.spaces.push(this.defaultSpace);

		// HINT we could use this to track `pending` locks (and put them in a map with expiring keys)
		// this.defaultSpace.locks.subscribe('update', (lock) => void this.handleLockEvent(lock));

		await this.defaultSpace.channel.subscribe(this.handleMessage.bind(this));

		this.defaultSpace.locks.subscribe((lock) => {
			console.log('LOCK EVENT', lock);
			if (lock.reason?.code === 101004) {
				console.log(
					'lock was invalidated because someone else acquired it at the same time',
					lock.member.clientId,
					lock.member.connectionId,
				);
			} else if (lock.reason?.code === 101003) {
				console.log('lock is taken by someone else', lock.member.clientId, lock.member.connectionId);
			}
			console.log('lock updated', lock);

			// we don't cancel the idle callback request because this could lead to waiting for an update
			// several seconds in case they fire frequently. And it doesn't hurt if we update more often!
			// if (idleCallback) {
			// 	cancelIdleCallback(idleCallback);
			// }

			// requestIdleCallback(this.updateState.bind(this), { timeout: 250 });

			// queueMicrotask will ensure that the update happens in the current loop BUT at the very end
			// which in this case works well with the issue that ably spaces seems to handle the event before
			// the actual locking state was updated
			queueMicrotask(this.updateState.bind(this));

			// setTimeout(() => this.updateState(), 1);
			// this.updateState();
		});

		this.defaultSpace.members.subscribe(() => {
			// we will also use queueMicrotask here to ensure that the update happens in the current loop but AFTER
			// any other things ably might do
			queueMicrotask(this.updateState.bind(this));
		});

		// this.defaultSpace.locks.on('')

		this.store.update((s) => {
			s.isSpaceReady = true;
		});

		// const update = () => {
		// 	requestIdleCallback(
		// 		() => {
		// 			if (this.client.connection.state === 'connected' && this.store.getSnapshot().isSpaceReady) {
		// 				this.updateState();
		// 			}
		// 			update();
		// 		},
		// 		{ timeout: 1000 },
		// 	);
		// };

		// update();
	}

	async sendMessage(message: RealtimeClient.Message['Hello']) {
		if (!this.defaultSpace) {
			console.error('No default space');
			return;
		}

		await this.defaultSpace.channel.publish(message.name, message.data);

		// this.sendMessage({topic: 'hello', payload: {data: 'world'}})
	}

	handleMessage(message: InboundMessage) {
		match(message as IMessage)
			.with({ name: 'hello' }, (m) => {})
			.otherwise((m) => {
				console.log('unhandled message', m);
			});
	}

	private async ensureEntered(space: Space) {
		const self = await space.members.getSelf();

		if (!self) {
			await space.enter();
		}
	}

	async lockItem(id: string, onDone?: LockAcquisitionHandler) {
		if (!this.defaultSpace) {
			console.error(`Cannot lock item ${id}, no default space`);
			return null;
		}

		const handlers = this.pendingLockAcquisitions.get(id) ?? [];

		// if a lock is currently pending, we don't do anything (we should not allow to re-acquire a lock that is already owned!)
		// if (this.pendingLockAcquisitions.has(id)) {
		// 	if (onDone) {
		// 		handlers.push(onDone);
		// 	}
		// 	return;
		// }

		if (onDone) {
			handlers.push(onDone);
		}

		this.pendingLockAcquisitions.set(id, handlers);

		// this.pendingLockAcquisitions.add(id);
		await this.client.connection.whenState('connected');

		await this.ensureEntered(this.defaultSpace);

		console.log('acquire lock...');
		// here we would need to create the pending lock entry

		const lockRequest = await this.defaultSpace.locks.acquire(id).catch((err) => {
			console.log('acquire lock catch!');
			// @ts-expect-error okok
			window.ablyerr = err;

			if (err instanceof ErrorInfo) {
				console.log('is ably error', err);
				switch (err.code) {
					case 101002:
						console.log(
							'🟢 lock is already aquired. It should not be possible to re-acquire a lock that is already owned! Fix you UI state!',
						);
				}
			} else {
				console.error('Could not acquire lock on item', id, err);
			}

			return null;
		});

		/** ----------------------------------------------------------------------------------------------
		 * 3 options when we request a lock:
		 *
		 * 1. this client already holds the lock => the catch clause will have run
		 * 2. another client holds the lock => the resulting request will be pending and before we reach here
		 *    the `updateState` handler will usuallly already have reported that the lock is taken
		 *    by someone else.
		 * 3. no client holds the lock yet => the resulting request will be pending
		 * _______________________________________________________________________________________________ */

		console.log('acquire lock done!', lockRequest);

		return lockRequest;
	}

	async unlockItem(id: string) {
		console.log('trying to unlock item', id);

		if (!this.defaultSpace) {
			console.error(`Cannot unlock item ${id}, no default space`);
			return null;
		}

		await this.client.connection.whenState('connected');

		await this.ensureEntered(this.defaultSpace);

		/** ----------------------------------------------------------------------------------------------
		 * this will also run successfully if we DO NOT currently hold the lock!
		 * _______________________________________________________________________________________________ */
		const lock = await this.defaultSpace.locks.release(id).catch((err) => {
			console.error('Could not release lock on item', id, err);
			return null;
		});

		console.log('unlock item done!', lock);

		return lock;
	}

	private handleConnectionStateChange(connectionStateChange: ably.ConnectionStateChange) {
		this.store.update((s) => {
			s.connectionState = connectionStateChange.current;
		});

		if (connectionStateChange.previous !== 'disconnected' && connectionStateChange.current === 'connected') {
			console.log('update state after disconnect');
			this.updateState();
		}
	}

	public onLockLost(callback: LockLostHandler, id: string = '*') {
		this.lockLostHandlers.set(id, new Set([...(this.lockLostHandlers.get(id) ?? []), callback]));
	}

	private updateState(event?: unknown) {
		if (event) {
			console.debug('updateState', event);
		}

		if (!this.defaultSpace) {
			return;
		}

		// console.log('update');

		void Promise.all([
			Promise.all([
				this.defaultSpace.members.getAll(),
				this.defaultSpace.members.getOthers(),
				this.defaultSpace.members.getSelf(),
			]),
			Promise.all([
				this.defaultSpace.locks.getAll(),
				this.defaultSpace.locks.getOthers(),
				this.defaultSpace.locks.getSelf(),
			]),
		]).then(([members, locks]) => {
			this.store.update((s) => {
				const previousSelfLocks = s.locks.self;
				// TODO: we need to keep track of pending locks and those that are confirmed (locked)
				// and on each update we need to check if a lock goes from pending into confirmed (locked) or maybe we have on that was locked but is now lost (due to connection issues)

				// remove pending locks

				for (const [id, handlers] of this.pendingLockAcquisitions.entries()) {
					const match = locks[0].find((lock) => lock.id === id);

					console.log('match', match);
					console.log('client', this.client.auth.clientId, this.client.connection.id);

					if (match) {
						if (
							match.member.clientId === this.client.auth.clientId &&
							match.member.connectionId === this.client.connection.id
						) {
							for (const handler of handlers) {
								handler('locked');
							}
						} else {
							for (const handler of handlers) {
								handler('unavailable');
							}
						}
						this.pendingLockAcquisitions.delete(id);
					}
				}

				const newLocks = {
					all: locks[0] ?? [],
					others: locks[1] ?? [],
					self: locks[2] ?? [],
				};

				if (!isEqual(newLocks, s.locks)) {
					s.locks = newLocks;
				}

				const newMembers = {
					all: members[0] ?? [],
					others: members[1] ?? [],
					self: members[2] ?? null,
				};

				// HINT: this triggers a rate limit issue
				// if (!newMembers.self) {
				// 	void this.defaultSpace?.enter();
				// }

				if (!isEqual(newMembers, s.members)) {
					s.members = newMembers;
				}
			});
		});
	}
}
