import { z } from 'zod';
import { dhlRetoureSchema, endorsementSchema, identCheckSchema, valueSchema, visualCheckOfAgeSchema } from './common';
export const labelFormatEnumSchema = z.enum([
    // 100x70 is only allowed for warenpost and warenpost international
    // 'Format_100_70mm',
    'Format_910_300_300',
    'Format_910_300_300_oz',
    'Format_910_300_400',
    'Format_910_300_410',
    'Format_910_300_600',
    'Format_910_300_610',
    'Format_910_300_700',
    'Format_910_300_700_oz',
    'Format_910_300_710',
    'Format_A4',
]);
export const labelFormatWarenpostEnumSchema = z.enum([...labelFormatEnumSchema.options, 'Format_100_70mm']);
export const productEnumSchema = z.enum([
    'Paket',
    'PaketInternational',
    'Europaket',
    'Warenpost',
    'WarenpostInternational',
    'Retoure',
]);
export const valueAddedServicesSchema = z.object({
    additionalInsurance: valueSchema.optional(),
    bulkyGoods: z.boolean().optional(),
    cashOnDelivery: valueSchema.optional(),
    closestDropPoint: z.boolean().optional(),
    dhlRetoure: dhlRetoureSchema.optional(),
    endorsement: endorsementSchema.optional(),
    identCheck: identCheckSchema.optional(),
    individualSenderRequirement: z.string().optional(),
    namedPersonOnly: z.boolean().optional(),
    noNeighbourDelivery: z.boolean().optional(),
    parcelOutletRouting: z.string().optional(),
    postalDeliveryDutyPaid: z.boolean().optional(),
    preferredDay: z.date().optional(),
    preferredLocation: z.string().optional(),
    preferredNeighbour: z.string().optional(),
    premium: z.boolean().optional(),
    signedForByRecipient: z.boolean().optional(),
    visualCheckOfAge: visualCheckOfAgeSchema.optional(),
});
export const valueAddedServicesCapabilitiesSchema = valueAddedServicesSchema.extend({
    additionalInsurance: z.boolean().optional(),
    bulkyGoods: z.boolean().optional(),
    cashOnDelivery: z.boolean().optional(),
    closestDropPoint: z.boolean().optional(),
    dhlRetoure: z.boolean().optional(),
    endorsement: z.boolean().optional(),
    identCheck: z.boolean().optional(),
    individualSenderRequirement: z.boolean().optional(),
    namedPersonOnly: z.boolean().optional(),
    noNeighbourDelivery: z.boolean().optional(),
    parcelOutletRouting: z.boolean().optional(),
    postalDeliveryDutyPaid: z.boolean().optional(),
    preferredDay: z.boolean().optional(),
    preferredLocation: z.boolean().optional(),
    preferredNeighbour: z.boolean().optional(),
    premium: z.boolean().optional(),
    signedForByRecipient: z.boolean().optional(),
    visualCheckOfAge: z.boolean().optional(),
});
export const minMaxSchema = z.object({
    max: z.number(),
    maxBulky: z.number().optional(),
    min: z.number().min(0),
});
const _productDefinitionsSchema = z
    .object({
    hint: z.string().describe('Hint to be used for form description'),
    id: z.string().describe("The provider's product ID"),
    name: z.string().describe('The name of the product'),
    placeholder: z.string().describe('Placeholder text for the product'),
    purpose: z.string().describe('Purpose of the product'),
    vasHint: z.string().describe('Hint to be used for value-added services'),
})
    .and(z.union([
    z.object({
        isReturn: z.literal(true),
    }),
    z.object({
        constraints: z.object({
            height: minMaxSchema.describe('Height in cm'),
            length: minMaxSchema.describe('Length in cm'),
            weight: minMaxSchema.describe('Weight in grams'),
            width: minMaxSchema.describe('Width in cm'),
        }),
        isReturn: z.literal(false),
        region: z.string().describe('Region of the product'),
        valueAddedServices: valueAddedServicesCapabilitiesSchema,
    }),
]));
export const productSchema = z.object({
    billingNumber: z
        .string()
        .regex(/^\d{12}[A-Za-z0-9]{2}$/, 'Die Abrechnungsnummer ist exakt 14 Zeichen lang, davon die ersten 12 sind Ziffern und die letzten 2 sind Ziffern oder Buchstaben.')
        .transform((val) => (val === '' ? undefined : val))
        .describe('The billing number for the product')
        .optional(),
    vas: z.boolean().describe('Whether the product has value-added services'),
});
// export type DhlParcelDeShippingProductSchema = z.infer<typeof dhlParcelDeShippingProductSchema>;
const dbProductItemSchema = z.object({
    billingNumber: z
        .string()
        .regex(/^\d{12}[A-Za-z0-9]{2}$/, 'Die Abrechnungsnummer ist exakt 14 Zeichen lang, davon die ersten 12 sind Ziffern und die letzten 2 sind Ziffern oder Buchstaben.')
        .nullable()
        .or(z.literal('')) // This allows the empty string as valid input
        .transform((val) => (val === '' ? null : val)),
});
const dbProductItemWithLabelFormatSchema = dbProductItemSchema.extend({
    labelFormat: z
        .union([z.literal(''), labelFormatEnumSchema])
        .nullable()
        .transform((val) => (val === '' ? null : val)),
});
const dbProductWarenpostItemWithLabelFormatSchema = dbProductItemSchema.extend({
    labelFormat: z
        .union([z.literal(''), labelFormatWarenpostEnumSchema])
        .nullable()
        .transform((val) => (val === '' ? null : val)),
});
const dbProductItemWithVasSchema = dbProductItemWithLabelFormatSchema.extend({
    vas: z.boolean(),
});
export const dbSchema = z.object({
    config: z.object({
        defaultLabelFormat: labelFormatEnumSchema.describe('The default label format for this DHL shipping contract.'),
        products: z
            .object({
            [productEnumSchema.Enum.Europaket]: dbProductItemWithLabelFormatSchema,
            /**
             * paket is the only product that has optional value-added services
             * depending on the billing number
             */
            [productEnumSchema.Enum.Paket]: dbProductItemWithVasSchema,
            [productEnumSchema.Enum.PaketInternational]: dbProductItemWithLabelFormatSchema,
            [productEnumSchema.Enum.Retoure]: dbProductItemSchema,
            [productEnumSchema.Enum.Warenpost]: dbProductWarenpostItemWithLabelFormatSchema,
            [productEnumSchema.Enum.WarenpostInternational]: dbProductWarenpostItemWithLabelFormatSchema,
        })
            .describe('The billing numbers and optional paket vas information for this DHL shipping contract.'),
        profile: z.string().describe('The profile for this DHL shipping contract.').nullable(),
    }),
    credentials: z.object({
        password: z.string().min(1).describe('The password for the DHL API'),
        username: z.string().min(1).describe('The username for the DHL API'),
    }),
    displayName: z.string().min(1).describe('Display name for this DHL shipping contract.'),
});
