import { zodResolver } from '@hookform/resolvers/zod';
import { getApiById } from '@packages/lib/api';
import { decodeJwt } from 'jose';
import React from 'react';
import { useForm } from 'react-hook-form';
import { Textarea } from 'react-hook-form-mantine';
import { z } from 'zod';

import { Dialog } from '@/components/Dialog';
import { SetupMarketDialog } from '@/components/dialogs/setup-market-dialog';
import { UnauthorizedDialog } from '@/components/dialogs/unauthorized-dialog';
import { Centered } from '@/components/ui/Centered';
import { createBackendClient } from '@/frachter/effect/lib/create-backend-client';
import { useValidateAuthToken } from '@/hooks/use-auth-token';

import { Route } from './~index';

const jwtSchema = z.string().refine(
	(token) => {
		try {
			// Decode the JWT without verifying signature
			const decoded = decodeJwt(token) as { exp?: number };

			if (!decoded.exp) {
				return false; // No exp field found
			}

			const currentTimeInSeconds = Math.floor(Date.now() / 1000);
			const oneMinuteLater = currentTimeInSeconds + 60;

			return decoded.exp >= oneMinuteLater; // Ensure exp is at least 1 minute in the future
		} catch {
			return false; // If decoding fails, token is invalid
		}
	},
	{
		message: 'Ungültiges Token',
	},
);

const connectOttoMarketInputSchema = z.object({
	invitationCode: jwtSchema,
});

export function ConnectOttoPage() {
	const form = useForm<z.infer<typeof connectOttoMarketInputSchema>>({
		defaultValues: { invitationCode: '' },
		mode: 'onChange',
		resolver: zodResolver(connectOttoMarketInputSchema),
		reValidateMode: 'onChange',
	});

	const { validate } = useValidateAuthToken();
	const search = Route.useSearch();

	const isFormValid = React.useMemo(() => {
		if (search.sandbox) return true;
		return form.formState.isValid;
	}, [form.formState.isValid, search.sandbox]);

	// HINT: now we have `sandbox:boolean` and `token:string` in the search params
	// and can send this to the backend via the `onConnect` callback

	const validationResult = validate(search.token);

	const api = getApiById('otto');

	const handleConnect = async () => {
		// TODO: having this wrapped in react-query would be helpful
		// this should validate the auth token and return a URL that we need to replace the location with
		await createBackendClient()
			.connect[api.id].$post(
				{
					json: search.sandbox
						? { origin: window.location.origin, sandbox: true }
						: { invitationCode: form.getValues().invitationCode, origin: window.location.origin },
				},
				{
					headers: {
						Authorization: 'Bearer ' + search.token,
					},
					init: {
						// required to allow setting cookies !
						credentials: 'include',
					},
				},
			)
			.then(async (result) => {
				if (result.status === 200) {
					const data = await result.json();

					window.location.replace(data.redirectUrl);
				}
			})
			.catch((err) => console.error(err));
	};

	if (!validationResult.success) {
		return (
			<Centered style={{ justifyContent: 'center' }}>
				<UnauthorizedDialog
					description="Starte die Verbindungsherstellung erneut."
					subtitle="Das temporäre Zugriffstoken ist abgelaufen."
				/>
			</Centered>
		);
	}

	return (
		<Centered>
			<SetupMarketDialog marketId={api.id} orgSlug={validationResult.payload.organization?.slug ?? null}>
				{!search.sandbox && (
					<Textarea
						autosize
						control={form.control}
						minRows={6}
						name="invitationCode"
						placeholder="Zur Verfügung gestellten Einladungscode hier einfügen"
						spellCheck="false"
						styles={{ input: { fontFamily: 'var(--mantine-font-family-monospace)' } }}
					/>
				)}
				<Dialog.Actions
					disabled={!isFormValid}
					label="Verbindungseinrichtung starten"
					labelSecondary="Abbrechen"
					onClick={() => void handleConnect()}
					onClickSecondary={() => window.close()}
				/>
			</SetupMarketDialog>
		</Centered>
	);
}
