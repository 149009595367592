import { Effect, Stream, SubscriptionRef } from 'effect';

import { createExternalStore } from '@/utils/createExternalStore';

/**
 * Use to react to the change stream of a subscription ref
 */
export const watchSubscriptionRefTask = <T>(
	ref: SubscriptionRef.SubscriptionRef<T>,
	handler?: (change: T) => unknown,
) =>
	ref.changes.pipe(
		Stream.runForEach((change) => Effect.succeed(handler ? handler(change) : change)),
		Effect.forkScoped,
	);

/**
 * this should be used for methods
 */
export const createStoreFromSubscriptionRefTask = <T, R>(
	ref: SubscriptionRef.SubscriptionRef<T>,
	selector: (state: T) => R = (v) => v as unknown as R,
) =>
	Effect.gen(function* () {
		const initialState = yield* ref;
		const store = createExternalStore<R>(selector(initialState));

		yield* ref.changes.pipe(
			Stream.changes,
			Stream.runForEach((change) => Effect.succeed(store.update(() => selector(change)))),
			Effect.interruptible,
			Effect.forkScoped,
		);

		return store;
	});

export function waitForPropertyOnTarget<P extends object, K extends keyof P>(key: K, target: P): Promise<P[K]> {
	const p = Promise.withResolvers<P[K]>();

	if (key in target) {
		// Key already exists, resolve immediately
		p.resolve(target[key]);
	} else {
		// Define a setter to detect when the property is set
		let value: any;
		Object.defineProperty(window, key, {
			configurable: true,
			enumerable: true,
			get() {
				// eslint-disable-next-line @typescript-eslint/no-unsafe-return
				return value;
			},
			set(newValue: P[K]) {
				value = newValue;
				p.resolve(newValue); // Resolve the promise when the key is set
				// Clean up by restoring the original behavior
				delete target[key];
				target[key] = value;
			},
		});
	}

	return p.promise;
}
