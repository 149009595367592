import { usePGlite } from '@electric-sql/pglite-react';
import { Repl } from '@electric-sql/pglite-repl';
import { ActionIcon, Box, Group, Title } from '@mantine/core';
import { IconX as IconClose, IconArrowsVertical as IconResize } from '@tabler/icons-react';
import React from 'react';
import ReactDOM from 'react-dom';

import { useOrganizationSyncStore } from '@/stores/organization-sync-store';

import s from './DatabaseRepl.module.scss';

export const DatabaseRepl = () => {
	const [replRoot, setReplRoot] = React.useState<HTMLDivElement | null>(null);

	React.useEffect(() => {
		if (replRoot) return;

		const element = document.createElement('div');
		element.id = 'pglite-repl-root';
		document.body.appendChild(element);
		setReplRoot(element);
	}, [replRoot]);

	const pg = usePGlite();

	const isOpen = useOrganizationSyncStore((s) => s.isPgLiteReplOpen);
	const toggle = useOrganizationSyncStore((s) => s.togglePgLiteRepl);

	const [isMaximized, setIsMaximized] = React.useState(false);

	return !replRoot
		? null
		: ReactDOM.createPortal(
				<Box
					className={s.repl}
					style={{
						borderRadius: 6,
						bottom: '1rem',
						display: isOpen ? 'block' : 'none',
						height: isMaximized ? undefined : 300,
						left: '1rem',
						overflow: 'clip',
						position: 'fixed',
						right: '1rem',
						top: isMaximized ? '1rem' : undefined,
						zIndex: 1000,
					}}
				>
					<Box
						style={{
							backgroundColor: 'black',
							display: 'grid',
							gridTemplateRows: '50px 1fr',
							height: '100%',
						}}
					>
						<Group align="center" justify="space-between" px="xs" style={{ backgroundColor: 'rgb(255 255 255 / 0.1)' }}>
							<Title c="white" order={6}>
								PG-Lite REPL
							</Title>
							<Group>
								<ActionIcon c="white" onClick={() => setIsMaximized((s) => !s)} variant="subtle">
									<IconResize size={20} />
								</ActionIcon>
								<ActionIcon c="white" onClick={toggle} variant="subtle">
									<IconClose size={20} />
								</ActionIcon>
							</Group>
						</Group>

						<Box style={{ backgroundColor: 'red', overflowY: 'scroll' }}>
							<Repl pg={pg} showTime />
						</Box>
					</Box>
				</Box>,
				replRoot,
			);
};
