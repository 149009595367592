import { Effect } from 'effect';

import { LockError } from './errors';
import { createLockId } from './program-create-lock-id';
import { getClientId } from './program-get-client-id';

export class WebLockService extends Effect.Service<WebLockService>()('WebLockService', {
	effect: Effect.gen(function* () {
		const clientId = yield* getClientId;

		const sessionLockMutex = yield* Effect.makeSemaphore(1);

		return {
			lockSession: () =>
				Effect.runPromise(
					// ensure we are never running this concurrently
					sessionLockMutex.withPermits(1)(
						Effect.gen(function* () {
							const locks = yield* Effect.tryPromise({
								catch: () => new LockError({ name: 'Unknown' }),
								try: () => navigator.locks.query(),
							});

							const hasSessionLock = locks.held?.find((lock) => lock.name === 'session' && lock.clientId === clientId);

							if (hasSessionLock) {
								return true;
							}

							const lockId = 'session';

							yield* createLockId(lockId, { ifAvailable: false });

							return true;
						}),
					),
				),
		};
	}),
}) {}
