import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/org/$slug/settings/')({
	beforeLoad: ({ params }) => {
		throw redirect({
			params,
			to: '/org/$slug/settings/markets',
		});
	},
});
