import { Stack, Text } from '@mantine/core';
import { createColumnHelper } from '@tanstack/table-core';
import React from 'react';

import type { IRowType } from './types';

const columnHelper = createColumnHelper<IRowType>();

const DEFAULT_TEXT_SIZE = 'sm';
const MIN_COLUMN_WIDTH = 80;

export const useCreateColumns = () => {
	const [columns] = React.useState(() => {
		return [
			columnHelper.accessor('orderId', {
				cell: (props) => {
					return (
						<Stack gap={2}>
							<Text size={DEFAULT_TEXT_SIZE}>{props.getValue()}</Text>
							{props.row.original.secondaryOrderId && (
								<Text c="dimmed" size={DEFAULT_TEXT_SIZE}>
									{props.row.original.secondaryOrderId}
								</Text>
							)}
						</Stack>
					);
				},
				header: () => (
					<Text fw={600} size={DEFAULT_TEXT_SIZE}>
						Bestellnummer
					</Text>
				),
			}),
		];
	});

	return columns;
};
