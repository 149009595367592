import { Data, Duration, Effect, Schedule } from 'effect';

import type { IAcquireShapeStreamOptions } from './types';

class ElectricFetchError extends Data.TaggedError('ElectricFetchError')<{ response: Response }> {}

export const electricFetchProgram =
	(
		options: Pick<IAcquireShapeStreamOptions, 'userActivityLatch' | 'getToken' | 'onIsSynchronizedChanged'> & {
			signal?: AbortSignal;
		},
	) =>
	(input: URL | RequestInfo | Request<unknown, CfProperties<unknown>>, init?: RequestInit) =>
		Effect.runPromise(
			Effect.gen(function* () {
				if (typeof input !== 'string') {
					throw new Error('electric sync input is not a string');
				}

				if (options.userActivityLatch) {
					yield* options.userActivityLatch.await;
				}

				const token = yield* options.getToken;

				if (!token) {
					throw new Error('No token');
				}

				const url = new URL(input);

				const liveSearchParam = url.searchParams.get('live');

				options.onIsSynchronizedChanged?.(liveSearchParam === 'true');

				const headers = new Headers(init?.headers);

				headers.set('Authorization', `Bearer ${token}`);

				const response = yield* Effect.promise(() =>
					fetch(url, {
						...init,
						headers,
						mode: 'cors', // do not use `credentials: 'include'` or `mode: 'cors'`
						signal: options.signal,
					}),
				);

				if (response.status === 401) {
					yield* new ElectricFetchError({ response });
				}

				return response;
			}).pipe(
				Effect.retry({
					schedule: Schedule.exponential(Duration.seconds(1)),
					times: 10,
					while(error) {
						console.log('electric fetch error', error.response);

						if (error instanceof ElectricFetchError) {
							return true;
						}

						return false;
					},
				}),
			),
		);
