/**
 * Checks if the validation messages contain an error related to the billing number authorization.
 * The errors for an invalid billing number and for a billing number that is not authorized are quite the same.
 *
 * But in case it does not meet schema requirements, the message contains
 * - for `en-EN`: `Parameter billingNumber must consist of 14 character (10-digit account number, 2 digit procedure ID, 2 digit partnerID).`
 * - for `de-DE`: `Der Parameter billingNumber muss aus 14 Zeichen bestehen (10 Ziffern Accountnummer, 2 Ziffern Prozess-ID, 2 Ziffern Partner-ID).`
 *
 * Otherwise it is most likely an error related to the billing number not being authorized for the requested operation.
 *
 * @param validationMessages The validation messages to check.
 * @returns True if the validation messages contain an error related to the billing number authorization, false otherwise.
 */
export const getBillingNumberAuthorizationErrorIndex = (validationMessages) => validationMessages.findIndex((m) => m.validationState === 'Error' &&
    m.property === 'billingNumber' &&
    (m.validationMessage?.includes('Parameter billingNumber') ||
        m.validationMessage?.includes('Die ausgewählte Abrechnungsnummer') ||
        m.validationMessage?.includes('The selected billing number')));
/**
 * Checks if the validation messages contain an error related to the return billing number authorization.
 *
 * @param validationMessages The validation messages to check.
 * @returns True if the validation messages contain an error related to the return billing number authorization, false otherwise.
 */
export const getReturnBillingNumberAuthorizationErrorIndex = (validationMessages) => validationMessages.findIndex((m) => m.validationState === 'Error' &&
    m.property === 'services.dhlRetoure' &&
    (m.validationMessage?.includes('billing number') || m.validationMessage?.includes('Abrechnungsnummer ')));
/**
 * Notes:
 *  - we will not check for billing number schema validation as our forms already validate this
 *    and unless a user "hacks" the form, this should not happen
 */
