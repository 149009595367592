import React from 'react';

import { useFrachterApi } from './use-frachter';

export function useDatabaseStatus() {
	const {
		database: { $store },
	} = useFrachterApi();

	return React.useSyncExternalStore($store.store.subscribe, $store.store.getSnapshot);
}
