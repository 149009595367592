'use client';

import { Box, Text, Title } from '@mantine/core';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import React, { PropsWithChildren, useEffect, useId } from 'react';

import { useAppLayoutSetTitle, useAppLayoutTitle } from '../app/AppLayoutContext';

import s from './AppPageTitle.module.scss';
import { getAppsBreadcrumbs } from './getAppsBreadcrumbs';

/**
 *
 * Dynamic title that accepts
 * - a string -> turned into a title
 * - a react node -> rendered as is
 * - undefined -> auto-generate breadcrumbs
 */
export const AppPageTitle = ({ children }: React.PropsWithChildren) => {
	const setTitle = useAppLayoutSetTitle();
	const key = useId();

	useEffect(() => {
		setTitle((prev) => {
			/**
			 * this is
			 * - the same string as children if children is a string
			 * - the key from useId if children is a React.ReactNode
			 * - a string in the form of `breadcrumb1|separator1|breadcrumb2|separator2|...` if children is undefined
			 */
			const previousKey = prev?.map((v) => v.key).join('|') ?? '';

			if (children == null) {
				// try auto-generating breadcrumbs. If no path matches, return null
				const pathItems = getAppsBreadcrumbs();

				if (!pathItems) {
					return null;
				}

				const items: { element: React.JSX.Element; key: string }[] = [];

				for (let i = 0; i < pathItems.length; i++) {
					const pathItem = pathItems[i];
					const isFirst = i === 0;

					items.push({
						element: isFirst ? (
							<Title order={3}>{pathItem}</Title>
						) : (
							<Title c={'dimmed'} fw="400" order={5}>
								{pathItem}
							</Title>
						),
						key: `${pathItem}-${i}`,
					});

					if (i < pathItems.length - 1) {
						items.push({ element: <Text c="dimmed">/</Text>, key: `separator-${i}` });
					}
				}

				const nextKey = items.map((v) => v.key).join('|');

				if (nextKey === previousKey) {
					return prev;
				}

				return items;
			} else if (typeof children === 'string') {
				if (previousKey === children) {
					return prev;
				}
				return [
					{
						element: <Title order={3}>{children}</Title>,
						key: children,
					},
				];
			} else {
				if (previousKey === key) {
					return prev;
				}

				return [{ element: <MotionWrapper key={key}>{children}</MotionWrapper>, key }];
			}
		});
	}, [children, key, setTitle]);

	return null;
};

const variants = {
	enter: {
		transition: { staggerChildren: 0.1, when: 'afterChildren' },
	},
	exit: {
		transition: { staggerChildren: 0.05, staggerDirection: -1 },
	},
};

export function AppPageTitleWrapper({
	className,
	style,
}: React.PropsWithChildren<{ className?: string; style?: React.CSSProperties }>) {
	const items = useAppLayoutTitle();

	return (
		<Box className={clsx(className)} style={style}>
			<AnimatePresence mode="wait">
				<Box
					key={'container'}
					animate="enter"
					className={s.root}
					component={motion.div}
					exit="exit"
					initial="exit"
					variants={variants}
				>
					{items?.map((item) => (
						<MotionWrapper key={item.key}>
							<Box style={{ marginTop: -1 }}>{item.element}</Box>
						</MotionWrapper>
					))}
				</Box>
			</AnimatePresence>
		</Box>
	);
}

const itemVariants = {
	enter: {
		opacity: 1,
		transition: { duration: 0.15, ease: 'easeInOut' },
		x: 0,
	},
	exit: {
		opacity: 0,
		transition: { duration: 0.1, ease: 'easeInOut' },
		x: 20,
	},
};

const MotionWrapper: React.FC<PropsWithChildren> = ({ children }) => {
	return (
		<Box className={s.motionWrapper} component={motion.div} variants={itemVariants}>
			{children}
		</Box>
	);
};
