import { and, asc, count, eq, sql, type InferSelectModel } from 'drizzle-orm';

import { dbs } from '@/drizzle/pglite';

import { DB } from './types';

export const getOrdersCountByStatus =
	(status: InferSelectModel<(typeof dbs)['marketOrdersLocal']>['status'], marketId: string, merchantId: string) =>
	(drizzle: DB) => {
		return drizzle
			.select({ count: count() })
			.from(dbs.marketOrders)
			.where(
				and(
					eq(dbs.marketOrdersLocal.status, status),
					eq(dbs.marketOrdersLocal.marketId, marketId),
					eq(dbs.marketOrdersLocal.merchantId, merchantId),
				),
			);
	};

export const getMarketOrdersStats = (marketId: string, merchantId: string) => (drizzle: DB) =>
	drizzle
		.select({
			cancelled: sql<number>/*sql*/ `
				CAST(
					COUNT(*) FILTER (
						WHERE
							status = 'CANCELLED'
					) AS INT
				) AS "cancelled"
			`,
			partiallyShipped: sql<number>/*sql*/ `
				CAST(
					COUNT(*) FILTER (
						WHERE
							status = 'PARTIALLY_SHIPPED'
					) AS INT
				) AS "partiallyShipped"
			`,
			pending: sql<number>/*sql*/ `
				CAST(
					COUNT(*) FILTER (
						WHERE
							status = 'PENDING'
					) AS INT
				) AS "pending"
			`,
			returned: sql<number>/*sql*/ `
				CAST(
					COUNT(*) FILTER (
						WHERE
							status = 'RETURNED'
					) AS INT
				) AS "returned"
			`,
			shipped: sql<number>/*sql*/ `
				CAST(
					COUNT(*) FILTER (
						WHERE
							status = 'SHIPPED'
					) AS INT
				) AS "shipped"
			`,
			unfulfillable: sql<number>/*sql*/ `
				CAST(
					COUNT(*) FILTER (
						WHERE
							status = 'UNFULLFILLABLE'
					) AS INT
				) AS "unfulfillable"
			`,
			unshipped: sql<number>/*sql*/ `
				CAST(
					COUNT(*) FILTER (
						WHERE
							status = 'UNSHIPPED'
					) AS INT
				) AS "unshipped"
			`,
		})
		.from(dbs.marketOrdersLocal)
		.where(and(eq(dbs.marketOrdersLocal.marketId, marketId), eq(dbs.marketOrdersLocal.merchantId, merchantId)));

export interface GetOrderIdsByStatusParams {
	marketId: string;
	merchantId: string;
	status: InferSelectModel<(typeof dbs)['marketOrdersLocal']>['status'];
}

export const getOrderIdsByStatus =
	(params: GetOrderIdsByStatusParams, options?: { offset?: number; limit?: number }) => (drizzle: DB) =>
		drizzle.query.marketOrdersLocal.findMany({
			columns: { orderId: true },
			...(options?.offset ? { offset: options.offset } : {}),
			...(options?.limit ? { limit: options.limit } : {}),
			orderBy: asc(dbs.marketOrdersLocal.updatedAt),
			where: and(
				eq(dbs.marketOrdersLocal.status, params.status),
				eq(dbs.marketOrdersLocal.marketId, params.marketId),
				eq(dbs.marketOrdersLocal.merchantId, params.merchantId),
			),
		});
