'use client';

import { Box, rem, Stack, Tabs, Title } from '@mantine/core';
import {
	IconCreditCardPay as IconBilling,
	IconBuildingStore,
	IconBuilding as IconOrganization,
	IconTruckDelivery as IconCarrier,
	IconPackageExport as IconShippingTemplates,
	IconPrinter as IconCloudprint,
	IconShoppingBag as IconProducts,
	IconSettings,
	IconDeviceDesktop as IconSystem,
} from '@tabler/icons-react';
import { useNavigate, useRouter, useRouterState } from '@tanstack/react-router';

import { useFrachterUser } from '@/hooks/use-frachter-user';

import s from './SideBarSettings.module.scss';

export const SideBarSettings = () => {
	const { user } = useFrachterUser();
	const iconStyle = { height: rem(18), width: rem(18) };

	const router = useRouter();

	const { location } = useRouterState();
	const navigate = useNavigate();

	const pathname = location.pathname;

	if (!user) {
		return null;
	}

	const { slug } = user.organization ?? {};

	return !slug ? null : (
		<Box className={s.root}>
			<Stack gap="xs" h="100%">
				<Tabs
					className={s.menu}
					defaultValue="gallery"
					orientation="vertical"
					radius="xs"
					value={typeof window === 'undefined' ? '' : pathname}
					onChange={(value) => {
						if (typeof value === 'string') {
							void navigate({
								to: value,
							});
							// router.push(value);
						}
					}}
				>
					<Tabs.List h="100%">
						{/** ██████████████████████████████████████████████████████████████████████████████████████████████
						 * ORGANIZATION
						 * ███████████████████████████████████████████████████████████████████████████████████████████████ */}

						<Title c="dimmed" mb="xs" order={5}>
							Organisation
						</Title>

						<Tabs.Tab
							leftSection={<IconOrganization style={iconStyle} />}
							size="lg"
							value={router.buildLocation({ params: { slug }, to: '/org/$slug/settings/organization' }).href}
						>
							Stammdaten
						</Tabs.Tab>

						<Tabs.Tab
							leftSection={<IconSystem style={iconStyle} />}
							size="lg"
							value={router.buildLocation({ params: { slug }, to: '/org/$slug/settings/system' }).href}
						>
							System
						</Tabs.Tab>

						<Tabs.Tab
							leftSection={<IconBilling style={iconStyle} />}
							size="lg"
							value={router.buildLocation({ params: { slug }, to: '/org/$slug/settings/billing' }).href}
						>
							Abrechnung
						</Tabs.Tab>

						{/** ██████████████████████████████████████████████████████████████████████████████████████████████
						 * QUICKSHIP
						 * ███████████████████████████████████████████████████████████████████████████████████████████████ */}

						<Title c="dimmed" mb="xs" mt="md" order={5}>
							Quickship
						</Title>

						<Tabs.Tab
							leftSection={<IconCloudprint style={iconStyle} />}
							size="lg"
							value={router.buildLocation({ params: { slug }, to: '/org/$slug/settings/cloudprint' }).href}
						>
							CloudPrint
						</Tabs.Tab>

						<Tabs.Tab
							leftSection={<IconBuildingStore style={iconStyle} />}
							size="lg"
							value={router.buildLocation({ params: { slug }, to: '/org/$slug/settings/markets' }).href}
						>
							Marktplätze
						</Tabs.Tab>

						<Tabs.Tab
							leftSection={<IconCarrier style={iconStyle} />}
							size="lg"
							value={router.buildLocation({ params: { slug }, to: '/org/$slug/settings/carriers' }).href}
						>
							Versanddienstleister
						</Tabs.Tab>

						<Tabs.Tab
							leftSection={<IconShippingTemplates style={iconStyle} />}
							size="lg"
							value={router.buildLocation({ params: { slug }, to: '/org/$slug/settings/shipping-templates' }).href}
						>
							Versandvorlagen
						</Tabs.Tab>

						<Tabs.Tab
							leftSection={<IconSettings style={iconStyle} />}
							value={router.buildLocation({ params: { slug }, to: '/org/$slug/settings/shipping-rules' }).href}
						>
							Versandregeln
						</Tabs.Tab>

						<Tabs.Tab
							leftSection={<IconProducts style={iconStyle} />}
							value={router.buildLocation({ params: { slug }, to: '/org/$slug/settings/products' }).href}
						>
							Produkte
						</Tabs.Tab>
					</Tabs.List>
				</Tabs>
			</Stack>
		</Box>
	);
};
