// THIS should be the base for the new shapes

import type { SyncShapeToTableResult } from '@electric-sql/pglite-sync';

import { Effect } from 'effect';

import type { IMakeShapeStreamOptions } from './types';

import { DatabaseSyncError } from '../errors';

import { electricFetchProgram } from './shape-fetch-program';
import { createTriggerMigrationStatement, clearYearMonthFromTableStatement, clearTableStatement } from './sql';

/**
 * TODO: rename this as it does not return an effect Stream! and that is confusing!
 */
export const makeShapeStream = (options: IMakeShapeStreamOptions) =>
	Effect.gen(function* () {
		if (options.yearMonth) {
			yield* Effect.tryPromise(() => options.pg.exec(createTriggerMigrationStatement(options.table)));
		}

		const abortController = new AbortController();

		const signals = [abortController.signal, options.signal].filter(Boolean) as AbortSignal[];

		const signal = AbortSignal.any(signals);

		yield* Effect.addFinalizer(() =>
			Effect.gen(function* () {
				console.log('finalizer createShapeStream');
				abortController.abort();
			}),
		);

		return yield* Effect.async<SyncShapeToTableResult, DatabaseSyncError, never>((resume) => {
			options.pg.electric
				.syncShapeToTable({
					onMustRefetch: async (tx) => {
						if (options.yearMonth) {
							await tx.exec(clearYearMonthFromTableStatement(options.table, options.yearMonth)).catch((err) => {
								console.error('error clearing year month', err);
							});
						} else {
							await tx.exec(clearTableStatement(options.table)).catch((err) => {
								console.error('error clearing table', err);
							});
						}
					},
					primaryKey: options.primaryKey,
					shape: {
						fetchClient: electricFetchProgram({
							getToken: options.getAuthToken,
							onIsSynchronizedChanged: options.onIsSynchronizedChanged,
							signal: signal,
							userActivityLatch: options.userActivityLatch,
						}),
						onError: (error) => {
							console.error(`Error in electric sync for table ${options.table}`, error);
						},
						params: {
							table: options.table,
							...(options.yearMonth && { year_month: [options.yearMonth] }),
						},
						signal: signal,
						url: options.syncUrl.toString(),
					},

					// HINT: re-enable when we're able to use multiple shapes and properly control inserts
					shapeKey: options.yearMonth ? `${options.table}:${options.yearMonth}` : options.table, // `${options.table}:${initialPartitionKeys.join('|')}`,
					table: options.table,
				})
				.then((v) => {
					resume(Effect.succeed(v));
				})
				.catch((err) => {
					console.error('shape error', err);
					resume(Effect.fail(new DatabaseSyncError({ message: err.message ?? 'unknown error' })));
				});
		}).pipe(Effect.interruptible);
	});
