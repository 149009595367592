'use client';

import { ActionIcon } from '@mantine/core';
import { IconChevronLeft as IconLeft } from '@tabler/icons-react';
import { Link, ParsedLocation, useMatch, useParams, useRouter, useRouterState } from '@tanstack/react-router';
import { useEffect, useState } from 'react';

export const BackButton = () => {
	const routerState = useRouterState();
	const router = useRouter();
	const { slug } = useParams({ strict: false });
	const pathname = routerState.location.pathname;

	const matchedPath = useMatch({
		from: '/org/$slug',
		shouldThrow: false,
	});

	const [lastLocation, setLastLocation] = useState<ParsedLocation>();

	const lastHref = !slug
		? undefined
		: (lastLocation?.href ?? router.buildLocation({ params: { slug }, to: '/org/$slug' }).href);

	useEffect(() => {
		if (!matchedPath || !pathname) return;

		const subPath = pathname.split(matchedPath.pathname)[1];

		if (!subPath) return;

		const firstSegment = subPath.split('/')[1];

		if (firstSegment !== 'settings') {
			setLastLocation(routerState.location);
		}
	}, [matchedPath, pathname, router, routerState.location, slug]);

	return (
		<Link to={lastHref ?? '/'}>
			<ActionIcon color="var(--mantine-color-text)" radius="xl" size="md" variant="outline">
				<IconLeft color="var(--mantine-color-text)" size={20} />
			</ActionIcon>
		</Link>
	);
};
