import { createFileRoute } from '@tanstack/react-router';

import { AppPageLayout } from '@/layouts/app-page';

import { ShippingTemplatesSettingsPage } from './page';

export const Route = createFileRoute('/org/$slug/settings/shipping-templates')({
	component: () => (
		<AppPageLayout variant="settings">
			<ShippingTemplatesSettingsPage />
		</AppPageLayout>
	),
});
