import { produce } from 'immer';
import { throttle } from 'lodash-es';

type Updater<T> = (draft: T) => T | void;

export interface ExternalStore<T> {
	dispatch: () => void;
	getSnapshot: () => T;
	subscribe: (callback: () => void) => () => void;
	subscribers: Set<() => void>;
	update: (producerOrNextStore: T | Updater<T>) => T;
}

export function createExternalStore<T>(initial: T, throttleMs = 0): ExternalStore<T> {
	const subscribers = new Set<() => void>();

	const dispatch = throttle(
		() => {
			subscribers.forEach((callback) => callback());
		},
		throttleMs, // set 0 to ensure we batch all updates in the current frame
		{ leading: false, trailing: true },
	);

	let store: T = initial;

	return {
		dispatch,
		getSnapshot: () => store,
		subscribe: (callback) => {
			subscribers.add(callback);
			return () => subscribers.delete(callback);
		},
		subscribers,
		update: (producerOrNextStore) => {
			let next: T;

			if (typeof producerOrNextStore === 'function') {
				const up = producerOrNextStore as Updater<T>;
				next = produce(store, up);
			} else {
				next = produce(store, () => producerOrNextStore);
			}

			if (!Object.is(store, next)) {
				store = next;
				dispatch();
			}
			return store;
		},
	};
}
