import { Box, Modal } from '@mantine/core';
import { useNotifications } from '@mantine/notifications';
import * as easing from 'css-in-js-easing';
import { lineWobble } from 'ldrs';
import React from 'react';

import { BLOCKING_NOTIFICATION_PREFIX } from '@/frachter/effect/global-services/NotificationService';
import { useAppStore } from '@/stores/app-store';

import { FrachterLogo } from '../FrachterLogo';

lineWobble.register();

export function AppPreloader({ forceVisible }: { forceVisible?: boolean }) {
	const [isVisible, setIsVisible] = React.useState(false);
	const timeoutRef = React.useRef<NodeJS.Timeout | null>(null);

	// const isVisible = useAppStore((s) => s.isAppPreloaderVisible);

	const notificationsStore = useNotifications();

	const hasBlockingNotification = notificationsStore.notifications.some((value) =>
		value.id?.startsWith(BLOCKING_NOTIFICATION_PREFIX),
	);

	/** ----------------------------------------------------------------------------------------------
	 * we are going do debounce visibility changes to prevent flickering
	 * WARN: esnure that routes do not suspend, instead suspend components inside of routes!
	 * _______________________________________________________________________________________________ */
	React.useEffect(() => {
		useAppStore.subscribe((state) => {
			if (!state.isAppPreloaderVisible) {
				if (timeoutRef.current) {
					clearTimeout(timeoutRef.current);
				}
				timeoutRef.current = setTimeout(() => {
					setIsVisible(false);
				}, 250);
			}

			if (state.isAppPreloaderVisible) {
				if (timeoutRef.current) {
					clearTimeout(timeoutRef.current);
				}

				setIsVisible(true);
			}
		});
	}, []);

	const handleClose = () => {
		// console.log('close');
	};

	return (
		<Modal
			centered
			closeOnClickOutside={false}
			closeOnEscape={false}
			keepMounted
			onClose={handleClose}
			opened={hasBlockingNotification || forceVisible || isVisible}
			size="xs"
			trapFocus={false}
			withCloseButton={false}
			zIndex="calc(var(--notifications-z-index) - 1)"
			overlayProps={{
				backgroundOpacity: 1,
				bg: 'black',
			}}
			styles={{
				body: {
					aspectRatio: '1/1',
					backgroundColor: 'rgba(5 5 5 / 1)',
				},
				content: {
					backgroundColor: 'rgba(5 5 5 / 1)',
					border: '1px solid rgba(10 10 10 / 1)',
					borderColor: 'rgba(255 255 255 / 0.1)',
					borderRadius: 20,
					overflow: 'hidden',
				},
			}}
			transitionProps={{
				duration: 500,
				transition: {
					common: {
						transformOrigin: 'center center',
						transitionDuration: `${500}ms`,
						transitionTimingFunction: easing.easeInOutCirc,
					},
					in: { opacity: 1, transform: 'scale(1)' },
					out: { opacity: 0, transform: 'scale(1.2)' },
					transitionProperty: 'transform, opacity',
				},
			}}
		>
			<Box style={{ aspectRatio: '1/1', display: 'grid', placeContent: 'center', position: 'relative' }}>
				<FrachterLogo size={100} />
				<Box style={{ bottom: 20, display: 'grid', left: 0, placeContent: 'center', position: 'absolute', right: 0 }}>
					<l-line-wobble bg-opacity="0.1" color="var(--mantine-color-brand-5)" size="140" speed="1.75" stroke="3" />
				</Box>
			</Box>
		</Modal>
	);
}

export function AppPreloaderToggle() {
	const setAppPreloadKey = useAppStore((s) => s.setAppPreloadKey);
	const unsetAppPreloadKey = useAppStore((s) => s.unsetAppPreloadKey);

	React.useEffect(() => {
		setAppPreloadKey('app-preloader');
		return () => unsetAppPreloadKey('app-preloader');
	}, [setAppPreloadKey, unsetAppPreloadKey]);

	return null;
}
