import { useErrorListener } from '@liveblocks/react/suspense';
import { Box } from '@mantine/core';
import { createFileRoute } from '@tanstack/react-router';
import React from 'react';

import useLiveStore from '@/stores/live-store';

export const Route = createFileRoute('/playground/liveblocks')({
	component: Component,
});

const ROOM_ID = 'room-id';

function Component() {
	const {
		liveblocks: { enterRoom, leaveRoom },
	} = useLiveStore();

	useErrorListener((error) => {
		if (error.code === 4006) {
			// Room ID has been changed, get the new ID and redirect
			const newRoomId = error.message;
			console.log('room id changed', newRoomId);
		}
	});

	const others = useLiveStore((state) => state.liveblocks.others);
	const userCount = others.length;

	React.useEffect(() => {
		enterRoom(ROOM_ID);
		return () => {
			leaveRoom();
		};
	}, [enterRoom, leaveRoom]);

	return <Box>There are {userCount} other user(s) online</Box>;
}
