import clsx from 'clsx';
import React from 'react';

import { useFrachterUser } from '@/hooks/use-frachter-user';

import { AppLayout } from '../app';

import { AccountControl } from './components/AccountControl';
import { OrganizationSideBar } from './OrganizationSideBar';

export function OrganizationLayout({
	children,
	className,
	hideAccountMenu,
}: React.PropsWithChildren<{
	className?: string;
	/**
	 * if true, the account menu will not be rendered
	 */
	hideAccountMenu?: boolean;
	/**
	 * if true, we ensure that the user is logged into the requested organization.
	 * If the user is in another org, we'll auto-switch it, if the user can access it.
	 */
	requestedOrganizationSlug?: string;
}>) {
	// our middleware.ts file takes care that the user is logged in

	const { user } = useFrachterUser({ organization: true });

	return (
		<AppLayout
			/* we must use RelatimeProvider like this. Otherwise we can't use mantine components in it */
			className={clsx(className)}
			// layoutWrapper={LayoutWrapper}
			sideBarContent={user.organization.id && <OrganizationSideBar />}
			appBarContent={
				<AccountControl
					currentOrganizationId={user.organization.id}
					hideOrganizationMenu={Boolean(hideAccountMenu)}
					memberships={user.organizationMemberships}
				/>
			}
		>
			{children}
		</AppLayout>
	);
}
