import { useApolloClient } from '@apollo/client';
import { createFileRoute } from '@tanstack/react-router';
import React from 'react';

import { AuthenticatedOutlet } from '@/components/AuthenticatedOutlet';
import { useFrachterUser } from '@/hooks/use-frachter-user';

export const Route = createFileRoute('/org')({
	component: Layout,
});

function Layout() {
	return (
		/* contains trpc and graphql provider that we need with an active user organization and without (for creating an organization) */
		// <OrganizationStoreProvider frachterClientId={frachterClientId}>
		// <OrganizationSyncStoreProvider frachterClientId={frachterClientId}>
		<CacheWrapper>
			<AuthenticatedOutlet />
		</CacheWrapper>
		// </OrganizationSyncStoreProvider>
		// </OrganizationStoreProvider>
	);
}

const CacheWrapper = ({ children }: { children: React.ReactNode }) => {
	const client = useApolloClient();

	const { user } = useFrachterUser();

	const [isPending, startTransition] = React.useTransition();

	React.useEffect(() => {
		startTransition(async () => {
			/* clear the apollo cache when the organization changes */
			await client.clearStore();
		});
	}, [client, user?.organization?.id]);

	return isPending ? null : <>{children}</>;
};
