import React from 'react';
import { useCookies } from 'react-cookie';

import { constants } from '@/data/constants';

export const useSetAppLayout = (value?: 'default' | 'standalone') => {
	const [, setCookie] = useCookies([constants.COOKIE_NAME_APP_LAYOUT], {
		doNotParse: true,
	});

	React.useEffect(() => {
		if (!value) return;
		setCookie('app_layout', value);
	}, [value, setCookie]);
};

export const useGetAppLayout = () => {
	const [{ [constants.COOKIE_NAME_APP_LAYOUT]: appLayout }] = useCookies<
		typeof constants.COOKIE_NAME_APP_LAYOUT,
		{
			[constants.COOKIE_NAME_APP_LAYOUT]: 'default' | 'standalone';
		}
	>([constants.COOKIE_NAME_APP_LAYOUT], { doNotParse: true });

	return appLayout;
};
