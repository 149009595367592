import { Data } from 'effect';

import { ILockOwner } from '../../types/locks';

/**
 * To be used in case a request was made where the lock was already owned by someone else
 * or in case an unlock request was made but we are not holding the lock.
 */
export class LockUnavailableError extends Data.TaggedError('LockUnavailableError')<{
	lockId: string;
	owner: ILockOwner;
}> {}

/**
 * To be used in case a lock was acquired but then immediately lost to someone else
 * who has requested the lock at the same time.
 *
 * Happens seldom but according to ably docs, it can happen and we know how to catch it.
 */
export class LockRaceConditionError extends Data.TaggedError('LockRaceConditionError')<{
	lockId: string;
}> {}

export class UnauthorizedError extends Data.TaggedError('UnauthorizedError')<{
	message: string;
}> {}
