import { ActionIcon, Box, Group, Input, Title } from '@mantine/core';
import { useDebouncedCallback } from '@mantine/hooks';
import { IconSearch, IconX } from '@tabler/icons-react';
import React from 'react';

import tableStyles from './table.module.scss';

interface ISearchInputProps {
	onChange: (value: string) => void;
	placeholder?: string;
}

export interface ITableTopBarProps {
	search?: ISearchInputProps;
	title?: string;
}

const SearchInput: React.FC<ISearchInputProps> = ({ onChange, placeholder = 'Suche' }) => {
	const [value, setValue] = React.useState('');

	const debouncedOnChange = useDebouncedCallback(onChange, 500);

	React.useEffect(() => {
		if (!value) {
			/* immediately trigger onChange if the input is empty */
			onChange(value);
		} else {
			debouncedOnChange(value);
		}
	}, [value, debouncedOnChange, onChange]);

	return (
		<Group>
			<Input
				miw={400}
				onChange={(e) => setValue(e.target.value)}
				placeholder={placeholder}
				radius="md"
				rightSectionPointerEvents={value ? 'all' : 'none'}
				value={value}
				rightSection={
					value ? (
						<ActionIcon onClick={() => setValue('')} size="sm" variant="transparent">
							<IconX />
						</ActionIcon>
					) : (
						<ActionIcon size="sm" variant="transparent">
							<IconSearch />
						</ActionIcon>
					)
				}
			/>
		</Group>
	);
};

export const TableTopBar: React.FC<ITableTopBarProps> = ({ search, title }) => {
	return (
		<Box
			className={tableStyles.topBar}
			py="md"
			style={{
				alignItems: 'center',
				// backgroundColor: `color-mix(in srgb, var(--mantine-color-text) 5%, transparent)`,
				borderBottom: '1px solid color-mix(in srgb, var(--mantine-color-text) 5%, transparent)',
				display: 'grid',
				gridAutoFlow: 'column',
				justifyContent: 'space-between',
				minHeight: 50,
			}}
		>
			{title && <Title order={5}>{title}</Title>}
			{search ? <SearchInput {...search} /> : null}
		</Box>
	);
};

export const MemoizedTableTopBar = React.memo(TableTopBar);
