import { Cause, Chunk, Data, Effect, Exit, Schedule, Stream, SubscriptionRef } from 'effect';
import { TimeoutException } from 'effect/Cause';
import { createExternalStore } from '../react';
import { ZodValidationError } from './errors';
import { changesDeepEqual } from './Stream';
export const makeWatcher = (initialValue, options = {}) => Effect.gen(function* () {
    const ref = yield* SubscriptionRef.make(initialValue);
    const store = createExternalStore(structuredClone(yield* SubscriptionRef.get(ref)));
    const stream = options?.useReferentialEquality
        ? ref.changes.pipe(Stream.changes)
        : ref.changes.pipe(changesDeepEqual);
    yield* Stream.runForEach(stream, (data) => Effect.succeed(store.update(() => structuredClone(data)))).pipe(Effect.interruptible, Effect.forkScoped);
    return {
        ref,
        store,
        stream,
    };
});
export const makeWatcherFromStream = (stream) => Effect.gen(function* () {
    // HINT: no idea if this works...
    const firstRepeatedChunk = yield* Stream.runCollect(Stream.repeat(stream, Schedule.once).pipe(Stream.take(0)));
    const ref = yield* SubscriptionRef.make(firstRepeatedChunk.pipe(Chunk.toArray)[0]);
    const store = createExternalStore(yield* SubscriptionRef.get(ref));
    yield* Stream.runForEach(stream, (data) => Effect.succeed(store.update(() => data))).pipe(Effect.interruptible, Effect.forkScoped);
    return {
        ref,
        store,
        stream,
    };
});
class RetryError extends Data.TaggedError('RetryError') {
}
/**
 * Create a task that can be retried until the timeout is reached or when we do not
 * explicitly call retry in the catch block.
 *
 * It can also be aborted / interrupted by calling the returned `interrupt` handler.
 *
 * It is mainly meant for being exposed to non-effect framworks like React as it won't expose
 * any effect-ish types.
 *
 * @param options
 * @returns
 */
export const createRetriableTask = async (task, options) => {
    const timeoutSeconds = options?.timeoutSeconds ?? 10;
    const retryDelaySeconds = options?.retryDelaySeconds ?? 1;
    /* prepare the request with a retry */
    const request = Effect.tryPromise({
        catch: (err) => task.catch(() => new RetryError(), err),
        try: () => task.try().then((v) => ({ data: v, err: null })),
    }).pipe(Effect.retry({
        schedule: Schedule.spaced(`${retryDelaySeconds} seconds`),
        while: (err) => err instanceof RetryError,
    }), Effect.timeoutTo({
        duration: `${timeoutSeconds} seconds`,
        onSuccess: (v) => v,
        onTimeout: () => {
            return { data: null, err: { type: 'timeout' } };
        },
    }));
    const result = Effect.runPromiseExit(request, {
        ...(options?.signal && { signal: options.signal }),
    }).then((exit) => {
        if (Exit.isSuccess(exit)) {
            return Exit.getOrElse(exit, () => {
                throw new Error('invalid_exit');
            });
        }
        else if (Exit.isInterrupted(exit)) {
            return { err: { reason: null, type: 'interrupted' } };
        }
        return {
            err: {
                reason: Cause.pretty(exit.cause),
                type: 'unhandled_error',
            },
        };
    });
    return result;
};
export function handleExit(exit) {
    return Exit.match(exit, {
        onFailure: (cause) => {
            if (Cause.isInterrupted(cause)) {
                return { data: null, err: { reason: null, type: 'interrupt' } };
            }
            else if (Cause.isDie(cause)) {
                throw new Error('Program quit unexpectedly', {
                    cause: Cause.pretty(cause),
                });
            }
            const failures = Chunk.toArray(Cause.failures(cause));
            if (failures.length > 1) {
                throw new Error('Unexpected: Multiple failures', { cause: failures });
            }
            const failure = failures[0];
            if (failure instanceof TimeoutException) {
                return {
                    data: null,
                    err: { reason: failure, type: 'timeout' },
                };
            }
            return { data: null, err: { reason: failure, type: 'failure' } };
        },
        onSuccess: (value) => ({ data: value, err: null }),
    });
}
// export const validateZodSchema: <TSchema extends z.ZodSchema, TData = unknown>(
// 	schema: TSchema,
// 	data: TData,
// ) => Effect.Effect<z.TypeOf<TSchema>, ZodValidationError, never> = (schema, data) =>
// 	Effect.gen(function* <TTSchema extends z.ZodSchema = TSchema, TData = unknown>() {
// 		const result = schema.safeParse(data) as SafeParseReturnType<TData, z.TypeOf<TSchema>>;
// 		if (!result.success) {
// 			return yield* new ZodValidationError({ reason: result.error });
// 		}
// 		const rd = result.data;
// 		return rd;
// 	});
export function validateZodSchema(schema, data) {
    return Effect.gen(function* () {
        const result = schema.safeParse(data);
        if (!result.success) {
            return yield* new ZodValidationError({ reason: result.error });
        }
        const rd = result.data;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return rd;
    });
}
