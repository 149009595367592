/// <reference types="type-fest" />

'use client';

// import 'type-fest/source/partial-deep';

import { generateColors } from '@mantine/colors-generator';
import {
	type DefaultMantineColor,
	type MantineColorsTuple,
	type MantineThemeOverride,
	type VariantColorsResolver,
	createTheme,
	defaultVariantColorsResolver,
	mergeThemeOverrides,
	rem,
} from '@mantine/core';

// import vars from '@/styles/_index.module.scss';

export type Colors = Record<keyof typeof colors | DefaultMantineColor, MantineColorsTuple>;

declare module '@mantine/core' {
	export interface MantineThemeColorsOverride {
		colors: Colors;
	}
}

const _colors = {
	danger: generateColors('#F21616') as MantineColorsTuple,
	ok: generateColors('#2BDD66') as MantineColorsTuple,
	primary: generateColors('#00B5FF') as MantineColorsTuple,
	warning: generateColors('#ffa800') as MantineColorsTuple,
	//  [
	// 	'oklch(93.15% 0.059 10.85)',
	// 	'oklch(86.3% 0.118 10.85)',
	// 	'oklch(79.45% 0.178 10.85)',
	// 	'oklch(72.6% 0.237 10.85)',
	// 	'oklch(65.75% 0.296 10.85)',
	// 	'oklch(62.6% 0.317 10.85)',
	// 	'oklch(59.45% 0.338 10.85)',
	// 	'oklch(56.3% 0.358 10.85)',
	// 	'oklch(53.15% 0.379 10.85)',
	// 	'oklch(50.0% 0.400 10.85)',
	// ] as MantineColorsTuple,
};

/**
 * color generators:
 * https://uicolors.app/create
 *
 */
export const colors = {
	brand: [
		'#ffe8f2',
		'#ffcedf',
		'#ff9bbc',
		'#ff6497',
		'#fe3877',
		'#fe1c63',
		'#ff0959',
		'#e4004a',
		'#cc0041',
		'#b30036',
	] satisfies MantineColorsTuple,

	// white: [
	// 	'#ffffff',
	// 	'#ffffff',
	// 	'#ffffff',
	// 	'#ffffff',
	// 	'#ffffff',
	// 	'#ffffff',
	// 	'#ffffff',
	// 	'#ffffff',
	// 	'#ffffff',
	// 	'#ffffff',
	// ] satisfies MantineColorsTuple,

	// }),
	danger: _colors.danger,
	dark: [
		'#263A52',
		'#203146',
		'#19283B',
		'#13202F',
		'#0D1825',
		'#08101A',
		'#040911',
		'#020408',
		'#010102',
		'#000000',
	] satisfies MantineColorsTuple,
	ok: _colors.ok,
	// [
	// 	'#e4fcff',
	// 	'#d0f6ff',
	// 	'#a1ebfd',
	// 	'#6fe1fc',
	// 	'#4ed8fb',
	// 	'#3dd2fb',
	// 	'#30cffc',
	// 	'#21b8e1',
	// 	'#00a3c9',
	// 	'#008eb1',
	// ] satisfies MantineColorsTuple,

	// error: virtualColor({
	// 	name: 'err2',
	// 	dark: _colors.error,
	// 	light: _colors.error,
	primary: _colors.primary,

	warning: _colors.warning,
	//  [
	// 	'#E6FCF5',
	// 	'#C3FAE8',
	// 	'#96F2D7',
	// 	'#63E6BE',
	// 	'#38D9A9',
	// 	'#20C997',
	// 	'#12B886',
	// 	'#0CA678',
	// 	'#099268',
	// 	'#087F5B',
	// ] satisfies MantineColorsTuple,

	// warn: [
	// 	'#fff7ed',
	// 	'#ffedd5',
	// 	'#fed7aa',
	// 	'#fdba74',
	// 	'#fb923c',
	// 	'#f97316',
	// 	'#ea580c',
	// 	'#c2410c',
	// 	'#9a3412',
	// 	'#7c2d12',
	// 	'#431407',
	// ] satisfies MantineColorsTuple,

	// white: colorsTuple(Array.from({ length: 10 }, (_) => '#FFFFFF')),
};

const virtualColors = {
	// intense: virtualColor({
	// 	dark: 'white',
	// 	light: 'dark',
	// 	name: 'intense',
	// }),
} as const;

const variantColorResolver: VariantColorsResolver = (input) => {
	const defaultResolvedColors = defaultVariantColorsResolver(input);

	// if (input.color === 'intense') {
	// 	return {
	// 		...defaultResolvedColors,
	// 		// color: defaultResolvedColors.color.includes('white')
	// 		// 	? 'var(--mantine-color-dark-0)'
	// 		// 	: 'var(--mantine-color-white-0)',
	// 	};
	// }

	// Override some properties for variant
	// if (parsedColor.isThemeColor && parsedColor.color === 'lime' && input.variant === 'filled') {
	//   return {
	//     ...defaultResolvedColors,
	//     color: 'var(--mantine-color-black)',
	//     hoverColor: 'var(--mantine-color-black)',
	//   };
	// }

	// // Completely override variant
	// if (input.variant === 'light') {
	//   return {
	//     background: rgba(parsedColor.value, 0.1),
	//     hover: rgba(parsedColor.value, 0.15),
	//     border: `${rem(1)} solid ${parsedColor.value}`,
	//     color: darken(parsedColor.value, 0.1),
	//   };
	// }

	// // Add new variants support
	// if (input.variant === 'danger') {
	//   return {
	//     background: 'var(--mantine-color-red-9)',
	//     hover: 'var(--mantine-color-red-8)',
	//     color: 'var(--mantine-color-white)',
	//     border: 'none',
	//   };
	// }

	return defaultResolvedColors;
};

// const getShadowColor = (alpha: number) => `rgba(255, 255, 255, ${alpha})`;

export const theme = createTheme({
	colors: {
		...colors,
		...virtualColors,
	},
	components: {
		Divider: {
			defaultProps: {},
			styles: {
				label: {
					fontSize: rem(12),
				},
			},
		},
		HoverCard: {
			defaultProps: {
				arrowSize: 12,
				middlewares: { flip: true, shift: { padding: 16 } },
				withArrow: true,
			},
		},
		Menu: {
			defaultProps: {
				middlewares: { flip: true, shift: { padding: 16 } },
			},
		},
		TextInput: {
			styles: {
				label: {
					opacity: 0.8,
					paddingBottom: rem(2),
					paddingLeft: rem(2),
				},
			},
		},
		Tooltip: {
			defaultProps: {
				openDelay: 1000,
				withArrow: true,
			},
		},
	},
	// breakpoints: {
	// 	/**
	// 	 * 36em / 576px
	// 	 */
	// 	xs: vars.breakpointXs,
	// 	/**
	// 	 * 48em / 768px
	// 	 */
	// 	sm: vars.breakpointSm,
	// 	/**
	// 	 * 62em / 992px
	// 	 */
	// 	md: vars.breakpointMd,
	// 	/**
	// 	 * 75em / 1200px
	// 	 * window tablet: 1280 x ~520 (not fullscreen, fullscreen ~ 1280 x 600 ?)
	// 	 */
	// 	lg: vars.breakpointLg,
	// 	/**
	// 	 * 88em / 1408px
	// 	 */
	// 	xl: vars.breakpointXl,
	// },

	defaultRadius: 'sm',

	fontFamily:
		'Mona Sans, -apple-system, "system-ui", "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
	fontFamilyMonospace: 'FrachterMono, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace',

	fontSizes: {
		lg: rem(16),
		md: rem(14),
		sm: rem(12),
		xl: rem(18),
		xs: rem(10),
	},

	headings: {
		//
	},

	primaryColor: 'primary',

	primaryShade: 7,

	radius: {
		lg: rem(12),
		md: rem(8),
		sm: rem(3),
		xl: rem(9999),
		xs: rem(1),
	},

	scale: 1,

	shadows: {
		clerk: `rgba(0, 0, 0, 0.08) 0px 5px 15px 0px, rgba(25, 28, 33, 0.2) 0px 15px 35px -5px;`,

		lg: `inset 0 0 0.6px 1.2px hsla(0, 0%, 100%, 0.075),
					0 0 0 1.2px hsla(0, 0%, 0%, 0.05),
					0 0.4px 0.5px hsla(0, 0%, 0%, 0.02),
					0 1.2px 2px hsla(0, 0%, 0%, 0.045),
					0 4.5px 8px hsla(0, 0%, 0%, 0.09)`,

		md: `inset 0 0 0.5px 1px hsla(0, 0%,  
					100%, 0.075),
					/* shadow ring 👇 */
					0 0 0 1px hsla(0, 0%, 0%, 0.05),
					/* multiple soft shadows 👇 */
					0 0.3px 0.4px hsla(0, 0%, 0%, 0.02),
					0 0.9px 1.5px hsla(0, 0%, 0%, 0.045),
					0 3.5px 6px hsla(0, 0%, 0%, 0.09)`,

		sm: `inset 0 0 0.4px 0.8px hsla(0, 0%, 100%, 0.075),
					0 0 0 0.8px hsla(0, 0%, 0%, 0.05),
					0 0.2px 0.3px hsla(0, 0%, 0%, 0.02),
					0 0.7px 1.2px hsla(0, 0%, 0%, 0.045),
					0 2.8px 4.5px hsla(0, 0%, 0%, 0.09)`,

		xl: `inset 0 0 0.75px 1.5px hsla(0, 0%, 100%, 0.075),
					0 0 0 1.5px hsla(0, 0%, 0%, 0.05),
					0 0.6px 0.8px hsla(0, 0%, 0%, 0.02),
					0 1.8px 3px hsla(0, 0%, 0%, 0.045),
					0 7px 12px hsla(0, 0%, 0%, 0.09)`,

		xs: `inset 0 0 0.25px 0.5px hsla(0, 0%, 100%, 0.075),
					0 0 0 0.5px hsla(0, 0%, 0%, 0.05),
					0 0.15px 0.2px hsla(0, 0%, 0%, 0.02),
					0 0.45px 0.75px hsla(0, 0%, 0%, 0.045),
					0 1.75px 3px hsla(0, 0%, 0%, 0.09)`,
	},

	variantColorResolver,
} satisfies MantineThemeOverride);

export const appTheme = mergeThemeOverrides(theme, {
	fontSizes: {
		lg: rem(18),
		md: rem(15),
		sm: rem(13),
		xl: rem(22),
		xs: rem(10),
	},
});
