import { createFileRoute } from '@tanstack/react-router';
import React from 'react';

import { MantineTest } from './MantineTest';

export const Route = createFileRoute('/playground/worker')({
	component: About,
});

function About() {
	const ref = React.useRef<boolean>(false);

	React.useEffect(() => {
		if (ref.current) return;
		ref.current = true;

		const myWorker = new Worker(new URL('../../workers/dummy-worker.ts', import.meta.url));

		myWorker.postMessage([111, 222]);
		console.log('Message posted to worker');

		myWorker.onmessage = (e) => {
			console.log('Message received from worker', e.data);
		};
		// throw new Error('uh oh');
	}, []);

	return <MantineTest />;
}
