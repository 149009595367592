import { Effect } from 'effect';
import { getCountryCodeAlpha2ForAlpha3 } from '../../../../constants/country-codes-map';
import { zPartnerOrderOrdersV4Fixed } from './schema';
export const normalizeShippingAddress = (raw, connection) => Effect.gen(function* () {
    const parsed = zPartnerOrderOrdersV4Fixed.safeParse(raw);
    if (!parsed.success) {
        return yield* Effect.dieMessage(`Failed to parse Otto order: ${parsed.error.message}`);
    }
    const address = parsed.data.deliveryAddress;
    if (!address) {
        // return yield* Effect.dieMessage(`No shipping address found in Otto order`);
        return null;
    }
    return {
        orderId: parsed.data.orderNumber,
        orderMarketId: connection.marketId,
        orderMerchantId: connection.merchantId,
        type: 'market',
        ...(address.addition ? { addition1: address.addition } : {}),
        city: address.city,
        // country: getCountryLabelForAlpha3Code(address.countryCode as any),
        countryCode: getCountryCodeAlpha2ForAlpha3(address.countryCode),
        ...(address.email ? { email: address.email } : {}),
        house: address.houseNumber,
        name1: `${address.title ?? ''} ${address.firstName ?? ''} ${address.lastName ?? ''}`.replace(/\s+/g, ' ').trim(),
        ...(address.phoneNumber && { phone: address.phoneNumber }),
        street: address.street,
        zip: address.zipCode,
    };
});
