import { ActionIcon, Tooltip } from '@mantine/core';
import { withTrackSignals } from '@preact-signals/safe-react/manual';
import { IconPrinter as IconPrintQueue } from '@tabler/icons-react';

import { useCloudprintQueuePanel } from '@/components/CloudprintQueue';

const OriginalCloudprintQueueButton: React.FC<{}> = () => {
	const { isVisible, toggle } = useCloudprintQueuePanel();

	return (
		<Tooltip label="Druckaufträge" position="bottom">
			<ActionIcon
				aria-label="Druckaufträge"
				color={isVisible ? 'cyan' : 'var(--mantine-color-text)'}
				onClick={toggle}
				variant="transparent"
			>
				<IconPrintQueue size={16} strokeWidth={1.7} />
			</ActionIcon>
		</Tooltip>
	);
};

export const CloudprintQueueButton = withTrackSignals(OriginalCloudprintQueueButton);
