import { createFileRoute, redirect } from '@tanstack/react-router';

import { Route as MarketIdMerchantIdRoute } from './~$marketId/~$merchantId/~index';
import { PageQuickship } from './PageQuickship';

export const Route = createFileRoute('/org/$slug/quickship')({
	beforeLoad: async ({ context, location, matches, params }) => {
		await context.frachter?.orgReady();

		const lastMatchId = matches.at(-1)?.routeId;

		const api = context.frachter;

		const markets = api?.getOrganizationAPI()?.$$markets.getSnapshot();

		const lastUsedMarket = context.lastUsedMarket;

		let defaultMarket: { marketId: string; merchantId: string } | undefined;

		if (lastUsedMarket) {
			const match = Object.values(markets ?? {}).find(
				(m) =>
					m.connection.marketId === lastUsedMarket.marketId && m.connection.merchantId === lastUsedMarket.merchantId,
			);

			if (match) {
				defaultMarket = match.connection;
			}
		}

		if (!defaultMarket && markets) {
			defaultMarket = Object.values(markets)?.at(0)?.connection;
		}

		if (lastMatchId === Route.id && defaultMarket) {
			console.log('REDIRECT');
			return redirect({
				params: {
					marketId: defaultMarket.marketId,
					merchantId: defaultMarket.merchantId,
					slug: params.slug,
				},
				to: '/org/$slug/quickship/$marketId/$merchantId',
			});

			// user tries to access `/quickship` directly
		} else if (lastMatchId === MarketIdMerchantIdRoute.id) {
			// TODO: do nothing here, let the page render and there show info if the market is available or not !
			// console.log('🟢🟢🟢 -> /quickship/$marketId/$merchantId');
		} else {
			// console.log('🔴🔴🔴 -> ???', lastMatchId);
		}
	},
	component: PageQuickship,
});
