import { electricSync } from '@electric-sql/pglite-sync';
import { live } from '@electric-sql/pglite/live';
import { PGliteWorker } from '@electric-sql/pglite/worker';
import { makeWatcher } from '@packages/lib/effect';
import { UserActivityService } from '@packages/lib/effect-services';
import { drizzle } from 'drizzle-orm/pglite';
import { Effect, Option, SubscriptionRef } from 'effect';
import Logger from 'js-logger';
import { connectProxy } from 'pg-browser-proxy';

import { dbs } from '@/drizzle/pglite';

import { AuthService } from '../../global-services/AuthService';
import { NotificationService } from '../../global-services/NotificationService';
import { BackendService } from '../BackendService';
import { OrganizationContextService } from '../OrganizationContextService';

import { makeShapes } from './electric/make-all-shapes';
import { WorkerCreationError } from './errors';
import { IPgliteMeta } from './types';

// Log messages will be written to the window's console.
// eslint-disable-next-line react-hooks/rules-of-hooks
Logger.useDefaults();

Logger.setLevel(Logger.DEBUG);

// const pgDialect = new PgDialect({ casing: 'camelCase' });

interface IInfo {
	// HINT: error does not really make sense here, because if the initialization fails, the effect itself will fail
	status: 'idle' | 'initializing' | 'ready' | 'closing' | 'error';
}

export type IDatabaseBroadcastMessage =
	| {
			action: 'ERROR';
	  }
	| { action: 'RESET' }
	| { action: 'RELOAD' };

export class DatabaseService extends Effect.Service<DatabaseService>()('DatabaseService', {
	scoped: Effect.gen(function* () {
		const organizationContextService = yield* OrganizationContextService;
		yield* Effect.logDebug('DatabaseService: START', organizationContextService.organizationId);

		const broadcastChannel = new BroadcastChannel(`frachter/pglite/${organizationContextService.organizationId}`);

		const authService = yield* AuthService;

		const notificationService = yield* NotificationService;

		const backendService = yield* BackendService;

		const userActivityService = yield* Effect.serviceOption(UserActivityService);

		/** ----------------------------------------------------------------------------------------------
		 * prepare store
		 * _______________________________________________________________________________________________ */
		const initialStore: IInfo = { status: 'initializing' };

		const $store = yield* makeWatcher<IInfo>(initialStore);

		/** ----------------------------------------------------------------------------------------------
		 * prepare worker
		 * _______________________________________________________________________________________________ */

		yield* Effect.logDebug('DatabaseService: setup worker', organizationContextService.organizationId);

		// TODO: for some reason, after like 6 hours, the worker is not being properly created anymore
		// it is being instantiated here but it does not show up as a worker in the browser dev console
		// under `top` contexts
		// we should test this with a production build as it could be that rspack kind of loses
		// the "file" of the worker and therefore PGLite
		const worker = new Worker(new URL(`./worker/worker-default.ts`, import.meta.url), {
			name: `pglite-worker-${organizationContextService.organizationId}`,
			type: 'module',
		});

		yield* Effect.logDebug('DatabaseService: create pgClient', organizationContextService.organizationId);

		const pgClient = yield* Effect.tryPromise({
			catch: (error) => {
				console.error('Could not create worker', error);

				return new WorkerCreationError({
					cause: error,
					message: 'Could not create worker',
				});
			},
			try: async () =>
				PGliteWorker.create(worker, {
					extensions: { electric: electricSync(), live },
					id: organizationContextService.organizationId,
					meta: { reset: true } satisfies IPgliteMeta,
				}),
		});

		const getBackendToken = authService.getBackendToken.pipe(
			Effect.provide(yield* Effect.context<OrganizationContextService>()),
		);

		yield* makeShapes({
			getAuthToken: getBackendToken,
			pg: pgClient,
			syncUrl: backendService.client.electric.sync.$url(),
			userActivityLatch: Option.getOrUndefined(userActivityService)?.latch,
		});

		// HINT: this creates a websocket connection to the proxy so that we are able to use regular postgres GUIs with the browser db
		// see package.json and drizzle.config.ts for more details
		// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
		const socket = connectProxy((message: any) => pgClient.execProtocolRaw(message), {
			wsPort: 453,
		});

		yield* Effect.logDebug('DatabaseService: create drizzle client', organizationContextService.organizationId);
		// @ts-expect-error this should work
		const drizzleClient = drizzle(pgClient, { casing: 'camelCase', schema: dbs });

		yield* Effect.logDebug('DatabaseService: update $store', organizationContextService.organizationId);
		yield* SubscriptionRef.update($store.ref, (prev) => ({ ...prev, status: 'ready' as const }));

		yield* Effect.logDebug('DatabaseService: add finalizer', organizationContextService.organizationId);
		yield* Effect.addFinalizer((exit) =>
			Effect.gen(function* () {
				socket.close();
				broadcastChannel.close();

				yield* Effect.logDebug('DatabaseService: CLOSING');
				yield* SubscriptionRef.update($store.ref, (prev) => ({ ...prev, status: 'closing' as const }));

				yield* Effect.promise(() => pgClient.close());

				try {
					worker.terminate();
					console.log('!!!! worker terminated');
				} catch (err) {
					console.error('!!!! Could not terminate worker', err);
				}

				// yield* Effect.logDebug('📀 close database');
				// yield* Effect.sleep(2000);
				// yield* Effect.logDebug('📀 database closed');

				yield* SubscriptionRef.update($store.ref, (prev) => ({ ...prev, status: 'idle' as const }));
				yield* Effect.logDebug('DatabaseService: CLOSED');
			}),
		);

		yield* Effect.logDebug('DatabaseService: READY', organizationContextService.organizationId);

		broadcastChannel.addEventListener('message', (event) => {
			const data = event.data as IDatabaseBroadcastMessage;

			if (data.action === 'ERROR') {
				Effect.runSync(
					SubscriptionRef.update($store.ref, (v) => {
						return { ...v, status: 'error' as const };
					}),
				);
			} else if (data.action === 'RELOAD') {
				window.location.reload();
			}
		});

		return {
			$store,
			drizzleClient,
			pgClient,
			reset() {
				broadcastChannel.postMessage({ action: 'RESET' } satisfies IDatabaseBroadcastMessage);
			},
			stats: {
				tables: async () => {
					return pgClient
						.query<{
							name: string;
							size: number;
						}>(/* sql */ `
							SELECT
								table_name as name,
								pg_relation_size (quote_ident (table_name)) AS size
							FROM
								information_schema.tables
							WHERE
								table_schema = 'public' -- Specify the schema if needed (default is 'public')
								AND table_type = 'BASE TABLE'
							ORDER BY
								pg_relation_size (quote_ident (table_name)) DESC;
						`)
						.then((res) => res.rows)
						.catch((err) => null);
				},
			},
		};
	}),
}) {}
