import { z } from 'zod';
import { printerSchema } from '../cloudprint';
import { ablySpaceMemberSchema, cloudprintRealtimeClientIdSchema, userRealtimeClientIdSchema } from './ably';
export const cloudprintPresenceMessageSchema = ablySpaceMemberSchema.extend({
    clientId: cloudprintRealtimeClientIdSchema,
    profileData: z.object({
        meta: z.object({
            hostname: z.string(),
        }),
        printers: z.record(z.string(), printerSchema),
    }),
});
export const CloudprintPresenceMessage = {};
export const userPresenceMessageSchema = ablySpaceMemberSchema.extend({
    clientId: userRealtimeClientIdSchema,
    profileData: z.object({
        meta: z.object({
            browser: z.enum(['opera', 'firefox', 'edge', 'yandex', 'vivaldi', 'chromium', 'safari', 'chrome', 'other']),
            device: z.enum(['desktop', 'mobile', 'tablet']),
            os: z.enum(['windows', 'macos', 'linux', 'other']),
        }),
    }),
});
