import { match } from 'ts-pattern';
import { z } from 'zod';
export var PRINTER_STATUS;
(function (PRINTER_STATUS) {
    PRINTER_STATUS["UNKNOWN"] = "UNKNOWN";
    PRINTER_STATUS["IDLE"] = "IDLE";
    PRINTER_STATUS["PRINTING"] = "PRINTING";
    PRINTER_STATUS["STOPPED"] = "STOPPED";
})(PRINTER_STATUS || (PRINTER_STATUS = {}));
export const printerSchema = z.object({
    displayName: z.string().describe('The name of the printer as displayed to the user.'),
    name: z.string().describe('The name of the printer as understood by the OS.'),
});
const printerStatusSchema = z.number().transform((num) => match(num)
    .with(3, () => PRINTER_STATUS.IDLE)
    .with(4, () => PRINTER_STATUS.PRINTING)
    .with(5, () => PRINTER_STATUS.STOPPED)
    .otherwise(() => PRINTER_STATUS.UNKNOWN));
export const printerWithStatusSchema = printerSchema.extend({
    status: printerStatusSchema,
});
/**
 * an additional schema to store the most recently selected printer
 * for this we not only need the printer name and displayName but also the clientId and hostname
 */
export const printerWithClientIdAndHostnameSchema = printerSchema.extend({
    clientId: z.string().describe('The ably clientId this printer belongs to.'),
    hostname: z.string().describe('The hostname of cloudprint client associated with this printer.'),
});
// TODO: continue from here (reuse those types in the messages !)
