import Spaces from '@ably/spaces';
import { messages } from '@packages/lib/schema/realtime';
import { Effect, Fiber, Scope, SubscriptionRef } from 'effect';
import { z } from 'zod';

import { getClientMeta } from '@/utils/browser-meta';

import { OrganizationContextService } from '../OrganizationContextService';

import { makeRealtimeLockApi } from './program-make-realtime-lock-api';
import {
	IMessageEnvelope,
	makeMessageStream,
	sendMessage,
	sendMessageAck,
	sendMessageAckSchema,
} from './program-make-realtime-message-api';
import { makeRealtimePresenceApi } from './program-make-realtime-presence-api';
import { makeRealtimeSpace } from './program-make-realtime-space';
import { RealtimeClientService } from './RealtimeClientService';
// import { makeSpace } from './space';

export class RealtimeService extends Effect.Service<RealtimeService>()('RealtimeService', {
	// dependencies: [RealtimeClientService.Default],
	scoped: Effect.gen(function* () {
		yield* Effect.logDebug('RealtimeService: START');

		const { organizationId: organizationId } = yield* OrganizationContextService;

		const realtimeClient = yield* RealtimeClientService;

		const scope = yield* Scope.make();

		yield* Effect.addFinalizer((exit) =>
			Effect.gen(function* () {
				yield* Effect.logDebug('RealtimeService: CLOSING');
				yield* Scope.close(scope, exit);
				yield* Effect.logDebug('RealtimeService: CLOSED');
			}),
		);

		yield* Effect.logDebug('RealtimeService: READY');

		const make = Effect.gen(function* () {
			yield* Effect.logDebug('RealtimeService: setup');

			yield* realtimeClient.connect();

			const spaces = new Spaces(realtimeClient.client);

			/** ----------------------------------------------------------------------------------------------
			 * Make spaces
			 * _______________________________________________________________________________________________ */

			const clientDataRef = yield* SubscriptionRef.make<messages.UserPresenceMessage['profileData']>({
				meta: getClientMeta(),
			});

			// TODO: test if we really need this
			yield* Effect.yieldNow();

			const { clientSpace, organizationSpace } = yield* Fiber.join(
				yield* Effect.fork(
					Effect.all(
						{
							clientSpace: makeRealtimeSpace(`org:${organizationId}:${realtimeClient.client.auth.clientId}`, spaces),
							organizationSpace: makeRealtimeSpace(`org:${organizationId}`, spaces, clientDataRef),
						},
						{ concurrency: 'unbounded' },
					),
				),
			);

			/** ----------------------------------------------------------------------------------------------
			 * Make APIs
			 * _______________________________________________________________________________________________ */

			const { lockApi, messageApi, presenceApi } = yield* Fiber.join(
				yield* Effect.fork(
					Effect.all(
						{
							lockApi: makeRealtimeLockApi(organizationSpace),
							// TODO: change to $clientMessages and sendMessage(clientId)
							messageApi: Effect.all({
								$clientChannelMessages: makeMessageStream(clientSpace.channel),
								sendMessage: Effect.succeed((envelope: IMessageEnvelope) =>
									sendMessage(envelope, organizationId).pipe(
										Effect.provideService(RealtimeClientService, realtimeClient),
									),
								),
								sendMessageAck: Effect.succeed(
									<TSchema extends z.ZodSchema>(envelope: IMessageEnvelope, replySchema: TSchema) =>
										sendMessageAck(envelope, organizationId, clientSpace, replySchema).pipe(
											Effect.provideService(RealtimeClientService, realtimeClient),
										),
								),
							}),
							presenceApi: makeRealtimePresenceApi(organizationSpace),
						},
						{ concurrency: 'unbounded' },
					),
				),
			);

			// createMessagingApi ?

			yield* Effect.logDebug('RealtimeService: setup completed');

			return {
				...lockApi,
				...presenceApi,
				...messageApi,
			};
		}).pipe(Effect.provideService(RealtimeClientService, realtimeClient), Scope.extend(scope));

		// let isInitialized = false;

		// /* wait for the auth session stream to have an organization and do NOT connect beforehand */
		// yield* Stream.runCollect(
		// 	Stream.takeWhile(auth.$session.stream, (session) => {
		// 		if (!isInitialized && session?.organization?.id === organizationId) {
		// 			isInitialized = true;
		// 			return false;
		// 		}
		// 		return true;
		// 	}),
		// );

		return yield* make;
	}),
}) {}
