import { Box, Modal, rem } from '@mantine/core';
import { createFileRoute } from '@tanstack/react-router';

import { SetupForm } from '../../../features/connections/dhl_parcel_de_shipping_post_parcel_germany_v2/setup-form';

export const Route = createFileRoute('/playground/components/dhl-form')({
	component: Page,
});

function Page() {
	return (
		<Box p="xl" style={{ display: 'grid', gap: rem(32), gridTemplateColumns: 'auto auto', justifyContent: 'center' }}>
			<Modal
				centered
				onClose={() => {}}
				opened={true}
				radius="md"
				styles={{ body: { height: 'clamp(200px, 75vh, 780px)', padding: 0 }, inner: { paddingBlock: rem(16) } }}
				withCloseButton={false}
			>
				<SetupForm />
			</Modal>
		</Box>
	);
}
