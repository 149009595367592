'use client';

import { useMutation, useQuery } from '@apollo/client';
import { Box, Button, Divider, Group, Modal, Stack, Text, Title } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { apis, getApiById } from '@packages/lib/api';
import { useRouter } from '@tanstack/react-router';
import { Effect } from 'effect';
import React from 'react';
import { match } from 'ts-pattern';

import { MarketProviderConfig } from '@/data/warehouse';
import { ConnectionSetupItemProps, ConnectionSetupList } from '@/features/connections/components';
import { ConnectionEstablishedList } from '@/features/connections/components';
import { getAuthToken } from '@/frachter/effect/programs/get-auth-token';
import { graphql } from '@/gql/graphql';

import { ValidateRef } from '../../~setup/~index';

export const MarketConnectionsListQuery = graphql(
	`
		query MarketConnectionsListQuery {
			marketConnections {
				id
				createdAt
				disabled
				displayName
				environment
				merchantId
				marketId
				market {
					description
					displayName
					id
				}
			}
		}
	`,
	[],
);

const MarketConnectionUpdate = graphql(`
	mutation MarketConnectionUpdate($displayName: String!, $marketId: String!, $merchantId: String!) {
		marketConnectionUpdate(displayName: $displayName, marketId: $marketId, merchantId: $merchantId) {
			displayName
			merchantId
			id
			marketId
			market {
				description
				displayName
				id
			}
		}
	}
`);

/**
 * upload here: https://dash.cloudflare.com/817f0f02a185b4610bb72ad8927ea199/r2/eu/buckets/frachter
 */
export default function SettingsMarketsPage({ validateRef }: { validateRef?: ValidateRef }) {
	const [isOpen, { close, toggle }] = useDisclosure(false);
	const { data, loading } = useQuery(MarketConnectionsListQuery, { fetchPolicy: 'cache-and-network' });
	const router = useRouter();

	const [mutate, _mutationResult] = useMutation(MarketConnectionUpdate);

	const handleClose = () => {
		close();
	};

	const validate = React.useCallback(() => {
		return Promise.resolve((data?.marketConnections.length ?? 0) > 0);
	}, [data?.marketConnections]);

	React.useImperativeHandle(validateRef, () => validate);

	const handleClick: ConnectionSetupItemProps['onClick'] = React.useCallback(
		async (id, environment) => {
			console.log('clicked', id, environment);

			await match(id)
				.with(apis.otto_market.id, async () => {
					/**
					 * Setup Flow
					 *
					 * 1. we fetch a custom clerk template token that contains the sub (userId), the orgId, orgRole and orgSlug
					 * 2. We open the `/connect/xyz` link with an optional `sandbox=1` parameter
					 * 3. on this connect page, we pre-validate the clerk JWT and check if we have the sandbox flag set
					 *    - if no, we show an input to enter the invitation JWT and then enable the connect button
					 *    - if yes, we directly show the enabled connect button (or straight redirect)
					 * 4. we send a POST request to the backend with the clerk JWT, the provider and the optional provider production JWT (and the sandobx flag)
					 * 5. the backend verifies the clerk JWT and then issues a redirect to the provider's connect page
					 * 6. from there on, the OAuth flow starts.
					 * 7. on success, we will publish a GraphQL subscription message of `settings/organization` type, which should refetch the data on the client
					 * 8. and we redirect back to the `/connect/xyz` page together with a status query params of either `success` or `error`
					 */

					const token = await Effect.runPromise(getAuthToken('connect'));

					const to = router.buildLocation({
						params: {
							apiId: apis.otto_market.id,
						},
						search: {
							...(environment === 'sandbox' && { sandbox: true }),
							...(token && { token }),
						},
						to: '/connect/$apiId',
					}).href;

					window.open(to, 'frachter_connect');
				})
				.otherwise(() => {
					console.log('not implemented');
				});
		},
		[router],
	);

	return (
		<>
			<Stack gap="lg" py="md">
				<Group justify="space-between">
					<Title order={3}>Marktplätze</Title>
				</Group>
				<Text size="md">
					Hier verwaltest Du Marktplätze, die sich mit Frachter verbinden lassen, um darüber Bestellungen zu
					importieren, Bestellungen zu überwachen und den Bestellstatus sowie Tracking-Nummern zurück an die Marktplätze
					zu senden. Das macht die Verwaltung für dich einfach und effizient.
				</Text>

				<Divider my="md" />

				<Group justify="space-between">
					<Title order={5}>Marktplatz hinzufügen</Title>
					<Button display={'none'} onClick={toggle} size="sm" variant="light">
						Mehr Marktplätze entdecken
					</Button>
				</Group>

				<ConnectionSetupList>
					<ConnectionSetupList.Item
						environment="production"
						id={apis.otto_market.id}
						label={`Otto`}
						logo={<MarketProviderConfig.otto.LogoSquare />}
						onClick={handleClick}
						subtitle={'Otto Marktplatz'}
					/>

					<ConnectionSetupList.Item
						environment="sandbox"
						id={apis.otto_market.id}
						label={`Otto`}
						logo={<MarketProviderConfig.otto.LogoSquare />}
						onClick={handleClick}
						subtitle={'Otto Marktplatz'}
					/>
					{/* <ConnectionSetupList.Item
						disabled
						id={MarketProviderConfig.amazon_market.name}
						label={`${MarketProviderConfig.amazon_market.name}`}
						logo={<MarketProviderConfig.amazon_market.LogoSquare />}
						onClick={handleClick}
					/> */}
					{/* <ConnectionSetupList.Item
						disabled
						id={MarketProviderConfig.kaufland_market.name}
						label={`${MarketProviderConfig.kaufland_market.name}`}
						logo={<MarketProviderConfig.kaufland_market.LogoSquare />}
						onClick={handleClick}
					/> */}
					{/* <ConnectionSetupList.Item
						disabled
						id={MarketProviderConfig.zalando_market.name}
						label={`${MarketProviderConfig.zalando_market.name}`}
						logo={<MarketProviderConfig.zalando_market.LogoSquare />}
						onClick={handleClick}
					/> */}
				</ConnectionSetupList>

				<Group justify="space-between" mt="xl">
					<Title order={5}>Verbundene Marktplätze</Title>
				</Group>

				<ConnectionEstablishedList loading={loading} subject="market">
					{data?.marketConnections.map((v) => {
						const providerConfig = MarketProviderConfig[v.marketId as unknown as keyof typeof MarketProviderConfig];

						if (!providerConfig) {
							throw new Error(`Provider config not found for market ${v.marketId}`);
						}

						const apiSpec = getApiById(v.marketId as any);

						if (!apiSpec) {
							throw new Error(`API spec not found for market ${v.marketId}`);
						}

						console.log('v', v);

						return (
							<ConnectionEstablishedList.Item
								key={v.id}
								createdAt={v.createdAt}
								description={`${apiSpec.displayName} Marktplatz`}
								disabled={v.disabled}
								displayName={v.displayName}
								environment={v.environment}
								id={v.id}
								logo={<providerConfig.LogoSquare />}
								name={apiSpec.displayName}
								onChangeDisplayName={(displayName) =>
									mutate({ variables: { displayName, marketId: v.marketId, merchantId: v.merchantId } })
								}
							>
								{v.marketId === apis.otto_market.id && (
									<ConnectionEstablishedList.Item.Detail label="Partner-ID" value={v.merchantId} />
								)}
							</ConnectionEstablishedList.Item>
						);
					})}
				</ConnectionEstablishedList>
			</Stack>

			{/* HINT: this is for markets that do not require an external browser window to be opened */}
			<Modal
				centered
				onClose={handleClose}
				opened={isOpen}
				radius="lg"
				size="lg"
				title="Marktplatz verbinden"
				transitionProps={{ transition: 'slide-up' }}
			>
				<Box p="md">
					{/* <SetupMarketDialog noPaper onComplete={() => console.log('TODO')} provider={marketProvider} /> */}
				</Box>
			</Modal>
		</>
	);
}
