'use client';

import { ComboboxData, Group, Select } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import React from 'react';
import { useDeepCompareEffect } from 'use-deep-compare';

import { constants } from '@/data/constants';
import { useCloudprint } from '@/frachter/hooks/use-cloudprint';
import { useOrganizationStore } from '@/stores/organization-store';

import { CloudprintQueueButton } from '../CloudprintQueueButton';

import s from './RealtimePrinterSelect.module.scss';

export const RealtimePrinterSelect: React.FC<{ hideQueueButton?: boolean }> = ({ hideQueueButton }) => {
	const printers = useCloudprint();

	const clientDevice = useOrganizationStore((s) => s.cloudprintClientDevice);
	const setClientDevice = useOrganizationStore((s) => s.setCloudprintClientDevice);

	useDeepCompareEffect(
		function ensureSelectedPrinterIsInList() {
			if (
				clientDevice.current &&
				!printers.list.find(
					(p) => p.clientId === clientDevice.current?.clientId && p.name === clientDevice.current?.name,
				)
			) {
				notifications.show({
					autoClose: false,
					color: 'warning',
					id: constants.NOTIFICATION_ID_PRINTER_GONE,
					message:
						'Dein gewählter Drucker ist nicht mehr verfügbar. Bitte wähle einen anderen Drucker aus oder prüfe die Verbindung zu CloudPrint.',
					title: 'Druckerverbindung unterbrochen',
				});
				setClientDevice({ current: null });
			} else if (
				!clientDevice.current &&
				clientDevice.selected &&
				printers.list.find(
					(p) => p.clientId === clientDevice.selected?.clientId && p.name === clientDevice.selected?.name,
				)
			) {
				notifications.hide(constants.NOTIFICATION_ID_PRINTER_GONE);
				setClientDevice({ current: clientDevice.selected });
			}
		},
		[printers, clientDevice, setClientDevice],
	);

	const handleChange = (value: string | null) => {
		if (value) {
			const [clientId, name] = value.split('|');
			const printer = printers.list.find((v) => v.clientId === clientId && v.name === name);

			if (printer) {
				setClientDevice({
					current: printer,
					selected: printer,
				});
			}
		}
	};

	const data: ComboboxData = React.useMemo(
		() =>
			printers.byClient
				.filter((v) => v.isOnline)
				.map((group) => ({
					group: group.hostname!,
					items: group.printers.map((p) => ({
						label: p.displayName,
						value: `${p.clientId}|${p.name}`,
					})),
				})),
		[printers.byClient],
	);

	return (
		<Group gap="xs">
			<Select
				allowDeselect={false}
				checkIconPosition="right"
				comboboxProps={{ radius: 'md', transitionProps: { duration: 200, transition: 'pop' } }}
				data={data}
				nothingFoundMessage="Keine Drucker verfügbar"
				onChange={handleChange}
				placeholder="Drucker auswählen"
				pointer
				radius="md"
				value={clientDevice.current ? `${clientDevice.current?.clientId}|${clientDevice.current?.name}` : null}
				classNames={{
					dropdown: s.select,
				}}
				styles={{
					input: { fontSize: 11, textOverflow: 'ellipsis' },
					option: { fontSize: 11 },
				}}
			/>
			{!hideQueueButton && <CloudprintQueueButton />}
		</Group>
	);
};
