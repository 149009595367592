import { WarehouseService } from '@packages/lib/effect-services/warehouse-frontend';
import { Effect, Logger, LogLevel } from 'effect';

import { IOrganizationAPI } from '@/frachter/types';

import { BackendService } from '../BackendService';
import { DatabaseService } from '../DatabaseService';
import { RealtimeService } from '../RealtimeService';

import { CloudprintService } from './CloudprintApiService';

export class ApiService extends Effect.Service<ApiService>()('ApiService', {
	scoped: Effect.gen(function* () {
		const realtime = yield* RealtimeService;
		const backend = yield* BackendService;
		const database = yield* DatabaseService;
		const warehouse = yield* WarehouseService;

		const cloudprint = yield* CloudprintService;

		return {
			/* double $$ means it's a store ready to be used with useSyncExternalStore */
			// TODO: maybe use {locks: {acquire, unlock, $store}}
			$$locks: realtime.$locks.store,
			$$markets: warehouse.$markets.store,
			$$realtimeMembers: realtime.$members.store,
			backend,
			cloudprint,
			database,
			requestLock: async (id, options) => {
				const result = await Effect.runPromise(
					realtime
						.requestLock(id, options)
						.pipe(Effect.merge, Logger.withMinimumLogLevel(LogLevel.Debug), Effect.provide(Logger.pretty)),
				);
				return result;
			},
			unlock: async (id) => {
				const result = await Effect.runPromise(
					realtime
						.unlock(id)
						.pipe(Effect.merge, Logger.withMinimumLogLevel(LogLevel.Debug), Effect.provide(Logger.pretty)),
				);
				return result;
			},
		} satisfies IOrganizationAPI;
	}),
}) {}
