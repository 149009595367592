import { Effect } from 'effect';
import { apis } from '../../../../api/spec';
import { WarehouseMarketService } from '../../../../effect-services/warehouse/services';
import { notImplemented } from '../../../../effect-services/warehouse/utils';
export class MarketServiceGuteMarken extends Effect.Service()(`frachter/market/${apis.gute_marken.id}`, {
    effect: Effect.gen(function* () {
        return {
            hello: Effect.succeed('hello'),
            warehouse: WarehouseMarketService.of({
                capabilities: new Set([]),
                labelRequirements: { returnShippingLabel: false },
                makeSynchronizeOrders: notImplemented,
                marketId: apis.gute_marken.id,
                normalizeLineItems: (raw) => Effect.succeed([{ id: 'amazon-lineitem-1' }]),
                normalizeOrder: (raw) => Effect.succeed({ raw }),
                normalizeShippingAddress: notImplemented,
                prepareFulfillOrderPayload: notImplemented,
                supportedCarrierIds: new Set([
                    apis.dhl_parcel_de_returns_post_parcel_germany_v1.id,
                    apis.dhl_parcel_de_shipping_post_parcel_germany_v2.id,
                    apis.gls_shipit.id,
                ]),
            }),
        };
    }),
}) {
}
