'use no memo';

import type { MRT_TableOptions } from 'mantine-react-table';

import { Button, Group, Text } from '@mantine/core';
import { useMutationState } from '@tanstack/react-query';
import React from 'react';

import type { IRowType } from './types';

import { useProductsTableStore } from './store';

export const TopToolbarCustomActions: MRT_TableOptions<IRowType>['renderTopToolbarCustomActions'] = ({ table }) => {
	return (
		<Button
			onClick={() => {
				table.setCreatingRow(true); //simplest way to open the create row modal with no default values
				//or you can pass in a row object to set default values with the `createRow` helper function
				// table.setCreatingRow(
				//   createRow(table, {
				//     //optionally pass in default values for the new row, useful for nested data or other complex scenarios
				//   }),
				// );
			}}
		>
			Create New User
		</Button>
	);
};

export const BottomToolbarCustomActions: MRT_TableOptions<IRowType>['renderBottomToolbarCustomActions'] = ({
	table,
}) => {
	console.log('render toolbar');

	const editedRows = useProductsTableStore((s) => s.editedRows);
	const validationErrors = useProductsTableStore((s) => s.validationErrors);
	const resetEditedRows = useProductsTableStore((s) => s.resetEditedRows);
	const numSelectedRows = table.getSelectedRowModel().rows.length;

	//UPDATE action
	// eslint-disable-next-line @typescript-eslint/require-await
	const handleSaveUsers = React.useCallback(async () => {
		if (Object.values(validationErrors).some((error) => !!error)) return;
		console.log('handleSaveUsers');
		// TODO: restore this (get previous values and merge with new values)
		// await updateRows(Object.values(editedRows));
		resetEditedRows();
	}, [validationErrors, resetEditedRows]);

	const [isUpdatingRowStatus] = useMutationState({
		filters: { mutationKey: ['products:updateRows'] },
		select: (mutation) => mutation.state.status,
	});

	console.log('isUpdatingRowStatus', isUpdatingRowStatus);

	return (
		<Group px="xs">
			<Text>
				{numSelectedRows + ' '}
				{numSelectedRows === 1 ? 'Produkt' : 'Produkte'} ausgewählt
			</Text>
			<Button
				color="blue"
				disabled={Object.keys(editedRows).length === 0 || Object.values(validationErrors).some((error) => !!error)}
				loading={isUpdatingRowStatus === 'pending'}
				onClick={() => void handleSaveUsers()}
			>
				Save
			</Button>
		</Group>
	);
};
