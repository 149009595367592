/* eslint-disable perfectionist/sort-objects */
import type {
	ColumnFiltersState,
	ExpandedState,
	PaginationState,
	RowSelectionState,
	SortingState,
} from '@tanstack/react-table';

import React from 'react';
import { createStore, useStore } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import type { IBaseTableData } from './types';

export declare namespace TableStore {
	interface State<TRowType extends IBaseTableData> {
		columnFilters: ColumnFiltersState;
		editedRows: Record<string, Partial<TRowType>>;
		enableRowSelection: boolean;
		expanded: ExpandedState;
		isFetching: boolean;
		pagination: PaginationState;
		rowSelection: RowSelectionState;
		rowSelectionStatus: 'none' | 'some' | 'all';
		/**
		 * for any id it will be
		 * - undefined (initial or third click)
		 * - desc: false (first click)
		 * - desc: true (second click)
		 */
		sorting: SortingState;
		totalRowCount: number;
		validationErrors: Record<string, string | undefined>;
	}

	interface Actions<TRowType extends IBaseTableData> {
		resetEditedRows: () => void;
		resetValidationErrors: () => void;
		setColumnFilters: (columnFilters: ColumnFiltersState) => void;
		setEditedRow: (row: TRowType) => void;
		setEditedRowValue: (id: string, value: Partial<TRowType>) => void;
		setEnableRowSelection: (enable: boolean) => void;
		setExpanded: (expanded: ExpandedState) => void;
		setIsFetching: (isFetching: boolean) => void;
		setPagination: (value: Partial<PaginationState>) => void;
		setRowSelection: (rows: RowSelectionState) => void;
		setRowSelectionStatus: (rowSelectionStatus: 'none' | 'some' | 'all') => void;
		setSortingState: (value: SortingState) => void;
		setTotalRowCount: (totalRowCount: number) => void;
		setValidationError: (id: string, error: string | undefined) => void;
		setValidationErrors: (errors: Record<string, string | undefined>) => void;
	}
}

const createTableStore = <TRowType extends IBaseTableData>(
	initialState?: Partial<TableStore.State<TRowType>>,
	storeId?: string,
) => {
	return createStore<TableStore.State<TRowType> & TableStore.Actions<TRowType>>()(
		devtools(
			immer((set) => ({
				columnFilters: [],
				setColumnFilters: (columnFilters: ColumnFiltersState) => set({ columnFilters }),
				expanded: {},
				setExpanded: (expanded: ExpandedState) => set({ expanded }),
				totalRowCount: 0,
				setTotalRowCount: (totalRowCount: number) => set({ totalRowCount }),
				sorting: [],
				setSortingState: (value: SortingState) => set({ sorting: value }),
				rowSelectionStatus: 'none',
				setRowSelectionStatus: (rowSelectionStatus: 'none' | 'some' | 'all') => set({ rowSelectionStatus }),
				enableRowSelection: true,
				setEnableRowSelection: (enable: boolean) => set({ enableRowSelection: enable }),
				isFetching: true,

				editedRows: {},

				pagination: {
					pageIndex: -1,
					pageSize: -1,
				},

				resetEditedRows: () => set({ editedRows: {} }),

				resetValidationErrors: () =>
					set((state) => {
						state.validationErrors = {};
					}),
				rowSelection: {},

				setEditedRow: (row) =>
					set((state) => {
						state.editedRows[row.id] = row as any;
					}),

				setEditedRowValue: (id, value) =>
					set((state) => {
						state.editedRows[id] = value as any;
					}),

				setIsFetching: (isFetching: boolean) => set({ isFetching }),

				setPagination: (value) =>
					set((state) => {
						if (value.pageIndex !== undefined) {
							state.pagination.pageIndex = value.pageIndex;
						}
						if (value.pageSize !== undefined) {
							state.pagination.pageSize = value.pageSize;
						}
					}),

				setRowSelection: (rows) =>
					set((state) => {
						state.rowSelection = rows;
					}),

				setValidationError: (id, error) =>
					set((state) => {
						state.validationErrors[id] = error;
					}),

				setValidationErrors: (errors) =>
					set((state) => {
						state.validationErrors = errors;
					}),

				validationErrors: {},

				...initialState,
			})),
			{ name: 'frachter', store: `table-${storeId}` },
		),
	);
};

export const TableStoreContext = React.createContext<ReturnType<typeof createTableStore> | null>(null);

export function TableStoreProvider<TRowType extends IBaseTableData>({
	children,
	initialState,
	storeId,
}: {
	children: React.ReactNode;
	initialState?: Partial<TableStore.State<TRowType>>;
	storeId?: string;
}) {
	const randomId = React.useId();

	const [store] = React.useState(() => createTableStore(initialState, storeId ?? randomId));

	return <TableStoreContext.Provider value={store as any}>{children}</TableStoreContext.Provider>;
}

// Base hook that works with 'any' type
export function useTableStore<U>(selector: (state: TableStore.State<any> & TableStore.Actions<any>) => U) {
	const store = React.useContext(TableStoreContext);

	if (!store) {
		throw new Error('useTableStore must be used within a TableStoreProvider');
	}

	return useStore(store, selector);
}

// Factory function that creates a typed hook
export function createTypedTableStoreHook<TRowType extends IBaseTableData>() {
	return function useTypedTableStore<U>(
		selector: (state: TableStore.State<TRowType> & TableStore.Actions<TRowType>) => U,
	): U {
		// Use the base hook but preserve the type information
		const result = useTableStore((state) =>
			selector(state as TableStore.State<TRowType> & TableStore.Actions<TRowType>),
		);
		return result;
	};
}
