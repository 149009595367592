import { useVirtualizer } from '@tanstack/react-virtual';
import React from 'react';

// @ts-expect-error - it works but ts complains. Will hopefully be fixed by @tanstack or react https://github.com/TanStack/virtual/issues/736
const useVirtualizerWithoutReactCompiler: typeof useVirtualizer = (...args) => {
	// eslint-disable-next-line react-compiler/react-compiler
	'use no memo';
	return { ...useVirtualizer(...args) } as unknown as ReturnType<typeof useVirtualizer>;
};

export const CloudprintQueueList: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
	const parentRef = React.useRef<HTMLDivElement>(null);
	const childrenArray = React.Children.toArray(children);

	// The virtualizer

	const rowVirtualizer = useVirtualizerWithoutReactCompiler<HTMLDivElement, HTMLDivElement>({
		count: childrenArray.length,
		estimateSize: () => 95,
		getScrollElement: () => parentRef.current,
		horizontal: false,

		// initialRect: parentRef.current?.getBoundingClientRect() ?? new DOMRect(),
	});

	// console.log('render CloudprintQueueList');

	// React.useLayoutEffect(() => {
	// 	const interval = setInterval(() => {
	// 		if (rowVirtualizer.getTotalSize() > 0) {
	// 			clearInterval(interval);
	// 			rowVirtualizer?.measure?.();
	// 		}
	// 	}, 500);

	// 	return () => clearInterval(interval);
	// }, [rowVirtualizer]);

	const items = rowVirtualizer.getVirtualItems().map((virtualItem) => {
		return (
			<div
				key={virtualItem.key}
				ref={rowVirtualizer.measureElement}
				data-index={virtualItem.index}
				style={{
					backgroundColor:
						virtualItem.index % 2 === 0
							? 'color-mix(in srgb, var(--mantine-color-text) 4%, transparent)'
							: 'transparent',
					left: 0,
					position: 'absolute',
					top: 0,
					transform: `translateY(${virtualItem.start}px)`,
					width: '100%',
				}}
			>
				{childrenArray[virtualItem.index]}
			</div>
		);
	});

	return (
		<div
			ref={parentRef}
			style={{
				inset: 0,
				overflow: 'auto', // Make it scroll!
				position: 'absolute',
			}}
		>
			{/* The large inner element to hold all of the items */}
			<div
				style={{
					height: `${rowVirtualizer.getTotalSize()}px`,
					position: 'relative',
					width: '100%',
				}}
			>
				{items}
			</div>
		</div>
	);
};
