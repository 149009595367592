'use client';

import { Box } from '@mantine/core';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';

import { useAppLayoutPageVariant } from '../app/AppLayoutContext';

import s from './OrganizationSideBar.module.scss';
import { SideBarApps } from './SideBarApps';
import { SideBarSettings } from './SideBarSettings';

export const OrganizationSideBar: React.FC<{}> = () => {
	const pageVariant = useAppLayoutPageVariant();

	return (
		<Box className={clsx(s.root, pageVariant === 'apps' && s.withApps, pageVariant === 'settings' && s.withSettings)}>
			<AnimatePresence>
				{/* WARN: do not unmount apps sidebar as this also contains controls for auth and database! */}
				<MotionSideBar key="apps" visible={pageVariant === 'apps'}>
					<SideBarApps />
				</MotionSideBar>
				{pageVariant === 'settings' && (
					<MotionSideBar key="settings" visible={pageVariant === 'settings'}>
						<SideBarSettings />
					</MotionSideBar>
				)}
			</AnimatePresence>
		</Box>
	);
};

const MotionSideBar: React.FC<React.PropsWithChildren<{ visible?: boolean }>> = ({ children, visible }) => {
	return (
		<Box
			className={clsx(s.motionSideBar, !visible && s.inaccessible)}
			component={motion.div}
			exit={{ opacity: 0, x: '-200%' }}
			/* inert = true prevents the sidebar from being focused when it's not visible */
			inert={visible ? false : true}
			initial={{ opacity: 0, x: '-100%' }}
			animate={{
				opacity: visible ? 1 : 0,
				pointerEvents: visible ? 'all' : 'none',
				tabIndex: visible ? 0 : -1,
				transition: { duration: 0.15, ease: 'easeInOut' },
				x: visible ? 0 : '-100%',
				zIndex: visible ? 1 : 0,
			}}
		>
			{children}
		</Box>
	);
};
