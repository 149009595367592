export const body = {
    consignee: {
        additionalAddressInformation1: 'Apartment 107',
        addressStreet: 'Kurt-Schumacher-Str. 20',
        city: 'Bonn',
        country: 'DEU',
        email: 'maria@musterfrau.de',
        name1: 'Maria Musterfrau',
        phone: '+49 987654321',
        postalCode: '53113',
    },
    consigneeInternational: {
        addressStreet: 'Street 42',
        city: 'Sofia',
        country: 'BGR',
        name1: 'Joe Black',
        postalCode: '1000',
    },
    details: {
        dim: {
            height: 100,
            length: 200,
            uom: 'mm',
            width: 150,
        },
        weight: {
            uom: 'g',
            value: 500,
        },
    },
    detailsWarenpost: {
        dim: {
            height: 1,
            length: 10,
            uom: 'cm',
            width: 15,
        },
        weight: {
            uom: 'g',
            value: 500,
        },
    },
    profile: 'STANDARD_GRUPPENPROFIL',
    returnAddress: {
        addressStreet: 'Frankenstraße 298',
        city: 'Essen',
        country: 'DEU',
        name1: 'My Online Shop GmbH',
        name2: 'Retourenlager',
        postalCode: '45133',
    },
    shipper: {
        additionalAddressInformation1: '2. Etage',
        addressStreet: 'Sträßchensweg 10',
        city: 'Bonn',
        country: 'DEU',
        email: 'max@mustermann.de',
        name1: 'My Online Shop GmbH',
        phone: '+49 123456789',
        postalCode: '53113',
    },
};
