import { Effect, Stream } from 'effect';

import type { IMakeShapeStreamOptions } from './types';

import { NotificationService } from '@/frachter/effect/global-services/NotificationService';

import { makeContinuousShape } from './make-continuous-shape';
import { makeShape } from './make-shape';

/**
 * We have
 * - a persistent shape for products table
 * - a persistent shape for KV table
 * - two shapes (previous, current) for market_orders table
 * - two shapes (previous, current) for carrier_labels table
 */
export const makeShapes = (
	options: Pick<IMakeShapeStreamOptions, 'getAuthToken' | 'pg' | 'syncUrl' | 'userActivityLatch'>,
) =>
	Effect.gen(function* () {
		const notificationService = yield* NotificationService;

		const syncUrl = new URL(`https://backend.frachter.app/electric/sync`);
		// const syncUrl = options.syncUrl;

		// we need
		// a program that runs continously and will check if all shapes are what we expect
		// this means checking the current time and maybe switching previous and current shapes

		const sharedOptions = {
			getAuthToken: options.getAuthToken,
			pg: options.pg,
			syncUrl,
			userActivityLatch: options.userActivityLatch,
		};

		const { isSynchronizedRef: marketOrdersIsSynchronizedRef } = yield* makeContinuousShape({
			...sharedOptions,
			onIsSynchronizedChanged: (isLive) => {
				console.log('isLive', isLive);
			},
			primaryKey: ['market_id', 'merchant_id', 'order_id'],
			table: 'market_orders',
		});

		const { isSynchronizedRef: carrierLabelsIsSynchronizedRef } = yield* makeContinuousShape({
			...sharedOptions,
			onIsSynchronizedChanged: (isLive) => {
				console.log('isLive', isLive);
			},
			primaryKey: ['id'],
			table: 'carrier_labels',
		});

		const { isSynchonizedRef: productsIsSynchronizedRef } = yield* makeShape({
			...sharedOptions,
			primaryKey: ['id'],
			table: 'products',
		});

		const marketOrdersNotification = notificationService.databaseSyncProgress({
			table: {
				label: 'Bestellungen',
				name: 'market_orders',
			},
		});

		const carrierLabelsNotification = notificationService.databaseSyncProgress({
			table: {
				label: 'Versandlabels',
				name: 'carrier_labels',
			},
		});

		const productsNotification = notificationService.databaseSyncProgress({
			table: {
				label: 'Produkte',
				name: 'products',
			},
		});

		yield* Effect.forkScoped(
			marketOrdersIsSynchronizedRef.changes.pipe(
				Stream.changes,
				Stream.runForEach((isSynchronized) => {
					if (isSynchronized) {
						console.log('🟢🟢🟢🟢🟢 market ordersis synchronized', isSynchronized);
						marketOrdersNotification.hide();
					} else {
						marketOrdersNotification.show();
					}
					return Effect.void;
				}),
			),
		);

		yield* Effect.forkScoped(
			carrierLabelsIsSynchronizedRef.changes.pipe(
				Stream.changes,
				Stream.runForEach((isSynchronized) => {
					if (isSynchronized) {
						console.log('🟢🟢🟢🟢🟢 carrier lables is synchronized', isSynchronized);
						carrierLabelsNotification.hide();
					} else {
						carrierLabelsNotification.show();
					}
					return Effect.void;
				}),
			),
		);

		yield* Effect.forkScoped(
			productsIsSynchronizedRef.changes.pipe(
				Stream.changes,
				Stream.runForEach((isSynchronized) => {
					if (isSynchronized) {
						console.log('🟢🟢🟢🟢🟢 products is synchronized', isSynchronized);
						productsNotification.hide();
					} else {
						productsNotification.show();
					}
					return Effect.void;
				}),
			),
		);
	});
