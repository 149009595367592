import { useRouterState } from '@tanstack/react-router';
import React from 'react';

import { useOrganizationStore } from '@/stores/organization-store';

export const useCloudprintQueuePanel = () => {
	const isVisible = useOrganizationStore((s) => s.isCloudprintQueueVisible);
	const setIsVisible = useOrganizationStore((s) => s.setIsCloudprintQueueVisible);

	const previewItem = useOrganizationStore((s) => s.cloudprintQueuePreviewItem);
	const setPreviewItem = useOrganizationStore((s) => s.setCloudprintQueuePreviewItem);

	const routerState = useRouterState();

	const pathname = routerState.location.pathname;

	const [pathnameWhenOpen, setPathnameWhenOpen] = React.useState<string | null>(null);

	// TODO: improve this: we should keep track of the URL history in redux (~ last 100 URLs) and when the path changes, cloudprint should be hidden
	React.useEffect(() => {
		if (isVisible && !pathnameWhenOpen) {
			setPathnameWhenOpen(pathname);
		}
	}, [isVisible, pathname, pathnameWhenOpen]);

	React.useEffect(() => {
		if (isVisible && pathnameWhenOpen && pathname !== pathnameWhenOpen) {
			setIsVisible(false);
		}
	}, [isVisible, pathnameWhenOpen, pathname, setIsVisible]);

	return {
		hide: () => setIsVisible(false),
		isVisible,
		preview: {
			hide: () => setPreviewItem(null),
			isVisible: previewItem !== null,
			item: previewItem,
			setItem: setPreviewItem,
		},
		show: () => setIsVisible(true),
		toggle: () => setIsVisible(!isVisible),
	};
};
