import type { PaginationState } from '@tanstack/react-table';

import {
	keepPreviousData,
	useQuery,
	type DefaultError,
	type QueryKey,
	type UseQueryOptions,
} from '@tanstack/react-query';

/**
 * Helper around useQuery that provides a paginated query key
 * which does NOT include the pagination state!
 *
 * And it uses placeholderData to avoid 0 rows flash while changing pages/loading next page
 *
 * otherwise, the pagination would reset when we paginate
 */
export const usePaginatedQuery = <
	TQueryFnData = unknown,
	TError = DefaultError,
	TData = TQueryFnData,
	TQueryKey extends QueryKey = QueryKey,
>({
	pageIndex,
	pageSize,
	...options
}: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey> & PaginationState) => {
	const query = useQuery<TQueryFnData, TError, TData, TQueryKey>({
		...options,
		placeholderData: keepPreviousData, // don't have 0 rows flash while changing pages/loading next page
		queryKey: [...options.queryKey, pageSize, pageIndex] as unknown as TQueryKey,
	});

	return {
		paginatedQueryKey: [...options.queryKey],
		query,
	};
};
