import { Box, Paper, Stack, Text, Title } from '@mantine/core';
import { useNavigate } from '@tanstack/react-router';
import React from 'react';

import { MonoButton } from '../ui/MonoButton';

import s from './unauthorized-dialog.module.scss';

export const UnauthorizedDialog: React.FC<{
	isHidden?: boolean;
	title?: React.ReactNode;
	subtitle?: React.ReactNode;
	description?: React.ReactNode;
	buttonText?: React.ReactNode;
	onClick?: () => void;
}> = ({
	buttonText = 'Zurück zu frachter.app',
	description = 'Bitte wende Dich an den Administrator Deiner Organisation, um Deine Zugriffsrechte und Rollen zu überprüfen und versuche es erneut.',
	isHidden,
	onClick,
	subtitle = 'Dein Benutzer-Account verfügt nicht über die nötigen Rechte, um auf diesen Bereich zuzugreifen.',
	title = 'Fehlende Berechtigung',
}) => {
	const navigate = useNavigate();

	return isHidden ? null : (
		<Box className={s.root}>
			<Paper maw={500} p="xl" radius="lg" shadow="xs">
				<Stack gap="xl">
					<Stack gap="xs">
						<Title order={3}>{title}</Title>
						{subtitle && <Text c="dimmed">{subtitle}</Text>}
					</Stack>
					<Stack>
						{description && <Text>{description}</Text>}
						<MonoButton mt="lg" onClick={() => (onClick ? onClick() : void navigate({ to: '/' }))}>
							{buttonText}
						</MonoButton>
					</Stack>
				</Stack>
			</Paper>
		</Box>
	);
};
