import { useQuery } from '@apollo/client';
// import { DevTool } from '@hookform/devtools';
import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button, Divider, Group, Stack, Text, Title } from '@mantine/core';
import {
	organizationAddressSchema,
	OrganizationAddressSchema,
	OrganizationAddressTypeEnum,
} from '@packages/lib/schema/organization';
import { IconInfoCircle } from '@tabler/icons-react';
import React from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { Checkbox } from 'react-hook-form-mantine';
import { useDeepCompareEffect } from 'use-deep-compare';

import { graphql } from '@/gql/graphql';
import { useFrachterUser } from '@/hooks/use-frachter-user';

import { ValidateRef } from '../../~setup/~index';

import {
	createAddressDefaultValues,
	organizationSettingsMetaFormSchema,
	OrganizationSettingsMetaFormSchema,
} from './form';
import {
	OrganizationAddressForm,
	OrganizationAddressFormFragment,
	useMutateOrganizationAddress,
} from './OrganizationAddressForm';

export const OrganizationAddressQuery = graphql(
	`
		query OrganizationAddressQuery($input: QueryOrganizationBySlugInput!) {
			organizationBySlug(input: $input) {
				... on QueryOrganizationBySlugSuccess {
					data {
						companyAddress {
							...OrganizationAddressFormFragment
						}
						shippingAddress {
							...OrganizationAddressFormFragment
						}
						returnAddress {
							...OrganizationAddressFormFragment
						}
					}
				}
			}
		}
	`,
	[OrganizationAddressFormFragment],
);

/**
 * TODO: we need a way to know if any of the forms are dirty
 * so that, if we don't pass a submitRef, we can enable the save button !
 * @param param0
 * @returns
 */
export default function SettingsOrganizationPage({
	hideSaveButton,
	validateRef,
}: {
	validateRef?: ValidateRef;
	hideSaveButton?: boolean;
}) {
	const { user } = useFrachterUser({ organization: true });

	const companyAddressForm = useForm<OrganizationAddressSchema>({
		defaultValues: createAddressDefaultValues(),
		mode: 'onBlur',
		resolver: zodResolver(organizationAddressSchema),
		reValidateMode: 'onBlur',
	});

	const shippingAddressForm = useForm<OrganizationAddressSchema>({
		defaultValues: createAddressDefaultValues(),
		mode: 'onBlur',
		resolver: zodResolver(organizationAddressSchema),
		reValidateMode: 'onBlur',
	});

	const returnAddressForm = useForm<OrganizationAddressSchema>({
		defaultValues: createAddressDefaultValues(),
		mode: 'onBlur',
		resolver: zodResolver(organizationAddressSchema),
		reValidateMode: 'onBlur',
	});

	const metaForm = useForm<OrganizationSettingsMetaFormSchema>({
		defaultValues: { hasReturnAddress: false, hasShippingAddress: false },
		mode: 'all',
		resolver: zodResolver(organizationSettingsMetaFormSchema),
		reValidateMode: 'onBlur',
	});

	/** ----------------------------------------------------------------------------------------------
	 * state
	 * _______________________________________________________________________________________________ */

	const [isSubmitting, setIsSubmitting] = React.useState(false);
	const hasShippingAddress = useWatch({ control: metaForm.control, name: 'hasShippingAddress' });
	const hasReturnAddress = useWatch({ control: metaForm.control, name: 'hasReturnAddress' });

	React.useImperativeHandle(validateRef, () => submit);

	const { data, refetch } = useQuery(OrganizationAddressQuery, {
		variables: { input: { slug: user.organization.slug } },
	});

	const shippingAddressId =
		data?.organizationBySlug?.__typename === 'QueryOrganizationBySlugSuccess'
			? data?.organizationBySlug.data.shippingAddress?.id
			: null;

	const returnAddressId =
		data?.organizationBySlug?.__typename === 'QueryOrganizationBySlugSuccess'
			? data?.organizationBySlug.data.returnAddress?.id
			: null;

	const {
		user: { organization },
	} = useFrachterUser({ organization: true });

	/** ----------------------------------------------------------------------------------------------
	 * mutations
	 * _______________________________________________________________________________________________ */

	const mutateCompanyAddress = useMutateOrganizationAddress({
		addressType: OrganizationAddressTypeEnum.company,
		slug: organization.slug,
	});

	const mutateShippingAddress = useMutateOrganizationAddress({
		addressType: OrganizationAddressTypeEnum.shipping,
		slug: organization.slug,
	});

	const mutateReturnAddress = useMutateOrganizationAddress({
		addressType: OrganizationAddressTypeEnum.return,
		slug: organization.slug,
	});

	/** ----------------------------------------------------------------------------------------------
	 * effects
	 * _______________________________________________________________________________________________ */

	useDeepCompareEffect(() => {
		if (data?.organizationBySlug?.__typename === 'QueryOrganizationBySlugSuccess') {
			if (data.organizationBySlug.data.companyAddress === null && organization) {
				companyAddressForm.reset(createAddressDefaultValues({ company: organization.name }));
			} else {
				companyAddressForm.reset(createAddressDefaultValues(data.organizationBySlug.data.companyAddress));
			}

			shippingAddressForm.reset(createAddressDefaultValues(data.organizationBySlug.data.shippingAddress));

			metaForm.reset({
				hasReturnAddress: !!data.organizationBySlug.data.returnAddress,
				hasShippingAddress: !!data.organizationBySlug.data.shippingAddress,
			});
		}
	}, [data, companyAddressForm, shippingAddressForm, metaForm, organization]);

	/** ----------------------------------------------------------------------------------------------
	 * callbacks
	 * _______________________________________________________________________________________________ */

	const submit = React.useCallback(async () => {
		setIsSubmitting(true);

		try {
			const validationResult = await Promise.all([
				companyAddressForm.trigger(),
				hasShippingAddress ? shippingAddressForm.trigger() : Promise.resolve(true),
				hasReturnAddress ? returnAddressForm.trigger() : Promise.resolve(true),
			]);

			if (!validationResult.every((v) => v === true)) {
				return false;
			}

			const promises: Promise<void>[] = [];

			if (companyAddressForm.formState.isDirty) {
				promises.push(mutateCompanyAddress.update.execute(companyAddressForm.getValues()));
			}

			if (!hasShippingAddress && shippingAddressId) {
				promises.push(mutateShippingAddress.delete.execute(shippingAddressId));
			} else if (hasShippingAddress && shippingAddressForm.formState.isDirty) {
				promises.push(mutateShippingAddress.update.execute(shippingAddressForm.getValues()));
			}

			if (!hasReturnAddress && returnAddressId) {
				promises.push(mutateReturnAddress.delete.execute(returnAddressId));
			} else if (hasReturnAddress && returnAddressForm.formState.isDirty) {
				promises.push(mutateReturnAddress.update.execute(returnAddressForm.getValues()));
			}

			await Promise.all(promises);
			await refetch();

			return true;
		} finally {
			setIsSubmitting(false);
		}
	}, [
		companyAddressForm,
		hasShippingAddress,
		shippingAddressForm,
		hasReturnAddress,
		returnAddressForm,
		shippingAddressId,
		returnAddressId,
		refetch,
		mutateCompanyAddress.update,
		mutateShippingAddress.delete,
		mutateShippingAddress.update,
		mutateReturnAddress.delete,
		mutateReturnAddress.update,
	]);

	return (
		<Stack component="form" onSubmit={() => void submit().catch(() => {})} py="md">
			{/* <DevTool control={metaForm.control} /> */}
			<Group justify="space-between">
				<Title order={3}>Stammdatenverwaltung</Title>
				<Button
					color="ok"
					loading={isSubmitting}
					onClick={() => void submit().catch(() => {})}
					size="xs"
					style={{ display: hideSaveButton ? 'none' : 'block' }}
					variant="filled"
					disabled={
						!companyAddressForm.formState.isDirty &&
						!(shippingAddressForm.formState.isDirty && hasShippingAddress) &&
						!metaForm.formState.isDirty
					}
				>
					Änderungen speichern
				</Button>
			</Group>
			<Stack>
				<Group justify="space-between" style={{ position: 'sticky', top: 0 }}>
					<Title order={5}>Unternehmensadresse</Title>
				</Group>

				<OrganizationAddressForm control={companyAddressForm.control} />

				<Divider mt="xl" />

				<Stack>
					<Stack gap={0}>
						<Checkbox
							control={metaForm.control}
							fw={600}
							label="Abweichende Versandsadresse verwenden"
							my="md"
							name="hasShippingAddress"
						/>

						<Box
							style={{
								alignItems: 'center',
								display: hasShippingAddress ? 'none' : 'grid',
								gap: '0.5rem',
								gridTemplateColumns: 'auto 1fr',
							}}
						>
							<IconInfoCircle size={22} />
							<Text c="dimmed" size="sm">
								Ist keine abweichende Versandsadresse angegeben, wird die Unternehmensadresse verwendet.
							</Text>
						</Box>
					</Stack>

					<Box style={{ display: hasShippingAddress ? 'block' : 'none' }}>
						<Stack>
							<Title order={5}>Versandadresse</Title>
							<OrganizationAddressForm control={shippingAddressForm.control} />
						</Stack>
					</Box>
				</Stack>

				<Divider mt="xl" />

				<Stack>
					<Stack gap={0}>
						<Checkbox
							control={metaForm.control}
							fw={600}
							label="Abweichende Retourenadresse verwenden"
							my="md"
							name="hasReturnAddress"
						/>

						<Box
							style={{
								alignItems: 'center',
								display: hasReturnAddress ? 'none' : 'grid',
								gap: '0.5rem',
								gridTemplateColumns: 'auto 1fr',
							}}
						>
							<IconInfoCircle size={22} />
							<Text c="dimmed" size="sm">
								Ist keine abweichende Retourenadresse angegeben, wird die abweichende Versandadresse genutzt, falls
								angegeben. Ansonsten wird die Unternehmensadresse verwendet.
							</Text>
						</Box>
					</Stack>

					<Box style={{ display: hasReturnAddress ? 'block' : 'none' }}>
						<Stack>
							<Title order={5}>Retourenadresse</Title>
							<OrganizationAddressForm control={returnAddressForm.control} />
						</Stack>
					</Box>
				</Stack>
			</Stack>
		</Stack>
	);
}
