/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { z } from 'zod';
import { zPartnerOrderOrdersV4 } from '../../../../api/providers/otto_market/__generated__/hey-api/zod.gen';
// Define custom date schema
const customDateSchema = z
    .string()
    .transform((val) => val.replace(/\+0000$/, 'Z'))
    .pipe(z.string().datetime())
    .optional();
// Recursive function to replace `z.string().datetime().optional()` while retaining type
const replaceDateSchema = (schema) => {
    if (schema instanceof z.ZodObject) {
        const newShape = Object.fromEntries(Object.entries(schema.shape).map(([key, value]) => [key, replaceDateSchema(value)]));
        return z.object(newShape);
    }
    else if (schema instanceof z.ZodArray) {
        return z.array(replaceDateSchema(schema.element));
    }
    else if (schema instanceof z.ZodOptional) {
        return replaceDateSchema(schema.unwrap()).optional();
    }
    else if (schema instanceof z.ZodEffects) {
        return replaceDateSchema(schema._def.schema);
    }
    else if (schema instanceof z.ZodString) {
        if (schema._def.checks?.some((check) => check.kind === 'datetime')) {
            return customDateSchema;
        }
    }
    return schema;
};
// Apply the transformation while retaining the type
export const zPartnerOrderOrdersV4Fixed = replaceDateSchema(zPartnerOrderOrdersV4);
