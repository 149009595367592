import { Button } from '@mantine/core';
import { createFileRoute } from '@tanstack/react-router';

import { useFrachterApi } from '@/frachter/hooks/use-frachter';

export const Route = createFileRoute('/org/$slug/playground/electric/')({
	component: RouteComponent,
});

function RouteComponent() {
	const api = useFrachterApi();

	const handleButton = async () => {
		// const a = await api.backend.client.electric.sync.orders.$get();
		// const result = (await a.json()) as { originUrl: string };
		// console.log(result);
		// const { originUrl } = result;
		// const url = new URL(originUrl);
		// const params = Object.fromEntries(new URLSearchParams(url.search).entries());
		// console.log(params);
	};

	return (
		<div>
			<Button onClick={() => void handleButton()}>Request shape</Button>
		</div>
	);
}
