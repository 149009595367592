import { Divider, Stack, Text, Title } from '@mantine/core';
import { DhlParcelDeShippingPostParcelGermanyV2Sdk } from '@packages/lib/api-sdk/dhl_parcel_de_shipping_post_parcel_germany_v2';
import React from 'react';
import { Control, useFormContext, useWatch } from 'react-hook-form';
import { PasswordInput, Switch, TextInput } from 'react-hook-form-mantine';

import { DhlProductInput, LabelFormatSelect } from './setup-form-parts';
import { FormSchema } from './setup-form-schema';

const productEnum = DhlParcelDeShippingPostParcelGermanyV2Sdk.spec.schema.productEnum.Enum;

export const DhlFormSectionGeneral: React.FC<{ control: Control<FormSchema> }> = ({ control }) => {
	return (
		<Stack gap="xl">
			{/* Label Format */}
			<Stack>
				<Title order={6}>Standard-Etikettenformat</Title>
				<LabelFormatSelect control={control} name="defaultLabelFormat" />

				<Text c="dimmed" size="sm">
					Hinweis: Bei Bedarf kann für jedes Produkt ein anderes Etikettenformat ausgewählt werden. Für Warenpost und
					Warenpost International stehen zudem weitere Formate zur Verfügung.
				</Text>

				<Switch
					control={control}
					label="Individuelles Etiketten-Format für einzelne Produkte wählen"
					name="isIndividualLabelFormatsEnabled"
				/>
			</Stack>

			<Divider />

			{/* Profile */}
			<TextInput
				control={control}
				description="Das DHL Gruppenprofil, das Du für API-Anfragen nutzen möchtest"
				label="DHL Profil / Nutzergruppe"
				name="config.profile"
				placeholder="STANDARD_GRUPPENPROFIL"
			/>
		</Stack>
	);
};

export const DhlFormSectionCredentials: React.FC<{ control: Control<FormSchema> }> = ({ control }) => {
	return (
		<Stack>
			<Title order={4}>API-Zugangsdaten</Title>

			<TextInput
				control={control}
				description="Dein Benutzername für die DHL API"
				label="DHL Benutzername"
				name="credentials.username"
				placeholder="user123"
				required
			/>

			<PasswordInput
				control={control}
				description="Dein Passwort für die DHL API"
				label="DHL Passwort"
				name="credentials.password"
				placeholder="password456"
				required
				type="password"
			/>
		</Stack>
	);
};

export const DhlFormSectionProductsNational: React.FC<{ control: Control<FormSchema> }> = ({ control }) => {
	const form = useFormContext<FormSchema>();

	const isIndividualLabelFormatsEnabled = useWatch({ control, name: 'isIndividualLabelFormatsEnabled' });

	const paketBillingNumber = useWatch({ control, name: 'config.products.Paket.billingNumber' });
	const warenpostBillingNumber = useWatch({ control, name: 'config.products.Warenpost.billingNumber' });

	const { resetField } = form;

	React.useEffect(() => {
		if (!paketBillingNumber && !warenpostBillingNumber) {
			resetField('config.products.Retoure.billingNumber');
		}
	}, [paketBillingNumber, resetField, warenpostBillingNumber]);

	return (
		<Stack gap="lg">
			<DhlProductInput control={control} name={productEnum.Paket} showLabelSelect={isIndividualLabelFormatsEnabled} />

			<DhlProductInput
				control={control}
				name={productEnum.Warenpost}
				showLabelSelect={isIndividualLabelFormatsEnabled}
			/>

			<Divider />

			<Stack gap="xs">
				<Text size="xs">
					Hinweis: DHL Retoure kann nur in Verbindung mit einer Abrechnungsnummer für Paket oder Warenpost verwendet
					werden
				</Text>

				<DhlProductInput
					control={control}
					disabled={!paketBillingNumber && !warenpostBillingNumber}
					name={productEnum.Retoure}
				/>
			</Stack>
		</Stack>
	);
};

export const DhlFormSectionProductsInternational: React.FC<{ control: Control<FormSchema> }> = ({ control }) => {
	const isIndividualLabelFormatsEnabled = useWatch({ control, name: 'isIndividualLabelFormatsEnabled' });

	return (
		<Stack gap="lg">
			<DhlProductInput
				control={control}
				name={productEnum.Europaket}
				showLabelSelect={isIndividualLabelFormatsEnabled}
			/>
			<DhlProductInput
				control={control}
				name={productEnum.PaketInternational}
				showLabelSelect={isIndividualLabelFormatsEnabled}
			/>
			<DhlProductInput
				control={control}
				name={productEnum.WarenpostInternational}
				showLabelSelect={isIndividualLabelFormatsEnabled}
			/>
		</Stack>
	);
};
