import { ContextModalProps } from '@mantine/modals';

import * as DhlPostParcelGermanyShippingV2 from '@/features/connections/dhl_parcel_de_shipping_post_parcel_germany_v2';

export const DhlPostParcelGermanyShippingV2Modal = ({
	context,
	id,
	innerProps,
}: ContextModalProps<DhlPostParcelGermanyShippingV2.SetupFormProps>) => (
	<DhlPostParcelGermanyShippingV2.SetupForm
		{...innerProps}
		onCancel={() => context.closeModal(id)}
		onComplete={() => context.closeModal(id)}
	/>
);
