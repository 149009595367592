import { createClient } from '@hey-api/client-fetch';
import { createOpenApiClientFetch, createOpenApiClientProxyFetch, passthrough } from './create-fetch';
export const makeApiClass = (options) => {
    const _class = class Api {
        static zod = options.zod;
        static api = options.sdk;
        static spec = options.spec;
        static fetch = {
            api: createOpenApiClientFetch,
            passthrough: passthrough,
            proxy: createOpenApiClientProxyFetch,
        };
        static getClient(options, clientOptions) {
            return createClient({
                ...clientOptions,
                baseUrl: this.spec.api.baseUrl[options.environment],
            });
        }
        static getServices(_client) {
            return {};
        }
    };
    return _class;
};
