import { Effect, Layer, Logger, LogLevel, Option } from 'effect';
import { SentryService } from '../effect/sentry/SentryService';
const isPlainObject = (value) => Object.prototype.toString.call(value) === '[object Object]';
let warnCount = 0;
/**
 * ensure that for markets we use
 * `yield* Effect.logError(validationError, { marketId: 'market-1', merchantId: 'merchant-1' });`
 */
const frachterLoggerLayer = Logger.addEffect(Effect.gen(function* () {
    const sentryOption = yield* Effect.serviceOption(SentryService);
    const logger = Logger.make(({ logLevel, message }) => {
        if (Option.isNone(sentryOption) && warnCount < 3) {
            console.warn('Sentry is not available for logging errors. Please provide a SentryService to the runtime.');
            warnCount++;
            return;
        }
        const isErrorOrFatal = LogLevel.greaterThanEqual(logLevel, LogLevel.Error);
        if (!isErrorOrFatal)
            return;
        const sentry = Option.getOrThrow(sentryOption);
        const contexts = {};
        if (Array.isArray(message)) {
            const [first, second, ...rest] = message;
            if (rest.length > 0) {
                console.warn('Cannot use more than two arguments for logging errors with Sentry. Additional context will be ignored.');
            }
            if (!(first instanceof Error)) {
                console.warn('First message is not an error. Skipping Sentry logging.');
                return;
            }
            // if (first.cause) {
            // 	contexts.Cause = first.cause;
            // }
            if (typeof second !== 'undefined' && !isPlainObject(second)) {
                console.warn('Second message is not a plain object. Cannot add context.');
            }
            else {
                contexts.AdditionalData = second;
            }
            sentry.captureException(first, { contexts });
        }
    });
    // in window environments, we always use the pretty pretty logger
    // TODO: insert errors into database
    if (typeof window !== 'undefined') {
        return Logger.zip(logger, Logger.prettyLoggerDefault);
    }
    /* use either json logger for production or pretty logger otherwise */
    return Logger.zip(logger, process.env.NODE_ENV === 'production' ? Logger.jsonLogger : Logger.prettyLoggerDefault);
    // return logger;
}));
export const FrachterLoggerDefault = Layer.merge(frachterLoggerLayer, Logger.replace(Logger.defaultLogger, Logger.none));
