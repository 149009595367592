import { ActionIcon, Box, Collapse, Select, Space, Stack, Text, TextInput } from '@mantine/core';
import { DateTimePicker } from '@mantine/dates';
import { IconSearch, IconX } from '@tabler/icons-react';
import { addDays, subDays } from 'date-fns';

import { useCloudprintQueueFormContext } from './CloudprintQueueFormContext';

export const CloudprintQueueFilter: React.FC<{}> = () => {
	const form = useCloudprintQueueFormContext();

	const from = form.values.from;
	const to = form.values.to;

	return (
		<Box bg="color-mix(in srgb, var(--mantine-color-text) 4%, transparent)">
			<Collapse in={form.values.isFilterActive}>
				<Stack p="md">
					<Box style={{ alignItems: 'center', display: 'grid', gap: '1rem', gridTemplateColumns: '1fr auto auto' }}>
						<TextInput
							{...form.getInputProps('search')}
							leftSection={<Space w={5} />}
							leftSectionWidth={16}
							placeholder="Suche"
							radius="xl"
							size="sm"
							rightSection={
								<Box mr="sm" style={{ display: 'grid', placeContent: 'center' }}>
									<ActionIcon
										onClick={() => form.setFieldValue('search', '')}
										variant="transparent"
										style={{
											opacity: form.values.search ? 1 : 0,
											pointerEvents: form.values.search ? 'auto' : 'none',
										}}
									>
										{form.values.search ? <IconX size={14} stroke={1.5} /> : <IconSearch size={16} />}
									</ActionIcon>
								</Box>
							}
						/>
						<Text c="dimmed" size="sm">
							in
						</Text>
						<Select
							defaultValue={'all'}
							placeholder="Status"
							radius="md"
							w={130}
							{...form.getInputProps('statusFilter')}
							data={[
								{ label: 'Alle', value: 'all' },
								{ label: 'Erfolgreich', value: 'success' },
								{ label: 'Fehlerhaft', value: 'error' },
							]}
						/>
					</Box>

					<Box
						style={{
							alignItems: 'center',
							display: 'grid',
							gap: '1rem',
							gridTemplateColumns: '1fr auto 1fr',
						}}
					>
						<DateTimePicker
							{...form.getInputProps('from')}
							highlightToday
							leftSection={<Space h="sm" />}
							maxDate={to ? new Date(to) : addDays(new Date(), 1)}
							minDate={subDays(new Date(), 14)}
							placeholder="Start"
							radius="xl"
							size="sm"
							rightSection={
								<ActionIcon
									mr="sm"
									onClick={() => form.setFieldValue('from', undefined)}
									variant="transparent"
									style={{
										opacity: from ? 1 : 0,
										pointerEvents: from ? 'auto' : 'none',
									}}
								>
									<IconX size={14} stroke={1.5} />
								</ActionIcon>
							}
							styles={{
								input: { textAlign: 'center' },
							}}
						/>
						<Text c="dimmed" size="sm">
							bis
						</Text>
						<DateTimePicker
							{...form.getInputProps('to')}
							highlightToday
							leftSection={<Space h="sm" />}
							maxDate={addDays(new Date(), 1)}
							minDate={from ? from : subDays(new Date(), 14)}
							placeholder="Ende"
							radius="xl"
							size="sm"
							rightSection={
								<ActionIcon
									mr="sm"
									onClick={() => form.setFieldValue('to', undefined)}
									variant="transparent"
									style={{
										opacity: to ? 1 : 0,
										pointerEvents: to ? 'auto' : 'none',
									}}
								>
									<IconX size={14} stroke={1.5} />
								</ActionIcon>
							}
							styles={{
								input: { textAlign: 'center' },
							}}
						/>
					</Box>
				</Stack>
			</Collapse>
		</Box>
	);
};
