import { LiteralUnion } from 'type-fest';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

type AppPreloadKey = LiteralUnion<'app' | 'pglite', string>;

interface IAppStoreState {
	appPreloadKeys: string[];
	isAppPreloaderVisible: boolean;
}

interface IAppStoreActions {
	setAppPreloadKey: (key: AppPreloadKey) => void;
	unsetAppPreloadKey: (key: AppPreloadKey) => void;
}

export const useAppStore = create<IAppStoreState & IAppStoreActions>()(
	devtools(
		immer((set) => ({
			appPreloadKeys: [],

			isAppPreloaderVisible: false,
			setAppPreloadKey: (key) =>
				set((s) => {
					if (s.appPreloadKeys.includes(key)) return;
					s.appPreloadKeys.push(key);
					if (s.appPreloadKeys.length === 1) {
						s.isAppPreloaderVisible = true;
					}
				}),

			unsetAppPreloadKey: (key) =>
				set((s) => {
					s.appPreloadKeys = s.appPreloadKeys.filter((k) => k !== key);
					if (s.appPreloadKeys.length === 0) {
						s.isAppPreloaderVisible = false;
					}
				}),
		})),
		{ name: 'frachter', store: 'app' },
	),
);
