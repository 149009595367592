import { Button, Stack, Text } from '@mantine/core';
import { IconArrowLeft as IconBack } from '@tabler/icons-react';
import { createFileRoute } from '@tanstack/react-router';

import { MonoButton } from '@/components/ui/MonoButton';

export const Route = createFileRoute('/org/$slug/playground/theme')({
	component: Page,
});

function Page() {
	return (
		<Stack style={{ display: 'grid', gap: 10, gridTemplateColumns: 'repeat(3, 1fr)' }}>
			<Text>no variant</Text>
			<MonoButton leftSection={<IconBack />}>Zurück</MonoButton>
			<Button leftSection={<IconBack />}>Zurück</Button>

			<Text>default</Text>
			<MonoButton leftSection={<IconBack />} variant="default">
				Zurück
			</MonoButton>
			<Button leftSection={<IconBack />} variant="default">
				Zurück
			</Button>

			<Text>filled</Text>
			<MonoButton leftSection={<IconBack />} variant="filled">
				Zurück
			</MonoButton>
			<Button leftSection={<IconBack />} variant="filled">
				Zurück
			</Button>

			<Text>gradient</Text>
			<MonoButton leftSection={<IconBack />} variant="gradient">
				Zurück
			</MonoButton>
			<Button leftSection={<IconBack />} variant="gradient">
				Zurück
			</Button>

			<Text>light</Text>
			<MonoButton leftSection={<IconBack />} variant="light">
				Zurück
			</MonoButton>
			<Button leftSection={<IconBack />} variant="light">
				Zurück
			</Button>

			<Text>outline</Text>
			<MonoButton leftSection={<IconBack />} variant="outline">
				Zurück
			</MonoButton>
			<Button leftSection={<IconBack />} variant="outline">
				Zurück
			</Button>

			<Text>subtle</Text>
			<MonoButton leftSection={<IconBack />} variant="subtle">
				Zurück
			</MonoButton>
			<Button leftSection={<IconBack />} variant="subtle">
				Zurück
			</Button>

			<Text>transparent</Text>
			<MonoButton leftSection={<IconBack />} variant="transparent">
				Zurück
			</MonoButton>
			<Button leftSection={<IconBack />} variant="transparent">
				Zurück
			</Button>

			<Text>white</Text>
			<MonoButton leftSection={<IconBack />} variant="white">
				Zurück
			</MonoButton>
			<Button leftSection={<IconBack />} variant="white">
				Zurück
			</Button>
		</Stack>
	);
}
