import { apis } from '@packages/lib/api';
import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

import { ConnectOttoPage } from './otto';

const connectMarketSearchParamsSchema = z.object({
	/**
	 * if true, we should connect to sandbox API
	 */
	sandbox: z.boolean().optional().catch(false),
	/**
	 * if we have a status, we know that the response came from the backend
	 */
	status: z.enum(['unauthorized', 'success', 'error']).optional(),
	/**
	 * the clerk connect templated token string (use with `useConnectToken.validate()`)
	 */
	token: z.string().optional(),
});

export const Route = createFileRoute('/connect/$apiId/')({
	component: RouteComponent,
	validateSearch: connectMarketSearchParamsSchema,
});

function RouteComponent() {
	const { apiId } = Route.useParams();

	switch (apiId) {
		case apis.otto_market.id:
			return <ConnectOttoPage />;
		default:
			return <div>Unknown API ID: {apiId}</div>;
	}
}
