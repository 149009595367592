import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/')({
	beforeLoad: async ({ context }) => {
		const { organization } = context.clerk;

		if (organization) {
			await context.frachter?.setOrganization(organization.slug);
			throw redirect({
				params: { slug: organization.slug! },
				to: '/org/$slug',
			});
		} else {
			throw redirect({
				to: '/org',
			});
		}
	},
	component: () => null,
});
