'use client';

import { Box, Loader, Modal, Stack, Text, Title } from '@mantine/core';
import { useNetwork } from '@mantine/hooks';
import { match } from 'ts-pattern';

import s from './NetworkConnectionGuard.module.scss';

export const NetworkConnectionGuard = () => {
	const network = useNetwork();
	// const realtimeError = useAppSelector((s) => s.realtimeConnection.error);
	// const wasConnectedOnce = useAppSelector((s) => s.realtimeConnection.connection.clientId !== null);

	const isRealtimeFailure = false; // wasConnectedOnce && Boolean(realtimeError);

	const problem = match({ network: !network.online, realtime: isRealtimeFailure })
		.with({ network: true }, () => 'network' as const)
		// .with({ realtime: true }, () => 'realtime' as const)
		.otherwise(() => null);

	return (
		<Modal
			centered
			onClose={() => null}
			opened={problem !== null}
			styles={{ title: { color: 'var(--mantine-color-error)' } }}
			title="Verbindungsprobleme"
			withCloseButton={false}
			zIndex="var(--z-index-network-connection-guard)"
		>
			<Box className={s.content}>
				<Title order={4}>Du bist offline</Title>
				<Text c="dimmed" size="sm">
					Die{' '}
					{problem === 'network' ? 'Netzwerkverbindung' : problem === 'realtime' ? 'Echtzeitverbindung' : 'Verbindung'}{' '}
					scheint unterbrochen zu sein.
				</Text>
				<Stack mt="lg">
					<Text>
						Wir benötigen eine aktive Verbindung zum Internet, um Geräte und Daten miteinander synchronisieren zu
						können.
					</Text>
					{problem === 'network' ? (
						<Text>Bitte überprüfe die Verbindung. Sobald Du wieder online bist, kannst du weiterarbeiten.</Text>
					) : problem === 'realtime' ? (
						<Stack align="center" my="lg">
							<Loader type="dots" />
							<Text size="sm" ta="center">
								Verbindung wird erneuert. Bitte warte einen Moment.
							</Text>
						</Stack>
					) : null}
				</Stack>
			</Box>
		</Modal>
	);
};
