import { Layer, ManagedRuntime } from 'effect';

import { GlobalLayer, FrachterLayer, memoMap } from './layers';

// HINT: if we split RealtimeService into RealtimeClientService and RealtimeService, then we could "aggregate" everything
// within RealtimeService and there use the ClientSpace and the OrganizationSpace, which both use the RealtimeClientService

export const FrachterRuntime = ManagedRuntime.make(FrachterLayer.pipe(Layer.provide(GlobalLayer)), memoMap);

export const GlobalRuntime = ManagedRuntime.make(GlobalLayer, memoMap);
