import { useOrganization, useOrganizationList, useSession, useUser } from '@clerk/clerk-react';
import React from 'react';

import { GenericUserClient, PublicOrganizationMember } from '@/type-defs/auth';
import { clerkClientUserToGenericUserProfile, parseOrganizationMemberships } from '@/utils/auth';
import { getAuthUrl } from '@/utils/get-auth-url';
import { setSentryOrganization, setSentryUser } from '@/utils/sentry';

/**
 * HINT: we must ensure that we only use this hook when clerk and the user is already loaded
 * @param options
 */
export const useFrachterUser = <TOptions extends { user: true } | { organization: true } | undefined = undefined>(
	options?: TOptions,
): {
	switchOrganization: (idOrSlug: string) => Promise<void>;
} & (TOptions extends { user: true }
	? { user: GenericUserClient }
	: TOptions extends { organization: true }
		? {
				user: Omit<GenericUserClient, 'organization'> & {
					organization: NonNullable<GenericUserClient['organization']>;
				};
				members: PublicOrganizationMember[];
			}
		: {
				user: GenericUserClient | null;
				members: PublicOrganizationMember[];
			}) => {
	const { isLoaded, user } = useUser();
	const { membership, memberships, organization } = useOrganization({ memberships: { infinite: true } });
	const organizationList = useOrganizationList({ userMemberships: { infinite: true } });
	const { session } = useSession();

	React.useEffect(() => {
		if (!isLoaded || !organizationList.isLoaded) return;
		/**
		 * redirect to login if we do not have a user but we
		 * require either the user or the organization
		 */
		if (
			(options && 'user' in options && options?.user && !user) ||
			(options && 'organization' in options && options?.organization && !user)
		) {
			window.location.href = getAuthUrl();
		}
	}, [isLoaded, options, organizationList.isLoaded, user]);

	React.useEffect(() => {
		if (!isLoaded || !organizationList.isLoaded) return;
		/**
		 * redirect to organization select if we do not have an organization
		 * but require the organization
		 */
		if (options && 'organization' in options && options?.organization && !organization) {
			// redirect to organization select
			window.location.href = `/app/org?next=${window.location.href}`;
		}
	}, [isLoaded, options, organization, organizationList.isLoaded]);

	const genericUser = clerkClientUserToGenericUserProfile(
		user,
		organizationList.userMemberships.data ?? [],
		!organization
			? null
			: {
					id: organization.id,
					imageUrl: organization.imageUrl,
					name: organization.name,
					permissions: membership?.permissions ?? [],
					role: membership?.role ?? 'unknown',
					slug: organization.slug!,
				},
	);

	const switchOrganization = async (idOrSlug: string) => {
		if (!organizationList.setActive) {
			throw new Error('organizationList.setActive is not available');
		}

		await organizationList.setActive({ organization: idOrSlug });
		await organizationList.userMemberships.revalidate();
		await session?.reload();
		// await router.invalidate();
	};

	React.useEffect(() => {
		if (genericUser?.id) {
			setSentryUser(genericUser);
		} else {
			setSentryUser(null);
		}

		if (genericUser?.organization?.id) {
			setSentryOrganization(genericUser?.organization);
		} else {
			setSentryOrganization(null);
		}
	}, [genericUser, genericUser?.organization, genericUser?.organization?.id]);

	// eslint-disable-next-line @typescript-eslint/no-unsafe-return
	return {
		members: parseOrganizationMemberships(memberships),
		switchOrganization,
		user: genericUser as any,
	} as any;
};
