'use client';

import { Box } from '@mantine/core';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import React, { memo, useEffect, useId } from 'react';
import isEqual from 'react-fast-compare';

import { useAppLayoutSetPageVariant } from '../app/AppLayoutContext';

import s from './AppPageLayout.module.scss';
import { AppPageTitle } from './AppPageTitle';

export type AppPageLayoutVariant = 'default' | 'apps' | 'settings';

/**
 * Layout for app-like content that will have the AppBar on top.
 * @param props
 */
export const AppPageLayout = memo(function AppPageLayout(
	options: React.PropsWithChildren<{
		maxWidth?: string | number;
		title?: React.ReactNode;
		variant: AppPageLayoutVariant;
	}>,
) {
	const setVariant = useAppLayoutSetPageVariant();

	const { children, title, variant = 'default' } = options;

	const maxWidth = 'maxWidth' in options ? options.maxWidth : `var(--page-max-width)`;

	useEffect(() => {
		setVariant(variant);
	}, [variant, setVariant]);

	const key = useId();

	return (
		<Box
			key={key}
			animate={{ opacity: 1, transition: { delay: 0.15 } }}
			className={clsx(s.root)}
			component={motion.div}
			exit={{ opacity: 0 }}
			initial={{ opacity: 0 }}
			pb={variant === 'default' ? 0 : 'md'}
			pr={variant === 'default' ? 0 : 'md'}
			style={{ maxWidth }}
		>
			{/* <AppPageLayoutClassSwitcher layout={variant} /> */}
			{/* will be rendered inside a portal > so this does not affect the page's layout */}
			{<AppPageTitle>{title}</AppPageTitle>}
			{children}
		</Box>
	);
}, isEqual);
