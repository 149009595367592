/** ------------------------------------------------------------------------------------
 *  RequestGenericError, RequestClientError, RequestServerError ...
 * ------------------------------------------------------------------------------------- */
import { Data } from 'effect';
export class RequestInterruptedException extends Data.TaggedError('frachter/RequestInterruptedException') {
    message;
    constructor(message, context) {
        super({ ...context, resource: getResource(context.request) });
        this.message = message;
    }
}
/**
 * This is a generic error that is used to wrap all other request errors.
 * It should only be used when there is no status code or an error that we don't
 * explicitly handle.
 */
export class RequestGenericError extends Data.TaggedError('frachter/RequestGenericError') {
    message;
    constructor(message, context) {
        super({ ...context, resource: getResource(context.request) });
        this.message = message;
    }
}
/**
 * if it is a `usage_limit_exceeded` error,
 * we should check the response headers for `Retry-After` key, which
 * tells us how many seconds we should wait before retrying the request
 */
export class RequestClientError extends Data.TaggedError('frachter/RequestClientError') {
    message;
    constructor(message, context) {
        super({ ...context, resource: getResource(context.request) });
        this.message = message;
    }
}
/**
 * for any 5xx, retry x times, then inform user
 */
export class RequestServerError extends Data.TaggedError('frachter/RequestServerError') {
    message;
    constructor(message, context) {
        super({ ...context, resource: getResource(context.request) });
        this.message = message;
    }
}
export class ResponseValidationError extends Data.TaggedError('frachter/ResponseValidationError') {
    message;
    constructor(message, context) {
        super({ ...context, ...(context.request ? { resource: getResource(context.request) } : {}) });
        this.message = message;
    }
}
function getResource(request) {
    return `${request.method.toUpperCase()} ${request.url}`;
}
