import { faker } from '@faker-js/faker';

type FulfillmentStatus =
	| 'ANNOUNCED'
	| 'PROCESSABLE'
	| 'SENT'
	| 'RETURNED'
	| 'CANCELLED_BY_PARTNER'
	| 'CANCELLED_BY_MARKETPLACE';

interface PositionItemOptions {
	fulfillmentStatus?: FulfillmentStatus;
}

function createPositionItem(options?: PositionItemOptions) {
	return {
		dealId: faker.string.uuid(),
		dealName: `Deal ${faker.number.int({ max: 52, min: 1 })}`,
		fulfillmentStatus:
			options?.fulfillmentStatus ??
			faker.helpers.arrayElement([
				'ANNOUNCED',
				'PROCESSABLE',
				'SENT',
				'RETURNED',
				'CANCELLED_BY_PARTNER',
				'CANCELLED_BY_MARKETPLACE',
			]),
		itemValueGrossPrice: {
			amount: faker.number.int({ max: 100, min: 1 }),
			currency: 'EUR',
		},
		positionItemId: faker.string.uuid(),
		product: {
			articleNumber: faker.string.alphanumeric({ length: { max: 10, min: 5 } }),
			ean: faker.string.numeric({ length: { max: 13, min: 10 } }),
			productTitle: faker.commerce.productName(),
			sku: faker.string.alphanumeric({ length: { max: 10, min: 5 } }),
			vatRate: 19,
		},
	};
}

export const createOrder = (options?: PositionItemOptions) => {
	const orderId = faker.string.uuid();

	return {
		// initialDeliveryFees: [
		// 	{
		// 		deliveryFeeAmount: {
		// 			amount: faker.number.int({ max: 100, min: 1 }),
		// 			currency: 'EUR',
		// 		},
		// 		name: 'DELIVERY_FEE_STANDARD',
		// 		positionItemIds: [faker.string.uuid()],
		// 		vatRate: 19,
		// 	},
		// ],
		// lastModifiedDate: faker.date.recent().toISOString(),
		// links: [
		// 	{
		// 		href: `/v4/orders/${orderId}`,
		// 		rel: 'self',
		// 	},
		// ],
		// orderDate: faker.date.recent().toISOString(),
		// orderLifecycleInformation: {
		// 	lifecycleChangeDate: faker.date.recent().toISOString(),
		// },
		orderNumber: faker.string.alphanumeric({ length: { max: 10, min: 5 } }),
		payment: {
			paymentMethod: 'PREPAYMENT',
		},
		positionItems: Array.from({ length: faker.number.int({ max: 10, min: 1 }) }, () => createPositionItem(options)),
		salesOrderId: orderId,
	};
};
