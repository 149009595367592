import { Data, Duration, Effect, Match, Schedule } from 'effect';
import { RequestClientError, RequestServerError } from './errors';
const MAX_DELAY_SECONDS = 6;
const DEFAULT_DELAY = 1;
const MAX_RETRIES = 5;
/**
 * A policy for retrying request errors.
 *
 * Retries should be possible for 429 errors where we use the Retry-After in case it's available
 * in the response headers. Otherwise, and also for RequestServerErrors, we use a default.
 *
 * In case of non 429 errors, we exponentially increase the delay between retries until we've reached
 * a MAX_DELAY_SECONDS (6 seconds).
 *
 */
const createRetryPolicy = (options) => Schedule.identity().pipe(
// Schedule.passthrough,
/* add delay depending on the error (which might be a 429 that provides the number of seconds to wait has a header) */
Schedule.addDelay((error) => {
    if (!(error instanceof Data.Error)) {
        return Duration.zero;
    }
    return Match.value(error).pipe(Match.when(Match.instanceOf(RequestClientError), (error) => {
        const retryAfter = parseFloat(error.response.headers.get('Retry-After') ?? '0');
        if (retryAfter > 0) {
            return Duration.seconds(retryAfter);
        }
        return Duration.seconds(options?.defaultDelay ?? DEFAULT_DELAY);
    }), Match.orElse(() => Duration.seconds(options?.defaultDelay ?? DEFAULT_DELAY)));
}), 
/* max of 5 retries */
Schedule.intersect(Schedule.recurs(options?.maxRetries ?? MAX_RETRIES)), Schedule.modifyDelay(([requestError, number], duration) => {
    if (!(requestError instanceof Data.Error)) {
        return duration;
    }
    const is429AndHasRetryAfter = requestError instanceof RequestClientError && requestError.response.headers.has('Retry-After');
    /* if it's not a 429 with a Retry-After header, we'll set a max delay of 5 seconds an exponentially grow the delay like 1, 2, 4, then max of 5 seconds */
    if (!is429AndHasRetryAfter) {
        return Duration.clamp(duration, {
            /* max of 6 seconds */
            maximum: Duration.seconds(MAX_DELAY_SECONDS),
            minimum: duration.pipe(Duration.times(2 * number)),
        });
    }
    /* otherwise we keep the duration */
    return duration;
}), 
/* jittered with a max of 1.2 and min of 1.0 */
Schedule.jitteredWith({ max: 1.2, min: 1.0 }));
export const retryRequest = (options) => Effect.retry({
    schedule: createRetryPolicy(options),
    while(error) {
        if (options?.skipRetryOn?.(error)) {
            return false;
        }
        if (error instanceof Data.Error) {
            return Match.value(error).pipe(Match.withReturnType(), 
            /* we retry RequestClientErrors only when the reason is 'usage_limit_exceeded' */
            Match.when(Match.instanceOf(RequestClientError), (e) => e.reason === 'usage_limit_exceeded'), 
            /* we retry RequestServerErrors always */
            Match.when(Match.instanceOf(RequestServerError), () => true), 
            /* all other errors will not be retried */
            Match.orElse(() => false));
        }
        return false;
    },
});
