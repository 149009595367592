'use client';

import { UserButton } from '@clerk/clerk-react';
import { Box, Skeleton } from '@mantine/core';
import { useRef } from 'react';

import { useHasChildWithSelector } from '@/hooks/use-has-child-with-selector';

export const ProfileMenu: React.FC<{}> = () => {
	const containerRef = useRef<HTMLDivElement | null>(null);
	const isReady = useHasChildWithSelector(containerRef, '.cl-userButtonTrigger.cl-button');

	return (
		<Box style={{ height: !isReady ? 28 : 'auto', position: 'relative', width: !isReady ? 28 : 'auto' }}>
			<Box style={{ display: isReady ? 'none' : 'block', height: 28, position: 'absolute', width: 28 }}>
				<Skeleton circle height={28} />
			</Box>
			<Box ref={containerRef} style={{ display: 'grid', placeContent: 'center' }}>
				<UserButton afterSignOutUrl="/" />
			</Box>
		</Box>
	);
};
