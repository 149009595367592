'use client';

import { Box } from '@mantine/core';
import clsx from 'clsx';
import React from 'react';
import isEqual from 'react-fast-compare';

import sAppLayout from '../app/AppLayout.module.scss';
import { useAppLayoutPageVariant } from '../app/AppLayoutContext';

export const AppPageVariantWrapper = React.memo(function AppPageVariantWrapper({
	children,
	className,
}: React.PropsWithChildren<{ className?: string }>) {
	const variant = useAppLayoutPageVariant();

	return <Box className={clsx(sAppLayout[`page-variant-${variant}`], className)}>{children}</Box>;
}, isEqual);
