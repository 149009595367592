import { Box, Button, Stack } from '@mantine/core';
import { wrap } from 'comlink';

import { IWorker } from './worker/types';

// Problem here woudl be that ALL workers would get the messages
// but if we want to reset, only the leader should do that
// but all clients should then be informed that they need to reload AFTER the reset is complete
const bc = new BroadcastChannel('frachter/playground/rpc');

const obj = wrap<IWorker>(bc);

const worker = new Worker(new URL('./worker/worker-rpc.ts', import.meta.url), {
	name: 'worker-rpc',
});

export default function Page() {
	return (
		<Box p="lg">
			<Stack>Hello /playground/rpc/</Stack>
			<Button onClick={() => void obj.hello()}>do something</Button>
		</Box>
	);
}

/**
 * how should it work?
 *
 * let's say we start two tabs at the same time
 * then one will be the leader and the other one will not
 *
 * now the migrations fail
 *
 * then ALL clients should be informed
 * and a reset should be triggered
 * and after the reset, the clients should reload
 *
 *
 * basically every worker that is used in a service should be able to
 * instruct the browser tab to STOP anything (org should close)
 *
 * then do something (like delete the opfs stuff)
 * and then instruct the browser tab to reload
 *
 *
 * so the broadcast channel would need to be part of the organization manager service
 * and that one should then
 * - assign a random client id with crypto.randomUUID()
 * - and listen to messages like `SERVICE_ERROR` with a payload of the organization id
 *
 * then the organization service manager could determine if the current tab is affected
 * and close the scope
 * -
 */
