export type User = {
	id: string;
	firstName: string;
	lastName: string;
	email: string;
	state: string;
};

export interface IProductRow {
	currency: (typeof currencies)[number];
	description: string;
	ean: string;
	id: string;
	imageUrl: string;
	price: number;
	sku: string;
	title: string;
	weight: number;
	weightUnit: (typeof weightUnits)[number];
}

// create 20 fake products
export const fakeProductsData: IProductRow[] = Array.from({ length: 100 }, (_, index) => ({
	currency: 'EUR' as (typeof currencies)[number],
	description: `Product ${index + 1} description`,
	ean: `1234567890${index + 1}`,
	id: `product-${index + 1}`,
	imageUrl: `https://via.placeholder.com/150?text=Product+${index + 1}`,
	price: 100 + index * 10 + 0.01 * index,
	sku: `SKU${index + 1}`,
	title: `Product ${index + 1}`,
	weight: 100 + index * 10,
	weightUnit: 'g' as (typeof weightUnits)[number],
}));

export const currencies = ['EUR', 'USD', 'GBP'];

export const weightUnits = ['g', 'kg'];

export const fakeData: User[] = [
	{
		email: 'Jerod14@hotmail.com',
		firstName: 'Kelvin',
		id: '9s41rp',
		lastName: 'Langosh',
		state: 'Ohio',
	},
	{
		email: 'Hugh.Dach79@hotmail.com',
		firstName: 'Molly',
		id: '08m6rx',
		lastName: 'Purdy',
		state: 'Rhode Island',
	},
	{
		email: 'Camden.Macejkovic@yahoo.com',
		firstName: 'Henry',
		id: '5ymtrc',
		lastName: 'Lynch',
		state: 'California',
	},
	{
		email: 'Eric0@yahoo.com',
		firstName: 'Glenda',
		id: 'ek5b97',
		lastName: 'Douglas',
		state: 'Montana',
	},
	{
		email: 'Ericka_Mueller52@yahoo.com',
		firstName: 'Leone',
		id: 'xxtydd',
		lastName: 'Williamson',
		state: 'Colorado',
	},
	{
		email: 'Veda_Feeney@yahoo.com',
		firstName: 'Mckenna',
		id: 'wzxj9m',
		lastName: 'Friesen',
		state: 'New York',
	},
	{
		email: 'Melvin.Pacocha@yahoo.com',
		firstName: 'Wyman',
		id: '21dwtz',
		lastName: 'Jast',
		state: 'Montana',
	},
	{
		email: 'Delfina12@gmail.com',
		firstName: 'Janick',
		id: 'o8oe4k',
		lastName: 'Willms',
		state: 'Nebraska',
	},
	{
		email: 'Jerod14@hotmail.com',
		firstName: 'Kelvin',
		id: '9s41rp',
		lastName: 'Langosh',
		state: 'Ohio',
	},
	{
		email: 'Hugh.Dach79@hotmail.com',
		firstName: 'Molly',
		id: '08m6rx',
		lastName: 'Purdy',
		state: 'Rhode Island',
	},
	{
		email: 'Camden.Macejkovic@yahoo.com',
		firstName: 'Henry',
		id: '5ymtrc',
		lastName: 'Lynch',
		state: 'California',
	},
	{
		email: 'Eric0@yahoo.com',
		firstName: 'Glenda',
		id: 'ek5b97',
		lastName: 'Douglas',
		state: 'Montana',
	},
	{
		email: 'Ericka_Mueller52@yahoo.com',
		firstName: 'Leone',
		id: 'xxtydd',
		lastName: 'Williamson',
		state: 'Colorado',
	},
	{
		email: 'Veda_Feeney@yahoo.com',
		firstName: 'Mckenna',
		id: 'wzxj9m',
		lastName: 'Friesen',
		state: 'New York',
	},
	{
		email: 'Melvin.Pacocha@yahoo.com',
		firstName: 'Wyman',
		id: '21dwtz',
		lastName: 'Jast',
		state: 'Montana',
	},
	{
		email: 'Delfina12@gmail.com',
		firstName: 'Janick',
		id: 'o8oe4k',
		lastName: 'Willms',
		state: 'Nebraska',
	},
	{
		email: 'Jerod14@hotmail.com',
		firstName: 'Kelvin',
		id: '9s41rp',
		lastName: 'Langosh',
		state: 'Ohio',
	},
	{
		email: 'Hugh.Dach79@hotmail.com',
		firstName: 'Molly',
		id: '08m6rx',
		lastName: 'Purdy',
		state: 'Rhode Island',
	},
	{
		email: 'Camden.Macejkovic@yahoo.com',
		firstName: 'Henry',
		id: '5ymtrc',
		lastName: 'Lynch',
		state: 'California',
	},
	{
		email: 'Eric0@yahoo.com',
		firstName: 'Glenda',
		id: 'ek5b97',
		lastName: 'Douglas',
		state: 'Montana',
	},
	{
		email: 'Ericka_Mueller52@yahoo.com',
		firstName: 'Leone',
		id: 'xxtydd',
		lastName: 'Williamson',
		state: 'Colorado',
	},
	{
		email: 'Veda_Feeney@yahoo.com',
		firstName: 'Mckenna',
		id: 'wzxj9m',
		lastName: 'Friesen',
		state: 'New York',
	},
	{
		email: 'Melvin.Pacocha@yahoo.com',
		firstName: 'Wyman',
		id: '21dwtz',
		lastName: 'Jast',
		state: 'Montana',
	},
	{
		email: 'Delfina12@gmail.com',
		firstName: 'Janick',
		id: 'o8oe4k',
		lastName: 'Willms',
		state: 'Nebraska',
	},
	{
		email: 'Jerod14@hotmail.com',
		firstName: 'Kelvin',
		id: '9s41rp',
		lastName: 'Langosh',
		state: 'Ohio',
	},
	{
		email: 'Hugh.Dach79@hotmail.com',
		firstName: 'Molly',
		id: '08m6rx',
		lastName: 'Purdy',
		state: 'Rhode Island',
	},
	{
		email: 'Camden.Macejkovic@yahoo.com',
		firstName: 'Henry',
		id: '5ymtrc',
		lastName: 'Lynch',
		state: 'California',
	},
	{
		email: 'Eric0@yahoo.com',
		firstName: 'Glenda',
		id: 'ek5b97',
		lastName: 'Douglas',
		state: 'Montana',
	},
	{
		email: 'Ericka_Mueller52@yahoo.com',
		firstName: 'Leone',
		id: 'xxtydd',
		lastName: 'Williamson',
		state: 'Colorado',
	},
	{
		email: 'Veda_Feeney@yahoo.com',
		firstName: 'Mckenna',
		id: 'wzxj9m',
		lastName: 'Friesen',
		state: 'New York',
	},
	{
		email: 'Melvin.Pacocha@yahoo.com',
		firstName: 'Wyman',
		id: '21dwtz',
		lastName: 'Jast',
		state: 'Montana',
	},
	{
		email: 'Delfina12@gmail.com',
		firstName: 'Janick',
		id: 'o8oe4k',
		lastName: 'Willms',
		state: 'Nebraska',
	},
	{
		email: 'Jerod14@hotmail.com',
		firstName: 'Kelvin',
		id: '9s41rp',
		lastName: 'Langosh',
		state: 'Ohio',
	},
	{
		email: 'Hugh.Dach79@hotmail.com',
		firstName: 'Molly',
		id: '08m6rx',
		lastName: 'Purdy',
		state: 'Rhode Island',
	},
	{
		email: 'Camden.Macejkovic@yahoo.com',
		firstName: 'Henry',
		id: '5ymtrc',
		lastName: 'Lynch',
		state: 'California',
	},
	{
		email: 'Eric0@yahoo.com',
		firstName: 'Glenda',
		id: 'ek5b97',
		lastName: 'Douglas',
		state: 'Montana',
	},
	{
		email: 'Ericka_Mueller52@yahoo.com',
		firstName: 'Leone',
		id: 'xxtydd',
		lastName: 'Williamson',
		state: 'Colorado',
	},
	{
		email: 'Veda_Feeney@yahoo.com',
		firstName: 'Mckenna',
		id: 'wzxj9m',
		lastName: 'Friesen',
		state: 'New York',
	},
	{
		email: 'Melvin.Pacocha@yahoo.com',
		firstName: 'Wyman',
		id: '21dwtz',
		lastName: 'Jast',
		state: 'Montana',
	},
	{
		email: 'Delfina12@gmail.com',
		firstName: 'Janick',
		id: 'o8oe4k',
		lastName: 'Willms',
		state: 'Nebraska',
	},
];

//50 us states array
export const usStates = [
	'Alabama',
	'Alaska',
	'Arizona',
	'Arkansas',
	'California',
	'Colorado',
	'Connecticut',
	'Delaware',
	'Florida',
	'Georgia',
	'Hawaii',
	'Idaho',
	'Illinois',
	'Indiana',
	'Iowa',
	'Kansas',
	'Kentucky',
	'Louisiana',
	'Maine',
	'Maryland',
	'Massachusetts',
	'Michigan',
	'Minnesota',
	'Mississippi',
	'Missouri',
	'Montana',
	'Nebraska',
	'Nevada',
	'New Hampshire',
	'New Jersey',
	'New Mexico',
	'New York',
	'North Carolina',
	'North Dakota',
	'Ohio',
	'Oklahoma',
	'Oregon',
	'Pennsylvania',
	'Rhode Island',
	'South Carolina',
	'South Dakota',
	'Tennessee',
	'Texas',
	'Utah',
	'Vermont',
	'Virginia',
	'Washington',
	'West Virginia',
	'Wisconsin',
	'Wyoming',
	'Puerto Rico',
];
