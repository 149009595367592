'use client';

import * as realtimeSchema from '@packages/lib/schema/realtime';
import { UAParser } from 'ua-parser-js';

const parser = new UAParser();

type Schema = realtimeSchema.messages.UserPresenceMessage['profileData']['meta'];

export function getClientMeta(): Schema {
	const result = parser.getResult();

	const browserName = result.browser.name?.toLowerCase();
	const osName = result.os.name?.toLowerCase();
	const deviceType = result.device.type?.toLowerCase();

	let browser: Schema['browser'] = 'other';

	const possibleBrowsers = ['opera', 'firefox', 'edge', 'yandex', 'vivaldi', 'chromium', 'safari', 'chrome'] as const;

	for (const possibleBrowser of possibleBrowsers) {
		if (browserName?.includes(possibleBrowser)) {
			browser = possibleBrowser;
			break;
		}
	}

	let os: Schema['os'] = 'other';

	if (osName?.includes('windows')) {
		os = 'windows';
	} else if (osName?.includes('mac')) {
		os = 'macos';
	} else if (osName?.includes('linux')) {
		os = 'linux';
	}

	let device: Schema['device'] = 'desktop';

	if (['mobile', 'tablet'].includes(deviceType ?? '')) {
		device = deviceType as Schema['device'];
	}

	return {
		browser,
		device,
		os,
	};
}
