import type { Table } from '@tanstack/react-table';

import { Box, Skeleton } from '@mantine/core';
import clsx from 'clsx';
import React from 'react';

import { Cell, type ICellProps } from './components/Cell';
import { COLUMN_ID_EXPAND, COLUMN_ID_SELECT } from './contants';
import tableStyles from './table.module.scss';
import { ExpandPlaceholder } from './use-feature-expand';
import { SelectPlaceholder } from './use-feature-select';

// create an array of 100 items with values ranging from 20 to 100
const randomValues = Array.from({ length: 100 }, () => Math.floor(Math.random() * 80) + 20);

// create a function that will randomly pick one of the random values and return it
const randomValue = () => {
	const index = Math.floor(Math.random() * randomValues.length);
	return randomValues[index];
};

type ISkeletonProps = Pick<ICellProps, 'align' | 'justify'>;

const SkeletonRow: React.FC<{ columns: ({ id: string } & ISkeletonProps)[] }> = ({ columns }) => {
	return (
		<Box className={clsx(tableStyles.bodyRowSkeleton)} h={57}>
			{columns.map(({ align, id, justify }) => {
				let content: React.ReactElement = <Skeleton height={8} radius="xl" w={`${randomValue()}%`} />;

				if (id === COLUMN_ID_SELECT) {
					content = <SelectPlaceholder style={{ flex: 0, visibility: 'visible' }} />;
				}
				if (id === COLUMN_ID_EXPAND) {
					content = <ExpandPlaceholder style={{ flex: 0, visibility: 'visible' }} />;
				}
				return (
					<Cell key={`skeleton-${id}`} align={align} data-column-id={id} justify={justify} py="xs">
						{content}
					</Cell>
				);
			})}
		</Box>
	);
};

export const TableBodySkeleton: React.FC<{ numRows: number; table: Table<any>; className?: string }> = ({
	className,
	numRows,
	table,
}) => {
	const columns: ({ id: string } & ISkeletonProps)[] = React.useMemo(
		() =>
			table
				.getFlatHeaders()
				.filter((header) =>
					header.column.getIsVisible() && header.column.id === COLUMN_ID_SELECT
						? table.options.enableRowSelection
							? true
							: false
						: true,
				)
				.map((visibleHeader) => ({
					align: visibleHeader.column.columnDef.meta?.align,
					id: visibleHeader.column.id,
					justify: visibleHeader.column.columnDef.meta?.justify,
				})),
		[table],
	);

	return (
		<Box className={clsx(className)}>
			{Array.from({ length: numRows }).map((_, index) => (
				<React.Fragment key={index}>
					<SkeletonRow columns={columns} />
				</React.Fragment>
			))}
		</Box>
	);
};
