import { Box, Paper } from '@mantine/core';
import { createFileRoute } from '@tanstack/react-router';

import { AppPageControls, AppPageLayout } from '@/layouts/app-page';

export const Route = createFileRoute('/org/$slug/labels')({
	component: Page,
});

function Page() {
	return (
		<AppPageLayout maxWidth="100%" title="Labels" variant="apps">
			<AppPageControls>
				{/* <Tooltip label="Neue Bestellung anlegen">
					<Button leftSection={<IconPlus size={18} stroke={2} />} size="xs" variant="outline">
						Bestellung
					</Button>
				</Tooltip>
				<Divider orientation="vertical" /> */}
			</AppPageControls>

			<Box
				style={{
					display: 'grid',
					gap: '1rem',
					gridTemplateColumns: '1fr 3fr',
					height: '100%',
				}}
			>
				<Box style={{ display: 'grid', gap: '1rem', gridTemplateRows: 'auto 1fr' }}>
					<Paper p="md" radius="md" shadow="xs" style={{}}>
						Labels
					</Paper>
				</Box>
			</Box>
		</AppPageLayout>
	);
}
