import { useQuery } from '@tanstack/react-query';

import { useFrachterApi } from '@/frachter/hooks/use-frachter';

export const useQueryTableSizes = () => {
	const {
		database: { stats },
	} = useFrachterApi();

	return useQuery({
		queryFn: () => stats.tables(),
		queryKey: ['pglite', 'table-sizes'],
		refetchInterval: 3000, // Automatically refetch every 3 seconds
		staleTime: 3000,
	});
};
