'use client';

import { Box, Button, Paper, Pill, Space, Stack, Text, Title } from '@mantine/core';
import React from 'react';

import { pathFn } from '@/data/routes';

export const NotAuthorized: React.FC<{ next: string | null; slug: string }> = ({ next, slug }) => {
	// const router = useRouter();
	// useEffect(() => {
	// 	/**
	// 	 * this is required as the /org route that we link to would otherwise be cached
	// 	 * and not update when we execute the link
	// 	 *
	// 	 * @see https://nextjs.org/docs/app/building-your-application/caching#router-cache
	// 	 * @see https://nextjs.org/docs/app/building-your-application/caching#invalidation-1
	// 	 * @see https://nextjs.org/docs/app/api-reference/functions/use-router#userouter
	// 	 *
	// 	 */
	// 	// router.refresh();
	// }, [router]);

	return (
		<Paper
			p="lg"
			shadow="xs"
			style={{ display: 'grid', marginInline: 'auto', maxWidth: '90vw', placeContent: 'center', width: 500 }}
		>
			<Stack gap="sm">
				<Title c="danger" order={4}>
					Fehlende Zugriffsrechte
				</Title>
				<Text>Du bist nicht berechtig auf die folgende URL zuzugreifen:</Text>
				<Box>
					<Text fw="600">{next}</Text>
				</Box>
				<Text size="sm">
					Bitte wende Dich an den Administrator der Organisation{' '}
					<Pill c="sky" fw={600}>
						{slug}
					</Pill>
					, um Zugriff zu erhalten. Solltest Du bereits Mitglied dieser Organisation sein und keinen Zugriff haben,
					kontaktiere den Support.
				</Text>
				<Space />
				<Button
					bg="var(--mantine-color-text)"
					c="var(--mantine-color-body)"
					// onClick={() => redirect('/org')}
					component={'a'}
					href={pathFn.root({ view: 'org' })}
					// replace
				>
					Verfügbare Organisation anzeigen und anmelden.
				</Button>
			</Stack>
		</Paper>
	);
};
