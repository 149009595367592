import { useMutation } from '@apollo/client';
import { Box, Stack } from '@mantine/core';
import { OrganizationAddressSchema, OrganizationAddressTypeEnum } from '@packages/lib/schema/organization';
import { Control } from 'react-hook-form';
import { TextInput, Select } from 'react-hook-form-mantine';

import { graphql } from '@/gql/graphql';

export const OrganizationAddressFormFragment = graphql(`
	fragment OrganizationAddressFormFragment on Address @_unmask {
		id
		company
		gender
		firstName
		lastName
		street
		house
		countryCode
		city
		email
		zip
		phone
	}
`);

const OrganizationAddressUpdateMutation = graphql(
	`
		mutation OrganizationAddressUpdate($input: MutationOrganizationAddressUpdateInput!) {
			organizationAddressUpdate(input: $input) {
				... on MutationOrganizationAddressUpdateSuccess {
					data {
						...OrganizationAddressFormFragment
					}
				}
			}
		}
	`,
	[OrganizationAddressFormFragment],
);

const OrganizationAddressDeleteMutation = graphql(`
	mutation OrganizationAddressDelete($input: MutationOrganizationAddressDeleteInput!) {
		organizationAddressDelete(input: $input) {
			... on MutationOrganizationAddressDeleteSuccess {
				data {
					success
				}
			}
		}
	}
`);

export const useMutateOrganizationAddress = ({
	addressType,
	slug,
}: {
	addressType: OrganizationAddressTypeEnum; // 'primary' | 'secondary' | 'billing';
	slug: string;
}) => {
	const [mutateUpdate, mutationUpdate] = useMutation(OrganizationAddressUpdateMutation);
	const [mutateDelete, mutationDelete] = useMutation(OrganizationAddressDeleteMutation);

	return {
		delete: {
			execute: async (id: string) => {
				await mutateDelete({
					variables: {
						input: {
							id,
							slug,
							type: addressType,
						},
					},
				});
			},
			info: mutationDelete,
		},

		update: {
			execute: async (address: OrganizationAddressSchema) => {
				await mutateUpdate({
					variables: {
						input: {
							address,
							slug,
							type: addressType,
						},
					},
				});
			},
			info: mutationUpdate,
		},
	};
};

export const OrganizationAddressForm = ({
	control,
	disabled,
}: {
	disabled?: boolean;
	control: Control<OrganizationAddressSchema>;
	// address?: null | FragmentOf<typeof OrganizationAddressFormFragment>;
}) => {
	// const data = readFragment(OrganizationAddressFormFragment, address);

	return (
		<fieldset disabled={disabled}>
			<Stack>
				<TextInput
					control={control}
					label="Name des Unternehmens"
					name={`company`}
					placeholder="Name des Unternehmens"
				/>

				<Box style={{ display: 'grid', gap: 'var(--mantine-spacing-md)', gridTemplateColumns: 'auto 1fr 1fr' }}>
					<Select
						allowDeselect={false}
						control={control}
						label="Anrede"
						name="gender"
						w={80}
						data={[
							{ label: '-', value: 'unknown' },
							{ label: 'Herr', value: 'male' },
							{ label: 'Frau', value: 'female' },
						]}
					/>
					<TextInput control={control} label="Vorname" name="firstName" placeholder="Vorname" />
					<TextInput control={control} label="Nachname" name="lastName" placeholder="Nachname" />
				</Box>

				<Box style={{ display: 'grid', gap: 'var(--mantine-spacing-md)', gridTemplateColumns: '2fr 1fr' }}>
					<TextInput control={control} label="Straße" name="street" placeholder="Straße" required />
					<TextInput control={control} label="Hausnummer" name="house" placeholder="Hausnummer" required />
				</Box>

				<Box style={{ display: 'grid', gap: 'var(--mantine-spacing-md)', gridTemplateColumns: 'auto 0.75fr 2fr' }}>
					<Select
						control={control}
						label="Land"
						name="countryCode"
						placeholder="Land"
						required
						w={120}
						data={[
							{ label: 'Deutschland', value: 'DE' },
							{ label: 'Österreich', value: 'AT' },
							{ label: 'Schweiz', value: 'CH' },
						]}
					/>
					<TextInput control={control} label="Postleitzahl" name="zip" placeholder="Postleitzahl" required />
					<TextInput control={control} label="Ort" name="city" placeholder="Ort" required />
				</Box>

				<Box style={{ display: 'grid', gap: 'var(--mantine-spacing-md)', gridTemplateColumns: '1fr 1fr' }}>
					<TextInput control={control} label="E-Mail" name="email" placeholder="E-Mail" required />
					<TextInput control={control} label="Telefon" name="phone" placeholder="Telefon" />
				</Box>
			</Stack>
		</fieldset>
	);
};
