import { Box, Group, PinInput, rem, Stack, Text, useMantineTheme } from '@mantine/core';
import { useMutation } from '@tanstack/react-query';
import React, { ComponentProps, useCallback, useState } from 'react';
import { match } from 'ts-pattern';

import { useFrachterApi } from '@/frachter/hooks/use-frachter';
import { useFrachterUser } from '@/hooks/use-frachter-user';

import { DialogActions, Dialog } from '../Dialog/Dialog';

export const SetupCloudprintDialog = ({
	successActions,
	...props
}: {
	successActions: DialogActions;
} & Pick<ComponentProps<typeof Dialog>, 'noPaper'>) => {
	const { user } = useFrachterUser();
	const { cloudprint } = useFrachterApi();

	const [statusText, setStatusText] = useState<string>('Warte auf Eingabe');
	const theme = useMantineTheme();

	const [status, setStatus] = React.useState<'pending' | 'success'>('pending');

	const confirmConnectionMutation = useMutation({
		mutationFn: (code: string) => cloudprint.confirmCloudprintConnection(code),
		mutationKey: ['backendService', 'connect', 'cloudprint', 'confirm'],
	});

	const { mutateAsync } = confirmConnectionMutation;

	const handleOnComplete = useCallback(
		async (pin: string) => {
			setStatusText('Code wird geprüft');

			const result = await mutateAsync(pin);

			if ('data' in result) {
				match(result.data)
					.with('connected', () => {
						setStatusText('Verbindung erfolgreich hergestellt');
						setStatus('success');
					})
					.with('invalid', () => {
						setStatusText('Code konnte nicht gefunden werden');
					})
					.with(null, () => {
						setStatusText('Bitte versuche es erneut');
						console.error('unexpected response for cloudprint connection setup', result);
					})
					.exhaustive();
			} else {
				setStatusText('Bitte versuche es erneut');
				console.error('something did not quite work with cloudprint connection setup', result);
			}
		},
		[mutateAsync],
	);

	const leftSection = (
		<Dialog.Info
			title={'CloudPrint verbinden'}
			subTitle={
				<span>
					Stelle eine Verbindung mit <strong>CloudPrint</strong> her.
				</span>
			}
		>
			<Stack>
				<Text>
					Gib den 6-stelligen Code ein, der Dir in CloudPrint angezeigt wird. Nach der erfolgreichen Verbindung kannst
					du die Drucker in den Einstellungen verwalten.
					<br />
					<br />
					<strong>Hinweis:</strong> Du kannst verbundene Geräte jederzeit aus Deiner Organisation entfernen.
				</Text>
			</Stack>
		</Dialog.Info>
	);

	const { rightSection } = match<typeof status, { rightSection?: React.ReactNode }>(status)
		.with('pending', () => ({
			rightSection: (
				<Stack>
					<Group
						justify="center"
						my="lg"
						p="md"
						style={{
							backgroundColor: 'color-mix(in srgb, var(--mantine-color-text) 3%, transparent)',
							borderRadius: 4,
							// border: '1px solid color-mix(in srgb, var(--mantine-color-text) 10%, transparent)',
						}}
					>
						<Stack gap="xs">
							<PinInput
								disabled={confirmConnectionMutation.isPending || status === 'success'}
								fw={400}
								fz={rem(36)}
								length={6}
								onComplete={(v) => void handleOnComplete(v)}
								placeholder="•"
								size={'lg'}
								styles={{
									input: {
										color: theme.colors?.sky?.[5],
										fontFamily: theme.fontFamilyMonospace,
										fontSize: rem(36),
									},
								}}
							/>
							<Text c="dimmed" size="sm" ta="center">
								Groß- und Kleinbuchstaben berücksichtigen
							</Text>
						</Stack>
					</Group>
					<Box
						style={{
							border: '1px solid color-mix(in srgb, var(--mantine-color-text) 10%, transparent)',
							borderRadius: 4,
							width: '100%',
						}}
					>
						<Group px="md" py="sm">
							<Text c="dimmed" fw="700" size="sm">
								Status
							</Text>
							<Text c="dimmed" size="sm">
								{statusText}
							</Text>
						</Group>
					</Box>
				</Stack>
			),
		}))
		.with('success', () => ({
			rightSection: (
				<Dialog.Success
					message="Falls du bereits in einem anderen Fenster angemeldet bist, kannst du dieses Tab nun schließen oder direkt QuickShip öffnen."
					title="Cloudprint erfolgreich verbunden"
				>
					<Dialog.Actions {...successActions} />
				</Dialog.Success>
			),
		}))
		.exhaustive();

	return !user?.organization?.slug ? null : <Dialog leftSection={leftSection} rightSection={rightSection} {...props} />;
};
