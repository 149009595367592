import { Box, Group } from '@mantine/core';
import { flexRender, type Header, type HeaderGroup } from '@tanstack/react-table';
import clsx from 'clsx';
import React from 'react';

import type { IBaseTableData } from './types';

import { Cell } from './components/Cell';
import { FilterButton } from './components/FilterButton';
import { ResizeHandler } from './components/ResizeHandler';
import { SortingButton } from './components/SortingButton';
import { COLUMN_ID_EXPAND, COLUMN_ID_SELECT } from './contants';
import { useTableStore } from './store';
import tableStyles from './table.module.scss';
import { useResizeHeadColumnWatcher } from './use-feature-resize';

const HeadRowCell: React.FC<{ header: Header<IBaseTableData, unknown> }> = ({ header }) => {
	const { ref } = useResizeHeadColumnWatcher(header);

	const isSystemColumn = [COLUMN_ID_SELECT, COLUMN_ID_EXPAND].includes(header.column.id);
	const isPlaceholder = header.isPlaceholder;

	const canSort = header.column.getCanSort();
	const sortingState = useTableStore((state) => state.sorting.find((sorting) => sorting.id === header.column.id));

	const canFilter = header.column.getCanFilter();
	const filterState = useTableStore((state) => state.columnFilters.find((filter) => filter.id === header.column.id));

	const filterChild = React.useMemo(
		() =>
			header.column.columnDef.meta?.filter?.({
				selected: filterState?.value,
				setSelected: (newState) => {
					// Check if selected is an empty object
					const isEmptyObject =
						newState !== null &&
						typeof newState === 'object' &&
						!Array.isArray(newState) &&
						Object.keys(newState as {}).length === 0;

					const isEmpty =
						newState === undefined ||
						newState === null ||
						(Array.isArray(newState) && newState.length === 0) ||
						isEmptyObject;

					if (isEmpty) {
						header.column.setFilterValue(undefined);
					} else {
						header.column.setFilterValue(newState);
					}
				},
			}),
		[header.column, filterState],
	);

	return (
		<Cell
			key={header.id}
			ref={ref}
			align={header.column.columnDef.meta?.align}
			/* used for selecting columns for resizing through scoped generated style */
			data-column-id={header.column.id}
			justify={header.column.columnDef.meta?.justify}
			style={{
				...(header.column.columnDef.meta?.justify === 'end' && { flexDirection: 'row-reverse' }),
				justifyContent: 'space-between',
			}}
		>
			{isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
			{isPlaceholder || isSystemColumn ? null : (
				<Group gap={2}>
					{canSort && (
						<SortingButton
							onClick={() => header.column.toggleSorting()}
							sortDirection={sortingState?.desc === true ? 'desc' : sortingState?.desc === false ? 'asc' : null}
						/>
					)}
					{canFilter && filterChild && (
						<FilterButton
							isActive={Boolean(filterState?.value)}
							position={header.column.columnDef.meta?.justify === 'end' ? 'start' : 'end'}
						>
							{filterChild}
						</FilterButton>
					)}
				</Group>
			)}

			{isPlaceholder ? null : <ResizeHandler header={header} />}
		</Cell>
	);
};

export const TableHead: React.FC<{
	style?: React.CSSProperties;
	className?: string;
	headerGroups: HeaderGroup<IBaseTableData>[];
}> = ({ className, headerGroups, style }) => {
	// HINT: width of this is the sum of the widths of its children

	return (
		<Box className={className} style={style}>
			{headerGroups.map((headerGroup) => {
				return (
					<Box key={headerGroup.id} className={clsx(tableStyles.headRow)}>
						{headerGroup.headers.map((header) => {
							return <HeadRowCell key={header.id} header={header} />;
						})}
					</Box>
				);
			})}
		</Box>
	);
};

export const MemoizedTableHead = React.memo(TableHead);
