import { ActionIcon, Group, Text } from '@mantine/core';
import { Stack } from '@mantine/core';
import { IconArrowRight, IconSettingsFilled } from '@tabler/icons-react';
import clsx from 'clsx';
import { useCallback } from 'react';
import { LiteralUnion } from 'type-fest';

import { ROLE_MAPPING } from '@/data/constants';
import { GenericUserOrganizationClient, UserRole } from '@/type-defs/auth';

import s from './OrganizationListItem.module.scss';

const getRoleName = (role: LiteralUnion<UserRole, string>) => {
	if (role in ROLE_MAPPING) {
		return ROLE_MAPPING[role as UserRole];
	}

	return 'Unbekannt';
};

export const OrganizationListItem: React.FC<{
	isActive: boolean;
	onOpenSettings: () => void;
	onSelect: (org: GenericUserOrganizationClient) => void;
	org: GenericUserOrganizationClient;
}> = ({ isActive, onOpenSettings, onSelect, org }) => {
	const roleName = getRoleName(org.role);

	const handleClick = useCallback(() => {
		if (isActive) return;
		onSelect(org);
	}, [isActive, onSelect, org]);

	return (
		<>
			<Group key={org.id} className={clsx(s.root, !isActive && s.selectable)} onClick={handleClick} px="lg" py="md">
				<img
					alt={org.name}
					crossOrigin="anonymous"
					height={36}
					src={org.imageUrl}
					style={{ borderRadius: 4 }}
					width={36}
				/>
				<Stack flex={1} gap={0} justify="center">
					<Text {...(!isActive && { c: 'dimmed' })} fw={500} size="sm">
						{org.name}
					</Text>
					{isActive && (
						<Text c="dimmed" size="sm">
							{roleName}
						</Text>
					)}
				</Stack>
				{isActive ? (
					<ActionIcon
						aria-label="Settings"
						c="var(--mantine-color-text)"
						color="var(--mantine-color-body)"
						onClick={onOpenSettings}
						size="md"
						variant="filled"
					>
						<IconSettingsFilled size={16} />
					</ActionIcon>
				) : (
					<ActionIcon
						aria-label="Settings"
						c="var(--mantine-color-text)"
						color="var(--mantine-color-body)"
						size="md"
						variant="transparent"
					>
						<IconArrowRight size={16} />
					</ActionIcon>
				)}
			</Group>
		</>
	);
};
