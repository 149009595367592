'use memo';

import type { MRT_ColumnDef } from 'mantine-react-table';

import { Select, TextInput } from '@mantine/core';
import React from 'react';

import type { IRowType } from './types';

import { currencies, weightUnits } from './makeData';
import { useProductsTableStore } from './store';

const MIN_COLUMN_WIDTH = 80;

const validateRequired = (value: string) => !!value?.length;
const validateEmail = (email: string) =>
	!!email.length &&
	email
		.toLowerCase()
		.match(
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
		);

type TextInputCellOptions = { type: 'text' } | { type: 'number'; decimals?: number };

const createTextInputCell: (options: TextInputCellOptions) => MRT_ColumnDef<IRowType>['Edit'] = (options) =>
	function TextInputCell({ cell, column, row, table }) {
		const validationError = useProductsTableStore((s) => s.validationErrors[row.id]);

		const setValidationError = useProductsTableStore((s) => s.setValidationError);
		const setEditedRowValue = useProductsTableStore((s) => s.setEditedRowValue);
		const rowValue = row.getValue(column.id);

		const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
			const currentValue = event.currentTarget.value;

			// TODO: also reset edited items if they are no longer edited

			if (rowValue === currentValue) return;

			const validationError = !validateRequired(event.currentTarget.value) ? 'Required' : undefined;
			setValidationError(row.id, validationError);

			setEditedRowValue(row.id, { [column.id]: currentValue });
		};

		return (
			<TextInput
				defaultValue={cell.getValue() as string}
				error={validationError}
				onBlur={handleBlur}
				required={false}
				type="text"
				variant="unstyled"
				styles={{
					input:
						options.type === 'number'
							? {
									textAlign: 'right',
								}
							: undefined,
				}}
			/>
		);
	};

const createSelectCell: (data: string[]) => MRT_ColumnDef<IRowType>['Edit'] = (data) =>
	function SelectCell({ cell, column, row, table }) {
		const setEditedRowValue = useProductsTableStore((s) => s.setEditedRowValue);
		const rowValue = row.getValue(column.id);

		const handleBlur = (currentValue: string | null) => {
			// TODO: also reset edited items if they are no longer edited

			if (rowValue === currentValue) return;
			setEditedRowValue(row.id, { [column.id]: currentValue });
		};

		return <Select data={data} defaultValue={cell.getValue() as string} onChange={handleBlur} variant="unstyled" />;
	};

export const useCreateColumns = () => {
	const [columns] = React.useState<MRT_ColumnDef<IRowType>[]>([
		// {
		// 	accessorKey: 'id',
		// 	enableEditing: false,
		// 	header: 'Id',
		// 	size: 80,

		// },
		{
			accessorKey: 'sku',
			Edit: createTextInputCell({ type: 'text' }),
			header: 'SKU',
			minSize: MIN_COLUMN_WIDTH,
		},
		{
			accessorKey: 'ean',
			Edit: createTextInputCell({ type: 'text' }),
			header: 'EAN',
			minSize: MIN_COLUMN_WIDTH,
		},
		{
			accessorKey: 'weight',
			Edit: createTextInputCell({ decimals: 2, type: 'number' }),
			enableColumnActions: false,
			enableSorting: false,
			// enableColumnFilter: false,
			// enableGlobalFilter: false,
			header: 'Gewicht',
			mantineTableHeadCellProps: {
				align: 'right',
			},
			maxSize: MIN_COLUMN_WIDTH,
		},
		{
			accessorKey: 'weightUnit',
			Edit: createSelectCell(weightUnits),
			editVariant: 'select',
			enableColumnActions: false,
			enableSorting: false,
			header: 'Einheit',
			maxSize: MIN_COLUMN_WIDTH,
		},
		{
			accessorKey: 'title',
			Edit: createTextInputCell({ type: 'text' }),
			header: 'Produktname',
		},
		// {
		// 	accessorKey: 'description',
		// 	enableColumnActions: false,
		// 	enableSorting: false,
		// 	header: 'Beschreibung',
		// 	mantineEditTextInputProps: ({ cell, row }) => ({
		// 		// value: cell.getValue(),
		// 		error: validationErrors?.[cell.id],
		// 		//store edited user in state to be saved later
		// 		onBlur: (event) => {
		// 			// const validationError = !validateRequired(event.currentTarget.value) ? 'Required' : undefined;
		// 			// setValidationErrors({
		// 			// 	...validationErrors,
		// 			// 	[cell.id]: validationError,
		// 			// });
		// 			setEditedRows({ ...editedRows, [row.id]: row.original });
		// 		},
		// 		required: false,
		// 		type: 'text',
		// 	}),
		// },
		{
			accessorKey: 'price',
			Edit: createTextInputCell({ decimals: 2, type: 'number' }),
			enableColumnActions: false,
			enableSorting: false,
			header: 'Preis',

			mantineTableHeadCellProps: {
				align: 'right',
			},
			maxSize: MIN_COLUMN_WIDTH,
		},
		{
			accessorKey: 'currency',
			Edit: createSelectCell(currencies),
			editVariant: 'select',
			enableColumnActions: false,
			enableSorting: false,
			header: 'Währung',
			maxSize: MIN_COLUMN_WIDTH,
		},
	]);

	return columns;
};
