import type { HeaderGroup } from '@tanstack/react-table';

import { Box } from '@mantine/core';
import clsx from 'clsx';
import { flexRender } from 'mantine-react-table';
import React from 'react';

import type { IBaseTableData } from './types';

import { Cell } from './components/Cell';
import tableStyles from './table.module.scss';

export const TableFoot: React.FC<{
	className?: string;
	style?: React.CSSProperties;
	footerGroups: HeaderGroup<IBaseTableData>[];
}> = ({ className, footerGroups, style }) => {
	return (
		<Box className={className} style={style}>
			{footerGroups.map((footerGroup) => (
				<Box key={footerGroup.id} className={clsx(tableStyles.footRow)}>
					{footerGroup.headers.map((header) => {
						return (
							<Cell
								key={header.id}
								align={header.column.columnDef.meta?.align}
								/* used for selecting columns for resizing through scoped generated style */
								data-column-id={header.column.id}
								justify={header.column.columnDef.meta?.justify}
							>
								{header.isPlaceholder ? null : flexRender(header.column.columnDef.footer, header.getContext())}
							</Cell>
						);
					})}
				</Box>
			))}
		</Box>
	);
};

export const MemoizedTableFoot = React.memo(TableFoot);
