import { Divider, Group } from '@mantine/core';

import { GenericUserOrganizationClient } from '@/type-defs/auth';

import { OrganizationMenu } from './OrganizationMenu';
import { ProfileMenu } from './ProfileMenu';

export const AccountControl: React.FC<{
	hideOrganizationMenu?: boolean;
	currentOrganizationId: string;
	memberships: GenericUserOrganizationClient[];
}> = ({ currentOrganizationId, hideOrganizationMenu, memberships }) => {
	const org = memberships.find((m) => m.id === currentOrganizationId);

	return (
		<Group gap="xs">
			{!hideOrganizationMenu && (
				<>
					<Divider orientation="vertical" />
					<OrganizationMenu currentOrganization={org ?? null} memberships={memberships} />
				</>
			)}
			<ProfileMenu />
		</Group>
	);
};

/** ----------------------------------------------------------------------------------------------
 * id
 * name
 * slug
 * role
 * imageUrl
 * permissions
 * _______________________________________________________________________________________________ */
