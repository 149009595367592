import { Box, Text } from '@mantine/core';
import CountUp from 'react-countup';

export const Counter = ({ end = 0 }: { end: number }) => {
	return (
		<CountUp
			decimals={0}
			// duration={2.75}
			// end={160527.012}
			// onEnd={() => console.log('Ended! 👏')}
			// onStart={() => console.log('Started! 💨')}
			// prefix="EUR "
			// separator=" "
			end={end}
			preserveValue
			start={0}
			// suffix=" left"
		>
			{({ countUpRef }) => (
				<Box>
					<Text>
						<span ref={countUpRef} />
					</Text>
				</Box>
			)}
		</CountUp>
	);
};
