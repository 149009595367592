import { compile, match as matchPath } from 'path-to-regexp';

import { constants } from './constants';
import { env } from './env';

export { matchPath };

export const withOrigin = (path: string) => `${env.PUBLIC_SITE_URL}${path}`;

const template = {
	api: `/api/:endpoint{/:path}?`,
	apps: `/org/:slug/:app{/:view}?`,
	root: '{/:view}?',
};

// eslint-disable-next-line @typescript-eslint/no-namespace
declare namespace Params {
	export type root = { view?: 'org' | 'login' | 'playground' };
	export type apps = { slug: string } & (
		| { app: 'quickship' | 'dashboard' | 'history' }
		| {
				app: 'settings';
				view?:
					| 'quickship'
					| 'shipping-rules'
					| 'carriers'
					| 'markets'
					| 'cloudprint'
					| 'products'
					| 'system'
					| 'organization'
					| 'billing';
		  }
	);
	export type api = { endpoint: 'auth'; path: 'logout' };
}

export const getDefaultAppPath = (slug: string) => pathFn.apps({ app: constants.DEFAULT_ORGANIZATION_APP, slug });

export const pathFn = {
	api: compile<Params.api>(template.api, { encode: encodeURIComponent }),
	apps: compile<Params.apps>(template.apps, { encode: encodeURIComponent }),
	root: compile<Params.root>(template.root, { encode: encodeURIComponent }),
};

/**
 * use like
 * `matchFn.apps(pathname)`
 */
export const matchFn = {
	api: matchPath<Params.api>(template.api),
	apps: matchPath<Params.apps>(template.apps),
	org: matchPath('/org{/:path}*'),
	requiresCurrentOrganization: matchPath<{ path: string[]; slug: string }>('/(org){/:slug}{/:path}*'),
	requiresCurrentOrganizationAdmin: matchPath<{ path: string[]; slug: string }>('/org/:slug/settings{/:path}*'),
	root: matchPath<Params.root>(template.root),
};
