'use no memo';

import { Box, Button, Paper, Text } from '@mantine/core';
import React from 'react';

import type { IRowType } from './types';

import {
	Table,
	TableStoreProvider,
	createTypedTableStoreHook,
	MemoizedTableBottomBar,
	MemoizedTableTopBar,
} from '@/components/table';

import { useDataQuery } from './queries';
import { useCreateColumns } from './use-create-columns';

const useProductTableStore = createTypedTableStoreHook<IRowType>();

export const ProductsTable: React.FC<{}> = () => {
	return (
		<TableStoreProvider<IRowType>>
			<ProductsTableView />
		</TableStoreProvider>
	);
};

const ProductsTableView: React.FC<{}> = () => {
	const pagination = useProductTableStore((state) => state.pagination);
	const setIsFetching = useProductTableStore((state) => state.setIsFetching);
	const sortingState = useProductTableStore((state) => state.sorting);
	const columnFilters = useProductTableStore((state) => state.columnFilters);

	const sortingKeyItem = sortingState.at(0);

	const [search, setSearch] = React.useState('');

	const [enableSelectRows, setEnableSelectRows] = React.useState(true);

	// console.log(selectedRows);

	const columns = useCreateColumns();

	const [dataIndex, setDataIndex] = React.useState(0);

	const { paginatedQueryKey, query: query } = useDataQuery({
		dataIndex,
		filter: columnFilters,
		pagination,
		search,
		sort: sortingKeyItem,
	});

	React.useEffect(() => {
		setIsFetching(query.isFetching);
	}, [query.isFetching, setIsFetching]);

	const handleSearch = React.useCallback((value: string) => {
		setSearch(value);
	}, []);

	return (
		<>
			{/* HINT: we MUST set width! otherwise the table will exceed the width */}
			<Box style={{ display: 'flex', flexDirection: 'column', gap: '1rem', height: '100%' }}>
				<Button onClick={() => setDataIndex((prev) => (prev + 1) % 2)}>change data</Button>
				<Button onClick={() => setEnableSelectRows((prev) => !prev)}>toggle select rows</Button>
				<Paper
					radius="md"
					shadow="xl"
					style={{
						display: 'flex',
						flex: 1,
						overflow: 'hidden',
						// paddingInline: '1px',
					}}
				>
					<Table<IRowType>
						columns={columns}
						data={query.data?.items ?? []}
						enableRowSelection={enableSelectRows}
						getRowCanExpand={() => true}
						queryKey={paginatedQueryKey}
						tableBottomBar={<MemoizedTableBottomBar isFetching={query.isFetching} />}
						tableTopBar={<MemoizedTableTopBar search={{ onChange: handleSearch }} />}
						totalRowCount={query.data?.total ?? 0}
						renderExpanded={({ row }) => {
							return (
								<Box>
									<Box
										style={{
											alignContent: 'center',
											display: 'flex',
											flexDirection: 'column',
											gap: '0.5rem',
											minHeight: 36,
										}}
									>
										<Text size="sm"> hello </Text>
										<Text size="sm">{row.original.title}</Text>
										{/* <Text size="sm">{row.original.description}</Text> */}
									</Box>
								</Box>
							);
						}}
						styles={{
							cell: {
								py: 'xs',
							},
							content: {
								// HINT: if we don't set this, the head and foot row background will overlay the paper's side shadow
								px: 2,
							},
							frame: {
								// px: '4rem',
							},
						}}
					/>
				</Paper>
			</Box>
		</>
	);
};
