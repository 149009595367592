import Spaces, { Space } from '@ably/spaces';
import { ErrorInfo } from 'ably';
import { Effect, Stream, SubscriptionRef } from 'effect';

import { RealtimeClientService } from './RealtimeClientService';

declare global {
	interface Window {
		__spaces__?: Record<string, Space>;
	}
}

export const makeRealtimeSpace = <TSubscriptionRef extends Record<string, any>>(
	name: string,
	spaces: Spaces,
	presenceDataSubscriptionRef?: SubscriptionRef.SubscriptionRef<TSubscriptionRef>,
) =>
	Effect.gen(function* () {
		const realtimeClient = yield* RealtimeClientService;

		let isClosed = false;

		const space = yield* Effect.promise(() => spaces.get(name, { offlineTimeout: 10_000 }));

		const _attach = space.channel.attach.bind(space.channel);

		/** ----------------------------------------------------------------------------------------------
		 * Override the attach method to catch connection closed errors.
		 * There is no other way currently to catch these errors. But they happen regularly when we switch orgs.
		 *
		 * Underlying reason seems to be that ably is still trying to get members from the presence info due
		 * to the fact that ably assumes that the connection might be recovered withing a certain timeframe.
		 * But since we manually and intentionally close the connection, this is not possible anymore and
		 * will therefore throw.
		 *
		 * We use the `isClosed` flag to determine if the finalizers already ran, which means we've closed
		 * the connection manually and we check for error code 80017, which is the ably code for connection closed.
		 *
		 * Maybe this will be fixed with an upcoming release. Ably seems to actively work on the SDK again
		 * since November 2024. (However, we already tried the master branch on 04/12/2024 without success)
		 * _______________________________________________________________________________________________ */

		space.channel.attach = async (...args) => {
			return _attach(...args).catch((err) => {
				if (isClosed && 'cause' in err && err.cause instanceof ErrorInfo) {
					if ((err.cause as ErrorInfo).code === 80017) {
						// HINT 80017 is connection closed, which happens, when we close the connection manually
						console.debug('Ably SDK: Trying to throw an error during attach after the connection was closed manually.');
						return null;
					}
				}
				throw err;
			});
		};

		let reattachTimeout: NodeJS.Timeout | undefined;

		space.channel.on('failed', () => {
			console.debug(`space channel ${name} failed, will try to reattach in 5s`);
			if (reattachTimeout) {
				clearTimeout(reattachTimeout);
			}

			reattachTimeout = setTimeout(() => {
				void space.channel.attach();
			}, 5_000);
		});

		/**
		 * we must enter a space to be able to use it with locks
		 * by not chaining the promise with `.then` but instead using two yield*,
		 * we can ensure that in case we're waiting for a connection but then switch the org,
		 * we will not execute the next step
		 */
		yield* Effect.promise(() => realtimeClient.client.connection.whenState('connected'));
		// HINT: we don't set any presence data and instead derive it from the user's organization members

		const initialPresenceData = presenceDataSubscriptionRef
			? yield* SubscriptionRef.get(presenceDataSubscriptionRef)
			: undefined;

		// HINT: this should update the presence, whenever it changes
		// and stop, when the scope is closed
		if (presenceDataSubscriptionRef) {
			yield* presenceDataSubscriptionRef?.changes.pipe(
				Stream.changes,
				Stream.runForEach((change) => Effect.promise(() => space.updateProfileData(change))),
				Effect.interruptible,
				Effect.forkScoped,
			);
		}

		yield* Effect.addFinalizer((exit) =>
			Effect.gen(function* () {
				isClosed = true;
				yield* Effect.logDebug('makeSpace: finalizer START');
				yield* Effect.promise(() =>
					space.leave().catch((err) => Effect.logError(`makeSpace: finalize | could not leave space ${name}`, err)),
				);
				space.off();
				yield* Effect.logDebug('makeSpace: finalizer END');
			}),
		);

		yield* Effect.promise(() => space.enter(initialPresenceData));

		window.__spaces__ ??= {};
		window.__spaces__[name] = space;

		return space;
	});
