import { useFrachterUser } from './use-frachter-user';

/**
 * Frachter Client ID is being used as a prefix for
 * local storage keys so that each user can have different settings
 * per organization.
 */
export function useFrachterClientId(silenceWarnings = false) {
	const { user } = useFrachterUser();

	if (!user?.id && !silenceWarnings) {
		console.warn(`Warning: User is not defined. This will break any components using frachter client id.`);
	}

	if (!user?.organization?.id && !silenceWarnings) {
		console.warn(`Warning: User organization is not defined. This will break any components using frachter client id.`);
	}

	return `${user?.id ?? 'UNDEFINED'}:${user?.organization?.id ?? 'UNDEFINED'}`;
}
