'use client';

import { ActionIcon, Box, Group, Text } from '@mantine/core';
import { IconArrowLeft as IconBack, IconArrowRight as IconForward, IconHistory } from '@tabler/icons-react';
import clsx from 'clsx';

import sElectron from './ElectronAppBar.module.scss';

export const ElectronAppBar = () => {
	return (
		<Box
			key="electron-appbar"
			className={clsx(sElectron.dragger, 'electron', 'electron-appbar')}
			style={{
				display: 'grid',
				gridTemplateColumns: '1fr auto 1fr',
				paddingLeft: 100,
				paddingRight: 100,
				placeContent: 'center',
			}}
		>
			<Group gap="xs">
				<ActionIcon
					c="color-mix(in srgb, white 70%, transparent)"
					onClick={() => window.history.back()}
					size="xs"
					variant="transparent"
				>
					<IconBack />
				</ActionIcon>
				<ActionIcon
					c="color-mix(in srgb, white 70%, transparent)"
					onClick={() => window.history.forward()}
					size="xs"
					variant="transparent"
				>
					<IconForward />
				</ActionIcon>
				<ActionIcon c="color-mix(in srgb, white 70%, transparent)" ml="xs" size="xs" variant="transparent">
					<IconHistory />
				</ActionIcon>
			</Group>
			<Text c="dimmed" fw={600} fz={12} mt={2} ta="center">
				frachter
			</Text>
		</Box>
	);
};
