import { createFileRoute, Outlet } from '@tanstack/react-router';

import { AppPageLayout } from '@/layouts/app-page';

export const Route = createFileRoute('/org/$slug/playground')({
	component: Page,
});

function Page() {
	return (
		<AppPageLayout maxWidth="100%" title="Playground" variant="apps">
			{/* <AppPageControls>
			
			</AppPageControls> */}

			<Outlet />
		</AppPageLayout>
	);
}
