import { z } from 'zod';
/** ==============================================================================================
 *
 * MESSAGES USERS SEND TO CLOUDPRINT CLIENTS AS REQUESTS
 *
 * _______________________________________________________________________________________________ */
export const printJobRequestDataSchema = z.object({
    jobs: z.array(z.object({
        copies: z.number().optional().describe('the number of copies to print'),
        eid: z.string().nullable().optional().describe('external id of the order'),
        eid2: z.string().nullable().optional().describe('external id2 of the order'),
        id: z.string().describe('frachter reference id, e.g. order id'),
        pagesToPrint: z.string().optional().describe('like `1-4,7,9-12` or just `1`'),
        url: z.string().describe('file url to print'),
    })),
    printerName: z.string().describe('The name of the printer as understood by the OS.'),
    // topic: z.string().describe('The topic of the message, usually a hash over parts of the published data'),
});
export const cloudprintDisconnectRequestDataSchema = z.object({
    type: z.literal('CLOUDPRINT_DISCONNECT_REQUEST'),
});
// printjob
// disconnect request
/** ==============================================================================================
 *
 * MESSAGES CLOUDPRINT CLIENTS SEND TO USERS IN RESPONSE
 *
 * _______________________________________________________________________________________________ */
export const printJobResponseDataSchema = z.object({
    jobs: z.array(z.object({
        id: z.string().describe('frachter reference id, e.g. order id'),
        /**
         * TIMEOUT: when we time out the request after ~60 seconds
         * ABORTED: when the user forcefully aborts pending transactions
         * NOT_FOUND: when the requested file could not be found and we receive a 404 -> rarely to happen
         * INVALID_CONTENT_TYPE: when the header content type is not `application/pdf` -> rarely to happen
         * INVALID_RESPONSE: when we receive any other 4xx or 5xx response -> should usually not happen as we temporarily store the pdf
         */
        status: z.enum([
            /* these are currently issued by cloudprint */
            'OK',
            'ERROR_NOT_FOUND',
            'ERROR_INVALID_CONTENT_TYPE',
            'ERROR_INVALID_RESPONSE',
            'ERROR_NO_PRINTER',
            /* these are issued by frachter */
            'ERROR_TIMEOUT',
            'ERROR_ABORTED',
        ]),
    })),
});
// export const batchPrintJobResponseDataSchema = z.object({
// 	jobs: z.array(printJobResponseDataSchema),
// });
// export type BatchPrintJobResponseDataSchema = z.infer<typeof batchPrintJobResponseDataSchema>;
