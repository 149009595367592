'use client';

import { Box } from '@mantine/core';
import { AnimatePresence } from 'framer-motion';
import React from 'react';

export const ContentWrapper: React.FC<React.PropsWithChildren<{ className?: string; style?: React.CSSProperties }>> = ({
	children,
	className,
	style,
}) => {
	return (
		<Box className={className ?? ''} style={style ?? {}}>
			<AnimatePresence mode="sync">{children}</AnimatePresence>
		</Box>
	);
};
