import { z } from 'zod';
export const cloudprintRealtimeClientIdSchema = z.custom((val) => {
    return typeof val === 'string' ? val.startsWith('cloudprint:') : false;
});
export const userRealtimeClientIdSchema = z.custom((val) => {
    return typeof val === 'string' ? val.startsWith('frachter:') : false;
});
const ablyMessageActionSchema = z.enum([
    'message.unset',
    'message.create',
    'message.update',
    'message.delete',
    'annotation.create',
    'annotation.delete',
    'meta.occupancy',
]);
const ablyPresenceActionSchema = z.enum(['absent', 'present', 'enter', 'leave', 'update']);
export const ablyMessageSchema = z.object({
    action: ablyMessageActionSchema
        .optional()
        .describe('The action type of the message, one of the {@link MessageAction} enum values.'),
    clientId: z.string().optional().describe('The client ID of the publisher of this message.'),
    connectionId: z.string().optional().describe('The connection ID of the publisher of this message.'),
    createdAt: z
        .number()
        .optional()
        .describe('The timestamp of the very first version of a given message (will differ from createdAt only if the message has been updated or deleted).'),
    data: z.any().optional().describe('The message payload, if provided.'),
    encoding: z
        .string()
        .optional()
        .describe('This is typically empty, as all messages received from Ably are automatically decoded client-side using this value. However, if the message encoding cannot be processed, this attribute contains the remaining transformations not applied to the `data` payload.'),
    extras: z
        .any()
        .optional()
        .describe('A JSON object of arbitrary key-value pairs that may contain metadata, and/or ancillary payloads. Valid payloads include `push`, `delta`, `ref` and `headers`.'),
    id: z.string().optional().describe('Unique ID assigned by Ably to this message.'),
    name: z.string().optional().describe('The event name'),
    operation: z
        .object({
        clientId: z.string().optional(),
        description: z.string().optional(),
        metadata: z.record(z.string(), z.string()).optional(),
    })
        .optional()
        .describe('In the case of an updated or deleted message, this will contain metadata about the update or delete operation.'),
    refSerial: z.string().optional().describe('If this message references another, the serial of that message.'),
    refType: z.string().optional().describe('If this message references another, the type of reference that is.'),
    serial: z
        .string()
        .optional()
        .describe(`This message's unique serial (an identifier that will be the same in all future updates of this message).`),
    timestamp: z
        .number()
        .optional()
        .describe('Timestamp of when the message was received by Ably, as milliseconds since the Unix epoch. (This is the timestamp of the current version of the message)'),
    version: z
        .string()
        .optional()
        .describe('The version of the message, lexicographically-comparable with other versions (that share the same serial) Will differ from the serial only if the message has been  updated or deleted.'),
});
export const ablyInboundMessageSchema = ablyMessageSchema.extend({
    action: ablyMessageActionSchema.describe('The action type of the message, one of the {@link MessageAction} enum values.'),
    id: z.string().describe('Unique ID assigned by Ably to this message.'),
    serial: z
        .string()
        .describe(`This message's unique serial (an identifier that will be the same in all future updates of this message).`),
    timestamp: z
        .number()
        .describe('Timestamp of when the message was received by Ably, as milliseconds since the Unix epoch. (This is the timestamp of the current version of the message)'),
});
export const ablySpaceMemberSchema = z.object({
    clientId: z.string(),
    connectionId: z.string(),
    isConnected: z.boolean(),
    lastEvent: z.object({
        name: ablyPresenceActionSchema,
        timestamp: z.number(),
    }),
    location: z.unknown(),
    profileData: z.record(z.string(), z.unknown()).nullable(),
});
