import React from 'react';

import { IOrganizationIdStore } from '../effect/FrachterService';

import { useFrachter } from './use-frachter';

export const useOrganizationId = <
	K extends keyof IOrganizationIdStore,
	T extends IOrganizationIdStore | IOrganizationIdStore[K],
>(
	selector: (snapshot: IOrganizationIdStore) => T,
) => {
	const frachter = useFrachter();

	return React.useSyncExternalStore(frachter.$organizationId.subscribe, () =>
		selector(frachter.$organizationId.getSnapshot()),
	);
};
