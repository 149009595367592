import type { DisplayColumnDef, Row, Table } from '@tanstack/react-table';

import { ActionIcon } from '@mantine/core';
import { IconChevronDown, IconChevronRight } from '@tabler/icons-react';
import React from 'react';

import type { IBaseTableData, IRenderExpanded } from './types';

import { useForwardParentClick } from '@/hooks/use-forward-parent-click';

import { COLUMN_ID_EXPAND } from './contants';
import { useTableStore } from './store';

const ExpandRow: React.FC<{ row?: Row<IBaseTableData>; canExpand?: boolean }> = ({ canExpand, row }) => {
	const [ref] = useForwardParentClick<HTMLButtonElement>();
	const isVisible = row?.id && canExpand;

	const isExpanded = useTableStore((store) => {
		if (!row) return false;
		return typeof store.expanded === 'boolean' ? store.expanded : store.expanded[row.id];
	});

	return (
		<ActionIcon
			ref={ref}
			c={!isExpanded ? 'color-mix(in srgb, var(--mantine-color-text) 30%, transparent)' : 'blue'}
			onClick={row?.getToggleExpandedHandler()}
			size="sm"
			variant="transparent"
			style={{
				cursor: 'pointer',
				pointerEvents: isVisible ? 'auto' : 'none',
				visibility: isVisible ? 'visible' : 'hidden',
			}}
		>
			{isExpanded ? <IconChevronDown /> : <IconChevronRight />}
		</ActionIcon>
	);
};

const ExpandAll: React.FC<{ table: Table<IBaseTableData> }> = ({ table }) => {
	const [ref] = useForwardParentClick<HTMLButtonElement>();
	const isVisible = table.getCanSomeRowsExpand();

	const expandedRows = useTableStore((store) => store.expanded);

	const isAllExpanded = expandedRows === true;
	const isSomeExpanded = typeof expandedRows === 'object' && Object.keys(expandedRows).length > 0;

	return (
		<ActionIcon
			ref={ref}
			c={!isSomeExpanded && !isAllExpanded ? 'color-mix(in srgb, var(--mantine-color-text) 30%, transparent)' : 'blue'}
			onClick={() => table.toggleAllRowsExpanded()}
			size="sm"
			variant="transparent"
			style={{
				cursor: 'pointer',
				pointerEvents: isVisible ? 'auto' : 'none',
				visibility: isVisible ? 'visible' : 'hidden',
			}}
		>
			{isAllExpanded ? <IconChevronDown /> : isSomeExpanded ? <IconChevronRight /> : <IconChevronRight />}
		</ActionIcon>
	);
};

export const ExpandPlaceholder: React.FC<{ className?: string; style?: React.CSSProperties }> = ({
	className,
	style,
}) => {
	return (
		<ActionIcon
			c="color-mix(in srgb, var(--mantine-color-text) 10%, transparent)"
			className={className}
			size="sm"
			style={{ visibility: 'hidden', ...style }}
			variant="transparent"
		>
			<IconChevronRight />
		</ActionIcon>
	);
};

const expandedColumn: DisplayColumnDef<IBaseTableData> = {
	cell: ({ row }) => {
		return <ExpandRow canExpand={row.getCanExpand()} row={row} />;
	},
	footer: () => <ExpandPlaceholder />,
	header: ({ table }) => <ExpandAll table={table} />,
	id: COLUMN_ID_EXPAND,
	meta: {
		align: 'center',
	},
	size: 80,
};

export const useFeatureExpand = <TData extends IBaseTableData>(props: { renderExpanded?: IRenderExpanded<TData> }) => {
	return {
		column: props.renderExpanded ? expandedColumn : undefined,
		render: props.renderExpanded,
	};
};
