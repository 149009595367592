import { createClient } from '@hey-api/client-fetch';
import * as sdk from '../__generated__/hey-api/sdk.gen';
import * as types from '../__generated__/hey-api/types.gen';
import * as zod from '../__generated__/hey-api/zod.gen';
import { makeApiClass } from '../../../utils/make-api-class';
import { createOrdersHandler } from '../lib/create-orders-handler';
import { request } from '../mocks';
import { spec } from '../spec';
/**
 * The DHL Parcel DE Shipping Post Parcel Germany V2 API service client.
 *
 * Credentials are **static** so they should be provided at initialization if not passing the
 * request on to the proxy where they will be set
 */
export class DhlParcelDeShippingPostParcelGermanyV2Sdk extends makeApiClass({ sdk, spec, zod }) {
    static getClient(options, clientOptions) {
        return createClient({
            ...clientOptions,
            baseUrl: this.spec.api.baseUrl[options.environment],
            headers: {
                ...clientOptions?.headers,
                ...(options.language && { 'Accept-Language': options.language }),
                ...(options.apiKey && { 'dhl-api-key': options.apiKey }),
            },
        });
    }
    static getServices(client) {
        const api = DhlParcelDeShippingPostParcelGermanyV2Sdk.api;
        return {
            /**
             * Verifies the billing number for the given product. Cannot be Paket or Retoure.
             * @param product - The product to verify the billing number for.
             * @param billingNumber - The billing number to verify.
             * @param profile - The profile to use.
             * @returns
             */
            async verifyBillingNumber(product, billingNumber, profile = 'STANDARD_GRUPPENPROFIL') {
                try {
                    const result = await api.createOrders({
                        body: {
                            profile,
                            shipments: [
                                request.createOrderShipments[product].default({ billingNumber, referenceId: `${product}-default` }),
                            ],
                        },
                        client,
                        query: { validate: true },
                    });
                    const parsed = createOrdersHandler(result.data ?? result.error, result.response);
                    if (parsed.isUnauthorized) {
                        return { error: 'UNAUTHORIZED', success: false };
                    }
                    if (parsed.exception) {
                        return { error: 'UNHANDLED_ERROR', success: false };
                    }
                    if (parsed.items[0]?.err) {
                        return { error: 'INVALID_BILLING_NUMBER', success: false };
                    }
                    return { error: null, success: true };
                }
                catch (error) {
                    console.error(error);
                    return { error: 'UNHANDLED_ERROR', success: false };
                }
            },
            /**
             * Verifies the credentials for the DHL Parcel DE Shipping Post Parcel Germany V2 API.
             * @param credentials - The credentials to verify.
             * @returns
             */
            async verifyCredentials(credentials) {
                const result = await api.getOrder({
                    auth: () => `Basic ${btoa(`${credentials.username}:${credentials.password}`)}`,
                    client,
                    query: request.getOrderLabelQueryParams.default,
                });
                if (result.response.status === 401 || result.response.status === 403) {
                    return {
                        error: 'UNAUTHORIZED',
                        success: false,
                    };
                }
                if (result.response.status >= 200 && result.response.status < 500) {
                    return {
                        error: null,
                        success: true,
                    };
                }
                return { error: 'UNHANDLED_ERROR', success: false };
            },
            /**
             * Verifies the Paket billing number for the given product and also checks if the billing number is VAS-enabled.
             * @param billingNumber - The billing number to verify.
             * @param profile - The profile to use.
             * @returns
             */
            async verifyPaketBillingNumberWithOptionalVas(billingNumber, profile = 'STANDARD_GRUPPENPROFIL') {
                try {
                    const result = await api.createOrders({
                        body: {
                            profile,
                            shipments: [
                                request.createOrderShipments.Paket.default({
                                    billingNumber,
                                    referenceId: 'paket-default',
                                }),
                                request.createOrderShipments.Paket.vas({
                                    billingNumber,
                                    referenceId: 'paket-vas',
                                }),
                            ],
                        },
                        client,
                        query: { validate: true },
                    });
                    const parsed = createOrdersHandler(result.data ?? result.error, result.response);
                    if (parsed.isUnauthorized) {
                        return { error: 'UNAUTHORIZED', success: false };
                    }
                    if (parsed.exception) {
                        return { error: 'UNHANDLED_ERROR', success: false };
                    }
                    const [noVasItem, vasItem] = parsed.items;
                    if (noVasItem?.err && noVasItem.err.isBillingNumberUnauthorized) {
                        return { error: 'INVALID_BILLING_NUMBER', success: false };
                    }
                    const vas = !vasItem?.err?.isBillingNumberUnauthorized;
                    return { error: null, success: true, vas };
                }
                catch (error) {
                    console.error(error);
                    return { error: 'UNHANDLED_ERROR', success: false };
                }
            },
            /**
             * Verifies the retoure billing number for the given product.
             * @param product - The product to verify the billing number for. Can only be Paket or Warenpost.
             * @param billingNumber - The billing number to verify.
             * @param returnBillingNumber - The return billing number to verify.
             * @param profile
             * @returns
             */
            async verifyRetoureBillingNumber(product, billingNumber, returnBillingNumber, profile = 'STANDARD_GRUPPENPROFIL') {
                try {
                    const result = await api.createOrders({
                        body: {
                            profile,
                            shipments: [
                                request.createOrderShipments.Retoure.default({
                                    billingNumber,
                                    product,
                                    referenceId: 'default-retoure',
                                    returnBillingNumber,
                                }),
                            ],
                        },
                        client,
                        query: { validate: true },
                    });
                    const parsed = createOrdersHandler(result.data ?? result.error, result.response);
                    if (parsed.isUnauthorized) {
                        return { error: 'UNAUTHORIZED', success: false };
                    }
                    if (parsed.exception) {
                        return { error: 'UNHANDLED_ERROR', success: false };
                    }
                    const [item] = parsed.items;
                    if (item?.err) {
                        if (item.err.isBillingNumberUnauthorized) {
                            return { error: 'INVALID_BILLING_NUMBER', success: false };
                        }
                        if (item.err.isReturnBillingNumberUnauthorized) {
                            return { error: 'INVALID_RETURN_BILLING_NUMBER', success: false };
                        }
                    }
                    return { error: null, success: true };
                }
                catch (error) {
                    console.error(error);
                    return { error: 'UNHANDLED_ERROR', success: false };
                }
            },
        };
    }
}
(function (DhlParcelDeShippingPostParcelGermanyV2Sdk) {
    DhlParcelDeShippingPostParcelGermanyV2Sdk.Types = types;
})(DhlParcelDeShippingPostParcelGermanyV2Sdk || (DhlParcelDeShippingPostParcelGermanyV2Sdk = {}));
