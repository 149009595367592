import * as Sentry from '@sentry/react';

import { GenericUserClient, GenericUserOrganizationServer } from '@/type-defs/auth';

export function setSentryUser(
	user: Partial<Omit<GenericUserClient, 'organization' | 'organizationMemberships'>> | null,
) {
	if (!user) {
		Sentry.setUser(null);
	} else {
		if (user.id && user.email) {
			let username = '';

			if (user.username) {
				username = user.username;
			} else if (user.firstName && user.lastName) {
				username = `${user.firstName} ${user.lastName}`;
			} else if (user.lastName) {
				username = user.lastName;
			} else if (user.firstName) {
				username = user.firstName;
			}

			Sentry.setUser({
				email: user.email,
				id: user.id,
				username,
			});
		}
	}
}
export function setSentryOrganization(organization: Partial<GenericUserOrganizationServer> | null) {
	if (!organization) {
		Sentry.setContext('organization', null);
	} else {
		Sentry.setContext('organization', {
			id: organization.id,
			slug: organization.slug,
		});
	}
}
