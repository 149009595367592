import { Button, Modal, Stack, Text, Title } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import React from 'react';

import { useDatabaseStatus } from '@/frachter/hooks/use-database-status';
import { useFrachterApi } from '@/frachter/hooks/use-frachter';

export const DatabaseHealthCheck: React.FC<{ organizationId: string }> = ({ organizationId }) => {
	const [isOpen, { close, open }] = useDisclosure();

	const [isLoading, setIsLoading] = React.useState(false);

	const [bc, setBc] = React.useState<BroadcastChannel>();

	const [clientId] = React.useState<string>(crypto.randomUUID());

	const { status } = useDatabaseStatus();

	console.log('orgid', organizationId);

	const {
		// eslint-disable-next-line @typescript-eslint/unbound-method
		database: { reset },
	} = useFrachterApi();

	React.useEffect(() => {
		if (status === 'error') {
			open();
		}
	});

	// React.useEffect(() => {
	// 	const bc = new BroadcastChannel(`frachter/pglite/${organizationId}`);

	// 	setBc(bc);

	// 	bc.onmessage = (e) => {
	// 		console.log('MESSAGE', e);

	// 		const data = e.data as IDatabaseBroadcastMessage;

	// 		if (data.action === 'ERROR') {
	// 			open();
	// 		}

	// 		if (data.action === 'RELOAD') {
	// 			window.location.reload();
	// 		}
	// 	};

	// 	return () => {
	// 		bc.close();
	// 	};
	// }, [organizationId, open, clientId]);

	// const reset = React.useCallback(() => {
	// 	setIsLoading(true);

	// 	bc?.postMessage({ action: 'RESET' } satisfies IDatabaseBroadcastMessage);
	// 	// await wait(1000);
	// 	// await deleteIndexedDb(organizationId);
	// }, [bc]);

	return (
		<>
			<Modal
				centered
				closeOnClickOutside={false}
				closeOnEscape={false}
				onClose={close}
				opened={isOpen}
				title="Datenbankfehler"
				withCloseButton={false}
			>
				<Stack>
					<Title order={6}>Die lokale Datenbank ist in einem fehlerhaften Zustand.</Title>
					<Text>
						Bitte versuchen Sie die Datenbank zu löschen und neu zu erstellen. Dazu müssen Sie die Anwendung neustarten.
					</Text>
					<Button loading={isLoading} onClick={() => void reset()}>
						Datenbank zurücksetzen
					</Button>
				</Stack>
			</Modal>
		</>
	);
};
