import { SpaceMember, type Lock as AblyLock } from '@ably/spaces';
import { messages } from '@packages/lib/schema/realtime';
import { OrganizationUserSchema } from '@packages/lib/schema/user';
import { Effect } from 'effect';
import { match, P } from 'ts-pattern';

import { ILock } from '@/frachter/types/locks';

export const spaceMemberToRealtimeMember = (
	member: SpaceMember | null,
	organizationUsers: OrganizationUserSchema[] = [],
) =>
	Effect.gen(function* () {
		const schema = messages.userPresenceMessageSchema.or(messages.cloudprintPresenceMessageSchema).nullable();
		const parsed = schema.safeParse(member);

		if (parsed.data === null) {
			return null;
		}

		if (!parsed.success) {
			yield* Effect.logWarning('Could not parse member', parsed.error);
			return null;
		}

		const parsedMember = parsed.data;

		const realtimeMember = match(parsedMember)
			.with({ clientId: P.string.startsWith('frachter:') }, (v) => ({
				clientId: v.clientId,
				connectionId: v.connectionId,
				data: {
					...v.profileData,
					// add the profile from our clerk users
					profile: organizationUsers.find((user) => `frachter:${user.id}` === v.clientId) ?? null,
				},
				isConnected: v.isConnected,
				type: 'user' as const,
			}))
			.with({ clientId: P.string.startsWith('cloudprint:') }, (v) => ({
				clientId: v.clientId,
				connectionId: v.connectionId,
				data: v.profileData,
				isConnected: v.isConnected,
				type: 'cloudprint' as const,
			}))
			.exhaustive();

		return realtimeMember;
	});

export function ablyLockToFrachterLock(lock: AblyLock): ILock {
	return {
		details: {
			lockedBy: {
				clientId: lock.member.clientId,
				connectionId: lock.member.connectionId,
			},
			lockedSince: lock.timestamp,
		},
		id: lock.id,
		metadata: lock.attributes,
		status: 'acquired',
	};
}
