import { useApolloClient, useSubscription } from '@apollo/client';
import React from 'react';

import { graphql } from '@/gql/graphql';

const OrganizationSettingsSubscription = graphql(`
	subscription OrganizationSettingsSubscription {
		organizationSettings {
			mutationType
			payload {
				type
			}
		}
	}
`);

/**
 * This hook subscribes to changes in the organization settings, which are triggered by a
 * pub/sub mechanism in graphql or API endpoints like the `connect` routes.
 *
 * When an update happens, it'll instruct apollo client to refetch certain queries that depend
 * on those settings like marketplace links or cloudprint links.
 *
 * In the future, we can narrow this down to a more fine granular approach, but for now this is
 * a good middle ground to keep data in sync.
 */
export const useOrganizationSettingsLiveUpdates = () => {
	const subscription = useSubscription(OrganizationSettingsSubscription);

	const client = useApolloClient();

	React.useEffect(() => {
		if (!subscription.data) return;
		void client.refetchQueries({
			include: 'active',
			// [
			// 	OrganizationAddressQuery,
			// 	MarketLinkListQuery,
			// 	CloudprintLinksQuery,
			// 	MarketLinkListItemFragment,
			// 	OrganizationAddressFormFragment,
			// ],
		});
	}, [client, subscription.data]);
};
