import { Box, Paper, Stack, Text } from '@mantine/core';
import { useMatchRoute } from '@tanstack/react-router';
import { AnimatePresence, motion } from 'motion/react';
import React from 'react';

import { useGlobalServices } from '@/frachter/hooks/use-global-services';

export const BrowserSessionLock: React.FC<React.PropsWithChildren> = ({ children }) => {
	const [, startTransition] = React.useTransition();
	const [hasLock, setHasLock] = React.useState(false);
	const { webLockService } = useGlobalServices();

	const matchRoute = useMatchRoute();

	React.useEffect(() => {
		startTransition(async () => {
			/** ----------------------------------------------------------------------------------------------
			 * we only want to lock the session if we are on the connect route
			 * _______________________________________________________________________________________________ */
			const isConnectRoute = matchRoute({ to: '/connect/$apiId' });

			if (!isConnectRoute) {
				// this is blocking until we have the lock
				await webLockService.lockSession();
				setHasLock(true);
			}
		});
	}, [matchRoute, webLockService]);

	return (
		<AnimatePresence>
			{hasLock ? (
				<Box key="app">{children}</Box>
			) : (
				<Box
					key="lock"
					animate={{ opacity: 1 }}
					component={motion.div}
					exit={{ opacity: 0 }}
					initial={{ opacity: 0 }}
					style={{ display: 'grid', inset: 0, placeContent: 'center', position: 'fixed' }}
				>
					<Paper m="md" miw={300} p="xl" radius="md">
						<Stack align="center" justify="center">
							<Text ta="center">Frachter wird bereits in einem anderen Tab in diesem Browser verwendet</Text>
							<Text c="dimmed" size="sm">
								Multi-Tab-Support wird bald verfügbar sein
							</Text>
						</Stack>
					</Paper>
				</Box>
			)}
		</AnimatePresence>
	);
};
