import { FrachterLoggerDefault } from '@packages/lib/effect';
import { UserActivityService } from '@packages/lib/effect-services';
import { Effect, Layer, Logger, LogLevel } from 'effect';
import { ConfigError } from 'effect';

import { FrachterService } from '../FrachterService';
import { AuthService } from '../global-services/AuthService';
import { GraphqlClientService } from '../global-services/GraphqlService';
import { NotificationService } from '../global-services/NotificationService';
import { WebLockService } from '../global-services/WebLockService';
import { ApiService } from '../organization-services/ApiService';
import { CloudprintService } from '../organization-services/ApiService/CloudprintApiService';
import { BackendService } from '../organization-services/BackendService';
import { DatabaseService } from '../organization-services/DatabaseService';
import { WorkerCreationError, type DatabaseError } from '../organization-services/DatabaseService/errors';
import { DatabaseServicePowersync } from '../organization-services/DatabaseService/powersync/DatabaseServicePowersync';
import { OrganizationContextService } from '../organization-services/OrganizationContextService';
import { RealtimeService } from '../organization-services/RealtimeService';
import { RealtimeClientService } from '../organization-services/RealtimeService/RealtimeClientService';
import { WarehouseLayer } from '../organization-services/WarehouseService/WarehouseSetupService';

export type IOrganizationRuntimeServices =
	| DatabaseService
	| RealtimeService
	| OrganizationContextService
	| BackendService;

export type IOrganizationRuntimeErrors = DatabaseError | WorkerCreationError | ConfigError.ConfigError;

// WARN: do not put this into runtime.ts as this will cause an error "cannot ... before initialization"

// TODO: use configuration as mentioned here https://effect.website/docs/observability/logging/#loading-the-log-level-from-configuration
export const RuntimeConfigLayer = Layer.mergeAll(
	NotificationService.Default,
	FrachterLoggerDefault,
	Logger.minimumLogLevel(LogLevel.Debug),
);

// TODO: clean this up !!!!
export const OrgRuntimeLayer = Layer.mergeAll(
	ApiService.Default.pipe(
		Layer.provide(
			RealtimeService.Default.pipe(Layer.provide(RealtimeClientService.Default), Layer.provide(BackendService.Default)),
		),
		Layer.provide(
			CloudprintService.Default.pipe(
				Layer.provide(
					RealtimeService.Default.pipe(
						Layer.provideMerge(RealtimeClientService.Default),
						Layer.provideMerge(BackendService.Default),
					),
				),
			),
		),
		Layer.provideMerge(WarehouseLayer),
	),
	// WarehouseSetupService.Default,
	BackendService.Default,
	RealtimeService.Default.pipe(Layer.provide(RealtimeClientService.Default), Layer.provide(BackendService.Default)),
).pipe(
	// Layer.provideMerge(DatabaseServicePowersync.Default),
	Layer.provideMerge(DatabaseService.Default),
	Layer.provideMerge(BackendService.Default),
	Layer.provideMerge(UserActivityService.Default),
	Layer.provide(RuntimeConfigLayer),
);

// export class OrganizationRuntimeLayer extends Effect.Tag('OrganizationRuntime')<
// 	OrganizationRuntimeLayer,
// 	{
// 		readonly make: (
// 			organizationId: string,
// 		) => Layer.Layer<IOrganizationRuntimeServices, IOrganizationRuntimeErrors, AuthService | OrganizationId>;
// 	}
// >() {
// 	static Live = Layer.suspend(() =>
// 		Layer.succeed(this, {
// 			make: (organizationId: string) =>
// 				Layer.provide(
// 					Layer.mergeAll(
// 						OrganizationContextService.Default,
// 						DatabaseService.Default,
// 						BackendService.Default,
// 						RealtimeService.Default.pipe(
// 							Layer.provide(RealtimeSpacesService.Default),
// 							Layer.provide(RealtimeClientService.Default),
// 						),
// 					).pipe(
// 						Layer.provide(
// 							Layer.mergeAll(
// 								/* BackendService is a Requirement for some of the other Layers */
// 								BackendService.Default,
// 							),
// 						),
// 					),
// 					Layer.mergeAll(
// 						Layer.succeed(OrganizationId, organizationId),
// 						OrganizationContextService.Default,
// 						GlobalLayer,
// 					),
// 				),
// 		}),
// 	);

// 	static WithRealtimeApiKey = Layer.suspend(() =>
// 		Layer.succeed(this, {
// 			make: (organizationId: string) =>
// 				Layer.provide(
// 					Layer.mergeAll(
// 						OrganizationContextService.Default,
// 						DatabaseService.Default,
// 						BackendService.Default,
// 						RealtimeService.Default.pipe(
// 							// here we inject the api key config into the realtime service
// 							Layer.provide(Layer.setConfigProvider(RealtimeConfigProvider.ApiKey)),
// 							Layer.provide(RealtimeSpacesService.Default),
// 							Layer.provide(RealtimeClientService.Default),
// 						),
// 					).pipe(Layer.provide(Layer.mergeAll(BackendService.Default))),
// 					Layer.mergeAll(
// 						Layer.succeed(OrganizationId, organizationId),
// 						OrganizationContextService.Default,
// 						GlobalLayer,
// 					),
// 				),
// 		}),
// 	);

// 	static Test = Layer.suspend(() =>
// 		Layer.succeed(this, {
// 			make: (organizationId: string) =>
// 				Layer.provide(
// 					Layer.mergeAll(
// 						OrganizationContextService.Default,
// 						DatabaseService.Default,
// 						BackendService.Default,
// 						RealtimeService.Default.pipe(
// 							Layer.provide(RealtimeSpacesService.Default),
// 							Layer.provide(RealtimeClientService.Default),
// 						),
// 					).pipe(Layer.provide(Layer.mergeAll(BackendService.Default))),
// 					Layer.mergeAll(
// 						Layer.succeed(OrganizationId, organizationId),
// 						OrganizationContextService.Default,
// 						GlobalLayer,
// 					),
// 				),
// 		}),
// 	);
// }

export const GlobalLayer = Layer.mergeAll(GraphqlClientService.Default, WebLockService.Default).pipe(
	Layer.provideMerge(AuthService.Default),
	Layer.provideMerge(RuntimeConfigLayer),
);

// HINT: we need suspend to avoid uninitialized error
export const FrachterLayer = Layer.suspend(() => Layer.mergeAll(FrachterService.Default));

// we use this to ensure that FrachterRuntime and GlobalRuntime use the same global services
export const memoMap = Effect.runSync(Layer.makeMemoMap);
