'use client';

import { Box, Stack } from '@mantine/core';
// import { useSystemContext } from '@/context/OrganizationContext';
import { useNavigate, useRouteContext } from '@tanstack/react-router';
import { partition } from 'lodash-es';
import React from 'react';

import { GenericUserOrganizationClient } from '@/type-defs/auth';

import { OrganizationListItem } from './OrganizationListItem';

export const OrganizationList: React.FC<{
	currentOrganization?: GenericUserOrganizationClient | null;
	memberships: GenericUserOrganizationClient[];
	onClose?: () => void;
	onOpenSettings: () => void;
	onSelect?: (org: GenericUserOrganizationClient) => void;
}> = ({ currentOrganization, memberships, onOpenSettings, onSelect }) => {
	const [[current], others] = partition(memberships, (m) => m.id === currentOrganization?.id);

	const navigate = useNavigate();
	const routeContext = useRouteContext({ strict: false });

	const handleSelect = (org: GenericUserOrganizationClient) => {
		if (onSelect) {
			onSelect(org);
		}

		// TODO: before we can navigate, we must wait for all transactions to finish !
		// so ideally, instead of using navigate directly, we should externalize the logic into a hook
		// that shows a modal that informs the user about pending transactions and then navigates

		const next = routeContext.canRedirectToAfterOrgSwitch?.()
			? new URL(window.location.href).toString().replace(/\/org\/[^/]+/i, `/org/${org.slug}`)
			: undefined;

		void navigate({
			replace: true,
			search: (_prev) =>
				({
					action: 'autologin',
					/**
					 * if `canRedirectToAfterOrgSwitch` is true, we can pass a next search param
					 * so that we will direclty redirect to the current url but with the new organization slug
					 */
					next,
					slug: org.slug,
				}) satisfies typeof _prev,
			to: '/org',
		});
	};

	if (!currentOrganization) {
		return <Box>Keine Organisation ausgewählt</Box>;
	} else {
		return (
			<>
				<Stack gap={0}>
					{[current, ...others].map((org) => {
						return (
							<OrganizationListItem
								key={org.id}
								isActive={org.id === currentOrganization.id}
								onSelect={(org) => handleSelect(org)}
								org={org}
								onOpenSettings={() => {
									onOpenSettings();
								}}
							/>
						);
					})}
				</Stack>
			</>
		);
	}
};
