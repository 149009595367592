import { ActionIcon, Popover } from '@mantine/core';
import { IconFilter, IconFilterFilled } from '@tabler/icons-react';
import React from 'react';

import type { ICellProps } from './Cell';

import { useTableStore } from '../store';

export const FilterButton: React.FC<
	React.PropsWithChildren<{
		className?: string;
		onClick?: () => void;
		style?: React.CSSProperties;
		position?: Omit<ICellProps['justify'], 'center'>;
		isActive: boolean;
	}>
> = ({ children, className, isActive, onClick, position, style }) => {
	const isFetching = useTableStore((state) => state.isFetching);
	// const isActive = useTableStore((state) => state.columnFilters.some((filter) => filter.id === id));

	return (
		<Popover
			position={!position ? 'bottom' : position === 'end' ? 'bottom-end' : 'bottom-start'}
			shadow="sm"
			width={200}
			withArrow
		>
			<Popover.Target>
				<ActionIcon
					c={isActive ? 'primary' : 'color-mix(in srgb, var(--mantine-color-text) 20%, transparent)'}
					className={className}
					onClick={onClick}
					size="xs"
					style={style}
					variant="transparent"
				>
					<IconFilter />
				</ActionIcon>
			</Popover.Target>
			<Popover.Dropdown>
				<fieldset disabled={isFetching}>{children}</fieldset>
			</Popover.Dropdown>
		</Popover>
	);
};
