import { Outlet } from '@tanstack/react-router';
import React from 'react';
import { useDeepCompareEffect } from 'use-deep-compare';

import { useValidateAuthToken } from '@/hooks/use-auth-token';
import { useFrachterUser } from '@/hooks/use-frachter-user';
import { getAuthUrl } from '@/utils/get-auth-url';

import { UnauthorizedDialog } from './dialogs/unauthorized-dialog';

/**
 * we use this for instance as a guard for accessing `connect` routes (e.g. for otto market)
 * where we might not have a clerk user session but instead a search param that contains a token
 */
export const AuthenticatedOutlet: React.FC<{ tokenType?: 'default' | 'connect'; children?: React.ReactNode }> = ({
	children,
	tokenType = 'default',
}) => {
	const [isAuthenticated, setIsAuthenticated] = React.useState(false);

	const { validate: validateConnectToken } = useValidateAuthToken();

	const { user } = useFrachterUser();

	useDeepCompareEffect(() => {
		if (tokenType === 'default') {
			if (user) {
				setIsAuthenticated(true);
			} else {
				// this does not work as the redirect fn will prepend the url with the basePath, which is `/app` in this case
				// and this would lead to an infinite redirect loop
				// throw redirect({ to: getAuthUrl(),  });
				// therefore we must use the window.location.replace
				window.location.replace(getAuthUrl());
			}
		} else if (tokenType === 'connect') {
			const validationResult = validateConnectToken();

			if (validationResult.success) {
				setIsAuthenticated(true);
			}
		}
	}, [tokenType, user, validateConnectToken]);

	return !isAuthenticated ? (
		tokenType === 'default' ? null : (
			<UnauthorizedDialog
				description="Bitte gehe zurück zu frachter und versuche es erneut. Sollte das Problem weiterhin auftreten, wende Dich bitte an den Support."
				onClick={() => window.close()}
				subtitle="Das für diese Aktion ausgestellte Zugriffstoken ist abgelaufen. Aus Sicherheitsgründen sind temporäre Tokens nur wenige Minuten gültig."
			/>
		)
	) : (
		(children ?? <Outlet />)
	);
};
