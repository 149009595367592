import { Box, Group, Paper, Title } from '@mantine/core';
import { Outlet, useParams } from '@tanstack/react-router';

import { RealtimePrinterSelect } from '@/components/realtime/RealtimePrinterSelect';
import { AppPageControls, AppPageLayout } from '@/layouts/app-page';

import { BundleModeControl } from './BundleModeControl';
import { MarketSelect } from './MarketSelect';

export const PageQuickship: React.FC = () => {
	const params = useParams({ strict: false });

	return (
		<AppPageLayout
			maxWidth="100%"
			variant="apps"
			title={
				<Group>
					<Title order={3}>Bestellungen</Title>
					<MarketSelect marketId={params.marketId} merchantId={params.merchantId} />
				</Group>
			}
		>
			<AppPageControls>
				{/* <Tooltip label="Neue Bestellung anlegen">
					<Button leftSection={<IconPlus size={18} stroke={2} />} size="xs" variant="outline">
						Bestellung
					</Button>
				</Tooltip>
				<Divider orientation="vertical" /> */}
				<RealtimePrinterSelect />
			</AppPageControls>

			<Box
				style={{
					display: 'grid',
					gap: '1rem',
					height: '100%',
					position: 'relative',
				}}
			>
				<Paper radius="sm" shadow="xs" style={{ flex: 1, inset: 0, overflow: 'hidden', position: 'absolute' }}>
					<Outlet />
				</Paper>
			</Box>
		</AppPageLayout>
	);
};
