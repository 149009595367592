import React from 'react';

import { AUTO_LOAD_FRACHTER_ORG_SYMBOL, FrachterService } from '../frachter/effect/FrachterService';

export const FrachterContext = React.createContext<FrachterService | null>(null);

export const FrachterContextProvider: React.FC<{
	autoLoadPathPrefix?: string | string[];
	children?: React.ReactNode;
	frachterService: Promise<FrachterService>;
}> = ({ autoLoadPathPrefix, children, frachterService }) => {
	const frachter = React.use(frachterService);

	React.useEffect(() => {
		if (!autoLoadPathPrefix) return;
		const pathname = window.location.pathname;

		for (const prefix of !Array.isArray(autoLoadPathPrefix) ? [autoLoadPathPrefix] : autoLoadPathPrefix) {
			if (pathname.startsWith(prefix)) {
				void frachter.setOrganization(AUTO_LOAD_FRACHTER_ORG_SYMBOL);
				break;
			}
		}
	}, [autoLoadPathPrefix, frachter]);

	return <FrachterContext.Provider value={frachter}>{children}</FrachterContext.Provider>;
};
