'use client';

import { ActionIcon, Box, Group, Tooltip, useMantineColorScheme } from '@mantine/core';
import { useFullscreen } from '@mantine/hooks';
import { IconWindowMaximize, IconWindowMinimize } from '@tabler/icons-react';
import React from 'react';

import { ColorSchemeToggle } from './components/ColorSchemeToggle';

export const AppBarPermanentSection: React.FC<{ style?: React.CSSProperties }> = ({ style }) => {
	const { fullscreen: isFullscreen, toggle: toggleFullscreen } = useFullscreen();
	const { colorScheme } = useMantineColorScheme({});

	React.useEffect(() => {
		if (isFullscreen) {
			if (document.body.classList.contains('fullscreen')) return;
			document.body.classList.add('fullscreen');
		} else {
			document.body.classList.remove('fullscreen');
		}
	}, [isFullscreen]);

	return (
		<Group style={style}>
			<Tooltip label={!isFullscreen ? 'Vollbild-Modus aktivieren' : 'Vollbild-Modus beenden'} position="bottom">
				<ActionIcon color="var(--mantine-color-text)" onClick={() => void toggleFullscreen()} variant="subtle">
					{!isFullscreen ? (
						<IconWindowMaximize size={20} stroke={1.5} />
					) : (
						<IconWindowMinimize size={20} stroke={1.5} />
					)}
				</ActionIcon>
			</Tooltip>
			<Tooltip label={colorScheme === 'dark' ? 'Light-Mode aktivieren' : 'Dark-Mode aktivieren'} position="bottom">
				<Box>
					<ColorSchemeToggle />
				</Box>
			</Tooltip>
		</Group>
	);
};
