import type { hct } from '@frachter/backend';

import fetchRetry from 'fetch-retry';
import { hc } from 'hono/client';

import { env } from '@/data/env';

const fetchWithRetry = fetchRetry(fetch, {
	retryDelay(attempt) {
		return Math.min(5000, 1000 * Math.pow(2, attempt));
	},
	retryOn: function (_, error, response) {
		if (!response) {
			return true;
		}

		// retry on any network error, or 4xx or 5xx status codes
		if (error !== null || response.status >= 500) {
			return true;
		}

		return false;
	},
});

function intoHeaders<T extends HeadersInit>(headers?: T): Headers {
	if (headers instanceof Headers) {
		return headers;
	} else if (Array.isArray(headers)) {
		return new Headers(headers);
	} else if (typeof headers === 'object') {
		return new Headers(headers);
	}

	return new Headers();
}

export const createBackendClient = (getAdditonalHeaders?: () => Promise<{ [key: string]: string | undefined }>) => {
	return hc(env.PUBLIC_BACKEND_URL, {
		async fetch(input, requestInit, _) {
			const headers = intoHeaders(requestInit?.headers);

			if (getAdditonalHeaders) {
				const additionalHeaders = await getAdditonalHeaders();
				Object.entries(additionalHeaders).forEach(([key, value]) => {
					if (value) {
						headers.set(key, value);
					}
				});
			}

			if (requestInit?.method === 'POST' && requestInit?.body) {
				headers.set('Content-Type', 'application/json');
			}

			return fetchWithRetry(input, {
				...requestInit,
				headers,
			});
		},
	}) as unknown as hct;
};
