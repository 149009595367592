import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import type { IRowType } from './types';

import { fakeProductsData } from './makeData';

//CREATE hook (post new user to api)
export function useCreateRow() {
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: async (row: IRowType) => {
			//send api update request here
			await new Promise((resolve) => setTimeout(resolve, 10)); //fake api call
			return Promise.resolve();
		},
		//client side optimistic update
		onMutate: (newRowInfo: IRowType) => {
			queryClient.setQueryData(
				['products'],
				(prevRows: any) =>
					[
						...prevRows,
						{
							...newRowInfo,
							id: (Math.random() + 1).toString(36).substring(7),
						},
					] as IRowType[],
			);
		},
		// onSettled: () => queryClient.invalidateQueries({ queryKey: ['users'] }), //refetch users after mutation, disabled for demo
	});
}

//READ hook (get users from api)
export function useGetRows() {
	return useQuery<IRowType[]>({
		queryFn: async () => {
			console.log('GET ROWS');
			//send api request here
			await new Promise((resolve) => setTimeout(resolve, 10)); //fake api call
			return Promise.resolve(fakeProductsData);
		},
		queryKey: ['products'],
		refetchOnMount: false,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
	});
}

//UPDATE hook (put users in api)
export function useUpdateRows() {
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: async (rows: IRowType[]) => {
			//send api update request here
			await new Promise((resolve) => setTimeout(resolve, 10)); //fake api call
			return Promise.resolve();
		},
		mutationKey: ['products:updateRows'],
		//client side optimistic update
		onMutate: (newRows: IRowType[]) => {
			queryClient.setQueryData(['products'], (prevRows: IRowType[]) =>
				prevRows?.map((row: IRowType) => {
					const newRow = newRows.find((r) => r.id === row.id);
					return newRow ? newRow : row;
				}),
			);
		},
		// onSettled: () => queryClient.invalidateQueries({ queryKey: ['users'] }), //refetch users after mutation, disabled for demo
	});
}

//DELETE hook (delete user in api)
export function useDeleteRow() {
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: async (rowId: string) => {
			//send api update request here
			await new Promise((resolve) => setTimeout(resolve, 10)); //fake api call
			return Promise.resolve();
		},
		//client side optimistic update
		onMutate: (id: string) => {
			queryClient.setQueryData(['products'], (prevRows: IRowType[]) =>
				prevRows?.filter((row: IRowType) => row.id !== id),
			);
		},
		// onSettled: () => queryClient.invalidateQueries({ queryKey: ['users'] }), //refetch users after mutation, disabled for demo
	});
}
