import { PGliteProvider } from '@electric-sql/pglite-react';
import { ModalsProvider } from '@mantine/modals';
import { createFileRoute, Outlet, redirect, useMatch } from '@tanstack/react-router';
import { match } from 'ts-pattern';

import { CloudprintQueue } from '@/components/CloudprintQueue';
import { OrganizationDevtools } from '@/components/devtools/OrganizationDevtools';
import { modals } from '@/components/mantine-modals';
import { useFrachter } from '@/frachter/hooks/use-frachter';
import { useFrachterStatus } from '@/frachter/hooks/use-frachter-status';
import { useOrganizationSettingsLiveUpdates } from '@/hooks/use-organization-settings-live-update';
import { OrganizationLayout } from '@/layouts/organization';

export const Route = createFileRoute('/org/$slug')({
	beforeLoad: ({ context, params }) => {
		const { organization, user } = context.clerk;

		if (!organization) {
			throw redirect({
				to: '/org',
			});
		}

		match(params.slug)
			.with('_', () => {
				throw redirect({
					search: {
						action: 'autologin',
						next: window.location.href,
						slug: '_',
					},
					to: '/org',
				});
			})
			.otherwise(() => {
				const canActivate =
					user?.organizationMemberships?.some(({ organization }) => organization.slug === params.slug) ?? false;
				if (!organization.slug) {
					throw redirect({
						...(canActivate && { search: { action: 'autologin', next: window.location.href, slug: params.slug } }),
						to: '/org',
					});
				}

				if (context.frachter?.$$status.getSnapshot().organization === 'ready' && organization?.slug !== params.slug) {
					throw redirect({
						search: {
							action: 'mismatch',
							activeSlug: organization.slug,
							canActivate,
							next: canActivate ? window.location.href.replace(/\/org\/[^/]+/i, `/org/${params.slug}`) : undefined,
							selectedSlug: params.slug,
						},
						to: '/org',
					});
				}
			});
	},
	component: Layout,
});

function Layout() {
	const frachter = useFrachter();
	const status = useFrachterStatus();
	const isSetupRoute = useMatch({ from: '/org/$slug/setup', shouldThrow: false });
	const isReady = status.organization === 'ready';

	return !isReady ? null : (
		/* we need to keep the ModalsProvider here as some modals do require organization api to be available */
		// TODO: re-check this after we've gone effectful
		<PGliteProvider db={frachter.getOrganizationAPI()!.database.pgClient}>
			<ModalsProvider labels={{ cancel: 'Abbrechen', confirm: 'Bestätigen' }} modals={modals}>
				<OrganizationLayout hideAccountMenu={isSetupRoute !== undefined}>
					<OrganizationDevtools key="devtools" />
					<OrganizationSubscriptions key="subscriptions" />
					<CloudprintQueue key="cloudprint-queue" />
					<Outlet key="outlet" />
				</OrganizationLayout>
			</ModalsProvider>
		</PGliteProvider>
	);
}

const OrganizationSubscriptions = () => {
	useOrganizationSettingsLiveUpdates();
	return null;
};
