import type { useUser } from '@clerk/clerk-react';

import { constants } from '@/data/constants';
import { GenericUserClient, GenericUserOrganizationClient, PublicOrganizationMember } from '@/type-defs/auth';

interface ClerkOrganizationMembership {
	organization: {
		id: string;
		slug: string | null;
		imageUrl: string;
		name: string;
	};
	permissions: string[];
	role: string;
}

export function clerkClientUserToGenericUserProfile(
	user: NonNullable<ReturnType<typeof useUser>['user']> | null | undefined,
	memberships: ClerkOrganizationMembership[],
	organization?: GenericUserOrganizationClient | null,
): GenericUserClient | null {
	if (!user) return null;

	const firstName = user.firstName ?? '';
	const lastName = user.lastName ?? '';
	const initials = firstName.charAt(0) + lastName.charAt(0) || null;

	const organizationMemberships: GenericUserClient['organizationMemberships'] = memberships.map((org) => ({
		// allowedMembersCount: org.organization.maxAllowedMemberships,
		// currentMembersCount: org.organization.membersCount,
		id: org.organization.id,
		imageUrl: org.organization.imageUrl,
		name: org.organization.name,
		permissions: org.permissions,
		role: org.role,
		slug: org.organization.slug!,
	}));

	return {
		email: user.primaryEmailAddress?.emailAddress ?? null,
		firstName: user.firstName,
		fullName: user.fullName,
		id: user.id,
		imageUrl: user.imageUrl,
		initials,
		isSuperuser: constants.SUPERUSER_IDS.includes(user.id),
		lastName: user.lastName,
		/**
		 * the current organization the user is a member of and is active
		 */
		organization: organization ?? null,
		/**
		 * which other organizations the user is a member of
		 */
		organizationMemberships,
		username: user.username,
	};
}

export function parseOrganizationMemberships(
	memberships: {
		data:
			| undefined
			| {
					id: string;
					permissions: string[];
					publicUserData: {
						firstName: string | null;
						imageUrl: string | null;
						lastName: string | null;
					};
					role: string;
			  }[];
	} | null,
): PublicOrganizationMember[] {
	return (
		memberships?.data?.map((v) => {
			const { firstName, lastName } = v.publicUserData;

			return {
				firstName,
				fullName: `${firstName} ${lastName}`,
				id: v.id,
				imageUrl: v.publicUserData.imageUrl,
				initials: `${firstName?.[0]}${lastName?.[0]}`.toUpperCase(),
				lastName,
				permissions: v.permissions,
				role: v.role,
			};
		}) ?? []
	);
}
