import * as realtimeSchema from '@packages/lib/schema/realtime';
import React from 'react';
import { StoreApi, useStore, createStore } from 'zustand';
import { persist, createJSONStorage, devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { CloudprintQueueItemData } from '@/components/CloudprintQueue';
import { SelectedCloudprintClientDevice } from '@/type-defs/common';

// TODO: effect migration remove

export namespace IOrganizationStore {
	export interface State {
		cloudprintClientDevice: {
			/**
			 * the device that was last selected (to restore it after a reload)
			 */
			selected: SelectedCloudprintClientDevice | null;
			/**
			 * the device that is currently selected and active
			 */
			current: SelectedCloudprintClientDevice | null;
		};
		cloudprintQueuePreviewItem: CloudprintQueueItemData | null;
		isCloudprintQueueVisible: boolean;
		/**
		 * this is a combination of marketId:merchantId
		 * since we are on the client, we don't need the org id/slug
		 */
		lastUsedMarket: { marketId: string; merchantId: string } | null;
		realtimePresences: {
			leader: any;
			cloudprint: any;
			frachter: any;
		};
	}

	export interface Actions {
		setCloudprintClientDevice: (payload: Partial<IOrganizationStore.State['cloudprintClientDevice']>) => void;
		setCloudprintQueuePreviewItem: (item: CloudprintQueueItemData | null) => void;
		setIsCloudprintQueueVisible: (value: boolean) => void;
		setLastUsedMarket: (lastUsedMarket: { marketId: string; merchantId: string } | null) => void;
		setRealtimePresenceLeader: (leader: any) => void;
	}
}

const getInitialState: () => IOrganizationStore.State = () => ({
	cloudprintClientDevice: { current: null, selected: null },
	cloudprintQueuePreviewItem: null,
	isCloudprintQueueVisible: false,
	lastUsedMarket: null,
	realtimePresences: {
		cloudprint: [],
		frachter: [],
		leader: null,
	},
});

const OrganizationStoreContext = React.createContext<StoreApi<
	IOrganizationStore.State & IOrganizationStore.Actions
> | null>(null);

interface OrganizationStoreProps {
	frachterClientId: string;
}

export const createOrganizationStore = (props: OrganizationStoreProps) =>
	createStore<IOrganizationStore.Actions & IOrganizationStore.State>()(
		devtools(
			persist(
				immer((set) => ({
					...getInitialState(),
					setCloudprintClientDevice: (payload) => {
						set(
							(s) => {
								s.cloudprintClientDevice = { ...s.cloudprintClientDevice, ...payload };
							},
							false,
							'setSelectedCloudprintClientDevice',
						);
					},
					setCloudprintQueuePreviewItem: (item: CloudprintQueueItemData | null) => {
						set(
							(s) => {
								s.cloudprintQueuePreviewItem = item;
							},
							false,
							'setCloudprintQueuePreviewItem',
						);
					},
					setIsCloudprintQueueVisible: (visibility) => {
						set(
							(s) => {
								s.isCloudprintQueueVisible = visibility;
							},
							false,
							'setCloudprintQueueVisibility',
						);
					},
					setLastUsedMarket: (lastUsedMarket: { marketId: string; merchantId: string } | null) => {
						set((s) => {
							s.lastUsedMarket = lastUsedMarket;
						});
					},
					setRealtimePresenceLeader: (leader: any) => {
						set(
							(s) => {
								s.realtimePresences.leader = leader;
							},
							false,
							'setRealtimePresenceLeader',
						);
					},
				})),
				{ name: `organization-store/${props.frachterClientId}`, storage: createJSONStorage(() => localStorage) },
			),
			{
				name: `frachter/${props.frachterClientId}`,
				store: `organization`,
			},
		),
	);

export const OrganizationStoreProvider: React.FC<{ children: React.ReactNode; frachterClientId: string }> = ({
	children,
	frachterClientId,
}) => {
	const [store, setStore] = React.useState(createOrganizationStore({ frachterClientId }));
	const [clientId, setClientId] = React.useState(frachterClientId);

	React.useEffect(() => {
		if (clientId !== frachterClientId) {
			setStore(createOrganizationStore({ frachterClientId }));
			setClientId(frachterClientId);
		}
	}, [clientId, frachterClientId, store]);

	return <OrganizationStoreContext.Provider value={store}>{children}</OrganizationStoreContext.Provider>;
};

export function useOrganizationStore<T>(
	selector: (state: IOrganizationStore.State & IOrganizationStore.Actions) => T,
): T {
	const store = React.use(OrganizationStoreContext);
	if (!store) throw new Error('Missing OrganizationStoreProvider in the tree');
	return useStore(store, selector);
}
