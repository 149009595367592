import { utc } from '@date-fns/utc';
import { addDays, getISOWeek, getISOWeekYear } from 'date-fns';
import { addMonths, addWeeks } from 'date-fns';
export const getWeekStringForDate = (date, options) => {
    const { shiftDays = 0, shiftWeeks = 0 } = options ?? {};
    // Ensure `date-fns` interprets it in UTC
    const utcDate = addWeeks(addDays(date, shiftDays), shiftWeeks, { in: utc });
    const weekNumber = getISOWeek(utcDate);
    return `${getISOWeekYear(utcDate)}-W${String(weekNumber).padStart(2, '0')}`;
};
export const getMonthStringForDate = (date, options) => {
    const { shiftDays = 0, shiftMonths = 0 } = options ?? {};
    const shiftedDate = addMonths(addDays(date, shiftDays), shiftMonths, { in: utc });
    const month = new Intl.DateTimeFormat('en', { month: 'numeric', timeZone: 'UTC' }).format(shiftedDate);
    return `${shiftedDate.getFullYear()}-${month.padStart(2, '0')}`;
};
