import { ApolloProvider } from '@apollo/client';
import { useClerk } from '@clerk/clerk-react';
import React from 'react';

import { useGlobalServices } from '@/frachter/hooks/use-global-services';

// you need to create a component to wrap your app in
export function AuthenticatedGraphqlProvider({ children }: React.PropsWithChildren<{}>) {
	const { graphqlService } = useGlobalServices();

	return <ApolloProvider client={graphqlService.client}>{children}</ApolloProvider>;
}

// Main GraphqlProvider Component
export const GraphqlProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
	const clerk = useClerk();

	if (!clerk.session) {
		return children;
	}

	return <AuthenticatedGraphqlProvider>{children}</AuthenticatedGraphqlProvider>;
};
