import { getSpacing, type MantineSpacing } from '@mantine/core';
import React from 'react';

export interface ITableStyles {
	/**
	 *
	 */
	cell?: {
		px?: MantineSpacing;
		py?: MantineSpacing;
	};
	/**
	 * padding for
	 * - content (head, body, foot)
	 *
	 * Can be used e.g. if a surrounding paper's shadows are being overlayed by the
	 * cell backgrounds of head and foot
	 *
	 * default: 0
	 */
	content?: {
		px?: MantineSpacing;
		py?: MantineSpacing;
	};

	/**
	 * padding for
	 * - top bar
	 * - bottom bar
	 * - first column (start/left)
	 * - last column (end/right)
	 *
	 * default: cell padding
	 */
	frame?: {
		px?: MantineSpacing;
		py?: MantineSpacing;
	};
}

export function useCreateStyles(styles?: ITableStyles) {
	const vars = React.useMemo(() => {
		const vars: Record<string, undefined | string> = {};

		vars['--cell-px'] = getSpacing(styles?.cell?.px);
		vars['--cell-py'] = getSpacing(styles?.cell?.py);

		vars['--frame-px'] = getSpacing(styles?.frame?.px);
		vars['--frame-py'] = getSpacing(styles?.frame?.py);

		vars['--content-px'] = getSpacing(styles?.content?.px);
		vars['--content-py'] = getSpacing(styles?.content?.py);

		return vars;
	}, [styles]);

	return vars;
}
