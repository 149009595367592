import { useOrganization } from '@clerk/clerk-react';
import { Box, Button, Group, Stack, Text, Title } from '@mantine/core';
import React, { useSyncExternalStore } from 'react';

import { useRequestLock } from '@/frachter/hooks/use-request-lock';

import { useFrachter, useFrachterApi } from '../../../../frachter/hooks/use-frachter';
import { useOrganizationId } from '../../../../frachter/hooks/use-organization-id';
import { useRealtimeUserMembers } from '../../../../frachter/hooks/use-realtime-user-members';

export default function Page() {
	const frachter = useFrachter();
	const api = useFrachterApi();
	const organizationId = useOrganizationId((s) => s);
	const authSession = useSyncExternalStore(frachter.$session.subscribe, frachter.$session.getSnapshot);
	const { organization } = useOrganization();

	return (
		<Box p="lg">
			<Stack gap="xl">
				<Stack>
					<Title order={3}>Switch Tenants</Title>
					<Text>current org: {organization?.slug}</Text>
					<Stack>
						<Button onClick={() => void frachter.setOrganization('testorg')}>Set org id to testorg</Button>
						<Button onClick={() => void frachter.setOrganization('dummy')}>Set org id to dummy</Button>
						<Button onClick={() => void frachter.setOrganization('frachter')}>Set org id to frachter</Button>
						<Button color="warning" onClick={() => void frachter.setOrganization(null)}>
							Unset org id
						</Button>
					</Stack>
				</Stack>

				<Stack>
					<Title order={3}>Status</Title>
					<Stack>
						<Box style={{ columnGap: '2rem', display: 'grid', gridTemplateColumns: 'auto 1fr' }}>
							<Text>current org id: </Text>
							<Text>{String(organizationId.current)}</Text>
							<Text>requested org id: </Text>
							<Text>{String(organizationId.requested)}</Text>
							<Text>pending org id: </Text>
							<Text>{organizationId.pending === undefined ? '-' : String(organizationId.pending)}</Text>
						</Box>
						<Box style={{ columnGap: '2rem', display: 'grid', gridTemplateColumns: 'auto 1fr' }}>
							<Text>realtime service status</Text>
							<Text>connected?</Text>
							<Text>realtime connection status</Text>
							<Text>?</Text>
						</Box>
					</Stack>
				</Stack>

				<Stack>
					<Title order={3}>Auth Session</Title>
					<Text>user: {authSession?.user?.firstName}</Text>
					<Text>organization: {authSession?.organization?.slug}</Text>
				</Stack>

				<Stack>
					<Title order={3}>Test Services</Title>
					<fieldset disabled={!(organizationId.current && organizationId.pending === undefined)}>
						<Stack>
							{/* <Button onClick={() => void manager.getOrganization()?.database.sql()}>Test database service</Button> */}
							{/* <Button
								onClick={() =>
									// void manager.getOrganization()?.realtime.publish(`message at ${new Date().toISOString()}`)
								}
							>
								Test realtime service
							</Button> */}
						</Stack>
					</fieldset>
				</Stack>
			</Stack>
			<Stack>
				<Title order={3}>Locks</Title>
				<Group>
					<Button>Lock resource /orders/1000</Button>
					<Button>Unlock resource /orders/1000</Button>
				</Group>
				<Group>
					<Button>Lock resource /orders/2000</Button>
					<Button>Unlock resource /orders/2000</Button>
				</Group>
				<Stack>
					<div>lock 1</div>
					<div>lock 2</div>
				</Stack>
				<LockTestWrapper />
			</Stack>
			{api && <Members />}
		</Box>
	);
}

const Members = () => {
	const members = useRealtimeUserMembers('all');
	const me = useRealtimeUserMembers('self');

	return (
		<Stack mt="xl">
			<Title order={3}>Members</Title>
			<Box style={{ display: 'grid', gap: '1rem', gridTemplateColumns: 'auto auto 1fr 1fr 1fr' }}>
				{members.map((member) => {
					return (
						<React.Fragment key={member.connectionId}>
							<Box>{member.connectionId === me?.connectionId ? '👋' : '🌍'}</Box>
							<Text>{member.clientId}</Text>
							<Text>{member.connectionId}</Text>
							<Text>
								{member.type === 'user'
									? `${member.data.profile?.firstName} ${member.data.profile?.lastName}`
									: member.data.meta.hostname}
							</Text>
							<Text>isConnected: {String(member.isConnected)}</Text>
							{/* <Text>{member.}</Text></> */}
						</React.Fragment>
					);
				})}
			</Box>
		</Stack>
	);
};

const LockTest = () => {
	const { data } = useRequestLock('123');

	return (
		<Group>
			<Text>is owner: {String(data?.isOwner)}</Text>
			<Text>status: {data?.lock.status}</Text>
			<Text>has lost lock: {String(data?.hasLostLock)}</Text>
		</Group>
	);
};

const LockTestWrapper = () => {
	const [isMounted, setIsMounted] = React.useState(true);

	return (
		<Stack>
			<Title>LOCK TEST</Title>
			<Button onClick={() => setIsMounted((s) => !s)}>toggle</Button>
			{isMounted && <LockTest />}
		</Stack>
	);
};
