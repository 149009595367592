'use no memo';

import { Box } from '@mantine/core';
import {
	getCoreRowModel,
	getExpandedRowModel,
	useReactTable,
	type ColumnDef,
	type Row,
	type RowData,
} from '@tanstack/react-table';
import clsx from 'clsx';
import * as React from 'react';
import { isPresent } from 'ts-extras';
import { useDeepCompareEffect } from 'use-deep-compare';

import type { ICellProps } from './components/Cell';
import type { IBaseTableData, IColumnFilter, IRenderExpanded } from './types';

import { COLUMN_SIZE_RESET_VALUE } from './contants';
import { useTableStore } from './store';
import s from './table.module.scss';
import { MemoizedTableBody } from './TableBody';
import { TableBodySkeleton } from './TableBodySkeleton';
import { MemoizedTableFoot } from './TableFoot';
import { MemoizedTableHead } from './TableHead';
import { useCreateStyles, type ITableStyles } from './use-create-styles';
import { useFeatureExpand } from './use-feature-expand';
import { useFeatureResize } from './use-feature-resize';
import { useFeatureSelect } from './use-feature-select';

declare module '@tanstack/table-core' {
	// interface TableMeta<TData extends RowData> {
	// 	align: 'start' | 'end';
	// }

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	interface ColumnMeta<TData extends RowData, TValue> {
		align?: ICellProps['align'];
		filter?: IColumnFilter<any>;
		justify?: ICellProps['justify'];
	}
}

interface ITableProps<TData extends IBaseTableData> {
	className?: string;
	columns: ColumnDef<TData, any>[];
	data: TData[];
	enableRowSelection?: boolean;
	getRowCanExpand?: (row: Row<TData>) => boolean;
	/**
	 * must be passed to detect when the query changes
	 * e.g. through filters
	 *
	 * in that case we must reset the pagination index to 0
	 */
	queryKey: any[];

	/**
	 * function to render the expanded (details) row
	 * should have a single parent element
	 * that can be un-padded by setting the padding directly
	 * with a className or as style prop, which will override
	 * the defaults set for direct children
	 */
	renderExpanded?: IRenderExpanded<TData>;

	// rowCount: number;
	style?: React.CSSProperties;
	/**
	 * Styles that match variables in our css modules files
	 */
	styles?: ITableStyles;
	tableBottomBar?: React.ReactNode;

	tableTopBar?: React.ReactNode;

	totalRowCount: number;
}

export function Table<TData extends IBaseTableData = IBaseTableData>({
	className,
	columns,
	data,
	enableRowSelection = false,
	getRowCanExpand,
	// rowCount,
	queryKey,
	renderExpanded,
	style,
	styles,
	tableBottomBar,
	tableTopBar,
	totalRowCount,
}: ITableProps<TData>) {
	const tableRootElementRef = React.useRef<HTMLDivElement | null>(null);
	const tableContentElementRef = React.useRef<HTMLDivElement | null>(null);
	const rowSelection = useTableStore((state) => state.rowSelection);
	const setEnableRowSelection = useTableStore((state) => state.setEnableRowSelection);
	const setRowSelection = useTableStore((state) => state.setRowSelection);
	const setRowSelectionStatus = useTableStore((state) => state.setRowSelectionStatus);
	const setSortingState = useTableStore((state) => state.setSortingState);
	const sorting = useTableStore((state) => state.sorting);
	const setTotalRowCount = useTableStore((state) => state.setTotalRowCount);
	const storedTotalRowCount = useTableStore((state) => state.totalRowCount);
	const setExpanded = useTableStore((state) => state.setExpanded);
	const expanded = useTableStore((state) => state.expanded);

	const columnFilters = useTableStore((state) => state.columnFilters);
	const setColumnFilters = useTableStore((state) => state.setColumnFilters);

	const pagination = useTableStore((state) => state.pagination);
	const setPagination = useTableStore((state) => state.setPagination);
	const isFetching = useTableStore((state) => state.isFetching);

	/** ----------------------------------------------------------------------------------------------
	 * additional columns
	 * _______________________________________________________________________________________________ */
	const selectColumn = useFeatureSelect();
	const { column: expandColumn } = useFeatureExpand({ renderExpanded });

	/** ----------------------------------------------------------------------------------------------
	 * table initialization
	 * _______________________________________________________________________________________________ */

	const mergedColumns = React.useMemo(
		() => (enableRowSelection ? [expandColumn, ...columns, selectColumn].filter(isPresent) : columns),
		[columns, enableRowSelection, expandColumn, selectColumn],
	);

	const table = useReactTable<{ id: string }>({
		columnResizeMode: 'onChange',
		columns: mergedColumns as ColumnDef<{ id: string }>[],
		data,
		debugTable: true,
		defaultColumn: {
			/* we use a different default than tanstack table */
			enableColumnFilter: false,
			/* we use a different default than tanstack table */
			enableSorting: false,
			/* we use a different default than tanstack table, which is -1 for size to determine that no size was set */
			size: COLUMN_SIZE_RESET_VALUE,
		},

		enableColumnResizing: true,
		enableRowSelection: (row) => {
			if (!enableRowSelection) return false;
			return data.findIndex((v) => v.id === row.id) !== -1;
		},
		getCoreRowModel: getCoreRowModel(),
		getExpandedRowModel: getExpandedRowModel(),
		getRowCanExpand: getRowCanExpand as any,
		getRowId: (row) => row.id,
		manualFiltering: true,
		// getPaginationRowModel: getPaginationRowModel(),
		manualPagination: true,
		manualSorting: true,
		onColumnFiltersChange: (updaterOrValue) => {
			if (typeof updaterOrValue === 'function') {
				const value = updaterOrValue(columnFilters);
				setColumnFilters(value);
			} else {
				setColumnFilters(updaterOrValue);
			}
		},
		onExpandedChange: (updaterOrValue) => {
			if (typeof updaterOrValue === 'function') {
				const value = updaterOrValue(expanded);
				setExpanded(value);
			} else {
				setExpanded(updaterOrValue);
			}
		},
		onPaginationChange: setPagination as any,
		onRowSelectionChange: (updaterOrValue) => {
			if (typeof updaterOrValue === 'function') {
				const value = updaterOrValue(rowSelection);
				setRowSelection(value);
			} else {
				setRowSelection(updaterOrValue);
			}
		},
		onSortingChange: (updaterOrValue) => {
			if (typeof updaterOrValue === 'function') {
				const value = updaterOrValue(sorting);
				setSortingState(value);
			} else {
				setSortingState(updaterOrValue);
			}
		},
		// rowCount,
		state: {
			columnFilters,
			// sorting,
			expanded,
			pagination,
			rowSelection,
			sorting,
		},
	});

	/** ----------------------------------------------------------------------------------------------
	 * sideeffects
	 * _______________________________________________________________________________________________ */

	const tableIsAllRowsSelected = table.getIsAllRowsSelected();
	const tableIsSomeRowsSelected = table.getIsSomeRowsSelected();

	/* sync enableRowSelection from props to store */
	React.useEffect(() => {
		setEnableRowSelection(enableRowSelection);
	}, [enableRowSelection, setEnableRowSelection]);

	/* TODO: Improve later so that we only reset items that are no longer on the new page(size) */
	/* reset row selection when pagination changes */
	React.useEffect(() => {
		setRowSelection({});
	}, [setRowSelection, pagination.pageIndex, pagination.pageSize, data]);

	/* sync row selection status */
	React.useEffect(() => {
		setRowSelectionStatus(tableIsAllRowsSelected ? 'all' : tableIsSomeRowsSelected ? 'some' : 'none');
	}, [tableIsAllRowsSelected, tableIsSomeRowsSelected, setRowSelectionStatus]);

	React.useEffect(() => {
		setTotalRowCount(totalRowCount);
	}, [totalRowCount, setTotalRowCount]);

	/**
	 * if the last page is reached and the total row count changes,
	 * we need to reset the pagination index to the last page
	 */
	React.useEffect(() => {
		const isLastPage = pagination.pageIndex * pagination.pageSize >= storedTotalRowCount;
		if (isLastPage) {
			setPagination({ pageIndex: Math.max(0, Math.floor(storedTotalRowCount / pagination.pageSize)) });
		}
	}, [storedTotalRowCount, pagination.pageIndex, pagination.pageSize, setPagination]);

	/* initialize pagination if not set */
	React.useEffect(() => {
		if (pagination.pageIndex === -1) {
			setPagination({ pageIndex: 0 });
		}
		if (pagination.pageSize === -1) {
			setPagination({ pageSize: 20 });
		}
	}, [pagination.pageIndex, pagination.pageSize, setPagination]);

	/**
	 * reset pagination index when either the queryKey changes or the pageSize
	 * use `usePaginatedQuery` to avoid resetting the pagination index when the queryKey changes
	 *
	 * useDeepCompareEffect is used to avoid resetting the pagination index when the queryKey changes
	 * because the queryKey is a new array on every render
	 */
	useDeepCompareEffect(() => {
		setExpanded({});
		setPagination({ pageIndex: 0 });
	}, [queryKey, setPagination, pagination.pageSize, setExpanded]);

	/**
	 * reset scroll position when we change the page index
	 */
	React.useEffect(() => {
		if (tableContentElementRef.current) {
			tableContentElementRef.current.scrollTo({ behavior: 'smooth', top: 0 });
		}
	}, [pagination.pageIndex]);

	/** ----------------------------------------------------------------------------------------------
	 * styles
	 * _______________________________________________________________________________________________ */

	const { styleVars: columnResizeStyleVars } = useFeatureResize(table, tableRootElementRef);

	const styleVars = useCreateStyles(styles);

	const mergedStyle = React.useMemo(
		() => ({ ...columnResizeStyleVars, ...style, ...styleVars }),
		[style, columnResizeStyleVars, styleVars],
	);

	/** ----------------------------------------------------------------------------------------------
	 * rendering
	 * _______________________________________________________________________________________________ */

	const rows = table.getRowModel().rows;

	return (
		<>
			<Box ref={tableRootElementRef} className={clsx(s.root, className)} style={mergedStyle}>
				{tableTopBar}

				<Box ref={tableContentElementRef} className={s.content}>
					{/** ----------------------------------------------------------------------------------------------
					 * TableHeader
					 * _______________________________________________________________________________________________ */}
					<fieldset disabled={isFetching} style={{ display: 'contents' }}>
						<MemoizedTableHead key={'head'} className={s.headRoot} headerGroups={table.getHeaderGroups()} />
					</fieldset>
					{/** -------------------------------------------------------------------------------- --------------
					 * TableBody
					 * _______________________________________________________________________________________________ */}

					{/* we need an outer box to ensure the body always takes up the full space, even when there are no items */}
					<Box component="fieldset" disabled={isFetching} style={{ display: 'contents' }}>
						{isFetching ? (
							<TableBodySkeleton key="skeleton" className={s.bodyRoot} numRows={50} table={table} />
						) : (
							<MemoizedTableBody key="body" className={s.bodyRoot} renderExpanded={renderExpanded as any} rows={rows} />
						)}
					</Box>
					{/** ----------------------------------------------------------------------------------------------
					 * TableFooter (hidden for now)
					 * _______________________________________________________________________________________________ */}
					<fieldset disabled={isFetching} style={{ display: 'contents' }}>
						<MemoizedTableFoot key="foot" className={s.footRoot} footerGroups={table.getFooterGroups()} />
					</fieldset>
				</Box>

				{/** ----------------------------------------------------------------------------------------------
				 * TODO: Improve by not disabling the fieldset but instead using an AbortSignal
				 * _______________________________________________________________________________________________ */}
				{tableBottomBar}
			</Box>
		</>
	);
}
