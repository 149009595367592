import { Effect, Logger, LogLevel } from 'effect';
import React from 'react';

import { AuthService } from '../effect/global-services/AuthService';
import { GraphqlClientService } from '../effect/global-services/GraphqlService';
import { WebLockService } from '../effect/global-services/WebLockService';
import { GlobalRuntime } from '../effect/lib/runtime';

const GlobalServices = GlobalRuntime.runPromise(
	Effect.gen(function* () {
		// console.log('❌❌❌❌❌❌ GLOBAL SERVICES ❌❌❌❌❌❌');
		return {
			authService: yield* AuthService,
			graphqlService: yield* GraphqlClientService,
			webLockService: yield* WebLockService,
		};
	}).pipe(Effect.provide(WebLockService.Default), Effect.scoped),
);

export const useGlobalServices = () => {
	return React.use(GlobalServices);
};
