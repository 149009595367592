'use client';

import { Box, Button, Group, Paper, Stack, Text, Title, TitleOrder } from '@mantine/core';
import { Divider } from '@mantine/core';
import { IconCheck, IconMoodSadSquint as IconError } from '@tabler/icons-react';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';

import { useFrachterUser } from '@/hooks/use-frachter-user';

import { MonoButton } from '../ui/MonoButton';

import s from './Dialog.module.scss';

export interface DialogProps {
	className?: string;
	leftSection: React.ReactNode;
	noPaper?: boolean;
	rightSection: React.ReactNode;
	style?: React.CSSProperties;
}

/**
 * TODO: account switcher !
 * @param param0
 */
export function Dialog({ className, leftSection, noPaper, rightSection, style }: DialogProps) {
	const content = (
		<Stack className={clsx(s.container)}>
			<Box className={s.leftSection}>{leftSection}</Box>

			<Divider className={s.divider} orientation="vertical" px="sm" />

			<Stack className={s.rightSection}>{rightSection}</Stack>
		</Stack>
	);

	return noPaper ? (
		content
	) : (
		<Paper
			p="xl"
			radius={'lg'}
			shadow="md"
			styles={{ root: { ...style } }}
			classNames={{
				root: clsx(s.root, className),
			}}
		>
			{content}
		</Paper>
	);
}

const TitleElement = ({
	center = false,
	children,
	order = 2,
}: React.PropsWithChildren<{ order?: TitleOrder; center?: boolean }>) =>
	typeof children === 'string' ? (
		<Title lh={1} order={order} ta={center ? 'center' : undefined}>
			{children}
		</Title>
	) : (
		children
	);

const SubTitleElement = ({ children, order = 5 }: React.PropsWithChildren<{ order?: TitleOrder }>) => {
	return children && typeof children === 'object' && 'type' in children && children.type === 'span' ? (
		<Title fw={400} order={order}>
			{children}
		</Title>
	) : (
		children
	);
};

Dialog.Info = function Info({
	children,
	subTitle,
	title,
}: React.PropsWithChildren<{ subTitle?: React.ReactNode; title?: React.ReactNode }>) {
	return (
		// info is not available anymore... if we see issues, figure out why
		<Stack /* className={s.info} */ gap="lg">
			<TitleElement>{title}</TitleElement>
			<SubTitleElement>{subTitle}</SubTitleElement>
			<Box>{children}</Box>
		</Stack>
	);
};

export type DialogActions =
	| {
			disabled?: boolean;
			label: string;
			onClick: () => void;
			disabledSecondary?: never;
			labelSecondary?: null;
			onClickSecondary?: never;
	  }
	| {
			disabled?: boolean;
			label: string;
			onClick: () => void;
			disabledSecondary?: boolean;
			labelSecondary: string;
			onClickSecondary: () => void;
	  };

Dialog.Actions = function Actions({
	disabled,
	disabledSecondary,
	label,
	labelSecondary,
	onClick,
	onClickSecondary,
}: DialogActions) {
	return (
		<Box style={{ display: 'flex', gap: '1rem' }}>
			{labelSecondary && (
				<Button
					color="gray"
					disabled={Boolean(disabledSecondary)}
					flex={1}
					miw="min-content"
					onClick={onClickSecondary}
					variant="outline"
				>
					{labelSecondary}
				</Button>
			)}
			{label && (
				<MonoButton disabled={Boolean(disabled)} flex={1} miw="min-content" onClick={onClick}>
					{label}
				</MonoButton>
			)}
		</Box>
	);
};

Dialog.Success = function Success({
	children,
	message,
	title,
}: React.PropsWithChildren<{ message?: React.ReactNode; title?: React.ReactNode }>) {
	const [isVisible, setIsVisible] = React.useState(false);

	React.useEffect(() => {
		setIsVisible(true);
	}, []);

	return (
		<AnimatePresence>
			{isVisible && (
				<Box
					animate={{ opacity: 1, scale: 1 }}
					className={s.successOverlay}
					component={motion.div}
					initial={{ opacity: 0, scale: 0.8 }}
				>
					<Stack align="center" gap="xs">
						<IconCheck color="currentColor" size={80} />
						<TitleElement order={3}>{title}</TitleElement>
						<Text c="dimmed" fw={500} size="sm" ta="center">
							{message}
						</Text>
						{children}

						{/* {footer && <Box>{footer}</Box>} */}
					</Stack>
				</Box>
			)}
		</AnimatePresence>
	);
};

Dialog.Organization = function Organization({ orgSlug }: { orgSlug: string | null }) {
	const { user } = useFrachterUser();

	return (
		<Box className={s.organization}>
			<Group className={s.organizationName}>
				<Text size="sm">verbinden mit </Text>
				<Text fw={700} size="sm" suppressHydrationWarning>
					{orgSlug ?? user?.organization?.name}
				</Text>
			</Group>
		</Box>
	);
};

Dialog.Error = function Error({
	children,
	message,
	subtitle,
	title,
}: React.PropsWithChildren<{ message?: React.ReactNode; subtitle?: React.ReactNode; title?: React.ReactNode }>) {
	const [isVisible, setIsVisible] = React.useState(false);

	React.useEffect(() => {
		setIsVisible(true);
	}, []);

	return (
		<AnimatePresence>
			{isVisible && (
				<Box
					animate={{ opacity: 1, scale: 1 }}
					className={s.successOverlay}
					component={motion.div}
					initial={{ opacity: 0, scale: 0.8 }}
				>
					<Stack align="center" gap="xs" maw={400}>
						<IconError color="currentColor" size={50} />
						<TitleElement order={3}>{title}</TitleElement>
						{subtitle && (
							<Text fw={500} size="sm" ta="center">
								{subtitle}
							</Text>
						)}
						{message && (
							<Text c="dimmed" fw={500} mt="md" size="sm" ta="center">
								{message}
							</Text>
						)}
						{children}

						{/* {footer && <Box>{footer}</Box>} */}
					</Stack>
				</Box>
			)}
		</AnimatePresence>
	);
};

Dialog.WithComplete = function WithComplete({
	children,
	footer,
	isVisible,
	message,
}: React.PropsWithChildren<{ footer?: React.ReactNode; isVisible: boolean; message?: string }>) {
	return (
		<Box>
			{!isVisible && children}
			<AnimatePresence>
				{isVisible && (
					<Box
						animate={{ opacity: 1, scale: 1 }}
						className={s.successOverlay}
						component={motion.div}
						initial={{ opacity: 0, scale: 0.8 }}
					>
						<Stack align="center" gap="xs">
							<IconCheck color="currentColor" size={80} />
							{message && (
								<Text fw={600} size="md">
									{message}
								</Text>
							)}
							{footer && <Box>{footer}</Box>}
						</Stack>
					</Box>
				)}
			</AnimatePresence>
		</Box>
	);
};

// TODO integrate slack message !
Dialog.WithError = function WithError({
	children,
	footer,
	isVisible,
	message,
}: React.PropsWithChildren<{ footer?: React.ReactNode; isVisible?: boolean; message?: string }>) {
	return (
		<Box>
			{!isVisible && children}
			<AnimatePresence>
				{isVisible && (
					<Box
						animate={{ opacity: 1, scale: 1 }}
						className={s.successOverlay}
						component={motion.div}
						initial={{ opacity: 0, scale: 0.8 }}
					>
						<Stack align="center" gap="xs">
							<IconError color="red" size={80} />
							{message && (
								<Text fw={600} size="md">
									{message}
								</Text>
							)}
							{footer && <Box>{footer}</Box>}
						</Stack>
					</Box>
				)}
			</AnimatePresence>
		</Box>
	);
};
