export function formatDateParts(date: Date, locale: string): { datetime: string; weekday: string } {
	const datePart = new Intl.DateTimeFormat(locale, {
		day: '2-digit',
		month: '2-digit',
		year: 'numeric',
	}).format(date);

	const timePart = new Intl.DateTimeFormat(locale, {
		hour: '2-digit',
		hour12: false,
		minute: '2-digit',
	}).format(date);

	const weekday = new Intl.DateTimeFormat(locale, {
		weekday: 'long',
	}).format(date);

	const timeLabel = locale.startsWith('de') ? 'Uhr' : "o'clock";

	return {
		datetime: `${datePart}, ${timePart} ${timeLabel}`,
		weekday: weekday,
	};
}
