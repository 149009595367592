import { Box, Divider, Group, Paper, ScrollArea, Stack, Text, Title, UnstyledButton, Loader } from '@mantine/core';
import { ApiEnvironmentEnumSchema } from '@packages/lib/schema/system';
import React from 'react';

import { useOrganizationSyncStore } from '@/stores/organization-sync-store';

import s from './ConnectionSetupList.module.scss';
import { EnvironmentIndicator } from './EnvironmentIndicator';

export function ConnectionSetupList({ children }: { children: React.ReactNode }) {
	return (
		<Paper shadow="xs" style={{ borderRadius: 8 }}>
			<ScrollArea h={250}>
				<Box className={s.list} p={4}>
					{children}
				</Box>
			</ScrollArea>
		</Paper>
	);
}

export interface ConnectionSetupListItemProps {
	disabled?: boolean;
	disabledMessage?: string;
	environment?: ApiEnvironmentEnumSchema;
	id: string;
	label: string;
	loading?: boolean;
	logo: React.ReactNode;
	onClick: (id: string, environment?: ApiEnvironmentEnumSchema) => void | Promise<void>;
	subtitle?: string;
}

const ConnectionSetupListItem: React.FC<ConnectionSetupListItemProps> = ({
	disabled,
	disabledMessage = 'Bald verfügbar',
	environment = 'production',
	id,
	label,
	loading,
	logo,
	onClick,
	subtitle,
}) => {
	const [isPending, startTransition] = React.useTransition();

	const isSandboxEnabled = useOrganizationSyncStore((s) => s.isSandboxEnabled);

	const handleClick = React.useCallback(() => {
		startTransition(async () => {
			await onClick(id, environment);
		});
	}, [onClick, id, environment]);

	const isLoading = isPending || loading;
	const isDisabled = disabled || isLoading;

	const isHidden = environment === 'sandbox' && !isSandboxEnabled;

	return isHidden ? null : (
		<Stack className={s.root} gap={0}>
			<UnstyledButton className={s.button} disabled={isDisabled} onClick={handleClick}>
				<Box className={s.item} opacity={disabled ? 0.7 : 1} p={4}>
					<Box style={{ display: 'grid', gap: '1rem', gridTemplateColumns: 'auto 1fr auto' }}>
						<Box
							className={s.logo}
							style={{
								aspectRatio: 1,
								border: `1px solid color-mix(in srgb, var(--mantine-color-text) 10%, transparent)`,
								display: 'grid',
								filter: disabled ? 'grayscale(1)' : 'none',
								placeContent: 'center',
								position: 'relative',
								width: 60,
							}}
						>
							<Box style={{ inset: 0, opacity: isLoading ? 0 : 1, position: 'absolute' }}>{logo}</Box>

							<Box
								p="md"
								style={{
									display: 'grid',
									inset: 0,
									placeContent: 'center',
									position: 'absolute',
									visibility: isLoading ? 'visible' : 'hidden',
									zIndex: 1,
								}}
							>
								<Loader size="sm" />
							</Box>
						</Box>

						<Box style={{ alignContent: 'center', display: 'grid' }}>
							<Group justify="space-between">
								<Title order={6}>{label}</Title>
								<Box style={{ justifySelf: 'start' }}>
									<EnvironmentIndicator environment={environment ?? 'production'} />
								</Box>
							</Group>
							{subtitle && <Text size="sm">{subtitle}</Text>}
							{disabled && (
								<Text c="dimmed" fw={500} size="xs">
									{disabledMessage}
								</Text>
							)}
						</Box>
					</Box>
				</Box>
			</UnstyledButton>
			<Divider className={s.divider} />
		</Stack>
	);
};

ConnectionSetupList.Item = ConnectionSetupListItem;
