/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/~__root';
import { Route as OrgRouteImport } from './routes/~org/~route';
import { Route as IndexImport } from './routes/~index';
import { Route as PlaygroundWorkerImport } from './routes/~playground/~worker';
import { Route as PlaygroundPreloaderImport } from './routes/~playground/~preloader';
import { Route as PlaygroundLiveblocksImport } from './routes/~playground/~liveblocks';
import { Route as OrgSlugRouteImport } from './routes/~org/~$slug/~route';
import { Route as ConnectStatusImport } from './routes/~connect/~status';
import { Route as PlaygroundComponentsDhlFormImport } from './routes/~playground/~components/~dhl-form';
import { Route as OrgSlugSetupRouteImport } from './routes/~org/~$slug/~setup/~route';
import { Route as OrgSlugSettingsRouteImport } from './routes/~org/~$slug/~settings/~route';
import { Route as OrgSlugQuickshipRouteImport } from './routes/~org/~$slug/~quickship/~route';
import { Route as OrgSlugProductsRouteImport } from './routes/~org/~$slug/~products/~route';
import { Route as OrgSlugPlaygroundRouteImport } from './routes/~org/~$slug/~playground/~route';
import { Route as OrgSlugLogsRouteImport } from './routes/~org/~$slug/~logs/~route';
import { Route as OrgSlugLabelsRouteImport } from './routes/~org/~$slug/~labels/~route';
import { Route as OrgSlugDashboardRouteImport } from './routes/~org/~$slug/~dashboard/~route';
import { Route as PlaygroundTanstackTableIndexImport } from './routes/~playground/~tanstack-table/~index';
import { Route as PlaygroundRpcIndexImport } from './routes/~playground/~rpc/~index';
import { Route as PlaygroundClerkIndexImport } from './routes/~playground/~clerk/~index';
import { Route as OrgentryIndexImport } from './routes/~org/~(entry)/~index';
import { Route as OrgSlugIndexImport } from './routes/~org/~$slug/~index';
import { Route as ConnectApiIdIndexImport } from './routes/~connect/~$apiId/~index';
import { Route as OrgSlugSettingsSystemRouteImport } from './routes/~org/~$slug/~settings/~system/~route';
import { Route as OrgSlugSettingsShippingTemplatesRouteImport } from './routes/~org/~$slug/~settings/~shipping-templates/~route';
import { Route as OrgSlugSettingsShippingRulesRouteImport } from './routes/~org/~$slug/~settings/~shipping-rules/~route';
import { Route as OrgSlugSettingsQuickshipRouteImport } from './routes/~org/~$slug/~settings/~quickship/~route';
import { Route as OrgSlugSettingsProductsRouteImport } from './routes/~org/~$slug/~settings/~products/~route';
import { Route as OrgSlugSettingsOrganizationRouteImport } from './routes/~org/~$slug/~settings/~organization/~route';
import { Route as OrgSlugSettingsMarketsRouteImport } from './routes/~org/~$slug/~settings/~markets/~route';
import { Route as OrgSlugSettingsCloudprintRouteImport } from './routes/~org/~$slug/~settings/~cloudprint/~route';
import { Route as OrgSlugSettingsCarriersRouteImport } from './routes/~org/~$slug/~settings/~carriers/~route';
import { Route as OrgSlugSettingsBillingRouteImport } from './routes/~org/~$slug/~settings/~billing/~route';
import { Route as OrgSlugPlaygroundThemeImport } from './routes/~org/~$slug/~playground/~theme';
import { Route as PlaygroundEffectSharedRefIndexImport } from './routes/~playground/~effect/~shared-ref/~index';
import { Route as PlaygroundAblySpacesIndexImport } from './routes/~playground/~ably/~spaces/~index';
import { Route as PlaygroundAblySpacesNewIndexImport } from './routes/~playground/~ably/~spaces-new/~index';
import { Route as OrgSlugSetupIndexImport } from './routes/~org/~$slug/~setup/~index';
import { Route as OrgSlugSettingsIndexImport } from './routes/~org/~$slug/~settings/~index';
import { Route as OrgSlugPlaygroundWarehouseIndexImport } from './routes/~org/~$slug/~playground/~warehouse/~index';
import { Route as OrgSlugPlaygroundPgliteIndexImport } from './routes/~org/~$slug/~playground/~pglite/~index';
import { Route as OrgSlugPlaygroundElectricIndexImport } from './routes/~org/~$slug/~playground/~electric/~index';
import { Route as OrgSlugQuickshipMarketIdMerchantIdIndexImport } from './routes/~org/~$slug/~quickship/~$marketId/~$merchantId/~index';
import { Route as OrgSlugQuickshipMarketIdMerchantIdKeyIndexImport } from './routes/~org/~$slug/~quickship/~$marketId/~$merchantId/~$key/~index';

// Create/Update Routes

const OrgRouteRoute = OrgRouteImport.update({
  id: '/org',
  path: '/org',
  getParentRoute: () => rootRoute,
} as any);

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any);

const PlaygroundWorkerRoute = PlaygroundWorkerImport.update({
  id: '/playground/worker',
  path: '/playground/worker',
  getParentRoute: () => rootRoute,
} as any);

const PlaygroundPreloaderRoute = PlaygroundPreloaderImport.update({
  id: '/playground/preloader',
  path: '/playground/preloader',
  getParentRoute: () => rootRoute,
} as any);

const PlaygroundLiveblocksRoute = PlaygroundLiveblocksImport.update({
  id: '/playground/liveblocks',
  path: '/playground/liveblocks',
  getParentRoute: () => rootRoute,
} as any);

const OrgSlugRouteRoute = OrgSlugRouteImport.update({
  id: '/$slug',
  path: '/$slug',
  getParentRoute: () => OrgRouteRoute,
} as any);

const ConnectStatusRoute = ConnectStatusImport.update({
  id: '/connect/status',
  path: '/connect/status',
  getParentRoute: () => rootRoute,
} as any);

const PlaygroundComponentsDhlFormRoute =
  PlaygroundComponentsDhlFormImport.update({
    id: '/playground/components/dhl-form',
    path: '/playground/components/dhl-form',
    getParentRoute: () => rootRoute,
  } as any);

const OrgSlugSetupRouteRoute = OrgSlugSetupRouteImport.update({
  id: '/setup',
  path: '/setup',
  getParentRoute: () => OrgSlugRouteRoute,
} as any);

const OrgSlugSettingsRouteRoute = OrgSlugSettingsRouteImport.update({
  id: '/settings',
  path: '/settings',
  getParentRoute: () => OrgSlugRouteRoute,
} as any);

const OrgSlugQuickshipRouteRoute = OrgSlugQuickshipRouteImport.update({
  id: '/quickship',
  path: '/quickship',
  getParentRoute: () => OrgSlugRouteRoute,
} as any);

const OrgSlugProductsRouteRoute = OrgSlugProductsRouteImport.update({
  id: '/products',
  path: '/products',
  getParentRoute: () => OrgSlugRouteRoute,
} as any);

const OrgSlugPlaygroundRouteRoute = OrgSlugPlaygroundRouteImport.update({
  id: '/playground',
  path: '/playground',
  getParentRoute: () => OrgSlugRouteRoute,
} as any);

const OrgSlugLogsRouteRoute = OrgSlugLogsRouteImport.update({
  id: '/logs',
  path: '/logs',
  getParentRoute: () => OrgSlugRouteRoute,
} as any);

const OrgSlugLabelsRouteRoute = OrgSlugLabelsRouteImport.update({
  id: '/labels',
  path: '/labels',
  getParentRoute: () => OrgSlugRouteRoute,
} as any);

const OrgSlugDashboardRouteRoute = OrgSlugDashboardRouteImport.update({
  id: '/dashboard',
  path: '/dashboard',
  getParentRoute: () => OrgSlugRouteRoute,
} as any);

const PlaygroundTanstackTableIndexRoute =
  PlaygroundTanstackTableIndexImport.update({
    id: '/playground/tanstack-table/',
    path: '/playground/tanstack-table/',
    getParentRoute: () => rootRoute,
  } as any);

const PlaygroundRpcIndexRoute = PlaygroundRpcIndexImport.update({
  id: '/playground/rpc/',
  path: '/playground/rpc/',
  getParentRoute: () => rootRoute,
} as any);

const PlaygroundClerkIndexRoute = PlaygroundClerkIndexImport.update({
  id: '/playground/clerk/',
  path: '/playground/clerk/',
  getParentRoute: () => rootRoute,
} as any);

const OrgentryIndexRoute = OrgentryIndexImport.update({
  id: '/(entry)/',
  path: '/',
  getParentRoute: () => OrgRouteRoute,
} as any);

const OrgSlugIndexRoute = OrgSlugIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => OrgSlugRouteRoute,
} as any);

const ConnectApiIdIndexRoute = ConnectApiIdIndexImport.update({
  id: '/connect/$apiId/',
  path: '/connect/$apiId/',
  getParentRoute: () => rootRoute,
} as any);

const OrgSlugSettingsSystemRouteRoute = OrgSlugSettingsSystemRouteImport.update(
  {
    id: '/system',
    path: '/system',
    getParentRoute: () => OrgSlugSettingsRouteRoute,
  } as any,
);

const OrgSlugSettingsShippingTemplatesRouteRoute =
  OrgSlugSettingsShippingTemplatesRouteImport.update({
    id: '/shipping-templates',
    path: '/shipping-templates',
    getParentRoute: () => OrgSlugSettingsRouteRoute,
  } as any);

const OrgSlugSettingsShippingRulesRouteRoute =
  OrgSlugSettingsShippingRulesRouteImport.update({
    id: '/shipping-rules',
    path: '/shipping-rules',
    getParentRoute: () => OrgSlugSettingsRouteRoute,
  } as any);

const OrgSlugSettingsQuickshipRouteRoute =
  OrgSlugSettingsQuickshipRouteImport.update({
    id: '/quickship',
    path: '/quickship',
    getParentRoute: () => OrgSlugSettingsRouteRoute,
  } as any);

const OrgSlugSettingsProductsRouteRoute =
  OrgSlugSettingsProductsRouteImport.update({
    id: '/products',
    path: '/products',
    getParentRoute: () => OrgSlugSettingsRouteRoute,
  } as any);

const OrgSlugSettingsOrganizationRouteRoute =
  OrgSlugSettingsOrganizationRouteImport.update({
    id: '/organization',
    path: '/organization',
    getParentRoute: () => OrgSlugSettingsRouteRoute,
  } as any);

const OrgSlugSettingsMarketsRouteRoute =
  OrgSlugSettingsMarketsRouteImport.update({
    id: '/markets',
    path: '/markets',
    getParentRoute: () => OrgSlugSettingsRouteRoute,
  } as any);

const OrgSlugSettingsCloudprintRouteRoute =
  OrgSlugSettingsCloudprintRouteImport.update({
    id: '/cloudprint',
    path: '/cloudprint',
    getParentRoute: () => OrgSlugSettingsRouteRoute,
  } as any);

const OrgSlugSettingsCarriersRouteRoute =
  OrgSlugSettingsCarriersRouteImport.update({
    id: '/carriers',
    path: '/carriers',
    getParentRoute: () => OrgSlugSettingsRouteRoute,
  } as any);

const OrgSlugSettingsBillingRouteRoute =
  OrgSlugSettingsBillingRouteImport.update({
    id: '/billing',
    path: '/billing',
    getParentRoute: () => OrgSlugSettingsRouteRoute,
  } as any);

const OrgSlugPlaygroundThemeRoute = OrgSlugPlaygroundThemeImport.update({
  id: '/theme',
  path: '/theme',
  getParentRoute: () => OrgSlugPlaygroundRouteRoute,
} as any);

const PlaygroundEffectSharedRefIndexRoute =
  PlaygroundEffectSharedRefIndexImport.update({
    id: '/playground/effect/shared-ref/',
    path: '/playground/effect/shared-ref/',
    getParentRoute: () => rootRoute,
  } as any);

const PlaygroundAblySpacesIndexRoute = PlaygroundAblySpacesIndexImport.update({
  id: '/playground/ably/spaces/',
  path: '/playground/ably/spaces/',
  getParentRoute: () => rootRoute,
} as any);

const PlaygroundAblySpacesNewIndexRoute =
  PlaygroundAblySpacesNewIndexImport.update({
    id: '/playground/ably/spaces-new/',
    path: '/playground/ably/spaces-new/',
    getParentRoute: () => rootRoute,
  } as any);

const OrgSlugSetupIndexRoute = OrgSlugSetupIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => OrgSlugSetupRouteRoute,
} as any);

const OrgSlugSettingsIndexRoute = OrgSlugSettingsIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => OrgSlugSettingsRouteRoute,
} as any);

const OrgSlugPlaygroundWarehouseIndexRoute =
  OrgSlugPlaygroundWarehouseIndexImport.update({
    id: '/warehouse/',
    path: '/warehouse/',
    getParentRoute: () => OrgSlugPlaygroundRouteRoute,
  } as any);

const OrgSlugPlaygroundPgliteIndexRoute =
  OrgSlugPlaygroundPgliteIndexImport.update({
    id: '/pglite/',
    path: '/pglite/',
    getParentRoute: () => OrgSlugPlaygroundRouteRoute,
  } as any);

const OrgSlugPlaygroundElectricIndexRoute =
  OrgSlugPlaygroundElectricIndexImport.update({
    id: '/electric/',
    path: '/electric/',
    getParentRoute: () => OrgSlugPlaygroundRouteRoute,
  } as any);

const OrgSlugQuickshipMarketIdMerchantIdIndexRoute =
  OrgSlugQuickshipMarketIdMerchantIdIndexImport.update({
    id: '/$marketId/$merchantId/',
    path: '/$marketId/$merchantId/',
    getParentRoute: () => OrgSlugQuickshipRouteRoute,
  } as any);

const OrgSlugQuickshipMarketIdMerchantIdKeyIndexRoute =
  OrgSlugQuickshipMarketIdMerchantIdKeyIndexImport.update({
    id: '/$marketId/$merchantId/$key/',
    path: '/$marketId/$merchantId/$key/',
    getParentRoute: () => OrgSlugQuickshipRouteRoute,
  } as any);

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/';
      path: '/';
      fullPath: '/';
      preLoaderRoute: typeof IndexImport;
      parentRoute: typeof rootRoute;
    };
    '/org': {
      id: '/org';
      path: '/org';
      fullPath: '/org';
      preLoaderRoute: typeof OrgRouteImport;
      parentRoute: typeof rootRoute;
    };
    '/connect/status': {
      id: '/connect/status';
      path: '/connect/status';
      fullPath: '/connect/status';
      preLoaderRoute: typeof ConnectStatusImport;
      parentRoute: typeof rootRoute;
    };
    '/org/$slug': {
      id: '/org/$slug';
      path: '/$slug';
      fullPath: '/org/$slug';
      preLoaderRoute: typeof OrgSlugRouteImport;
      parentRoute: typeof OrgRouteImport;
    };
    '/playground/liveblocks': {
      id: '/playground/liveblocks';
      path: '/playground/liveblocks';
      fullPath: '/playground/liveblocks';
      preLoaderRoute: typeof PlaygroundLiveblocksImport;
      parentRoute: typeof rootRoute;
    };
    '/playground/preloader': {
      id: '/playground/preloader';
      path: '/playground/preloader';
      fullPath: '/playground/preloader';
      preLoaderRoute: typeof PlaygroundPreloaderImport;
      parentRoute: typeof rootRoute;
    };
    '/playground/worker': {
      id: '/playground/worker';
      path: '/playground/worker';
      fullPath: '/playground/worker';
      preLoaderRoute: typeof PlaygroundWorkerImport;
      parentRoute: typeof rootRoute;
    };
    '/connect/$apiId/': {
      id: '/connect/$apiId/';
      path: '/connect/$apiId';
      fullPath: '/connect/$apiId';
      preLoaderRoute: typeof ConnectApiIdIndexImport;
      parentRoute: typeof rootRoute;
    };
    '/org/$slug/': {
      id: '/org/$slug/';
      path: '/';
      fullPath: '/org/$slug/';
      preLoaderRoute: typeof OrgSlugIndexImport;
      parentRoute: typeof OrgSlugRouteImport;
    };
    '/org/(entry)/': {
      id: '/org/(entry)/';
      path: '/';
      fullPath: '/org/';
      preLoaderRoute: typeof OrgentryIndexImport;
      parentRoute: typeof OrgRouteImport;
    };
    '/playground/clerk/': {
      id: '/playground/clerk/';
      path: '/playground/clerk';
      fullPath: '/playground/clerk';
      preLoaderRoute: typeof PlaygroundClerkIndexImport;
      parentRoute: typeof rootRoute;
    };
    '/playground/rpc/': {
      id: '/playground/rpc/';
      path: '/playground/rpc';
      fullPath: '/playground/rpc';
      preLoaderRoute: typeof PlaygroundRpcIndexImport;
      parentRoute: typeof rootRoute;
    };
    '/playground/tanstack-table/': {
      id: '/playground/tanstack-table/';
      path: '/playground/tanstack-table';
      fullPath: '/playground/tanstack-table';
      preLoaderRoute: typeof PlaygroundTanstackTableIndexImport;
      parentRoute: typeof rootRoute;
    };
    '/org/$slug/dashboard': {
      id: '/org/$slug/dashboard';
      path: '/dashboard';
      fullPath: '/org/$slug/dashboard';
      preLoaderRoute: typeof OrgSlugDashboardRouteImport;
      parentRoute: typeof OrgSlugRouteImport;
    };
    '/org/$slug/labels': {
      id: '/org/$slug/labels';
      path: '/labels';
      fullPath: '/org/$slug/labels';
      preLoaderRoute: typeof OrgSlugLabelsRouteImport;
      parentRoute: typeof OrgSlugRouteImport;
    };
    '/org/$slug/logs': {
      id: '/org/$slug/logs';
      path: '/logs';
      fullPath: '/org/$slug/logs';
      preLoaderRoute: typeof OrgSlugLogsRouteImport;
      parentRoute: typeof OrgSlugRouteImport;
    };
    '/org/$slug/playground': {
      id: '/org/$slug/playground';
      path: '/playground';
      fullPath: '/org/$slug/playground';
      preLoaderRoute: typeof OrgSlugPlaygroundRouteImport;
      parentRoute: typeof OrgSlugRouteImport;
    };
    '/org/$slug/products': {
      id: '/org/$slug/products';
      path: '/products';
      fullPath: '/org/$slug/products';
      preLoaderRoute: typeof OrgSlugProductsRouteImport;
      parentRoute: typeof OrgSlugRouteImport;
    };
    '/org/$slug/quickship': {
      id: '/org/$slug/quickship';
      path: '/quickship';
      fullPath: '/org/$slug/quickship';
      preLoaderRoute: typeof OrgSlugQuickshipRouteImport;
      parentRoute: typeof OrgSlugRouteImport;
    };
    '/org/$slug/settings': {
      id: '/org/$slug/settings';
      path: '/settings';
      fullPath: '/org/$slug/settings';
      preLoaderRoute: typeof OrgSlugSettingsRouteImport;
      parentRoute: typeof OrgSlugRouteImport;
    };
    '/org/$slug/setup': {
      id: '/org/$slug/setup';
      path: '/setup';
      fullPath: '/org/$slug/setup';
      preLoaderRoute: typeof OrgSlugSetupRouteImport;
      parentRoute: typeof OrgSlugRouteImport;
    };
    '/playground/components/dhl-form': {
      id: '/playground/components/dhl-form';
      path: '/playground/components/dhl-form';
      fullPath: '/playground/components/dhl-form';
      preLoaderRoute: typeof PlaygroundComponentsDhlFormImport;
      parentRoute: typeof rootRoute;
    };
    '/org/$slug/settings/': {
      id: '/org/$slug/settings/';
      path: '/';
      fullPath: '/org/$slug/settings/';
      preLoaderRoute: typeof OrgSlugSettingsIndexImport;
      parentRoute: typeof OrgSlugSettingsRouteImport;
    };
    '/org/$slug/setup/': {
      id: '/org/$slug/setup/';
      path: '/';
      fullPath: '/org/$slug/setup/';
      preLoaderRoute: typeof OrgSlugSetupIndexImport;
      parentRoute: typeof OrgSlugSetupRouteImport;
    };
    '/playground/ably/spaces-new/': {
      id: '/playground/ably/spaces-new/';
      path: '/playground/ably/spaces-new';
      fullPath: '/playground/ably/spaces-new';
      preLoaderRoute: typeof PlaygroundAblySpacesNewIndexImport;
      parentRoute: typeof rootRoute;
    };
    '/playground/ably/spaces/': {
      id: '/playground/ably/spaces/';
      path: '/playground/ably/spaces';
      fullPath: '/playground/ably/spaces';
      preLoaderRoute: typeof PlaygroundAblySpacesIndexImport;
      parentRoute: typeof rootRoute;
    };
    '/playground/effect/shared-ref/': {
      id: '/playground/effect/shared-ref/';
      path: '/playground/effect/shared-ref';
      fullPath: '/playground/effect/shared-ref';
      preLoaderRoute: typeof PlaygroundEffectSharedRefIndexImport;
      parentRoute: typeof rootRoute;
    };
    '/org/$slug/playground/theme': {
      id: '/org/$slug/playground/theme';
      path: '/theme';
      fullPath: '/org/$slug/playground/theme';
      preLoaderRoute: typeof OrgSlugPlaygroundThemeImport;
      parentRoute: typeof OrgSlugPlaygroundRouteImport;
    };
    '/org/$slug/settings/billing': {
      id: '/org/$slug/settings/billing';
      path: '/billing';
      fullPath: '/org/$slug/settings/billing';
      preLoaderRoute: typeof OrgSlugSettingsBillingRouteImport;
      parentRoute: typeof OrgSlugSettingsRouteImport;
    };
    '/org/$slug/settings/carriers': {
      id: '/org/$slug/settings/carriers';
      path: '/carriers';
      fullPath: '/org/$slug/settings/carriers';
      preLoaderRoute: typeof OrgSlugSettingsCarriersRouteImport;
      parentRoute: typeof OrgSlugSettingsRouteImport;
    };
    '/org/$slug/settings/cloudprint': {
      id: '/org/$slug/settings/cloudprint';
      path: '/cloudprint';
      fullPath: '/org/$slug/settings/cloudprint';
      preLoaderRoute: typeof OrgSlugSettingsCloudprintRouteImport;
      parentRoute: typeof OrgSlugSettingsRouteImport;
    };
    '/org/$slug/settings/markets': {
      id: '/org/$slug/settings/markets';
      path: '/markets';
      fullPath: '/org/$slug/settings/markets';
      preLoaderRoute: typeof OrgSlugSettingsMarketsRouteImport;
      parentRoute: typeof OrgSlugSettingsRouteImport;
    };
    '/org/$slug/settings/organization': {
      id: '/org/$slug/settings/organization';
      path: '/organization';
      fullPath: '/org/$slug/settings/organization';
      preLoaderRoute: typeof OrgSlugSettingsOrganizationRouteImport;
      parentRoute: typeof OrgSlugSettingsRouteImport;
    };
    '/org/$slug/settings/products': {
      id: '/org/$slug/settings/products';
      path: '/products';
      fullPath: '/org/$slug/settings/products';
      preLoaderRoute: typeof OrgSlugSettingsProductsRouteImport;
      parentRoute: typeof OrgSlugSettingsRouteImport;
    };
    '/org/$slug/settings/quickship': {
      id: '/org/$slug/settings/quickship';
      path: '/quickship';
      fullPath: '/org/$slug/settings/quickship';
      preLoaderRoute: typeof OrgSlugSettingsQuickshipRouteImport;
      parentRoute: typeof OrgSlugSettingsRouteImport;
    };
    '/org/$slug/settings/shipping-rules': {
      id: '/org/$slug/settings/shipping-rules';
      path: '/shipping-rules';
      fullPath: '/org/$slug/settings/shipping-rules';
      preLoaderRoute: typeof OrgSlugSettingsShippingRulesRouteImport;
      parentRoute: typeof OrgSlugSettingsRouteImport;
    };
    '/org/$slug/settings/shipping-templates': {
      id: '/org/$slug/settings/shipping-templates';
      path: '/shipping-templates';
      fullPath: '/org/$slug/settings/shipping-templates';
      preLoaderRoute: typeof OrgSlugSettingsShippingTemplatesRouteImport;
      parentRoute: typeof OrgSlugSettingsRouteImport;
    };
    '/org/$slug/settings/system': {
      id: '/org/$slug/settings/system';
      path: '/system';
      fullPath: '/org/$slug/settings/system';
      preLoaderRoute: typeof OrgSlugSettingsSystemRouteImport;
      parentRoute: typeof OrgSlugSettingsRouteImport;
    };
    '/org/$slug/playground/electric/': {
      id: '/org/$slug/playground/electric/';
      path: '/electric';
      fullPath: '/org/$slug/playground/electric';
      preLoaderRoute: typeof OrgSlugPlaygroundElectricIndexImport;
      parentRoute: typeof OrgSlugPlaygroundRouteImport;
    };
    '/org/$slug/playground/pglite/': {
      id: '/org/$slug/playground/pglite/';
      path: '/pglite';
      fullPath: '/org/$slug/playground/pglite';
      preLoaderRoute: typeof OrgSlugPlaygroundPgliteIndexImport;
      parentRoute: typeof OrgSlugPlaygroundRouteImport;
    };
    '/org/$slug/playground/warehouse/': {
      id: '/org/$slug/playground/warehouse/';
      path: '/warehouse';
      fullPath: '/org/$slug/playground/warehouse';
      preLoaderRoute: typeof OrgSlugPlaygroundWarehouseIndexImport;
      parentRoute: typeof OrgSlugPlaygroundRouteImport;
    };
    '/org/$slug/quickship/$marketId/$merchantId/': {
      id: '/org/$slug/quickship/$marketId/$merchantId/';
      path: '/$marketId/$merchantId';
      fullPath: '/org/$slug/quickship/$marketId/$merchantId';
      preLoaderRoute: typeof OrgSlugQuickshipMarketIdMerchantIdIndexImport;
      parentRoute: typeof OrgSlugQuickshipRouteImport;
    };
    '/org/$slug/quickship/$marketId/$merchantId/$key/': {
      id: '/org/$slug/quickship/$marketId/$merchantId/$key/';
      path: '/$marketId/$merchantId/$key';
      fullPath: '/org/$slug/quickship/$marketId/$merchantId/$key';
      preLoaderRoute: typeof OrgSlugQuickshipMarketIdMerchantIdKeyIndexImport;
      parentRoute: typeof OrgSlugQuickshipRouteImport;
    };
  }
}

// Create and export the route tree

interface OrgSlugPlaygroundRouteRouteChildren {
  OrgSlugPlaygroundThemeRoute: typeof OrgSlugPlaygroundThemeRoute;
  OrgSlugPlaygroundElectricIndexRoute: typeof OrgSlugPlaygroundElectricIndexRoute;
  OrgSlugPlaygroundPgliteIndexRoute: typeof OrgSlugPlaygroundPgliteIndexRoute;
  OrgSlugPlaygroundWarehouseIndexRoute: typeof OrgSlugPlaygroundWarehouseIndexRoute;
}

const OrgSlugPlaygroundRouteRouteChildren: OrgSlugPlaygroundRouteRouteChildren =
  {
    OrgSlugPlaygroundThemeRoute: OrgSlugPlaygroundThemeRoute,
    OrgSlugPlaygroundElectricIndexRoute: OrgSlugPlaygroundElectricIndexRoute,
    OrgSlugPlaygroundPgliteIndexRoute: OrgSlugPlaygroundPgliteIndexRoute,
    OrgSlugPlaygroundWarehouseIndexRoute: OrgSlugPlaygroundWarehouseIndexRoute,
  };

const OrgSlugPlaygroundRouteRouteWithChildren =
  OrgSlugPlaygroundRouteRoute._addFileChildren(
    OrgSlugPlaygroundRouteRouteChildren,
  );

interface OrgSlugQuickshipRouteRouteChildren {
  OrgSlugQuickshipMarketIdMerchantIdIndexRoute: typeof OrgSlugQuickshipMarketIdMerchantIdIndexRoute;
  OrgSlugQuickshipMarketIdMerchantIdKeyIndexRoute: typeof OrgSlugQuickshipMarketIdMerchantIdKeyIndexRoute;
}

const OrgSlugQuickshipRouteRouteChildren: OrgSlugQuickshipRouteRouteChildren = {
  OrgSlugQuickshipMarketIdMerchantIdIndexRoute:
    OrgSlugQuickshipMarketIdMerchantIdIndexRoute,
  OrgSlugQuickshipMarketIdMerchantIdKeyIndexRoute:
    OrgSlugQuickshipMarketIdMerchantIdKeyIndexRoute,
};

const OrgSlugQuickshipRouteRouteWithChildren =
  OrgSlugQuickshipRouteRoute._addFileChildren(
    OrgSlugQuickshipRouteRouteChildren,
  );

interface OrgSlugSettingsRouteRouteChildren {
  OrgSlugSettingsIndexRoute: typeof OrgSlugSettingsIndexRoute;
  OrgSlugSettingsBillingRouteRoute: typeof OrgSlugSettingsBillingRouteRoute;
  OrgSlugSettingsCarriersRouteRoute: typeof OrgSlugSettingsCarriersRouteRoute;
  OrgSlugSettingsCloudprintRouteRoute: typeof OrgSlugSettingsCloudprintRouteRoute;
  OrgSlugSettingsMarketsRouteRoute: typeof OrgSlugSettingsMarketsRouteRoute;
  OrgSlugSettingsOrganizationRouteRoute: typeof OrgSlugSettingsOrganizationRouteRoute;
  OrgSlugSettingsProductsRouteRoute: typeof OrgSlugSettingsProductsRouteRoute;
  OrgSlugSettingsQuickshipRouteRoute: typeof OrgSlugSettingsQuickshipRouteRoute;
  OrgSlugSettingsShippingRulesRouteRoute: typeof OrgSlugSettingsShippingRulesRouteRoute;
  OrgSlugSettingsShippingTemplatesRouteRoute: typeof OrgSlugSettingsShippingTemplatesRouteRoute;
  OrgSlugSettingsSystemRouteRoute: typeof OrgSlugSettingsSystemRouteRoute;
}

const OrgSlugSettingsRouteRouteChildren: OrgSlugSettingsRouteRouteChildren = {
  OrgSlugSettingsIndexRoute: OrgSlugSettingsIndexRoute,
  OrgSlugSettingsBillingRouteRoute: OrgSlugSettingsBillingRouteRoute,
  OrgSlugSettingsCarriersRouteRoute: OrgSlugSettingsCarriersRouteRoute,
  OrgSlugSettingsCloudprintRouteRoute: OrgSlugSettingsCloudprintRouteRoute,
  OrgSlugSettingsMarketsRouteRoute: OrgSlugSettingsMarketsRouteRoute,
  OrgSlugSettingsOrganizationRouteRoute: OrgSlugSettingsOrganizationRouteRoute,
  OrgSlugSettingsProductsRouteRoute: OrgSlugSettingsProductsRouteRoute,
  OrgSlugSettingsQuickshipRouteRoute: OrgSlugSettingsQuickshipRouteRoute,
  OrgSlugSettingsShippingRulesRouteRoute:
    OrgSlugSettingsShippingRulesRouteRoute,
  OrgSlugSettingsShippingTemplatesRouteRoute:
    OrgSlugSettingsShippingTemplatesRouteRoute,
  OrgSlugSettingsSystemRouteRoute: OrgSlugSettingsSystemRouteRoute,
};

const OrgSlugSettingsRouteRouteWithChildren =
  OrgSlugSettingsRouteRoute._addFileChildren(OrgSlugSettingsRouteRouteChildren);

interface OrgSlugSetupRouteRouteChildren {
  OrgSlugSetupIndexRoute: typeof OrgSlugSetupIndexRoute;
}

const OrgSlugSetupRouteRouteChildren: OrgSlugSetupRouteRouteChildren = {
  OrgSlugSetupIndexRoute: OrgSlugSetupIndexRoute,
};

const OrgSlugSetupRouteRouteWithChildren =
  OrgSlugSetupRouteRoute._addFileChildren(OrgSlugSetupRouteRouteChildren);

interface OrgSlugRouteRouteChildren {
  OrgSlugIndexRoute: typeof OrgSlugIndexRoute;
  OrgSlugDashboardRouteRoute: typeof OrgSlugDashboardRouteRoute;
  OrgSlugLabelsRouteRoute: typeof OrgSlugLabelsRouteRoute;
  OrgSlugLogsRouteRoute: typeof OrgSlugLogsRouteRoute;
  OrgSlugPlaygroundRouteRoute: typeof OrgSlugPlaygroundRouteRouteWithChildren;
  OrgSlugProductsRouteRoute: typeof OrgSlugProductsRouteRoute;
  OrgSlugQuickshipRouteRoute: typeof OrgSlugQuickshipRouteRouteWithChildren;
  OrgSlugSettingsRouteRoute: typeof OrgSlugSettingsRouteRouteWithChildren;
  OrgSlugSetupRouteRoute: typeof OrgSlugSetupRouteRouteWithChildren;
}

const OrgSlugRouteRouteChildren: OrgSlugRouteRouteChildren = {
  OrgSlugIndexRoute: OrgSlugIndexRoute,
  OrgSlugDashboardRouteRoute: OrgSlugDashboardRouteRoute,
  OrgSlugLabelsRouteRoute: OrgSlugLabelsRouteRoute,
  OrgSlugLogsRouteRoute: OrgSlugLogsRouteRoute,
  OrgSlugPlaygroundRouteRoute: OrgSlugPlaygroundRouteRouteWithChildren,
  OrgSlugProductsRouteRoute: OrgSlugProductsRouteRoute,
  OrgSlugQuickshipRouteRoute: OrgSlugQuickshipRouteRouteWithChildren,
  OrgSlugSettingsRouteRoute: OrgSlugSettingsRouteRouteWithChildren,
  OrgSlugSetupRouteRoute: OrgSlugSetupRouteRouteWithChildren,
};

const OrgSlugRouteRouteWithChildren = OrgSlugRouteRoute._addFileChildren(
  OrgSlugRouteRouteChildren,
);

interface OrgRouteRouteChildren {
  OrgSlugRouteRoute: typeof OrgSlugRouteRouteWithChildren;
  OrgentryIndexRoute: typeof OrgentryIndexRoute;
}

const OrgRouteRouteChildren: OrgRouteRouteChildren = {
  OrgSlugRouteRoute: OrgSlugRouteRouteWithChildren,
  OrgentryIndexRoute: OrgentryIndexRoute,
};

const OrgRouteRouteWithChildren = OrgRouteRoute._addFileChildren(
  OrgRouteRouteChildren,
);

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute;
  '/org': typeof OrgRouteRouteWithChildren;
  '/connect/status': typeof ConnectStatusRoute;
  '/org/$slug': typeof OrgSlugRouteRouteWithChildren;
  '/playground/liveblocks': typeof PlaygroundLiveblocksRoute;
  '/playground/preloader': typeof PlaygroundPreloaderRoute;
  '/playground/worker': typeof PlaygroundWorkerRoute;
  '/connect/$apiId': typeof ConnectApiIdIndexRoute;
  '/org/$slug/': typeof OrgSlugIndexRoute;
  '/org/': typeof OrgentryIndexRoute;
  '/playground/clerk': typeof PlaygroundClerkIndexRoute;
  '/playground/rpc': typeof PlaygroundRpcIndexRoute;
  '/playground/tanstack-table': typeof PlaygroundTanstackTableIndexRoute;
  '/org/$slug/dashboard': typeof OrgSlugDashboardRouteRoute;
  '/org/$slug/labels': typeof OrgSlugLabelsRouteRoute;
  '/org/$slug/logs': typeof OrgSlugLogsRouteRoute;
  '/org/$slug/playground': typeof OrgSlugPlaygroundRouteRouteWithChildren;
  '/org/$slug/products': typeof OrgSlugProductsRouteRoute;
  '/org/$slug/quickship': typeof OrgSlugQuickshipRouteRouteWithChildren;
  '/org/$slug/settings': typeof OrgSlugSettingsRouteRouteWithChildren;
  '/org/$slug/setup': typeof OrgSlugSetupRouteRouteWithChildren;
  '/playground/components/dhl-form': typeof PlaygroundComponentsDhlFormRoute;
  '/org/$slug/settings/': typeof OrgSlugSettingsIndexRoute;
  '/org/$slug/setup/': typeof OrgSlugSetupIndexRoute;
  '/playground/ably/spaces-new': typeof PlaygroundAblySpacesNewIndexRoute;
  '/playground/ably/spaces': typeof PlaygroundAblySpacesIndexRoute;
  '/playground/effect/shared-ref': typeof PlaygroundEffectSharedRefIndexRoute;
  '/org/$slug/playground/theme': typeof OrgSlugPlaygroundThemeRoute;
  '/org/$slug/settings/billing': typeof OrgSlugSettingsBillingRouteRoute;
  '/org/$slug/settings/carriers': typeof OrgSlugSettingsCarriersRouteRoute;
  '/org/$slug/settings/cloudprint': typeof OrgSlugSettingsCloudprintRouteRoute;
  '/org/$slug/settings/markets': typeof OrgSlugSettingsMarketsRouteRoute;
  '/org/$slug/settings/organization': typeof OrgSlugSettingsOrganizationRouteRoute;
  '/org/$slug/settings/products': typeof OrgSlugSettingsProductsRouteRoute;
  '/org/$slug/settings/quickship': typeof OrgSlugSettingsQuickshipRouteRoute;
  '/org/$slug/settings/shipping-rules': typeof OrgSlugSettingsShippingRulesRouteRoute;
  '/org/$slug/settings/shipping-templates': typeof OrgSlugSettingsShippingTemplatesRouteRoute;
  '/org/$slug/settings/system': typeof OrgSlugSettingsSystemRouteRoute;
  '/org/$slug/playground/electric': typeof OrgSlugPlaygroundElectricIndexRoute;
  '/org/$slug/playground/pglite': typeof OrgSlugPlaygroundPgliteIndexRoute;
  '/org/$slug/playground/warehouse': typeof OrgSlugPlaygroundWarehouseIndexRoute;
  '/org/$slug/quickship/$marketId/$merchantId': typeof OrgSlugQuickshipMarketIdMerchantIdIndexRoute;
  '/org/$slug/quickship/$marketId/$merchantId/$key': typeof OrgSlugQuickshipMarketIdMerchantIdKeyIndexRoute;
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute;
  '/connect/status': typeof ConnectStatusRoute;
  '/playground/liveblocks': typeof PlaygroundLiveblocksRoute;
  '/playground/preloader': typeof PlaygroundPreloaderRoute;
  '/playground/worker': typeof PlaygroundWorkerRoute;
  '/connect/$apiId': typeof ConnectApiIdIndexRoute;
  '/org/$slug': typeof OrgSlugIndexRoute;
  '/org': typeof OrgentryIndexRoute;
  '/playground/clerk': typeof PlaygroundClerkIndexRoute;
  '/playground/rpc': typeof PlaygroundRpcIndexRoute;
  '/playground/tanstack-table': typeof PlaygroundTanstackTableIndexRoute;
  '/org/$slug/dashboard': typeof OrgSlugDashboardRouteRoute;
  '/org/$slug/labels': typeof OrgSlugLabelsRouteRoute;
  '/org/$slug/logs': typeof OrgSlugLogsRouteRoute;
  '/org/$slug/playground': typeof OrgSlugPlaygroundRouteRouteWithChildren;
  '/org/$slug/products': typeof OrgSlugProductsRouteRoute;
  '/org/$slug/quickship': typeof OrgSlugQuickshipRouteRouteWithChildren;
  '/playground/components/dhl-form': typeof PlaygroundComponentsDhlFormRoute;
  '/org/$slug/settings': typeof OrgSlugSettingsIndexRoute;
  '/org/$slug/setup': typeof OrgSlugSetupIndexRoute;
  '/playground/ably/spaces-new': typeof PlaygroundAblySpacesNewIndexRoute;
  '/playground/ably/spaces': typeof PlaygroundAblySpacesIndexRoute;
  '/playground/effect/shared-ref': typeof PlaygroundEffectSharedRefIndexRoute;
  '/org/$slug/playground/theme': typeof OrgSlugPlaygroundThemeRoute;
  '/org/$slug/settings/billing': typeof OrgSlugSettingsBillingRouteRoute;
  '/org/$slug/settings/carriers': typeof OrgSlugSettingsCarriersRouteRoute;
  '/org/$slug/settings/cloudprint': typeof OrgSlugSettingsCloudprintRouteRoute;
  '/org/$slug/settings/markets': typeof OrgSlugSettingsMarketsRouteRoute;
  '/org/$slug/settings/organization': typeof OrgSlugSettingsOrganizationRouteRoute;
  '/org/$slug/settings/products': typeof OrgSlugSettingsProductsRouteRoute;
  '/org/$slug/settings/quickship': typeof OrgSlugSettingsQuickshipRouteRoute;
  '/org/$slug/settings/shipping-rules': typeof OrgSlugSettingsShippingRulesRouteRoute;
  '/org/$slug/settings/shipping-templates': typeof OrgSlugSettingsShippingTemplatesRouteRoute;
  '/org/$slug/settings/system': typeof OrgSlugSettingsSystemRouteRoute;
  '/org/$slug/playground/electric': typeof OrgSlugPlaygroundElectricIndexRoute;
  '/org/$slug/playground/pglite': typeof OrgSlugPlaygroundPgliteIndexRoute;
  '/org/$slug/playground/warehouse': typeof OrgSlugPlaygroundWarehouseIndexRoute;
  '/org/$slug/quickship/$marketId/$merchantId': typeof OrgSlugQuickshipMarketIdMerchantIdIndexRoute;
  '/org/$slug/quickship/$marketId/$merchantId/$key': typeof OrgSlugQuickshipMarketIdMerchantIdKeyIndexRoute;
}

export interface FileRoutesById {
  __root__: typeof rootRoute;
  '/': typeof IndexRoute;
  '/org': typeof OrgRouteRouteWithChildren;
  '/connect/status': typeof ConnectStatusRoute;
  '/org/$slug': typeof OrgSlugRouteRouteWithChildren;
  '/playground/liveblocks': typeof PlaygroundLiveblocksRoute;
  '/playground/preloader': typeof PlaygroundPreloaderRoute;
  '/playground/worker': typeof PlaygroundWorkerRoute;
  '/connect/$apiId/': typeof ConnectApiIdIndexRoute;
  '/org/$slug/': typeof OrgSlugIndexRoute;
  '/org/(entry)/': typeof OrgentryIndexRoute;
  '/playground/clerk/': typeof PlaygroundClerkIndexRoute;
  '/playground/rpc/': typeof PlaygroundRpcIndexRoute;
  '/playground/tanstack-table/': typeof PlaygroundTanstackTableIndexRoute;
  '/org/$slug/dashboard': typeof OrgSlugDashboardRouteRoute;
  '/org/$slug/labels': typeof OrgSlugLabelsRouteRoute;
  '/org/$slug/logs': typeof OrgSlugLogsRouteRoute;
  '/org/$slug/playground': typeof OrgSlugPlaygroundRouteRouteWithChildren;
  '/org/$slug/products': typeof OrgSlugProductsRouteRoute;
  '/org/$slug/quickship': typeof OrgSlugQuickshipRouteRouteWithChildren;
  '/org/$slug/settings': typeof OrgSlugSettingsRouteRouteWithChildren;
  '/org/$slug/setup': typeof OrgSlugSetupRouteRouteWithChildren;
  '/playground/components/dhl-form': typeof PlaygroundComponentsDhlFormRoute;
  '/org/$slug/settings/': typeof OrgSlugSettingsIndexRoute;
  '/org/$slug/setup/': typeof OrgSlugSetupIndexRoute;
  '/playground/ably/spaces-new/': typeof PlaygroundAblySpacesNewIndexRoute;
  '/playground/ably/spaces/': typeof PlaygroundAblySpacesIndexRoute;
  '/playground/effect/shared-ref/': typeof PlaygroundEffectSharedRefIndexRoute;
  '/org/$slug/playground/theme': typeof OrgSlugPlaygroundThemeRoute;
  '/org/$slug/settings/billing': typeof OrgSlugSettingsBillingRouteRoute;
  '/org/$slug/settings/carriers': typeof OrgSlugSettingsCarriersRouteRoute;
  '/org/$slug/settings/cloudprint': typeof OrgSlugSettingsCloudprintRouteRoute;
  '/org/$slug/settings/markets': typeof OrgSlugSettingsMarketsRouteRoute;
  '/org/$slug/settings/organization': typeof OrgSlugSettingsOrganizationRouteRoute;
  '/org/$slug/settings/products': typeof OrgSlugSettingsProductsRouteRoute;
  '/org/$slug/settings/quickship': typeof OrgSlugSettingsQuickshipRouteRoute;
  '/org/$slug/settings/shipping-rules': typeof OrgSlugSettingsShippingRulesRouteRoute;
  '/org/$slug/settings/shipping-templates': typeof OrgSlugSettingsShippingTemplatesRouteRoute;
  '/org/$slug/settings/system': typeof OrgSlugSettingsSystemRouteRoute;
  '/org/$slug/playground/electric/': typeof OrgSlugPlaygroundElectricIndexRoute;
  '/org/$slug/playground/pglite/': typeof OrgSlugPlaygroundPgliteIndexRoute;
  '/org/$slug/playground/warehouse/': typeof OrgSlugPlaygroundWarehouseIndexRoute;
  '/org/$slug/quickship/$marketId/$merchantId/': typeof OrgSlugQuickshipMarketIdMerchantIdIndexRoute;
  '/org/$slug/quickship/$marketId/$merchantId/$key/': typeof OrgSlugQuickshipMarketIdMerchantIdKeyIndexRoute;
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath;
  fullPaths:
    | '/'
    | '/org'
    | '/connect/status'
    | '/org/$slug'
    | '/playground/liveblocks'
    | '/playground/preloader'
    | '/playground/worker'
    | '/connect/$apiId'
    | '/org/$slug/'
    | '/org/'
    | '/playground/clerk'
    | '/playground/rpc'
    | '/playground/tanstack-table'
    | '/org/$slug/dashboard'
    | '/org/$slug/labels'
    | '/org/$slug/logs'
    | '/org/$slug/playground'
    | '/org/$slug/products'
    | '/org/$slug/quickship'
    | '/org/$slug/settings'
    | '/org/$slug/setup'
    | '/playground/components/dhl-form'
    | '/org/$slug/settings/'
    | '/org/$slug/setup/'
    | '/playground/ably/spaces-new'
    | '/playground/ably/spaces'
    | '/playground/effect/shared-ref'
    | '/org/$slug/playground/theme'
    | '/org/$slug/settings/billing'
    | '/org/$slug/settings/carriers'
    | '/org/$slug/settings/cloudprint'
    | '/org/$slug/settings/markets'
    | '/org/$slug/settings/organization'
    | '/org/$slug/settings/products'
    | '/org/$slug/settings/quickship'
    | '/org/$slug/settings/shipping-rules'
    | '/org/$slug/settings/shipping-templates'
    | '/org/$slug/settings/system'
    | '/org/$slug/playground/electric'
    | '/org/$slug/playground/pglite'
    | '/org/$slug/playground/warehouse'
    | '/org/$slug/quickship/$marketId/$merchantId'
    | '/org/$slug/quickship/$marketId/$merchantId/$key';
  fileRoutesByTo: FileRoutesByTo;
  to:
    | '/'
    | '/connect/status'
    | '/playground/liveblocks'
    | '/playground/preloader'
    | '/playground/worker'
    | '/connect/$apiId'
    | '/org/$slug'
    | '/org'
    | '/playground/clerk'
    | '/playground/rpc'
    | '/playground/tanstack-table'
    | '/org/$slug/dashboard'
    | '/org/$slug/labels'
    | '/org/$slug/logs'
    | '/org/$slug/playground'
    | '/org/$slug/products'
    | '/org/$slug/quickship'
    | '/playground/components/dhl-form'
    | '/org/$slug/settings'
    | '/org/$slug/setup'
    | '/playground/ably/spaces-new'
    | '/playground/ably/spaces'
    | '/playground/effect/shared-ref'
    | '/org/$slug/playground/theme'
    | '/org/$slug/settings/billing'
    | '/org/$slug/settings/carriers'
    | '/org/$slug/settings/cloudprint'
    | '/org/$slug/settings/markets'
    | '/org/$slug/settings/organization'
    | '/org/$slug/settings/products'
    | '/org/$slug/settings/quickship'
    | '/org/$slug/settings/shipping-rules'
    | '/org/$slug/settings/shipping-templates'
    | '/org/$slug/settings/system'
    | '/org/$slug/playground/electric'
    | '/org/$slug/playground/pglite'
    | '/org/$slug/playground/warehouse'
    | '/org/$slug/quickship/$marketId/$merchantId'
    | '/org/$slug/quickship/$marketId/$merchantId/$key';
  id:
    | '__root__'
    | '/'
    | '/org'
    | '/connect/status'
    | '/org/$slug'
    | '/playground/liveblocks'
    | '/playground/preloader'
    | '/playground/worker'
    | '/connect/$apiId/'
    | '/org/$slug/'
    | '/org/(entry)/'
    | '/playground/clerk/'
    | '/playground/rpc/'
    | '/playground/tanstack-table/'
    | '/org/$slug/dashboard'
    | '/org/$slug/labels'
    | '/org/$slug/logs'
    | '/org/$slug/playground'
    | '/org/$slug/products'
    | '/org/$slug/quickship'
    | '/org/$slug/settings'
    | '/org/$slug/setup'
    | '/playground/components/dhl-form'
    | '/org/$slug/settings/'
    | '/org/$slug/setup/'
    | '/playground/ably/spaces-new/'
    | '/playground/ably/spaces/'
    | '/playground/effect/shared-ref/'
    | '/org/$slug/playground/theme'
    | '/org/$slug/settings/billing'
    | '/org/$slug/settings/carriers'
    | '/org/$slug/settings/cloudprint'
    | '/org/$slug/settings/markets'
    | '/org/$slug/settings/organization'
    | '/org/$slug/settings/products'
    | '/org/$slug/settings/quickship'
    | '/org/$slug/settings/shipping-rules'
    | '/org/$slug/settings/shipping-templates'
    | '/org/$slug/settings/system'
    | '/org/$slug/playground/electric/'
    | '/org/$slug/playground/pglite/'
    | '/org/$slug/playground/warehouse/'
    | '/org/$slug/quickship/$marketId/$merchantId/'
    | '/org/$slug/quickship/$marketId/$merchantId/$key/';
  fileRoutesById: FileRoutesById;
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute;
  OrgRouteRoute: typeof OrgRouteRouteWithChildren;
  ConnectStatusRoute: typeof ConnectStatusRoute;
  PlaygroundLiveblocksRoute: typeof PlaygroundLiveblocksRoute;
  PlaygroundPreloaderRoute: typeof PlaygroundPreloaderRoute;
  PlaygroundWorkerRoute: typeof PlaygroundWorkerRoute;
  ConnectApiIdIndexRoute: typeof ConnectApiIdIndexRoute;
  PlaygroundClerkIndexRoute: typeof PlaygroundClerkIndexRoute;
  PlaygroundRpcIndexRoute: typeof PlaygroundRpcIndexRoute;
  PlaygroundTanstackTableIndexRoute: typeof PlaygroundTanstackTableIndexRoute;
  PlaygroundComponentsDhlFormRoute: typeof PlaygroundComponentsDhlFormRoute;
  PlaygroundAblySpacesNewIndexRoute: typeof PlaygroundAblySpacesNewIndexRoute;
  PlaygroundAblySpacesIndexRoute: typeof PlaygroundAblySpacesIndexRoute;
  PlaygroundEffectSharedRefIndexRoute: typeof PlaygroundEffectSharedRefIndexRoute;
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  OrgRouteRoute: OrgRouteRouteWithChildren,
  ConnectStatusRoute: ConnectStatusRoute,
  PlaygroundLiveblocksRoute: PlaygroundLiveblocksRoute,
  PlaygroundPreloaderRoute: PlaygroundPreloaderRoute,
  PlaygroundWorkerRoute: PlaygroundWorkerRoute,
  ConnectApiIdIndexRoute: ConnectApiIdIndexRoute,
  PlaygroundClerkIndexRoute: PlaygroundClerkIndexRoute,
  PlaygroundRpcIndexRoute: PlaygroundRpcIndexRoute,
  PlaygroundTanstackTableIndexRoute: PlaygroundTanstackTableIndexRoute,
  PlaygroundComponentsDhlFormRoute: PlaygroundComponentsDhlFormRoute,
  PlaygroundAblySpacesNewIndexRoute: PlaygroundAblySpacesNewIndexRoute,
  PlaygroundAblySpacesIndexRoute: PlaygroundAblySpacesIndexRoute,
  PlaygroundEffectSharedRefIndexRoute: PlaygroundEffectSharedRefIndexRoute,
};

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>();

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "~__root.tsx",
      "children": [
        "/",
        "/org",
        "/connect/status",
        "/playground/liveblocks",
        "/playground/preloader",
        "/playground/worker",
        "/connect/$apiId/",
        "/playground/clerk/",
        "/playground/rpc/",
        "/playground/tanstack-table/",
        "/playground/components/dhl-form",
        "/playground/ably/spaces-new/",
        "/playground/ably/spaces/",
        "/playground/effect/shared-ref/"
      ]
    },
    "/": {
      "filePath": "~index.tsx"
    },
    "/org": {
      "filePath": "~org/~route.tsx",
      "children": [
        "/org/$slug",
        "/org/(entry)/"
      ]
    },
    "/connect/status": {
      "filePath": "~connect/~status.tsx"
    },
    "/org/$slug": {
      "filePath": "~org/~$slug/~route.tsx",
      "parent": "/org",
      "children": [
        "/org/$slug/",
        "/org/$slug/dashboard",
        "/org/$slug/labels",
        "/org/$slug/logs",
        "/org/$slug/playground",
        "/org/$slug/products",
        "/org/$slug/quickship",
        "/org/$slug/settings",
        "/org/$slug/setup"
      ]
    },
    "/playground/liveblocks": {
      "filePath": "~playground/~liveblocks.tsx"
    },
    "/playground/preloader": {
      "filePath": "~playground/~preloader.tsx"
    },
    "/playground/worker": {
      "filePath": "~playground/~worker.tsx"
    },
    "/connect/$apiId/": {
      "filePath": "~connect/~$apiId/~index.tsx"
    },
    "/org/$slug/": {
      "filePath": "~org/~$slug/~index.tsx",
      "parent": "/org/$slug"
    },
    "/org/(entry)/": {
      "filePath": "~org/~(entry)/~index.tsx",
      "parent": "/org"
    },
    "/playground/clerk/": {
      "filePath": "~playground/~clerk/~index.tsx"
    },
    "/playground/rpc/": {
      "filePath": "~playground/~rpc/~index.tsx"
    },
    "/playground/tanstack-table/": {
      "filePath": "~playground/~tanstack-table/~index.tsx"
    },
    "/org/$slug/dashboard": {
      "filePath": "~org/~$slug/~dashboard/~route.tsx",
      "parent": "/org/$slug"
    },
    "/org/$slug/labels": {
      "filePath": "~org/~$slug/~labels/~route.tsx",
      "parent": "/org/$slug"
    },
    "/org/$slug/logs": {
      "filePath": "~org/~$slug/~logs/~route.tsx",
      "parent": "/org/$slug"
    },
    "/org/$slug/playground": {
      "filePath": "~org/~$slug/~playground/~route.tsx",
      "parent": "/org/$slug",
      "children": [
        "/org/$slug/playground/theme",
        "/org/$slug/playground/electric/",
        "/org/$slug/playground/pglite/",
        "/org/$slug/playground/warehouse/"
      ]
    },
    "/org/$slug/products": {
      "filePath": "~org/~$slug/~products/~route.tsx",
      "parent": "/org/$slug"
    },
    "/org/$slug/quickship": {
      "filePath": "~org/~$slug/~quickship/~route.tsx",
      "parent": "/org/$slug",
      "children": [
        "/org/$slug/quickship/$marketId/$merchantId/",
        "/org/$slug/quickship/$marketId/$merchantId/$key/"
      ]
    },
    "/org/$slug/settings": {
      "filePath": "~org/~$slug/~settings/~route.tsx",
      "parent": "/org/$slug",
      "children": [
        "/org/$slug/settings/",
        "/org/$slug/settings/billing",
        "/org/$slug/settings/carriers",
        "/org/$slug/settings/cloudprint",
        "/org/$slug/settings/markets",
        "/org/$slug/settings/organization",
        "/org/$slug/settings/products",
        "/org/$slug/settings/quickship",
        "/org/$slug/settings/shipping-rules",
        "/org/$slug/settings/shipping-templates",
        "/org/$slug/settings/system"
      ]
    },
    "/org/$slug/setup": {
      "filePath": "~org/~$slug/~setup/~route.tsx",
      "parent": "/org/$slug",
      "children": [
        "/org/$slug/setup/"
      ]
    },
    "/playground/components/dhl-form": {
      "filePath": "~playground/~components/~dhl-form.tsx"
    },
    "/org/$slug/settings/": {
      "filePath": "~org/~$slug/~settings/~index.tsx",
      "parent": "/org/$slug/settings"
    },
    "/org/$slug/setup/": {
      "filePath": "~org/~$slug/~setup/~index.tsx",
      "parent": "/org/$slug/setup"
    },
    "/playground/ably/spaces-new/": {
      "filePath": "~playground/~ably/~spaces-new/~index.ts"
    },
    "/playground/ably/spaces/": {
      "filePath": "~playground/~ably/~spaces/~index.tsx"
    },
    "/playground/effect/shared-ref/": {
      "filePath": "~playground/~effect/~shared-ref/~index.tsx"
    },
    "/org/$slug/playground/theme": {
      "filePath": "~org/~$slug/~playground/~theme.tsx",
      "parent": "/org/$slug/playground"
    },
    "/org/$slug/settings/billing": {
      "filePath": "~org/~$slug/~settings/~billing/~route.tsx",
      "parent": "/org/$slug/settings"
    },
    "/org/$slug/settings/carriers": {
      "filePath": "~org/~$slug/~settings/~carriers/~route.tsx",
      "parent": "/org/$slug/settings"
    },
    "/org/$slug/settings/cloudprint": {
      "filePath": "~org/~$slug/~settings/~cloudprint/~route.tsx",
      "parent": "/org/$slug/settings"
    },
    "/org/$slug/settings/markets": {
      "filePath": "~org/~$slug/~settings/~markets/~route.tsx",
      "parent": "/org/$slug/settings"
    },
    "/org/$slug/settings/organization": {
      "filePath": "~org/~$slug/~settings/~organization/~route.tsx",
      "parent": "/org/$slug/settings"
    },
    "/org/$slug/settings/products": {
      "filePath": "~org/~$slug/~settings/~products/~route.tsx",
      "parent": "/org/$slug/settings"
    },
    "/org/$slug/settings/quickship": {
      "filePath": "~org/~$slug/~settings/~quickship/~route.tsx",
      "parent": "/org/$slug/settings"
    },
    "/org/$slug/settings/shipping-rules": {
      "filePath": "~org/~$slug/~settings/~shipping-rules/~route.tsx",
      "parent": "/org/$slug/settings"
    },
    "/org/$slug/settings/shipping-templates": {
      "filePath": "~org/~$slug/~settings/~shipping-templates/~route.tsx",
      "parent": "/org/$slug/settings"
    },
    "/org/$slug/settings/system": {
      "filePath": "~org/~$slug/~settings/~system/~route.tsx",
      "parent": "/org/$slug/settings"
    },
    "/org/$slug/playground/electric/": {
      "filePath": "~org/~$slug/~playground/~electric/~index.tsx",
      "parent": "/org/$slug/playground"
    },
    "/org/$slug/playground/pglite/": {
      "filePath": "~org/~$slug/~playground/~pglite/~index.tsx",
      "parent": "/org/$slug/playground"
    },
    "/org/$slug/playground/warehouse/": {
      "filePath": "~org/~$slug/~playground/~warehouse/~index.tsx",
      "parent": "/org/$slug/playground"
    },
    "/org/$slug/quickship/$marketId/$merchantId/": {
      "filePath": "~org/~$slug/~quickship/~$marketId/~$merchantId/~index.tsx",
      "parent": "/org/$slug/quickship"
    },
    "/org/$slug/quickship/$marketId/$merchantId/$key/": {
      "filePath": "~org/~$slug/~quickship/~$marketId/~$merchantId/~$key/~index.tsx",
      "parent": "/org/$slug/quickship"
    }
  }
}
ROUTE_MANIFEST_END */
