import { spec } from '../spec';
import { body } from './data';
export const getOrderLabelQueryParams = {
    default: {
        docFormat: 'PDF',
        includeDocs: 'URL',
        shipment: ['0034043333301020004890509'],
    },
};
export const createOrderShipments = {
    /**
     * Default for testing V54EPAK
     */
    [spec.schema.productEnum.Enum.Europaket]: {
        default: ({ billingNumber, referenceId }) => ({
            billingNumber,
            consignee: body.consigneeInternational,
            details: body.details,
            product: 'V54EPAK',
            refNo: referenceId,
            services: {
                additionalInsurance: {
                    currency: 'GBP',
                    value: 600,
                },
                endorsement: 'RETURN',
            },
            shipper: body.shipper,
        }),
    },
    [spec.schema.productEnum.Enum.Paket]: {
        /**
         * Default for testing V01PAK
         */
        default: ({ billingNumber, referenceId }) => ({
            billingNumber,
            consignee: body.consignee,
            details: body.details,
            product: 'V01PAK',
            refNo: referenceId,
            shipper: body.shipper,
        }),
        /**
         * Default for testing V01PAK with a value added service that does not require a return billing number.
         */
        vas: ({ billingNumber, referenceId }) => ({
            billingNumber,
            consignee: body.consignee,
            details: body.details,
            product: 'V01PAK',
            refNo: referenceId,
            services: {
                visualCheckOfAge: 'A16',
            },
            shipper: body.shipper,
        }),
    },
    /**
     * Default for testing V53WPAK
     */
    [spec.schema.productEnum.Enum.PaketInternational]: {
        default: ({ billingNumber, referenceId }) => ({
            billingNumber,
            consignee: body.consigneeInternational,
            customs: {
                exportType: 'PRESENT',
                items: [
                    {
                        countryOfOrigin: 'FRA',
                        hsCode: '123456',
                        itemDescription: 'Item 1',
                        itemValue: {
                            currency: 'EUR',
                            value: 10,
                        },
                        itemWeight: {
                            uom: 'kg',
                            value: 1,
                        },
                        packagedQuantity: 1,
                    },
                ],
                postalCharges: {
                    currency: 'EUR',
                    value: 1,
                },
            },
            details: body.details,
            product: 'V53WPAK',
            refNo: referenceId,
            services: {
                additionalInsurance: {
                    currency: 'EUR',
                    value: 600,
                },
                endorsement: 'RETURN',
            },
            shipper: body.shipper,
        }),
    },
    /**
     * Default for testing Retoure billingNumber
     * we just pass the same billing number twice,
     * which will always result in an error for the billing number
     * but for the returnBillingNumber only if it's not valid / unauthorized
     */
    [spec.schema.productEnum.Enum.Retoure]: {
        default: ({ billingNumber, product, referenceId, returnBillingNumber, }) => ({
            billingNumber,
            consignee: body.consignee,
            details: body.details,
            product: product === 'Paket' ? 'V01PAK' : 'V62WP',
            refNo: referenceId,
            services: {
                dhlRetoure: {
                    billingNumber: returnBillingNumber,
                    refNo: referenceId,
                    returnAddress: body.returnAddress,
                },
            },
            shipper: body.shipper,
        }),
    },
    /**
     * Default for testing V62WP
     */
    [spec.schema.productEnum.Enum.Warenpost]: {
        default: ({ billingNumber, referenceId }) => ({
            billingNumber,
            consignee: body.consignee,
            details: body.detailsWarenpost,
            product: 'V62WP',
            refNo: referenceId,
            services: {
                preferredNeighbour: 'Erika Mustermann',
            },
            shipper: body.shipper,
        }),
    },
    /**
     * Default for testing V66WPI
     */
    [spec.schema.productEnum.Enum.WarenpostInternational]: {
        default: ({ billingNumber, referenceId }) => ({
            billingNumber,
            consignee: body.consigneeInternational,
            details: body.detailsWarenpost,
            product: 'V66WPI',
            refNo: referenceId,
            services: {
                premium: true,
            },
            shipper: body.shipper,
        }),
    },
};
