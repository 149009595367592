import { Group } from '@mantine/core';
import React from 'react';

import type { IRowType } from './types';

import { MemoizedTableBottomBar, MemoizedTableTopBar, Table } from '@/components/table';
import { MonoButton } from '@/components/ui/MonoButton';

import { useBundleListQuery } from './use-bundle-list-query';
import { useBundleListTableStore } from './use-bundle-list-table-store';
import { useCreateColumns } from './use-create-columns';

export function BundleListTable(props: { marketId: string; merchantId: string }) {
	const pagination = useBundleListTableStore((s) => s.pagination);

	const [refreshedAt, setRefreshedAt] = React.useState<number>(Date.now());

	const { data, hasUpdate, totalRowCount } = useBundleListQuery({
		pagination,
		params: {
			bundleKeyReference: 'sku',
			marketId: props.marketId,
			merchantId: props.merchantId,
		},
		queryKey: [refreshedAt.toString()],
	});

	// const numOrdersLive = useDrizzleLive((db) =>
	// 	db
	// 		.select({ count: count() })
	// 		.from(dbs.marketOrdersLocal)
	// 		.where(
	// 			and(
	// 				eq(dbs.marketOrdersLocal.status, 'UNSHIPPED'),
	// 				eq(dbs.marketOrdersLocal.marketId, props.marketId),
	// 				eq(dbs.marketOrdersLocal.merchantId, props.merchantId),
	// 			),
	// 		),
	// );

	const refresh = React.useCallback(() => {
		setRefreshedAt(Date.now());
	}, []);

	const columns = useCreateColumns();

	const actions = React.useMemo(() => {
		return (
			<Group>
				<MonoButton disabled={!hasUpdate} onClick={refresh} size="xs">
					Aktualisieren
				</MonoButton>
			</Group>
		);
	}, [hasUpdate, refresh]);

	return (
		<Table<IRowType & { id: string }>
			columns={columns}
			data={data ?? []}
			queryKey={[refreshedAt.toString()]}
			tableBottomBar={<MemoizedTableBottomBar actions={actions} isFetching={false} />}
			tableTopBar={<MemoizedTableTopBar title="Bundles" />}
			totalRowCount={totalRowCount}
			styles={{
				cell: {
					py: 'xs',
				},
				content: {
					// HINT: if we don't set this, the head and foot row background will overlay the paper's side shadow
					px: 2,
				},
				frame: {
					// px: '4rem',
				},
			}}
		/>
	);
}
