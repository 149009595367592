import { Box } from '@mantine/core';
import { createFileRoute, Outlet } from '@tanstack/react-router';

import { AppPageLayout } from '@/layouts/app-page';

export const Route = createFileRoute('/org/$slug/setup')({
	component: () => (
		<AppPageLayout title="Ersteinrichtung" variant="default">
			<Box px="lg">
				<Outlet />
			</Box>
		</AppPageLayout>
	),
});
