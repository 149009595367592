import Spaces from '@ably/spaces';
import ably from 'ably';
import { Effect, Option, SubscriptionRef } from 'effect';

import { BackendService } from '../BackendService';

import { realtimeConfig } from './config';

declare global {
	interface Window {
		__ably__: {
			client?: ably.Realtime;
			clients?: ably.Realtime[];
		};
	}
}

export class RealtimeClientService extends Effect.Service<RealtimeClientService>()('RealtimeClientService', {
	scoped: Effect.gen(function* () {
		yield* Effect.logDebug('RealtimeClientService: START');
		const config = yield* realtimeConfig;

		const backendService = yield* BackendService;

		const ablyClient = new ably.Realtime({
			...(Option.isSome(config.ablyApiKey)
				? { clientId: Option.getOrThrow(config.ablyClientId), key: Option.getOrThrow(config.ablyApiKey) }
				: {
						// eslint-disable-next-line @typescript-eslint/no-misused-promises
						authCallback: async (_, callback) => {
							try {
								const result = await Effect.runPromise(backendService.getRealtimeClientToken);

								// if (result) {
								// 	console.log('RealtimeClientService: authCallback', JSON.parse(result.token.capability));
								// }

								if (result) {
									callback(null, result.token);
								} else {
									callback(`Could not get realtime token`, null);
								}
							} catch (err) {
								console.error(`Could not get realtime token`, err);
								callback(`Could not get realtime token`, null);
							}
						},
					}),
			autoConnect: false,
			disconnectedRetryTimeout: 1000,
			echoMessages: false,
			// logHandler: (msg) => Effect.runSync(Effect.logDebug(msg).pipe(Effect.withSpan('realtime-client'))),
			logLevel: 1,
			recover: (_, callback) => {
				callback(true);
			},
			suspendedRetryTimeout: 5_000,
			transportParams: {
				heartbeatInterval: 5000,
				remainPresentFor: 10_000,
			},
		});

		window.__ably__ ??= { clients: [] };

		window.__ably__.client = ablyClient;
		window.__ably__.clients?.push(ablyClient);

		const connectionStatusRef = yield* SubscriptionRef.make<ably.ConnectionState>('disconnected');

		ablyClient.connection.on((change) => {
			Effect.runSync(SubscriptionRef.set(connectionStatusRef, change.current));
		});

		const spaces = new Spaces(ablyClient);

		yield* Effect.logDebug('RealtimeClientService: READY');

		yield* Effect.addFinalizer((exit) =>
			Effect.gen(function* () {
				yield* Effect.logDebug('RealtimeClientService: CLOSING', { connectionStatus: ablyClient.connection.state });

				// HINT: keep this here until we've fixed the issue that the org does not switch after the tab was open for x hours

				ablyClient.close();

				// @ts-expect-error is ok
				if (window.__DEBUGGER__) {
					// eslint-disable-next-line no-debugger
					debugger;
				}

				// TODO: 🔴 WE NEED TO handle situations where we are NOT connected and especially for more than X seconds (20 ?)
				// what do we do in this case? ably seems to stop trying to re-establish the connection. So can we do that manually?
				// maybe the connection is suspended?
				yield* Effect.promise(() =>
					Promise.race([
						ablyClient.connection.whenState('initialized'),
						ablyClient.connection.whenState('closed'),
						ablyClient.connection.whenState('suspended'),
						ablyClient.connection.whenState('failed'),
						ablyClient.connection.whenState('disconnected'),
					]),
				);

				yield* Effect.logDebug('RealtimeClientService: CLOSED', { connectionStatus: ablyClient.connection.state });
			}),
		);

		return {
			client: ablyClient,
			connect: () =>
				Effect.gen(function* () {
					ablyClient.connect();
					yield* Effect.promise(() => ablyClient.connection.whenState('connected'));
				}),
			spaces,
		};
	}),
}) {}
