// prettier-ignore
export const countryCodeMap = {
    AD: { code: { alpha2: 'AD', alpha3: 'AND' }, label: { de: 'Andorra', en: 'Andorra' } },
    AE: { code: { alpha2: 'AE', alpha3: 'ARE' }, label: { de: 'Vereinigte Arabische Emirate', en: 'United Arab Emirates' } },
    AF: { code: { alpha2: 'AF', alpha3: 'AFG' }, label: { de: 'Afghanistan', en: 'Afghanistan' } },
    AG: { code: { alpha2: 'AG', alpha3: 'ATG' }, label: { de: 'Antigua und Barbuda', en: 'Antigua and Barbuda' } },
    AI: { code: { alpha2: 'AI', alpha3: 'AIA' }, label: { de: 'Anguilla', en: 'Anguilla' } },
    AL: { code: { alpha2: 'AL', alpha3: 'ALB' }, label: { de: 'Albanien', en: 'Albania' } },
    AM: { code: { alpha2: 'AM', alpha3: 'ARM' }, label: { de: 'Armenien', en: 'Armenia' } },
    AO: { code: { alpha2: 'AO', alpha3: 'AGO' }, label: { de: 'Angola', en: 'Angola' } },
    AR: { code: { alpha2: 'AR', alpha3: 'ARG' }, label: { de: 'Argentinien', en: 'Argentina' } },
    AS: { code: { alpha2: 'AS', alpha3: 'ASM' }, label: { de: 'Amerikanisch-Samoa', en: 'American Samoa' } },
    AT: { code: { alpha2: 'AT', alpha3: 'AUT' }, label: { de: 'Österreich', en: 'Austria' } },
    AU: { code: { alpha2: 'AU', alpha3: 'AUS' }, label: { de: 'Australien', en: 'Australia' } },
    AW: { code: { alpha2: 'AW', alpha3: 'ABW' }, label: { de: 'Aruba', en: 'Aruba' } },
    AX: { code: { alpha2: 'AX', alpha3: 'ALA' }, label: { de: 'Ålandinseln', en: 'Åland Islands' } },
    AZ: { code: { alpha2: 'AZ', alpha3: 'AZE' }, label: { de: 'Aserbaidschan', en: 'Azerbaijan' } },
    BA: { code: { alpha2: 'BA', alpha3: 'BIH' }, label: { de: 'Bosnien und Herzegowina', en: 'Bosnia and Herzegovina' } },
    BB: { code: { alpha2: 'BB', alpha3: 'BRB' }, label: { de: 'Barbados', en: 'Barbados' } },
    BD: { code: { alpha2: 'BD', alpha3: 'BGD' }, label: { de: 'Bangladesch', en: 'Bangladesh' } },
    BE: { code: { alpha2: 'BE', alpha3: 'BEL' }, label: { de: 'Belgien', en: 'Belgium' } },
    BF: { code: { alpha2: 'BF', alpha3: 'BFA' }, label: { de: 'Burkina Faso', en: 'Burkina Faso' } },
    BG: { code: { alpha2: 'BG', alpha3: 'BGR' }, label: { de: 'Bulgarien', en: 'Bulgaria' } },
    BH: { code: { alpha2: 'BH', alpha3: 'BHR' }, label: { de: 'Bahrain', en: 'Bahrain' } },
    BI: { code: { alpha2: 'BI', alpha3: 'BDI' }, label: { de: 'Burundi', en: 'Burundi' } },
    BJ: { code: { alpha2: 'BJ', alpha3: 'BEN' }, label: { de: 'Benin', en: 'Benin' } },
    BL: { code: { alpha2: 'BL', alpha3: 'BLM' }, label: { de: 'Saint-Barthélemy', en: 'Saint Barthélemy' } },
    BM: { code: { alpha2: 'BM', alpha3: 'BMU' }, label: { de: 'Bermuda', en: 'Bermuda' } },
    BN: { code: { alpha2: 'BN', alpha3: 'BRN' }, label: { de: 'Brunei Darussalam', en: 'Brunei Darussalam' } },
    BO: { code: { alpha2: 'BO', alpha3: 'BOL' }, label: { de: 'Bolivien', en: 'Bolivia' } },
    BQ: { code: { alpha2: 'BQ', alpha3: 'BES' }, label: { de: 'Bonaire, Sint Eustatius und Saba', en: 'Bonaire, Sint Eustatius and Saba' } },
    BR: { code: { alpha2: 'BR', alpha3: 'BRA' }, label: { de: 'Brasilien', en: 'Brazil' } },
    BS: { code: { alpha2: 'BS', alpha3: 'BHS' }, label: { de: 'Bahamas', en: 'Bahamas' } },
    BT: { code: { alpha2: 'BT', alpha3: 'BTN' }, label: { de: 'Bhutan', en: 'Bhutan' } },
    BV: { code: { alpha2: 'BV', alpha3: 'BVT' }, label: { de: 'Bouvetinsel', en: 'Bouvet Island' } },
    BW: { code: { alpha2: 'BW', alpha3: 'BWA' }, label: { de: 'Botswana', en: 'Botswana' } },
    BY: { code: { alpha2: 'BY', alpha3: 'BLR' }, label: { de: 'Weißrussland', en: 'Belarus' } },
    BZ: { code: { alpha2: 'BZ', alpha3: 'BLZ' }, label: { de: 'Belize', en: 'Belize' } },
    CA: { code: { alpha2: 'CA', alpha3: 'CAN' }, label: { de: 'Kanada', en: 'Canada' } },
    CC: { code: { alpha2: 'CC', alpha3: 'CCK' }, label: { de: 'Kokosinseln', en: 'Cocos (Keeling) Islands' } },
    CD: { code: { alpha2: 'CD', alpha3: 'COD' }, label: { de: 'Demokratische Republik Kongo', en: 'Democratic Republic of the Congo' } },
    CF: { code: { alpha2: 'CF', alpha3: 'CAF' }, label: { de: 'Zentralafrikanische Republik', en: 'Central African Republic' } },
    CG: { code: { alpha2: 'CG', alpha3: 'COG' }, label: { de: 'Republik Kongo', en: 'Republic of the Congo' } },
    CH: { code: { alpha2: 'CH', alpha3: 'CHE' }, label: { de: 'Schweiz', en: 'Switzerland' } },
    CI: { code: { alpha2: 'CI', alpha3: 'CIV' }, label: { de: 'Elfenbeinküste', en: 'Ivory Coast' } },
    CK: { code: { alpha2: 'CK', alpha3: 'COK' }, label: { de: 'Cookinseln', en: 'Cook Islands' } },
    CL: { code: { alpha2: 'CL', alpha3: 'CHL' }, label: { de: 'Chile', en: 'Chile' } },
    CM: { code: { alpha2: 'CM', alpha3: 'CMR' }, label: { de: 'Kamerun', en: 'Cameroon' } },
    CN: { code: { alpha2: 'CN', alpha3: 'CHN' }, label: { de: 'China', en: 'China' } },
    CO: { code: { alpha2: 'CO', alpha3: 'COL' }, label: { de: 'Kolumbien', en: 'Colombia' } },
    CR: { code: { alpha2: 'CR', alpha3: 'CRI' }, label: { de: 'Costa Rica', en: 'Costa Rica' } },
    CU: { code: { alpha2: 'CU', alpha3: 'CUB' }, label: { de: 'Kuba', en: 'Cuba' } },
    CV: { code: { alpha2: 'CV', alpha3: 'CPV' }, label: { de: 'Kap Verde', en: 'Cape Verde' } },
    CW: { code: { alpha2: 'CW', alpha3: 'CUW' }, label: { de: 'Curaçao', en: 'Curaçao' } },
    CX: { code: { alpha2: 'CX', alpha3: 'CXR' }, label: { de: 'Weihnachtsinsel', en: 'Christmas Island' } },
    CY: { code: { alpha2: 'CY', alpha3: 'CYP' }, label: { de: 'Zypern', en: 'Cyprus' } },
    CZ: { code: { alpha2: 'CZ', alpha3: 'CZE' }, label: { de: 'Tschechische Republik', en: 'Czech Republic' } },
    DE: { code: { alpha2: 'DE', alpha3: 'DEU' }, label: { de: 'Deutschland', en: 'Germany' } },
    DJ: { code: { alpha2: 'DJ', alpha3: 'DJI' }, label: { de: 'Dschibuti', en: 'Djibouti' } },
    DK: { code: { alpha2: 'DK', alpha3: 'DNK' }, label: { de: 'Dänemark', en: 'Denmark' } },
    DM: { code: { alpha2: 'DM', alpha3: 'DMA' }, label: { de: 'Dominica', en: 'Dominica' } },
    DO: { code: { alpha2: 'DO', alpha3: 'DOM' }, label: { de: 'Dominikanische Republik', en: 'Dominican Republic' } },
    DZ: { code: { alpha2: 'DZ', alpha3: 'DZA' }, label: { de: 'Algerien', en: 'Algeria' } },
    EC: { code: { alpha2: 'EC', alpha3: 'ECU' }, label: { de: 'Ecuador', en: 'Ecuador' } },
    EE: { code: { alpha2: 'EE', alpha3: 'EST' }, label: { de: 'Estland', en: 'Estonia' } },
    EG: { code: { alpha2: 'EG', alpha3: 'EGY' }, label: { de: 'Ägypten', en: 'Egypt' } },
    ER: { code: { alpha2: 'ER', alpha3: 'ERI' }, label: { de: 'Eritrea', en: 'Eritrea' } },
    ES: { code: { alpha2: 'ES', alpha3: 'ESP' }, label: { de: 'Spanien', en: 'Spain' } },
    ET: { code: { alpha2: 'ET', alpha3: 'ETH' }, label: { de: 'Äthiopien', en: 'Ethiopia' } },
    FI: { code: { alpha2: 'FI', alpha3: 'FIN' }, label: { de: 'Finnland', en: 'Finland' } },
    FJ: { code: { alpha2: 'FJ', alpha3: 'FJI' }, label: { de: 'Fidschi', en: 'Fiji' } },
    FK: { code: { alpha2: 'FK', alpha3: 'FLK' }, label: { de: 'Falklandinseln', en: 'Falkland Islands' } },
    FM: { code: { alpha2: 'FM', alpha3: 'FSM' }, label: { de: 'Mikronesien', en: 'Micronesia' } },
    FO: { code: { alpha2: 'FO', alpha3: 'FRO' }, label: { de: 'Färöer', en: 'Faroe Islands' } },
    FR: { code: { alpha2: 'FR', alpha3: 'FRA' }, label: { de: 'Frankreich', en: 'France' } },
    GA: { code: { alpha2: 'GA', alpha3: 'GAB' }, label: { de: 'Gabun', en: 'Gabon' } },
    GB: { code: { alpha2: 'GB', alpha3: 'GBR' }, label: { de: 'Vereinigtes Königreich', en: 'United Kingdom' } },
    GD: { code: { alpha2: 'GD', alpha3: 'GRD' }, label: { de: 'Grenada', en: 'Grenada' } },
    GE: { code: { alpha2: 'GE', alpha3: 'GEO' }, label: { de: 'Georgien', en: 'Georgia' } },
    GF: { code: { alpha2: 'GF', alpha3: 'GUF' }, label: { de: 'Französisch-Guayana', en: 'French Guiana' } },
    GG: { code: { alpha2: 'GG', alpha3: 'GGY' }, label: { de: 'Guernsey', en: 'Guernsey' } },
    GH: { code: { alpha2: 'GH', alpha3: 'GHA' }, label: { de: 'Ghana', en: 'Ghana' } },
    GI: { code: { alpha2: 'GI', alpha3: 'GIB' }, label: { de: 'Gibraltar', en: 'Gibraltar' } },
    GL: { code: { alpha2: 'GL', alpha3: 'GRL' }, label: { de: 'Grönland', en: 'Greenland' } },
    GM: { code: { alpha2: 'GM', alpha3: 'GMB' }, label: { de: 'Gambia', en: 'Gambia' } },
    GN: { code: { alpha2: 'GN', alpha3: 'GIN' }, label: { de: 'Guinea', en: 'Guinea' } },
    GP: { code: { alpha2: 'GP', alpha3: 'GLP' }, label: { de: 'Guadeloupe', en: 'Guadeloupe' } },
    GQ: { code: { alpha2: 'GQ', alpha3: 'GNQ' }, label: { de: 'Äquatorialguinea', en: 'Equatorial Guinea' } },
    GR: { code: { alpha2: 'GR', alpha3: 'GRC' }, label: { de: 'Griechenland', en: 'Greece' } },
    GT: { code: { alpha2: 'GT', alpha3: 'GTM' }, label: { de: 'Guatemala', en: 'Guatemala' } },
    GU: { code: { alpha2: 'GU', alpha3: 'GUM' }, label: { de: 'Guam', en: 'Guam' } },
    GW: { code: { alpha2: 'GW', alpha3: 'GNB' }, label: { de: 'Guinea-Bissau', en: 'Guinea-Bissau' } },
    GY: { code: { alpha2: 'GY', alpha3: 'GUY' }, label: { de: 'Guyana', en: 'Guyana' } },
    HK: { code: { alpha2: 'HK', alpha3: 'HKG' }, label: { de: 'Hongkong', en: 'Hong Kong' } },
    HM: { code: { alpha2: 'HM', alpha3: 'HMD' }, label: { de: 'Heard- und McDonald-Inseln', en: 'Heard Island and McDonald Islands' } },
    HN: { code: { alpha2: 'HN', alpha3: 'HND' }, label: { de: 'Honduras', en: 'Honduras' } },
    HR: { code: { alpha2: 'HR', alpha3: 'HRV' }, label: { de: 'Kroatien', en: 'Croatia' } },
    HT: { code: { alpha2: 'HT', alpha3: 'HTI' }, label: { de: 'Haiti', en: 'Haiti' } },
    HU: { code: { alpha2: 'HU', alpha3: 'HUN' }, label: { de: 'Ungarn', en: 'Hungary' } },
    ID: { code: { alpha2: 'ID', alpha3: 'IDN' }, label: { de: 'Indonesien', en: 'Indonesia' } },
    IE: { code: { alpha2: 'IE', alpha3: 'IRL' }, label: { de: 'Irland', en: 'Ireland' } },
    IL: { code: { alpha2: 'IL', alpha3: 'ISR' }, label: { de: 'Israel', en: 'Israel' } },
    IM: { code: { alpha2: 'IM', alpha3: 'IMN' }, label: { de: 'Isle of Man', en: 'Isle of Man' } },
    IN: { code: { alpha2: 'IN', alpha3: 'IND' }, label: { de: 'Indien', en: 'India' } },
    IQ: { code: { alpha2: 'IQ', alpha3: 'IRQ' }, label: { de: 'Irak', en: 'Iraq' } },
    IR: { code: { alpha2: 'IR', alpha3: 'IRN' }, label: { de: 'Iran', en: '	Iran' } },
    IS: { code: { alpha2: 'IS', alpha3: 'ISL' }, label: { de: 'Island', en: 'Iceland' } },
    IT: { code: { alpha2: 'IT', alpha3: 'ITA' }, label: { de: 'Italien', en: 'Italy' } },
    JE: { code: { alpha2: 'JE', alpha3: 'JEY' }, label: { de: 'Jersey', en: 'Jersey' } },
    JM: { code: { alpha2: 'JM', alpha3: 'JAM' }, label: { de: 'Jamaika', en: 'Jamaica' } },
    JO: { code: { alpha2: 'JO', alpha3: 'JOR' }, label: { de: 'Jordanien', en: 'Jordan' } },
    JP: { code: { alpha2: 'JP', alpha3: 'JPN' }, label: { de: 'Japan', en: 'Japan' } },
    KE: { code: { alpha2: 'KE', alpha3: 'KEN' }, label: { de: 'Kenia', en: 'Kenya' } },
    KG: { code: { alpha2: 'KG', alpha3: 'KGZ' }, label: { de: 'Kirgisistan', en: 'Kyrgyzstan' } },
    KH: { code: { alpha2: 'KH', alpha3: 'KHM' }, label: { de: 'Kambodscha', en: 'Cambodia' } },
    KI: { code: { alpha2: 'KI', alpha3: 'KIR' }, label: { de: 'Kiribati', en: 'Kiribati' } },
    KM: { code: { alpha2: 'KM', alpha3: 'COM' }, label: { de: 'Komoren', en: 'Comoros' } },
    KN: { code: { alpha2: 'KN', alpha3: 'KNA' }, label: { de: 'St. Kitts und Nevis', en: 'Saint Kitts and Nevis' } },
    KP: { code: { alpha2: 'KP', alpha3: 'PRK' }, label: { de: 'Nordkorea', en: 'North Korea' } },
    KR: { code: { alpha2: 'KR', alpha3: 'KOR' }, label: { de: 'Südkorea', en: 'South Korea' } },
    KW: { code: { alpha2: 'KW', alpha3: 'KWT' }, label: { de: 'Kuwait', en: 'Kuwait' } },
    KY: { code: { alpha2: 'KY', alpha3: 'CYM' }, label: { de: 'Kaimaninseln', en: 'Cayman Islands' } },
    KZ: { code: { alpha2: 'KZ', alpha3: 'KAZ' }, label: { de: 'Kasachstan', en: 'Kazakhstan' } },
    LA: { code: { alpha2: 'LA', alpha3: 'LAO' }, label: { de: 'Laos', en: 'Laos' } },
    LB: { code: { alpha2: 'LB', alpha3: 'LBN' }, label: { de: 'Libanon', en: 'Lebanon' } },
    LC: { code: { alpha2: 'LC', alpha3: 'LCA' }, label: { de: 'St. Lucia', en: 'Saint Lucia' } },
    LI: { code: { alpha2: 'LI', alpha3: 'LIE' }, label: { de: 'Liechtenstein', en: 'Liechtenstein' } },
    LK: { code: { alpha2: 'LK', alpha3: 'LKA' }, label: { de: 'Sri Lanka', en: 'Sri Lanka' } },
    LR: { code: { alpha2: 'LR', alpha3: 'LBR' }, label: { de: 'Liberia', en: 'Liberia' } },
    LS: { code: { alpha2: 'LS', alpha3: 'LSO' }, label: { de: 'Lesotho', en: 'Lesotho' } },
    LT: { code: { alpha2: 'LT', alpha3: 'LTU' }, label: { de: 'Litauen', en: 'Lithuania' } },
    LU: { code: { alpha2: 'LU', alpha3: 'LUX' }, label: { de: 'Luxemburg', en: 'Luxembourg' } },
    LV: { code: { alpha2: 'LV', alpha3: 'LVA' }, label: { de: 'Lettland', en: 'Latvia' } },
    LY: { code: { alpha2: 'LY', alpha3: 'LBY' }, label: { de: 'Libyen', en: 'Libya' } },
    MA: { code: { alpha2: 'MA', alpha3: 'MAR' }, label: { de: 'Marokko', en: 'Morocco' } },
    MC: { code: { alpha2: 'MC', alpha3: 'MCO' }, label: { de: 'Monaco', en: 'Monaco' } },
    MD: { code: { alpha2: 'MD', alpha3: 'MDA' }, label: { de: 'Moldawien', en: 'Moldova' } },
    ME: { code: { alpha2: 'ME', alpha3: 'MNE' }, label: { de: 'Montenegro', en: 'Montenegro' } },
    MF: { code: { alpha2: 'MF', alpha3: 'MAF' }, label: { de: 'Saint-Martin', en: 'Saint Martin' } },
    MG: { code: { alpha2: 'MG', alpha3: 'MDG' }, label: { de: 'Madagaskar', en: 'Madagascar' } },
    MH: { code: { alpha2: 'MH', alpha3: 'MHL' }, label: { de: 'Marshallinseln', en: 'Marshall Islands' } },
    MK: { code: { alpha2: 'MK', alpha3: 'MKD' }, label: { de: 'Nordmazedonien', en: 'North Macedonia' } },
    ML: { code: { alpha2: 'ML', alpha3: 'MLI' }, label: { de: 'Mali', en: 'Mali' } },
    MM: { code: { alpha2: 'MM', alpha3: 'MMR' }, label: { de: 'Myanmar', en: 'Myanmar' } },
    MN: { code: { alpha2: 'MN', alpha3: 'MNG' }, label: { de: 'Mongolei', en: 'Mongolia' } },
    MO: { code: { alpha2: 'MO', alpha3: 'MAC' }, label: { de: 'Macau', en: 'Macau' } },
    MP: { code: { alpha2: 'MP', alpha3: 'MNP' }, label: { de: 'Nördliche Marianen', en: 'Northern Mariana Islands' } },
    MQ: { code: { alpha2: 'MQ', alpha3: 'MTQ' }, label: { de: 'Martinique', en: 'Martinique' } },
    MR: { code: { alpha2: 'MR', alpha3: 'MRT' }, label: { de: 'Mauretanien', en: 'Mauritania' } },
    MS: { code: { alpha2: 'MS', alpha3: 'MSR' }, label: { de: 'Montserrat', en: 'Montserrat' } },
    MT: { code: { alpha2: 'MT', alpha3: 'MLT' }, label: { de: 'Malta', en: 'Malta' } },
    MU: { code: { alpha2: 'MU', alpha3: 'MUS' }, label: { de: 'Mauritius', en: 'Mauritius' } },
    MV: { code: { alpha2: 'MV', alpha3: 'MDV' }, label: { de: 'Malediven', en: 'Maldives' } },
    MW: { code: { alpha2: 'MW', alpha3: 'MWI' }, label: { de: 'Malawi', en: 'Malawi' } },
    MX: { code: { alpha2: 'MX', alpha3: 'MEX' }, label: { de: 'Mexiko', en: 'Mexico' } },
    MY: { code: { alpha2: 'MY', alpha3: 'MYS' }, label: { de: 'Malaysia', en: 'Malaysia' } },
    MZ: { code: { alpha2: 'MZ', alpha3: 'MOZ' }, label: { de: 'Mosambik', en: 'Mozambique' } },
    NA: { code: { alpha2: 'NA', alpha3: 'NAM' }, label: { de: 'Namibia', en: 'Namibia' } },
    NC: { code: { alpha2: 'NC', alpha3: 'NCL' }, label: { de: 'Neukaledonien', en: 'New Caledonia' } },
    NE: { code: { alpha2: 'NE', alpha3: 'NER' }, label: { de: 'Niger', en: 'Niger' } },
    NF: { code: { alpha2: 'NF', alpha3: 'NFK' }, label: { de: 'Norfolkinsel', en: 'Norfolk Island' } },
    NG: { code: { alpha2: 'NG', alpha3: 'NGA' }, label: { de: 'Nigeria', en: 'Nigeria' } },
    NI: { code: { alpha2: 'NI', alpha3: 'NIC' }, label: { de: 'Nicaragua', en: 'Nicaragua' } },
    NL: { code: { alpha2: 'NL', alpha3: 'NLD' }, label: { de: 'Niederlande', en: 'Netherlands' } },
    NO: { code: { alpha2: 'NO', alpha3: 'NOR' }, label: { de: 'Norwegen', en: 'Norway' } },
    NP: { code: { alpha2: 'NP', alpha3: 'NPL' }, label: { de: 'Nepal', en: 'Nepal' } },
    NR: { code: { alpha2: 'NR', alpha3: 'NRU' }, label: { de: 'Nauru', en: 'Nauru' } },
    NU: { code: { alpha2: 'NU', alpha3: 'NIU' }, label: { de: 'Niue', en: 'Niue' } },
    NZ: { code: { alpha2: 'NZ', alpha3: 'NZL' }, label: { de: 'Neuseeland', en: 'New Zealand' } },
    OM: { code: { alpha2: 'OM', alpha3: 'OMN' }, label: { de: 'Oman', en: 'Oman' } },
    PA: { code: { alpha2: 'PA', alpha3: 'PAN' }, label: { de: 'Panama', en: 'Panama' } },
    PE: { code: { alpha2: 'PE', alpha3: 'PER' }, label: { de: 'Peru', en: 'Peru' } },
    PF: { code: { alpha2: 'PF', alpha3: 'PYF' }, label: { de: 'Französisch-Polynesien', en: 'French Polynesia' } },
    PG: { code: { alpha2: 'PG', alpha3: 'PNG' }, label: { de: 'Papua-Neuguinea', en: 'Papua New Guinea' } },
    PH: { code: { alpha2: 'PH', alpha3: 'PHL' }, label: { de: 'Philippinen', en: 'Philippines' } },
    PK: { code: { alpha2: 'PK', alpha3: 'PAK' }, label: { de: 'Pakistan', en: 'Pakistan' } },
    PL: { code: { alpha2: 'PL', alpha3: 'POL' }, label: { de: 'Polen', en: 'Poland' } },
    PM: { code: { alpha2: 'PM', alpha3: 'SPM' }, label: { de: 'St. Pierre und Miquelon', en: 'Saint Pierre and Miquelon' } },
    PN: { code: { alpha2: 'PN', alpha3: 'PCN' }, label: { de: 'Pitcairn', en: 'Pitcairn' } },
    PR: { code: { alpha2: 'PR', alpha3: 'PRI' }, label: { de: 'Puerto Rico', en: 'Puerto Rico' } },
    PS: { code: { alpha2: 'PS', alpha3: 'PSE' }, label: { de: 'Palästina', en: 'Palestine' } },
    PT: { code: { alpha2: 'PT', alpha3: 'PRT' }, label: { de: 'Portugal', en: 'Portugal' } },
    PW: { code: { alpha2: 'PW', alpha3: 'PLW' }, label: { de: 'Palau', en: 'Palau' } },
    PY: { code: { alpha2: 'PY', alpha3: 'PRY' }, label: { de: 'Paraguay', en: 'Paraguay' } },
    QA: { code: { alpha2: 'QA', alpha3: 'QAT' }, label: { de: 'Katar', en: 'Qatar' } },
    RE: { code: { alpha2: 'RE', alpha3: 'REU' }, label: { de: 'Réunion', en: 'Réunion' } },
    RO: { code: { alpha2: 'RO', alpha3: 'ROU' }, label: { de: 'Rumänien', en: 'Romania' } },
    RS: { code: { alpha2: 'RS', alpha3: 'SRB' }, label: { de: 'Serbien', en: 'Serbia' } },
    RU: { code: { alpha2: 'RU', alpha3: 'RUS' }, label: { de: 'Russland', en: 'Russia' } },
    RW: { code: { alpha2: 'RW', alpha3: 'RWA' }, label: { de: 'Ruanda', en: 'Rwanda' } },
    SA: { code: { alpha2: 'SA', alpha3: 'SAU' }, label: { de: 'Saudi-Arabien', en: 'Saudi Arabia' } },
    SB: { code: { alpha2: 'SB', alpha3: 'SLB' }, label: { de: 'Salomonen', en: 'Solomon Islands' } },
    SC: { code: { alpha2: 'SC', alpha3: 'SYC' }, label: { de: 'Seychellen', en: 'Seychelles' } },
    SD: { code: { alpha2: 'SD', alpha3: 'SDN' }, label: { de: 'Sudan', en: 'Sudan' } },
    SE: { code: { alpha2: 'SE', alpha3: 'SWE' }, label: { de: 'Schweden', en: 'Sweden' } },
    SG: { code: { alpha2: 'SG', alpha3: 'SGP' }, label: { de: 'Singapur', en: 'Singapore' } },
    SH: { code: { alpha2: 'SH', alpha3: 'SHN' }, label: { de: 'St. Helena', en: 'Saint Helena' } },
    SI: { code: { alpha2: 'SI', alpha3: 'SVN' }, label: { de: 'Slowenien', en: 'Slovenia' } },
    SJ: { code: { alpha2: 'SJ', alpha3: 'SJM' }, label: { de: 'Svalbard und Jan Mayen', en: 'Svalbard and Jan Mayen' } },
    SK: { code: { alpha2: 'SK', alpha3: 'SVK' }, label: { de: 'Slowakei', en: 'Slovakia' } },
    SL: { code: { alpha2: 'SL', alpha3: 'SLE' }, label: { de: 'Sierra Leone', en: 'Sierra Leone' } },
    SM: { code: { alpha2: 'SM', alpha3: 'SMR' }, label: { de: 'San Marino', en: 'San Marino' } },
    SN: { code: { alpha2: 'SN', alpha3: 'SEN' }, label: { de: 'Senegal', en: 'Senegal' } },
    SO: { code: { alpha2: 'SO', alpha3: 'SOM' }, label: { de: 'Somalia', en: 'Somalia' } },
    SR: { code: { alpha2: 'SR', alpha3: 'SUR' }, label: { de: 'Suriname', en: 'Suriname' } },
    SS: { code: { alpha2: 'SS', alpha3: 'SSD' }, label: { de: 'Südsudan', en: 'South Sudan' } },
    ST: { code: { alpha2: 'ST', alpha3: 'STP' }, label: { de: 'São Tomé und Príncipe', en: 'Sao Tome and Principe' } },
    SV: { code: { alpha2: 'SV', alpha3: 'SLV' }, label: { de: 'El Salvador', en: 'El Salvador' } },
    SX: { code: { alpha2: 'SX', alpha3: 'SXM' }, label: { de: 'Sint Maarten', en: 'Sint Maarten' } },
    SY: { code: { alpha2: 'SY', alpha3: 'SYR' }, label: { de: 'Syrien', en: 'Syria' } },
    SZ: { code: { alpha2: 'SZ', alpha3: 'SWZ' }, label: { de: 'Eswatini', en: 'Eswatini' } },
    TC: { code: { alpha2: 'TC', alpha3: 'TCA' }, label: { de: 'Turks- und Caicosinseln', en: '	Turks and Caicos Islands' } },
    TD: { code: { alpha2: 'TD', alpha3: 'TCD' }, label: { de: 'Tschad', en: 'Chad' } },
    TG: { code: { alpha2: 'TG', alpha3: 'TGO' }, label: { de: 'Togo', en: 'Togo' } },
    TH: { code: { alpha2: 'TH', alpha3: 'THA' }, label: { de: 'Thailand', en: 'Thailand' } },
    TJ: { code: { alpha2: 'TJ', alpha3: 'TJK' }, label: { de: 'Tadschikistan', en: 'Tajikistan' } },
    TK: { code: { alpha2: 'TK', alpha3: 'TKL' }, label: { de: 'Tokelau', en: 'Tokelau' } },
    TL: { code: { alpha2: 'TL', alpha3: 'TLS' }, label: { de: 'Osttimor', en: 'Timor-Leste' } },
    TM: { code: { alpha2: 'TM', alpha3: 'TKM' }, label: { de: 'Turkmenistan', en: 'Turkmenistan' } },
    TN: { code: { alpha2: 'TN', alpha3: 'TUN' }, label: { de: 'Tunesien', en: 'Tunisia' } },
    TO: { code: { alpha2: 'TO', alpha3: 'TON' }, label: { de: 'Tonga', en: 'Tonga' } },
    TR: { code: { alpha2: 'TR', alpha3: 'TUR' }, label: { de: 'Türkei', en: 'Turkey' } },
    TT: { code: { alpha2: 'TT', alpha3: 'TTO' }, label: { de: 'Trinidad und Tobago', en: 'Trinidad and Tobago' } },
    TV: { code: { alpha2: 'TV', alpha3: 'TUV' }, label: { de: 'Tuvalu', en: 'Tuvalu' } },
    TW: { code: { alpha2: 'TW', alpha3: 'TWN' }, label: { de: 'Taiwan', en: 'Taiwan' } },
    TZ: { code: { alpha2: 'TZ', alpha3: 'TZA' }, label: { de: 'Tansania', en: 'Tanzania' } },
    UA: { code: { alpha2: 'UA', alpha3: 'UKR' }, label: { de: 'Ukraine', en: 'Ukraine' } },
    UG: { code: { alpha2: 'UG', alpha3: 'UGA' }, label: { de: 'Uganda', en: 'Uganda' } },
    US: { code: { alpha2: 'US', alpha3: 'USA' }, label: { de: 'Vereinigte Staaten von Amerika', en: 'United States of America' } },
    UY: { code: { alpha2: 'UY', alpha3: 'URY' }, label: { de: 'Uruguay', en: 'Uruguay' } },
    UZ: { code: { alpha2: 'UZ', alpha3: 'UZB' }, label: { de: 'Usbekistan', en: 'Uzbekistan' } },
    VA: { code: { alpha2: 'VA', alpha3: 'VAT' }, label: { de: 'Vatikanstadt', en: 'Vatican City' } },
    VC: { code: { alpha2: 'VC', alpha3: 'VCT' }, label: { de: 'St. Vincent und die Grenadinen', en: 'Saint Vincent and the Grenadines' } },
    VE: { code: { alpha2: 'VE', alpha3: 'VEN' }, label: { de: 'Venezuela', en: 'Venezuela' } },
    VG: { code: { alpha2: 'VG', alpha3: 'VGB' }, label: { de: 'Britische Jungferninseln', en: 'British Virgin Islands' } },
    VI: { code: { alpha2: 'VI', alpha3: 'VIR' }, label: { de: 'Amerikanische Jungferninseln', en: 'U.S. Virgin Islands' } },
    VN: { code: { alpha2: 'VN', alpha3: 'VNM' }, label: { de: 'Vietnam', en: 'Vietnam' } },
    VU: { code: { alpha2: 'VU', alpha3: 'VUT' }, label: { de: 'Vanuatu', en: 'Vanuatu' } },
    WF: { code: { alpha2: 'WF', alpha3: 'WLF' }, label: { de: 'Wallis und Futuna', en: 'Wallis and Futuna' } },
    WS: { code: { alpha2: 'WS', alpha3: 'WSM' }, label: { de: 'Samoa', en: 'Samoa' } },
    YE: { code: { alpha2: 'YE', alpha3: 'YEM' }, label: { de: 'Jemen', en: 'Yemen' } },
    YT: { code: { alpha2: 'YT', alpha3: 'MYT' }, label: { de: 'Mayotte', en: 'Mayotte' } },
    ZA: { code: { alpha2: 'ZA', alpha3: 'ZAF' }, label: { de: 'Südafrika', en: 'South Africa' } },
    ZM: { code: { alpha2: 'ZM', alpha3: 'ZMB' }, label: { de: 'Sambia', en: 'Zambia' } },
    ZW: { code: { alpha2: 'ZW', alpha3: 'ZWE' }, label: { de: 'Simbabwe', en: 'Zimbabwe' } },
};
// Function to get the German label with inferred return type
export const getCountryLabelForAlpha3Code = (alpha3) => {
    return Object.values(countryCodeMap).find((entry) => entry.code.alpha3 === alpha3).label.de;
};
// Function to get the alpha2 code with inferred return type
export const getCountryCodeAlpha2ForAlpha3 = (alpha3) => {
    return Object.values(countryCodeMap).find((entry) => entry.code.alpha3 === alpha3).code.alpha2;
};
