import { getApiById } from '@packages/lib/api';
// import { DhlParcelDeReturnsPostParcelGermanyV1Sdk } from '@packages/lib/api-sdk/dhl-parcel-de-returns-post-parcel-germany-v1';
// import { DhlParcelDeShippingPostParcelGermanyV2Sdk } from '@packages/lib/api-sdk/dhl-parcel-de-shipping-post-parcel-germany-v2';
// import { CarrierSpecApiSchema, carrierProviderEnumSchema } from '@packages/lib/schema/carriers';

import LogoCarrierDhlSquare from '@/assets/logo-carrier-dhl-square.svg';
import LogoCarrierDhl from '@/assets/logo-carrier-dhl.svg';
// import LogoCarrierDpdSquare from '@/assets/logo-carrier-dpd-square.svg';
// import LogoCarrierDpd from '@/assets/logo-carrier-dpd.svg';
import LogoCarrierGlsSquare from '@/assets/logo-carrier-gls-square.svg';
import LogoCarrierGls from '@/assets/logo-carrier-gls.svg';
// import LogoCarrierHermesSquare from '@/assets/logo-carrier-hermes-square.svg';
// import LogoCarrierHermes from '@/assets/logo-carrier-hermes.svg';
// import LogoCarrierUpsSquare from '@/assets/logo-carrier-ups-square.svg';
// import LogoCarrierUps from '@/assets/logo-carrier-ups.svg';
// import LogoMarketAmazonSquare from '@/assets/logo-market-amazon-square.svg';
// import LogoMarketAmazon from '@/assets/logo-market-amazon.svg';
// import LogoMarketKauflandSquare from '@/assets/logo-market-kaufland-square.svg';
// import LogoMarketKaufland from '@/assets/logo-market-kaufland.svg';
import LogoMarketOttoSquare from '@/assets/logo-market-otto-square.svg';
import LogoMarketOtto from '@/assets/logo-market-otto.svg';
// import LogoMarketZalandoSquare from '@/assets/logo-market-zalando-square.svg';
// import LogoMarketZalando from '@/assets/logo-market-zalando.svg';

export const MarketProviderConfig = {
	// [marketProviderEnumSchema.Enum.amazon_market]: {
	// 	description: 'Amazon Marktplatz',
	// 	Logo: LogoMarketAmazon,
	// 	LogoSquare: LogoMarketAmazonSquare,
	// 	name: 'Amazon',
	// },
	[getApiById('fra').id]: {
		Logo: () => null,
		LogoSquare: () => null,
	},
	// TODO: replace with GuteMarken logo
	[getApiById('guma').id]: {
		Logo: LogoMarketOtto,
		LogoSquare: LogoMarketOttoSquare,
	},

	// [marketProviderEnumSchema.Enum.kaufland_market]: {
	// 	description: 'Kaufland Marktplatz',
	// 	Logo: LogoMarketKaufland,
	// 	LogoSquare: LogoMarketKauflandSquare,
	// 	name: 'Kaufland',
	// },
	[getApiById('otto').id]: {
		Logo: LogoMarketOtto,
		LogoSquare: LogoMarketOttoSquare,
	},

	// [marketProviderEnumSchema.Enum.zalando_market]: {
	// 	description: 'Zalando Marktplatz',
	// 	Logo: LogoMarketZalando,
	// 	LogoSquare: LogoMarketZalandoSquare,
	// 	name: 'Zalando',
	// },
} satisfies Record<
	string,
	{
		Logo: React.FC;
		LogoSquare: React.FC;
	}
>;

export const CarrierProviderConfig = {
	[getApiById('dhl1').id]: {
		Logo: LogoCarrierDhl,
		LogoSquare: LogoCarrierDhlSquare,
	},
	[getApiById('dhl2').id]: {
		Logo: LogoCarrierDhl,
		LogoSquare: LogoCarrierDhlSquare,
	},
	// [carrierProviderEnumSchema.Enum.DPD]: {
	// 	// apis: {},
	// 	Logo: LogoCarrierDpd,
	// 	LogoSquare: LogoCarrierDpdSquare,
	// 	name: 'DPD',
	// },
	[getApiById('gls1').id]: {
		Logo: LogoCarrierGls,
		LogoSquare: LogoCarrierGlsSquare,
	},
	// [carrierProviderEnumSchema.Enum.HERMES]: {
	// 	// apis: {},
	// 	Logo: LogoCarrierHermes,
	// 	LogoSquare: LogoCarrierHermesSquare,
	// 	name: 'Hermes',
	// },
	// [carrierProviderEnumSchema.Enum.UPS]: {
	// 	// apis: {},
	// 	Logo: LogoCarrierUps,
	// 	LogoSquare: LogoCarrierUpsSquare,
	// 	name: 'UPS',
	// },
} satisfies Record<
	string,
	{
		Logo: React.FC;
		LogoSquare: React.FC;
	}
>;
