import { z } from 'zod';
export const userRoleSchema = z.enum(['org:superuser', 'org:admin', 'org:member']);
export const jwtUserSchema = z.object({
    id: z.string(),
    isSuperuser: z.boolean().default(false).optional(),
    organization: z
        .object({
        id: z.string(),
        name: z.string(),
        permissions: z.array(z.enum([
            'org:sys_domains:manage',
            'org:sys_domains:read',
            'org:sys_memberships:manage',
            'org:sys_memberships:read',
            'org:sys_profile:delete',
            'org:sys_profile:manage',
        ])),
        role: userRoleSchema,
        slug: z.string(),
    })
        .optional(),
});
export const userSchema = z.object({
    firstName: z.string().nullable().optional().default(null),
    id: z.string(),
    imageUrl: z.string().nullable().optional().default(null),
    lastName: z.string().nullable().optional().default(null),
});
export const organizationUserSchema = userSchema.extend({
    permissions: z.array(z.string()),
    role: z.string(),
});
export const organizationSchema = z.object({
    id: z.string(),
    permissions: z.array(z.string()),
    role: z.string(),
    slug: z.string(),
});
