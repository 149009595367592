export const zodErrors = {
    company_or_name_required: 'COMPANY_OR_NAME_REQUIRED',
};
export var ZodCustomErrorCode;
(function (ZodCustomErrorCode) {
    ZodCustomErrorCode["CompanyOrNameRequired"] = "company_or_name_required";
    ZodCustomErrorCode["Required"] = "required";
})(ZodCustomErrorCode || (ZodCustomErrorCode = {}));
export const zodErrorTranslations = {
    de: {
        custom: {
            company_or_name_required: 'Firmenname oder Vorname und Nachname sind erforderlich.',
            required: 'Erforderlich',
        },
    },
    en: {
        custom: {
            company_or_name_required: 'Company name or first name and last name are required.',
            required: 'Required',
        },
    },
};
