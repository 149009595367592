import { DhlParcelDeShippingPostParcelGermanyV2Sdk } from '@packages/lib/api-sdk/dhl_parcel_de_shipping_post_parcel_germany_v2';
import { ZodCustomErrorCode } from '@packages/lib/schema/zod-error';
import { z, ZodIssueCode } from 'zod';

const productEnum = DhlParcelDeShippingPostParcelGermanyV2Sdk.spec.schema.productEnum.Enum;

export const setupFormSchema = DhlParcelDeShippingPostParcelGermanyV2Sdk.spec.schema.db
	.extend({
		isIndividualLabelFormatsEnabled: z.boolean(),
	})
	.superRefine((val, ctx) => {
		const isAtLeastOneBillingNumberSet = [
			val.config.products[productEnum.Retoure],
			val.config.products[productEnum.PaketInternational],
			val.config.products[productEnum.Europaket],
			val.config.products[productEnum.Warenpost],
			val.config.products[productEnum.WarenpostInternational],
		].some((product) => product.billingNumber !== '');

		if (!isAtLeastOneBillingNumberSet) {
			ctx.addIssue({
				code: ZodIssueCode.custom,
				params: { i18n: ZodCustomErrorCode.Required },
				path: ['billingNumber'],
			});
		}
	});

export type FormSchema = z.infer<typeof setupFormSchema>;
