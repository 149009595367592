import { Box, Button, Group } from '@mantine/core';
import { and, eq, like } from 'drizzle-orm';

import { TableStoreProvider } from '@/components/table';
import { dbs } from '@/drizzle/pglite';
import { useFrachterApi } from '@/frachter/hooks/use-frachter';

import { Route } from './~index';
import { BundleListTable } from './bundle-list-table/BundleListTable';

export function Page() {
	const params = Route.useParams();

	// const {
	// 	data: frozenOrderIds,
	// 	hasUpdate: hasFrozenOrderIdsUpdate,
	// 	newData: frozenOrderIdsUpdated,
	// 	update: updateFrozenOrderIds,
	// } = useFrozenOrderIds({
	// 	marketId: params.marketId,
	// 	merchantId: params.merchantId,
	// 	status: 'PENDING',
	// });

	const { database } = useFrachterApi();

	const addTestOrders = async () => {
		// take a single order from the database

		const order = await database.drizzleClient.query.marketOrdersLocal.findFirst({
			where: and(
				eq(dbs.marketOrdersLocal.marketId, 'otto'),
				eq(dbs.marketOrdersLocal.merchantId, '1116820'),
				eq(dbs.marketOrdersLocal.status, 'UNSHIPPED'),
			),
		});

		if (order) {
			const numNewOrders = 2;

			const newOrders = Array.from({ length: numNewOrders }, (_, i) => ({
				...order,
				createdAt: new Date(),
				eanList: ['TEST-EAN-1', 'TEST-EAN-2'],
				orderId: order.orderId + `-TEST-${i}-${Date.now()}`,
				skuList: ['TEST-SKU-1', 'TEST-SKU-2'],
			}));

			await database.drizzleClient.insert(dbs.marketOrdersLocal).values(newOrders);
		}
	};

	const removeTestOrders = async () => {
		await database.drizzleClient.delete(dbs.marketOrdersLocal).where(like(dbs.marketOrdersLocal.orderId, '%-TEST-%'));
	};

	const showDebug = true;

	return (
		<Box style={{ display: 'flex', flexDirection: 'column', gap: '1rem', height: '100%' }}>
			{showDebug && (
				<Box bg="rgb(255 0 0 / 0.2)" p="md">
					<Group>
						<Button onClick={() => void addTestOrders()} size="compact-xs">
							Add 2 Test Orders
						</Button>
						<Button onClick={() => void removeTestOrders()} size="compact-xs">
							Remove Test Orders
						</Button>
					</Group>
				</Box>
			)}
			<TableStoreProvider initialState={{ isFetching: false }}>
				<BundleListTable marketId={params.marketId} merchantId={params.merchantId} />
			</TableStoreProvider>
		</Box>
	);
}
