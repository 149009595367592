import React, { useSyncExternalStore } from 'react';
import isEqual from 'react-fast-compare';

import { useFrachterApi } from '@/frachter/hooks/use-frachter';

import { ApiService } from '../effect/organization-services/ApiService';

type RemoveSyncStatus<T> = {
	[K in keyof T]: Omit<T[K], 'syncStatus'>;
};

// Example usage
type OriginalType = ReturnType<ApiService['$$markets']['getSnapshot']>;

type ModifiedType = RemoveSyncStatus<OriginalType>;

export function useMarkets(includeSyncStatus: true): OriginalType;
export function useMarkets(includeSyncStatus?: false): ModifiedType;
export function useMarkets(includeSyncStatus?: boolean) {
	const { $$markets } = useFrachterApi();

	const selectorRef = React.useRef<ModifiedType>({});

	const markets = useSyncExternalStore($$markets.subscribe, () => {
		if (includeSyncStatus) {
			return $$markets.getSnapshot();
		} else {
			const snapshot = $$markets.getSnapshot();

			const mappedSnapshot = Object.fromEntries(
				Object.entries(snapshot).map(([key, value]) => {
					const { syncStatus, ...rest } = value;

					return [key, rest];
				}),
			);

			if (isEqual(selectorRef.current, mappedSnapshot)) {
				return selectorRef.current;
			}

			selectorRef.current = mappedSnapshot;

			return selectorRef.current;
		}
	});

	return markets;
}
