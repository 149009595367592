import { ApolloLink, HttpLink, split } from '@apollo/client';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { withScalars } from 'apollo-link-scalars';
import { Effect, Stream } from 'effect';
import { buildClientSchema, Kind, OperationTypeNode } from 'graphql';
import { DateTimeResolver } from 'graphql-scalars';
import { Client, createClient } from 'graphql-ws';

import { env } from '@/data/env';

import possibleTypes from '../../../../../possible-types.json' with { type: 'json' };
import schema from '../../../../../schema.json';
import { getAuthToken } from '../../programs/get-auth-token';
import { AuthService } from '../AuthService';

// let counter = 0;

export class GraphqlClientService extends Effect.Service<GraphqlClientService>()('GraphqlClientService', {
	scoped: Effect.gen(function* () {
		yield* Effect.logDebug('GraphqlClientService: START');
		// console.log('gql service', ++counter);

		const authService = yield* AuthService;
		let currentOrganizationId = (yield* authService.$session.ref)?.organization?.id;

		const getToken = (orgId?: string) => {
			return Effect.runPromise(getAuthToken('backend', { organizationId: orgId }));
		};

		// @ts-expect-error should be ok
		const clientSchema = buildClientSchema(schema);

		const typesMap = {
			DateTime: DateTimeResolver,
		};

		let currentWsClient: Client | null = null;

		function createLink(organizationId?: string) {
			const p = Promise.withResolvers<ApolloLink | null>();
			let link: ApolloLink | null = null;

			const httpLink = new HttpLink({
				fetch: async (input, init) => {
					const token = await getToken(organizationId);

					return fetch(input, {
						...init,
						headers: {
							...init?.headers,
							Authorization: 'Bearer ' + token,
						},
					});
				},
				// (this does not work if you are rendering your page with `export const dynamic = "force-static"`)
				fetchOptions: { cache: 'no-store', credentials: 'include' },
				// you can disable result caching here if you want to
				// this needs to be an absolute url, as relative urls cannot be used in SSR
				uri: env.PUBLIC_BACKEND_URL + '/graphql',

				// you can override the default `fetchOptions` on a per query basis
				// via the `context` property on the options passed as a second argument
				// to an Apollo Client data fetching hook, e.g.:
				// const { data } = useSuspenseQuery(MY_QUERY, { context: { fetchOptions: { cache: "force-cache" }}});
			});

			const wsUrl = new URL(env.PUBLIC_BACKEND_URL + '/graphql');
			wsUrl.protocol = 'wss';

			const createWsClient = () => {
				if (currentWsClient) {
					void currentWsClient.dispose();
				}

				const wsClient = createClient({
					/**
					 * this will issue a ping against the server every 3 seconds
					 * TODO: implement a client side connection health check like describe in https://github.com/enisdenjo/graphql-ws/issues/117#issuecomment-856664604
					 */
					keepAlive: 3_000,
					// generateID: () => crypto.randomUUID(),
					// HINT: we must NOT use lazy, because this will establish a connection ONLY when a subscription was set up
					// and it will only CLOSE the connection, when there is no subscription anymore
					lazy: false,
					on: {
						connected: (_socket) => {
							p.resolve(link);
						},
						error: (error) => {
							console.log('🔴 error', error);
						},
					},

					retryAttempts: Number.POSITIVE_INFINITY,

					url: async () => {
						const token = await getToken(organizationId);
						wsUrl.searchParams.set('token', token ?? '');
						return wsUrl.toString();
					},
				});

				currentWsClient = wsClient;

				return wsClient;
			};

			// HINT: we only want to setup the ws link if we are in an organization
			// in case we're about to CREATE a new organization, we won't have an organization id yet
			const wsLink = organizationId ? new GraphQLWsLink(createWsClient()) : () => null;

			const splitLink = split(
				({ query }) => {
					const definition = getMainDefinition(query);
					return (
						definition.kind === Kind.OPERATION_DEFINITION && definition.operation === OperationTypeNode.SUBSCRIPTION
					);
				},
				// HINT: This is kinda hacky but it seems to work
				// if we don't do this, then initial token will be null for the first time and we will run 3 ws requests to establish a connection
				wsLink,
				httpLink,
			);

			link = ApolloLink.from([withScalars({ schema: clientSchema, typesMap }), splitLink]);

			return p.promise;
		}

		// use the `ApolloClient` from "@apollo/experimental-nextjs-app-support"
		const client = new ApolloClient({
			// use the `InMemoryCache` from "@apollo/experimental-nextjs-app-support"
			cache: new InMemoryCache({
				possibleTypes,
			}),
			name: 'frachter',
		});

		yield* authService.$session.stream.pipe(
			Stream.map((session) => ({
				organizationId: session?.organization?.id,
				userId: session?.user?.id,
			})),
			Stream.changes,
			Stream.runForEach((next) =>
				Effect.gen(function* () {
					if (next.organizationId !== currentOrganizationId) {
						// reset client
						yield* Effect.promise(() =>
							client.clearStore().catch((err) => console.error('could not reset store', err)),
						);
					}

					const link = yield* Effect.promise(() => createLink(next.organizationId));

					if (!link) {
						throw new Error('could not create link');
					}

					client.setLink(link);

					currentOrganizationId = next.organizationId;

					return Effect.void;
				}),
			),
			Effect.interruptible,
			Effect.forkDaemon,
		);

		return {
			client,
		};
	}),
}) {}
