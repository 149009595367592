import { Box, Group, Stack, Stepper, Title } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconArrowLeft as IconBack, IconArrowRight as IconNext } from '@tabler/icons-react';
import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { motion, AnimatePresence } from 'motion/react';
import React from 'react';
import { match } from 'ts-pattern';
import { z } from 'zod';

import { MonoButton } from '@/components/ui/MonoButton';
import { useCloudprint } from '@/frachter/hooks/use-cloudprint';

import SettingsCarriersPage from '../~settings/~carriers/page';
import SettingsCloudprintPage from '../~settings/~cloudprint/page';
import SettingsMarketsPage from '../~settings/~markets/page';
import SettingsOrganizationPage from '../~settings/~organization/page';

import s from './index.module.scss';

const STEPS = 5;

export type ValidateRef = React.Ref<null | (() => Promise<boolean>)>;

const setupSearchParamsSchema = z.object({
	step: z.number().min(1).max(STEPS).optional().default(1),
});

export const Route = createFileRoute('/org/$slug/setup/')({
	component: Page,
	validateSearch: setupSearchParamsSchema,
});

const MStack = motion.create(Stack);

function Page() {
	const [isPending, transition] = React.useTransition();

	const cloudprintDevices = useCloudprint();

	const search = Route.useSearch();
	const params = Route.useParams();

	const navigate = useNavigate();

	const step1CallbackRef: ValidateRef = React.useRef(null);
	const step2CallbackRef: ValidateRef = React.useRef(null);
	const step3CallbackRef: ValidateRef = React.useRef(null);

	const next = () => {
		transition(async () => {
			let isValid: boolean = false;

			if (search.step === 1 && step1CallbackRef.current) {
				isValid = await step1CallbackRef.current().then((result) => {
					if (!result) {
						notifications.show({
							color: 'danger',
							id: 'setup-step-1',
							message: 'Bitte überprüfe Deine Eingaben.',
							position: 'bottom-center',
							title: 'Validierung nicht erfolgreich',
						});
					}
					return result;
				});
			} else if (search.step === 2 && step2CallbackRef.current) {
				isValid = await step2CallbackRef.current().then((result) => {
					if (!result) {
						notifications.show({
							color: 'danger',
							id: 'setup-step-2',
							message: 'Bitte richte Cloudprint ein und gib mindestens einen Drucker frei.',
							position: 'bottom-center',
							title: 'Keine Cloudprint-Drucker gefunden',
						});
					}
					return result;
				});
			} else if (search.step === 3 && step3CallbackRef.current) {
				isValid = await step3CallbackRef.current().then((result) => {
					if (!result) {
						notifications.show({
							color: 'danger',
							id: 'setup-step-3',
							message: 'Bitte richte mindestens einen Marktplatz ein.',
						});
					}
					return result;
				});
			}

			if (isValid) {
				// HINT: tanstack router seems to have changed... we must now pass the to property
				navigate({
					params: { slug: params.slug },
					resetScroll: true,
					search: { step: search.step + 1 },
					to: Route.to,
				}).catch((err) => console.error(err));
			}
		});
	};

	const previous = () => {
		// HINT: tanstack router seems to have changed... we must now pass the to property
		navigate({
			params: { slug: params.slug },
			resetScroll: true,
			search: { step: search.step - 1 },
			to: Route.to,
		}).catch((err) => console.error(err));
	};

	const component = match(search.step)
		.with(1, () => (
			<SetupItem key="org">
				<SettingsOrganizationPage hideSaveButton validateRef={step1CallbackRef} />
			</SetupItem>
		))
		.with(2, () => (
			<SetupItem key="cloudprint">
				<SettingsCloudprintPage validateRef={step2CallbackRef} />
			</SetupItem>
		))
		.with(3, () => (
			<SetupItem key="markets">
				<SettingsMarketsPage validateRef={step3CallbackRef} />
			</SetupItem>
		))
		.with(4, () => (
			<SetupItem key="carriers">
				<SettingsCarriersPage />
			</SetupItem>
		))
		.with(5, () => (
			<SetupItem key="success">
				<SuccessPage />
			</SetupItem>
		))
		.otherwise(() => {
			throw new Error(`Unknown step ${search.step}`);
		});

	return (
		<Box>
			<Box style={{ float: 'left', marginRight: '3rem', position: 'sticky', top: 'var(--org-appbar-height)' }}>
				<Stepper
					active={search.step - 1}
					allowNextStepsSelect={false}
					className={s.stepper}
					iconSize={24}
					mt="lg"
					orientation="vertical"
					size="md"
				>
					{['Stammdaten', 'CloudPrint', 'Marktplätze', 'Versanddienstleister', 'Du kannst starten!'].map(
						(label, index, arr) => (
							<Stepper.Step
								key={label}
								description={label}
								label={index === arr.length - 1 ? 'Fertig' : `Schritt ${index + 1}`}
							/>
						),
					)}
				</Stepper>
			</Box>

			<Stack justify="space-between" mih="calc(100dvh - var(--org-appbar-height))">
				<AnimatePresence mode="wait" presenceAffectsLayout>
					{component}
				</AnimatePresence>

				<Group justify="space-between" pb="md">
					<MonoButton disabled={search.step <= 1} leftSection={<IconBack />} onClick={previous} variant="subtle">
						Zurück
					</MonoButton>
					<MonoButton
						disabled={search.step === 1 ? false : cloudprintDevices.byClient.length === 0 || search.step >= STEPS}
						loading={isPending}
						onClick={() => void next()}
						rightSection={<IconNext />}
						size="lg"
					>
						Weiter
					</MonoButton>
					{/* <Button color="intense">Hello</Button> */}
				</Group>
			</Stack>
		</Box>
	);
}

const SuccessPage = () => {
	return (
		<Stack>
			<Title order={1}>Einrichtung erfolgreich</Title>
		</Stack>
	);
};

const SetupItem: React.FC<React.PropsWithChildren> = ({ children }) => {
	return (
		<MStack animate={{ opacity: 1 }} exit={{ opacity: 0 }} initial={{ opacity: 0 }} pb="xl">
			{children}
		</MStack>
	);
};
