import { createFileRoute } from '@tanstack/react-router';

import { AppPageLayout } from '@/layouts/app-page';

import Page from './page';

export const Route = createFileRoute('/org/$slug/settings/shipping-rules')({
	component: () => (
		<AppPageLayout variant="settings">
			<Page />
		</AppPageLayout>
	),
});
