import { useQuery, useApolloClient } from '@apollo/client';
import { PrinterWithClientIdAndHostnameSchema } from '@packages/lib/schema/cloudprint';
import { Effect, pipe, Schedule } from 'effect';
import React from 'react';
import { isPresent } from 'ts-extras';

import { graphql } from '@/gql/graphql';
import { useFrachterUser } from '@/hooks/use-frachter-user';

import { useFrachterApi } from './use-frachter';

export const CloudprintLinksQuery = graphql(`
	query CloudprintLinks($input: QueryCloudprintLinksInput!) {
		cloudprintLinks(input: $input) {
			... on QueryCloudprintLinksSuccess {
				data {
					clientId
					createdAt
					id
				}
			}
		}
	}
`);

const CloudprintLinkDeleteMutation = graphql(`
	mutation CloudprintConnectionDelete($input: MutationCloudprintConnectionDeleteInput!) {
		cloudprintConnectionDelete(input: $input) {
			... on MutationCloudprintConnectionDeleteSuccess {
				data {
					success
				}
			}
		}
	}
`);

export type CloudprintByClient<TIsOnline extends boolean = boolean> = {
	clientId: string;
	printers: PrinterWithClientIdAndHostnameSchema[];
	linkedAt: Date;
	isOnline: TIsOnline;
	isDisconnecting?: boolean;
	/**
	 * hostname is only set if the client is online
	 */
	hostname?: string;
};

export function useCloudprint() {
	const api = useFrachterApi();

	const {
		user: { organization },
	} = useFrachterUser({ organization: true });

	const [clientIdsToDisconnect, setClientIdsToDisconnect] = React.useState<string[]>([]);

	const query = useQuery(CloudprintLinksQuery, {
		fetchPolicy: 'cache-first',
		variables: { input: { slug: organization.slug } },
	});

	const apolloClient = useApolloClient();

	const { data, refetch } = query;

	const members = React.useSyncExternalStore(
		api.$$realtimeMembers.subscribe,
		() => api.$$realtimeMembers.getSnapshot().cloudprint,
	);

	/**
	 * WARN: for cloudprint clients, it might happen that a client reloads.
	 * In that case, we'd have this client multiple times in the list.
	 * Therefore we use a map to ensure we only have one entry per client.
	 */
	const uniqueMembers = React.useMemo(
		() => Array.from(new Map(members.map((v) => [v.clientId, v])).values()),
		[members],
	);

	const flatPrinterList: PrinterWithClientIdAndHostnameSchema[] = React.useMemo(
		() =>
			uniqueMembers
				?.map((v) =>
					Object.values(v.data.printers).map((printer) => ({
						...printer,
						clientId: v.clientId,
						hostname: v.data.meta.hostname,
					})),
				)
				.flat(),
		[uniqueMembers],
	);

	const byClient: CloudprintByClient[] = React.useMemo(() => {
		const printersByHostname = Map.groupBy<string, PrinterWithClientIdAndHostnameSchema>(
			flatPrinterList,
			(v) => v.hostname,
		);

		const links = data?.cloudprintLinks?.__typename === 'QueryCloudprintLinksSuccess' ? data.cloudprintLinks.data : [];

		return links
			.map((link) => {
				const cloudprintPresence = uniqueMembers.find((v) => v.clientId.split(':')[1] === link.clientId);

				const data: CloudprintByClient = {
					clientId: link.clientId,
					isDisconnecting: clientIdsToDisconnect.includes(link.clientId),
					isOnline: false,
					linkedAt: link.createdAt,
					printers: [],
				};

				if (cloudprintPresence) {
					// we have a link but no presence, this means that the client is not connected
					const hostname = cloudprintPresence.data.meta.hostname;

					data.hostname = hostname;
					data.isOnline = true;
					data.printers = printersByHostname.get(hostname) ?? [];
				}
				return data;
			})
			.filter(isPresent)
			.sort((a, b) => (a.hostname ?? '').localeCompare(b.hostname ?? ''));
	}, [clientIdsToDisconnect, uniqueMembers, flatPrinterList, data]);

	const disconnect = React.useCallback(
		async (clientId: string) => {
			const prefixedClientId = `cloudprint:${clientId}`;

			const onlineClient = uniqueMembers.find((v) => v.clientId === prefixedClientId);

			setClientIdsToDisconnect((s) => {
				if (!s.includes(clientId)) {
					return [...s, clientId];
				} else {
					return s;
				}
			});

			if (onlineClient) {
				await api.cloudprint.disconnectCloudprintClient(prefixedClientId);

				/**
				 * This should
				 * 1. wait 2 seconds
				 * 2. check if the client is still present
				 *   - if yes, repeat but wait another 2 seconds
				 *   - if no, return
				 *
				 * then we can check if the client was removed (by the cloudprint client)
				 * and if not, we can delete the client through graphql
				 */
				const action = Effect.tryPromise({
					catch: (err) => {
						console.error(err);
						return null;
					},
					try: () =>
						apolloClient
							.query({
								query: CloudprintLinksQuery,
								variables: { input: { clientId, slug: organization.slug } },
							})
							.then((result) => {
								if (
									result.data?.cloudprintLinks.__typename === 'QueryCloudprintLinksSuccess' &&
									result.data.cloudprintLinks.data.length > 0
								) {
									throw new Error('Client still linked');
								}
								return result;
							}),
				});

				const program = Effect.gen(function* () {
					// yield* Effect.sleep('1000 millis');

					// test in our effect playground project
					return yield* pipe(
						Effect.retry(action, {
							schedule: Schedule.exponential('2 seconds', 2),
							times: 5,
						}),
					);
				});

				const result = await Effect.runPromise(program).catch(() => null);

				if (
					result === null ||
					(result?.data?.cloudprintLinks.__typename === 'QueryCloudprintLinksSuccess' &&
						result.data.cloudprintLinks.data.length > 0)
				) {
					await apolloClient
						.mutate({
							mutation: CloudprintLinkDeleteMutation,
							variables: { input: { clientId, slug: organization.slug } },
						})
						.catch((err) => console.error(err));
				}
			} else {
				/* in case the client is offline, we just remove the entry from the database and hope the client will re-auth soon */
				await apolloClient.mutate({
					mutation: CloudprintLinkDeleteMutation,
					variables: { input: { clientId, slug: organization.slug } },
				});
			}

			await refetch().catch((err) => console.error(err));
			setClientIdsToDisconnect((s) => s.filter((v) => v !== clientId));
		},
		[apolloClient, api.cloudprint, uniqueMembers, organization, refetch],
	);

	return {
		byClient,
		disconnect,
		list: flatPrinterList,
	};

	// const cloudprintPresencesByClientId = React.useMemo(() => {
	// 	return new Map(realtimePresences.cloudprint.map((v) => [v.clientId.split(':')[1], v]));
	// }, [realtimePresences]);
}
