import { z, ZodIssueCode } from 'zod';
import { genderSchema } from './address';
import { countryCodeIso31661Alpha2Schema } from './country-codes-alpha2';
import { nonEmptyStringSchema } from './lib/string';
import { ZodCustomErrorCode } from './zod-error';
export var OrganizationAddressTypeEnum;
(function (OrganizationAddressTypeEnum) {
    OrganizationAddressTypeEnum["company"] = "company";
    OrganizationAddressTypeEnum["shipping"] = "shipping";
    OrganizationAddressTypeEnum["billing"] = "billing";
    OrganizationAddressTypeEnum["return"] = "return";
})(OrganizationAddressTypeEnum || (OrganizationAddressTypeEnum = {}));
export const simpleOrganizationSchema = z.object({
    id: z.string().min(1),
    name: z.string().min(1).optional(),
    slug: z.string().min(1),
});
export const organizationCreateSchema = z.object({
    name: z
        .string()
        .min(3, { message: 'Mindestens 3 Zeichen erforderlich' })
        .max(40, { message: 'Maximal 40 Zeichen erlaubt' })
        .regex(/^[a-zA-Z][a-zA-Z0-9_.\-\s()[\]]*[a-zA-Z0-9_-\s()[\]]$/, {
        message: 'Überprüfe Deine Eingabe und halte Dich an die Vorgaben',
    }),
    slug: z
        .string()
        .min(3)
        .max(40)
        .regex(/^[a-zA-Z](?!.*--)[a-zA-Z0-9-]*[a-zA-Z0-9]$/, {
        message: 'Überprüfe Deine Eingabe und halte Dich an die Vorgaben',
    }),
});
const organizationAdditionalAddressSchema = z.object({
    city: nonEmptyStringSchema,
    countryCode: countryCodeIso31661Alpha2Schema.nullable().default('DE'),
    email: z.string().email().trim().nullable().default(null),
    house: nonEmptyStringSchema,
    phone: z.string().trim().optional().nullable().default(null),
    street: nonEmptyStringSchema,
    zip: nonEmptyStringSchema,
});
const _organizationAddressSchema = z
    .object({
    company: z.string().trim().optional().nullable().default(null).describe('Company name'),
    firstName: z.string().trim().optional().nullable().default(null),
    /**
     * following will be used as name2
     */
    gender: genderSchema.nullable().default('unknown'),
    lastName: z.string().trim().optional().nullable().default(null),
})
    .merge(organizationAdditionalAddressSchema)
    .superRefine((val, ctx) => {
    if (!val.company && !val.firstName && !val.lastName) {
        ctx.addIssue({
            code: ZodIssueCode.custom,
            params: { i18n: ZodCustomErrorCode.CompanyOrNameRequired },
            path: ['company'],
        });
    }
    else if (!val.company && !val.firstName && val.lastName) {
        ctx.addIssue({
            code: ZodIssueCode.custom,
            params: { i18n: ZodCustomErrorCode.Required },
            path: ['firstName'],
        });
    }
    else if (!val.company && val.firstName && !val.lastName) {
        ctx.addIssue({
            code: ZodIssueCode.custom,
            params: { i18n: ZodCustomErrorCode.Required },
            path: ['lastName'],
        });
    }
});
export const organizationAddressSchema = _organizationAddressSchema.and(organizationAdditionalAddressSchema);
