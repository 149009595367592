import React from 'react';

import { FrachterContext } from '../../context/FrachterContext';

export const useFrachter = () => {
	const frachter = React.useContext(FrachterContext);

	if (!frachter) {
		throw new Error('Frachter context not found');
	}

	return frachter;
};

export const useFrachterApi = () => {
	const frachter = useFrachter();

	if (!frachter?.getOrganizationAPI()) {
		throw new Error('Frachter context not found');
	}

	return frachter.getOrganizationAPI()!;
};
