import { apis } from '../../../../api/spec';
import { dhlAddressSchema, dhlWeightSchema } from './common';
import { dbSchema, labelFormatEnumSchema, labelFormatWarenpostEnumSchema, productEnumSchema, productSchema, valueAddedServicesSchema, } from './schema';
export const spec = {
    api: apis.dhl_parcel_de_shipping_post_parcel_germany_v2,
    labels: {
        [labelFormatEnumSchema.Enum.Format_910_300_300]: {
            id: '910-300-300',
            name: 'Laserdruck (Bogen A5) 105x148mm (910-300-300)',
            restrictedTo: [],
        },
        [labelFormatEnumSchema.Enum.Format_910_300_300_oz]: {
            id: '910-300-300-oz',
            name: 'Laserdruck (Bogen A5) 105x148mm (910-300-300) ohne Zusatzetiketten',
            restrictedTo: [],
        },
        [labelFormatEnumSchema.Enum.Format_910_300_400]: {
            id: '910-300-400',
            name: 'Thermodruck (Faltband) 103x150mm (910-300-400)',
            restrictedTo: [],
        },
        [labelFormatEnumSchema.Enum.Format_910_300_410]: {
            id: '910-300-410',
            name: 'Thermodruck (Rolle) 103x150mm (910-300-410)',
            restrictedTo: [],
        },
        [labelFormatEnumSchema.Enum.Format_910_300_600]: {
            id: '910-300-600',
            name: 'Thermodruck (Faltband) 103x199mm (910-300-600)',
            restrictedTo: [],
        },
        [labelFormatEnumSchema.Enum.Format_910_300_610]: {
            id: '910-300-610',
            name: 'Thermodruck (Rolle) 103x199mm (910-300-610)',
            restrictedTo: [],
        },
        [labelFormatEnumSchema.Enum.Format_910_300_700]: {
            id: '910-300-700',
            name: 'Laserdruck (Bogen A5) 105x208mm (910-300-700)',
            restrictedTo: [],
        },
        [labelFormatEnumSchema.Enum.Format_910_300_700_oz]: {
            id: '910-300-700-oz',
            name: 'Laserdruck (Bogen A5) 105x208mm (910-300-700) ohne Zusatzetiketten',
            restrictedTo: [],
        },
        [labelFormatEnumSchema.Enum.Format_910_300_710]: {
            id: '910-300-710',
            name: 'Laserdruck 105x209mm (910-300-710)',
            restrictedTo: [],
        },
        [labelFormatEnumSchema.Enum.Format_A4]: {
            id: 'A4',
            name: 'Laserdruck A4 Normalpapier',
            restrictedTo: [],
        },
        [labelFormatWarenpostEnumSchema.Enum.Format_100_70mm]: {
            id: '100x70mm',
            name: 'Warenpost 100x70mm',
            restrictedTo: [productEnumSchema.Enum.Warenpost, productEnumSchema.Enum.WarenpostInternational],
        },
    },
    products: {
        [productEnumSchema.Enum.Europaket]: {
            constraints: {
                height: {
                    max: 60,
                    min: 3.5,
                },
                length: {
                    max: 120,
                    min: 15,
                },
                weight: {
                    max: 31.5,
                    min: 0.01,
                },
                width: {
                    max: 60,
                    min: 11,
                },
            },
            hint: 'Die Abrechnungsnummer für DHL Europaket ist 14-stellig und endet i. d. R. auf 5401 oder 5402.',
            id: 'V54EPAK',
            isReturn: false,
            name: 'Europaket',
            placeholder: 'Abrechnungsnummer (xxxxxxxxxx5401)',
            purpose: 'Business-to-Business-Versand. Nutzung für Versand an Endkunden nicht gestattet; überwiegend in EU-Länder unter Nutzung des DHL-eigenen Netzes',
            region: 'DE',
            valueAddedServices: {
                additionalInsurance: true,
            },
            vasHint: 'Always has VAS',
        },
        [productEnumSchema.Enum.Paket]: {
            constraints: {
                height: {
                    max: 60,
                    min: 1,
                },
                length: {
                    max: 120,
                    maxBulky: 200,
                    min: 15,
                },
                weight: {
                    max: 31.5,
                    min: 0.01,
                },
                width: {
                    max: 60,
                    min: 11,
                },
            },
            hint: 'Die Abrechnungsnummer für DHL Paket ist 14-stellig und endet i. d. R. auf 0101, 0102 oder 0103.',
            id: 'V01PAK',
            isReturn: false,
            name: 'Paket',
            placeholder: 'Abrechnungsnummer (xxxxxxxxxx0101)',
            purpose: 'Paket im nationalen Bereich',
            region: 'GLOBAL',
            valueAddedServices: {
                additionalInsurance: true,
                bulkyGoods: true,
                cashOnDelivery: true,
                /**
                 * this can only be selected if a billing number for product Retoure is available
                 */
                dhlRetoure: true,
                identCheck: true,
                namedPersonOnly: true,
                noNeighbourDelivery: true,
                parcelOutletRouting: true,
                preferredDay: true,
                preferredLocation: true,
                preferredNeighbour: true,
                signedForByRecipient: true,
                visualCheckOfAge: true,
            },
            vasHint: 'Has VAS if it does not end with 0101 unless we run a manual check.',
        },
        [productEnumSchema.Enum.PaketInternational]: {
            constraints: {
                height: {
                    max: 60,
                    min: 1,
                },
                length: {
                    max: 120,
                    maxBulky: 200,
                    min: 15,
                },
                weight: {
                    max: 31.5,
                    min: 0.01,
                },
                width: {
                    max: 60,
                    min: 11,
                },
            },
            hint: 'Die Abrechnungsnummer für DHL Paket International ist 14-stellig und endet i. d. R. auf 5301 oder 5302.',
            id: 'V53WPAK',
            isReturn: false,
            name: 'Paket International',
            placeholder: 'Abrechnungsnummer (xxxxxxxxxx5301)',
            purpose: 'Business-to-Consumer-Versand weltweit',
            region: 'EU',
            valueAddedServices: {
                additionalInsurance: true,
                bulkyGoods: true,
                cashOnDelivery: true,
                closestDropPoint: true,
                endorsement: true,
                postalDeliveryDutyPaid: true,
                premium: true,
            },
            vasHint: 'Always has VAS',
        },
        [productEnumSchema.Enum.Retoure]: {
            hint: 'Die Abrechnungsnummer für DHL Retoure für DHL Paket und Warenpost ist 14-stellig und endet i. d. R. auf 0701 oder 0702.',
            id: 'V07PAK',
            isReturn: true,
            name: 'Retoure für Paket und Warenpost',
            placeholder: 'Abrechnungsnummer (xxxxxxxxxx0701)',
            purpose: 'DHL Retoure für DHL Paket und Warenpost',
            vasHint: 'Never has VAS',
        },
        [productEnumSchema.Enum.Warenpost]: {
            constraints: {
                height: {
                    max: 5,
                    min: 0.1,
                },
                length: {
                    max: 35,
                    min: 10,
                },
                weight: {
                    max: 1,
                    min: 0.01,
                },
                width: {
                    max: 25,
                    min: 7,
                },
            },
            hint: 'Die Abrechnungsnummer für DHL Warenpost ist 14-stellig und endet i. d. R. auf 6201 oder 6202.',
            id: 'V62WP',
            isReturn: false,
            name: 'Warenpost',
            placeholder: 'Abrechnungsnummer (xxxxxxxxxx6201)',
            purpose: 'Nationaler Versand für kleine Sendungen im Briefnetz der deutschen Post',
            region: 'DE',
            valueAddedServices: {
                /**
                 * this can only be selected if a billing number for product Retoure is available
                 */
                dhlRetoure: true,
                parcelOutletRouting: true,
                preferredLocation: true,
                preferredNeighbour: true,
            },
            vasHint: 'Always has VAS',
        },
        [productEnumSchema.Enum.WarenpostInternational]: {
            constraints: {
                height: {
                    max: 10,
                    min: 0.1,
                },
                length: {
                    max: 35.3,
                    min: 14,
                },
                weight: {
                    max: 1,
                    min: 0.01,
                },
                width: {
                    max: 25,
                    min: 9,
                },
            },
            hint: 'Die Abrechnungsnummer für Warenpost International ist 14-stellig und endet i. d. R. auf 6601, 6602, 6603 oder 6604.',
            id: 'V66WPI',
            isReturn: false,
            name: 'Warenpost International',
            placeholder: 'Abrechnungsnummer (xxxxxxxxxx6601)',
            purpose: 'Internationaler Versand für kleine Sendungen im Briefnetz der Postgesellschaften',
            region: 'GLOBAL',
            valueAddedServices: {
                premium: true,
            },
            vasHint: 'Has VAS if it does not end with 6603.',
        },
    },
    schema: {
        address: dhlAddressSchema,
        db: dbSchema,
        labelFormatEnum: labelFormatEnumSchema,
        labelFormatWarenpostEnum: labelFormatWarenpostEnumSchema,
        product: productSchema,
        productEnum: productEnumSchema,
        valueAddedServices: valueAddedServicesSchema,
        weight: dhlWeightSchema,
    },
    valueAddedServices: {
        additionalInsurance: {
            description: 'Zusätzliche Versicherung. Wenn verwendet, wird eine zusätzliche Versicherung aktiviert.',
        },
        bulkyGoods: {
            description: 'Sperrgut - wenn verwendet, gilt die Sendung als Sperrgut.',
        },
        cashOnDelivery: {
            description: 'Nachnahme - wenn verwendet, wird Nachnahme aktiviert.',
        },
        closestDropPoint: {
            description: 'Zustellung zum nächstgelegenen Abgabepunkt basierend auf der Adresse des Empfängers. Für diese Art der Zustellung ist entweder die Telefonnummer und/oder die E-Mail-Adresse des Empfängers erforderlich. Bei Sendungen mit DHL Paket International wird empfohlen, eine der drei Zustellungsarten zu wählen: Economy, Premium, CDP. Andernfalls wird der Standard für das Empfängerland verwendet.',
        },
        dhlRetoure: {
            description: 'DHL Retoure. Wenn verwendet, wird die DHL Retoure aktiviert.',
        },
        endorsement: {
            description: 'Anweisungen und Empfehlungen, wie mit international unzustellbaren Sendungen zu verfahren ist. Standardmäßig werden unzustellbare Sendungen zurückgesendet. Es gibt länderspezifische Regelungen, ob die Sendung sofort oder nach einer Frist zurückgesendet wird.',
        },
        identCheck: {
            description: 'Identitätsprüfung. Wenn verwendet, wird die Identitätsprüfung des Empfängers aktiviert.',
        },
        individualSenderRequirement: {
            description: 'Besondere Anweisungen für die Lieferung. 2-stelliger Code, mögliche Werte im Vertrag festgelegt.',
        },
        namedPersonOnly: {
            description: 'Die Zustellung kann nur persönlich durch den Empfänger unterschrieben werden.',
        },
        noNeighbourDelivery: {
            description: 'Die Zustellung kann nur persönlich durch den Empfänger oder Mitglieder seines Haushalts unterschrieben werden.',
        },
        parcelOutletRouting: {
            description: 'Unzustellbare nationale Sendungen können weitergeleitet und im Einzelhandel hinterlegt werden. Benachrichtigungen werden an die E-Mail-Adresse (Fallback: E-Mail des Empfängers) gesendet.',
        },
        postalDeliveryDutyPaid: {
            description: 'Alle Einfuhrzölle werden vom Absender bezahlt.',
        },
        preferredDay: {
            description: 'Bevorzugter Liefertag im Format JJJJ-MM-TT. Der Absender kann einen bevorzugten Liefertag angeben. Der bevorzugte Tag sollte zwischen 2 und 6 Werktagen nach der Übergabe an DHL liegen.',
        },
        preferredLocation: {
            description: 'Bevorzugter Ablageort. Kann als Text angegeben werden.',
        },
        preferredNeighbour: {
            description: 'Bevorzugter Nachbar. Kann als Text angegeben werden.',
        },
        premium: {
            description: 'Wahl zwischen Premium- und Economy-Paket. Die Verfügbarkeit ist länderabhängig und kann von DHL angepasst werden, wenn die Auswahl nicht verfügbar ist. Bitte überprüfen Sie das Etikett.',
        },
        signedForByRecipient: {
            description: 'Die Zustellung muss vom Empfänger und nicht von DHL-Mitarbeitern unterschrieben werden.',
        },
        visualCheckOfAge: {
            description: 'Wenn verwendet, wird eine Altersüberprüfung des Empfängers durchgeführt.',
        },
    },
};
