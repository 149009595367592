import type { IRowType } from '../routes/~playground/~tanstack-table/products-table/types';

export const currencies = ['EUR', 'USD', 'GBP'];

export const weightUnits = ['g', 'kg'];

export const makeProductsData: (count?: number) => IRowType[] = (count = 100) =>
	Array.from({ length: count }, (_, index) => ({
		currency: 'EUR' as (typeof currencies)[number],
		description: `Product ${index + 1} description`,
		ean: `1234567890${index + 1}`,
		id: `product-${index + 1}`,
		imageUrl: `https://via.placeholder.com/150?text=Product+${index + 1}`,
		price: 100 + index * 10 + 0.01 * index,
		sku: `SKU${index + 1}`,
		// pick one from 'active', 'inactive', 'archived'
		status: (['active', 'inactive', 'archived'] as const)[index % 3],
		title: `Product ${index + 1}`,
		weight: 100 + index * 10,
		weightUnit: 'g' as (typeof weightUnits)[number],
	}));
