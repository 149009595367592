import { Box, Paper, Title } from '@mantine/core';

export default function SettingsProductsPage() {
	return (
		<>
			<Box pt="md" style={{ display: 'grid', gridTemplateRows: 'auto 1fr' }}>
				<Title mb="sm" order={4}>
					Produktliste
				</Title>
				<Paper p="md" shadow="sm">
					liste hier
				</Paper>
			</Box>
		</>
	);
}
