import { carrierProviderEnumSchema } from '../schema/carriers';
// HINT: whenever we update this, we must also create a migration for the cloud db
// with id and display_name
export const apis = {
    dhl_parcel_de_returns_post_parcel_germany_v1: {
        baseUrl: {
            production: 'https://api-eu.dhl.com/parcel/de/shipping/returns/v1',
            sandbox: 'https://api-sandbox.dhl.com/parcel/de/shipping/returns/v1',
        },
        displayName: 'DHL Paket DE Retoure (Post & Paket Deutschland)',
        documentation: [
            { category: 'api', url: 'https://developer.dhl.com/api-reference/dhl-parcel-de-returns-post-parcel-germany' },
        ],
        id: 'dhl2',
        provider: carrierProviderEnumSchema.Values.DHL,
        type: 'carrier',
        version: '1.0.6',
    },
    dhl_parcel_de_shipping_post_parcel_germany_v2: {
        baseUrl: {
            production: 'https://api.dhl.com/parcel/de/shipping/v2',
            sandbox: 'https://api-sandbox.dhl.com/parcel/de/shipping/v2',
        },
        displayName: 'DHL Post & Paket Deutschland',
        documentation: [
            {
                category: 'api',
                url: 'https://developer.dhl.com/api-reference/parcel-de-shipping-post-parcel-germany-v2?lang=de',
            },
        ],
        id: 'dhl1',
        provider: carrierProviderEnumSchema.Values.DHL,
        type: 'carrier',
        version: '2.1.10',
    },
    frachter: {
        baseUrl: {
            production: 'https://www.frachter.app',
        },
        displayName: 'frachter',
        documentation: [],
        id: 'fra',
        type: 'market',
        version: '1.0.0',
    },
    gls_shipit: {
        baseUrl: {
            production: 'https://api.gls-group.net',
            sandbox: 'https://api-sandbox.gls-group.net',
        },
        displayName: 'GLS ShipIt',
        documentation: [
            { category: 'api', url: 'https://dev-portal.gls-group.net/docs/shipit-farm/1/overview' },
            {
                category: 'additional',
                url: 'https://shipit.gls-group.eu/webservices/3_5_15/doxygen/WS-REST-API/rest_shipment_processing.html',
            },
        ],
        id: 'gls1',
        type: 'carrier',
        version: 'v1',
    },
    gute_marken: {
        baseUrl: {
            production: 'https://gute-marken-integration-364572703832.europe-west3.run.app/orders-inbox',
            sandbox: 'https://gute-marken-integration-490702813986.europe-west3.run.app/orders-inbox',
        },
        displayName: 'Gute Marken',
        documentation: [],
        id: 'guma',
        type: 'market',
        version: '1.0.0',
    },
    otto_market: {
        baseUrl: {
            production: 'https://api.otto.market',
            sandbox: 'https://sandbox.api.otto.market',
        },
        displayName: 'Otto',
        documentation: [{ category: 'api', url: 'https://api.otto.market/docs' }],
        id: 'otto',
        type: 'market',
        version: '2024-08-08',
    },
};
/**
 * Returns the API specification for a given ID.
 */
export function getApiById(id) {
    return Object.values(apis).find((api) => api.id === id);
}
// Define specific ID arrays
export const marketIds = Object.values(apis)
    .filter((api) => api.type === 'market')
    .map((api) => api.id);
export const carrierIds = Object.values(apis)
    .filter((api) => api.type === 'carrier')
    .map((api) => api.id);
