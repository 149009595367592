import { Box } from '@mantine/core';
import { connectStatusPageSearchParamsSchema } from '@packages/lib/schema/pages';
import { createFileRoute } from '@tanstack/react-router';
import { useWindowSize } from '@uidotdev/usehooks';
import React from 'react';
import Confetti from 'react-confetti';
import { z } from 'zod';

import { SetupMarketDialog } from '@/components/dialogs/setup-market-dialog';
import { Centered } from '@/components/ui/Centered';

export const Route = createFileRoute('/connect/status')({
	component: Page,
	validateSearch: connectStatusPageSearchParamsSchema,
});

function Page() {
	const search = Route.useSearch();

	if (!search.marketId) {
		return <div>No market id given.</div>;
	}

	if (search.error) {
		return (
			<Centered>
				<SetupMarketDialog errorReason={search.error} marketId={search.marketId} status="error" />
			</Centered>
		);
	} else {
		return <Success marketId={search.marketId} />;
	}
}

const Success: React.FC<{ marketId: z.infer<typeof connectStatusPageSearchParamsSchema.shape.marketId> }> = ({
	marketId,
}) => {
	const { height = 0, width = 0 } = useWindowSize();

	const [confettiCount, setConfettiCount] = React.useState(200);

	React.useEffect(() => {
		if (confettiCount === 0) return;
		setTimeout(() => {
			setConfettiCount(Math.floor(confettiCount / 1.8));
		}, 3000);
	}, [confettiCount]);

	return (
		<Centered>
			<SetupMarketDialog marketId={marketId} status="success" />
			<Box style={{ overflow: 'visible', position: 'absolute', zIndex: -1 }}>
				<Confetti
					height={height ?? 0}
					initialVelocityX={10.4}
					initialVelocityY={12}
					numberOfPieces={confettiCount}
					tweenDuration={5000}
					width={width ?? 0}
					confettiSource={{
						h: 10,
						w: 10,
						x: (width ?? 0) / 2,
						y: (height ?? 0) / 2,
					}}
				/>
			</Box>
		</Centered>
	);
};
