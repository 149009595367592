import { Chunk, Duration, Effect, Option, Stream, SubscriptionRef, SynchronizedRef } from 'effect';
import { makeLatch } from 'effect/Effect';
const INITIAL_INACTIVITY_THRESHOLD_SECONDS = 60 * 10;
export class UserActivityService extends Effect.Service()('UserActivityService', {
    dependencies: [],
    scoped: Effect.gen(function* () {
        const abortController = new AbortController();
        const thresholdSecondsRef = yield* SynchronizedRef.make(INITIAL_INACTIVITY_THRESHOLD_SECONDS);
        const latch = yield* makeLatch(true);
        const activityRef = yield* SubscriptionRef.make(true);
        const activityChangeStream = activityRef.changes.pipe(Stream.changes);
        if (typeof window !== 'undefined') {
            const tickingStream = Stream.tick(Duration.seconds(1));
            const interactionStream = Stream.async((emit) => {
                ['mousemove', 'keydown', 'mousedown', 'touchstart'].forEach((event) => {
                    document.addEventListener(event, () => void emit(Effect.succeed(Chunk.of(Date.now()))).catch((err) => console.error(err)), {
                        signal: abortController.signal,
                    });
                });
                // emit an initial value to ensure the stream can run with the first tick
                emit(Effect.succeed(Chunk.of(Date.now()))).catch((err) => console.error(err));
            });
            const tabActiveStream = Stream.async((emit) => {
                document.addEventListener('visibilitychange', () => void emit(Effect.succeed(Chunk.of(document.visibilityState === 'visible' ? Option.some(Date.now()) : Option.none()))).catch((err) => console.error(err)), {
                    signal: abortController.signal,
                });
                // emit an initial value to ensure the stream can run with the first tick
                emit(Effect.succeed(Chunk.of(Option.some(Date.now())))).catch((err) => console.error(err));
            });
            const zipped = Stream.zipLatestAll(interactionStream, tabActiveStream, tickingStream);
            yield* Effect.yieldNow();
            yield* Stream.runForEach(zipped, ([lastInteractionTimestamp, lastActiveTimestampOption]) => Effect.gen(function* () {
                const lastActiveTimestamp = Option.getOrElse(lastActiveTimestampOption, () => Date.now());
                const secondsSinceLastInteraction = (Date.now() - lastInteractionTimestamp) / 1000;
                const secondsSinceLastActive = (Date.now() - lastActiveTimestamp) / 1000;
                const thresholdSeconds = yield* thresholdSecondsRef;
                if (Option.isNone(lastActiveTimestampOption)) {
                    // tab is not visible
                    if (secondsSinceLastInteraction < thresholdSeconds) {
                        yield* latch.open;
                        yield* SubscriptionRef.set(activityRef, true);
                    }
                    else {
                        yield* latch.close;
                        yield* SubscriptionRef.set(activityRef, false);
                    }
                }
                else {
                    // tab is visible
                    if (secondsSinceLastActive < thresholdSeconds || secondsSinceLastInteraction < thresholdSeconds) {
                        yield* latch.open;
                        yield* SubscriptionRef.set(activityRef, true);
                    }
                    else {
                        yield* latch.close;
                        yield* SubscriptionRef.set(activityRef, false);
                    }
                }
            })).pipe(Effect.interruptible, Effect.forkScoped);
        }
        yield* Effect.addFinalizer(() => Effect.gen(function* () {
            abortController.abort();
        }));
        return {
            activityChangeStream,
            activityRef,
            latch,
        };
    }),
}) {
}
