import { Box } from '@mantine/core';
import { useParams } from '@tanstack/react-router';
import React from 'react';

import { TableStoreProvider } from '@/components/table';

import { BundleDetailsTable } from './bundle-details-table/BundleDetailsTable';

export function BundleDetailsPage() {
	// const params = Route.useParams();

	const params = useParams({ strict: false });

	console.log(params);

	return (
		<Box style={{ display: 'flex', flexDirection: 'column', gap: '1rem', height: '100%' }}>
			<TableStoreProvider initialState={{ isFetching: false }}>
				<BundleDetailsTable
					bundleKey={params.key}
					bundleKeyReference={'sku'}
					marketId={params.marketId}
					merchantId={params.merchantId}
				/>
			</TableStoreProvider>
		</Box>
	);
}
