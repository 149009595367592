import fetchRetry from 'fetch-retry';
/**
 * create a fetch function that can be used to proxy requests by modifying headers
 * and that retries requests multiple times in case of errors or server errors
 *
 * @param options - the options to create the fetch function
 * @returns a fetch function
 */
export function createFetch(options = {}) {
    const MAX_DELAY = options.maxDelay ?? 4000;
    return fetchRetry(async (input, init) => {
        if (!options.proxy) {
            return fetch(input, init);
        }
        /**
         * in case the input is a request, reuse its
         * properties for the final request
         *
         * since we cannot just clone or spread the request,
         * we must manually set all properties
         */
        if (input instanceof Request) {
            init = {
                ...init,
                body: init?.body ?? input.body,
                cache: init?.cache ?? input.cache,
                credentials: init?.credentials ?? input.credentials,
                headers: init?.headers ?? input.headers,
                integrity: init?.integrity ?? input.integrity,
                keepalive: init?.keepalive ?? input.keepalive,
                method: init?.method ?? input.method,
                mode: init?.mode ?? input.mode,
                redirect: init?.redirect ?? input.redirect,
                referrer: init?.referrer ?? input.referrer,
                referrerPolicy: init?.referrerPolicy ?? input.referrerPolicy,
                signal: init?.signal ?? input.signal,
            };
            input = input.url;
        }
        /* take the original url */
        const inputUrl = typeof input === 'string' ? new URL(input) : input;
        /* remove the search params from the original url */
        // TODO: we must support search params !
        inputUrl.search = '';
        const proxy = await options.proxy();
        const headers = new Headers(init?.headers);
        headers.set('x-proxy-url', inputUrl.toString());
        /* rewrite the authorization header */
        const originalAuthorization = headers.get('authorization');
        if (originalAuthorization) {
            headers.set('x-proxy-authorization', originalAuthorization);
        }
        /* use our proxy authorization */
        headers.set('authorization', proxy.authorization);
        /* initialize the proxy url based on the original url */
        const finalUrl = new URL(inputUrl.toString());
        const proxyUrl = new URL(proxy.baseUrl);
        finalUrl.hostname = proxyUrl.hostname;
        finalUrl.pathname = `${proxyUrl.pathname}${inputUrl.pathname}`;
        return fetch(finalUrl, {
            ...init,
            // @ts-expect-error fix that later (due to `exactOptionalPropertyTypes` in tsconfig)
            duplex: 'half',
            headers,
        });
    }, {
        retryDelay(attempt) {
            const delay = Math.min(MAX_DELAY, Math.pow(2, attempt) * 500);
            return delay;
        },
        /**
         * we retry at most 9 times so that the total number of requests is 10
         */
        retryOn: (attempt, error, response) => (error && !(error instanceof DOMException)) || (response && response.status >= 500) ? attempt < 9 : false,
    });
}
/**
 * Default fetch function, which will retry the request if it fails.
 */
const defaultFetch = fetchRetry((input, init) => fetch(input, {
    ...init,
    // @ts-expect-error fix that later (due to `exactOptionalPropertyTypes` in tsconfig)
    duplex: 'half',
}), {
    /**
     * Set the retry delay exponentially with a max of 3 seconds
     * and an exponential factor of 250ms to the power of the attempt.
     * TODO: check if attempt is 0 or 1 for the first retry.
     */
    retryDelay: (attempt) => Math.min(3000, Math.pow(2, attempt) * 250),
    /**
     * We retry at most 9 times so that the total number of requests is 10
     * In case of a DOMException, we assume the request was intentionally aborted
     * through a signal.
     */
    retryOn: (attempt, error, response) => (error && !(error instanceof DOMException)) || (response && response.status >= 500) ? attempt < 9 : false,
});
/**
 * Hint: baseUrl will be provided through the createClient({}) call
 */
/**
 * Fetch function for direct API operations. Should be used when
 * our backend is not interested in the response and when either the
 * requesting client does not require CORS or if the requested backend
 * supports CORS.
 *
 * @param init
 * @returns
 */
export function createOpenApiClientFetch(initOverrides) {
    return (clientRequest) => {
        if (!(clientRequest instanceof Request))
            throw new Error('clientInput must be a Request');
        return defaultFetch(clientRequest, {
            ...initOverrides,
            headers: { ...initOverrides?.headers },
        });
    };
}
function mapRequest(request, options) {
    /* since we only provide the url and not a request, we must take care of everything else manually */
    const _request = new Request(options?.url ?? request.url, {
        body: options?.init?.body ?? request.body,
        cache: options?.init?.cache ?? request.cache,
        credentials: options?.init?.credentials ?? request.credentials,
        // @ts-expect-error fix that later (due to `exactOptionalPropertyTypes` in tsconfig)
        duplex: 'half',
        /* headers must be merged manually */
        headers: request.headers,
        integrity: options?.init?.integrity ?? request.integrity,
        keepalive: options?.init?.keepalive ?? request.keepalive,
        method: options?.init?.method ?? request.method,
        mode: options?.init?.mode ?? request.mode,
        redirect: options?.init?.redirect ?? request.redirect,
        referrer: options?.init?.referrer ?? request.referrer,
        referrerPolicy: options?.init?.referrerPolicy ?? request.referrerPolicy,
        signal: options?.init?.signal ?? request.signal,
    });
    const initHeaders = new Headers(options?.init?.headers ?? {});
    initHeaders.forEach((value, key) => {
        _request.headers.set(key, value);
    });
    return _request;
}
/**
 * Fetch function to send requests through a proxy.
 * Therefore, the original url, including query params, and the original headers must be preserved
 * Headers can be multiple headers in theory, not only the Authorization header.
 *
 * @param proxyBaseUrl
 * @param init
 * @returns
 */
export function createOpenApiClientProxyFetch(
/**
 * proxy options must be the baseUrl, and and optional auth function.
 * The client's baseUrl and auth function will be sued for the original request
 */
proxy, initOverrides) {
    return async (clientRequest) => {
        if (!(clientRequest instanceof Request))
            throw new Error('clientInput must be a Request');
        // @ts-expect-error fix that later (due to `exactOptionalPropertyTypes` in tsconfig)
        const mappedRequest = mapRequest(clientRequest, { init: initOverrides, url: proxy.baseUrl });
        mappedRequest.headers.set('X-Proxy-Url', clientRequest.url);
        if (clientRequest.headers.has('Authorization')) {
            mappedRequest.headers.set('X-Proxy-Authorization', clientRequest.headers.get('Authorization'));
        }
        if (proxy.auth) {
            mappedRequest.headers.set('Authorization', await proxy.auth());
        }
        if (initOverrides?.headers) {
            for (const [key, value] of Object.entries(initOverrides.headers)) {
                mappedRequest.headers.set(key, value);
            }
        }
        const finalRequest = new Request(mappedRequest, initOverrides);
        return defaultFetch(finalRequest);
    };
}
/**
 * Fetch function to pass proxied requests through to the original api.
 * Therefore, the "mappings' from "createOpenApiClientProxyFetch" must be undone.
 *
 * If auth is to be set through this call, it should be set in the init object.
 *
 * @param request
 * @param init
 * @returns
 */
function createOpenApiClientPassthroughFetch(request, options) {
    return async (_clientRequest) => {
        if (!request.headers.has('X-Proxy-Url')) {
            throw new Error('X-Proxy-Url header is missing');
        }
        // @ts-expect-error fix that later (due to `exactOptionalPropertyTypes` in tsconfig)
        const mappedRequest = mapRequest(request, {
            init: options?.initOverrides,
            url: request.headers.get('X-Proxy-Url'),
        });
        mappedRequest.headers.delete('X-Proxy-Url');
        const initHeaders = new Headers(options?.initOverrides?.headers ?? {});
        /* only set the auth header from x-proxy-authorization in case we do not have an override  */
        if (!initHeaders.has('Authorization') && request.headers.has('X-Proxy-Authorization')) {
            mappedRequest.headers.set('Authorization', request.headers.get('X-Proxy-Authorization'));
        }
        mappedRequest.headers.delete('X-Proxy-Authorization');
        const response = await defaultFetch(mappedRequest);
        /* clone it and pass on for early or raw response return */
        if (options?.onResponse) {
            options.onResponse(response.clone());
        }
        return response;
    };
}
export async function passthrough(fnOrRequest, requestOrInitOverrides, options) {
    if (fnOrRequest instanceof Request) {
        const request = fnOrRequest;
        const initOverrides = requestOrInitOverrides;
        return createOpenApiClientPassthroughFetch(request, { initOverrides })(request);
    }
    else {
        const fn = fnOrRequest;
        const request = requestOrInitOverrides;
        // Perform any operations here if needed
        let _response = null;
        // Call the provided function and pick up the result
        const result = await fn({
            ...options?.clientOptions,
            baseUrl: 'https://a.b',
            // @ts-expect-error fix that later (due to `exactOptionalPropertyTypes` in tsconfig)
            fetch: createOpenApiClientPassthroughFetch(request, {
                initOverrides: options?.initOverrides,
                onResponse: (response) => {
                    _response = response;
                },
            }),
        });
        if (_response) {
            result.response = _response;
        }
        return result;
    }
}
// 				const result = await passthrough(sdk.ordersV4FindPartnerOrders, new Request('https://www.lalelu-msw.com/prefix1/v4/orders'));
// if (!result.data) {
// 	result.
// }
// 				const result2 = await sdk.ordersV4FindPartnerOrders();
// 				result2.
