import { Effect } from 'effect';
import { isPresent } from 'ts-extras';
import { zPartnerOrderOrdersV4Fixed } from './schema';
export const getOrderStatus = (order) => {
    const stati = new Set(order.positionItems.map((item) => item.fulfillmentStatus));
    if (stati.has('RETURNED')) {
        return 'RETURNED';
    }
    if (stati.has('ANNOUNCED') &&
        (stati.size === 1 ||
            (stati.size === 2 && stati.has('CANCELLED_BY_MARKETPLACE')) ||
            (stati.size === 2 && stati.has('CANCELLED_BY_PARTNER')) ||
            (stati.size === 3 && stati.has('CANCELLED_BY_MARKETPLACE') && stati.has('CANCELLED_BY_PARTNER')))) {
        return 'PENDING';
    }
    if (!stati.has('ANNOUNCED')) {
        if (stati.has('PROCESSABLE') && !stati.has('SENT')) {
            return 'UNSHIPPED';
        }
        else if (stati.has('PROCESSABLE') && stati.has('SENT')) {
            return 'PARTIALLY_SHIPPED';
        }
    }
    if (stati.has('SENT') && !stati.has('PROCESSABLE')) {
        return 'SHIPPED';
    }
    if (stati.has('CANCELLED_BY_MARKETPLACE') || stati.has('CANCELLED_BY_PARTNER')) {
        if (stati.size === 1 ||
            (stati.size === 2 && stati.has('CANCELLED_BY_MARKETPLACE') && stati.has('CANCELLED_BY_PARTNER'))) {
            return 'CANCELLED';
        }
    }
    throw new Error('Invalid order status');
};
export const normalizeOrder = (raw, connection) => Effect.gen(function* () {
    const parsed = zPartnerOrderOrdersV4Fixed.safeParse(raw);
    if (!parsed.success || !parsed.data) {
        Effect.dieMessage(`Failed to parse Otto order: ${parsed.error.message}`);
    }
    const order = parsed.data;
    const processableLineItems = order.positionItems.filter((item) => item.fulfillmentStatus === 'PROCESSABLE');
    return {
        createdAt: new Date(order.orderDate),
        eanList: processableLineItems
            .map((item) => item.product.ean)
            .filter(isPresent)
            .sort(),
        marketId: connection.marketId,
        merchantId: connection.merchantId,
        orderId: order.orderNumber,
        raw,
        secondaryOrderId: order.salesOrderId,
        // TODO: check if we can group for LISTS of strings or if we need a single string ! (just test in postgres browser)
        skuList: processableLineItems
            .map((item) => item.product.sku)
            .filter(isPresent)
            .sort(),
        status: getOrderStatus(order),
        updatedAt: new Date(order.lastModifiedDate ?? order.orderDate),
    };
});
