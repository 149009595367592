import {
	Box,
	Button,
	Collapse,
	Divider,
	Group,
	Loader,
	Paper,
	rem,
	Stack,
	Switch,
	Text,
	TextInput,
} from '@mantine/core';
import { useDebouncedState, useDisclosure } from '@mantine/hooks';
import { ApiEnvironmentEnumSchema } from '@packages/lib/schema/system';
import { IconChevronDown as IconExpand, IconChevronUp as IconCollapse } from '@tabler/icons-react';
import React from 'react';

import { MonoButton } from '@/components/ui/MonoButton';
import { EnvironmentIndicator } from '@/features/connections/components';

import s from './ConnectionEstablishedListItem.module.scss';

export interface ConnectionEstablishedListItemProps {
	children?: React.ReactNode;
	createdAt: Date;
	description?: string;
	// details?: { label: React.ReactNode; value: React.ReactNode }[];
	disabled?: boolean;
	displayName?: string | null;
	environment: ApiEnvironmentEnumSchema;
	id: string;
	logo: React.ReactNode;
	name: string;
	// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
	onChangeDisplayName: (displayName: string, id: string) => unknown | Promise<unknown>;
	// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
	onEdit?: (id: string) => unknown | Promise<unknown>;
}

export function ConnectionEstablishedListItem({
	// details = [],
	children,
	createdAt,
	description,
	disabled,
	displayName = '',
	environment,
	id,
	logo,
	name,
	onChangeDisplayName,
	onEdit,
}: ConnectionEstablishedListItemProps) {
	const [opened, { toggle }] = useDisclosure(false);

	const [currentDisplayName, setCurrentDisplayName] = React.useState(displayName ?? '');
	const [displayNameValue, setDisplayNameValue] = useDebouncedState(displayName ?? '', 1000);
	const [isDirty, setIsDirty] = React.useState(false);

	const [isPending, startTransition] = React.useTransition();

	React.useEffect(() => {
		startTransition(async () => {
			const trimmedValue = displayNameValue.trim();
			if (!isDirty) return;

			if (currentDisplayName === trimmedValue) return;

			await onChangeDisplayName(trimmedValue, id);
			setCurrentDisplayName(trimmedValue);
		});
	}, [currentDisplayName, displayName, displayNameValue, id, isDirty, onChangeDisplayName]);

	return (
		<Paper p="md" radius="md" shadow="xs">
			<Stack>
				<Box>
					<Box style={{ display: 'grid', gap: '1.0rem', gridTemplateColumns: 'auto 1fr' }}>
						<Box
							className={s.logoContainer}
							style={{
								border: '1px solid color-mix(in srgb, var(--mantine-color-text) 8%, transparent)',
								width: 86,
							}}
						>
							{logo}
						</Box>
						<Stack gap="xs">
							<Box
								style={{
									alignItems: 'center',
									display: 'grid',
									// gap: '1.5rem',
									gridTemplateColumns: '1fr auto auto ',
								}}
							>
								<Stack gap={0}>
									<Text fw="bold" size="lg">
										{name}
									</Text>
									<Text size="sm">{description}</Text>
								</Stack>

								{onEdit && (
									<MonoButton onClick={() => onEdit?.(id)} size="xs" variant="outline">
										Bearbeiten
									</MonoButton>
								)}
							</Box>
							<Box
								style={{
									alignItems: 'center',
									display: 'grid',
									gap: '1rem',
									gridTemplateColumns: '1fr auto  auto',
								}}
							>
								<Group>
									<TextInput
										defaultValue={displayName ?? ''}
										placeholder="Name"
										radius="md"
										size="xs"
										styles={{ input: { fontSize: rem(12) } }}
										w={180}
										onChange={(v) => {
											setIsDirty(true);
											setDisplayNameValue(v.currentTarget.value);
										}}
									/>
									<Loader size="xs" style={{ visibility: isPending ? 'visible' : 'hidden' }} />
								</Group>

								<Button
									aria-label="Details"
									c="var(--mantine-color-text)"
									onClick={toggle}
									p={0}
									style={{ display: 'none' }}
									variant="transparent"
									rightSection={
										opened ? (
											<IconCollapse stroke={1.5} style={{ height: '90%', width: '90%' }} />
										) : (
											<IconExpand stroke={1.5} style={{ height: '90%', width: '90%' }} />
										)
									}
								>
									<Text size="sm">Details</Text>
								</Button>

								<EnvironmentIndicator environment={environment} />

								<Box style={{ justifySelf: 'end' }}>
									<Switch checked={!disabled} disabled label="Aktiv" />
								</Box>
							</Box>

							<Collapse in={!opened}>
								<Stack gap={0} px={4}>
									<Divider />

									<ConnectionEstablishedListItem.Detail label="verbunden seit" value={createdAt.toLocaleDateString()} />

									{children}
								</Stack>
							</Collapse>
						</Stack>
					</Box>
				</Box>
			</Stack>
		</Paper>
	);
}

ConnectionEstablishedListItem.Detail = function ConnectionEstablishedListItemDetail({
	label,
	value,
}: {
	label: string;
	value: React.ReactNode;
}) {
	return (
		<Stack className={s.detail} gap={0}>
			<Group justify="space-between" mih={30}>
				<Text c="dimmed" fw={600} size="sm">
					{label}
				</Text>
				<Text
					ff="monospace"
					size="sm"
					style={{
						display: 'grid',
						gap: '0.5rem',
						gridAutoFlow: 'column',
						justifyContent: 'end',
					}}
				>
					{value}
				</Text>
			</Group>
			<Divider className={s.detailDivider} opacity={0.5} />
		</Stack>
	);
};
