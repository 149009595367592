import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/org/$slug/')({
	beforeLoad: ({ params }) => {
		throw redirect({
			params,
			to: '/org/$slug/quickship',
		});
	},
});
