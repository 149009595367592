import { useOrganization, useOrganizationList, useSession } from '@clerk/clerk-react';
import { Button, Divider, Stack, Text, Title, Loader } from '@mantine/core';
import { createFileRoute } from '@tanstack/react-router';
import React from 'react';

import { MonoButton } from '@/components/ui/MonoButton';

export const Route = createFileRoute('/playground/clerk/')({
	component: Page,
});

function Page() {
	// HINT: memberships here does not seem to update
	const { organization } = useOrganization({ memberships: { infinite: true } });

	const { setActive, userMemberships } = useOrganizationList({ userMemberships: { infinite: true } });

	// React.useEffect(() => {
	// 	void memberships?.revalidate?.();
	// 	//
	// }, [memberships]);

	const { session } = useSession();

	const [nextOrgSlug, setNextOrgSlug] = React.useState<string | null>(null);

	// console.log({ memberships, organization, userMemberships });

	console.log('activeOrg slug', organization?.slug);

	const switchOrg = async (slug: string | null) => {
		if (!slug) return;
		console.log('before switch');
		setNextOrgSlug(slug);
		await setActive?.({ beforeEmit: () => console.log('before emit'), organization: slug });
		// if we do this, it is almost certain that the new org was already loaded
		await session?.reload();
		console.log('after switch');
	};

	React.useEffect(() => {
		if (nextOrgSlug && organization?.slug === nextOrgSlug) {
			setNextOrgSlug(null);
		}
	}, [nextOrgSlug, organization?.slug]);

	return (
		<Stack p="xl">
			<Title>clerk test</Title>
			<Text>
				active org <pre>{organization?.slug}</pre>
			</Text>

			<Divider />

			<Button>deactivate current org</Button>

			{nextOrgSlug && <Loader />}

			<Divider />

			<Button onClick={() => void session?.getToken({ skipCache: true })}>get token with skipped cache</Button>

			{/* HINT: this works */}
			<Button onClick={() => void userMemberships?.revalidate?.()}>revalidate userMemberships</Button>

			<Stack p="lg">
				{userMemberships?.data?.map((membership) => {
					return (
						<MonoButton
							key={membership.id}
							disabled={membership.organization.id === organization?.id}
							onClick={() => void switchOrg(membership?.organization?.slug)}
						>
							{membership?.organization.slug}
						</MonoButton>
					);
				})}
			</Stack>
		</Stack>
	);
}
