import { Box, Checkbox, Stack, Text } from '@mantine/core';
import { createColumnHelper } from '@tanstack/react-table';
import React from 'react';

import type { IColumnFilter } from '@/components/table/types';

import type { IRowType } from './types';

import { createTextInputCell } from '../../../../components/table/renderer';

export const columnHelper = createColumnHelper<IRowType>();

const MIN_COLUMN_WIDTH = 80;

export const useCreateColumns = () => {
	const [columns] = React.useState(() => {
		return [
			columnHelper.accessor('sku', {
				cell: createTextInputCell({ type: 'text' }),
				enableSorting: true,
				footer: '-',
				header: () => <Text fw={600}>SKU</Text>,

				minSize: MIN_COLUMN_WIDTH,
			}),
			columnHelper.accessor('ean', {
				cell: createTextInputCell({ type: 'text' }),
				enableSorting: true,
				footer: '-',
				header: () => (
					<Text fw={600} size="sm">
						EAN
					</Text>
				),
				minSize: MIN_COLUMN_WIDTH,
				size: 600,
			}),
			columnHelper.accessor('title', {
				cell: createTextInputCell({ type: 'text' }),
				enableSorting: true,
				footer: '-',
				header: () => (
					<Text fw={600} size="sm">
						Name
					</Text>
				),
				minSize: MIN_COLUMN_WIDTH,
			}),
			columnHelper.accessor('weight', {
				cell: createTextInputCell({ type: 'number' }),
				footer: () => (
					<Text px="xs" ta="right">
						-
					</Text>
				),
				header: () => (
					<Text fw={600} size="sm">
						Gewicht
					</Text>
				),
				meta: {
					justify: 'end',
				},
				minSize: MIN_COLUMN_WIDTH,
			}),
			columnHelper.accessor('status', {
				cell: (props) => {
					const value = props.getValue();

					let text = '';

					switch (value) {
						case 'active':
							text = 'Aktiv';
							break;
						case 'inactive':
							text = 'Inaktiv';
							break;
						case 'archived':
							text = 'Archiviert';
							break;
					}
					return (
						<Box style={{ alignSelf: 'center' }}>
							<Text size="sm">{text}</Text>
						</Box>
					);
				},
				enableColumnFilter: true,
				enableSorting: true,
				footer: () => '-',
				header: (props) => (
					<Box>
						<Text fw={600}>Status</Text>
					</Box>
				),

				meta: {
					filter: function ({ selected, setSelected }) {
						const current = selected ?? {};

						const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
							const name = event.target.name as keyof typeof current;
							const value = event.target.checked;

							if (value) {
								const newValue = { ...current, [name]: true };
								setSelected(newValue);
							} else {
								const newSelected = { ...current };
								delete newSelected[name];
								setSelected(newSelected);
							}
						};

						return (
							<Stack justify="stretch">
								<Checkbox
									defaultChecked={current['active'] === true}
									label="Aktiv"
									labelPosition="left"
									name="active"
									onChange={handleChange}
									size="xs"
									styles={{ labelWrapper: { flex: 1 } }}
								/>
								<Checkbox
									defaultChecked={current['inactive'] === true}
									label="Inaktiv"
									labelPosition="left"
									name="inactive"
									onChange={handleChange}
									size="xs"
									styles={{ labelWrapper: { flex: 1 } }}
								/>
								<Checkbox
									defaultChecked={current['archived'] === true}
									label="Archiviert"
									labelPosition="left"
									name="archived"
									onChange={handleChange}
									size="xs"
									styles={{ labelWrapper: { flex: 1 } }}
								/>
							</Stack>
						);
						// TODO: reuse a type that we can also use for our query !
					} satisfies IColumnFilter<{ active?: boolean; inactive?: boolean; archived?: boolean }>,
				},

				minSize: MIN_COLUMN_WIDTH,
			}),
			// columnHelper.accessor('status', {
			// 	cell: (props) => {
			// 		const value = props.getValue();

			// 		let text = '';

			// 		switch (value) {
			// 			case 'active':
			// 				text = 'Aktiv';
			// 				break;
			// 			case 'inactive':
			// 				text = 'Inaktiv';
			// 				break;
			// 			case 'archived':
			// 				text = 'Archiviert';
			// 				break;
			// 		}
			// 		return (
			// 			<Box style={{ alignSelf: 'center' }}>
			// 				<Text size="sm">{text}</Text>
			// 			</Box>
			// 		);
			// 	},
			// 	footer: () => (
			// 		<Text px="xs" ta="right">
			// 			-
			// 		</Text>
			// 	),
			// 	header: () => (
			// 		<Text fw={600} size="sm">
			// 			Status
			// 		</Text>
			// 	),
			// 	minSize: MIN_COLUMN_WIDTH,
			// }),
		];
	});

	return columns;
};
