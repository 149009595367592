import { Box, Stack, Text, Title } from '@mantine/core';

export function Page() {
	return (
		<Box>
			<Stack>
				<Title>Warehouse</Title>
				<Text>hello</Text>
			</Stack>
		</Box>
	);
}
