import { notifications } from '@mantine/notifications';
import { Effect, Schedule } from 'effect';

import { constants } from '@/data/constants';

import { AuthService } from '../../global-services/AuthService';
import { createBackendClient } from '../../lib/create-backend-client';
import { OrganizationContextService } from '../OrganizationContextService';

export class BackendService extends Effect.Service<BackendService>()('BackendService', {
	scoped: Effect.gen(function* () {
		yield* Effect.logDebug('BackendService: START');
		// const authService = yield* AuthService;
		const { getBackendToken } = yield* AuthService;

		const organizationContextService = yield* OrganizationContextService;

		const client = createBackendClient(() =>
			Effect.runPromise(
				getBackendToken.pipe(Effect.provideService(OrganizationContextService, organizationContextService)),
			).then((token) => {
				if (token) {
					return {
						Authorization: `Bearer ${token}`,
					};
				}

				return {};
			}),
		);

		yield* Effect.logDebug('BackendService: END');

		return {
			client,
			getRealtimeClientToken: Effect.gen(function* () {
				const getToken = Effect.promise(() =>
					client.connect.cloudprint.token
						.$post()
						.then(async (res) => {
							notifications.hide(constants.NOTIFICATION_ID_REALTIME_TOKEN_FETCH_FAILED);

							if (res.ok) {
								const r = await res.json();

								return {
									organizations: r.organizations,
									token: {
										// HINT: we set defaults in case we don't get the info from the backend
										// although this might be a legacy problem that we've already fixed
										...r.payload,
										clientId: r.payload.clientId || '',
										ttl: r.payload.ttl || 0,
									},
								};
							}

							console.error('Error getting token', res);
							return null;
						})
						.catch(() => null),
				);

				const result = yield* Effect.retryOrElse(
					Effect.filterOrFail(
						getToken,
						(v) => v !== null,
						() => Effect.succeed(null),
					),
					Schedule.addDelay(Schedule.recurs(5), () => '100 millis'),
					(e) => e,
				).pipe(
					/* as of now, this will never run because we always succeed */
					Effect.catchAll((e) =>
						Effect.gen(function* () {
							yield* Effect.logError(`Error getting realtime client token`, e);
							notifications.show({
								autoClose: false,
								color: 'warning',
								id: constants.NOTIFICATION_ID_REALTIME_TOKEN_FETCH_FAILED,
								message: 'Die Echtzeit-Verbindung konnte nicht autorisiert werden. Bitte überprüfe deine Verbindung.',
								title: 'Fehler bei einer Netzwerkanfrage',
							});
							return null;
						}),
					),
				);

				return result;
			}),
		};
	}),
}) {}
