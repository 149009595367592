import { showNotification, hideNotification } from '@mantine/notifications';
import { Effect } from 'effect';

/**
 * this is being used by our `AppPreloader` to determine if we have a notification that blocks
 * the app from being interactive for the user (e.g. when a database (re-)sync is in progress)
 */
export const BLOCKING_NOTIFICATION_PREFIX = 'blocking:';

export class NotificationService extends Effect.Service<NotificationService>()('frachter/NotificationService', {
	effect: Effect.gen(function* () {
		return {
			databaseSyncProgress: (options: { table: { name: string; label: string } }) => {
				const id = `${BLOCKING_NOTIFICATION_PREFIX}-${options.table.name}`;
				return {
					hide: () => hideNotification(id),
					show: () =>
						showNotification({
							autoClose: false,
							color: 'blue',
							id,
							loading: true,
							message: `${options.table.label} werden mit der Frachter's Cloud-Datenbank abgeglichen. Bitte warten...`,
							title: `Synchronisiere ${options.table.label}`,
						}),
				};
			},
		};
	}),
}) {}
