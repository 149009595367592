import { Box } from '@mantine/core';
import { flexRender, type Row } from '@tanstack/react-table';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'motion/react';
import React from 'react';

import type { IBaseTableData, IRenderExpanded } from './types';

import { EmptyList } from '@/components/ui/EmptyList';

import { Cell } from './components/Cell';
import { COLUMN_ID_SELECT } from './contants';
import { useTableStore } from './store';
import tableStyles from './table.module.scss';

const MBox = motion.create(Box<HTMLDivElement>);

export const TableBody: React.FC<{
	rows: Row<IBaseTableData>[];
	className?: string;
	renderExpanded?: IRenderExpanded<IBaseTableData>;
	style?: React.CSSProperties;
}> = ({ className, renderExpanded, rows, style }) => {
	const enableRowSelection = useTableStore((state) => state.enableRowSelection);

	return (
		<Box className={className} style={style}>
			{rows.length === 0 ? (
				<EmptyList key="empty" message="Keine Daten gefunden" />
			) : (
				<AnimatePresence presenceAffectsLayout>
					{rows.map((row) => (
						<MBox
							key={row.id}
							animate={{ opacity: 1 }}
							className={clsx(tableStyles.bodyRow)}
							initial={{ opacity: 0 }}
							// exit={{
							// 	opacity: 0,
							// }}
						>
							<Box className={tableStyles.bodyRowColumns}>
								{row.getVisibleCells().map((cell) => {
									if (cell.column.id === COLUMN_ID_SELECT && !enableRowSelection) {
										return null;
									}

									return (
										<Cell
											key={cell.id}
											align={cell.column.columnDef.meta?.align}
											/* used for selecting columns for resizing through scoped generated style */
											data-column-id={cell.column.id}
											justify={cell.column.columnDef.meta?.justify}
										>
											{flexRender(cell.column.columnDef.cell, cell.getContext())}
										</Cell>
									);
								})}
							</Box>
							<AnimatePresence>
								{row.getIsExpanded() && (
									<motion.div
										key={row.id}
										animate={{ height: 'auto' }}
										className={clsx(tableStyles.bodyRowExpanded)}
										exit={{ height: 0 }}
										initial={{ height: 0 }}
									>
										{renderExpanded?.({ row })}
									</motion.div>
								)}
							</AnimatePresence>
						</MBox>
					))}
				</AnimatePresence>
			)}
		</Box>
	);
};

export const MemoizedTableBody = React.memo(TableBody);
