import {
	MarketServiceGuteMarken,
	MarketServiceOtto,
	WarehouseGlobalsService,
	WarehouseRegistryService,
	WarehouseService,
} from '@packages/lib/effect-services/warehouse-frontend';
import { Effect, Layer } from 'effect';

import { env } from '@/data/env';
import { graphql } from '@/gql/graphql';

import { AuthService } from '../../global-services/AuthService';
import { GraphqlClientService } from '../../global-services/GraphqlService';
import { DatabaseService } from '../DatabaseService';
import { OrganizationContextService } from '../OrganizationContextService';

const WarehouseQuery = graphql(`
	query WarehouseQuery {
		marketConnections {
			disabled
			environment
			displayName
			id
			merchantId
			marketId
			createdAt
			market {
				description
				displayName
				id
			}
		}
		carrierConnections {
			... on CarrierConnectionDhlPostParcelGermanyShippingV2 {
				environment
				displayName
				carrier {
					description
					displayName
					id
					provider
				}
				carrierId
				disabled
				id
			}
		}
	}
`);

export const WarehouseGlobalsServiceManual = Layer.effect(
	WarehouseGlobalsService,
	Effect.gen(function* () {
		const databaseService = yield* DatabaseService;
		const authService = yield* AuthService;

		const organizationContextService = yield* OrganizationContextService;

		return {
			// get in frachter-app from data/env -> `PUBLIC_BACKEND_URL`
			backendBaseUrl: env.PUBLIC_BACKEND_URL,
			// TODO: use the same drizzle interface we use in our frachter client!
			// LATER: move this into a different service
			drizzle: databaseService.drizzleClient,
			getBackendAuthorizationHeaderValue: authService.getBackendToken
				.pipe(Effect.provide(Layer.succeed(OrganizationContextService, organizationContextService)))
				.pipe(Effect.andThen((v) => `Bearer ${v}`)),
		};
	}),
);

export class WarehouseSetupService extends Effect.Service<WarehouseSetupService>()('WarehouseSetupService', {
	// dependencies: [WarehouseLayer],
	scoped: Effect.gen(function* () {
		const warehouseService = yield* WarehouseService;

		yield* Effect.logDebug('WarehouseSetupService START');

		const { client } = yield* GraphqlClientService;

		const getMarketConnections = Effect.promise(() => client.query({ query: WarehouseQuery }));

		const result = yield* getMarketConnections;

		yield* warehouseService.setMarkets(result.data.marketConnections);
		console.log(env.PUBLIC_BACKEND_URL);

		// console.log(result.data.marketConnections[0].disabled);

		// client.query()

		return {};
	}),
}) {}

const marketsLayer = Layer.provideMerge(
	WarehouseRegistryService.Default,
	Layer.mergeAll(MarketServiceGuteMarken.Default, MarketServiceOtto.Default),
);

export const WarehouseLayer = Layer.provideMerge(
	WarehouseSetupService.Default,
	Layer.mergeAll(WarehouseService.Default, WarehouseGlobalsServiceManual, marketsLayer),
);
