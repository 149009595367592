import { useMutation } from '@apollo/client';
import { DevTool } from '@hookform/devtools';
import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Divider, Group, Stack, Text, Title, Loader } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { DhlParcelDeShippingPostParcelGermanyV2Sdk } from '@packages/lib/api-sdk/dhl_parcel_de_shipping_post_parcel_germany_v2';
import { spec } from '@packages/lib/api/dhl_parcel_de_shipping_post_parcel_germany_v2';
import { ApiEnvironmentEnumSchema } from '@packages/lib/schema/system';
import clsx from 'clsx';
import { Effect } from 'effect';
import React from 'react';
import { createPortal } from 'react-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { TextInput } from 'react-hook-form-mantine';

import { MonoButton } from '@/components/ui/MonoButton';
import { env } from '@/data/env';
import { CarrierProviderConfig } from '@/data/warehouse';
import { EnvironmentIndicator } from '@/features/connections/components';
import { getAuthToken } from '@/frachter/effect/programs/get-auth-token';
import { FragmentOf, graphql, readFragment } from '@/gql/graphql';
import { useFrachterUser } from '@/hooks/use-frachter-user';
import { useModalShake } from '@/hooks/use-modal-shake';

import { setupFormSchema, FormSchema } from './setup-form-schema';
import {
	DhlFormSectionCredentials,
	DhlFormSectionGeneral,
	DhlFormSectionProductsInternational,
	DhlFormSectionProductsNational,
} from './setup-form-sections';
import s from './setup-form.module.scss';

const productEnum = DhlParcelDeShippingPostParcelGermanyV2Sdk.spec.schema.productEnum.Enum;

const wait = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const SetupFormFragment = graphql(`
	fragment CarrierConnectionDhlPostParcelGermanyShippingV2Fragment on CarrierConnectionDhlPostParcelGermanyShippingV2 {
		... on CarrierConnectionDhlPostParcelGermanyShippingV2 {
			id
			displayName
			config {
				defaultLabelFormat
				products {
					Europaket {
						billingNumber
						labelFormat
					}
					Paket {
						billingNumber
						labelFormat
						vas
					}
					PaketInternational {
						billingNumber
						labelFormat
					}
					Retoure {
						billingNumber
					}
					Warenpost {
						billingNumber
						labelFormat
					}
					WarenpostInternational {
						billingNumber
						labelFormat
					}
				}
				profile
			}
			credentials {
				password
				username
			}
		}
	}
`);

const DhlPostParcelGermanyShippingV2UpsertMutation = graphql(`
	mutation DhlPostParcelGermanyShippingV2UpsertMutation($input: MutationCarrierConnectionUpsertInput!) {
		carrierConnectionUpsert(input: $input) {
			... on GenericErrorInterface {
				message
			}
			... on MutationCarrierConnectionUpsertSuccess {
				data {
					... on CarrierConnectionDhlPostParcelGermanyShippingV2 {
						carrierId
					}
				}
			}
		}
	}
`);

export interface SetupFormProps {
	environment?: ApiEnvironmentEnumSchema | null;
	fragment?: FragmentOf<typeof SetupFormFragment> | null;
	onCancel?: () => void;
	onComplete?: () => void;
}

/**
 *
 * Hints:
 * - we can only start validating billing numbers if we have working credentials !
 * - we can only validate retoure if we have either a working PAK01 or a working WP62 billingNumber
 *
 * @returns
 */
export function SetupForm({ environment = 'production', fragment, onCancel, onComplete }: SetupFormProps) {
	const [mutateUpsert, mutationUpsert] = useMutation(DhlPostParcelGermanyShippingV2UpsertMutation);

	const { user } = useFrachterUser({ organization: true });

	const data = readFragment(SetupFormFragment, fragment);

	const form = useForm<FormSchema>({
		criteriaMode: 'all',

		defaultValues: {
			config: {
				defaultLabelFormat:
					data?.config?.defaultLabelFormat ??
					DhlParcelDeShippingPostParcelGermanyV2Sdk.spec.schema.labelFormatEnum.Enum.Format_910_300_710,

				products: {
					[productEnum.Europaket]: {
						billingNumber: data?.config?.products.Europaket.billingNumber ?? '',
						labelFormat: data?.config?.products.Europaket.labelFormat ?? null,
					},
					[productEnum.Paket]: {
						billingNumber: data?.config?.products.Paket.billingNumber ?? '',
						labelFormat: data?.config?.products.Paket.labelFormat ?? null,
						vas: data?.config?.products.Paket.vas ?? false,
					},
					[productEnum.PaketInternational]: {
						billingNumber: data?.config?.products.PaketInternational.billingNumber ?? '',
						labelFormat: data?.config?.products.PaketInternational.labelFormat ?? null,
					},
					[productEnum.Retoure]: { billingNumber: data?.config?.products.Retoure.billingNumber ?? '' },
					[productEnum.Warenpost]: {
						billingNumber: data?.config?.products.Warenpost.billingNumber ?? '',
						labelFormat: data?.config?.products.Warenpost.labelFormat ?? null,
					},
					[productEnum.WarenpostInternational]: {
						billingNumber: data?.config?.products.WarenpostInternational.billingNumber ?? '',
						labelFormat: data?.config?.products.WarenpostInternational.labelFormat ?? null,
					},
				},
				profile: data?.config?.profile ?? '',
			},
			credentials: {
				password: data?.credentials?.password ?? '',
				username: data?.credentials?.username ?? '',
			},

			displayName: data?.displayName ?? '',

			// this is just for local use
			isIndividualLabelFormatsEnabled: false,
		},
		mode: 'onBlur',
		resolver: zodResolver(setupFormSchema),
	});

	const { ref, shake } = useModalShake<HTMLFormElement>();

	const [isPending, startTransition] = React.useTransition();

	const [apiClient] = React.useState(
		DhlParcelDeShippingPostParcelGermanyV2Sdk.getClient(
			{ environment: environment ?? 'production', language: 'de-DE' },
			{
				fetch: DhlParcelDeShippingPostParcelGermanyV2Sdk.fetch.proxy({
					auth: async () => `Bearer ${await Effect.runPromise(getAuthToken('backend'))}`,
					baseUrl: `${env.PUBLIC_BACKEND_URL}/proxy/dhl`,
				}),
			},
		),
	);

	const services = DhlParcelDeShippingPostParcelGermanyV2Sdk.getServices(apiClient);

	const { control } = form;

	React.useEffect(() => {
		if (
			mutationUpsert.data &&
			mutationUpsert.data.carrierConnectionUpsert.__typename === 'MutationCarrierConnectionUpsertSuccess'
		) {
			form.reset(form.getValues());
		}
	}, [form, mutationUpsert.data]);

	const [step, setStep] = React.useState(0);
	const TOTAL_STEPS = 5;

	const displayName = form.getValues('displayName');

	const notify = React.useCallback(
		function notify({
			key,
			message,
			title,
			type,
		}: {
			message: React.ReactNode;
			title: React.ReactNode;
			key: string;
			type: 'error' | 'warning';
		}) {
			notifications.show({
				autoClose: 10_000,
				color: type === 'error' ? 'danger' : 'warning',
				id: `dhl-form-multistep-${displayName}-${key}-${type}`,

				message: <Text size="sm">{message}</Text>,
				title: (
					<Text fw={800} size="sm">
						{title}
					</Text>
				),
			});
		},
		[displayName],
	);

	const handleStepChange = React.useCallback(
		(value: 'previous' | 'next') => {
			if (value === 'previous') {
				setStep(Math.max(step - 1, 0));
				return;
			}

			startTransition(async () => {
				/** ----------------------------------------------------------------------------------------------
				 * Step 0 - Vertragsname und Zugangsdaten
				 * _______________________________________________________________________________________________ */
				if (step === 0) {
					const validationResult = await form
						.trigger('displayName', { shouldFocus: true })
						.then((v) => v && form.trigger('credentials', { shouldFocus: true }));

					if (!validationResult) return;

					// check if fields are dirty, if so, trigger validation
					if (form.getFieldState('credentials').isDirty) {
						const result = await services.verifyCredentials(form.getValues().credentials);

						if (!result.success) {
							shake();

							notify({
								key: 'credentials',
								message: (
									<>
										Die Verbindung zur DHL API über den Vertrag <strong>{form.getValues('displayName')}</strong> konnte
										nicht hergestellt werden. Bitte überprüfe deine Zugangsdaten.
									</>
								),
								title: 'Fehler bei der Verbindung: Ungültige Zugangsdaten',
								type: 'error',
							});

							return;
						} else {
							form.resetField('credentials', { defaultValue: form.getValues('credentials') });
							form.resetField('displayName', { defaultValue: form.getValues('displayName') });
						}
					}
				} else if (step === 2) {
					/** ----------------------------------------------------------------------------------------------
					 * Step 1 - Nothing to validate through the backend
					 * _______________________________________________________________________________________________ */

					/** ----------------------------------------------------------------------------------------------
					 * Step 2 -Nationaler Versand
					 * _______________________________________________________________________________________________ */
					// TODO ----
					/**
					 * billingNumbers should be optional !
					 * for Nationaler Versand it does not have to be filled !
					 * same for internationaler versand
					 *
					 * but at the end, we should issue a warning that no billing number was given and we cannot proceed!
					 *
					 * we need a refine function to allow DHL Retoure input only if DHL Paket or DHL Warenpost have been given
					 * then we need to first validate the billingNumber for Paket and Warenpost
					 * then we need to see which of them has been valid and use it for return billing number validation
					 *
					 * those two should be passed to Promise.all to run them in parallel
					 *
					 * and in case we do not have VAS for paket, we should also issue a warning notification !
					 */

					const validationResult = await form
						.trigger('config.products.Paket.billingNumber', { shouldFocus: true })
						.then((v) => v && form.trigger('config.products.Warenpost.billingNumber', { shouldFocus: true }))
						.then((v) => v && form.trigger('config.products.Retoure.billingNumber', { shouldFocus: true }));

					if (!validationResult) return;

					const paketBillingNumber = form.getValues('config.products.Paket.billingNumber');
					const warenpostBillingNumber = form.getValues('config.products.Warenpost.billingNumber');

					const [paketResult, warenpostResult] = await Promise.all([
						form.getFieldState('config.products.Paket.billingNumber').isDirty && paketBillingNumber
							? services.verifyPaketBillingNumberWithOptionalVas(paketBillingNumber)
							: Promise.resolve(undefined),

						form.getFieldState('config.products.Warenpost.billingNumber').isDirty && warenpostBillingNumber
							? services.verifyBillingNumber('Warenpost', warenpostBillingNumber)
							: Promise.resolve(undefined),
					]);

					const hasError = [paketResult, warenpostResult].some((result) => result?.error);

					if (paketResult?.error) {
						notify({
							key: 'paket',
							message: `Die angegebene Abrechnungsnummer wurde von DHL nicht akzeptiert. Bitte prüfe deine Eingabe.`,
							title: `Ungültige Abrechnungsnummer für ${DhlParcelDeShippingPostParcelGermanyV2Sdk.spec.schema.productEnum.Enum.Paket}`,
							type: 'error',
						});
					}

					if (warenpostResult?.error) {
						notify({
							key: 'warenpost',
							message: `Die angegebene Abrechnungsnummer wurde von DHL nicht akzeptiert. Bitte prüfe deine Eingabe.`,
							title: `Ungültige Abrechnungsnummer für ${DhlParcelDeShippingPostParcelGermanyV2Sdk.spec.schema.productEnum.Enum.Warenpost}`,
							type: 'error',
						});
					}

					if (paketResult?.success) {
						form.resetField('config.products.Paket', {
							defaultValue: { ...form.getValues('config.products.Paket'), vas: paketResult.vas },
						});

						if (!paketResult.vas) {
							notify({
								key: 'paket',
								message: `Die angegebene Abrechnungsnummer ist nicht für Value Added Services (VAS) wie z. B. Alterssichtprüfung freigeschaltet.`,
								title: `Keine Value Added Services für ${DhlParcelDeShippingPostParcelGermanyV2Sdk.spec.schema.productEnum.Enum.Paket} verfügbar`,
								type: 'warning',
							});

							// let the user wait 2 seconds to recognize the warning
							await wait(2_000);
						}
					}

					if (warenpostResult?.success) {
						form.resetField('config.products.Warenpost', {
							defaultValue: { ...form.getValues('config.products.Warenpost'), billingNumber: warenpostBillingNumber },
						});
					}

					if (hasError) {
						shake();
						return;
					}

					if (form.getFieldState('config.products.Retoure.billingNumber').isDirty) {
						const paketBillingNumber = form.getValues('config.products.Paket.billingNumber');
						const warenpostBillingNumber = form.getValues('config.products.Warenpost.billingNumber');

						const retoureBillingNumber = form.getValues('config.products.Retoure.billingNumber')!;

						let result: Awaited<ReturnType<typeof services.verifyRetoureBillingNumber>> | undefined = undefined;

						if (paketBillingNumber) {
							result = await services.verifyRetoureBillingNumber('Paket', paketBillingNumber, retoureBillingNumber);
						} else if (warenpostBillingNumber) {
							result = await services.verifyRetoureBillingNumber(
								'Warenpost',
								warenpostBillingNumber,
								retoureBillingNumber,
							);
						}

						if (result?.error) {
							notify({
								key: 'retoure',
								message: `Die angegebene Abrechnungsnummer wurde von DHL nicht akzeptiert. Bitte prüfe deine Eingabe.`,
								title: `Ungültige Abrechnungsnummer für DHL Retoure`,
								type: 'error',
							});

							shake();
							return;
						} else {
							form.resetField('config.products.Retoure', {
								defaultValue: { ...form.getValues('config.products.Retoure'), billingNumber: retoureBillingNumber },
							});
						}
					}
				} else if (step === 3) {
					const fields = [productEnum.Europaket, productEnum.PaketInternational, productEnum.WarenpostInternational];

					for (const field of fields) {
						const isDirty = form.getFieldState(`config.products.${field}.billingNumber`).isDirty;
						const value = form.getValues(`config.products.${field}.billingNumber`);

						if (isDirty && value) {
							const result = await services.verifyBillingNumber(field, value);

							if (result.error) {
								notify({
									key: field,
									message: `Die angegebene Abrechnungsnummer wurde von DHL nicht akzeptiert. Bitte prüfe deine Eingabe.`,
									title: `Ungültige Abrechnungsnummer für ${DhlParcelDeShippingPostParcelGermanyV2Sdk.spec.schema.productEnum.Enum[field]}`,
									type: 'error',
								});

								shake();

								return;
							} else {
								form.resetField(`config.products.${field}`, {
									defaultValue: { ...form.getValues(`config.products.${field}`), billingNumber: value },
								});
							}
						}
					}

					/** ----------------------------------------------------------------------------------------------
					 * check if we have at least one valid billing number for a shipping product
					 * _______________________________________________________________________________________________ */

					const hasValidBillingNumber = [
						productEnum.Paket,
						productEnum.Warenpost,
						productEnum.WarenpostInternational,
						productEnum.PaketInternational,
						productEnum.Europaket,
					].some((field) => Boolean(form.getValues(`config.products.${field}.billingNumber`)));

					console.log('hasValidBillingNumber', hasValidBillingNumber);

					if (!hasValidBillingNumber) {
						notify({
							key: 'no-billing-number',
							message:
								'Du benötigst eine Abrechnungsnummer für mindestens ein Versand-Produkt, um den Vertrag nutzen zu können. Bitte setze eine Abrechnungsnummer für nationalen oder internationalen Versand, um den Vertrag zu speichern.',
							title: 'Kein Produkt eingerichtet',
							type: 'error',
						});

						shake();
						return;
					}

					const { isIndividualLabelFormatsEnabled, ...input } = form.getValues();

					const isSuccess = await mutateUpsert({
						variables: {
							input: {
								carrierId: spec.api.id,
								environment: environment ?? 'production',
								id: data?.id ?? null,
								organizationId: user.organization.id,
								provider: 'DHL',
								...input,
							},
						},
					})
						.then(() => {
							onComplete?.();
							return true;
						})
						.catch((err) => {
							console.error(err);
							return false;
						});

					if (!isSuccess) {
						shake();
						setStep(step - 1);
					}
					return;
				}

				setStep(Math.min(step + 1, TOTAL_STEPS - 1));
			});
		},
		[step, form, services, shake, notify, mutateUpsert, environment, data?.id, user.organization.id, onComplete],
	);

	const config = CarrierProviderConfig.dhl1;

	return (
		<FormProvider {...form}>
			<Box
				ref={ref}
				className={clsx(s.form, 'lalelu')}
				component="form"
				// // eslint-disable-next-line @typescript-eslint/no-misused-promises
				// onSubmit={form.handleSubmit(handleSubmit)}
				style={{ height: '100%', overflow: 'hidden' }}
			>
				{createPortal(
					<DevTool
						control={control}
						styles={{ button: { position: 'fixed', right: 0, top: 0 }, panel: { position: 'fixed' } }}
					/>,
					document.body,
				)}
				<Box style={{ display: 'grid', gridTemplateRows: 'auto 1fr auto', height: '100%' }}>
					{/* HEADER ------------------------------------------------------------ */}
					<Stack gap={0}>
						<Group align="start" gap="xl" justify="space-between" p="md" pb="md">
							<Stack gap={0}>
								<Title order={3}>Einrichtung</Title>
								<Title order={5}>DHL-Geschäftskundenportal</Title>
							</Stack>
							<Stack align="end" gap={0} justify="start">
								<Box h={30} w={90}>
									<config.Logo height={30} width={'100%'} />
								</Box>
								<EnvironmentIndicator environment={environment ?? 'production'} />
							</Stack>
						</Group>
						<Divider />
					</Stack>

					{/* FORMS ------------------------------------------------------------ */}
					<Box
						className={s.stepper}
						style={{
							display: 'grid',
							gap: 0,
							gridAutoFlow: 'column',
							gridTemplateColumns: `repeat(${TOTAL_STEPS}, 1fr)`,
							overflow: 'hidden',
							translate: `-${(step * 100) / TOTAL_STEPS}%`,
							width: `calc(${TOTAL_STEPS} * 100%)`,
						}}
					>
						<StepFormItem activeStep={step} step={0}>
							<TextInput
								control={control}
								description="Vergib einen beliebigen Namen um diesen Vertrag in frachter leicht zu finden."
								label="Vertragsname"
								name="displayName"
								placeholder="Mein DHL-Vertrag"
								required
							/>

							<Divider my="xs" />

							<DhlFormSectionCredentials control={control} />
						</StepFormItem>

						<StepFormItem activeStep={step} step={1} title="Allgemeine Einstellungen">
							<DhlFormSectionGeneral control={control} />
						</StepFormItem>

						<StepFormItem activeStep={step} step={2} title="Nationaler Versand">
							<DhlFormSectionProductsNational control={control} />
						</StepFormItem>

						<StepFormItem activeStep={step} step={3} title="Internationaler Versand">
							<DhlFormSectionProductsInternational control={control} />
						</StepFormItem>

						<StepFormItem activeStep={step} step={4}>
							<Box style={{ display: 'grid', height: '100%', placeContent: 'center' }}>
								<Stack align="center">
									<Loader />
									<Text c="dimmed" size="sm">
										Speichere Daten...
									</Text>
								</Stack>
							</Box>
						</StepFormItem>
					</Box>

					{/* FOOTER ------------------------------------------------------------ */}
					<Box component="fieldset" disabled={isPending || step === TOTAL_STEPS - 1}>
						<Stack gap={0}>
							<Divider />

							<Group justify="space-between" p="md">
								<MonoButton onClick={onCancel} variant="transparent">
									Abbrechen
								</MonoButton>
								<Group>
									<MonoButton disabled={step === 0} onClick={() => void handleStepChange('previous')} variant="light">
										Zurück
									</MonoButton>
									<MonoButton loading={isPending} onClick={() => void handleStepChange('next')}>
										Weiter
									</MonoButton>
								</Group>
							</Group>
						</Stack>
					</Box>
				</Box>
			</Box>
		</FormProvider>
	);
}

const StepFormItem = ({
	activeStep,
	children,
	step,
	title,
}: {
	children: React.ReactNode;
	title?: React.ReactNode;
	step: number;
	activeStep: number;
}) => {
	const hasTitle = Boolean(title);

	return (
		<Box
			style={{
				display: 'flex',
				flex: 1,
				flexDirection: 'column',
				overflowY: 'auto',
				width: '100%',
			}}
		>
			{hasTitle && (
				<Stack
					gap={0}
					mb="md"
					p="md"
					py="xs"
					style={{
						backgroundColor: 'var(--mantine-color-body)',
						borderBottom: `1px solid color-mix(in srgb, var(--mantine-color-text) 8%, transparent)`,
						position: 'sticky',
						top: 0,
						width: '100%',
						zIndex: 10000,
					}}
				>
					<Title order={4}>{title}</Title>
				</Stack>
			)}
			<Box component="fieldset" disabled={step !== activeStep} h="100%" w="100%">
				<Stack h="100%" pb="md" pt={!hasTitle ? 'md' : 0} px="md" w="100%">
					{children}
				</Stack>
			</Box>
		</Box>
	);
};
