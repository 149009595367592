'use no memo';

import { Box } from '@mantine/core';
import { MantineReactTable, type MRT_TableInstance } from 'mantine-react-table';
import React from 'react';

import type { IRowType } from './types';

import s from './index.module.scss';
import { useCreateTable } from './use-create-table';

const ExampleWithProviders = () => {
	const tableOptions = useCreateTable();
	console.log('RENDER PAGE');
	return (
		//Put this with your other react-query providers near root of your app
		<Box className={s.root}>
			<MantineReactTable {...tableOptions.tableOptions} />

			{/* <Table table={table} /> */}
		</Box>
	);
};

const Table = React.memo(function MemoTable({ table }: { table: MRT_TableInstance<IRowType> }) {
	console.log('🔴 render table');
	return <MantineReactTable table={table} />;
});

export default ExampleWithProviders;
