import { useDebouncedCallback } from '@mantine/hooks';
import React from 'react';

import { FrachterLocksContext } from '../../context/FrachterLocksContext';
import { LockUnavailableError } from '../effect/lib/errors';
import { ILockPending, ILockRequestOptions } from '../types/locks';

import { useFrachterApi } from './use-frachter';
import { useRealtimeUserMembers } from './use-realtime-user-members';

export function useRequestLock(id: string, options?: ILockRequestOptions) {
	const api = useFrachterApi();

	const me = useRealtimeUserMembers('self');

	const locks = React.useContext(FrachterLocksContext);

	if (!locks) {
		throw new Error('useLock must be used within a LockProvider');
	}

	const { requestLock, unlock } = api;

	const [isPending, startTransition] = React.useTransition();

	/** ----------------------------------------------------------------------------------------------
	 * Store & State
	 * _______________________________________________________________________________________________ */
	const requestorId = React.useId();

	/**
	 * memoize this to avoid re-renders when being used with
	 * our external store!
	 */
	const pendingLock = React.useMemo(
		() =>
			({
				id,
				metadata: options?.metadata,
				status: 'pending',
			}) satisfies ILockPending,
		[id, options?.metadata],
	);

	const lock = React.useSyncExternalStore(api.$$locks.subscribe, () => {
		const all = api.$$locks.getSnapshot().all;
		if (id in all) {
			return all[id];
		}
		return pendingLock;
	});

	const member = useRealtimeUserMembers(lock?.status === 'acquired' ? lock.details.lockedBy : undefined);

	const [hasLostLock, setHasLostLock] = React.useState(false);

	const [hasBecomeOwner, setHasBecomeOwner] = React.useState(false);

	const isOwner = React.useMemo(() => {
		return (
			lock.status === 'acquired' &&
			lock.details.lockedBy.clientId === me?.clientId &&
			lock.details.lockedBy.connectionId === me?.connectionId
		);
	}, [lock, me]);

	/** ----------------------------------------------------------------------------------------------
	 * LOCKING
	 * _______________________________________________________________________________________________ */

	/* create separate method so we can expose it through `retry` */
	const doLock = React.useCallback(async () => {
		locks.setLock(id, requestorId);
		const result = await requestLock(id, options ?? {});

		if (result instanceof LockUnavailableError) {
			locks.unsetLock(id, requestorId);
		} else {
			setHasLostLock(false);
		}
	}, [id, locks, options, requestLock, requestorId]);

	const handleState = useDebouncedCallback((task: 'lock' | 'unlock') => {
		startTransition(async () => {
			if (task === 'lock') {
				await doLock();
			} else {
				locks.unsetLock(id, requestorId);
				await unlock(id);
			}
		});
	}, 10);

	/** ----------------------------------------------------------------------------------------------
	 * side effects
	 * _______________________________________________________________________________________________ */

	/* start initial locking (and re-lock when an unavailable lock becomes available and we've requested it) */
	React.useEffect(() => {
		if (lock.status === 'pending') {
			handleState('lock');
		}
	}, [lock.status, handleState]);

	/* unlock on unmount */
	React.useEffect(() => {
		return () => {
			handleState('unlock');
		};
	}, [handleState]);

	/* set hasBecomeOwner when we become owner to know when we've lost it */
	React.useEffect(() => {
		if (isOwner) {
			setHasBecomeOwner(true);
		}
	}, [isOwner]);

	/* unlock and set state when we've lost a lock */
	React.useEffect(() => {
		if (hasBecomeOwner && !isOwner) {
			handleState('unlock');
			setHasLostLock(true);
		}
	}, [hasBecomeOwner, isOwner, handleState]);

	return {
		data: {
			hasLostLock,
			isOwner,
			lock,
			owner: member,
		},
		isLoading: isPending,
		retry: () => doLock(),
	};
}
