import { createRetriableTask, handleExit } from '@packages/lib/effect';
import { type PrinterWithClientIdAndHostnameSchema } from '@packages/lib/schema/cloudprint';
import { messages } from '@packages/lib/schema/realtime';
import { create, windowedFiniteBatchScheduler } from '@yornaath/batshit';
import { InferInsertModel } from 'drizzle-orm';
import { Effect } from 'effect';

import { dbs } from '@/drizzle/pglite';

import { BackendService } from '../BackendService';
import { DatabaseService } from '../DatabaseService';
import { RealtimeService } from '../RealtimeService';

export class CloudprintService extends Effect.Service<CloudprintService>()('CloudprintService', {
	scoped: Effect.gen(function* () {
		const realtime = yield* RealtimeService;
		const database = yield* DatabaseService;
		const backend = yield* BackendService;

		return {
			batchPrint: create<
				messages.PrintJobResponseDataSchema['jobs'] | undefined,
				{
					clientDevice?: undefined | PrinterWithClientIdAndHostnameSchema;
					job: messages.PrintJobRequestDataSchema['jobs'][number];
				},
				messages.PrintJobResponseDataSchema['jobs'][number]
			>({
				fetcher: async (requests) => {
					const cloudprintClientDevice = requests[0].clientDevice;
					console.log('batchPrint', requests);
					if (!cloudprintClientDevice) {
						throw new Error('cloudprintClientDevice is not set');
					}

					const dbItemData: Pick<
						InferInsertModel<typeof dbs.printjobs>,
						'createdAt' | 'hostname' | 'printerName' | 'type'
					> = {
						createdAt: new Date(),
						hostname: cloudprintClientDevice.hostname,
						printerName: cloudprintClientDevice.name,
						type: 'order' as const,
					};

					const p = Promise.withResolvers<messages.PrintJobResponseDataSchema['jobs'] | undefined>();

					let isResolved = false;

					async function resolve(results?: messages.PrintJobResponseDataSchema['jobs']) {
						if (isResolved) return;
						isResolved = true;

						if (results) {
							await database.drizzleClient.insert(dbs.printjobs).values(
								results.map((v) => {
									const job = requests.find((j) => j.job.id === v.id)!.job;

									return {
										...dbItemData,
										...v,
										...job,
										eid: job.eid ?? null,
										eid2: job.eid2 ?? null,
									};
								}),
							);
						}

						p.resolve(results);
					}

					if (!cloudprintClientDevice) {
						void resolve(requests.map((v) => ({ ...v.job, status: 'ERROR_NO_PRINTER' })));
					} else {
						void Effect.runPromiseExit(
							realtime
								.sendMessageAck(
									{
										payload: {
											jobs: requests.map((v) => v.job),
											printerName: cloudprintClientDevice.name,
										} satisfies messages.PrintJobRequestDataSchema,
										receiverClientId: cloudprintClientDevice.clientId,
									},
									messages.printJobResponseDataSchema,
								)
								.pipe(Effect.scoped),
						)
							.then(handleExit)
							.then((result) => {
								console.log('result', result);

								if (result.data) {
									void resolve(result.data.jobs);
								} else if (result.err) {
									switch (result.err.type) {
										case 'timeout':
											console.log('timeout', result.err);
											void resolve(requests.map((v) => ({ ...v.job, status: 'ERROR_TIMEOUT' })));
											// TODO: show notification to user
											break;
										case 'interrupt':
											console.log('interrupt', result.err);
											void resolve(requests.map((v) => ({ ...v.job, status: 'ERROR_ABORTED' })));
											// TODO: show notification to user
											break;
										case 'failure':
											console.log('failure', result.err);
											void resolve();
											// TODO: show notification to user
											break;
									}
								}
							});
					}

					return p.promise;
				},
				name: 'batchPrint',
				resolver: (items, query) => {
					const item = (items ?? []).find((v) => v.id === query.job.id)!;
					return item;
				},
				scheduler: windowedFiniteBatchScheduler({
					maxBatchSize: 50,
					windowMs: 250,
				}),
			}),

			confirmCloudprintConnection: async (code: string, signal?: AbortSignal) => {
				return createRetriableTask(
					{
						catch(retry, err) {
							if (err instanceof Error && err.message === '5xx') {
								return retry();
							}

							return new Error('unknown');
						},
						try: () =>
							backend.client.connect.cloudprint.confirm.$post({ json: { code } }).then((r) => {
								if (r.status >= 500) {
									throw new Error('5xx');
								}

								if (r.ok) {
									return 'connected';
								}

								return 'invalid';
							}),
					},
					{
						retryDelaySeconds: 1,
						signal,
						timeoutSeconds: 15,
					},
				);
			},

			// HINT: we no longer need the organizationId, because we've already set this during the api creation in RealtimeService
			disconnectCloudprintClient: async (cloudprintRealtimeClientId: string) => {
				return Effect.runPromise(
					realtime.sendMessage({
						payload: { type: 'CLOUDPRINT_DISCONNECT_REQUEST' } satisfies messages.CloudprintDisconnectRequestDataSchema,
						receiverClientId: cloudprintRealtimeClientId,
					}),
				);
			},
		};
	}),
}) {}
