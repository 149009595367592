import { Effect } from 'effect';

import { LockError } from './errors';
import { LockResultAcquired, LockResultUnavailable } from './types';

export const createLockId = (id: string, options?: Omit<LockOptions, 'steal'>) =>
	Effect.async<LockResultAcquired | LockResultUnavailable, LockError>((resume, signal) => {
		const p = Promise.withResolvers<void>();

		const finalOptions: LockOptions = { ifAvailable: true, mode: 'exclusive', signal, steal: false, ...options };

		/**
		 * remove signal if `ifAvailable` is set to true, as this would cause an error otherwise
		 */
		if (finalOptions.ifAvailable) {
			delete finalOptions.signal;
		}

		// navigator.locks.query().then(locks => {
		// 	locks.held?.find(lock => lock.name === id && lock.clientId === )
		// })

		void navigator.locks
			.request(id, finalOptions, (lock) => {
				/**
				 * we could cast to `Lock` as lock would only be null if `ifAvailable` is true, otherwise it waits until available
				 * @see https://developer.mozilla.org/en-US/docs/Web/API/Web_Locks_API#options
				 */

				if (!lock) {
					resume(Effect.succeed({ status: 'unavailable' }));
				} else {
					resume(
						Effect.succeed({
							lock,
							release: () => {
								p.resolve();
							},
							status: 'acquired',
						}),
					);
				}

				return p.promise;
			})
			.catch((error) => {
				console.log('ERROR', error);
				if (error instanceof DOMException) {
					switch (error.name) {
						case 'InvalidStateError':
							// Thrown if the environments document is not fully active.
							resume(Effect.fail(new LockError({ name: 'InvalidStateError' })));
							break;
						case 'SecurityError':
							// Thrown if a lock manager cannot be obtained for the current environment.
							resume(Effect.fail(new LockError({ name: 'SecurityError' })));
							break;
						case 'NotSupportedError':
							// Thrown if name starts with a hyphen (-), both options steal and ifAvailable are true,
							// or if option signal exists and either option steal or ifAvailable is true.
							resume(Effect.fail(new LockError({ name: 'NotSupportedError' })));
							break;
						case 'AbortError':
							// Thrown if the option signal exists and is aborted.
							// ignore, as it's handled by effect
							break;
						default:
							// this should not happen
							resume(Effect.die(error));
					}
				} else {
					resume(Effect.die(error));
				}
			});
	});
