// 1) Reference your RequestResult type
import { Effect } from 'effect';
export function sdkToRequest(fn, options) {
    const p = Promise.withResolvers();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    fn({
        baseUrl: 'https://__ignore__.com',
        ...options,
        fetch: (request) => {
            p.resolve(request);
            throw new Error('ignore');
        },
    }).catch(() => { });
    return Effect.promise(() => p.promise);
}
// type SdkReturnType = ExtractHeyApiReturnDataType<typeof sdk.ordersV4GetPartnerOrderByOrderNumber>;
