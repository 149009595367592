import { Box, Button, Stack, TextInput } from '@mantine/core';
import React, { use } from 'react';

import { main, useMain, useStore } from './program';

export default function Page() {
	const program = use(main);

	const m = useMain();

	const id = useStore();

	console.log(id);

	// React.useEffect(() => {
	// 	const interval = setInterval(() => {
	// 		void m.a.setValue(new Date().toISOString());
	// 	}, 1000);

	// 	return () => clearInterval(interval);
	// }, [m.a]);

	return (
		<Box p="xl">
			<Stack>
				<Button onClick={() => program.a.sayHello()}>say hello</Button>
				<Button onClick={() => m.a.sayHello()}>say hello via hook</Button>
				<Button onClick={() => void program.a.setValue(crypto.randomUUID())}>set value</Button>
				<Button onClick={() => void program.a.closeScope()}>stop listening</Button>
				<Button onClick={() => void program.a.updateState((s) => ({ ...s, counter: s.counter + 1 }))}>
					update state
				</Button>
				<TextInput disabled value={id} />
			</Stack>
		</Box>
	);
}
