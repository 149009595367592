'use client';

import React, { memo, useState } from 'react';
import isEqual from 'react-fast-compare';
import { createContext, useContextSelector } from 'use-context-selector';

import { AppPageLayoutVariant } from '../app-page/AppPageLayout';

export interface AppLayoutContext {
	controls: [React.ReactNode, React.Dispatch<React.SetStateAction<React.ReactNode>>];
	pageVariant: [AppPageLayoutVariant, React.Dispatch<React.SetStateAction<AppPageLayoutVariant>>];
	title: [
		{ element: React.JSX.Element; key: string }[] | null,
		React.Dispatch<React.SetStateAction<{ element: React.JSX.Element; key: string }[] | null>>,
	];
}

const initialContext: AppLayoutContext = {
	controls: [null, () => null],
	pageVariant: ['default', () => null],
	title: [null, () => null],
};

const appLayoutContext = createContext<AppLayoutContext>(initialContext);

export const AppLayoutProvider = memo(function AppLayoutProvider(props: React.PropsWithChildren) {
	return (
		<appLayoutContext.Provider
			value={{
				controls: useState<React.ReactNode>(null),
				pageVariant: useState<AppPageLayoutVariant>('default'),
				title: useState<{ element: React.JSX.Element; key: string }[] | null>(null),
			}}
		>
			{props.children}
		</appLayoutContext.Provider>
	);
}, isEqual);

export const useAppLayoutControls = () => {
	return useContextSelector(appLayoutContext, (v) => v.controls[0]);
};

export const useAppLayoutSetControls = () => {
	return useContextSelector(appLayoutContext, (v) => v.controls[1]);
};

export const useAppLayoutTitle = () => {
	return useContextSelector(appLayoutContext, (v) => v.title[0]);
};

export const useAppLayoutSetTitle = () => {
	return useContextSelector(appLayoutContext, (v) => v.title[1]);
};

export const useAppLayoutPageVariant = () => {
	return useContextSelector(appLayoutContext, (v) => v.pageVariant[0]);
};

export const useAppLayoutSetPageVariant = () => {
	return useContextSelector(appLayoutContext, (v) => v.pageVariant[1]);
};
