import { Stack, Title } from '@mantine/core';
import { DhlParcelDeShippingPostParcelGermanyV2Sdk } from '@packages/lib/api-sdk/dhl_parcel_de_shipping_post_parcel_germany_v2';
import React from 'react';
import { Control } from 'react-hook-form';
import { Select, TextInput } from 'react-hook-form-mantine';

import { FormSchema } from './setup-form-schema';

const productEnum = DhlParcelDeShippingPostParcelGermanyV2Sdk.spec.schema.productEnum.Enum;

export const LabelFormatSelect: React.FC<{
	control: Control<FormSchema>;
	name: Exclude<keyof typeof productEnum, typeof productEnum.Retoure> | 'defaultLabelFormat';
}> = ({ control, name }) => {
	const labelOptions = Array.from(
		Object.entries(DhlParcelDeShippingPostParcelGermanyV2Sdk.spec.labels)
			.map(([key, { name }]) => ({
				label: name,
				value: key,
			}))
			.filter(({ value }) =>
				name === productEnum.Warenpost || name === 'WarenpostInternational'
					? true
					: value !==
						DhlParcelDeShippingPostParcelGermanyV2Sdk.spec.schema.labelFormatWarenpostEnum.Enum.Format_100_70mm,
			),
	);

	let placeholder = 'Wähle ein Format';

	if (name !== 'defaultLabelFormat') {
		placeholder = 'Standard-Etikettenformat verwenden';
	}

	return (
		<Select
			allowDeselect={name !== 'defaultLabelFormat'}
			control={control}
			data={labelOptions}
			/* use as any here to avoid issue with Retoure not having a label format ! */
			name={name === 'defaultLabelFormat' ? 'config.defaultLabelFormat' : `config.products.${name}.labelFormat`}
			placeholder={placeholder}
			description={
				name === 'defaultLabelFormat'
					? ''
					: 'Du kannst auf den Standard zurücksetzen, indem du das gewählte Format abwählst.'
			}
		/>
	);
};

export const DhlProductInput: React.FC<{
	control: Control<FormSchema>;
	name: keyof typeof productEnum;
	showLabelSelect?: boolean;
	disabled?: boolean;
}> = ({ control, disabled, name, showLabelSelect }) => {
	const { hint: description, name: label, placeholder } = DhlParcelDeShippingPostParcelGermanyV2Sdk.spec.products[name];

	// const value = useWatch({ control, name: `config.products.${name}` });

	return (
		<Stack gap={2}>
			<Title order={6}>{label}</Title>
			<Stack gap="xs">
				<TextInput
					control={control}
					description={description}
					disabled={Boolean(disabled)}
					maxLength={14}
					name={`config.products.${name}.billingNumber`}
					placeholder={placeholder}
				/>
				{name !== productEnum.Retoure && showLabelSelect && <LabelFormatSelect control={control} name={name} />}
			</Stack>
		</Stack>
	);
};
