import { Data } from 'effect';
import { fromError } from 'zod-validation-error';
export class MarketValidationError extends Data.TaggedError('frachter/MarketValidationError') {
    constructor(params) {
        const parsed = fromError(params.zError);
        super();
        // @ts-expect-error this is ok
        this.message = parsed.message;
        // @ts-expect-error this is ok
        this.stack = parsed.stack;
        // @ts-expect-error this is ok
        this.cause = params.cause;
        // @ts-expect-error this is ok
        this.marketId = params.marketId;
        // @ts-expect-error this is ok
        this.merchantId = params.merchantId;
    }
}
export class UserRecoverableMarketError extends Data.TaggedError('frachter/UserRecoverableMarketError') {
}
export class SystemRecoverableMarketError extends Data.TaggedError('frachter/SystemRecoverableMarketError') {
}
export class FatalMarketError extends Data.TaggedError('frachter/FatalMarketError') {
}
// interface ApiError {
// 	// payload means that we've provided incorrect request payload (body or headers)
// 	reason: 'unauthorized' | 'usage_limit_exceeded' | 'payload' | 'unknown';
// }
// ApiError
// => usage_limit_exceeded (420) => notification to retry manually as we've already tried on the server
// => unauthorized (401, 403) => notification to check credentials
// => UNEXPECTED (all other cases that we could not map to a specific error)
// CarrierLabelCreationError
// => MISSING_INPUTS => notification to check inputs (should also be logged with sentry as this should not happen) / see detailed error info (json)
// => INVALID_VALUES => notification to check inputs (should also be logged with sentry as this should not happen) / see detailed error info (json)
// => UNEXPECTED (all other cases)
// message: string
// CarrierLabelCancellationError
// => ALREADY_USED => notification that label cannot be cancelled as it has already been used
// => NOT_ALLOWED => notification that the label cannot be cancelled (we don't have a reason for it)
// => UNEXPECTED (all other cases)
// message: string
// OrderFulfillmentError
// => MISSING_INPUTS
// => INVALID_VALUES
// ???
// => UNEXPECTED (all other cases)
