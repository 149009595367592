import { Effect, SubscriptionRef } from 'effect';

import type { IMakeShapeStreamOptions } from './types';

import { makeShapeStream } from './make-shape-stream';

export const makeShape = (options: Omit<IMakeShapeStreamOptions, 'onIsSynchronizedChanged'>) =>
	Effect.gen(function* () {
		const isSynchonizedRef = yield* SubscriptionRef.make<boolean>(true);

		yield* Effect.forkScoped(
			makeShapeStream({
				...options,
				onIsSynchronizedChanged(live) {
					void Effect.runPromise(SubscriptionRef.set(isSynchonizedRef, live));
				},
			}),
		);

		return {
			isSynchonizedRef,
		};
	});
