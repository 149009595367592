import { match, P } from 'ts-pattern';
import { getBillingNumberAuthorizationErrorIndex, getReturnBillingNumberAuthorizationErrorIndex } from './errors';
function parseResponseItem(item) {
    const validationMessages = item.validationMessages ? [...item.validationMessages] : [];
    if (item.sstatus.statusCode >= 200 && item.sstatus.statusCode < 300) {
        return {
            data: {
                ...(item.codLabel && { customsDocumentLabelUrl: item.codLabel.url }),
                ...(item.customsDoc && { customsDocumentUrl: item.customsDoc.url }),
                ...(item.label && { labelUrl: item.label.url }),
                ...(item.returnShipmentNo && { returnTrackingNumber: item.returnShipmentNo }),
                ...(item.routingCode && { routingCode: item.routingCode }),
                ...(item.shipmentNo && { trackingNumber: item.shipmentNo }),
                warnings: validationMessages
                    ?.filter((m) => m.validationState === 'Warning')
                    .map((v) => ({
                    message: v.validationMessage ?? '',
                    path: v.property ?? '',
                    type: 'warning',
                }))
                    // only keep warnings that do have a message and path
                    .filter((v) => v.message.length > 0) ?? [],
            },
            err: null,
        };
    }
    else {
        const billingNumberAuthorizationErrorIndex = getBillingNumberAuthorizationErrorIndex(validationMessages);
        const returnBillingNumberAuthorizationErrorIndex = getReturnBillingNumberAuthorizationErrorIndex(validationMessages);
        if (billingNumberAuthorizationErrorIndex !== -1) {
            // remove the validation message from the array
            validationMessages?.splice(billingNumberAuthorizationErrorIndex, 1);
        }
        if (returnBillingNumberAuthorizationErrorIndex !== -1) {
            // remove the validation message from the array
            validationMessages?.splice(returnBillingNumberAuthorizationErrorIndex, 1);
        }
        // now parse the remaining validation messages and split into warnings and errors
        const errors = validationMessages
            ?.filter((m) => m.validationState === 'Error')
            .map((v) => ({
            message: v.validationMessage ?? '',
            ...(v.property && { path: v.property }),
            type: 'error',
        }))
            .filter((e) => e.message.length > 0) ?? [];
        const warnings = validationMessages
            ?.filter((m) => m.validationState === 'Warning')
            .map((v) => ({
            message: v.validationMessage ?? '',
            ...(v.property && { path: v.property }),
            type: 'warning',
        }))
            .filter((e) => e.message.length > 0) ?? [];
        return {
            data: null,
            err: {
                errors,
                isBillingNumberUnauthorized: billingNumberAuthorizationErrorIndex !== -1,
                isReturnBillingNumberUnauthorized: returnBillingNumberAuthorizationErrorIndex !== -1,
                warnings: warnings ?? null,
            },
        };
    }
}
export function createOrdersHandler(result, response) {
    if (response.status === 401 || response.status === 403) {
        return {
            isUnauthorized: true,
            items: [],
        };
    }
    // const isMultipleShipments = response.status === 207;
    // just ignore the root status details, we always traverse the items !
    return (match(result)
        // TODO: problem is we need to check the validation messages for each item !
        // AND: another issue is, that we need to check if we have a single shipment item or multiple ! (can be done by checking the response status code, which is 207 in case of multiple)
        .with({ items: P.not(P.nullish) }, (resultWithItems) => {
        return {
            items: resultWithItems.items.map(parseResponseItem),
        };
    })
        .otherwise((resultWithoutItems) => {
        if ('title' in resultWithoutItems) {
            return {
                exception: {
                    message: resultWithoutItems.title,
                    status: resultWithoutItems.statusCode ?? resultWithoutItems.status,
                },
                items: [],
            };
        }
        else {
            return {
                exception: {
                    message: 'Unknown error. This should be handled!',
                    // not implemented status code
                    status: 501,
                },
                items: [],
            };
        }
        /**
         * this case happens when we do not have the `shipments` in the request body
         * status in this case will most likely be 500
         */
    }));
}
