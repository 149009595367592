import * as cloudprint from './cloudprint';
import * as quickship from './quickship';

/**
 * database execution statements
 */
export const dbx = {
	cloudprint,
	quickship,
};
