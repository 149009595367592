/**
 * responses are created with postman and can be used as spread for msw mocks
 */
export const response = {
    client: {
        /**
         * happens when invalid credentials are provided
         */
        invalidCredentials: [
            {
                detail: 'The credentials you provided are invalid.',
                status: 401,
                statusCode: 401,
                title: 'Unauthorized',
            },
            { status: 401 },
        ],
        /**
         * happens when no credentials are provided
         */
        noCredentials: [
            {
                detail: 'Invalid combination of credentials: Use EITHER Bearer Token or (Apikey and Basic Auth).',
                status: 401,
                title: 'Unauthorized',
            },
            { status: 401 },
        ],
    },
    createOrders: {
        billingNumberNotAuthorized: [
            {
                items: [
                    {
                        sequenceNumber: 1,
                        sstatus: {
                            status: 400,
                            statusCode: 400,
                            title: 'A hard validation error occured in the shipment.',
                        },
                        validationMessages: [
                            {
                                property: 'services.dhlRetoure',
                                validationMessage: 'The billing number entered is not available for returns.',
                                validationState: 'Error',
                            },
                            {
                                property: 'billingNumber',
                                validationMessage: 'The selected billing number is not available.',
                                validationState: 'Error',
                            },
                        ],
                    },
                ],
                status: {
                    detail: '0 of 1 shipment validated OK. Details included.',
                    status: 400,
                    statusCode: 400,
                    title: 'Bad Request',
                },
            },
            { status: 400 },
        ],
        billingNumberNotAuthorizedAndReturnBillingNumberNotAuthorized: [
            {
                items: [
                    {
                        sequenceNumber: 1,
                        sstatus: {
                            status: 400,
                            statusCode: 400,
                            title: 'A hard validation error occured in the shipment.',
                        },
                        validationMessages: [
                            {
                                property: 'services.dhlRetoure',
                                validationMessage: 'The billing number entered is not available for returns.',
                                validationState: 'Error',
                            },
                            {
                                property: 'billingNumber',
                                validationMessage: 'The selected billing number is not available.',
                                validationState: 'Error',
                            },
                        ],
                    },
                ],
                status: {
                    detail: '0 of 1 shipment validated OK. Details included.',
                    status: 400,
                    statusCode: 400,
                    title: 'Bad Request',
                },
            },
            { status: 400 },
        ],
        billingNumberSchemaError: [
            {
                items: [
                    {
                        sstatus: {
                            status: 400,
                            statusCode: 400,
                            title: 'Bad Request',
                        },
                        validationMessages: [
                            {
                                property: 'billingNumber',
                                validationMessage: 'Parameter billingNumber must consist of 14 character (10-digit account number, 2 digit procedure ID, 2 digit partnerID).',
                                validationState: 'Error',
                            },
                        ],
                    },
                ],
                status: {
                    detail: '0 of 1 shipment successfully created.',
                    status: 400,
                    statusCode: 400,
                    title: 'Bad Request',
                },
            },
            { status: 400 },
        ],
        billingNumberSchemaErrorAndReturnBillingNumberSchemaError: [
            {
                items: [
                    {
                        sstatus: {
                            status: 400,
                            statusCode: 400,
                            title: 'Bad Request',
                        },
                        validationMessages: [
                            {
                                property: 'services.dhlRetoure.billingNumber',
                                validationMessage: 'Parameter billingNumber must consist of 14 character (10 digit account number, 2 digit procedure ID, 2 digit partnerID).',
                                validationState: 'Error',
                            },
                            {
                                property: 'billingNumber',
                                validationMessage: 'Parameter billingNumber must consist of 14 character (10-digit account number, 2 digit procedure ID, 2 digit partnerID).',
                                validationState: 'Error',
                            },
                        ],
                    },
                ],
                status: {
                    detail: '0 of 1 shipment successfully created.',
                    status: 400,
                    statusCode: 400,
                    title: 'Bad Request',
                },
            },
            { status: 400 },
        ],
        /**
         * in this case we have a warning and an error in the same shipment
         */
        hasWarningAndErrorInSameShipment: [
            {
                items: [
                    {
                        sequenceNumber: 1,
                        sstatus: {
                            status: 400,
                            statusCode: 400,
                            title: 'In der Sendung trat mindestens ein harter Fehler auf.',
                        },
                        validationMessages: [
                            {
                                property: 'consignee.city',
                                validationMessage: 'Der Ort ist zu dieser PLZ nicht bekannt. Die Sendung ist trotzdem leitcodierbar und es wurde ein Versandschein erzeugt.',
                                validationState: 'Warning',
                            },
                            {
                                property: 'details.dim',
                                validationMessage: 'Die angegebene Länge ist zu groß.',
                                validationState: 'Error',
                            },
                            {
                                property: 'details.dim',
                                validationMessage: 'Das angegebene Gurtmaß ist zu groß.',
                                validationState: 'Error',
                            },
                        ],
                    },
                ],
                status: {
                    detail: '0 von 1 Sendung validiert. Details finden Sie unten.',
                    status: 400,
                    statusCode: 400,
                    title: 'Bad Request',
                },
            },
            { status: 207 },
        ],
        invalidBillingNumber: [
            {
                items: [
                    {
                        sstatus: {
                            status: 400,
                            statusCode: 400,
                            title: 'Bad Request',
                        },
                        validationMessages: [
                            {
                                property: 'billingNumber',
                                validationMessage: 'The selected billing number is invalid.',
                                validationState: 'Error',
                            },
                        ],
                    },
                ],
                status: {
                    detail: '0 of 1 shipment successfully created.',
                    status: 400,
                    statusCode: 400,
                    title: 'Bad Request',
                },
            },
            { status: 400 },
        ],
        /**
         * happens when we pass a billing number that does not pass the schema validation
         */
        invalidReturnBillingNumber: [
            {
                items: [
                    {
                        sstatus: {
                            status: 400,
                            statusCode: 400,
                            title: 'Bad Request',
                        },
                        validationMessages: [
                            {
                                property: 'services.dhlRetoure',
                                validationMessage: 'The billing number entered is not available for returns.',
                                validationState: 'Error',
                            },
                        ],
                    },
                ],
                status: {
                    detail: '0 of 1 shipment successfully created.',
                    status: 400,
                    statusCode: 400,
                    title: 'Bad Request',
                },
            },
            { status: 400 },
        ],
        /**
         * in case we have multiple shipments, we get a multi-status response
         */
        missingConsigneeAndShipper: [
            {
                items: [
                    {
                        sequenceNumber: 1,
                        sstatus: {
                            detail: 'Der Webservice wurde ohne Fehler ausgeführt.',
                            status: 200,
                            statusCode: 200,
                            title: 'OK',
                        },
                        validationMessages: [],
                    },
                    {
                        sequenceNumber: 2,
                        sstatus: {
                            status: 400,
                            statusCode: 400,
                            title: 'In der Sendung trat mindestens ein harter Fehler auf.',
                        },
                        validationMessages: [
                            {
                                property: 'consignee',
                                validationMessage: 'Der Parameter consignee ist erforderlich.',
                                validationState: 'Error',
                            },
                            {
                                property: 'shipper',
                                validationMessage: 'Der Parameter shipper ist erforderlich.',
                                validationState: 'Error',
                            },
                        ],
                    },
                ],
                status: {
                    detail: '1 von 2 Sendungen validiert. Details finden Sie unten.',
                    status: 207,
                    statusCode: 207,
                    title: 'Multi-Status',
                },
            },
            { status: 207 },
        ],
        returnBillingNumberNotAuthorized: [
            {
                items: [
                    {
                        sequenceNumber: 1,
                        sstatus: {
                            status: 400,
                            statusCode: 400,
                            title: 'A hard validation error occured in the shipment.',
                        },
                        validationMessages: [
                            {
                                property: 'services.dhlRetoure',
                                validationMessage: 'The billing number entered is not available for returns.',
                                validationState: 'Error',
                            },
                        ],
                    },
                ],
                status: {
                    detail: '0 of 1 shipment validated OK. Details included.',
                    status: 400,
                    statusCode: 400,
                    title: 'Bad Request',
                },
            },
            { status: 400 },
        ],
        returnBillingNumberSchemaError: [
            {
                items: [
                    {
                        sstatus: {
                            status: 400,
                            statusCode: 400,
                            title: 'Bad Request',
                        },
                        validationMessages: [
                            {
                                property: 'services.dhlRetoure.billingNumber',
                                validationMessage: 'Parameter billingNumber must consist of 14 character (10 digit account number, 2 digit procedure ID, 2 digit partnerID).',
                                validationState: 'Error',
                            },
                        ],
                    },
                ],
                status: {
                    detail: '0 of 1 shipment successfully created.',
                    status: 400,
                    statusCode: 400,
                    title: 'Bad Request',
                },
            },
            { status: 400 },
        ],
    },
};
