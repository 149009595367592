import { Data } from 'effect';

export class WorkerCreationError extends Data.TaggedError('DatabaseService/WorkerCreationError')<{
	message: string;
	cause?: unknown;
}> {}

export class DatabaseCloseError extends Data.TaggedError('DatabaseService/DatabaseCloseError')<{
	message: string;
	cause?: unknown;
}> {}

export class DatabaseError extends Data.TaggedError('DatabaseService/DatabaseError')<{
	message: string;
	cause?: unknown;
}> {}

export class DatabaseSyncError extends Data.TaggedError('DatabaseService/DatabaseSyncError')<{
	message: string;
	reason?: 'shape_outdated';
	cause?: unknown;
}> {}
