export const createTriggerMigrationStatement = (table: string) => /*sql*/ `
  CREATE OR REPLACE FUNCTION set_year_month_and_week()
  RETURNS TRIGGER AS $$
  BEGIN
      -- Only update if created_at is changing
      IF NEW.created_at IS DISTINCT FROM OLD.created_at THEN
          NEW._year_month := TO_CHAR(NEW.created_at AT TIME ZONE 'UTC', 'YYYY-MM');
          NEW._year_week := TO_CHAR(NEW.created_at AT TIME ZONE 'UTC', 'IYYY-"W"IW');
      END IF;
      
      RETURN NEW;
  END;
  $$ LANGUAGE plpgsql;

  DROP TRIGGER IF EXISTS trigger_set_year_month_and_week ON ${table};

  CREATE TRIGGER trigger_set_year_month_and_week
  BEFORE INSERT OR UPDATE ON ${table}
  FOR EACH ROW
  EXECUTE FUNCTION set_year_month_and_week();
`;

export const clearYearMonthFromTableStatement = (table: string, yearMonth: string) => /*sql*/ `
  DELETE FROM ${table}
  WHERE
    _year_month = '${yearMonth}';
`;

export const clearTableStatement = (table: string) => /*sql*/ `
  DELETE FROM ${table};
`;

export const getElectricShapesStatement = (table: string) => /*sql*/ `
SELECT * FROM electric.subscriptions_metadata WHERE "key" LIKE '${table}%'
`;
