import { Group } from '@mantine/core';
import React from 'react';

import type { IBundleParams } from '../../bundle-list-table/types';

import type { IRowType } from './types';

import { MemoizedTableBottomBar, MemoizedTableTopBar, Table } from '@/components/table';
import { MonoButton } from '@/components/ui/MonoButton';

import { useBundleDetailsQuery } from './use-bundle-details-query';
import { useCreateColumns } from './use-create-columns';

export const BundleDetailsTable = (
	props: IBundleParams & {
		bundleKey: string;
	},
) => {
	// const pagination = useBundleDeta

	const [refreshedAt, setRefreshedAt] = React.useState<number>(Date.now());

	const { data, hasUpdate, totalRowCount } = useBundleDetailsQuery({
		pagination: {
			pageIndex: 0,
			pageSize: 20,
		},
		params: {
			bundleKey: props.bundleKey,
			bundleKeyReference: props.bundleKeyReference,
			marketId: props.marketId,
			merchantId: props.merchantId,
		},
		queryKey: [refreshedAt.toString()],
	});

	const refresh = React.useCallback(() => {
		setRefreshedAt(Date.now());
	}, []);

	const actions = React.useMemo(() => {
		return (
			<Group>
				<MonoButton disabled={!hasUpdate} onClick={refresh} size="xs">
					Aktualisieren
				</MonoButton>
			</Group>
		);
	}, [hasUpdate, refresh]);

	const columns = useCreateColumns();

	return (
		<Table<IRowType & { id: string }>
			columns={columns}
			data={data ?? []}
			queryKey={[refreshedAt.toString()]}
			tableBottomBar={<MemoizedTableBottomBar actions={actions} isFetching={false} />}
			tableTopBar={<MemoizedTableTopBar title="Bundle" />}
			totalRowCount={totalRowCount}
			styles={{
				cell: {
					py: 'xs',
				},
				content: {
					// HINT: if we don't set this, the head and foot row background will overlay the paper's side shadow
					px: 2,
				},
				frame: {
					// px: '4rem',
				},
			}}
		/>
	);
};
