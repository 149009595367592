import { ActionIcon } from '@mantine/core';
import { IconSortAscending, IconSortDescending } from '@tabler/icons-react';
import React from 'react';

export const SortingButton: React.FC<{
	className?: string;
	onClick?: () => void;
	style?: React.CSSProperties;
	sortDirection?: 'asc' | 'desc' | null;
}> = ({ className, onClick, sortDirection, style }) => {
	return (
		<ActionIcon
			c={
				typeof sortDirection === 'string' ? 'primary' : 'color-mix(in srgb, var(--mantine-color-text) 20%, transparent)'
			}
			className={className}
			onClick={onClick}
			size="sm"
			style={style}
			variant="transparent"
		>
			{sortDirection === 'asc' ? (
				<IconSortAscending />
			) : sortDirection === 'desc' ? (
				<IconSortDescending />
			) : (
				<IconSortAscending />
			)}
		</ActionIcon>
	);
};
