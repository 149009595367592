'use client';

import { Box, Group, Loader, ScrollArea, Stack, Text, Title } from '@mantine/core';
import { useNavigate } from '@tanstack/react-router';
import React, { useEffect } from 'react';

import type { GenericUserOrganizationClient } from '@/type-defs/auth';

import { Dialog } from '@/components/Dialog';
import { useFrachter } from '@/frachter/hooks/use-frachter';
import { useFrachterUser } from '@/hooks/use-frachter-user';
import { UrlRecoverySearchParam } from '@/type-defs/common';

import s from './OrganizationSelect.module.scss';

export const OrganizationSelect: React.FC<{
	autoLogin?: boolean;
	currentOrganizationId?: string;
	next?: UrlRecoverySearchParam['next'];
	orgSlug?: string;
}> = ({ autoLogin, currentOrganizationId, next, orgSlug }) => {
	const { user } = useFrachterUser({ user: true });

	const { setOrganization } = useFrachter();

	const [selectedOrgSlug, setSelectedOrgSlug] = React.useState(orgSlug);

	const [canLogin, setCanLogin] = React.useState(autoLogin);

	const navigate = useNavigate();

	useEffect(() => {
		async function activate() {
			if (!selectedOrgSlug) return;

			if (user.organization?.slug !== selectedOrgSlug) {
				await setOrganization(selectedOrgSlug);
				// await switchOrganization?.(selectedOrgSlug);
			}

			if (selectedOrgSlug) {
				if (next) {
					const url = new URL(next);
					await navigate({ replace: true, to: `${url.pathname}${url.search}` }).catch((err) => console.error(err));
				} else {
					await navigate({
						params: { slug: selectedOrgSlug },
						to: '/org/$slug',
					});
				}
			}
		}

		if (selectedOrgSlug && canLogin) {
			console.log('ACTIVATE');
			void activate();
		}
	}, [canLogin, navigate, next, selectedOrgSlug, setOrganization, user.organization?.slug]);

	const onSelect = (slug: string) => {
		setCanLogin(true);
		setSelectedOrgSlug(slug);
	};

	return (
		<Dialog
			style={{ alignSelf: 'center' }}
			leftSection={
				<Dialog.Info title="Account auswählen">
					<span>
						Melde Dich in einer Organisation an, um Deine Märkte und Bestellungen zu verwalten.
						<br />
						<br />
						<strong>Hinweis:</strong> Sobald Du angemeldet bist, kannst den Account jederzeit über die Navigation rechts
						oben wechseln.
					</span>
				</Dialog.Info>
			}
			rightSection={
				<Box className={s.root}>
					{user.organizationMemberships.length === 0 ? null : (
						<ScrollArea.Autosize className={s.listWrapper} mah={300} mx="auto">
							<Stack gap="xs" p="sm">
								{user.organizationMemberships.map((item) => {
									if (!item.slug) return null;

									return (
										<OrgItem
											key={item.id}
											active={user.organization?.id === item.id || item.slug === orgSlug}
											disabled={Boolean(autoLogin && !!selectedOrgSlug && item.slug !== selectedOrgSlug)}
											handleSelect={(slug) => onSelect(slug)}
											isCurrent={item.id === currentOrganizationId}
											loading={Boolean(canLogin && item.slug === selectedOrgSlug)}
											org={item}
										/>
									);
								})}
							</Stack>
						</ScrollArea.Autosize>
					)}
				</Box>
			}
		/>
	);
};

const OrgItem: React.FC<{
	active: boolean;
	disabled?: boolean;
	handleSelect: (slug: string) => void;
	isCurrent: boolean;
	loading?: boolean;
	org: GenericUserOrganizationClient;
}> = ({ active, disabled, handleSelect, isCurrent, loading, org }) => {
	return (
		<Box
			onClick={() => handleSelect(org.slug)}
			/* 	href={pathFn.apps({ slug: org.slug, app: constants.DEFAULT_ORGANIZATION_APP })} */
			style={{
				color: 'var(--mantine-color-text)',
				pointerEvents: loading || disabled ? 'none' : 'auto',
				textDecoration: 'none',
			}}
		>
			<Box className={s.orgItem}>
				<Stack gap={0}>
					<Group justify="space-between">
						<Stack data-selected={isCurrent} gap={2} p="sm">
							<Title order={6}>{org.name}</Title>
							<Text c="dimmed" size="sm">
								/{org.slug}
							</Text>
						</Stack>
						<Box className={s.imageContainer} mr="sm">
							<Box
								style={{
									backgroundColor: `black`,
									display: loading ? 'grid' : 'none',
									inset: 5,
									placeContent: 'center',
									position: 'absolute',
								}}
							>
								<Loader color="white" size="xs" type="oval" />
							</Box>
							<img alt={org.name} crossOrigin="anonymous" height={36} src={org.imageUrl} width={36} />
						</Box>
					</Group>
					{active && (
						<Box className={s.activeOrgItem}>
							<Text fw={500} py="xs" size="xs" ta="center">
								Du bist aktuell in {org.name} angemeldet
							</Text>
						</Box>
					)}
				</Stack>
			</Box>
		</Box>
	);
};
