import { Effect, Option } from 'effect';
import { InterruptedException } from 'effect/Cause';
import { isPresent } from 'ts-extras';
import { match } from 'ts-pattern';
import { RequestClientError, RequestGenericError, RequestInterruptedException, RequestServerError, } from './errors';
import { proxyRequest, unproxyRequest } from './proxy';
import { RequestConfigService } from './request-config-service';
import { validateResponse } from './validate-response';
// 3) Single implementation that TS matches to the correct overload
export function executeRequest(request, validatorsOrOptions, options) {
    let _validators;
    let _options = options;
    if (validatorsOrOptions &&
        ('baseUrl' in validatorsOrOptions || 'init' in validatorsOrOptions || 'useProxy' in validatorsOrOptions)) {
        _options = validatorsOrOptions;
    }
    else {
        _validators = validatorsOrOptions;
    }
    let clonedComputedRequest;
    return Effect.gen(function* () {
        const configService = Option.getOrNull(yield* Effect.serviceOption(RequestConfigService));
        // clone the request to avoid mutating the original request object
        // TODO: we can get rid of this as long as we don't mutate the headers, which we currently do below
        // just set them on the init object instead
        const _request = request.clone();
        const _headers = new Headers(_request.headers);
        /* abort controller handling */
        const abortController = new AbortController();
        const signals = [_request.signal, _options?.init?.signal, abortController.signal].filter(isPresent);
        const signal = AbortSignal.any(signals);
        let receivedResponse = false;
        /* finalizer to execute abort */
        yield* Effect.addFinalizer(() => Effect.gen(function* () {
            if (!receivedResponse) {
                abortController.abort();
            }
        }));
        /**
         * ensure headers are properly set
         * - request headers will be preserved
         * - init headers will **override** request headers
         *
         * if we don't manually merge headers, they would be appended in case they appear multiple time
         *
         * if we don't manually set headers and instead pass them to the new Request as init options,
         * it would override all headers from the original request object
         *
         * neither is what we want and it'd be too cumbersome to always manually merge headers before we execute
         * the request
         */
        const { headers, ...rest } = _options?.init ?? {};
        for (const [key, value] of Object.entries(headers ?? {})) {
            // console.log('set header', key, value);
            _headers.set(key, value);
        }
        let computedRequest = new Request(_request, { ...rest, headers: _headers, signal });
        const url = new URL(_request.url);
        /** ----------------------------------------------------------------------------------------------
         * undo proxy stuff
         * _______________________________________________________________________________________________ */
        const { isProxied, request: unproxiedRequest } = unproxyRequest(computedRequest);
        /** ----------------------------------------------------------------------------------------------
         * apply baseUrl if set
         * _______________________________________________________________________________________________ */
        if (_options?.baseUrl) {
            const base = new URL(_options.baseUrl);
            url.host = base.host;
            url.protocol = base.protocol;
            const basePathnameWithoutTrailingSlash = base.pathname.endsWith('/') ? base.pathname.slice(0, -1) : base.pathname;
            url.pathname = basePathnameWithoutTrailingSlash + url.pathname;
        }
        /** ----------------------------------------------------------------------------------------------
         * HANDLE GLOBAL REQUEST CONFIG (proxy and others)
         * _______________________________________________________________________________________________ */
        let _fetch = globalThis.fetch;
        if (_options?.useProxy && !configService) {
            throw new Error('RequestConfigService is required to use proxy');
        }
        /* override headers set in configService if they are not present in the given _options */
        if (configService) {
            const config = configService;
            if (config.fetch) {
                _fetch = config.fetch;
            }
            if (config.getHeaders) {
                const configHeaders = config.getHeaders;
                const headers = yield* Effect.promise(() => configHeaders());
                let optionsHeaders = _options?.init?.headers;
                if (optionsHeaders instanceof Headers) {
                    optionsHeaders = Object.fromEntries(optionsHeaders.entries());
                }
                for (const [key, value] of Object.entries(headers)) {
                    if (optionsHeaders && key in optionsHeaders) {
                        // skip
                    }
                    else {
                        computedRequest.headers.set(key, String(value));
                    }
                }
            }
            const proxy = config.proxy;
            /**
             * if we have explicitly set options.useProxy = false, then do NOT use the RequestConfigService proxy
             */
            if ((proxy?.enforce && _options?.useProxy !== false) || _options?.useProxy === true) {
                if (!proxy) {
                    throw new Error('RequestConfigService.proxy is required to use proxy');
                }
                let authorizationHeaderValue = null;
                if (proxy.getAuthorizationHeaderValue) {
                    authorizationHeaderValue = yield* proxy.getAuthorizationHeaderValue;
                }
                computedRequest = proxyRequest(computedRequest, {
                    authorizationHeaderValue,
                    baseUrl: proxy.url,
                });
            }
        }
        else if (isProxied) {
            computedRequest = unproxiedRequest;
        }
        else {
            computedRequest = new Request(url, computedRequest);
        }
        /** ----------------------------------------------------------------------------------------------
         * FETCH AND HANDLE REQUEST
         * _______________________________________________________________________________________________ */
        clonedComputedRequest = computedRequest.clone();
        const response = yield* Effect.tryPromise({
            catch: (error) => {
                if (error instanceof DOMException && error.name === 'AbortError') {
                    return new InterruptedException('Request aborted');
                }
                else if (error instanceof RequestClientError || error instanceof RequestServerError) {
                    return error;
                }
                return new RequestGenericError('Fatal request error', {
                    cause: error instanceof Error && error.cause ? error?.cause : error,
                    request: computedRequest,
                });
            },
            try: async () => {
                const response = await _fetch(computedRequest, {
                    // HINT: this is important to send cookies !
                    ...(typeof window !== 'undefined' && { credentials: 'include' }),
                });
                receivedResponse = true;
                if (response.status < 200 || response.status >= 400) {
                    const clonedResponse = response.clone();
                    if (response.status >= 400 && response.status < 500) {
                        const contentType = (response.headers.get('content-type') ?? '').toLowerCase();
                        let parsedBody = undefined;
                        try {
                            if (contentType.includes('application/json')) {
                                parsedBody = await clonedResponse.json();
                            }
                            else {
                                parsedBody = await clonedResponse.text();
                            }
                        }
                        catch {
                            //
                        }
                        const reason = match(response.status)
                            .with(401, () => 'unauthorized')
                            .with(403, () => 'unauthorized')
                            .with(429, () => 'usage_limit_exceeded')
                            .otherwise(() => 'unknown');
                        throw new RequestClientError('Request not successful.', {
                            parsedBody,
                            reason,
                            request: clonedComputedRequest,
                            response,
                        });
                    }
                    else if (response.status >= 500 && response.status < 600) {
                        throw new RequestServerError('Request not successful.', {
                            request: clonedComputedRequest,
                            response,
                        });
                    }
                }
                return response;
            },
        });
        /** ----------------------------------------------------------------------------------------------
         * if we don't have a schema, we just return the response
         * _______________________________________________________________________________________________ */
        return { response };
    }).pipe(Effect.mapError((e) => {
        if (e._tag === 'InterruptedException') {
            // here, it's ok to pass on the original request, as we don't know if for instance a proxy request has already been formed and fetched
            return new RequestInterruptedException('Request manually aborted', { request });
        }
        return e;
    }), Effect.andThen((v) => {
        if (_validators) {
            return validateResponse(v.response, _validators, clonedComputedRequest);
        }
        else {
            return v;
        }
    }), Effect.scoped);
}
