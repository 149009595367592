import { and, desc, eq, gte, ilike, lte, ne, or } from 'drizzle-orm';

import { DB } from './types';

export const getQueueItemsByStatus =
	(
		status: 'all' | 'success' | 'error',
		options?: {
			search?: string | undefined;
			dateRange?: { from: Date | undefined; to: Date | undefined } | undefined;
		},
	) =>
	(drizzle: DB) => {
		return drizzle.query.printjobs.findMany({
			orderBy: (t) => desc(t.createdAt),
			where: (t) => {
				let whereClause = and(
					...[
						options?.search ? or(ilike(t.eid, `%${options.search}%`), ilike(t.eid2, `%${options.search}%`)) : undefined,
						options?.dateRange?.from ? gte(t.createdAt, options?.dateRange?.from) : undefined,
						options?.dateRange?.to ? lte(t.createdAt, options?.dateRange?.to) : undefined,
					],
				);

				switch (status) {
					case 'success':
						whereClause = and(whereClause, eq(t.status, 'OK'));
						break;
					case 'error':
						whereClause = and(whereClause, ne(t.status, 'OK'));
						break;
				}

				return whereClause;
			},
		});
	};
