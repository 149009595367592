import { OrganizationProfile } from '@clerk/clerk-react';
import { Box, Button, Popover, Group, Text, Modal } from '@mantine/core';
import { useClickOutside, useHotkeys } from '@mantine/hooks';
import { IconChevronDown } from '@tabler/icons-react';
import { useState } from 'react';

import { GenericUserOrganizationClient } from '@/type-defs/auth';

import { OrganizationList } from './OrganizationList';
export const OrganizationMenu: React.FC<{
	currentOrganization?: GenericUserOrganizationClient | null;
	memberships: GenericUserOrganizationClient[];
}> = ({ currentOrganization, memberships }) => {
	const [popoverVisible, setPopoverVisible] = useState(false);
	const [isSettingsOpen, setIsSettingsOpen] = useState(false);

	// see https://developer.mozilla.org/en-US/docs/Web/API/UI_Events/Keyboard_event_key_values
	useHotkeys([['Escape', () => setPopoverVisible(false)]]);

	return (
		<>
			<Box>
				<Popover
					closeOnClickOutside={false}
					closeOnEscape
					keepMounted={false}
					offset={4}
					opened={popoverVisible}
					position="bottom-end"
					shadow="clerk"
					width={200}
				>
					<Popover.Target>
						{!currentOrganization ? (
							<Button color="gray" size="xs" variant="light">
								<Text size="sm">Keine Organisation aktiv</Text>
							</Button>
						) : (
							<Button
								c="var(--mantine-color-text)"
								px={0}
								variant="transparent"
								onMouseDown={() => {
									setPopoverVisible(!popoverVisible);
								}}
							>
								<Group gap="xs">
									<img
										alt={currentOrganization.name}
										crossOrigin="anonymous"
										height={22}
										src={currentOrganization.imageUrl}
										style={{ borderRadius: 4 }}
										width={22}
									/>
									<Text size="sm">{currentOrganization.name}</Text>
									<IconChevronDown size="14" />
								</Group>
							</Button>
						)}
					</Popover.Target>
					<Popover.Dropdown
						maw={350}
						miw={320}
						p={0}
						styles={{
							dropdown: {
								borderRadius: 8,
							},
						}}
					>
						<ClickOutsideWrapper isVisible={popoverVisible} onClickOutside={() => setPopoverVisible(false)}>
							<OrganizationList
								currentOrganization={currentOrganization ?? null}
								memberships={memberships}
								onOpenSettings={() => {
									setPopoverVisible(false);
									setIsSettingsOpen(true);
								}}
								onSelect={() => {
									setPopoverVisible(false);
								}}
							/>
						</ClickOutsideWrapper>
					</Popover.Dropdown>
				</Popover>
			</Box>

			<Modal
				centered
				closeOnClickOutside
				closeOnEscape
				keepMounted
				onClose={() => setIsSettingsOpen(false)}
				opened={isSettingsOpen}
				shadow={''}
				size="auto"
				styles={{
					body: { padding: 0 },
					content: { background: 'transparent' },
					header: { display: 'none' },
				}}
			>
				<OrganizationProfile routing="hash" />
			</Modal>
		</>
	);
};

const ClickOutsideWrapper: React.FC<React.PropsWithChildren<{ isVisible: boolean; onClickOutside: () => void }>> = ({
	children,
	isVisible,
	onClickOutside,
}) => {
	const ref = useClickOutside(() => {
		if (isVisible) {
			onClickOutside();
		}
	});

	return (
		<Box ref={ref} maw={350} miw={320}>
			{children}
		</Box>
	);
};
