import React from 'react';
import { StoreApi, useStore, createStore } from 'zustand';
import { persist, createJSONStorage, devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { withStorageDOMEvents } from './store-utils';

export namespace IOrganizationSyncStore {
	export interface State {
		isPgLiteReplOpen: boolean;
		isReactQueryDevtoolsInProductionEnabled: boolean;
		isSandboxEnabled: boolean;
	}

	export interface Actions {
		setSandboxEnabled: (isSandboxEnabled: boolean) => void;
		togglePgLiteRepl: () => void;
		toggleReactQueryDevtoolsInProduction: (isEnabled: boolean) => void;
	}
}

const getInitialState: () => IOrganizationSyncStore.State = () => ({
	isPgLiteReplOpen: false,
	isReactQueryDevtoolsInProductionEnabled: false,
	isSandboxEnabled: false,
});

const OrganizationSyncStoreContext = React.createContext<StoreApi<
	IOrganizationSyncStore.State & IOrganizationSyncStore.Actions
> | null>(null);

interface OrganizationSyncStoreProps {
	frachterClientId: string;
}

/**
 *
 * WARNING: this store synchronizes across browser tabs !
 */
export const createOrganizationSyncStore = (props: OrganizationSyncStoreProps) =>
	createStore<IOrganizationSyncStore.Actions & IOrganizationSyncStore.State>()(
		devtools(
			persist(
				immer((set) => ({
					...getInitialState(),

					setSandboxEnabled: (isSandboxEnabled: boolean) => {
						set(
							(s) => {
								console.log('setSandboxEnabled', isSandboxEnabled);
								s.isSandboxEnabled = isSandboxEnabled;
							},
							false,
							'setSandboxEnabled',
						);
					},
					togglePgLiteRepl: () => {
						set(
							(s) => {
								s.isPgLiteReplOpen = !s.isPgLiteReplOpen;
							},
							false,
							'togglePgLiteRepl',
						);
					},
					toggleReactQueryDevtoolsInProduction: (isEnabled: boolean) => {
						set(
							(s) => {
								s.isReactQueryDevtoolsInProductionEnabled = isEnabled;
							},
							false,
							'toggleReactQueryDevtoolsInProduction',
						);
					},
				})),
				{ name: `organization-sync-store/${props.frachterClientId}`, storage: createJSONStorage(() => localStorage) },
			),
			{
				name: `frachter/${props.frachterClientId}`,
				store: `organization-sync`,
			},
		),
	);

export const OrganizationSyncStoreProvider: React.FC<{ children: React.ReactNode; frachterClientId: string }> = ({
	children,
	frachterClientId,
}) => {
	const [store, setStore] = React.useState(createOrganizationSyncStore({ frachterClientId }));

	const [clientId, setClientId] = React.useState(frachterClientId);

	React.useEffect(() => {
		if (clientId !== frachterClientId) {
			setStore(createOrganizationSyncStore({ frachterClientId }));
			setClientId(frachterClientId);
		}
	}, [clientId, frachterClientId, store]);

	/**
	 * this adds localStorage synchronization across tabs to the store
	 */
	React.useEffect(() => {
		const cleanup = withStorageDOMEvents(store);
		return cleanup;
	}, [store]);

	return <OrganizationSyncStoreContext.Provider value={store}>{children}</OrganizationSyncStoreContext.Provider>;
};

export function useOrganizationSyncStore<T>(
	selector: (state: IOrganizationSyncStore.State & IOrganizationSyncStore.Actions) => T,
): T {
	const store = React.use(OrganizationSyncStoreContext);
	if (!store) throw new Error('Missing OrganizationSyncStoreProvider in the tree');
	return useStore(store, selector);
}
