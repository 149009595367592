import type { PaginationState } from '@tanstack/table-core';

import { and, eq, sql } from 'drizzle-orm';
import React from 'react';
import { useDeepCompareEffect } from 'use-deep-compare';

import type { IBundleParams, IRowType } from './types';

import { dbs } from '@/drizzle/pglite';
import { useDrizzleLive } from '@/hooks/use-drizzle-live';

/**
 * TODO: improve by storing the selection not inside memory
 * but in a snapshot table that keeps only the initially selected
 * skus / eans
 */
export const useBundleListQuery = (props: {
	pagination: PaginationState;
	params: IBundleParams;
	queryKey: string[];
}) => {
	const [currentKeyHashList, setCurrentKeyHashList] = React.useState<string[]>([]);
	const [aggregatedKeyHashList, setAggregatedKeyHashList] = React.useState<string[]>([]);
	const [hasUpdate, setHasUpdate] = React.useState(false);
	const [queryKey, setQueryKey] = React.useState<string[]>(props.queryKey);

	/**
	 * run a live query to get the current data
	 */
	const orderBundlesLive = useDrizzleLive((db) => {
		// Step 1: Define alias for the array group key (serialize array to text)
		const skuListKey = sql<string>`array_to_string (${dbs.marketOrdersLocal.skuList}, ',')`.as('id');
		const eanListKey = sql<string>`array_to_string (${dbs.marketOrdersLocal.eanList}, ',')`.as('id');

		const id = props.params.bundleKeyReference === 'sku' ? skuListKey : eanListKey;

		return db
			.select({
				count: sql<number>`count(*)`.as('count'),
				id,
				// eanListKey,
				minCreatedAt: sql<Date>`min(${dbs.marketOrdersLocal.createdAt})`.as('minCreatedAt'),
				orderIds: sql<string[]>`array_agg (${dbs.marketOrdersLocal.orderId})`.as('orderIds'),
				// skuList: dbs.marketOrdersLocal.skuList,
				// skuListKey,
			})
			.from(dbs.marketOrdersLocal)
			.where(
				and(
					eq(dbs.marketOrdersLocal.status, 'UNSHIPPED'),
					eq(dbs.marketOrdersLocal.marketId, props.params.marketId),
					eq(dbs.marketOrdersLocal.merchantId, props.params.merchantId),
					sql`array_length (${dbs.marketOrdersLocal.skuList}, 1) > 0`,
				),
			)
			.groupBy(id)
			.orderBy(sql`min(${dbs.marketOrdersLocal.createdAt}) ASC`);
	});

	/**
	 * initially fill the current key hash list (once)
	 * and update the aggregated key hash list (always)
	 */
	useDeepCompareEffect(() => {
		const keyHashList = orderBundlesLive.data?.map((bundle) => bundle.id);

		if (keyHashList) {
			setCurrentKeyHashList((previous) => {
				if (previous.length === 0) {
					return keyHashList;
				}
				return previous;
			});

			setAggregatedKeyHashList((previous) => {
				const set = new Set(previous);

				keyHashList.forEach((key) => {
					set.add(key);
				});

				return Array.from(set);
			});
		}
	}, [orderBundlesLive.data]);

	/**
	 * inform about update when we detect changes
	 */
	React.useEffect(() => {
		if (currentKeyHashList.length > 0 && currentKeyHashList.length !== aggregatedKeyHashList.length) {
			setHasUpdate(true);
		}
	}, [currentKeyHashList, aggregatedKeyHashList]);

	/**
	 * refresh the data when the query key changes
	 * and the data has updated
	 */
	React.useEffect(
		function refresh() {
			if (queryKey !== props.queryKey && hasUpdate) {
				const keyHashList = orderBundlesLive.data?.map((bundle) => bundle.id);
				setHasUpdate(false);
				setCurrentKeyHashList(keyHashList);
				setAggregatedKeyHashList(keyHashList);
				setQueryKey(props.queryKey);
			}
		},
		[orderBundlesLive.data, props.queryKey, queryKey, hasUpdate],
	);

	/**
	 * merge the stable data
	 * set count to 0 and date to null
	 * in case a bundle is no longer processable
	 */
	const mergedData = React.useMemo(() => {
		const kv = new Map<string, IRowType>(orderBundlesLive.data.map((bundle) => [bundle.id, bundle]));

		return currentKeyHashList.map((hash) => {
			const bundle = kv.get(hash);

			if (bundle) {
				return bundle;
			}

			// inform that we should refresh the data
			setHasUpdate((prev) => {
				if (prev !== true) {
					return true;
				}
				return prev;
			});

			return {
				count: 0,
				id: hash,
				minCreatedAt: null,
				orderIds: [],
			};
		});
	}, [currentKeyHashList, orderBundlesLive.data]);

	/**
	 * paginate the data
	 */
	const paginatedData = React.useMemo(() => {
		return mergedData.slice(
			props.pagination.pageIndex * props.pagination.pageSize,
			(props.pagination.pageIndex + 1) * props.pagination.pageSize,
		);
	}, [mergedData, props.pagination]);

	return {
		data: paginatedData,
		hasUpdate,
		totalRowCount: mergedData.length,
	};
};
