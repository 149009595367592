import { createInsertSchema, createSelectSchema } from 'drizzle-zod';
import * as tables from './tables';
export const zod = {
    carrierLabels: {
        insert: createInsertSchema(tables.carrierLabels),
        select: createSelectSchema(tables.carrierLabels),
    },
    marketOrderAddresses: {
        insert: createInsertSchema(tables.marketOrderAddresses),
        select: createSelectSchema(tables.marketOrderAddresses),
    },
    marketOrders: {
        insert: createInsertSchema(tables.marketOrders),
        select: createSelectSchema(tables.marketOrders),
    },
    marketOrdersLocal: {
        insert: createInsertSchema(tables.marketOrdersLocal),
        select: createSelectSchema(tables.marketOrdersLocal),
    },
    printjobs: {
        insert: createInsertSchema(tables.printjobs),
        select: createSelectSchema(tables.printjobs),
    },
    products: {
        insert: createInsertSchema(tables.products),
        select: createSelectSchema(tables.products),
    },
};
