'use client';

import { ActionIcon, Box, Divider, Tooltip } from '@mantine/core';
import {
	IconBug,
	IconDashboard as IconDashboard,
	IconTag as IconLabels,
	IconPackage as IconQuickship,
	IconSettings,
	IconRadioactiveFilled as IconSuperuser,
	IconCirclePlus as IconNewOrder,
	IconBuildingStore,
} from '@tabler/icons-react';
import { Link, useRouterState } from '@tanstack/react-router';
import clsx from 'clsx';

import { useFrachterUser } from '@/hooks/use-frachter-user';

import { SystemControlIndicator } from './components/SystemControlIndicator';
import s from './SideBarApps.module.scss';

export function SideBarApps() {
	const { user } = useFrachterUser();

	const router = useRouterState();

	const segments = router.location.pathname.split('/');

	const appSegment = segments.at(4);

	if (!user?.organization?.slug) {
		// console.warn(`Cannot render organization sidebar without user having a current organization.`);
		return null;
	}

	const { slug } = user.organization;

	const BUTTON_SIZE = 'lg';
	const ICON_SIZE = 20;
	const STROKE_WIDTH = 1.8;

	return (
		<Box className={clsx(s.root)} pb="md">
			<Box className={clsx(s.section)}>
				<Tooltip label="Bestellungen" position="right">
					<Link params={{ slug }} to="/org/$slug/quickship">
						<ActionIcon component={'div'} size={BUTTON_SIZE} style={{ outline: 'none' }} variant="subtle">
							<IconQuickship
								color={appSegment === 'quickship' ? 'var(--mantine-color-primary-5)' : 'var(--mantine-color-text)'}
								size={ICON_SIZE}
								stroke={STROKE_WIDTH}
							/>
						</ActionIcon>
					</Link>
				</Tooltip>

				<Tooltip label="Versandetiketten" position="right">
					<Link params={{ slug }} to="/org/$slug/labels">
						<ActionIcon component={'div'} size={BUTTON_SIZE} variant="subtle">
							<IconLabels
								color={appSegment === 'labels' ? 'var(--mantine-color-primary-5)' : 'var(--mantine-color-text)'}
								size={ICON_SIZE}
								stroke={STROKE_WIDTH}
							/>
						</ActionIcon>
					</Link>
				</Tooltip>

				<Tooltip label="Produkte" position="right">
					<Link params={{ slug }} to="/org/$slug/products">
						<ActionIcon component={'div'} size={BUTTON_SIZE} variant="subtle">
							<IconBuildingStore
								color={appSegment === 'products' ? 'var(--mantine-color-primary-5)' : 'var(--mantine-color-text)'}
								size={ICON_SIZE}
								stroke={STROKE_WIDTH}
							/>
						</ActionIcon>
					</Link>
				</Tooltip>

				<Divider orientation="horizontal" w="66%" />

				<Tooltip label="Neue Bestellung anlegen" position="right">
					<Link params={{ slug }} to="/org/$slug/labels">
						<ActionIcon component={'div'} size={BUTTON_SIZE} variant="subtle">
							<IconNewOrder
								color={appSegment === 'history' ? 'var(--mantine-color-primary-5)' : 'var(--mantine-color-text)'}
								size={ICON_SIZE}
								stroke={STROKE_WIDTH}
							/>
						</ActionIcon>
					</Link>
				</Tooltip>

				<Divider orientation="horizontal" w="66%" />

				<Tooltip label="Dashboard" position="right">
					<Link params={{ slug }} to="/org/$slug/dashboard">
						<ActionIcon component={'div'} size={BUTTON_SIZE} variant="subtle">
							<IconDashboard
								color={appSegment === 'dashboard' ? 'var(--mantine-color-primary-5)' : 'var(--mantine-color-text)'}
								size={ICON_SIZE}
								stroke={STROKE_WIDTH}
							/>
						</ActionIcon>
					</Link>
				</Tooltip>

				{/* <Tooltip label="Logs" position="right">
					<Link params={{ slug }} to="/org/$slug/logs">
						<ActionIcon component={'div'} size={BUTTON_SIZE} variant="subtle">
							<IconBug
								color={appSegment === 'logs' ? 'var(--mantine-color-primary-5)' : 'var(--mantine-color-text)'}
								size={ICON_SIZE}
								stroke={STROKE_WIDTH}
							/>
						</ActionIcon>
					</Link>
				</Tooltip> */}

				{/* {user.isSuperuser && (
					<Tooltip label="SuperUser" position="right">
						<Link params={{ slug }} to="/org/$slug/playground">
							<ActionIcon component={'div'} size={BUTTON_SIZE} variant="subtle">
								<IconSuperuser
									color={appSegment === 'playground' ? 'var(--mantine-color-primary-5)' : 'var(--mantine-color-text)'}
									size={ICON_SIZE}
									stroke={STROKE_WIDTH}
								/>
							</ActionIcon>
						</Link>
					</Tooltip>
				)} */}

				<Divider orientation="horizontal" w="66%" />

				<Link params={{ slug }} to="/org/$slug/settings">
					<ActionIcon component={'div'} size={BUTTON_SIZE} variant="subtle">
						<IconSettings color="var(--mantine-color-text)" size={ICON_SIZE} stroke={1.5} />
					</ActionIcon>
				</Link>
			</Box>

			<Box className={s.section}>
				{/* <Divider orientation="horizontal" /> */}
				{/* <SystemControlIndicator /> */}
			</Box>
		</Box>
	);
}
