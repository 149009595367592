import type { PaginationState } from '@tanstack/table-core';

import { and, arrayContains, asc, eq, inArray } from 'drizzle-orm';
import React from 'react';

import type { IBundleParams } from '../../bundle-list-table/types';

import { dbs } from '@/drizzle/pglite';
import { useDrizzleLive } from '@/hooks/use-drizzle-live';

export const useBundleDetailsQuery = (props: {
	params: IBundleParams & { bundleKey: string };
	pagination: PaginationState;
	queryKey: string[];
}) => {
	const [currentOrderIds, setCurrentOrderIds] = React.useState<string[]>([]);
	const [aggregatedOrderIds, setAggregatedOrderIds] = React.useState<string[]>([]);
	const [hasUpdate, setHasUpdate] = React.useState(false);
	const [queryKey, setQueryKey] = React.useState<string[]>(props.queryKey);

	const orderDetilsLive = useDrizzleLive((db) => {
		//

		// select all orders for the current bundle, ordered by createdAt date

		const keyList = props.params.bundleKey.split(',');

		return db.query.marketOrdersLocal.findMany({
			orderBy: [asc(dbs.marketOrdersLocal.createdAt)],
			where: and(
				eq(dbs.marketOrdersLocal.marketId, props.params.marketId),
				eq(dbs.marketOrdersLocal.merchantId, props.params.merchantId),
				eq(dbs.marketOrdersLocal.status, 'UNSHIPPED'),
				props.params.bundleKeyReference === 'sku'
					? arrayContains(dbs.marketOrdersLocal.skuList, keyList)
					: arrayContains(dbs.marketOrdersLocal.eanList, keyList),
			),
		});
	});

	console.log(props.params);

	return {
		data: orderDetilsLive.data.map((order) => ({
			...order,
			id: `${order.marketId}:${order.merchantId}:${order.orderId}`,
		})),
		hasUpdate,
		totalRowCount: orderDetilsLive.data.length,
	};
};
