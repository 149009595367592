import type { ColumnFiltersState, PaginationState, SortingState } from '@tanstack/react-table';

import type { IRowType } from './types';

import { makeProductsData } from '@/__fixtures__/makeProductData';
import { usePaginatedQuery } from '@/hooks/use-paginated-query';

const data1 = makeProductsData(8);
const data2 = makeProductsData(200);

const data = [data1, data2];

export const useDataQuery = (props: {
	pagination: PaginationState;
	dataIndex: number;
	search?: string;
	sort?: SortingState[number];
	filter?: ColumnFiltersState;
}) => {
	const queryKey = ['products', props.dataIndex, props.search, props.filter, props.sort];

	const { paginatedQueryKey, query } = usePaginatedQuery<{ items: IRowType[]; total: number }, Error>({
		...props.pagination,
		// IMPORTANT: also set this to 0 to avoid returning stale total counts
		gcTime: 0,
		// never cache! as we fetch data from local db
		networkMode: 'always',
		queryFn: () => {
			return new Promise((resolve) => {
				const allData = data[props.dataIndex];

				let filteredData = !props.search
					? allData
					: allData.filter(
							(item) =>
								item.ean.toLowerCase().includes(props.search?.toLowerCase() ?? '') ||
								item.sku.toLowerCase().includes(props.search?.toLowerCase() ?? '') ||
								item.title.toLowerCase().includes(props.search?.toLowerCase() ?? ''),
						);

				if (props.filter) {
					const filter = props.filter;
					const statusFilter = filter.find((filter) => filter.id === 'status');

					if (statusFilter) {
						const filterValue = statusFilter.value as { active?: boolean; inactive?: boolean; archived?: boolean };
						const possibilities = Object.entries(filterValue)
							.filter(([_, isActive]) => isActive === true)
							.map(([key]) => key);

						filteredData = filteredData.filter((item) => {
							return possibilities.includes(item.status);
						});
					}
				}

				let sortedData = filteredData;

				if (props.sort) {
					const sort = props.sort;
					sortedData = filteredData.toSorted((a, b) => {
						if (sort.id in b && sort.id in a) {
							const id = sort.id as keyof IRowType;

							// Compare values based on their types
							if (typeof a[id] === 'number' && typeof b[id] === 'number') {
								return sort.desc ? b[id] - a[id] : a[id] - b[id];
							} else if (typeof a[id] === 'string' && typeof b[id] === 'string') {
								return sort.desc ? b[id].localeCompare(a[id]) : a[id].localeCompare(b[id]);
							}

							return 0;
						}

						return 0;
					});
				}

				// slice paginated data last!
				const slicedData = sortedData.slice(
					props.pagination.pageIndex * props.pagination.pageSize,
					(props.pagination.pageIndex + 1) * props.pagination.pageSize,
				);

				setTimeout(() => {
					resolve({ items: slicedData, total: props.filter || props.search ? sortedData.length : allData.length });
				}, 1_000);
			});
		},
		queryKey,
	});

	return {
		paginatedQueryKey,
		query,
	};
};
