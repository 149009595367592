import { SpaceMember } from '@ably/spaces';
import { Box, Button, Divider, Group, Paper, Stack, Text, Title } from '@mantine/core';
import ably from 'ably';
import React, { useSyncExternalStore } from 'react';

import { RealtimeClient } from './RealtimeClient';
import { LockAcquisitionHandler } from './types';

/**
 *
 * TODO:
 * we need to be able to lock URLs !!!
 * and when we have a reload, we should be able to re-acquire the lock
 * or show a message that we lost the lock in case someone else is locking the same item
 */
export function Page() {
	const [realtimeClient] = React.useState(RealtimeClient.getInstance());

	const { isSpaceReady } = useRealtimeClientStatus(realtimeClient);

	const handleLock = (id: string) => {
		const onDone: LockAcquisitionHandler = (status) => console.log('acqusition done, status:', status);

		realtimeClient.lockItem(id, onDone).catch((err) => {
			console.error(`Could not lock item ${id}`, err);
		});
	};

	const handleUnlock = (id: string) => {
		realtimeClient.unlockItem(id).catch((err) => {
			console.error(`Could not unlock item ${id}`, err);
		});
	};

	return !isSpaceReady ? (
		<Box>Loading...</Box>
	) : (
		<Box m="lg">
			<Stack>
				<ConnectionStateIndicator realtimeClient={realtimeClient} />
				<LockableItem id="dummy1" onLock={handleLock} onUnlock={handleUnlock} realtimeClient={realtimeClient} />
			</Stack>

			<Group>
				<Button
					onClick={() =>
						void realtimeClient.sendMessage({
							data: {
								friend: 'world',
							},
							name: 'hello',
						})
					}
				>
					Send message
				</Button>
			</Group>

			<Stack my="lg">
				<Title order={3}>Members</Title>
				<MembersList realtimeClient={realtimeClient} />
			</Stack>

			<Stack my="lg">
				<Title order={3}>Locks</Title>
				<LocksList realtimeClient={realtimeClient} />
			</Stack>
		</Box>
	);
}

function ConnectionStateIndicator({ realtimeClient }: { realtimeClient: RealtimeClient }) {
	const connectionState = useSyncExternalStore(
		realtimeClient.store.subscribe,
		() => realtimeClient.store.getSnapshot().connectionState,
	);

	return <Text fw="bold">{connectionState}</Text>;
}

function LockableItem({
	id,
	onLock,
	onUnlock,
	realtimeClient,
}: {
	realtimeClient: RealtimeClient;
	id: string;
	onLock: (id: string) => void;
	onUnlock: (id: string) => void;
}) {
	const lock = useSyncExternalStore(realtimeClient.store.subscribe, () =>
		realtimeClient.store.getSnapshot().locks.all.find((lock) => lock.id === id),
	);

	const isLocked = lock?.status === 'locked';
	const hasLock =
		isLocked &&
		lock.member.clientId === realtimeClient.client.auth.clientId &&
		realtimeClient.client.connection.id === lock.member.connectionId;

	console.log({
		client: realtimeClient,
		lockMember: lock?.member,
	});

	return (
		<Paper
			m="lg"
			p="lg"
			style={{
				border: '1px solid color-mix(in srgb, var(--mantine-color-text) 50%, transparent)',
				borderRadius: 6,
				display: 'grid',
			}}
		>
			<Stack>
				<Title order={3}>My Lockable Item</Title>
				<Text>Status: {lock?.status === 'locked' ? '🔒 Locked' : '🟢 Unlocked'}</Text>
				<Group>
					<Text>Locked By: </Text>
					<Text ff="monospace">
						{lock?.member.clientId}:{lock?.member.connectionId}
					</Text>
				</Group>
				<Group>
					<Button /* disabled={isLocked}  */ loading={lock?.status === 'pending'} onClick={() => onLock(id)}>
						Lock
					</Button>
					<Button disabled={!isLocked || !hasLock} loading={lock?.status === 'pending'} onClick={() => onUnlock(id)}>
						Unlock
					</Button>
					<Button disabled={!isLocked || hasLock} loading={lock?.status === 'pending'} onClick={() => onLock(id)}>
						Try to take lock when it is taken
					</Button>
				</Group>
				<Stack>
					<Title order={4}>DEBUG</Title>
					<Group>
						<Button loading={lock?.status === 'pending'} onClick={() => onLock(id)}>
							Lock
						</Button>
						<Button loading={lock?.status === 'pending'} onClick={() => onUnlock(id)}>
							Unlock
						</Button>
						<Button loading={lock?.status === 'pending'} onClick={() => onLock(id)}>
							Try to take lock when it is taken
						</Button>
					</Group>
				</Stack>
			</Stack>
		</Paper>
	);
}

const LocksList = ({ realtimeClient }: { realtimeClient: RealtimeClient }) => {
	const locks = useSyncExternalStore(realtimeClient.store.subscribe, () => realtimeClient.store.getSnapshot().locks);

	return (
		<Box style={{ display: 'grid', gridTemplateColumns: 'repeat(5, auto)' }}>
			{locks.all.map((lock) => (
				<React.Fragment key={lock.id}>
					<Text ff="monospace" size="sm">
						{lock.id}
					</Text>
					<Text ff="monospace" size="sm">
						{lock.status}
					</Text>
					<Text ff="monospace" size="sm">
						{new Date(lock.timestamp).toLocaleString()}
					</Text>
					<Text ff="monospace" size="sm">
						{lock.member.clientId} : {lock.member.connectionId}
					</Text>
				</React.Fragment>
			))}
		</Box>
	);
};

const MembersList = ({ realtimeClient }: { realtimeClient: RealtimeClient }) => {
	const members = useSyncExternalStore(
		realtimeClient.store.subscribe,
		() => realtimeClient.store.getSnapshot().members,
	);

	console.log(members.others);

	return (
		<Stack>
			<Box style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)' }}>
				{members.self && <MembersListItem label="self" member={members.self} />}
			</Box>
			<Divider />
			<Box style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)' }}>
				{members.others.map((member) => (
					<MembersListItem key={`${member.clientId}-${member.connectionId}`} label="other" member={member} />
				))}
			</Box>
		</Stack>
	);
};

const MembersListItem = ({ label, member }: { member: SpaceMember; label: string }) => {
	return (
		<>
			<Box>{member.isConnected ? '🟢' : '🔴'}</Box>
			<Text size="sm">{label}</Text>
			<Text ff="monospace" size="sm">
				{member.clientId}
			</Text>
			<Text ff="monospace" size="sm">
				{member.connectionId}
			</Text>
		</>
	);
};

function useRealtimeClientStatus(client: RealtimeClient) {
	const state = useSyncExternalStore(client.store.subscribe, client.store.getSnapshot);

	return state;
}
