import React from 'react';

/**
 * Hook to expose a ref (and a potential second clickableRef)
 * that will forward clicks to the parent element
 *
 * This is useful for components that need to be rendered as a child element
 * but still need to receive clicks from the parent element (e.g. inside a table cell)
 *
 * For some components that expose a `ref` that is not the root element (e.g. for Checkboxes)
 * the clickableRef can be used to differentiate between the root element to select the parent of it
 * and the target element to select clickable elements inside the root element
 */
export const useForwardParentClick = <
	TElement extends HTMLElement = HTMLElement,
	TTarget extends HTMLElement = TElement,
>() => {
	const rootRef = React.useRef<TElement>(null);
	const clickableRef = React.useRef<TTarget>(null);

	React.useEffect(() => {
		const finalRef = clickableRef.current ?? rootRef.current;
		const previousPointerEvents = finalRef?.style.pointerEvents;

		const abortController = new AbortController();
		if (rootRef.current && finalRef) {
			const parentElement = rootRef.current.parentElement;
			if (parentElement) {
				finalRef.style.pointerEvents = 'none';

				parentElement.style.cursor = rootRef.current.style.cursor;
				parentElement.addEventListener(
					'click',
					() => {
						finalRef?.click();
					},
					{ signal: abortController.signal },
				);
			}
		}

		return () => {
			abortController.abort();
			if (finalRef && previousPointerEvents) {
				finalRef.style.pointerEvents = previousPointerEvents;
			}
		};
	}, []);

	return [rootRef, clickableRef];
};
