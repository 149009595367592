import type { Header, Table } from '@tanstack/react-table';

import { useDebouncedCallback } from '@mantine/hooks';
import React from 'react';

import { COLUMN_SIZE_RESET_VALUE } from './contants';
import tableStyles from './table.module.scss';

const getColumnSizeVarName = (columnId: string) => {
	return {
		current: `--table-column-${columnId}-width`,
		max: `--table-column-${columnId}-max-width`,
		min: `--table-column-${columnId}-min-width`,
	};
};

export const useFeatureResize = (table: Table<any>, ref: React.RefObject<HTMLElement | null>) => {
	const headers = table.getFlatHeaders();

	const styleVars = React.useMemo(() => {
		const styleVars: Record<string, string> = {};
		for (const header of headers) {
			const { size } = header.column.columnDef;

			// console.log('size', size, header.column.getSize(), size);

			const varNames = getColumnSizeVarName(header.column.id);

			if (size && size > 0) {
				styleVars[varNames.current] = typeof size === 'number' ? `${size}px` : 'auto';
			} else {
				delete styleVars[varNames.current];
			}
		}

		return styleVars;
	}, [headers]);

	const columnSizing = table.getState().columnSizing;
	const columnSizingInfo = table.getState().columnSizingInfo;
	const isResizing = table.getState().columnSizingInfo.isResizingColumn;

	React.useLayoutEffect(() => {
		if (!ref.current) return;

		const element = ref.current;
		const styleElement = document.createElement('style');

		const styleContentItems: string[] = [];

		for (const header of headers) {
			const { maxSize, minSize } = header.column.columnDef;

			const varNames = getColumnSizeVarName(header.column.id);

			styleContentItems.push(`
				[data-column-id="${header.column.id}"] {
					min-width: var(${varNames.current}, ${typeof minSize === 'number' ? `${minSize}px` : 0});
					max-width: var(${varNames.current}, ${typeof maxSize === 'number' ? `${maxSize}px` : 'auto'});
				}
			`);
		}

		styleElement.innerHTML = /*css*/ `
			@scope {
				:scope {
					${styleContentItems.join('\n')}
				}
			}
		`;

		element.appendChild(styleElement);

		return () => {
			element?.removeChild(styleElement);
		};
	}, [headers, ref]);

	// add a second
	const onResize = React.useCallback(
		(columnId: string, value: string | number) => {
			if (!ref.current) return;

			const varNames = getColumnSizeVarName(columnId);

			if (typeof value === 'string') {
				ref.current.style.setProperty(varNames.current, value);
			} else {
				if (value === COLUMN_SIZE_RESET_VALUE) {
					ref.current.style.removeProperty(varNames.current);
				} else {
					ref.current.style.setProperty(varNames.current, `${value}px`);
				}
			}
		},
		[ref],
	);

	React.useEffect(() => {
		const hasIsResizingClass = ref.current?.classList.contains(tableStyles.isResizing);
		if (!isResizing) {
			if (hasIsResizingClass) {
				ref.current?.classList.remove(tableStyles.isResizing);
			}
		} else {
			if (!hasIsResizingClass) {
				ref.current?.classList.add(tableStyles.isResizing);
			}
		}
	}, [isResizing, ref]);

	/**
	 * we use a diff between all ids and those existing in columnSizing
	 * to find out which columns need to be reset
	 *
	 * our resize observer should then restore the old value in the state
	 */
	React.useEffect(
		function resetSize() {
			const headerIds = new Set(headers.map((header) => header.column.id));
			const columnSizingKeys = new Set(Object.keys(columnSizing));
			const columnsToReset = headerIds.difference(columnSizingKeys);

			for (const header of headers) {
				if (columnsToReset.has(header.column.id)) {
					onResize(header.column.id, header.column.columnDef.size ?? -1);
				}
			}
		},
		[columnSizing, headers, onResize],
	);

	React.useEffect(() => {
		if (columnSizingInfo?.isResizingColumn) {
			const id = columnSizingInfo.isResizingColumn;

			// FIXME: Inaccurate dragging of table column resizer
			// Due to the fact, that initially all columns have `flex: 1` set,
			// dragging kinda goes 2x or even 3x, dependening on the surrounding columns
			// We'd need a proper algorithm to handle these cases.

			// onResize(id, `calc(${columnSizingInfo.startSize}px + (${columnSizingInfo.deltaOffset}px * 2))`);
			onResize(id, `calc(${columnSizingInfo.startSize}px + ${columnSizingInfo.deltaOffset}px)`);
		}
	}, [columnSizingInfo, onResize]);

	return {
		styleVars,
	};
};

/**
 * used to track the size of a header cell and update the table state
 */
export const useResizeHeadColumnWatcher = (header: Header<any, unknown>) => {
	// TODO: Get rid of this hook and use a mutation observer instead for `[data-cell-type="head"]
	// the `useResizeColumns` hook should also be given a ref to the header rows container
	// and then check initially for all `[data-cell-type="head"]` elements and then observe
	// mutations on the DOM element and keep adding and removing listeners

	const ref = React.useRef<HTMLDivElement>(null);
	const [size, setSize] = React.useState<ResizeObserverSize>();

	const [debounceMs, setDebounceMs] = React.useState(0);

	const debouncedUpdateTableState = useDebouncedCallback((width: number) => {
		header.getContext().table.setState((prev) => {
			// console.log('debounced', header.column.id, width);
			return {
				...prev,
				columnSizing: {
					...prev.columnSizing,
					[header.column.id]: width,
				},
			};
		});

		if (debounceMs === 0) {
			setDebounceMs(500);
		}
	}, debounceMs);

	/**
	 * HINT: we've implemented the resize observing ourself,
	 * because mantine's `useResizeObserver` seems to have a bug.
	 * It returns the contentRect.
	 * Or ResizeObserver behaves differently. Because it does not seem
	 * to pay any attention to `box: 'border-box'`.
	 */
	React.useEffect(() => {
		if (!ref.current) return;

		const element = ref.current;

		const observer = new ResizeObserver((entries) => {
			const [firstEntry] = entries;
			const [currentBorderBoxSize] = firstEntry.borderBoxSize;
			setSize(currentBorderBoxSize);
		});

		observer.observe(element, { box: 'border-box' });

		return () => {
			observer.disconnect();
		};
	}, [ref]);

	React.useLayoutEffect(() => {
		if (!size) return;
		debouncedUpdateTableState(size.inlineSize);
	}, [size, debouncedUpdateTableState]);

	return {
		ref,
	};
};
