import { Box, Modal, Stack, Text } from '@mantine/core';
import { useNavigate } from '@tanstack/react-router';
import React from 'react';
import { match } from 'ts-pattern';
import { z } from 'zod';

import { MonoButton } from '@/components/ui/MonoButton';
import { useSetAppLayout } from '@/hooks/use-app-layout';
import { useFrachterUser } from '@/hooks/use-frachter-user';

import { Route } from './~index';
import { CreateOrganizationForm } from './CreateOrganizationForm';
import { NotAuthorized } from './NotAuthorized';
import { OrganizationSelect } from './OrganizationSelect';

const organizationEntryPageSearchParamsMismatchSchema = z.object({
	action: z.literal('mismatch'),
	activeSlug: z.string(),
	canActivate: z.boolean(),
	next: z.string().optional(),
	selectedSlug: z.string(),
});

export const organizationEntryPageSearchParamsSchema = z.object({ layout: z.literal('standalone').optional() }).and(
	z
		.object({
			action: z.undefined(),
		})
		.or(organizationEntryPageSearchParamsMismatchSchema)
		.or(
			z.object({
				action: z.literal('create'),
			}),
		)
		.or(
			z.object({
				action: z.literal('autologin'),
				next: z.string().url().optional(),
				slug: z.string().optional().or(z.literal('_')),
			}),
		)
		.or(
			z.object({
				action: z.literal('switch'),
				next: z.string().url().optional(),
				slug: z.string(),
			}),
		)
		.or(
			z.object({
				action: z.literal('setup'),
				step: z.number().min(1).max(3).catch(1),
			}),
		),
);

export type OrganizationEntryPageSearchParams = z.infer<typeof organizationEntryPageSearchParamsSchema>;

// export type OrganizationEntryPageSearchParams =
// 	/**
// 	 * show account picker if we have multiple orgs, otherweise AUTO select the only org
// 	 */
// 	| { action?: never }
// 	/**
// 	 * when user wants to create a new organization
// 	 */
// 	| { action: 'create' }
// 	/**
// 	 * switch org (e.g. when someone tries to access a page under /org/:slug and is logged in with a different org)
// 	 */
// 	| {
// 			action: 'switch';
// 			next?: string;
// 			slug: string;
// 	  }
// 	/**
// 	 * the setup flow for an organization
// 	 * steps are just placeholders for now
// 	 */
// 	| {
// 			action: 'setup';
// 			step?: '1' | '2' | '3';
// 	  }
// 	| {
// 			action: 'autologin';
// 	  };

/**
 * OrganizationEntryPage
 *
 * Purpose
 * - show organization selection when no search params are set (= previous /account page)
 *
 * - setup an organization when search params are { action: 'setup' } and we have an active organization (which should be the case after we've created a new one) (= previous /setup page)
 *
 * - try to switch organization when search params are { action: 'switch', slug: 'my-slug', next?: 'https://www.frachter.app/org/my-slug/some-page'}
 * 	 and either redirect to next url when set, to org/:slug when not set or show an error when the user is not allowed to access the organization and prompt to select one where the user is a member
 */
export default function OrganizationEntryPage() {
	const search = Route.useSearch();

	useSetAppLayout(search.layout ?? 'default');

	const { user } = useFrachterUser();

	if (user?.organizationMemberships.length === 0) {
		return (
			<Box style={{ alignItems: 'center', display: 'grid', width: '100%' }}>
				<CreateOrganizationForm />
			</Box>
		);
	} else if (user?.organizationMemberships.length === 1) {
		return <OrganizationSelect autoLogin orgSlug={user?.organizationMemberships[0].slug} />;
	}

	const component = match(search)
		.with({ action: 'mismatch' }, (search) => {
			return <OrganizationSelect />; // <MismatchModal {...search} />;
		})
		.with({ action: 'create' }, () => {
			return <CreateOrganizationForm />;
		})
		.with({ action: 'setup' }, () => {
			return <div>setup</div>;
		})
		// eslint-disable-next-line @typescript-eslint/require-await
		.with({ action: 'switch' }, async ({ next, slug }) => {
			const org = user?.organizationMemberships.find((v) => v.slug === slug);

			if (!org) {
				return <NotAuthorized next={next ?? null} slug={slug} />;
			}

			return <OrganizationSelect next={next} orgSlug={org.slug} />;
		})
		.with({ action: 'autologin' }, ({ next, slug }) => {
			if (user?.organization?.id) {
				/** ----------------------------------------------------------------------------------------------
				 * we use the _ slug in case we directly want to access a sub-route
				 * however, for electron, we only have the autologin property set and no slug, otherwise we lose the
				 * layout query param because it would only available under `/org/:slug`
				 * _______________________________________________________________________________________________ */
				if (!slug || slug === '_') {
					slug = user.organization.slug;
					next = next?.replace(/\/org\/[^/]+/i, `/org/${slug}`);
				}

				return <OrganizationSelect autoLogin next={next} orgSlug={slug} />;
			} else {
				return <OrganizationSelect />;
			}
		})
		.otherwise(() => <OrganizationSelect />);

	return <>{component}</>;
}

const MismatchModal: React.FC<z.infer<typeof organizationEntryPageSearchParamsMismatchSchema>> = ({
	activeSlug,
	canActivate,
	next,
	selectedSlug,
}) => {
	const navigate = useNavigate();

	return (
		<Modal
			centered
			onClose={() => {}}
			opened={true}
			radius={'md'}
			size="md"
			title={canActivate ? 'Organisation wechseln' : 'Anmeldung nicht möglich'}
			withCloseButton={false}
			styles={{
				content: {
					paddingInline: '0.5rem',
				},
			}}
		>
			{canActivate ? (
				<Stack>
					<Text>
						Du bist derzeit in der Organisation mit dem Kürzel <strong>{activeSlug}</strong> angemeldet und hast
						versucht über einen Link direkt auf <strong>{selectedSlug}</strong> zuzugreifen.
						<br />
						<br />
						Wenn Du die Organisation wechselst, wirst Du, sofern andere Tabs in Deiner aktuellen Browser-Session aktiv
						sind, dort abgemeldet.
					</Text>
					<MonoButton
						mt="lg"
						onClick={() =>
							void navigate({
								search: {
									action: 'autologin',
									next,
									slug: selectedSlug,
								},
								to: '/org',
							})
						}
					>
						Zu {selectedSlug} wechseln
					</MonoButton>
				</Stack>
			) : (
				<Stack>
					<Text>
						Du hast versucht Dich in der Organisation mit der Abkürzung <strong>{selectedSlug}</strong> anzumelden.
						Entweder besitzt Du hierfür keine Rechte oder die Organsation existiert nicht.
						<br />
						<br />
						Kontaktiere den Administrator der Organisation, falls Du Dir sicher bist, dass der Link korrekt ist und
						versuche es im Anschluss noch einmal. Oder wähle eine andere Organisation aus Deiner Liste aus.
					</Text>
					<MonoButton mt="lg" onClick={() => void navigate({ to: '/org' })}>
						In einer anderen Organisation anmelden
					</MonoButton>
				</Stack>
			)}
		</Modal>
	);
};
