import { createFileRoute } from '@tanstack/react-router';

import { AppLayout } from '@/layouts/app';
import { AppPageLayout } from '@/layouts/app-page';

import Page, { organizationEntryPageSearchParamsSchema } from './page';

export const Route = createFileRoute('/org/(entry)/')({
	beforeLoad: (_ctx) => {
		// console.log('ctx', ctx);
	},
	component: Component,
	validateSearch: organizationEntryPageSearchParamsSchema,
});

function Component() {
	return (
		<AppLayout>
			<AppPageLayout variant="default">
				<Page />
			</AppPageLayout>
		</AppLayout>
	);
}
