import { Effect } from 'effect';
import { MarketServiceGuteMarken } from '../../../effect-services/warehouse-frontend/markets/gutemarken/market-service-gutemarken';
import { MarketServiceOtto } from '../../../effect-services/warehouse-frontend/markets/otto/market-service-otto';
export class WarehouseRegistryService extends Effect.Service()('frachter/WarehouseRegistryService', {
    effect: Effect.gen(function* () {
        const marketServices = {
            ['gutemarken']: yield* MarketServiceGuteMarken,
            ['otto']: yield* MarketServiceOtto,
        };
        return {
            getMarketService: (market) => Effect.gen(function* () {
                switch (market) {
                    case 'guma':
                        return marketServices.gutemarken;
                    case 'otto':
                        return marketServices.otto;
                    default:
                        return yield* Effect.dieMessage('Invalid market');
                }
            }),
        };
    }),
}) {
}
