'use client';

import { Box, Loader, Text } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

import s from './PdfPreview.module.scss';
import { loadPdf } from './utils';

type PageCallback = Parameters<NonNullable<React.ComponentProps<typeof Page>['onLoadSuccess']>>[0];

interface PdfPreviewProps {
	onPageSizeChange?: (size: { height: number; width: number }) => void;
	url: string;
	zoom?: number;
}

export const PdfPreview: React.FC<PdfPreviewProps> = ({ onPageSizeChange, url, zoom = 1 }) => {
	const { data, isError, isPending } = useQuery({
		queryFn: () => loadPdf(url),
		queryKey: ['pdf', url],
		retry: 1,
	});

	const [isReady, setIsReady] = useState(false);

	const [pageSize, setPageSize] = useState({ height: 0, width: 0 });

	const onPageLoadSuccess = ({ originalHeight, originalWidth }: PageCallback) => {
		setPageSize({ height: originalHeight, width: originalWidth });
		onPageSizeChange?.({ height: originalHeight, width: originalWidth });
	};

	useEffect(() => {
		pdfjs.GlobalWorkerOptions.workerSrc = `/app/static/external/pdfjs/pdf.worker.min.mjs`;

		setIsReady(true);
	}, []);

	const canDisplay = !isError && !isPending && isReady && pageSize.width > 0;

	return (
		<Box
			style={{
				backgroundColor: canDisplay ? 'white' : 'transparent',
				inset: 0,
				overflow: 'auto',
				position: 'absolute',
			}}
		>
			<Box style={{ opacity: canDisplay ? 1 : 0 }}>
				<Document
					className={s.document}
					file={data ?? null}
					loading=""
					renderMode="canvas"
					onError={() => {
						console.log('error doc');
					}}
					onInvalid={(error) => {
						console.log('invalid doc', error);
					}}
				>
					<Page
						canvasBackground="white"
						devicePixelRatio={window.devicePixelRatio}
						height={pageSize.height}
						onLoadSuccess={onPageLoadSuccess}
						pageNumber={1}
						renderTextLayer={true}
						scale={zoom}
						onError={(error) => {
							console.error('Error loading PDF', error);
						}}
					/>
				</Document>
			</Box>
			{isError ? (
				<Box style={{ display: 'grid', inset: 0, placeContent: 'center', position: 'absolute', zIndex: 1 }}>
					<Text>Dokument nicht verfügbar</Text>
				</Box>
			) : (
				<Box style={{ display: 'grid', inset: 0, placeContent: 'center', position: 'absolute' }}>
					<Loader style={{ opacity: canDisplay ? 0 : 1 }} type="dots" />
				</Box>
			)}
		</Box>
	);
};
