import { Effect } from 'effect';

import { constants } from '@/data/constants';

export const getAuthToken = (
	template: 'connect' | 'backend',
	options?: { organizationId?: string | undefined; skipCache?: boolean },
) =>
	Effect.promise(() => {
		if (!window.Clerk?.session) {
			throw new Error('Cannot get auth token without active clerk session.');
		}

		return window.Clerk.session.getToken({
			leewayInSeconds: constants.TOKEN_BACKEND_LEEWAY_IN_SECONDS,
			skipCache: options?.skipCache ?? false,
			template,
			...(options?.organizationId ? { organizationId: options.organizationId } : {}),
		});
	});
