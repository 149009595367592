'use client';

import { Box, Divider, Group, Modal, Stack, Switch, Text, Title } from '@mantine/core';
import { useDisclosure, useHotkeys } from '@mantine/hooks';
import React from 'react';

import { useOrganizationSyncStore } from '@/stores/organization-sync-store';

export const DebugPanel: React.FC<{}> = () => {
	const [opened, { close, toggle }] = useDisclosure(false);

	useHotkeys([['ctrl+D', toggle]]);

	// const [cookies, setCookie, removeCookie] = useCookies([constants.COOKIE_NAME_SANDBOX_INSTALLATION], {
	// 	doNotParse: true,
	// });

	const sharedPersistedStore = useOrganizationSyncStore((s) => s);

	return (
		<Modal
			fullScreen
			keepMounted
			onClose={close}
			opened={opened}
			title="Debugging Tools"
			transitionProps={{ transition: 'slide-up' }}
			zIndex="var(--z-index-debugger)"
		>
			<Box p="lg">
				<Stack gap="xl">
					<Group gap="xl" mih={70}>
						<Title order={4}>React Query</Title>
						<Box
							style={{
								alignItems: 'center',
								display: 'grid',
							}}
						>
							{process.env.NODE_ENV === 'production' && (
								<>
									<Switch
										checked={sharedPersistedStore.isReactQueryDevtoolsInProductionEnabled}
										label="aktivieren"
										onChange={(e) => sharedPersistedStore.toggleReactQueryDevtoolsInProduction(e.currentTarget.checked)}
									/>
								</>
							)}
						</Box>
						<Group />
					</Group>

					<Divider />

					<Title order={4}>Flags</Title>
					<Box style={{ columnGap: '1rem', display: 'grid', gridTemplateColumns: 'auto 1fr', rowGap: '2rem' }}>
						<>
							<Switch
								checked={sharedPersistedStore.isSandboxEnabled}
								onChange={(event) => {
									sharedPersistedStore.setSandboxEnabled(event.currentTarget.checked);
								}}
							/>
							<Text>
								Enable sandbox endpoints for connections (sets{' '}
								<Text component="span" ff="monospace" style={{ display: 'inline' }}>
									sandbox_installation = &quot;true&quot;
								</Text>{' '}
								cookie)
							</Text>
						</>
						<>
							<Switch
								checked={sharedPersistedStore.isPgLiteReplOpen}
								onChange={sharedPersistedStore.togglePgLiteRepl}
							/>
							<Text>Show PGLite REPL</Text>
						</>
					</Box>
				</Stack>
			</Box>
		</Modal>
	);
};
