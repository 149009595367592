import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import type { IRowType } from './types';

interface IProductsTableState {
	editedRows: Record<string, Partial<IRowType>>;
	validationErrors: Record<string, string | undefined>;
}

interface IProductsTableActions {
	resetEditedRows: () => void;
	resetValidationErrors: () => void;
	setEditedRow: (row: IRowType) => void;
	setEditedRowValue: (id: string, value: Partial<IRowType>) => void;
	setValidationError: (id: string, error: string | undefined) => void;
	setValidationErrors: (errors: Record<string, string | undefined>) => void;
}

export const useProductsTableStore = create<IProductsTableState & IProductsTableActions>()(
	devtools(
		immer((set) => ({
			editedRows: {},
			resetEditedRows: () => set({ editedRows: {} }),
			resetValidationErrors: () =>
				set((state) => {
					state.validationErrors = {};
				}),
			setEditedRow: (row) =>
				set((state) => {
					state.editedRows[row.id] = row;
				}),

			setEditedRowValue: (id, value) =>
				set((state) => {
					state.editedRows[id] = value;
				}),

			setValidationError: (id, error) =>
				set((state) => {
					state.validationErrors[id] = error;
				}),

			setValidationErrors: (errors) =>
				set((state) => {
					state.validationErrors = errors;
				}),
			validationErrors: {},
		})),
		{ name: 'frachter', store: 'products-table' },
	),
);
