import { z } from 'zod';
import { JWT_AUDIENCE_BACKEND, JWT_AUDIENCE_BACKEND_CONNECT, JWT_AUDIENCE_CLOUDPRINT, JWT_ISSUER_BACKEND, } from '../constants/jwt';
import { jwtUserSchema } from './user';
export const backendTokenSchema = z.object({
    aud: z.literal(JWT_AUDIENCE_BACKEND),
    azp: z.string().optional(),
    exp: z.number(),
    iat: z.number(),
    iss: z.string().optional(),
    jti: z.string().optional(),
    metadata: z
        .object({
        public: z.object({
            isSuperuser: z.boolean().default(false),
        }),
    })
        .optional(),
    nbf: z.number().optional(),
    // HINT: in case a user does not yet have an organization in the token, all fields are null
    // we are removing organization in this case in the context-middleware !
    organization: jwtUserSchema.shape.organization,
    sub: z.string().describe("The user's id"),
});
export const connectTokenSchema = backendTokenSchema.extend({
    aud: z.literal(JWT_AUDIENCE_BACKEND_CONNECT),
});
export const cloudprintClientTokenSchema = backendTokenSchema.extend({
    aud: z.literal(JWT_AUDIENCE_CLOUDPRINT),
    iss: z.literal(JWT_ISSUER_BACKEND),
});
