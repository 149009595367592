/**
 * This is a small service that is only used to store some handlers
 * and configuration that will be used across services within the warehouse
 * context.
 */
import { Context, Effect, Layer } from 'effect';
export class WarehouseGlobalsService extends Context.Tag('frachter/WarehouseGlobalsService')() {
}
export const WarehouseGlobalsServiceManual = Layer.effect(WarehouseGlobalsService, Effect.gen(function* () {
    return {
        // get in frachter-app from data/env -> `PUBLIC_BACKEND_URL`
        backendBaseUrl: 'https://backend.frachter-local.app',
        // TODO: use the same drizzle interface we use in our frachter client!
        // LATER: move this into a different service
        drizzle: {},
        getBackendAuthorizationHeaderValue: Effect.gen(function* () {
            return 'Bearer TODO: copy from frachter-app network requests';
        }),
    };
}));
