'use client';

import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { QueryClient, useQueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools as ReactQueryDevtoolsOriginal } from '@tanstack/react-query-devtools';
import { PersistQueryClientProvider, PersistedClient, Persister } from '@tanstack/react-query-persist-client';
import { del, get, set } from 'idb-keyval';
import React, { useState } from 'react';

import { useFrachterUser } from '@/hooks/use-frachter-user';
import { useOrganizationSyncStore } from '@/stores/organization-sync-store';

const ReactQueryDevtoolsProduction = React.lazy(() =>
	import('@tanstack/react-query-devtools/production').then((d) => ({
		default: d.ReactQueryDevtools,
	})),
);

export function ReactQueryProvider({ children }: React.PropsWithChildren) {
	const { user } = useFrachterUser();

	// const dispatch = useAppDispatch();

	const [client] = useState(
		new QueryClient({
			defaultOptions: {
				queries: {
					gcTime: 1000 * 60 * 15, // * 24 * 2, // 48 hours
					refetchOnWindowFocus: false,
				},
			},
		}),
	);

	// const isRestoring = useIsRestoring();

	// useEffect(() => {
	// 	if (isRestoring) {
	// 		dispatch(systemActions.queryCacheStatusChanged('restoring'));
	// 	} else {
	// 		dispatch(systemActions.queryCacheStatusChanged('operational'));
	// 	}
	// }, [dispatch, isRestoring]);

	return !user?.organization?.id ? (
		<QueryClientProvider client={client}>{children}</QueryClientProvider>
	) : (
		<PersistedQueryClientProvider client={client} organizationId={user.organization.id}>
			{children}
		</PersistedQueryClientProvider>
	);
}

const PersistedQueryClientProvider = ({
	children,
	client,
	organizationId,
}: React.PropsWithChildren<{ client: QueryClient; organizationId?: string }>) => {
	const [persister] = useState(
		organizationId
			? createIDBPersister(organizationId)
			: createSyncStoragePersister({ storage: typeof window !== 'undefined' ? window.localStorage : undefined }),
	);

	return (
		<PersistQueryClientProvider
			client={client}
			persistOptions={{
				dehydrateOptions: { shouldDehydrateMutation: () => false },
				// hydrateOptions: { defaultOptions: { mutations: { gcTime: 1000 * 60 * 60 * 24 } } },
				persister,
			}}
		>
			{children}
		</PersistQueryClientProvider>
	);
};

/**
 * Creates an Indexed DB persister
 * @see https://developer.mozilla.org/en-US/docs/Web/API/IndexedDB_API
 */
export function createIDBPersister(idbValidKey: IDBValidKey = 'reactQuery') {
	return {
		persistClient: async (client: PersistedClient) => {
			// console.log('persist client', ++counter);
			await set(idbValidKey, client);
		},
		removeClient: async () => {
			await del(idbValidKey);
		},
		restoreClient: async () => {
			return await get<PersistedClient>(idbValidKey);
		},
	} as Persister;
}

export const ReactQueryDevtools = () => {
	const isOpen = useOrganizationSyncStore((s) => s.isReactQueryDevtoolsInProductionEnabled);
	const queryClient = useQueryClient();

	return (
		<>
			<ReactQueryDevtoolsOriginal
				buttonPosition="relative"
				client={queryClient}
				initialIsOpen={false}
				position="bottom"
			/>
			{isOpen && (
				<React.Suspense fallback={null}>
					<ReactQueryDevtoolsProduction client={queryClient} />
				</React.Suspense>
			)}
		</>
	);
};
