import { notifications } from '@mantine/notifications';
import { makeWatcher } from '@packages/lib/effect';
import { Effect, Fiber, Layer, ManagedRuntime, Option, Stream, String, SubscriptionRef } from 'effect';

import { createExternalStore } from '@/utils/createExternalStore';

import { IOrganizationAPI } from '../types';

import { AuthService } from './global-services/AuthService';
import { GraphqlClientService } from './global-services/GraphqlService';
import { IOrganizationRuntimeErrors, IOrganizationRuntimeServices, OrgRuntimeLayer } from './lib/layers';
import { watchSubscriptionRefTask } from './lib/utils';
import { ApiService } from './organization-services/ApiService';
import { OrganizationContextService } from './organization-services/OrganizationContextService';

export interface IOrganizationIdStore {
	current: string | null;
	pending: string | null | undefined;
	requested: string | null;
}

export const getInitialOrganizationId = (): IOrganizationIdStore => ({
	current: null,
	pending: undefined,
	requested: null,
});

export const AUTO_LOAD_FRACHTER_ORG_SYMBOL = Symbol('AUTO_LOAD_FRACHTER_ORG_SYMBOL');

export class FrachterService extends Effect.Service<FrachterService>()('FrachterService', {
	scoped: Effect.gen(function* () {
		const authService = yield* AuthService;
		const graphqlClientService = yield* GraphqlClientService;

		const orgReadyLatch = yield* Effect.makeLatch(false);

		/** ----------------------------------------------------------------------------------------------
		 * setup refs as subscriptions to be able to synchronize with react hooks
		 * _______________________________________________________________________________________________ */

		yield* Effect.addFinalizer((exit) =>
			Effect.gen(function* () {
				yield* Effect.logDebug('finalizer organization manager service', exit);
			}),
		);

		const $status = yield* makeWatcher<{ organization: 'idle' | 'pending' | 'ready' | 'pending-teardown' }>({
			organization: 'idle',
		});

		const currentOrganizationIdRef = yield* SubscriptionRef.make<Option.Option<string>>(Option.none());
		const requestedOrganizationIdRef = yield* SubscriptionRef.make<Option.Option<string>>(Option.none());
		const pendingCurrentOrganizationIdRef = yield* SubscriptionRef.make<Option.Option<string | undefined>>(
			Option.some(undefined),
		);

		const organizationAPIRef = yield* SubscriptionRef.make<Option.Option<IOrganizationAPI>>(Option.none());

		/**
		 * we keep a reference to the current runtime, which holds everything we need to run a specific organization
		 */
		const organizationRuntimeRef = yield* SubscriptionRef.make<
			Option.Option<ManagedRuntime.ManagedRuntime<IOrganizationRuntimeServices, IOrganizationRuntimeErrors>>
		>(Option.none());

		/** ----------------------------------------------------------------------------------------------
		 * setup store for react
		 * _______________________________________________________________________________________________ */

		const $organizationId = createExternalStore<IOrganizationIdStore>(getInitialOrganizationId());

		/** ----------------------------------------------------------------------------------------------
		 * setup store synchronization
		 * _______________________________________________________________________________________________ */

		yield* watchSubscriptionRefTask(currentOrganizationIdRef, (change) =>
			$organizationId.update((draft) => {
				draft.current = Option.getOrNull(change);
			}),
		);

		yield* watchSubscriptionRefTask(requestedOrganizationIdRef, (change) =>
			$organizationId.update((draft) => {
				draft.requested = Option.getOrNull(change);
			}),
		);

		yield* watchSubscriptionRefTask(pendingCurrentOrganizationIdRef, (change) =>
			$organizationId.update((draft) => {
				draft.pending = Option.isSome(change) ? Option.getOrThrow(change) : null;
			}),
		);

		/**
		 * This is the task the will run on each change to the organizationId
		 *
		 * It will check if there is a previous organization and close its scope (shutdown).
		 * It will then check if there is a requested organization id and if so, it will build a new organization.
		 */
		const updateOrganizationTask = Effect.gen(function* () {
			const requestedOrganizationId = yield* SubscriptionRef.get(requestedOrganizationIdRef);
			const previousOrganizationRuntime = yield* SubscriptionRef.get(organizationRuntimeRef);

			// const previousOrganizationScope = yield* SubscriptionRef.get(organizationScopeRef);

			yield* SubscriptionRef.updateEffect(pendingCurrentOrganizationIdRef, () =>
				Effect.succeed(requestedOrganizationId),
			);

			/** ==============================================================================================
			 *
			 * shutdown existing organization
			 *
			 * _______________________________________________________________________________________________ */

			if (Option.isSome(previousOrganizationRuntime)) {
				yield* SubscriptionRef.update($status.ref, (v) => ({ ...v, organization: 'pending-teardown' as const }));
				/* we need to sleep for a bit to ensure that events are propagated to react */
				yield* Effect.sleep(10);

				const disposeFiber = yield* Effect.fork(previousOrganizationRuntime.value.disposeEffect);
				/* HINT: we need to join the fiber to be able to WAIT for the dispose to complete */
				/* otherwise new services will be initialized BEFORE the current org is disposed */
				yield* Fiber.join(disposeFiber);
			}

			/** ==============================================================================================
			 *
			 * build new organization
			 *
			 * _______________________________________________________________________________________________ */

			if (Option.isSome(requestedOrganizationId)) {
				yield* orgReadyLatch.close;
				yield* Effect.logDebug('FrachterService: requestedOrganizationId', requestedOrganizationId.value);
				yield* SubscriptionRef.update($status.ref, (v) => ({ ...v, organization: 'pending' as const }));
				yield* Effect.sleep(10);

				// here we'd need to setup our organization runtime instead of the custom scope !

				// get the current layer (either live or test)
				// const organizationRuntimeLayer = yield* OrganizationRuntimeLayer;

				yield* Effect.logDebug('FrachterService: Make new organization runtime', requestedOrganizationId.value);
				yield* authService.activateOrganization(Option.getOrThrow(requestedOrganizationId));

				const newOrganizationRuntime = ManagedRuntime.make(
					Layer.fresh(OrgRuntimeLayer).pipe(
						Layer.provide(Layer.succeed(GraphqlClientService, graphqlClientService)),
						Layer.provideMerge(Layer.succeed(AuthService, authService)),
						Layer.provideMerge(
							Layer.succeed(
								OrganizationContextService,
								OrganizationContextService.of({
									organizationId: requestedOrganizationId.value,
									userId: (yield* authService.$session.ref)?.user?.id ?? 'unknown',
								}),
							),
						),
						// Layer.provideMerge(FrachterLoggerDefault),
					),

					// organizationRuntimeLayer.make(requestedOrganizationId.value).pipe(
					// 	// pass through the current authService instance
					// 	Layer.provide(Layer.succeed(AuthService, authService)),
					// 	// Layer.provide(Logger.withMinimumLogLevel(LogLevel.Debug))
					// ),
				);

				yield* SubscriptionRef.updateEffect(organizationRuntimeRef, () =>
					Effect.succeed(Option.some(newOrganizationRuntime)),
				);

				const makeOrganization = Effect.gen(function* () {
					// const context = yield* OrganizationContextService;

					if (Option.isSome(requestedOrganizationId)) {
						yield* Effect.logDebug('FrachterService: create organization api', requestedOrganizationId.value);

						const api = yield* ApiService;

						// HINT: realtime depends on active org in clerk session, so we must call connect manually instead of adding this to the Layer effect!
						// yield* realtime.connect;

						return api;
					}

					return null;
				});

				const organization = yield* newOrganizationRuntime.runFork(
					makeOrganization.pipe(
						Effect.catchAllDefect((defect) => {
							Effect.logError('makeOrganization failed', defect);
							notifications.show({
								autoClose: false,
								color: 'red',
								id: 'organization',
								message: 'Die Organisation konnte nicht geladen werden. Bitte starten Sie die App neu.',
								title: 'Kritischer Fehler',
							});
							return Effect.void;
						}),
					),

					// makeOrganization.pipe(Effect.catchAllCause((cause) => Effect.logError('makeOrganization failed', cause))),
				);
				if (organization) {
					yield* SubscriptionRef.updateEffect(organizationAPIRef, () => Effect.succeed(Option.some(organization)));
					yield* SubscriptionRef.update($status.ref, (v) => ({ ...v, organization: 'ready' as const }));
					yield* orgReadyLatch.open;
				}
			} else {
				yield* authService.activateOrganization(null);
				yield* SubscriptionRef.updateEffect(organizationAPIRef, () => Effect.succeed(Option.none()));
				yield* SubscriptionRef.update($status.ref, (v) => ({ ...v, organization: 'idle' as const }));
			}

			/** ----------------------------------------------------------------------------------------------
			 * finish
			 * _______________________________________________________________________________________________ */

			yield* SubscriptionRef.updateEffect(currentOrganizationIdRef, () => Effect.succeed(requestedOrganizationId));
			yield* SubscriptionRef.updateEffect(pendingCurrentOrganizationIdRef, () =>
				Effect.succeed(Option.some(undefined)),
			);
		}).pipe(Effect.withSpan('updateOrganizationTask'));

		/** ----------------------------------------------------------------------------------------------
		 * run the updateOrganizationTask on change of the requested organization id
		 * _______________________________________________________________________________________________ */

		yield* requestedOrganizationIdRef.changes.pipe(
			// run only when there is a change
			Stream.changes,
			// log the change
			// Stream.tap((change) => Effect.logDebug('deferred changed', change)),
			// run the task
			Stream.runForEach((change) =>
				Effect.gen(function* () {
					const currentOrganizationId = yield* SubscriptionRef.get(currentOrganizationIdRef);
					const isEquivalent = Option.getEquivalence(String.Equivalence);

					if (!isEquivalent(currentOrganizationId, change)) {
						yield* updateOrganizationTask;
					}
				}),
			),
			Effect.interruptible,
			Effect.forkDaemon,
		);

		/** ----------------------------------------------------------------------------------------------
		 * return the public api
		 * _______________________________________________________________________________________________ */

		const setOrganization = (slugOrId: string | null | typeof AUTO_LOAD_FRACHTER_ORG_SYMBOL) =>
			Effect.runPromise(
				Effect.gen(function* () {
					let _slugOrId: string | null = null;

					if (slugOrId === AUTO_LOAD_FRACHTER_ORG_SYMBOL) {
						const session = yield* authService.$session.ref;
						if (session?.organization) {
							_slugOrId = session.organization.id;
						} else {
							console.log('auto load session but no organization id is selected');
						}
					} else {
						_slugOrId = slugOrId;
					}

					yield* Effect.logDebug('setOrganization', _slugOrId);
					// retrieve org id in case we have gotten a slug
					const nextId = yield* authService.getIdForSlugOrId(_slugOrId);

					yield* SubscriptionRef.updateEffect(requestedOrganizationIdRef, () =>
						Effect.succeed(nextId === null ? Option.none() : Option.some(nextId)),
					);
				}),
			);

		/** ----------------------------------------------------------------------------------------------
		 * initially setup the organization in case it's the first time we're running
		 * _______________________________________________________________________________________________ */
		// const session = yield* authService.$session.ref;

		// if (session?.organization) {
		// 	void setOrganization(session.organization.id);
		// }

		/** ----------------------------------------------------------------------------------------------
		 * cleanup (will probably never run because we won't dispose the root runtime)
		 * _______________________________________________________________________________________________ */
		yield* Effect.addFinalizer((exit) =>
			Effect.gen(function* () {
				yield* Effect.logDebug('OrganizationManagerService: CLOSING');
				yield* Effect.logDebug('OrganizationManagerService: CLOSED');
			}),
		);

		return {
			$$status: $status.store,
			/**
			 * The current, pending and requested organization id as reactive store.
			 */
			$organizationId,
			/**
			 * The current user's session alongside information about the
			 * user itself, it's organization(s) and the organization members.
			 * Follows the self/others/all pattern.
			 */
			$session: authService.$session.store,
			/**
			 * Get the API surface of the current active organization.
			 * It's better to call it with every change of the organization id
			 * instead of mutating an object reference and expose that one.
			 */
			getOrganizationAPI: () =>
				Effect.runSync(SubscriptionRef.get(organizationAPIRef).pipe(Effect.andThen((v) => Option.getOrNull(v)))),

			orgReady: () => Effect.runPromise(orgReadyLatch.await),

			/**
			 * set the next requested organization id, which will result in
			 * gracefully shutting down the current organization along with
			 * its resources and setup a new one for the given id.
			 *
			 * Will always use the most recent non-current organization id.
			 * So if you request multiple times, last one wins.
			 */
			setOrganization: setOrganization,
		};
	}),
}) {
	static Test = Effect.gen(function* () {
		//
	});
}
