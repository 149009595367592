/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { z } from 'zod';
const headerPresetsSchema = z.object({
    json: z.object({
        'Content-Type': z.literal('application/json'),
    }),
    text: z.object({
        ContentType: z.literal('text/plain'),
    }),
});
export function prepareRequest(requestSchema, build, responseSchema) {
    /** ----------------------------------------------------------------------------------------------
     * make function
     * _______________________________________________________________________________________________ */
    const make = (baseUrl, args, options) => {
        /* validate if requested */
        if (options?.validate) {
            requestSchema.init?.parse(args.init);
            requestSchema.path?.parse(args.path);
            requestSchema.search?.parse(args.search);
        }
        /* build url and append search params */
        const url = new URL(build.path(args.path), baseUrl);
        if (args.search) {
            for (const [key, value] of Object.entries(args.search)) {
                url.searchParams.append(key, value);
            }
        }
        /* prepare request init */
        const init = build.init?.(args.init);
        return new Request(url, init);
    };
    /** ----------------------------------------------------------------------------------------------
     * prepare response schema (might be functions that we must pass our presets)
     * _______________________________________________________________________________________________ */
    if (responseSchema) {
        // If we do have a response, transform the `headers` if it’s a function
        const computedResponseSchema = Object.fromEntries(Object.entries(responseSchema).map(([key, value]) => {
            const headers = typeof value.headers === 'function' ? value.headers(headerPresetsSchema.shape) : value.headers;
            return [key, { ...value, headers }];
        }));
        return {
            make,
            schema: {
                request: requestSchema,
                response: computedResponseSchema,
            },
        };
    }
    else {
        return {
            make,
            schema: {
                request: requestSchema,
            },
        };
    }
}
