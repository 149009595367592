import { z } from 'zod';
import { ZodCustomErrorCode } from '../zod-error';
export const nonEmptyStringSchema = z
    .string()
    .trim()
    .nullable()
    .default(null)
    .refine((v) => (v ?? '').length > 0, {
    params: { i18n: ZodCustomErrorCode.Required },
});
