'use no memo';

import { Box, Text } from '@mantine/core';
import { modals } from '@mantine/modals';
import { useMutationState } from '@tanstack/react-query';
import {
	// createRow,
	type MRT_Row,
	type MRT_TableOptions,
	useMantineReactTable,
} from 'mantine-react-table';
import React from 'react';

import type { IRowType } from './types';

import { BottomToolbarCustomActions, TopToolbarCustomActions } from './renderer';
import { useProductsTableStore } from './store';
import { useCreateColumns } from './use-create-columns';
import { useCreateRow, useDeleteRow, useGetRows, useUpdateRows } from './use-crud';

function validateUser(row: IRowType) {
	return {
		// email: !validateEmail(row.email) ? 'Incorrect Email Format' : '',
		// firstName: !validateRequired(row.firstName) ? 'First Name is Required' : '',
		// lastName: !validateRequired(row.lastName) ? 'Last Name is Required' : '',
	};
}

export function useCreateTable() {
	const resetValidationErrors = useProductsTableStore((s) => s.resetValidationErrors);
	const setValidationErrors = useProductsTableStore((s) => s.setValidationErrors);
	console.log('render useCreateTable');

	//call CREATE hook
	const { isPending: isCreatingRow, mutateAsync: createRow } = useCreateRow();

	//call READ hook
	const {
		data: fetchedRows = [],
		isError: isLoadingRowsError,
		isFetching: isFetchingRows,
		isLoading: isLoadingRows,
	} = useGetRows();

	//call UPDATE hook
	const [isUpdatingRowStatus] = useMutationState({
		filters: { mutationKey: ['products:updateRows'] },
		select: (mutation) => mutation.state.status,
	});

	//call DELETE hook
	const { isPending: isDeletingRow, mutateAsync: deleteRow } = useDeleteRow();

	//CREATE action
	const handleCreateRow = React.useCallback<NonNullable<MRT_TableOptions<IRowType>['onCreatingRowSave']>>(
		({ exitCreatingMode, values }) => {
			const newValidationErrors = validateUser(values);
			if (Object.values(newValidationErrors).some((error) => !!error)) {
				setValidationErrors(newValidationErrors);
				return;
			}

			resetValidationErrors();

			void createRow(values).then(() => {
				exitCreatingMode();
			});
		},
		[createRow, resetValidationErrors, setValidationErrors],
	);

	//DELETE action
	const openDeleteConfirmModal = React.useCallback(
		(row: MRT_Row<IRowType>) => {
			modals.openConfirmModal({
				children: (
					<Text>
						Are you sure you want to delete {row.original.title} {row.original.ean}? This action cannot be undone.
					</Text>
				),
				confirmProps: { color: 'red' },
				labels: { cancel: 'Cancel', confirm: 'Delete' },
				onConfirm: () => void deleteRow(row.original.id),
				title: 'Are you sure you want to delete this user?',
			});
		},
		[deleteRow],
	);

	const initialState = React.useMemo(
		() =>
			({
				density: 'xs',
				pagination: {
					pageIndex: 0,
					pageSize: 50,
				},
			}) as const,
		[],
	);

	const getRowId = React.useCallback((row: IRowType) => row.id, []);

	const onCreatingRowCancel = React.useCallback(() => {
		console.log('onCreatingRowCancel');
		resetValidationErrors();
	}, [resetValidationErrors]);

	const columns = useCreateColumns();

	const renderDetailPanel = React.useCallback<
		Required<MRT_TableOptions<IRowType>>['renderDetailPanel'] //TS needed to get the correct type of the inner arrow function below
	>(({ row, table }) => <Box>details</Box>, []);

	const [showColumnFilters, setShowColumnFilters] = React.useState(true);

	const tableOptions: MRT_TableOptions<IRowType> = {
		columns,
		createDisplayMode: 'row', // ('modal', and 'custom' are also available)
		data: fetchedRows,
		// debugAll: true,
		editDisplayMode: 'table', // ('modal', 'row', 'cell', and 'custom' are also available)
		enableColumnFilters: true,
		enableColumnOrdering: true,

		enableDensityToggle: false,

		enableEditing: true,
		enableFullScreenToggle: false,
		enableHiding: false,

		enableRowActions: false,
		enableRowSelection: true,
		enableRowVirtualization: false,
		enableStickyFooter: true,
		enableStickyHeader: true,
		// enableRowVirtualization: true,
		getRowId,
		initialState,
		// mantineTableContainerProps: {
		// 	style: {
		// 		// minHeight: '500px',
		// 	},
		// },
		mantineToolbarAlertBannerProps: isLoadingRowsError
			? {
					children: 'Error loading data',
					color: 'red',
				}
			: undefined,
		onCreatingRowCancel,
		onCreatingRowSave: handleCreateRow,
		// onGlobalFilterChange: (filter) => {
		// 	console.log('onGlobalFilterChange', filter);
		// },
		onShowColumnFiltersChange: (filter) => {
			console.log('onShowColumnFiltersChange', filter);
			setShowColumnFilters(filter);
		},
		onShowGlobalFilterChange: (filter) => {
			console.log('onShowGlobalFilterChange', filter);
		},
		positionActionsColumn: 'last',
		positionToolbarAlertBanner: 'none',
		renderBottomToolbarCustomActions: BottomToolbarCustomActions,
		renderDetailPanel: renderDetailPanel,
		// renderRowActions: ({ row }) => (
		// 	<Tooltip label="Delete">
		// 		<ActionIcon color="red" onClick={() => openDeleteConfirmModal(row)} size="sm">
		// 			<IconTrash size={16} />
		// 		</ActionIcon>
		// 	</Tooltip>
		// ),
		renderTopToolbarCustomActions: TopToolbarCustomActions,
		state: {
			isLoading: isLoadingRows,
			isSaving: isCreatingRow || isUpdatingRowStatus === 'pending' || isDeletingRow,
			showAlertBanner: isLoadingRowsError,
			showColumnFilters,
			showProgressBars: isFetchingRows,
		},
	};

	// const table = useMantineReactTable(tableOptions);

	// const memoizedTable = React.useMemo(() => {
	// 	console.log('🔴 render table memoized');
	// 	return table;
	// }, [table]);

	return {
		tableOptions,
	};
}
