import { Effect } from 'effect';
import { fromError } from 'zod-validation-error';
import { ResponseValidationError } from './errors';
export function validateResponse(response, validators, request) {
    return Effect.gen(function* () {
        /** ----------------------------------------------------------------------------------------------
         * otherwise we need to figure out if we can parse the body
         * _______________________________________________________________________________________________ */
        /* test status */
        const hasStatusMatch = response.status in validators;
        if (!hasStatusMatch) {
            return yield* new ResponseValidationError('Response status does not match given schema', {
                info: {
                    details: { expected: Object.keys(validators).map(Number), received: response.status },
                    type: 'status',
                },
                reason: 'unhandled',
                request,
                response,
            });
        }
        const validator = validators[response.status];
        const requestedHeadersSchema = validator.headers;
        const requestedBodySchema = validator.body;
        /** ----------------------------------------------------------------------------------------------
         * validate and parse headers if a schema was given
         * _______________________________________________________________________________________________ */
        let parsedHeaders = undefined;
        if (requestedHeadersSchema) {
            // 1) Gather all headers from the response
            //    Note: in browsers, response.headers.keys() is typically all lowercase already.
            //    If you have a custom fetch, you may get the actual case, or it may be normalized.
            const rawHeaders = {};
            for (const [headerKey, headerValue] of response.headers.entries()) {
                // This may already be lowercase in a standard browser fetch
                rawHeaders[headerKey] = headerValue;
            }
            // 2) Build a case‐insensitive subset that matches the schema's keys
            //    but preserve the exact casing of keys from the schema object.
            const schemaSubset = {};
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            for (const schemaKey of Object.keys(requestedHeadersSchema.shape)) {
                const foundRawKey = Object.keys(rawHeaders).find((rawKey) => rawKey.toLowerCase() === schemaKey.toLowerCase());
                if (foundRawKey) {
                    schemaSubset[schemaKey] = rawHeaders[foundRawKey];
                }
            }
            // 3) Validate just this subset with Zod
            const headersResult = requestedHeadersSchema.safeParse(schemaSubset);
            if (!headersResult.success) {
                const { details, message } = fromError(headersResult.error);
                return yield* new ResponseValidationError('Could not validate headers', {
                    info: {
                        details: { issues: details, message, source: 'headers' },
                        type: 'schema',
                    },
                    reason: 'unhandled',
                    request,
                    response,
                });
            }
            // 4) Merge the “other” headers.
            //    In your test, you wanted them lowercased in the final object
            //    even if they came from the server in some other case.
            const otherHeaders = {};
            for (const [rawKey, rawValue] of Object.entries(rawHeaders)) {
                // If this rawKey was not matched by the schema, add it as lowercased
                const wasSchemaKey = Object.keys(schemaSubset).some((schemaKey) => schemaKey.toLowerCase() === rawKey.toLowerCase());
                if (!wasSchemaKey) {
                    otherHeaders[rawKey.toLowerCase()] = rawValue;
                }
            }
            // 5) Combine them:
            //    - schema‐defined headers with their validated (and possibly parsed) values,
            //      using the exact capitalization from the schemaKey
            //    - “other” headers in lowercased form
            parsedHeaders = {
                ...otherHeaders,
                ...headersResult.data,
            };
        }
        if (!requestedBodySchema) {
            return {
                response,
                ...(parsedHeaders ? { headers: parsedHeaders } : {}),
            };
        }
        /** ----------------------------------------------------------------------------------------------
         * process the body
         * _______________________________________________________________________________________________ */
        let processedBody = undefined;
        if (!(response instanceof Response)) {
            // body has already been processed, it should be a string or object
            processedBody = response.body;
        }
        else {
            // body is a stream, so we need to consume it
            const responseContentType = response.headers.get('content-type')?.toLowerCase();
            const isJson = responseContentType?.includes('application/json');
            const isText = responseContentType?.includes('text/plain');
            const requestedParser = validator.parser ?? 'json';
            let parser = requestedParser;
            if (!requestedParser) {
                if (isJson) {
                    parser = 'json';
                }
                else if (isText) {
                    parser = 'text';
                }
            }
            /** ----------------------------------------------------------------------------------------------
             * if we are unable to parse because we could not determine the parser
             * or no parser was given, yield an error
             * _______________________________________________________________________________________________ */
            if (!parser && requestedBodySchema) {
                return yield* new ResponseValidationError('Could not determine parser', {
                    info: {
                        details: {
                            parser: requestedParser,
                            received: responseContentType,
                        },
                        type: 'contentType',
                    },
                    reason: 'unhandled',
                    request,
                    response,
                });
            }
            if (parser) {
                processedBody = yield* Effect.tryPromise({
                    catch: (err) => {
                        return new ResponseValidationError('Could not decode response to json', {
                            info: {
                                details: err,
                                type: 'decode',
                            },
                            reason: 'invalid',
                            request,
                            response,
                        });
                    },
                    try: async () => {
                        const clonedResponse = response.clone();
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                        return await clonedResponse[parser]();
                    },
                });
            }
        }
        if (!processedBody) {
            return yield* new ResponseValidationError('Could not process response body', {
                reason: 'unhandled',
                request,
                response,
            });
        }
        /* handle text and json */
        return yield* Effect.tryPromise({
            catch: (error) => {
                if (error instanceof ResponseValidationError) {
                    return error;
                }
                return new ResponseValidationError('Unhandled error', {
                    cause: error,
                    reason: 'unhandled',
                    request,
                    response,
                });
            },
            try: async () => {
                // TODO/optimize: (we could also set a certain header for the unproxy stuff and only do that if it was a proxied request )
                const parsedBody = await requestedBodySchema?.parseAsync(processedBody).catch((err) => {
                    const { details, message } = fromError(err);
                    throw new ResponseValidationError('Could not parse response into schema', {
                        info: {
                            details: { issues: details, message, source: 'body' },
                            type: 'schema',
                        },
                        reason: 'invalid',
                        request,
                        response,
                    });
                });
                return {
                    data: parsedBody,
                    response,
                    ...(parsedHeaders ? { headers: parsedHeaders } : {}),
                };
            },
        });
    });
}
