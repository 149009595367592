import { match, P } from 'ts-pattern';

import { matchFn } from '@/data/routes';

export const getAppsBreadcrumbs: () => string[] = () => {
	if (typeof window === 'undefined') return [];

	const pathMatch = matchFn.apps(document.location.pathname);

	return match(pathMatch)
		.with(P.nullish, () => [])
		.with({ params: { app: 'quickship', slug: P.string } }, () => ['QuickShip'])
		.with({ params: { app: 'history', slug: P.string } }, () => ['Verlauf'])
		.with({ params: { app: 'settings', slug: P.string, view: P.optional(P.nullish) } }, () => ['Einstellungen'])
		.with({ params: { app: 'settings', slug: P.string, view: 'quickship' } }, () => ['Einstellungen', 'QuickShip'])
		.with({ params: { app: 'settings', slug: P.string, view: 'carriers' } }, () => [
			'Einstellungen',
			'Versanddienstleister',
		])
		.with({ params: { app: 'settings', slug: P.string, view: 'products' } }, () => ['Einstellungen', 'Produkte'])
		.with({ params: { app: 'settings', slug: P.string, view: 'system' } }, () => ['Einstellungen', 'System'])
		.with({ params: { app: 'settings', slug: P.string, view: 'billing' } }, () => ['Einstellungen', 'Abrechnung'])
		.with({ params: { app: 'settings', slug: P.string, view: 'organization' } }, () => [
			'Einstellungen',
			'Organisation',
		])
		.with({ params: { app: 'settings', slug: P.string, view: 'markets' } }, () => ['Einstellungen', 'Marktplätze'])
		.with({ params: { app: 'settings', slug: P.string, view: 'cloudprint' } }, () => ['Einstellungen', 'CloudPrint'])
		.with({ params: { app: 'settings', slug: P.string, view: 'shipping-rules' } }, () => [
			'Einstellungen',
			'Versandregeln',
		])
		.otherwise(() => ['']);
};
