/**
 * The auth token is a custom JWT created with Clerk's JWT templating function.
 * It contains a subset of clerk's default claims:
 *
 * sub: userId
 * organization: {
 * 	slug,
 * 	role,
 * 	name,
 * 	id
 * }
 *
 * and has an expiration of 10 minutes for `connect` template and 1 hour for `backend` template.
 *
 * It must be sent to the backend so that we can verify that the user has permission
 * to connect a provider to the organization.
 *
 * The token mechanism can be used as an alternative to a logged in user.
 * This is useful when we run the app inside of electron and need to open a browser tab
 * where we don't have an active session.
 */

import { type ConnectToken, connectTokenSchema } from '@packages/lib/schema/jwt';

export const useValidateAuthToken = () => {
	function validate(
		value?: string,
	): { success: true; payload: ConnectToken; value: string } | { success: false; reason: 'INVALID' | 'EXPIRED' } {
		const _value = value ?? new URL(window.location.href).searchParams.get('token') ?? '';

		const jwtPayloadPart = _value?.split('.')[1];

		try {
			const payloadValidationResult = connectTokenSchema.safeParse(JSON.parse(atob(jwtPayloadPart)));
			if (payloadValidationResult.success) {
				const { exp } = payloadValidationResult.data;

				if (exp * 1000 < Date.now() - 60 * 2 * 1000) {
					return { reason: 'EXPIRED', success: false };
				}

				return { payload: payloadValidationResult.data, success: true, value: _value };
			}
			return { reason: 'INVALID', success: false };
		} catch {
			return { reason: 'INVALID', success: false };
		}
	}

	return {
		validate,
	};
};
