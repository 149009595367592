import { createFormContext } from '@mantine/form';
import { z } from 'zod';

export const cloudprintQueueFormSchema = z.object({
	from: z.date().optional(),
	isFilterActive: z.boolean().default(false),
	search: z.string().min(3),
	statusFilter: z.enum(['all', 'success', 'error']).default('all'),
	to: z.date().optional(),
});

export type ICloudprintQueueFormSchema = z.infer<typeof cloudprintQueueFormSchema>;

// You can give context variables any name
export const [CloudprintQueueFormProvider, useCloudprintQueueFormContext, useCloudprintQueueForm] =
	createFormContext<ICloudprintQueueFormSchema>();
