import { messages } from '@packages/lib/schema/realtime';
import { type Mutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { type InferSelectModel } from 'drizzle-orm';
import { useEffect } from 'react';

import type { CloudprintQueueItemData } from '@/components/CloudprintQueue';

import { dbx } from '@/db';
import { dbs } from '@/drizzle/pglite';

import { queryKeys } from '../data/queryKeys';

import { useDrizzleLive } from './use-drizzle-live';

export interface UseCompletedPrintJobItemsFilter {
	dateRange?: { from: Date | undefined; to: Date | undefined };
	search?: string;
	status?: 'all' | 'success' | 'error';
}

export const useCompletedPrintJobItems = (filter: UseCompletedPrintJobItemsFilter = {}) => {
	// const { database } = useFrachterApi();
	const { dateRange, search, status = 'all' } = filter;

	const databaseItems = useDrizzleLive(dbx.cloudprint.getQueueItemsByStatus(status, { dateRange, search }));

	return (databaseItems.data ?? []).map(mapDbItemDataToQueueItemData);
};

export const usePendingPrintJobItems = () => {
	const queryClient = useQueryClient();

	useEffect(() => {
		const unsubsribe = queryClient.getMutationCache().subscribe((event) => {
			if (
				event.mutation?.options.mutationKey?.[0] === queryKeys.printJobs[0] &&
				event.mutation?.options.mutationKey?.[1] === queryKeys.printJobs[1]
			) {
				void queryClient.invalidateQueries({ queryKey: [...queryKeys.printJobs] });
			}
		});

		return () => {
			unsubsribe();
		};
	}, [queryClient]);

	const query = useQuery<CloudprintQueueItemData[]>({
		queryFn: () => {
			const items: CloudprintQueueItemData[] = [];

			const mutations = queryClient.getMutationCache().findAll({
				mutationKey: [...queryKeys.printJobs],
				status: 'pending',
			}) as Mutation<unknown, unknown, messages.PrintJobRequestDataSchema['jobs'][number]>[];

			items.push(
				...mutations.map((mutation) => {
					const job = mutation.state.variables as messages.PrintJobRequestDataSchema['jobs'][number];
					return {
						...job,
						transactionState: 'pending',
					} satisfies CloudprintQueueItemData;
				}),
			);

			return items;
		},
		queryKey: [...queryKeys.printJobs],
	});

	return query.data ?? [];
};

function mapDbItemDataToQueueItemData(dbItem: InferSelectModel<typeof dbs.printjobs>): CloudprintQueueItemData {
	return {
		...dbItem,
		transactionState: dbItem.status === 'OK' ? 'success' : 'error',
	} satisfies CloudprintQueueItemData;
}
