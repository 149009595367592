import { createFileRoute, Outlet } from '@tanstack/react-router';

export const Route = createFileRoute('/org/$slug/settings')({
	beforeLoad: ({ context }) => {
		context?.canRedirectToAfterOrgSwitch(true);
	},
	component: Outlet,
	onLeave: ({ context }) => {
		context?.canRedirectToAfterOrgSwitch(false);
	},
});
