'use server';

import { Box, Divider } from '@mantine/core';
import { Link } from '@tanstack/react-router';
import clsx from 'clsx';
import React, { PropsWithChildren, Suspense } from 'react';

import { FrachterLogo } from '@/components/FrachterLogo';
import { useGetAppLayout } from '@/hooks/use-app-layout';

import { AppPageControlsWrapper } from '../app-page/AppPageControls';
import { AppPageTitleWrapper } from '../app-page/AppPageTitle';
import { AppPageVariantWrapper } from '../app-page/AppPageVariantWrapper';

import { AppBarPermanentSection } from './AppBarPermanentSection';
import s from './AppLayout.module.scss';
import { AppLayoutProvider } from './AppLayoutContext';
import { BackButton } from './BackButton';
import { DebugPanel } from './components/DebugPanel';
import { NetworkConnectionGuard } from './components/NetworkConnectionGuard';
import { ContentWrapper } from './ContentWrapper';
import { ElectronAppBar } from './ElectronAppBar';

/**
 * the app layout must have a user logged in !
 * @param param0
 */

export function AppLayout({
	appBarContent,
	children,
	className,
	layoutWrapper,
	sideBarContent,
}: React.PropsWithChildren<{
	appBarContent?: React.ReactNode;
	className?: string;
	layoutWrapper?: React.FC<PropsWithChildren>;
	sideBarContent?: React.ReactNode;
}>) {
	const Wrapper = layoutWrapper ?? React.Fragment;

	const appLayout = useGetAppLayout();

	return (
		<Box className={clsx('frame', s.frame, appLayout === 'standalone' && 'standalone')}>
			{/* since we want to be able to render other layouts (e.g. HomepageLayout) statically, we must update the context in all Layouts that derive from AppLayout */}
			{/* <AppContextUpdater organizations={organizations} user={user} /> */}

			{appLayout === 'standalone' && (
				<Box className={clsx('frame-bar', s.frameBar)}>
					<ElectronAppBar />
				</Box>
			)}

			<Box className={clsx('frame-content', s.frameContent)}>
				<Wrapper>
					<AppLayoutProvider>
						<AppPageVariantWrapper className={clsx(s.root, className)}>
							<Box className={clsx(s.topBar)} component="nav">
								<Box className={s.corner}>
									<Box className={s.backButton}>
										<Suspense fallback={null}>
											<BackButton />
										</Suspense>
									</Box>
									<Link to={'/'}>
										<Box className={s.logo} data-outer="outer-logo">
											<FrachterLogo size={28} />
										</Box>
									</Link>
								</Box>

								<Box className={s.appBar}>
									<Box className={clsx(s.appBarInfo)}>
										<AppPageTitleWrapper />
									</Box>

									<Box className={s.appBarControls}>
										<AppPageControlsWrapper className={clsx(s.appBarDynamicSection)} />
										{appBarContent}
										<Divider orientation="vertical" />
										<AppBarPermanentSection />
									</Box>
								</Box>
							</Box>

							{/* we need this wrapper to avoid that absolutely positioned content overflows our paddings ! */}
							<Box className={clsx(s.main)}>
								<Box className={s.sideBar}>{sideBarContent}</Box>
								<ContentWrapper className={s.content}>{children}</ContentWrapper>
							</Box>
						</AppPageVariantWrapper>

						<DebugPanel />
						<NetworkConnectionGuard />
					</AppLayoutProvider>
				</Wrapper>
			</Box>
		</Box>
	);
}
