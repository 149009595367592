'use client';

import { zodResolver } from '@mantine/form';

import {
	CloudprintQueueFormProvider,
	cloudprintQueueFormSchema,
	useCloudprintQueueForm,
} from './CloudprintQueueFormContext';
import { CloudprintQueuePanel } from './CloudprintQueuePanel';

export const CloudprintQueue = () => {
	const form = useCloudprintQueueForm({
		initialValues: {
			from: undefined,
			isFilterActive: false,
			search: '',
			statusFilter: 'all',
			to: undefined,
		},
		validate: zodResolver(cloudprintQueueFormSchema),
	});

	return (
		<CloudprintQueueFormProvider form={form}>
			<CloudprintQueuePanel />
		</CloudprintQueueFormProvider>
	);
};
