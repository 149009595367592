import { z } from 'zod';
import * as messages from './realtime-messages';
import { organizationUserSchema } from './user';
export { messages };
// export const clientSystemMetaSchema = z.object({
// 	browser: z.enum(['opera', 'firefox', 'edge', 'yandex', 'vivaldi', 'chromium', 'safari', 'chrome', 'other']),
// 	device: z.enum(['desktop', 'mobile', 'tablet']),
// 	os: z.enum(['windows', 'macos', 'linux', 'other']),
// });
// export type ClientSystemMeta = z.infer<typeof clientSystemMetaSchema>;
// export const presenceMessageSchema = z.object({
// 	action: z.enum(['absent', 'present', 'enter', 'leave', 'update']),
// 	clientId: z.string(),
// 	connectionId: z.string(),
// 	data: z.any(),
// 	encoding: z.string().optional().nullable(),
// 	/**
// 	 * TODO: how to get the timeserial from the message after published?
// 	 * TODO: TEST PUBLISHING MESSAGE and see if it returns the timeserial!
// 	 * TODO: how long can a message name be?
// 	 */
// 	// HINT: we have { headers: {..., clientId: string } } in there but we're not using it (for interactions)
// 	extras: z.any(),
// 	id: z.string(),
// 	timestamp: z.number(),
// });
// export type PresenceMessage = z.infer<typeof presenceMessageSchema>;
// export const cloudprintOrganizationPresenceMessageSchema = presenceMessageSchema.extend({
// 	clientId: z.string().startsWith('cloudprint:'),
// 	data: z.object({
// 		meta: z.object({
// 			hostname: z.string(),
// 		}),
// 		printers: z.array(printerSchema),
// 	}),
// });
// export const frachterClientPresenceMessageSchema = presenceMessageSchema.extend({
// 	clientId: z.string().startsWith('frachter:'),
// 	data: z.object({
// 		_ft: z.number().optional().describe(`The optional timestamp of the forced entry into the channel.`),
// 		_t: z.number().describe(`The timestamp when the client first entered the channel.`),
// 		meta: clientSystemMetaSchema,
// 	}),
// });
// export type FrachterClientPresenceMessage = z.infer<typeof frachterClientPresenceMessageSchema>;
/** ----------------------------------------------------------------------------------------------
 * new
 *
 * these are shapes derived from ably's presence profileData messages (ably spaces)
 * in addition, we also use
 * _______________________________________________________________________________________________ */
const realtimeBaseMemberSchema = z.object({
    connectionId: z.string(),
    isConnected: z.boolean(),
});
export const realtimeUserMemberSchema = realtimeBaseMemberSchema.extend({
    clientId: messages.userPresenceMessageSchema.shape.clientId,
    data: messages.userPresenceMessageSchema.shape.profileData.extend({
        profile: organizationUserSchema
            .nullable()
            .describe(`Must be injectecd through the information we have available from clerk. Will not be part of the presence data!`),
    }),
    type: z.literal('user'),
});
export const realtimeCloudprintMemberSchema = realtimeBaseMemberSchema.extend({
    clientId: messages.cloudprintPresenceMessageSchema.shape.clientId,
    data: messages.cloudprintPresenceMessageSchema.shape.profileData,
    type: z.literal('cloudprint'),
});
realtimeCloudprintMemberSchema.pick({});
export const realtimeMemberSchema = z.union([realtimeUserMemberSchema, realtimeCloudprintMemberSchema]);
