import { z } from 'zod';
export const dhlWeightSchema = z.object({
    uom: z.union([z.literal('kg'), z.literal('g')]).describe('Unit of measurement'),
    value: z.number(),
});
// export const dhlValueSchema = z.object({
// 	currency: z.union([z.literal('EUR'), z.literal('GBP'), z.literal('CHF')]).describe('Currency'),
// 	value: z.number(),
// });
export const dhlAddressSchema = z.object({
    additionalAddressInformation1: z
        .string()
        .optional()
        .describe('Additional information that is positioned either behind or below addressStreet on the label. If it is printed and where exactly depends on the country.'),
    additionalAddressInformation2: z
        .string()
        .optional()
        .describe('Additional information that is positioned either behind or below addressStreet on the label. If it is printed and where exactly depends on the country.'),
    addressHouse: z
        .string()
        .optional()
        .describe('Line 1 of the street address. This is just the house number. Can be added to street name instead.'),
    addressStreet: z
        .string()
        .describe('Line 1 of the street address. This is just the street name. Can also include house number.'),
    city: z.string().describe('City'),
    contactName: z
        .string()
        .optional()
        .describe('Optional contact name. This is not the primary name printed on the label.'),
    country: z
        .any() // Replace with appropriate schema for Country
        .describe('Country'),
    dispatchingInformation: z
        .string()
        .optional()
        .describe("An optional, additional line of address. It's only usable for a few countries, e.g. Belgium. It is positioned below name3 on the label."),
    email: z
        .string()
        .optional()
        .describe("Recipient's email address. For shipments within Germany, the email address is used for DHL Parcel Notification. In some countries, providing an email is mandatory for delivery to a droppoint."),
    name1: z.string().describe('Name1. Line 1 of name information'),
    name2: z.string().optional().describe('An optional, additional line of name information'),
    name3: z.string().optional().describe('An optional, additional line of name information'),
    phone: z
        .string()
        .optional()
        .describe("Recipient's phone number. For shipments within Germany, the phone number cannot be transmitted. In some countries, the provision of a phone number is mandatory for delivery to a droppoint."),
    postalCode: z.string().optional().describe('Mandatory for all countries but Ireland that use a postal code system.'),
    state: z
        .string()
        .optional()
        .describe('State, province or territory. For the USA please use the official regional ISO-Codes, e.g. US-AL.'),
});
export const dhlRetoureSchema = z.object({
    billingNumber: z.string(),
    refNo: z.string().optional(),
    returnAddress: dhlAddressSchema.optional(),
});
export const endorsementSchema = z.union([
    z.literal('RETURN', { description: 'Zum Absender zurück' }),
    z.literal('ABANDON', { description: 'Aufgabe des Pakets, keine Rücksendung' }),
], {
    description: 'Instructions and endorsement how to treat international undeliverable shipment. By default, shipments are returned if undeliverable. There are country specific rules whether the shipment is returned immediately or after a grace period.',
});
export const visualCheckOfAgeSchema = z.union([
    z.literal('A16', { description: 'Ab 16 Jahren' }),
    z.literal('A18', { description: 'Ab 18 Jahren' }),
]);
export const identCheckSchema = z.object({
    dateOfBirth: z.string().optional(),
    firstName: z.string(),
    lastName: z.string(),
    minimumAge: visualCheckOfAgeSchema.optional(),
});
export const currenciesSchema = z.enum([
    'AED',
    'AFN',
    'ALL',
    'AMD',
    'ANG',
    'AOA',
    'ARS',
    'AUD',
    'AWG',
    'AZN',
    'BAM',
    'BBD',
    'BDT',
    'BGN',
    'BHD',
    'BIF',
    'BMD',
    'BND',
    'BOB',
    'BOV',
    'BRL',
    'BSD',
    'BTN',
    'BWP',
    'BYR',
    'BZD',
    'CAD',
    'CDF',
    'CHE',
    'CHF',
    'CHW',
    'CLF',
    'CLP',
    'CNY',
    'COP',
    'COU',
    'CRC',
    'CUC',
    'CUP',
    'CVE',
    'CZK',
    'DJF',
    'DKK',
    'DOP',
    'DZD',
    'EGP',
    'ERN',
    'ETB',
    'EUR',
    'FJD',
    'FKP',
    'GBP',
    'GEL',
    'GHS',
    'GIP',
    'GMD',
    'GNF',
    'GTQ',
    'GYD',
    'HKD',
    'HNL',
    'HRK',
    'HTG',
    'HUF',
    'IDR',
    'ILS',
    'INR',
    'IQD',
    'IRR',
    'ISK',
    'JMD',
    'JOD',
    'JPY',
    'KES',
    'KGS',
    'KHR',
    'KMF',
    'KPW',
    'KRW',
    'KWD',
    'KYD',
    'KZT',
    'LAK',
    'LBP',
    'LKR',
    'LRD',
    'LSL',
    'LTL',
    'LVL',
    'LYD',
    'MAD',
    'MDL',
    'MGA',
    'MKD',
    'MMK',
    'MNT',
    'MOP',
    'MRO',
    'MUR',
    'MVR',
    'MWK',
    'MXN',
    'MXV',
    'MYR',
    'MZN',
    'NAD',
    'NGN',
    'NIO',
    'NOK',
    'NPR',
    'NZD',
    'OMR',
    'PAB',
    'PEN',
    'PGK',
    'PHP',
    'PKR',
    'PLN',
    'PYG',
    'QAR',
    'RON',
    'RSD',
    'RUB',
    'RWF',
    'SAR',
    'SBD',
    'SCR',
    'SDG',
    'SEK',
    'SGD',
    'SHP',
    'SLL',
    'SOS',
    'SRD',
    'SSP',
    'STD',
    'SYP',
    'SZL',
    'THB',
    'TJS',
    'TMT',
    'TND',
    'TOP',
    'TRY',
    'TTD',
    'TWD',
    'TZS',
    'UAH',
    'UGX',
    'USD',
    'USN',
    'USS',
    'UYI',
    'UYU',
    'UZS',
    'VEF',
    'VND',
    'VUV',
    'WST',
    'XAF',
    'XAG',
    'XAU',
    'XBA',
    'XBB',
    'XBC',
    'XBD',
    'XCD',
    'XDR',
    'XFU',
    'XOF',
    'XPD',
    'XPF',
    'XPT',
    'XXX',
    'YER',
    'ZAR',
    'ZMW',
    'UNKNOWN',
]);
export const valueSchema = z.object({
    currency: currenciesSchema,
    value: z.number(),
});
