import {
	organizationSchema,
	OrganizationUserSchema,
	organizationUserSchema,
	UserSchema,
	userSchema,
} from '@packages/lib/schema/user';
import { OrganizationSchema } from '@packages/lib/schema/user';
import { Effect, SubscriptionRef } from 'effect';
import { isPresent } from 'ts-extras';

import { IUserSession } from './types';

export const updateSession = (sessionSubRef: SubscriptionRef.SubscriptionRef<IUserSession | null>) =>
	Effect.gen(function* () {
		yield* Effect.logDebug('AuthService: updateSession');
		const clerk = window.Clerk;

		if (!clerk) {
			return;
		}

		/** ----------------------------------------------------------------------------------------------
		 * HANDLE CASES WHERE WE HAVE NO USER
		 * _______________________________________________________________________________________________ */
		if (!clerk.user) {
			const url = new URL(window.location.href);

			const target = new URL('/auth/sign-in', url.origin);
			target.searchParams.set('next', url.href);

			// HINT: this is a little hacky, but it seems to work for now !
			if (url.pathname.startsWith('/app/connect')) {
				return;
			}

			window.location.href = target.href;
		} else {
			const organizationUsers = yield* Effect.promise(() =>
				!clerk.organization
					? Promise.resolve([])
					: clerk.organization
							.getMemberships({ pageSize: 100 })
							.then((v) =>
								v.data
									.map(
										(m) =>
											organizationUserSchema.safeParse({ ...m, ...m.publicUserData, id: m.publicUserData.userId })
												?.data ?? null,
									)
									.filter((m): m is OrganizationUserSchema => m !== null),
							)
							.catch(() => []),
			);

			const currentOrganizationId = clerk.organization?.id;

			const organizationsList =
				clerk.user?.organizationMemberships
					.map(
						(o) =>
							organizationSchema.safeParse({
								id: o.organization.id,
								permissions: o.permissions,
								role: o.role,
								slug: o.organization.slug!,
							} satisfies OrganizationSchema)?.data ?? null,
					)
					.filter(isPresent) ?? [];

			const organization = organizationsList?.find((o) => o.id === currentOrganizationId) ?? null;

			const user =
				userSchema.safeParse({
					firstName: clerk.user?.firstName ?? null,
					id: clerk.user.id,
					imageUrl: clerk.user?.imageUrl ?? null,
					lastName: clerk.user?.lastName ?? null,
				} satisfies UserSchema).data ?? null;

			yield* SubscriptionRef.set(sessionSubRef, {
				organization,
				organizationsList,
				organizationUsers,
				user,
			});

			yield* Effect.logDebug('AuthService: updateSession completed');
		}
	});
