import { createRootRouteWithContext, Outlet } from '@tanstack/react-router';

import type { IOrganizationStore } from '@/stores/organization-store';

import { BrowserSessionLock } from '@/components/BrowserSessionLock';
import { Clerk } from '@/context/AuthContext';
import { FrachterService } from '@/frachter/effect/FrachterService';
// import { GenericUserClient } from '@/type-defs/auth';

export interface RouterContext {
	/**
	 * We must use a function here because context is inherited and if we modify the context directly, it will not be reflected in the parent context.
	 * And since our custom OrganizationSwitch component is NOT part of the child layouts, it would not receive the correct value.
	 * But if we use a function and put the state inside the root route or our RouterPreloader, it's fine.
	 *
	 * If we want to allow a switch to the current sub path, we must use the
	 * `beforeLoad` and `onLeave` hooks and call this function and pass the boolean value.
	 *
	 * For an example, see the `~org/~$slug/~settings/~route.tsx` route.
	 *
	 * @param value true if we can redirect to the current url but with a different org slug, false otherwise
	 * @returns current value
	 */
	canRedirectToAfterOrgSwitch: (value?: boolean) => boolean;
	clerk: Clerk;

	frachter: FrachterService | undefined;

	lastUsedMarket: IOrganizationStore.State['lastUsedMarket'];
}

export const Route = createRootRouteWithContext<RouterContext>()({
	beforeLoad: ({ context }) => {
		return context;
	},
	component: function RootRoute() {
		return (
			<>
				<BrowserSessionLock />
				<Outlet key="root" />
			</>
		);
	},
});
