import { Box, Button, Divider, Group, Loader, Pagination, Select, Text, type TextProps } from '@mantine/core';
import React from 'react';

import { useTableStore } from './store';
import tableStyles from './table.module.scss';

interface ITableBottomBarProps {
	actions?: React.ReactNode;
	/**
	 * we must pass this to avoid resetting the pagination when the query is fetching
	 */
	isFetching: boolean;
}

const Digits = ({ minChars: minChars = 2, value, ...rest }: TextProps & { value: number; minChars?: number }) => {
	return (
		<Text {...rest} style={{ ...rest.style, display: 'inline-block', minWidth: `${minChars}ch`, textAlign: 'right' }}>
			{value.toLocaleString()}
		</Text>
	);
};

const TableBottomBar: React.FC<ITableBottomBarProps> = ({ actions, isFetching }) => {
	/* retrieve data from table store */
	const selectedRowCount = useTableStore((store) => Object.keys(store.rowSelection).length);
	const pagination = useTableStore((store) => store.pagination);
	const setPagination = useTableStore((store) => store.setPagination);
	const enableRowSelection = useTableStore((state) => state.enableRowSelection);

	const totalRowCount = useTableStore((store) => store.totalRowCount);

	/* calculate total pages */
	const totalPages = Math.ceil(totalRowCount / pagination.pageSize);

	/* set the pageIndeValue, which must be pageIndex + 1 */
	const pageIndexValue = (pagination.pageIndex === -1 ? 0 : pagination.pageIndex) + 1;

	const currentFirstRowNumber = pagination.pageIndex * pagination.pageSize + 1;
	const currentLastRowNumber = Math.min(currentFirstRowNumber + pagination.pageSize - 1, totalRowCount);

	return (
		<Box
			className={tableStyles.bottomBar}
			component="fieldset"
			py="md"
			style={{
				backgroundColor: 'rgb(0 0 0 / .01)',
			}}
		>
			<Group>
				<Select
					checkIconPosition="right"
					data={['10', '20', '50', '100']}
					onChange={(value) => value !== null && setPagination({ pageSize: parseInt(value) })}
					size="xs"
					value={pagination.pageSize.toString()}
					w={70}
				/>
				<Group gap="xs">
					<Group gap="xs">
						<Digits minChars={3} size="sm" value={currentFirstRowNumber} />
						<Text c="dimmed">bis</Text>
						<Digits minChars={3} size="sm" value={currentLastRowNumber} />
					</Group>
					<Group gap="xs">
						<Text c="dimmed" size="sm">
							von
						</Text>
						<Box miw="3ch" style={{ alignContent: 'center', display: 'flex', justifyContent: 'end' }}>
							{isFetching ? <Loader size="xs" /> : <Digits minChars={3} size="sm" value={totalRowCount} />}
						</Box>
					</Group>
				</Group>
				<Pagination
					onChange={(value) => setPagination({ pageIndex: value - 1 })}
					radius="xl"
					size="sm"
					total={totalPages}
					value={pageIndexValue}
				/>
			</Group>
			<Group>
				{enableRowSelection && (
					<Group gap="xs">
						<Digits minChars={3} size="sm" value={selectedRowCount} />
						<Text c="dimmed" size="sm">
							ausgewählt
						</Text>
					</Group>
				)}
				{actions && (
					<>
						<Divider orientation="vertical" />
						{actions}
					</>
				)}
			</Group>
		</Box>
	);
};

export const MemoizedTableBottomBar = React.memo(TableBottomBar);
