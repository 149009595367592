/**
 * Proxy
 *
 * we want to be able to pass
 * - a different protocol
 * - a different origin
 * - NOT POSSIBLE: potentially a different username:password combination
 * - an optional pathname prefix
 *
 * the rest should remain the same (but what if we also want to change username and password?)
 */
export function proxyRequest(request, options) {
    const headers = new Headers(request.headers);
    const original = {
        authorizationHeaderValue: request.headers.get('Authorization'),
        url: new URL(request.url),
    };
    const proxy = {
        authorizationHeaderValue: options.authorizationHeaderValue,
        url: new URL(options.baseUrl),
    };
    if (proxy.url.searchParams.size > 0) {
        throw new Error('Proxy URL cannot have search params');
    }
    if (proxy.url.hash) {
        throw new Error('Proxy URL cannot have a hash');
    }
    const finalUrl = new URL(original.url);
    /** ----------------------------------------------------------------------------------------------
     * handle authorization header
     * _______________________________________________________________________________________________ */
    if (original.authorizationHeaderValue) {
        headers.set('X-Proxy-Authorization', original.authorizationHeaderValue);
        headers.delete('Authorization');
    }
    if (proxy.authorizationHeaderValue) {
        headers.set('Authorization', proxy.authorizationHeaderValue);
    }
    /** ----------------------------------------------------------------------------------------------
     * handle url
     * _______________________________________________________________________________________________ */
    /* origin (protocl, host, port) */
    headers.set('X-Proxy-Origin', original.url.origin);
    finalUrl.protocol = proxy.url.protocol;
    finalUrl.host = proxy.url.host;
    finalUrl.port = proxy.url.port;
    /* pathname */
    const proxyUrlPathnameWithoutTrailingSlash = proxy.url.pathname.endsWith('/')
        ? proxy.url.pathname.slice(0, -1)
        : proxy.url.pathname;
    finalUrl.pathname = proxyUrlPathnameWithoutTrailingSlash + original.url.pathname;
    headers.set('X-Proxy-Pathname-Length', proxyUrlPathnameWithoutTrailingSlash.length.toString());
    const proxiedRequest = new Request(finalUrl.toString(), request);
    // HINT: we must do header manipulation manually as if we spread the request above, things like the method get lost
    for (const [key] of proxiedRequest.headers.entries()) {
        proxiedRequest.headers.delete(key);
    }
    for (const [key, value] of headers.entries()) {
        proxiedRequest.headers.set(key, value);
    }
    return proxiedRequest;
}
export function unproxyRequest(request) {
    const origin = request.headers.get('X-Proxy-Origin');
    if (!origin) {
        return { isProxied: false, request };
    }
    const headers = new Headers();
    const proxy = {
        pathnameLength: Number(request.headers.get('X-Proxy-Pathname-Length')),
        url: new URL(request.url),
    };
    const original = {
        authorizationHeaderValue: request.headers.get('X-Proxy-Authorization'),
        url: new URL(origin),
    };
    const finalUrl = new URL(proxy.url);
    finalUrl.protocol = original.url.protocol;
    finalUrl.host = original.url.host;
    finalUrl.port = original.url.port;
    finalUrl.pathname = finalUrl.pathname.slice(proxy.pathnameLength);
    finalUrl.pathname = finalUrl.pathname.replace(/\/$/, '');
    if (original.authorizationHeaderValue) {
        headers.set('authorization', original.authorizationHeaderValue);
    }
    const unproxiedRequest = new Request(finalUrl.toString(), request);
    for (const [key, value] of headers.entries()) {
        unproxiedRequest.headers.set(key, value);
    }
    unproxiedRequest.headers.delete('X-Proxy-Pathname-Length');
    unproxiedRequest.headers.delete('X-Proxy-Origin');
    unproxiedRequest.headers.delete('X-Proxy-Authorization');
    return { isProxied: true, request: unproxiedRequest };
}
