import { ButtonProps } from '@mantine/core';
import { messages } from '@packages/lib/schema/realtime';
import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import React from 'react';
import isEqual from 'react-fast-compare';

import { queryKeys } from '@/data/queryKeys';
import { useFrachterApi } from '@/frachter/hooks/use-frachter';
import { SelectedCloudprintClientDevice } from '@/type-defs/common';

export type UseMutationPrintResult = UseMutationResult<
	messages.PrintJobResponseDataSchema['jobs'][number],
	never,
	messages.PrintJobRequestDataSchema['jobs'][number]
>;

export function useMutationPrint(
	job: messages.PrintJobRequestDataSchema['jobs'][number],
	clientDevice?: SelectedCloudprintClientDevice,
): {
	buttonProps: Pick<ButtonProps, 'loading' | 'disabled'> & { onClick: () => void };
	mutation: UseMutationPrintResult;
} {
	const queryClient = useQueryClient();
	const api = useFrachterApi();

	const isLoading = React.useMemo(() => {
		return Boolean(
			queryClient.getMutationCache().find({
				predicate(mutation) {
					return isEqual(mutation.state.variables, job) && mutation.state.status === 'pending';
				},
			}),
		);
	}, [job, queryClient]);

	const mutation: UseMutationPrintResult = useMutation({
		// meta: {},
		mutationFn: async (printJob) => {
			const result = await api.cloudprint.batchPrint.fetch({ clientDevice, job: printJob });

			if (result.status !== 'OK') {
				throw new Error(result.status);
			}

			return result;
		},
		mutationKey: [...queryKeys.printJobs, job.id],
		onMutate: async () => {
			await queryClient.invalidateQueries({ queryKey: [...queryKeys.printJobs] });
		},
		onSettled: () => {
			// we use an effect in `use-query-print-queue-items` to invalidate the query
			//
			// it would make more sense to directly pack the job into the query cache
			// otherwise the db counter will decrease for an instant and then go up again
			// await queryClient.getQueryCache().add({})
			/** ----------------------------------------------------------------------------------------------
			 * using a timeout is the most simple and realiable way to ensure that we'll get the expected
			 * result (pending items and non-pending items)
			 *
			 * if we use an await delay here that would not work because the mutation would still be
			 * considered pending and the button would remain in loading state
			 *
			 * Better solution: useEffect !
			 * _______________________________________________________________________________________________ */
		},
	});

	return {
		buttonProps: {
			// TODO: get rid of this as we will always have an api or not show the UI at all
			disabled: false,
			loading: mutation.isPending || isLoading,
			onClick: () => mutation.mutate(job),
		},
		mutation,
	};
}
