'use client';

import { Box, Space, Stack } from '@mantine/core';
import { marketIds, getApiById } from '@packages/lib/api';
import { ConnectStatusErrorReason } from '@packages/lib/schema/pages';
import { ComponentProps } from 'react';
import { match, P } from 'ts-pattern';

import { MarketProviderConfig } from '@/data/warehouse';

import { Dialog } from '../Dialog/Dialog';

import s from './setup-market-dialog.module.scss';

export type MarketSetupStatus = 'success' | 'error';

type Props = Pick<ComponentProps<typeof Dialog>, 'noPaper'> & {
	marketId: (typeof marketIds)[number];
	status?: MarketSetupStatus;
	orgSlug?: string | null;
	children?: React.ReactNode;
	errorReason?: ConnectStatusErrorReason;
};

export const SetupMarketDialog = ({ children, errorReason, marketId, orgSlug, status, ...props }: Props) => {
	const config = MarketProviderConfig[marketId];

	const api = getApiById(marketId);

	if (!api) {
		throw new Error(`API not found for marketId: ${marketId}`);
	}

	const logo = (
		<Box
			style={{
				display: 'grid',
				placeContent: 'center',
			}}
		>
			<Box className={s.logoContainer}>
				<config.Logo />
			</Box>
		</Box>
	);

	const { leftSection, rightSection } = match<
		typeof status,
		{ leftSection?: React.ReactNode; rightSection?: React.ReactNode }
	>(status)
		.with(P.nullish, () => ({
			leftSection: (
				<Dialog.Info
					title={'Marktplatz verbinden'}
					subTitle={
						<span>
							Stelle eine Verbindung mit <strong>{api.displayName}</strong> her.
						</span>
					}
				>
					<Stack>
						<Box>
							Um <strong>{api.displayName}</strong> mit Frachter zu verbinden, muss ein Authentifizierungs-Prozess
							durchgeführt werden. Hierfür benötigt Dein Benutzer-Account auf dem Marktplatz die entsprechenden Rechte.
							<br />
							<br />
							Solltest Du diese Rechte nicht besitzen, wende Dich bitte an denjenigen, der den Marktplatz eingerichtet
							hat.
						</Box>
					</Stack>
				</Dialog.Info>
			),
			rightSection: (
				<>
					{logo}
					<Dialog.Organization orgSlug={orgSlug ?? null} />
					<Space />
					{children}
				</>
			),
		}))
		.with('success', () => ({
			leftSection: logo,
			rightSection: (
				<Dialog.Success
					message={`Die Verbindung mit ${api.displayName} war erfolgreich. Du kannst dieses Fenster nun schließen und den Marktplatz in Frachter nutzen.`}
					title="Marktplatz erfolgreich verbunden"
				>
					<Dialog.Actions label="Schließen" onClick={() => window.close()} />
				</Dialog.Success>
			),
		}))
		.with('error', () => ({
			leftSection: logo,
			rightSection: (
				<Dialog.Error
					{...match(errorReason)
						.with(P.nullish, () => ({
							message:
								'Die Verbindung konnte nicht hergestellt werden. Du kannst es erneut versuchen. Falls das Problem wiederholt auftritt, wende Dich bitte an unseren Support.',
							title: 'Ein Fehler ist aufgetreten',
						}))
						.with('organization_mismatch', () => ({
							message:
								'Falls du einen anderen Frachter-Account hast, prüfe bitte, ob du dort eine Verbindung mit dem Marktplatz-Konto hergestellt hast und entferne die Verbindung. Andernfalls wende Dich bitte an unseren Support.',
							subtitle: 'Eine andere Frachter-Organisation hat bereits dieses Marktplatz-Konto verbunden',
							title: 'Ein Fehler ist aufgetreten',
						}))
						.with('provider_error', () => ({
							message:
								'Bitte prüfe auf dem Marktplatz, ob die Verbindung eingerichtet wurde. Falls ja, entferne sie dort und verbinde dich erneut. Leider sind die Schnittstellen mancher Marktplätze fehleranfällig.',
							subtitle: 'Der Marktplatz hat einen Fehler gemeldet',
							title: 'Ein Fehler ist aufgetreten',
						}))
						.exhaustive()}
				>
					<Space />
					<Dialog.Actions
						label="Schließen"
						// labelSecondary="Erneut versuchen"
						onClick={() => window.close()}
						// onClickSecondary={() => void navigate({ replace: true, to: retryPathname })}
					/>
				</Dialog.Error>
			),
		}))
		.exhaustive();

	return <Dialog leftSection={leftSection} rightSection={rightSection} {...props} />;
};
