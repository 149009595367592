import { Effect, SubscriptionRef } from 'effect';
import { acquireMarketConnectionService, releaseMarketConnectionService } from './market-connection-service';
export const setMarkets = ($markets, connections) => Effect.gen(function* () {
    const currentMarkets = yield* $markets;
    const closedMarketKeys = new Set(Object.entries(currentMarkets)
        .filter(([, { storeSubscriptionRef }]) => storeSubscriptionRef.pipe(Effect.runSync).status === 'closed')
        .map(([key]) => key));
    const openMarketKeys = new Set(Object.entries(currentMarkets)
        .filter(([, { storeSubscriptionRef }]) => storeSubscriptionRef.pipe(Effect.runSync).status === 'open')
        .map(([key]) => key));
    const connectionMap = new Map(connections.map((v) => [`${v.marketId}:${v.merchantId}`, v]));
    const connectionKeys = new Set(connectionMap.keys());
    const tasks = {
        close: openMarketKeys.difference(connectionKeys),
        create: connectionKeys.difference(closedMarketKeys.union(openMarketKeys)),
        open: closedMarketKeys.intersection(connectionKeys),
    };
    if (tasks.open.size > 0) {
        yield* SubscriptionRef.updateEffect($markets, (v) => Effect.gen(function* () {
            for (const id of tasks.open) {
                const market = (yield* $markets)[id];
                yield* market.open;
            }
            return v;
        }));
    }
    if (tasks.close.size > 0) {
        yield* SubscriptionRef.updateEffect($markets, (v) => Effect.gen(function* () {
            for (const id of tasks.close) {
                const market = currentMarkets[id];
                yield* market.close;
            }
            return v;
        }));
    }
    if (tasks.create.size > 0) {
        for (const id of tasks.create) {
            const connection = connectionMap.get(id);
            if (!connection) {
                throw new Error(`Connection not found: ${id}`);
            }
            const market = yield* Effect.acquireRelease(acquireMarketConnectionService(connection), releaseMarketConnectionService);
            yield* SubscriptionRef.update($markets, (v) => {
                v[id] = market;
                return v;
            });
            // TODO: remove this later
            // we just have it to debug the merged status stream in our warehouse
            yield* Effect.sleep(250);
        }
        // yield* SubscriptionRef.updateEffect($markets, (v) =>
        // 	Effect.gen(function* () {
        // 		// console.log('create', tasks.create);
        // 		for (const id of tasks.create) {
        // 			const connection = connectionMap.get(id);
        // 			if (!connection) {
        // 				throw new Error(`Connection not found: ${id}`);
        // 			}
        // 			const market = yield* Effect.acquireRelease(
        // 				acquireMarketConnectionService(connection),
        // 				releaseMarketConnectionService,
        // 			);
        // 			v[id] = market;
        // 		}
        // 		return v;
        // 	}),
        // );
    }
    return tasks;
});
