/* eslint-disable @typescript-eslint/no-unused-vars */
import { Effect } from 'effect';
import { apis } from '../../../../api/spec';
import { WarehouseMarketService } from '../../../../effect-services/warehouse/services';
import { MarketCapability } from '../../../../effect-services/warehouse/types';
import { notImplemented } from '../../../../effect-services/warehouse/utils';
import { makeSynchronizeOrders } from './make-synchronize-orders';
import { normalizeOrder } from './normalize-order';
import { normalizeShippingAddress } from './normalize-shipping-address';
// we need
// - [ ] db service (for MarketConnectionService)
// - [ ] ValidationError (in case validation in market fails)
export class MarketServiceOtto extends Effect.Service()(`frachter/market/${apis.otto_market.id}`, {
    dependencies: [],
    effect: Effect.gen(function* () {
        return {
            warehouse: WarehouseMarketService.of({
                capabilities: new Set([MarketCapability.SYNCHRONIZE_ORDERS, MarketCapability.FULFILL_ORDER]),
                labelRequirements: { returnShippingLabel: true },
                makeSynchronizeOrders: makeSynchronizeOrders,
                marketId: apis.otto_market.id,
                normalizeLineItems: (raw) => Effect.succeed([{ id: 'otto-lineitem-1' }]),
                normalizeOrder,
                normalizeShippingAddress,
                prepareFulfillOrderPayload: notImplemented,
                supportedCarrierIds: new Set([
                    apis.dhl_parcel_de_returns_post_parcel_germany_v1.id,
                    apis.dhl_parcel_de_shipping_post_parcel_germany_v2.id,
                    apis.gls_shipit.id,
                ]),
            }),
        };
    }),
}) {
}
