import type React from 'react';

import { Box, type BoxProps } from '@mantine/core';
import clsx from 'clsx';

import tableStyles from '../table.module.scss';

export interface ICellProps {
	/**
	 * used for vertical alignment of the cell content
	 */
	align?: 'start' | 'end' | 'center';
	/**
	 * used for horizontal alignment of the cell content
	 */
	justify?: 'start' | 'end' | 'center';
}

export const Cell: React.FC<React.PropsWithChildren<BoxProps & { ref?: any } & ICellProps>> = ({
	align,
	children,
	className,
	justify,
	...props
}) => {
	return (
		<Box
			className={clsx(tableStyles.cell, className)}
			style={{
				alignItems: align,
				justifyContent: justify,
			}}
			{...props}
		>
			{children}
		</Box>
	);
};
