/* eslint-disable perfectionist/sort-imports */
import '@mantine/notifications/styles.css';
import 'mantine-react-table/styles.css'; //make sure MRT styles were imported in your app root (once)
import '@packages/theme/styles/layers.scss';
import '@packages/theme/styles/globals.scss';
import './global-styles.scss';

import { zodErrorTranslations } from '@packages/lib/schema/zod-error';
import Cookies from 'js-cookie';
import { theme } from '@packages/theme/styles/mantine-theme';

import { RouterProvider, createRouter } from '@tanstack/react-router';
import i18n from 'i18next';
import React, { StrictMode, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { initReactI18next } from 'react-i18next';
/**
 * this is required for redux/graphql
 * redux and apollo graphql client have their own observable polyfills
 * since redux is imported first, then apollo, then probably redux devtools,
 * redux and the devtools will both have different polyfills. devtools will
 * use the one from apollo, redux will use the one from redux.
 *
 * and this leads to a warning.
 *
 * To avoid this, we just need to ensure that this polyfill will be used by
 * apollo and redux and import it here
 */
import 'zen-observable-ts';
import { z } from 'zod';
import { makeZodI18nMap } from 'zod-i18n-map';
import zodTranslationDe from 'zod-i18n-map/locales/de/zod.json';

import { env } from '@/data/env';

import { AppPreloader, AppPreloaderToggle } from './components/AppPreloader';
import { routeTree } from './routeTree.gen';

import { AuthProvider } from './context/AuthContext';
import { ColorSchemeScript, MantineProvider } from '@mantine/core';
import { constants } from './data/constants';
import { ReactQueryProvider } from './context/ReactQueryContext';
import { Notifications } from '@mantine/notifications';
import { SentryGlobalExceptionHandler } from './components/SentryGlobalExceptionHandler';
import { CookiesProvider } from 'react-cookie';
import { setDefaultOptions as setDefaultDateFnsOptions } from 'date-fns';
import { de } from 'date-fns/locale';

import { useClerk } from '@clerk/clerk-react';
import { Frachter } from './components/Frachter';
import { GraphqlProvider } from './context/GraphqlContext';
import { useFrachter } from './frachter/hooks/use-frachter';
import * as Sentry from '@sentry/react';
import { useFrachterClientId } from './hooks/use-frachter-client-id';
import { OrganizationStoreProvider, useOrganizationStore } from './stores/organization-store';
import { OrganizationSyncStoreProvider } from './stores/organization-sync-store';

Sentry.init({
	dsn: 'https://c0d23981f37e27bdfd79a7d4b8d17775@o217358.ingest.us.sentry.io/4508899252633600',
	integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration(), Sentry.extraErrorDataIntegration()],
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	tracePropagationTargets: [
		'localhost',
		/^https:\/\/backend.frachter.app\/(graphql|proxy)/,
		/^https:\/\/backend.frachter-local.app\/(graphql|proxy)/,
	],
	// Tracing
	tracesSampleRate: 1.0, //  Capture 100% of the transactions
});

await i18n.use(initReactI18next).init({
	fallbackLng: 'de',
	interpolation: {
		escapeValue: false,
	},
	lng: 'de',
	resources: {
		de: {
			custom: zodErrorTranslations.de.custom,
			zod: {
				...zodTranslationDe,
				errors: {
					...zodTranslationDe.errors,
					too_small: {
						...zodTranslationDe.errors.too_small,
						string: {
							exact: 'Genau {{minimum}} Zeichen erforderlich',
							inclusive: 'Mindestens {{minimum}} Zeichen erforderlich',
							not_inclusive: 'Mehr als {{minimum}} Zeichen erforderlich',
						},
					},
				},
			},
		},
	},
});

z.setErrorMap(makeZodI18nMap({ ns: ['zod', 'custom'] }));

setDefaultDateFnsOptions({ locale: de });

// Create a new router instance
const router = createRouter({
	basepath: env.PUBLIC_BASE_PATH,
	// we use as any here because we inject everything through our router preloader
	context: {} as any,
	defaultErrorComponent: ({ error, info, reset: _reset }) => {
		console.error(error);
		console.error(info);
		return <div>An unforeseen Error has happened</div>;
	},
	defaultNotFoundComponent: () => {
		return (
			<div>
				<h1>404</h1>
				<p>Not found!</p>
			</div>
		);
	},
	defaultPendingComponent: AppPreloaderToggle, // AppPreloader,
	defaultPendingMinMs: 500,
	defaultPendingMs: 1,
	// defaultPendingMs: 4000,
	// defaultPreload: 'intent',
	// This will ensure that the loader is always called when the route is preloaded or visited
	// defaultPreloadStaleTime: 0,
	// Since we're using React Query, we don't want loader calls to ever be stale
	defaultViewTransition: false,
	// defaultPendingMinMs: 1000,
	routeTree,
	scrollRestoration: true,
});

// Register the router instance for type safety
declare module '@tanstack/react-router' {
	interface Register {
		router: typeof router;
	}
}

const rootElement = document.getElementById('root')!;

const scheme = (Cookies.get(constants.COOKIE_NAME_COLOR_SCHEME) as 'light' | 'dark' | undefined) ?? 'dark';

const createDefaultCookieOptions = () => {
	const domain = window.location.hostname;
	const domainParts = domain.split('.');
	const genericDomain = '.' + [domainParts?.at(-2), domainParts?.at(-1)].join('.');

	const expires = new Date();
	expires.setFullYear(expires.getFullYear() + 1);

	return {
		domain: genericDomain,
		expires,
		path: '/',
	} satisfies {
		path?: string;
		expires?: Date;
		maxAge?: number;
		domain?: string;
		secure?: boolean;
		httpOnly?: boolean;
		sameSite?: boolean | 'none' | 'lax' | 'strict';
		partitioned?: boolean;
	};
};

console.log('✅ NODE_ENV', process.env.NODE_ENV);

if (!rootElement.innerHTML) {
	const root = ReactDOM.createRoot(rootElement);
	root.render(
		<StrictMode>
			<MantineProvider defaultColorScheme={scheme} theme={theme}>
				<ColorSchemeScript defaultColorScheme={scheme} />
				<AuthProvider publishableKey={env.PUBLIC_CLERK_PUBLISHABLE_KEY}>
					<GraphqlProvider>
						<Notifications />
						<SentryGlobalExceptionHandler />
						<AppPreloader />
						<ReactQueryProvider>
							<CookiesProvider defaultSetOptions={createDefaultCookieOptions()}>
								<Suspense fallback={null}>
									<Frachter>
										<StoreProvider>
											<PreloadedRouterProvider />
										</StoreProvider>
									</Frachter>
								</Suspense>
							</CookiesProvider>
						</ReactQueryProvider>
					</GraphqlProvider>
				</AuthProvider>
			</MantineProvider>
		</StrictMode>,
	);
}

console.log('React.version', React.version);

function PreloadedRouterProvider() {
	const lastUsedMarket = useOrganizationStore((s) => s.lastUsedMarket);

	const clerk = useClerk();

	const frachter = useFrachter();

	const canRedirectToAfterOrgSwitchRef = React.useRef(false);

	if (!clerk.loaded) return null;

	return (
		<RouterProvider
			router={router}
			context={{
				canRedirectToAfterOrgSwitch: (value?: boolean) => {
					if (typeof value === 'boolean') {
						canRedirectToAfterOrgSwitchRef.current = value;
					}
					return canRedirectToAfterOrgSwitchRef.current;
				},
				clerk,
				frachter: frachter ?? undefined,
				lastUsedMarket,
			}}
		/>
	);
}

function StoreProvider({ children }: { children: React.ReactNode }) {
	const frachterClientId = useFrachterClientId(true);

	return (
		<OrganizationStoreProvider frachterClientId={frachterClientId}>
			<OrganizationSyncStoreProvider frachterClientId={frachterClientId}>{children}</OrganizationSyncStoreProvider>
		</OrganizationStoreProvider>
	);
}
