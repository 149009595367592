import React from 'react';
import { match } from 'ts-pattern';

import { RealtimeMember } from '../types';

import { useFrachterApi } from './use-frachter';

const filterByIsConnected = (showOffline?: boolean) => (member: RealtimeMember | null) => {
	// console.log('filterByIsConnected', member, showOffline);
	return member === null ? false : showOffline ? true : member.isConnected;
};

interface IMember {
	clientId: string;
	connectionId: string;
}

/**
 * get members that are currently connected by realtime connection
 *
 * can be all | others | self or selected by clientId and connectionId for a particular member
 * in case of others and by id, we can filter by showOffline
 */
export function useRealtimeUserMembers(type: 'self'): RealtimeMember | null;
export function useRealtimeUserMembers(type: 'all', filter?: { showOffline?: boolean }): RealtimeMember[];
export function useRealtimeUserMembers(type: 'others', filter?: { showOffline?: boolean }): RealtimeMember[];
export function useRealtimeUserMembers(
	member?: { clientId: string; connectionId: string },
	filter?: { showOffline?: boolean },
): RealtimeMember | null;

export function useRealtimeUserMembers(
	typeOrMember?: 'all' | 'others' | 'self' | IMember,
	options?: { showOffline?: boolean },
) {
	const api = useFrachterApi();

	const $members = api.$$realtimeMembers;

	const members = React.useSyncExternalStore(api.$$realtimeMembers.subscribe, () => {
		/** ----------------------------------------------------------------------------------------------
		 * in case we've picked by type, return self | others | all, which is re-render safe
		 * _______________________________________________________________________________________________ */
		if (typeof typeOrMember === 'string') {
			return $members.getSnapshot().users[typeOrMember];
		}

		/** ----------------------------------------------------------------------------------------------
		 * in case we've picked by member, return the member only, which is re-render safe and might be undefined
		 * _______________________________________________________________________________________________ */
		const [memberByClientIdAndConnectionId] = typeOrMember
			? $members
					.getSnapshot()
					.users.all.filter(
						(member) => member.clientId === typeOrMember.clientId && member.connectionId === typeOrMember.connectionId,
					)
			: [null];

		return memberByClientIdAndConnectionId;
	});

	const result = match(typeOrMember)
		/* HINT: self can be null in case of a connection drop and reconnect */
		.with('self', () => members as RealtimeMember | null)
		.with('all', () => (members as RealtimeMember[]).filter(filterByIsConnected(options?.showOffline)))
		/* others are the only ones we allow to filter by offline / online state */
		.with('others', () => (members as RealtimeMember[]).filter(filterByIsConnected(options?.showOffline)))
		.otherwise(() =>
			members === null ? null : [members as RealtimeMember].filter(filterByIsConnected(options?.showOffline))[0],
		);

	return result;
}
