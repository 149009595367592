import { Config, ConfigProvider, Context } from 'effect';

const clientId = (() => {
	const sessionClientId = sessionStorage.getItem('realtime_client_id');

	if (sessionClientId) return sessionClientId;

	const clientId = crypto.randomUUID();
	sessionStorage.setItem('realtime_client_id', clientId);

	return clientId;
})();

export const realtimeConfig = Config.all({
	ablyApiKey: Config.option(Config.string('ABLY_API_KEY')),
	ablyClientId: Config.option(Config.string('ABLY_CLIENT_ID')),
});

export class RealtimeConfigProvider extends Context.Tag('RealtimeConfigProvider')<
	RealtimeConfigProvider,
	ConfigProvider.ConfigProvider
>() {
	/**
	 * Default uses api key auth when ABLY_API_KEY is provided.
	 * Otherwise it will use user authentication (token request).
	 */
	static ApiKey = ConfigProvider.fromMap(
		new Map([
			['ABLY_API_KEY', import.meta.env.ABLY_API_KEY as string],
			['ABLY_CLIENT_ID', (import.meta.env.ABLY_CLIENT_ID as string) ?? clientId],
		]),
	);

	/**
	 * User auth with not passthrough the `ABLY_API_KEY` and therefore
	 * the connection will always use user authentication (token request).
	 *
	 * We don't need that because when ablyApiKey is empty or we don't explicitly provide
	 * ABLY_API_KEY env var, we will use token auth
	 */
	// static UserAuth = ConfigProvider.fromMap(new Map());
}
