/* eslint-disable require-yield */
import { Context, Effect, Exit, Scope, Stream, SubscriptionRef } from 'effect';
import { use, useSyncExternalStore } from 'react';

import { createExternalStore } from '@/utils/createExternalStore';

export const store = createExternalStore<{
	status: 'idle' | 'initializing' | 'ready' | 'closing' | 'error';
	id: string;
}>({
	id: '',
	status: 'idle',
});

class OuterScope extends Context.Tag('OuterScope')<OuterScope, Scope.CloseableScope>() {
	static readonly Live = Scope.make();
}

const watcher = (value: string) =>
	Effect.gen(function* () {
		store.update((s) => ({ ...s, id: value }));
	});

const stateWatcher = (value: any) => Effect.gen(function* () {});

class ServiceA extends Effect.Service<ServiceA>()('ServiceA', {
	effect: Effect.gen(function* () {
		const scope = yield* OuterScope;
		const id = crypto.randomUUID();

		const simpleRef = yield* SubscriptionRef.make('initial');

		const objectRef = yield* SubscriptionRef.make<{ counter: number; name: string }>({ counter: 0, name: 'none' });

		yield* Effect.forkIn(Stream.runForEach(simpleRef.changes, watcher), scope);

		yield* Effect.forkIn(
			Stream.runForEach(
				objectRef.changes.pipe(
					// this can be used to select parts of the state and map it
					Stream.map((n) => n.counter % 4 === 0),
					// this can be used to only trigger the watcher when the selected state has changed
					Stream.changes,
				),
				stateWatcher,
			),
			scope,
		);

		// yield* Effect.yieldNow();

		return {
			closeScope: () => Effect.runPromise(Scope.close(scope, Exit.void)),
			sayHello: () => console.log('hello', id),
			setValue: (value: string) =>
				Effect.runPromise(
					Effect.gen(function* () {
						console.log('-------------------');

						const oldValue = yield* SubscriptionRef.get(simpleRef);
						console.log('old value', oldValue);

						// yield* SubscriptionRef.update(ref, () => value);
						yield* SubscriptionRef.set(simpleRef, value);

						const newValue = yield* SubscriptionRef.get(simpleRef);
						console.log('new value', newValue);
					}),
				),
			updateState: (updater: (s: { counter: number; name: string }) => { counter: number; name: string }) =>
				Effect.runSync(SubscriptionRef.update(objectRef, updater)),
		};
	}),
}) {}

export const main = Effect.runPromise(
	Effect.gen(function* () {
		return {
			a: yield* ServiceA,
		};
	}).pipe(Effect.provide(ServiceA.Default), Effect.provideServiceEffect(OuterScope, OuterScope.Live)),
);

export const useMain = () => {
	const m = use(main);

	return m;
};

export const useStore = () => {
	return useSyncExternalStore(store.subscribe, () => store.getSnapshot().id);
};

// I want a sliding queue to store a particular value (only the most recent)
// I want to be able to subscribe to changes to that value
// I want to be able to set that value
