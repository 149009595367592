import { RequiredDeep } from 'type-fest';
import { z } from 'zod';

// HINT: ensure to adapt Caddyfile when we change this one

export const BASE_PATH = '/app/';

/**
 * prefill imported env and ensure we don't have any undefined values
 * as those would override the final env
 */
const importedEnv = Object.fromEntries(Object.entries(import.meta.env).filter(([, value]) => value !== undefined));

/**
 * these are hardcoded defaults that we should later extract into the build environment
 */
const branchEnv = {
	frachter: {
		PUBLIC_API_ENVIRONMENT: 'production',
		PUBLIC_BACKEND_URL: 'https://backend.frachter.app',
		PUBLIC_BASE_PATH: BASE_PATH,
		PUBLIC_BRANCH: 'frachter',
		PUBLIC_CLERK_PUBLISHABLE_KEY: 'pk_live_Y2xlcmsuZnJhY2h0ZXIuYXBwJA',
		PUBLIC_LIVEBLOCKS_API_KEY: 'pk_prod_tfr_34qFcXHFReFovo1XxT55uxASCbl8cjTzH8GVYMK64NnQhTpk6csBCFHtkkb0',
		PUBLIC_SITE_URL: 'https://www.frachter.app',
	} satisfies EnvSchema,
	local: {
		PUBLIC_API_ENVIRONMENT: 'local',
		PUBLIC_BACKEND_URL: 'https://backend.frachter-local.app',
		PUBLIC_BASE_PATH: BASE_PATH,
		PUBLIC_BRANCH: 'local',
		PUBLIC_CLERK_PUBLISHABLE_KEY: 'pk_test_b24ta29hbGEtNjkuY2xlcmsuYWNjb3VudHMuZGV2JA',
		PUBLIC_LIVEBLOCKS_API_KEY: 'pk_dev_ylFAliOox_hqKk6VlQ4Sn_tcOJXWn7BZv6IA45vJA6RlumBZJsgt8bJauyEKlSmu',
		PUBLIC_SITE_URL: 'https://www.frachter-local.app',
	} satisfies EnvSchema,
	main: {
		PUBLIC_API_ENVIRONMENT: 'production',
		PUBLIC_BACKEND_URL: 'https://backend.frachter.app',
		PUBLIC_BASE_PATH: BASE_PATH,
		PUBLIC_BRANCH: 'main',
		PUBLIC_CLERK_PUBLISHABLE_KEY: 'pk_live_Y2xlcmsuZnJhY2h0ZXIuYXBwJA',
		PUBLIC_LIVEBLOCKS_API_KEY: 'pk_prod_tfr_34qFcXHFReFovo1XxT55uxASCbl8cjTzH8GVYMK64NnQhTpk6csBCFHtkkb0',
		PUBLIC_SITE_URL: 'https://www.frachter.app',
	} satisfies EnvSchema,
} as const;

const envSchema = z.object({
	PUBLIC_API_ENVIRONMENT: z.enum(['production', 'local']).optional(),
	PUBLIC_BACKEND_URL: z.string().optional(),
	PUBLIC_BASE_PATH: z.string().optional(),
	PUBLIC_BRANCH: z.enum(['local', 'frachter', 'main']).optional(),
	PUBLIC_CLERK_PUBLISHABLE_KEY: z.string().optional(),
	PUBLIC_LIVEBLOCKS_API_KEY: z.string().optional(),
	PUBLIC_SITE_URL: z.string().optional(),
});

type EnvSchema = z.infer<typeof envSchema>;

/**
 * if we are missing any entries, this should throw
 */
export const env = envSchema.parse(
	Object.assign({ ...branchEnv[import.meta.env.PUBLIC_BRANCH ?? 'local'] }, importedEnv),
) as RequiredDeep<EnvSchema>;
