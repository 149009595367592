/* eslint-disable @typescript-eslint/unbound-method */
import { useLiveQuery } from '@electric-sql/pglite-react';
import { Box, Button, Divider, Group, NumberInput, Stack, Text, Title } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import byteSize from 'byte-size';
import { count, desc, eq } from 'drizzle-orm';
import React from 'react';

import { dbs } from '@/drizzle/pglite';
import { useFrachterApi } from '@/frachter/hooks/use-frachter';
import { useDrizzleLive } from '@/hooks/use-drizzle-live';
import { useFrachterUser } from '@/hooks/use-frachter-user';
import { createOrder } from '@/mocks/otto-market/orders';

import { Counter } from './Counter';
import { useQueryTableSizes } from './database/database-queries';
import { DatabaseHealthCheck } from './database/DatabaseHealthCheck';

export const Route = createFileRoute('/org/$slug/playground/pglite/')({
	component: Page,
});

function Page() {
	const { user } = useFrachterUser();

	return (
		<>
			<DatabaseHealthCheck organizationId={user!.organization!.id} />
			<Inner />
		</>
	);
}

function Inner() {
	const {
		database: { drizzleClient: drizzle },
	} = useFrachterApi();

	const ordersQuery = drizzle.select().from(dbs.marketOrders).limit(10).toSQL();

	console.log('ordersQuery', ordersQuery);

	const orders = useDrizzleLive((db) =>
		db.query.marketOrders.findMany({ limit: 10, orderBy: desc(dbs.marketOrders.createdAt) }),
	);

	// const orders = useDrizzleLive(statements.cloudprint.dummy());

	// queries.cloudprint.queueItemsByStatus('all')(drizzle);

	console.log('orders', orders);

	const countData = useLiveQuery(drizzle.select({ count: count() }).from(dbs.marketOrders).toSQL().sql);

	const totalOrders = (countData?.rows[0]?.count as number) ?? 0;

	const query = drizzle.query.marketOrders.findMany({ limit: 10, orderBy: desc(dbs.marketOrders.createdAt) }).toSQL();

	const data = useLiveQuery(query.sql, query.params);

	return (
		<Box p="xl">
			<Box style={{ display: 'grid', gap: '2rem', gridTemplateColumns: 'repeat(3, 1fr)' }}>
				<Generator totalOrders={totalOrders} />

				<Stats totalOrders={totalOrders} />

				<Stack>
					<Box
						style={{
							display: 'grid',
							gap: '1rem',
							gridTemplateColumns: '100px 1fr',
						}}
					>
						{data?.rows.map((row) => {
							return (
								<React.Fragment key={row.orderId as string}>
									<Text ta="right">{row.index as string}</Text>
									<Text>{row.orderId as string}</Text>
								</React.Fragment>
							);
						})}
					</Box>
				</Stack>
			</Box>
		</Box>
	);
}

function Stats({ totalOrders }: { totalOrders: number }) {
	const tableSizes = useQueryTableSizes();

	// const idbSize = useQuery({
	// 	queryFn: async () => {
	// 		const data = await fetch('http://localhost:9002', {});

	// 		return data.json() as unknown as { total: number };
	// 	},
	// 	queryKey: ['debug', 'idb-kb'],
	// 	refetchInterval: 3000,
	// });

	const opfsSize = useQuery({
		queryFn: async () => {
			async function getDirectoryHandle(path: string[]): Promise<FileSystemDirectoryHandle> {
				let currentDir: FileSystemDirectoryHandle = await navigator.storage.getDirectory();

				// Navigate through the path array
				for (const dir of path) {
					currentDir = await currentDir.getDirectoryHandle(dir);
				}

				return currentDir;
			}

			async function getTotalSizeOfFilesInDirectory(directoryHandle: FileSystemDirectoryHandle): Promise<number> {
				let totalSize = 0;

				// @ts-expect-error ok ok
				// eslint-disable-next-line @typescript-eslint/no-unsafe-call
				for await (const entry of directoryHandle.values()) {
					if (entry.kind === 'file') {
						// Cast to FileSystemFileHandle and get file size
						const fileHandle = entry as FileSystemFileHandle;
						const file = await fileHandle.getFile();
						totalSize += file.size;
						console.log('file', file.name, file.size);
					} else if (entry.kind === 'directory') {
						// If it's a directory, recursively get its size
						const subdirHandle = entry as FileSystemDirectoryHandle;
						totalSize += await getTotalSizeOfFilesInDirectory(subdirHandle);
					}
				}

				return totalSize;
			}

			async function getTotalSizeOfFilesInFrachterData() {
				// Define the path to the target directory
				const path = ['frachter', 'org_1234', 'data'];

				// Get handle to the target directory
				const targetDirHandle = await getDirectoryHandle(path);

				// Get the total size of all files in the directory
				return await getTotalSizeOfFilesInDirectory(targetDirHandle);
			}

			// Request a handle to the folder (OPFS directory)
			return await getTotalSizeOfFilesInFrachterData();
		},
		queryKey: ['debug', 'opfs-kb'],
		refetchInterval: 3000,
	});

	// Call the function and log the result

	console.log('opfsSize', opfsSize);

	return (
		<Stack>
			<Stack>
				<Title order={3}>DB Details</Title>
				<Group justify="space-between">
					<Text>OPFS size</Text>
					<Text>{opfsSize?.data?.toLocaleString()} bytes</Text>
				</Group>
				<Group justify="space-between">
					<Text>IndexedDB size</Text>
					{/* <Text>{(idbSize.data?.total ?? 0).toLocaleString()} bytes</Text> */}
				</Group>
				<Stack>
					<Title order={4}>Table sizes</Title>
					{tableSizes.data?.map((v) => {
						const size = byteSize(v.size);

						return (
							<Text key={v.name}>
								{v.name}: {size.value} {size.unit}
							</Text>
						);
					})}
				</Stack>
			</Stack>
			<Group>
				<Stack>
					<Title order={5}>Number of orders:</Title>
					<Counter end={totalOrders} />
				</Stack>
			</Group>
		</Stack>
	);
}

function Generator({ totalOrders }: { totalOrders: number }) {
	const [ordersPerCycle, setOrdersPerCycle] = React.useState(1000);
	const [isAutoCreating, setIsAutoCreating] = React.useState(false);
	const [isAutoDeleting, setIsAutoDeleting] = React.useState(false);
	const [maxOrders, setMaxOrders] = React.useState(1_000_000);

	const [isPending, startTransition] = React.useTransition();

	const {
		database: { drizzleClient: drizzle, pgClient: pg, reset },
	} = useFrachterApi();

	const createOrders = React.useCallback(() => {
		startTransition(async () => {
			if (isPending) return;
			const orders = new Array(ordersPerCycle).fill(null).map(createOrder);
			await drizzle.insert(dbs.marketOrdersLocal).values(
				orders.map((order) => ({
					createdAt: new Date(),
					id: crypto.randomUUID(),
					marketId: 'otto',
					marketMerchantId: '10001',
					marketOrderId: order.orderNumber,
					merchantId: '10001',
					raw: order,
					status: 'PENDING' as const,
					updatedAt: new Date(),
				})),
			);
		});
	}, [drizzle, ordersPerCycle, isPending]);

	const deleteOrders = React.useCallback(() => {
		startTransition(async () => {
			if (isPending) return;

			await pg.exec(/* sql */ `
				WITH
					rows_to_delete AS (
						SELECT
							id
						FROM
							market_orders
						ORDER BY
							id
						LIMIT
							${ordersPerCycle}
					)
				DELETE FROM market_orders
				WHERE
					id IN (
						SELECT
							id
						FROM
							rows_to_delete
					);
			`);
		});
	}, [isPending, pg, ordersPerCycle]);

	React.useEffect(() => {
		if (isAutoCreating && totalOrders + ordersPerCycle < maxOrders) {
			createOrders();
		}
	}, [createOrders, isAutoCreating, totalOrders, maxOrders, ordersPerCycle]);

	React.useEffect(() => {
		if (isAutoDeleting && totalOrders - ordersPerCycle > 0) {
			deleteOrders();
		}
	}, [deleteOrders, isAutoDeleting, totalOrders, ordersPerCycle]);

	return (
		<Stack>
			<NumberInput
				defaultValue={ordersPerCycle}
				label="Number of orders to create per cycle (1-5000)"
				max={10000}
				min={1}
				onChange={(value) => setOrdersPerCycle(Number(value))}
				value={ordersPerCycle}
			/>

			<NumberInput
				defaultValue={ordersPerCycle}
				label="Max Orders"
				max={1_000_000}
				min={1}
				onChange={(value) => setMaxOrders(Number(value))}
				value={maxOrders}
			/>

			<Button disabled={isAutoCreating || isAutoDeleting} onClick={() => void createOrders()}>
				Create {ordersPerCycle} orders
			</Button>

			<Button
				{...(isAutoCreating && { color: 'green' })}
				disabled={isAutoDeleting}
				onClick={() => setIsAutoCreating(!isAutoCreating)}
			>
				Auto-Create {isAutoCreating ? 'ON' : 'OFF'}
			</Button>

			<Divider my="lg" />

			<Button disabled={isAutoCreating || isAutoDeleting} onClick={() => void deleteOrders()}>
				Delete {ordersPerCycle} orders
			</Button>

			<Button
				{...(isAutoDeleting && { color: 'green' })}
				disabled={isAutoCreating}
				onClick={() => setIsAutoDeleting(!isAutoDeleting)}
			>
				Auto-Delete {isAutoDeleting ? 'ON' : 'OFF'}
			</Button>

			<Divider my="lg" />

			<Button
				color="red"
				// eslint-disable-next-line @typescript-eslint/no-misused-promises
				onClick={async () => {
					const latestOrder = await drizzle.query.marketOrders.findFirst();

					if (latestOrder) {
						await drizzle
							.delete(dbs.marketOrders)
							// TODO: improve with composite index key
							.where(eq(dbs.marketOrders.orderId, latestOrder.orderId));
					}
				}}
			>
				remove latest order
			</Button>

			<Button color="red" onClick={() => void drizzle.delete(dbs.marketOrders).execute()}>
				DELETE FROM orders
			</Button>

			<Button color="red" onClick={() => void pg.exec('VACUUM market_orders')}>
				VACUUM orders
			</Button>

			<Button color="red" onClick={() => void pg.exec('VACUUM FULL market_orders')}>
				VACUUM FULL orders
			</Button>

			<Button color="red" onClick={() => void reset()}>
				Reset Database
			</Button>
		</Stack>
	);
}
