'use client';

import { ActionIcon, Box, Group, Paper, Title } from '@mantine/core';
import { withTrackSignals } from '@preact-signals/safe-react/manual';
import { IconChevronRight, IconCircleMinus, IconCirclePlus, IconAspectRatio as IconReset } from '@tabler/icons-react';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { useEffect, useMemo, useReducer, useState } from 'react';

import { PdfPreview } from '@/components/PdfPreview/PdfPreview';

import { useCloudprintQueuePanel } from '../../hooks/use-cloudprint-queue-panel';

import s from './CloudprintQueuePreview.module.scss';

type ZoomInAction = { type: 'zoomIn' };
type ZoomOutAction = { type: 'zoomOut' };
type ResetAction = { type: 'reset'; value: { minZoom: number; zoom: number } };
type ZoomSetAction = { type: 'zoomSet'; value: number };

type Action = ZoomInAction | ZoomOutAction | ResetAction | ZoomSetAction;

interface State {
	minZoom: number;
	zoom: number;
}

const OFFSET_Y = 50;
const OFFSET_LEFT = 16;

const MAX_SCALE_MULTIPLIER = 5;

function reducer(state: State, action: Action) {
	switch (action.type) {
		case 'zoomSet':
			return { ...state, zoom: action.value };
		case 'zoomOut':
			return { ...state, zoom: Math.max(state.minZoom, state.zoom - 0.5) };
		case 'zoomIn':
			return { ...state, zoom: Math.min(state.minZoom * MAX_SCALE_MULTIPLIER, state.zoom + 0.5) };
		case 'reset':
			return { ...state, ...action.value };
	}
}

interface Props {
	availableHeight: number;
	availableWidth: number;
	className?: string;
}

export const CloudprintQueuePreview: React.FC<Props> = withTrackSignals((props) => {
	const { availableHeight, availableWidth, className } = props as Props;

	const [pageSize, setPageSize] = useState({ height: 0, width: 0 });

	const panelVisibility = useCloudprintQueuePanel();

	const dimensions = useMemo(() => {
		if (pageSize.width === 0)
			return {
				height: '100%',
				width: 500,
				zoom: 1,
			};

		const widthRatio = pageSize.width / (availableWidth - OFFSET_LEFT);
		const heightRatio = pageSize.height / (availableHeight - OFFSET_Y);

		let scaleFactor = 1;

		if (widthRatio > heightRatio) {
			scaleFactor = 1 / widthRatio;
		} else {
			scaleFactor = 1 / heightRatio;
		}

		return {
			height: pageSize.height * scaleFactor,
			width: pageSize.width * scaleFactor,
			zoom: scaleFactor,
		};
	}, [availableHeight, availableWidth, pageSize]);

	const [state, dispatch] = useReducer(reducer, { minZoom: dimensions.zoom / 2, zoom: dimensions.zoom });

	const minZoom = dimensions.zoom / 2;

	useEffect(() => {
		dispatch({ type: 'reset', value: { minZoom, zoom: dimensions.zoom } });
	}, [dimensions.zoom, minZoom]);

	useEffect(() => {
		if (panelVisibility.preview.item) {
			dispatch({ type: 'reset', value: { minZoom, zoom: dimensions.zoom } });
		}
	}, [dimensions.zoom, minZoom, panelVisibility.preview.item]);

	return (
		<Box
			style={{
				height: availableHeight - OFFSET_Y,
				left: -availableWidth + OFFSET_LEFT,
				// overflow: 'hidden',
				pointerEvents: 'none',
				position: 'absolute',
				top: OFFSET_Y / 2,
				width: availableWidth - OFFSET_LEFT,
			}}
		>
			<Paper
				className={clsx(s.preview, className)}
				component={motion.div}
				exit={{ opacity: 0, transition: { ease: 'circInOut' }, x: '100%' }}
				initial={{ opacity: 0, x: '100%' }}
				shadow="sm"
				animate={{
					opacity: 1,
					transition: { ease: 'circInOut' },
					x: 0, //  availableWidth - OFFSET_LEFT + 2 - dimensions.width,
				}}
				style={{
					...(pageSize.width > 0 && { '--cloudprint-queue-panel-preview-page-width': pageSize.width + 'px' }),
					alignItems: 'start',
					display: 'grid',
					gridTemplateRows: 'auto 1fr',
					height: dimensions.height + 'px',
					overflow: 'hidden',
					right: 0,
					width: dimensions.width + 'px',
				}}
			>
				<Group
					justify="space-between"
					p="md"
					style={{
						backgroundColor: `color-mix(in srgb, var(--mantine-color-text) 5%, transparent)`,
					}}
				>
					<Title order={6} tt="uppercase">
						<Group gap="xs">Vorschau</Group>
					</Title>
					<Group>
						<ActionIcon
							disabled={state.zoom === dimensions.zoom}
							variant="transparent"
							onClick={() => {
								dispatch({ type: 'reset', value: { minZoom, zoom: dimensions.zoom } });
							}}
						>
							<IconReset size={22} />
						</ActionIcon>
						<ActionIcon
							disabled={state.zoom <= minZoom}
							variant="transparent"
							onClick={() => {
								dispatch({ type: 'zoomOut' });
							}}
						>
							<IconCircleMinus size={22} />
						</ActionIcon>

						<ActionIcon
							disabled={state.zoom >= minZoom * MAX_SCALE_MULTIPLIER}
							variant="transparent"
							onClick={() => {
								dispatch({ type: 'zoomIn' });
							}}
						>
							<IconCirclePlus size={22} />
						</ActionIcon>

						<ActionIcon
							variant="transparent"
							onClick={() => {
								panelVisibility.preview.hide();
							}}
						>
							<IconChevronRight size={22} />
						</ActionIcon>
					</Group>
				</Group>
				<Box style={{ height: '100%', position: 'relative' }}>
					{panelVisibility.preview.item && (
						<PdfPreview onPageSizeChange={setPageSize} url={panelVisibility.preview.item.url} zoom={state.zoom} />
					)}
				</Box>
			</Paper>
		</Box>
	);
});
