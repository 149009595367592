import { Button, Divider, NumberInput, Pill, Stack, TextInput, Title } from '@mantine/core';
import React from 'react';

export const MantineTest = () => {
	return (
		<Stack p="xl">
			{/* <Title order={2}>Hello from About!</Title> */}
			<Button color="brand">Click me now</Button>
			<Button color="brand">Click me now</Button>
			<Button color="brand">Click me now</Button>
			<Pill>Hello</Pill>
			<TextInput placeholder="ohoh" />
			<Title order={2}>something e 222</Title>
			<Divider orientation="horizontal" />
			<NumberInput description="Input description" label="Input label" placeholder="Input placeholder" />
			<NumberInput description="Input description" label="Input label" placeholder="Input placeholder" />
		</Stack>
	);
};
