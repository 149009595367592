import { Box, Text } from '@mantine/core';
import { ApiEnvironmentEnumSchema } from '@packages/lib/schema/system';

import { useOrganizationSyncStore } from '@/stores/organization-sync-store';

export const EnvironmentIndicator = ({ environment }: { environment: ApiEnvironmentEnumSchema }) => {
	const isSandboxEnabled = useOrganizationSyncStore((s) => s.isSandboxEnabled);

	const showIndicator = isSandboxEnabled ? true : environment === 'sandbox';

	return (
		<Box
			bg={environment === 'sandbox' ? 'warning' : 'ok'}
			c="white"
			miw={76}
			px="xs"
			py={2}
			style={{ borderRadius: 10, opacity: showIndicator ? 1 : 0 }}
		>
			<Text fw={600} size="xs" ta="center">
				{environment}
			</Text>
		</Box>
	);
};
