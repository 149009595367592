import { Select } from '@mantine/core';
import { useNavigate, useParams } from '@tanstack/react-router';
import React from 'react';

import { useMarkets } from '@/frachter/hooks/use-markets';
import { useOrganizationStore } from '@/stores/organization-store';

export const MarketSelect: React.FC<{ marketId: string; merchantId: string }> = ({ marketId, merchantId }) => {
	const markets = useMarkets();

	const setLastUsedMarket = useOrganizationStore((s) => s.setLastUsedMarket);

	const marketValues = Object.values(markets).map((market) => {
		return {
			label: `${market.connection.market.displayName} (${market.connection.displayName})`,
			value: `${market.connection.marketId}:${market.connection.merchantId}`,
		};
	});

	const params = useParams({ strict: false });

	const navigate = useNavigate();

	function changeMarket(market?: string | null) {
		if (!market || !params.slug) return;

		setLastUsedMarket({ marketId: market.split(':')[0], merchantId: market.split(':')[1] });

		void navigate({
			params: { marketId: market.split(':')[0], merchantId: market.split(':')[1], slug: params.slug },
			to: '/org/$slug/quickship/$marketId/$merchantId',
		});
	}

	return (
		<Select
			checkIconPosition="right"
			comboboxProps={{ transitionProps: { duration: 200, transition: 'pop' } }}
			data={marketValues}
			defaultValue={`${marketId}:${merchantId}`}
			onChange={(value) => changeMarket(value)}
			radius="md"
			size="md"
			style={{ marginLeft: -16 }}
			styles={{
				input: {
					background: 'transparent',
					border: 'none',
					fontWeight: 600,
					minWidth: 0,
					width: 'auto',
				},
			}}
		/>
	);
};
