import { Badge, Group, Stack, Text } from '@mantine/core';
import { createColumnHelper } from '@tanstack/table-core';
import React from 'react';

import type { IRowType } from './types';

import { formatDateParts } from '@/utils/date';

import { ActionColumn } from './columns/ActionColumn';

const columnHelper = createColumnHelper<IRowType>();

const DEFAULT_TEXT_SIZE = 'sm';

const MIN_COLUMN_WIDTH = 80;

export const useCreateColumns = () => {
	const [columns] = React.useState(() => {
		return [
			columnHelper.accessor('count', {
				cell: (props) => {
					return (
						<Text fw={600} size={DEFAULT_TEXT_SIZE}>
							{props.getValue()}
						</Text>
					);
				},
				header: () => (
					<Text fw={600} size={DEFAULT_TEXT_SIZE}>
						Anzahl
					</Text>
				),
				meta: {
					justify: 'end',
				},
				size: MIN_COLUMN_WIDTH,
			}),

			columnHelper.accessor('minCreatedAt', {
				cell: (props) => {
					const value = props.getValue();

					const { datetime, weekday } = value === null ? { datetime: '-', weekday: '-' } : formatDateParts(value, 'de');

					return (
						<Stack gap={2}>
							<Text size={DEFAULT_TEXT_SIZE}>{datetime}</Text>
							<Text c="dimmed" size={DEFAULT_TEXT_SIZE}>
								{weekday}
							</Text>
						</Stack>
					);
				},
				header: () => (
					<Text fw={600} size={DEFAULT_TEXT_SIZE}>
						Älteste Bestellung
					</Text>
				),
				size: 260,
			}),

			columnHelper.accessor('id', {
				cell: (props) => {
					const skus = props.getValue().split(',');

					// turn skus into an object with a count
					const skusWithCount = skus.reduce(
						(acc, sku) => {
							acc[sku] = (acc[sku] || 0) + 1;
							return acc;
						},
						{} as Record<string, number>,
					);

					console.log(skusWithCount);

					return (
						<Group gap="xs">
							{Object.entries(skusWithCount).map(([sku, count]) => {
								if (count === 1) {
									return (
										<Badge key={sku} radius="xs">
											{sku}
										</Badge>
									);
								}

								return (
									<Group key={sku} gap="xs">
										<Text fw={600} size="sm">
											{count} x{' '}
										</Text>
										<Badge radius="xs">{sku}</Badge>
									</Group>
								);
							})}
						</Group>
					);
				},
				header: () => (
					<Text fw={600} size={DEFAULT_TEXT_SIZE}>
						SKUs
					</Text>
				),
			}),

			columnHelper.display({
				cell: (props) => <ActionColumn {...props} />,
				header: () => (
					<Text fw={600} size={DEFAULT_TEXT_SIZE}>
						Aktionen
					</Text>
				),
				id: '__edit__',
				meta: {
					justify: 'end',
				},
				size: 160,
			}),
		];
	});

	return columns;
};
