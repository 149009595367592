import { makeWatcher } from '@packages/lib/effect';
import { Effect, SubscriptionRef } from 'effect';

import { waitForPropertyOnTarget } from '../../lib/utils';
import { OrganizationContextService } from '../../organization-services/OrganizationContextService';
import { getAuthToken } from '../../programs/get-auth-token';

import { IUserSession } from './types';

/* import's the window's global Clerk type */
import '@clerk/clerk-js/headless';

import { updateSession } from './update-session-program';

let counter = 0;

export class AuthService extends Effect.Service<AuthService>()('AuthService', {
	scoped: Effect.gen(function* () {
		counter++;

		yield* Effect.logDebug('AuthService: START', { instances: counter });

		const clerk = yield* Effect.promise(() => waitForPropertyOnTarget('Clerk', window));

		if (!clerk?.loaded) {
			yield* Effect.promise(async () => clerk?.load());
		}

		const $session = yield* makeWatcher<IUserSession | null>(null);

		yield* updateSession($session.ref);

		const getIdForSlugOrId = (orgIdOrSlug: string | null) =>
			SubscriptionRef.get($session.ref).pipe(
				Effect.andThen((session) => session?.organizationsList.find((o) => o.slug === orgIdOrSlug)?.id ?? orgIdOrSlug),
			);

		yield* Effect.logDebug('AuthService: READY');

		yield* Effect.addFinalizer((_exit) =>
			Effect.gen(function* () {
				yield* Effect.logDebug('AuthService: CLOSING');
				yield* Effect.logDebug('AuthService: CLOSED');
			}),
		);

		return {
			$session,
			activateOrganization: (orgIdOrSlug: string | null) =>
				Effect.gen(function* () {
					yield* Effect.logDebug('AuthService: activateOrganization', orgIdOrSlug);
					yield* Effect.promise(async () => {
						const p = Promise.withResolvers<void>();

						clerk?.client?.clearCache();

						await clerk?.setActive({
							beforeEmit: (_session) => {
								p.resolve();
							},
							organization: orgIdOrSlug,
						});

						// HINT: we must call touch()! otherwise, when we have breakpoints, the session will not have updated
						// no idea what this actually does, but it seems to work.
						// if we don't call this, the session will remain stale when we debug with breakpoints (and sometimes even after hibernation)
						await clerk?.session?.touch();

						// console.log('log token after');
						// await logToken(clerk?.session);
						return p.promise;
					});

					yield* updateSession($session.ref);

					yield* Effect.logDebug('AuthService: activateOrganization completed');
				}),

			getBackendToken: Effect.gen(function* () {
				if (!clerk || !clerk.session) {
					yield* Effect.dieMessage('Clerk not loaded or no active session');
					return null;
				}

				const { organizationId: organizationId } = yield* OrganizationContextService;

				return yield* getAuthToken('backend', { organizationId });
			}),
			getIdForSlugOrId,
			updateSession,
		};
	}),
}) {}
