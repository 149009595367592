import { FetchError as ElectricFetchError } from '@electric-sql/client';
import { getMonthStringForDate } from '@packages/lib/utils/date';
import { Effect } from 'effect';

import type { IAcquireShapeStreamOptions } from './types';

import { DatabaseError } from '../errors';

import { clearTableStatement } from './sql';

export function isShapeOutdatedError(error: unknown) {
	return (
		error instanceof ElectricFetchError &&
		error.status === 400 &&
		error.json &&
		'message' in error.json &&
		typeof error.json.message === 'string' &&
		error.json.message.includes('omit the shape handle')
	);
}

export const getYearMonthPreviousAndCurrent = Effect.sync(() => {
	const now = new Date();
	const previousYearMonth = getMonthStringForDate(now, { shiftDays: 1, shiftMonths: -1 });
	const currentYearMonth = getMonthStringForDate(now, { shiftDays: 1 });

	// @ts-expect-error debug
	if (window.partitionKeys) {
		// @ts-expect-error debug
		return window.partitionKeys as [string, string];
	}

	return [previousYearMonth, currentYearMonth];
});

export const deleteAllRows = (options: Pick<IAcquireShapeStreamOptions, 'pg' | 'table'>) =>
	Effect.tryPromise({
		catch: (error) => {
			console.error('clearTable error', error);
			return new DatabaseError({ cause: error, message: `Could not clear table ${options.table}` });
		},
		// try: () => options.pg.exec(/* sql */ `TRUNCATE TABLE ${options.table} RESTART IDENTITY CASCADE`),
		// HINT: do not use `TRUNCATE` as it will break live queries when the parition keys change on initial load
		try: () => options.pg.exec(clearTableStatement(options.table)),
	});
