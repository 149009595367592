import { Effect } from 'effect';
export class RequestConfigService extends Effect.Service()('RequestConfigService', {
    sync: () => {
        return {
            fetch: globalThis.fetch,
            getHeaders: () => Promise.resolve({}),
            proxy: null,
        };
    },
}) {
}
/**
 * Helper to update the request config
 * if being used, it also requires that the RequestConfigService is provided
 * @param config
 * @returns
 */
export const updateRequestConfig = (config) => Effect.updateService(RequestConfigService, (service) => ({ ...service, ...config }));
export const updateRequestConfig2 = (config) => Effect.updateService(RequestConfigService, (service) => ({ ...service, ...config }));
