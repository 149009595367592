import { ModalProps } from '@mantine/core';
// even though we don't need RT here, we get a tsc error that a reference cannot be named without this

import { DhlPostParcelGermanyShippingV2Modal } from './DhlPostParcelGermanyShippingV2Modal';
import { TestModal } from './TestModal';

export const modals = {
	demonstration: TestModal,
	DhlPostParcelGermanyShippingV2Modal,
} as const;

// ff ff fdfs  sds

/** ----------------------------------------------------------------------------------------------
 * prepare type-safe access to modals via mantine's `openContextModal` and `MODALS` constant
 * _______________________________________________________________________________________________ */

type Modals = typeof modals;
type ModalKeys = keyof Modals;

export const MODALS = Object.keys(modals).reduce(
	(acc, key) => {
		return { ...acc, [key]: key };
	},
	{} as {
		[K in ModalKeys]: K;
	},
);

declare module '@mantine/modals' {
	export interface MantineModalsOverride {
		modals: Modals;
	}
}

type DefaultModalProps = Pick<ModalProps, 'transitionProps' | 'centered'>;

export const modalProps = {
	default: {
		centered: true,
		transitionProps: { duration: 300, transition: 'slide-up' },
	} satisfies DefaultModalProps,
};
