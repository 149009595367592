import { Box } from '@mantine/core';

import { AppPageControls, AppPageLayout } from '@/layouts/app-page';

import TableExample from './page-products';
// import OriginalExample from './page-products/example';

export function Page() {
	console.log('RENDER ROUTE');
	return (
		<AppPageLayout maxWidth="100%" title="Produkte" variant="apps">
			<AppPageControls>
				{/* <Tooltip label="Neue Bestellung anlegen">
					<Button leftSection={<IconPlus size={18} stroke={2} />} size="xs" variant="outline">
						Bestellung
					</Button>
				</Tooltip>
				<Divider orientation="vertical" /> */}
			</AppPageControls>

			<Box
				style={{
					display: 'grid',
					gap: '1rem',
					gridTemplateColumns: '1fr',
					height: '100%',
					position: 'relative',
					width: '100%',
				}}
			>
				<Box style={{ display: 'grid', gap: '1rem', inset: 0, position: 'absolute' }}>
					{/* <Paper p="md" radius="md" shadow="xs" style={{}}> */}
					<TableExample />
					{/* </Paper> */}
				</Box>
			</Box>
		</AppPageLayout>
	);
}
