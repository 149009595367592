import { Box, Stack, Text } from '@mantine/core';
import { IconRobot as IconEmpty } from '@tabler/icons-react';

export const EmptyList: React.FC<{ message: string; className?: string }> = ({ className, message }) => {
	return (
		<Box className={className} style={{ display: 'grid', inset: 0, placeContent: 'center', position: 'absolute' }}>
			<Stack align="center" c="color-mix(in srgb, var(--mantine-color-text) 20%, var(--mantine-color-body))" p="xl">
				<IconEmpty size={60} stroke={1.5} />
				<Text c="dimmed" size="sm">
					{message}
				</Text>
			</Stack>
		</Box>
	);
};
