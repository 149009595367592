import { Box, Button, Divider, Group, Loader, Modal, Paper, Skeleton, Stack, Text, Title } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconBrandApple, IconBrandWindows, IconPlus, IconPrinter } from '@tabler/icons-react';
import React from 'react';

import { DialogActions } from '@/components/Dialog/Dialog';
import { SetupCloudprintDialog } from '@/components/dialogs/setup-cloudprint-dialog';
import { MonoButton } from '@/components/ui/MonoButton';
import { constants } from '@/data/constants';
import { CloudprintByClient, useCloudprint } from '@/frachter/hooks/use-cloudprint';
import { useMutationPrint } from '@/hooks/use-mutation-print';
import { SelectedCloudprintClientDevice } from '@/type-defs/common';

import { ValidateRef } from '../../~setup/~index';

/**
 * upload here: https://dash.cloudflare.com/817f0f02a185b4610bb72ad8927ea199/r2/eu/buckets/frachter
 */
export default function SettingsCloudprintPage({
	successActions,
	validateRef,
}: {
	successActions?: DialogActions;
	validateRef?: ValidateRef;
}) {
	const [isOpen, { toggle }] = useDisclosure(false);

	const cloudprint = useCloudprint();
	const numClients = cloudprint.byClient.length;
	const numPrinters = cloudprint.list.length;

	const handleDisconnect = React.useCallback(
		(clientId: string) => {
			cloudprint.disconnect(clientId).catch((err) => console.error(err));
		},
		[cloudprint],
	);

	const validate = React.useCallback(() => {
		if (numPrinters > 0) {
			return Promise.resolve(true);
		} else {
			return Promise.resolve(false);
		}
	}, [numPrinters]);

	React.useImperativeHandle(validateRef, () => validate);

	const mergedSuccessActions: DialogActions = React.useMemo(() => {
		return {
			label: successActions?.label ?? 'Schließen',
			onClick: () => {
				toggle();
				successActions?.onClick();
			},
		};
	}, [successActions, toggle]);

	return (
		<>
			<Stack gap="lg" py="md">
				<Group justify="space-between">
					<Title order={3}>CloudPrint</Title>
				</Group>
				<Text size="md">
					Um unkompliziert, schnell und von überall drucken zu können, lade Dir unseren{' '}
					<strong>Cloudprint Client</strong> herunter. Es gibt ihn für Windows und MacOS. Falls Deine Drucker an einem
					anderen Computer angeschlossen sind, wie z. B. an einem Drucker-Server, installiere den Cloudprint Client auf
					diesem Gerät.
				</Text>
				<Text size="md">
					<strong>Hinweis:</strong> Falls Du CloudPrint bereits installiert hast oder einem bereits installierten Client
					eine weitere Organisation hinzufügen möchtest, überspringe die Installation und verbinde Dich direkt.
				</Text>
				<Box
					mt="lg"
					style={{
						display: 'grid',
						gap: `var(--mantine-spacing-xl)`,
						gridTemplateColumns: `repeat(auto-fit, minmax(250px, 1fr))`,
					}}
				>
					<MonoButton
						component={'a'}
						href={'https://storage.frachter.app/cloudprint/latest/CloudPrint_x64-setup.exe'}
						leftSection={<IconBrandWindows size={20} />}
						size="sm"
						target="_blank"
						variant="outline"
					>
						CloudPrint für Windows
					</MonoButton>
					<MonoButton
						component={'a'}
						href={'https://storage.frachter.app/cloudprint/latest/CloudPrint_universal.dmg'}
						leftSection={<IconBrandApple size={20} />}
						size="sm"
						target="_blank"
						variant="outline"
					>
						CloudPrint für MacOS
					</MonoButton>
				</Box>
				<Divider my="md" />
				<Group justify="space-between">
					<Title order={5}>Verbundene Geräte</Title>

					<Button
						leftSection={<IconPlus size={20} />}
						onClick={toggle}
						size="xs"
						style={{ visibility: numClients > 0 ? 'visible' : 'hidden' }}
						variant="subtle"
					>
						Neuen CloudPrint Client verbinden
					</Button>
				</Group>
				<Stack key="stack" gap="md">
					{cloudprint.byClient.map((client, index) => {
						return (
							<Box key={index}>
								<CloudprintClientItem
									key={client.clientId}
									client={client}
									onDisconnect={(clientId) => void handleDisconnect(clientId)}
								/>
							</Box>
						);
					})}

					{numClients === 0 && (
						<Stack align="center" gap="xs">
							<Text c="dimmed" size="sm" ta="center">
								Es sind aktuell keine Geräte verbunden. Installiere den CloudPrint Client auf einem Computer, und
								verbinde diesen im Anschluss.
							</Text>
							<Button leftSection={<IconPlus size={20} />} onClick={toggle} size="sm" variant="subtle">
								Neuen CloudPrint Client verbinden
							</Button>
						</Stack>
					)}
				</Stack>
			</Stack>
			<Modal
				centered
				onClose={toggle}
				opened={isOpen}
				radius="lg"
				size="xl"
				title="Verbindung einrichten"
				transitionProps={{ transition: 'slide-up' }}
			>
				<Box p="md">
					<SetupCloudprintDialog noPaper successActions={mergedSuccessActions} />
				</Box>
			</Modal>
		</>
	);
}

const TestPrinterButton: React.FC<{ clientDevice: SelectedCloudprintClientDevice; isAvailable?: boolean }> = ({
	clientDevice,
	isAvailable,
}) => {
	const { buttonProps } = useMutationPrint(
		{
			eid: `test-${clientDevice.name}-${crypto.randomUUID()}`,
			id: crypto.randomUUID(),
			url: constants.SAMPLE_PDF_URL,
		},
		clientDevice,
	);

	return (
		<Button {...buttonProps} disabled={!isAvailable} size="xs" variant="subtle">
			Drucker testen
		</Button>
	);
};

const CloudprintClientItem: React.FC<{ client: CloudprintByClient; onDisconnect: (clientId: string) => void }> = ({
	client,
	onDisconnect,
}) => {
	const numPrinters = client.printers.length;

	return (
		<Paper p="md" shadow="xs">
			<Stack key={client.clientId}>
				<Group justify="space-between">
					<Stack gap={0}>
						{client.isOnline ? <Title order={6}>{client.hostname}</Title> : <Skeleton height={16} mb={5} width={200} />}

						<Text c="dimmed" ff="monospace" size="sm">
							ID: {client.clientId}
						</Text>
					</Stack>

					<Group>
						<>
							<Text size="sm">{client.printers.length > 0 && `${client.printers.length} Drucker`}</Text>
							<Button
								color="danger"
								loading={Boolean(client.isDisconnecting)}
								onClick={() => onDisconnect(client.clientId)}
								size="xs"
								variant="light"
							>
								{!client.isDisconnecting ? 'Verbindung trennen' : 'Wird getrennt...'}
							</Button>
						</>
					</Group>
				</Group>
				{!client.isOnline ? (
					<Group justify="space-between">
						<Text c="orange" size="sm" ta="center">
							Cloudprint Client ist offline
						</Text>
						<Loader color="orange" size="xs" type="bars" />
					</Group>
				) : (
					<Stack>
						{numPrinters === 0 ? (
							<Box bg="color-mix(in srgb, var(--mantine-color-text) 3%, transparent)" p="xs">
								<Text size="sm" ta="center">
									Keine Drucker freigegeben
								</Text>
							</Box>
						) : (
							client.printers.map((p) => {
								return (
									<Group
										key={p.name}
										justify="space-between"
										pl="sm"
										px="4"
										py="2"
										style={{
											backgroundColor: 'color-mix(in srgb, var(--mantine-color-text) 3%, transparent)',
											// border: `1px solid color-mix(in srgb, var(--mantine-color-text) 8%, transparent)`,
											borderRadius: 4,
										}}
									>
										<Group gap="xs">
											<IconPrinter size={16} />
											<Text size="sm">{p.displayName}</Text>
										</Group>
										<TestPrinterButton clientDevice={p} isAvailable />
									</Group>
								);
							})
						)}
					</Stack>
				)}
			</Stack>
		</Paper>
	);
};
