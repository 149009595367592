import { Space, type Lock as AblyLock } from '@ably/spaces';
import { makeWatcher } from '@packages/lib/effect';
import { Chunk, Deferred, Effect, Stream, SubscriptionRef, SynchronizedRef } from 'effect';

import { LockRaceConditionError, LockUnavailableError } from '@/frachter/effect/lib/errors';
import { ILock, ILockReleasing, ILockRequested, ILockRequestOptions, ILockStore } from '@/frachter/types/locks';

import { RealtimeClientService } from './RealtimeClientService';
import { ablyLockToFrachterLock } from './utils';

export function makeRealtimeLockApi(space: Space) {
	return Effect.gen(function* () {
		yield* Effect.logDebug('makeRealtimeLockApi: START');

		const realtimeClient = yield* RealtimeClientService;

		/**
		 * This holds the state of our locks and will be forwarded to be used
		 * with an externalStore and the `useSyncExternalStore` hook.
		 *
		 * It contains locks that are
		 * - acquired
		 * - pending
		 * - releasing
		 */
		const $locks = yield* makeWatcher<ILockStore>({ all: {}, others: {}, self: {} });

		const pendingLocksSyncRef = yield* SynchronizedRef.make<
			Record<
				string,
				{
					lock: ILockRequested | ILockReleasing;
					deferred: Deferred.Deferred<ILock, LockUnavailableError | LockRaceConditionError>;
				}
			>
		>({});

		// HINT: our deferred model might not be a good idea... (when we restore connections)
		const updateLocksTask = (lock: AblyLock) =>
			Effect.gen(function* () {
				yield* Effect.logDebug('makeRealtimeLockApi: handleLockUpdateTask', { lock });

				const clientId = realtimeClient.client.auth.clientId;
				const connectionId = realtimeClient.client.connection.id;

				const { all: previousLocks } = yield* $locks.ref;

				/**
				 * if it's a lock that relates to the current client connection
				 *
				 * if the status is `locked`, we acquired it
				 * if the status is 'unlocked' and we have a reason, we failed to acquire it
				 * if the status is `unlocked` and we have no reason, we released it
				 *
				 * cases:
				 * - the event relates to a lock that we either want to acquire or release => means we can resolve the deferred
				 * - the event relates to a lock that we own but we do not have any pending locks => means we have lost the lock (e.g. due to a connection drop and someone else acquired it)
				 * - otherwise, it's just an update from another client that we can simply take from `getAll`
				 */

				/** ==============================================================================================
				 *
				 * handle side effects for the current client
				 *
				 * _______________________________________________________________________________________________ */

				// HINT: warning, we might have situations where we DO NOT have a pending lock (e.g. straigt after a browser refresh)

				if (lock.member.clientId === clientId && lock.member.connectionId === connectionId) {
					const pendingLock = (yield* pendingLocksSyncRef)[lock.id];

					/** ----------------------------------------------------------------------------------------------
					 * the event relates to a lock where we currently have a deferred for => planned resolution
					 * _______________________________________________________________________________________________ */

					// it must match our clientId and connectionId
					if (lock.status === 'locked' || (lock.status === 'unlocked' && !lock.reason)) {
						// we have acquired or released it successfully
						if (pendingLock) {
							yield* Deferred.succeed(pendingLock.deferred, ablyLockToFrachterLock(lock));
						}
					} else if (lock.status === 'unlocked' && lock.reason) {
						if (lock.reason?.code === 101004) {
							/* fail the deferred with a LockRaceConditionError and remove the lock from the record */
							yield* Deferred.fail(pendingLock.deferred, new LockRaceConditionError({ lockId: lock.id }));
						} else if (lock.reason?.code === 101003) {
							// console.log('lock is taken by someone else', lock.member.clientId, lock.member.connectionId);
							/* fail the deferred with a LockUnavailableError because someone else holds it*/
							yield* Deferred.fail(
								pendingLock.deferred,
								new LockUnavailableError({
									lockId: lock.id,
									owner: { clientId: lock.member.clientId, connectionId: lock.member.connectionId },
								}),
							);
						} else {
							yield* Effect.logWarning('TODO: unhandled lock reason', lock);
						}
					} else {
						yield* Effect.logWarning('TODO: We have an unexpected lock event', lock);
					}

					yield* SynchronizedRef.update(pendingLocksSyncRef, (prev) => {
						delete prev[lock.id];
						return prev;
					});
				} else {
					// check if we currently have a lock, but now someone else has control over it, which
					// means we LOST it and need to call the onLockLost handler

					const previousLock = previousLocks[lock.id];

					if (
						previousLock &&
						previousLock.status === 'acquired' &&
						previousLock.details.lockedBy.clientId === clientId &&
						previousLock.details.lockedBy.connectionId === connectionId
					) {
						// we lost the lock
						// yield* Effect.promise(() => options.onLockLost(new LockUnavailableError({ lockId: lock.id, owner: previousLock.details.lockedBy })));
					}
				}

				const self = Effect.promise(() =>
					space.locks
						.getSelf()
						.catch(() => [] as AblyLock[])
						.then((locks) => locks.map(ablyLockToFrachterLock)),
				);

				const others = Effect.promise(() =>
					space.locks
						.getOthers()
						.catch(() => [] as AblyLock[])
						.then((locks) => locks.map(ablyLockToFrachterLock)),
				);

				const all = Effect.promise(() =>
					space.locks
						.getAll()
						.catch(() => [] as AblyLock[])
						.then((locks) => locks.map(ablyLockToFrachterLock)),
				);

				const result = yield* Effect.all({ all, others, self } as const, { concurrency: 'unbounded' });

				const pendingLocksObject = Object.entries(yield* pendingLocksSyncRef).map(([, { lock }]) => lock);

				yield* SubscriptionRef.set($locks.ref, {
					all: Object.fromEntries([...pendingLocksObject, ...result.all].map((lock) => [lock.id, lock])),
					others: Object.fromEntries(result.others.map((lock) => [lock.id, lock])),
					self: Object.fromEntries([...pendingLocksObject, ...result.all].map((lock) => [lock.id, lock])),
				});

				yield* Effect.logDebug('makeRealtimeLockApi: handleLockUpdateTask completed');
			});

		// this is a little

		yield* Stream.async<AblyLock>((emit) => {
			space.locks.subscribe((update) => {
				queueMicrotask(() => void emit(Effect.succeed(Chunk.of(update))));
			});
		}).pipe(
			Stream.runForEach((v) => updateLocksTask(v)),
			Effect.interruptible,
			Effect.forkScoped,
		);

		yield* Effect.addFinalizer((exit) =>
			Effect.gen(function* () {
				yield* Effect.logDebug('makeRealtimeLockApi: CLOSING');
				// HINT: do we really need both?
				space.locks.unsubscribe();
				space.locks.off();
				yield* Effect.logDebug('makeRealtimeLockApi: CLOSED');
			}),
		);

		yield* Effect.logDebug('makeRealtimeLockApi: READY');

		return {
			$locks,
			/**
			 * requestLock should always return a deferred that will be turned into a promise.
			 * The react hook that will call it, can
			 * - await the deferred to get the lock (or receive an error)
			 * - use the store to get the current status of a lock (and ensure we are not calling the requestLock more than once!)
			 * - BUT: how do we handle unmounts? (we should release a lock only after several ms have passed to avoid React.strict mode issues)
			 * - AND: how to we handle multiple `lockLost` handlers? (and their removal)?
			 * => hooks should take care of unsubscribing from lockLost AND when we call them, we should remove them as we don't want to call them multiple times!
			 *
			 *
			 * HINT: ignore lockLost for now!
			 *
			 * Implementation
			 * 1. take what we have from our draft implementation
			 * 2. ensure it works with effect
			 * 3. test locks with a hook
			 * 4. add lockLost handling (we need to run checks during updates and see if an own lock is in `releasing` before we remove it from `own`, if NOT, we've lost it)
			 */
			requestLock: (id: string, options?: ILockRequestOptions) =>
				Effect.gen(function* () {
					/** ----------------------------------------------------------------------------------------------
					 * first check if
					 * - we have a pending lock and yield the deferred
					 * - otherwise, if the lock is already in our $locks either yield a LockUnavailableError or the lock itself
					 * _______________________________________________________________________________________________ */

					const pendingLocks = yield* SynchronizedRef.get(pendingLocksSyncRef);

					yield* Effect.logDebug('makeRealtimeLockApi: requestLock: pendingLocks', pendingLocks);

					if (pendingLocks[id]) {
						yield* Effect.logDebug('makeRealtimeLockApi: requestLock: pending lock found', pendingLocks[id]);
						// return yield* Deferred.await(pendingLocks.get(id));
						return yield* Deferred.await(pendingLocks[id].deferred);
					}

					const locks = yield* $locks.ref;

					if (locks.others[id]) {
						yield* Effect.logDebug('makeRealtimeLockApi: requestLock: lock is owned by someone else', locks.others[id]);
						return yield* new LockUnavailableError({ lockId: id, owner: locks.others[id].details.lockedBy });
					} else if (locks.self[id]) {
						if (locks.self[id].status === 'acquired') {
							yield* Effect.logDebug(
								'makeRealtimeLockApi: requestLock: lock is owned by us and we have it',
								locks.self[id],
							);
							return locks.self[id];
						} else {
							return yield* Effect.dieMessage(
								`Lock ${id} is in status ${locks.self[id].status} but we expected it to be acquired! This means there is no deferred to await.`,
							);
						}
					}

					yield* Effect.logDebug('makeRealtimeLockApi: requestLock: creating new deferred');
					const deferred = yield* Deferred.make<ILock, LockUnavailableError | LockRaceConditionError>();

					yield* Effect.logDebug('makeRealtimeLockApi: requestLock: updating pendingLocksSyncRef', {
						id,
					});

					yield* SynchronizedRef.update(pendingLocksSyncRef, (prev) => {
						prev[id] = {
							deferred,
							lock: { createdAt: Date.now(), id, metadata: options?.metadata, status: 'requested' },
						};
						return prev;
					});

					yield* Effect.promise(() => space.locks.acquire(id, { attributes: options?.metadata ?? {} }));

					return yield* Deferred.await(deferred);
				}),
			unlock: (id: string) =>
				Effect.gen(function* () {
					const pendingLocks = yield* SynchronizedRef.get(pendingLocksSyncRef);

					const pendingLock = pendingLocks[id];

					if (pendingLock && pendingLock.lock.status === 'requested') {
						yield* Deferred.await(pendingLock.deferred);
					}

					yield* Effect.promise(() => space.locks.release(id));

					return true as const;
				}),
		};
	});
}
