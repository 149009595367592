'use client';

import { ActionIcon, Box, Group, Paper, Tabs, Text, Title } from '@mantine/core';
import { useResizeObserver } from '@mantine/hooks';
import { IconChevronRight, IconAdjustmentsAlt as IconFilter, IconPrinter } from '@tabler/icons-react';
import { useWindowSize } from '@uidotdev/usehooks';
import { AnimatePresence, motion } from 'framer-motion';
import React, { PropsWithChildren, useMemo } from 'react';

import { useCompletedPrintJobItems, usePendingPrintJobItems } from '@/hooks/use-query-print-queue-items';
import { useOrganizationStore } from '@/stores/organization-store';

import { useCloudprintQueuePanel } from '../../hooks/use-cloudprint-queue-panel';
import { EmptyList } from '../ui/EmptyList';

import { CloudprintQueueFilter } from './CloudprintQueueFilter';
import { useCloudprintQueueFormContext } from './CloudprintQueueFormContext';
import { CloudprintQueueItem, CloudprintQueueItemData, CloudprintQueueItemProps } from './CloudprintQueueItem';
import { CloudprintQueueList } from './CloudprintQueueList';
import s from './CloudprintQueuePanel.module.scss';
import { CloudprintQueuePreview } from './CloudprintQueuePreview';

interface CloudprintQueuePanelProps {}

export const CloudprintQueuePanel: React.FC<CloudprintQueuePanelProps> = () => {
	const panel = useCloudprintQueuePanel();

	const [rootRef, rootRect] = useResizeObserver<HTMLDivElement>();

	const windowSize = useWindowSize();

	const availableDimensions = useMemo(() => {
		const bounding = rootRef.current?.getBoundingClientRect();

		if (!bounding) {
			return { availableHeight: 0, availableWidth: 0 };
		}

		const offsetRight = (windowSize.width ?? 0) - bounding.right;

		const availableWidth = (windowSize.width ?? 0) - offsetRight - rootRect?.width;
		const availableHeight = bounding?.bottom - bounding?.top;

		return { availableHeight, availableWidth };
	}, [rootRect, rootRef, windowSize]);

	return (
		<AnimatePresence>
			{panel.isVisible && (
				<Box
					key="cloudprint-queue"
					ref={rootRef}
					animate={{ opacity: 1, transition: { ease: 'circInOut' }, x: 0 }}
					className={s.root}
					component={motion.div}
					exit={{ opacity: 0, transition: { ease: 'circInOut' }, x: '150%' }}
					initial={{ opacity: 0, x: '150%' }}
				>
					<AnimatePresence>
						{panel.preview.isVisible && (
							<CloudprintQueuePreview
								availableHeight={availableDimensions.availableHeight}
								availableWidth={availableDimensions.availableWidth}
								className={s.preview}
							/>
						)}
					</AnimatePresence>

					<Paper className={s.panel} shadow="md">
						<Group justify="space-between" p="md">
							<Title order={6} tt="uppercase">
								<Group gap="xs">
									<IconPrinter size={16} />
									Druckaufträge
								</Group>
							</Title>
							<Group>
								<ActionIcon
									variant="transparent"
									onClick={() => {
										panel.hide();
										panel.preview.hide();
									}}
								>
									<IconChevronRight size={22} />
								</ActionIcon>
							</Group>
						</Group>

						<CloudprintQueueFilter />

						<Tabs
							defaultValue="completed"
							keepMounted={false}
							styles={{
								panel: { height: '100%', position: 'relative', width: '100%' },
								root: { display: 'grid', gridTemplateRows: 'auto 1fr' },
								tab: { paddingBlock: 6, paddingInline: 8 },
							}}
						>
							<Tabs.List grow>
								<Tabs.Tab value="pending">
									<PendingItemsTabTitle />
								</Tabs.Tab>

								<Tabs.Tab value="completed">
									<CompletedItemsTabTitle />
								</Tabs.Tab>
							</Tabs.List>

							<Tabs.Panel value="completed">
								<TabContent>
									<CompletedItemsList />
								</TabContent>
							</Tabs.Panel>

							<Tabs.Panel value="pending">
								<TabContent>
									<PendingItemsList />
								</TabContent>
							</Tabs.Panel>
						</Tabs>
					</Paper>
				</Box>
			)}
		</AnimatePresence>
	);
};

const TabTitle: React.FC<PropsWithChildren<{ count?: number }>> = ({ children, count = 0 }) => {
	return (
		<Group gap={6}>
			{count > 0 && (
				<Text c="dimmed" component="span" size="sm">
					{count}
				</Text>
			)}
			<Text component="span" fw="600" size="sm">
				{children}
			</Text>
		</Group>
	);
};

const TabContent: React.FC<PropsWithChildren<{}>> = ({ children }) => {
	return <Box p="md">{children}</Box>;
};

const PendingItemsTabTitle = () => {
	return <TabTitle>Warteschlange</TabTitle>;
};

const PendingItemsList = () => {
	const items = usePendingPrintJobItems();

	return <PrintJobItemsList disableReprint items={items} />;
};

const CompletedItemsTabTitle = () => {
	const form = useCloudprintQueueFormContext();

	return (
		<TabTitle>
			<Group>
				Abgeschlossen{' '}
				<Box>
					<ActionIcon
						component="span"
						onClick={() => form.setFieldValue('isFilterActive', !form.values.isFilterActive)}
						variant={form.values.isFilterActive ? 'light' : 'transparent'}
					>
						<IconFilter size={16} />
					</ActionIcon>
				</Box>
			</Group>
		</TabTitle>
	);
};

const CompletedItemsList = () => {
	const form = useCloudprintQueueFormContext();

	const items = useCompletedPrintJobItems(
		!form.values.isFilterActive
			? {}
			: {
					dateRange: {
						from: form.values.from,
						to: form.values.to,
					},
					search: form.values.search,
					status: form.values.statusFilter,
				},
	);

	return <PrintJobItemsList items={items} />;
};

const PrintJobItemsList: React.FC<
	{ items: CloudprintQueueItemData[]; filter?: 'success' | 'error' | 'pending' } & Pick<
		CloudprintQueueItemProps,
		'disableReprint'
	>
> = ({ disableReprint, items }) => {
	const cloudprintSelectedClientDevice = useOrganizationStore((state) => state.cloudprintClientDevice);

	const panel = useCloudprintQueuePanel();

	return items.length === 0 ? (
		<EmptyList message="Keine Druckaufträge gefunden" />
	) : (
		<CloudprintQueueList>
			{(items ?? []).map((data) => {
				const isPreviewOpen = panel.preview.isVisible && panel.preview.item?.id === data.id;
				return (
					<CloudprintQueueItem
						key={data.id}
						data={data}
						disableReprint={Boolean(disableReprint)}
						hasPrinter={!!cloudprintSelectedClientDevice}
						isPreviewOpen={isPreviewOpen}
						onPreviewClick={() => (isPreviewOpen ? panel.preview.hide() : panel.preview.setItem(data))}
					/>
				);
			})}
		</CloudprintQueueList>
	);
};
