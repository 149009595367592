import { useMutation } from '@apollo/client';
import {
	Box,
	Button,
	Checkbox,
	Group,
	HoverCard,
	Modal,
	Paper,
	Space,
	Stack,
	Text,
	TextInput,
	Title,
} from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import * as organizationSchema from '@packages/lib/schema/organization';
import { IconCircleCheckFilled, IconHelpCircleFilled } from '@tabler/icons-react';
import { useNavigate } from '@tanstack/react-router';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'motion/react';
import React, { useEffect, useState } from 'react';
import { match } from 'ts-pattern';
import { z } from 'zod';

import { graphql } from '@/gql/graphql';
import { useFrachterUser } from '@/hooks/use-frachter-user';

import s from './CreateOrganizationForm.module.scss';

const MStack = motion.create(Stack);

const createOrganizationClientSchema = organizationSchema.organizationCreateSchema.extend({
	autoSlug: z.string(),
	isAutoSlug: z.boolean(),
});

type CreateOrganizationClientSchema = z.infer<typeof createOrganizationClientSchema>;

const initialValues: CreateOrganizationClientSchema = {
	autoSlug: '',
	isAutoSlug: true,
	name: '',
	slug: '',
};

const OrganizationCreateMutation = graphql(`
	mutation OrganizationCreateMutation($input: MutationOrganizationCreateInput!) {
		organizationCreate(input: $input) {
			__typename
			... on MutationOrganizationCreateSuccess {
				data {
					__typename
					id
					name
					slug
					orgId
				}
			}
			... on OrganizationCreateError {
				message
				code
			}
		}
	}
`);

/**
 * TODO: 🔴 if the user already has an organization and wants to create a new one, we must first
 * log out of the current organization. Otherwise the Apollo GraphQL client would setup a subscription
 * for the wrong organization.
 */
export const CreateOrganizationForm: React.FC<{ className?: string }> = ({ className }) => {
	const [submittedSlug, setSubmittedSlug] = React.useState<string | null>(null);
	const [organizationId, setOrganizationId] = React.useState<string | null>(null);

	const form = useForm({
		initialValues,
		transformValues: (values) => ({
			autoSlug: values.name
				.toLowerCase()
				.replace(/[[\]()]/g, '')
				.replace(/[^a-zA-Z0-9]/g, '-')
				.replace(/-{2,}/g, '-')
				.replace(/-?(.*)-$/, '$1'),
			isAutoSlug: values.isAutoSlug,
			name: values.name.trim(),
			slug: values.slug.trim(),
		}),
		validate: zodResolver(createOrganizationClientSchema),
		validateInputOnBlur: true,
	});

	const { getValues } = form;
	const [invalidSlugErrorMessage, setInvalidSlugErrorMessage] = useState<React.ReactElement | null>(null);
	const [mutate, mutation] = useMutation(OrganizationCreateMutation);
	const [errorReason, setErrorReason] = useState<'ORG_CREATION_LIMIT_EXCEEDED' | 'API_ERROR' | null>(null);
	const [isErrorModalOpen, errorModal] = useDisclosure(false);

	useEffect(() => {
		if (!mutation.data) return;

		match(mutation.data.organizationCreate)
			.with({ __typename: 'OrganizationCreateError', code: 'SLUG_UNAVAILABLE' }, () => {
				const slug = getValues().isAutoSlug ? getValues().autoSlug : getValues().slug;

				setInvalidSlugErrorMessage(
					<Text c="danger" fz="sm" ta="center">
						Der gewählte Slug <strong>{slug}</strong> ist bereits vergeben.
						<br /> Bitte wähle einen anderen!
					</Text>,
				);
			})
			.with({ __typename: 'OrganizationCreateError', code: 'ORG_CREATION_LIMIT_EXCEEDED' }, () => {
				setErrorReason('ORG_CREATION_LIMIT_EXCEEDED');
				errorModal.open();
			})
			.with({ __typename: 'FetchError' }, () => {
				setErrorReason('API_ERROR');
				errorModal.open();
			})
			.with({ __typename: 'MutationOrganizationCreateSuccess' }, ({ data }) => {
				setSubmittedSlug(data.slug);
				setOrganizationId(data.orgId);
			})

			.exhaustive();
	}, [getValues, mutation.data, errorModal]);

	const handleSubmit = (values: CreateOrganizationClientSchema) => {
		const { autoSlug, isAutoSlug, name, slug } = values;
		const finalSlug = isAutoSlug ? autoSlug : slug;
		void mutate({ variables: { input: { name, slug: finalSlug } } });
	};

	useEffect(() => {
		if (form.values.isAutoSlug && form.values.slug !== form.getTransformedValues().autoSlug) {
			form.setFieldValue('slug', form.getTransformedValues().autoSlug);
		}
	}, [form]);

	const isSuccess = submittedSlug && organizationId;

	return (
		<Paper className={s.paper} radius="md" shadow="sm">
			<Box className={clsx(s.root, className)} component="form" onSubmit={form.onSubmit(handleSubmit)}>
				{/* TODO: use AnimatePresence to switch between form and success overlay */}
				<AnimatePresence mode="wait" presenceAffectsLayout>
					{!isSuccess && (
						<MStack key="form" animate={{ opacity: 1 }} exit={{ opacity: 0 }} initial={{ opacity: 0 }} p="xl">
							<Title order={2}>Organisation erstellen</Title>

							<Text size="md">Wähle einen Namen für Deine Organisation</Text>
							<Text c="dimmed" size="sm">
								Der Name muss mit einem Buchstaben beginnen und darf auch Zahlen, Bindestriche und Unterstriche
								enthalten.
							</Text>
							<Stack>
								<Box mih="60">
									<TextInput {...form.getInputProps('name')} placeholder="Organisationsname" />
								</Box>
								<Group justify="space-between">
									<Group gap="xs">
										<Title order={6}>Slug</Title>
										<HoverCard closeDelay={400} position="right" shadow="md" width={400}>
											<HoverCard.Target>
												<IconHelpCircleFilled size={20} />
											</HoverCard.Target>
											<HoverCard.Dropdown>
												<Stack>
													<Text size="sm">
														Der Slug ist eine kleingeschriebene Variante, oft auch eine Abkürzung, des Namens der
														Organisation. Er wird als Teil der URL verwendet, um der Organisation einen eigenen und
														einzigartigen Namensraum zuzuweisen.
													</Text>
													<Text c="dimmed" size="sm">
														Über den Slug kannst Du z.B. ein Browser-Lesezeichen erstellen, das unter{' '}
														<strong>https://www.frachter.app/org/mein-slug</strong> direkt auf Deine Organisation
														verlinkt.
													</Text>
												</Stack>
											</HoverCard.Dropdown>
										</HoverCard>
									</Group>
									<Checkbox
										{...form.getInputProps('isAutoSlug', { type: 'checkbox' })}
										label="Automatisch generieren"
										labelPosition="left"
									/>
								</Group>
								<TextInput
									disabled={form.values.isAutoSlug}
									{...form.getInputProps('slug')}
									placeholder={'Organisations-Slug'}
									variant="filled"
								/>
								<Text c="dimmed" size="sm">
									Erlaubt sind Kleinbuchstaben, Zahlen und Bindestriche. Letzere nicht am Anfang und nicht am Ende. Sie
									kann später nicht mehr geändert werden.
								</Text>
							</Stack>
							<Button disabled={!form.isValid()} loading={mutation.loading} mt="md" type="submit">
								Organisation erstellen
							</Button>
							{invalidSlugErrorMessage}
						</MStack>
					)}

					{isSuccess && (
						<Box
							key="success"
							animate={{ opacity: 1 }}
							component={motion.div}
							exit={{ opacity: 0 }}
							initial={{ opacity: 0 }}
						>
							<SuccessFormOverlay id={organizationId} slug={submittedSlug} />
						</Box>
					)}
				</AnimatePresence>

				<Modal
					centered
					onClose={() => setErrorReason(null)}
					opened={isErrorModalOpen}
					radius="md"
					size="md"
					title="Hinweis"
				>
					<Stack>
						<Title order={5}>Die Organization konnte nicht erstellt werden</Title>
						<Text>Leider war es nicht möglich die gewünschte Organisation zu erstellen.</Text>
						{errorReason === 'API_ERROR' && (
							<Text>Es ist ein Fehler bei der externen API aufgetreten. Bitte versuche es erneut.</Text>
						)}
						{errorReason === 'ORG_CREATION_LIMIT_EXCEEDED' && (
							<Text>
								Du hast das Limit für die Anzahl an Organisationen erreicht. Bitte wende Dich an unseren Support unter
								support@frachter.app, um Dein Limit zu erhöhen.
							</Text>
						)}
						<Button
							mt="lg"
							onClick={() => {
								mutation.reset();
								errorModal.close();
							}}
						>
							Schließen
						</Button>
					</Stack>
				</Modal>
			</Box>
		</Paper>
	);
};

const SuccessFormOverlay: React.FC<{ id: string; slug: string }> = ({ id, slug }) => {
	const navigate = useNavigate();

	const { switchOrganization } = useFrachterUser();

	const [isPending, startTransition] = React.useTransition();

	const activateOrganization = React.useCallback(() => {
		startTransition(async () => {
			await switchOrganization?.(id);
			await navigate({ params: { slug }, replace: true, search: { step: 1 }, to: '/org/$slug/setup' });
		});
	}, [switchOrganization, id, navigate, slug]);

	return (
		<Stack className={s.successOverlayRoot} p="xl">
			<Stack align="center">
				<IconCircleCheckFilled color="var(--mantine-color-ok-5)" size={48} />
				<Text c="var(--mantine-color-success-5)" fw="bold">
					Organisation erfolgreich erstellt
				</Text>
				<Space />
				<Text px="lg" size="sm" ta="center">
					Du kannst später noch ein Bild für Deine Organisation hochladen und über das Menü oben rechts jederzeit den
					Namen ändern und Mitglieder einladen.
				</Text>
				<Space />
				<Text px="lg" size="sm" ta="center">
					Beginne nun über <strong>Setup starten</strong> die Einrichtung.
				</Text>
			</Stack>

			<Button
				fullWidth
				loading={isPending}
				mt="xl"
				onClick={() => void activateOrganization()}
				type="button"
				variant="light"
			>
				Setup starten
			</Button>
		</Stack>
	);
};
