'use client';

import type { WithLiveblocks } from '@liveblocks/zustand';

import { createClient } from '@liveblocks/client';
import { liveblocks } from '@liveblocks/zustand';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { env } from '@/data/env';

interface ILiveStoreState {
	// Your Zustand state type will be defined here
}

const client = createClient({
	publicApiKey: env.PUBLIC_LIVEBLOCKS_API_KEY,
});

const useLiveStore = create<WithLiveblocks<ILiveStoreState>>()(
	devtools(
		liveblocks(
			(/* set */) => ({
				// Your state and actions will go here
			}),
			{ client },
		),
		{ name: 'live-store', store: 'live-store' },
	),
);

export default useLiveStore;
