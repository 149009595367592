'use client';

import { Box } from '@mantine/core';
import { AnimatePresence } from 'framer-motion';
import { motion } from 'framer-motion';
import React, { useEffect, useId } from 'react';

import { useAppLayoutControls, useAppLayoutSetControls } from '../app/AppLayoutContext';

import s from './AppPageControls.module.scss';

export function AppPageControls({ children }: React.PropsWithChildren) {
	const setControls = useAppLayoutSetControls();
	const key = useId();

	useEffect(() => {
		setControls(
			<Box
				key={key}
				className={s.root}
				component={motion.div}
				initial={{ opacity: 0, y: '-100%' }}
				animate={{
					opacity: 1,
					transition: {
						duration: 0.1,
						ease: 'easeInOut',
					},
					y: 0,
				}}
				exit={{
					opacity: 0,
					transition: {
						duration: 0.1,
						ease: 'easeInOut',
					},
					y: '-100%',
				}}
			>
				{children}
			</Box>,
		);

		return () => {
			setControls(null);
		};
	}, [children, key, setControls]);

	return null;
}

export function AppPageControlsWrapper({ className, style }: { className?: string; style?: React.CSSProperties }) {
	const children = useAppLayoutControls();

	return (
		<Box className={className ?? ''} style={style ?? {}}>
			<AnimatePresence mode="wait">{children}</AnimatePresence>
		</Box>
	);
}
