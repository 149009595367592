import { useMutation, useQuery } from '@apollo/client';
import { Button, Divider, Group, rem, Stack, Text, Title } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { openContextModal } from '@mantine/modals';
import { carrierIds, getApiById } from '@packages/lib/api';
import { DhlParcelDeShippingPostParcelGermanyV2Sdk } from '@packages/lib/api-sdk/dhl_parcel_de_shipping_post_parcel_germany_v2';
import { spec as DhlPostParcelGermanyShippingV2Spec } from '@packages/lib/api/dhl_parcel_de_shipping_post_parcel_germany_v2';
import { spec as GlsShipItSpec } from '@packages/lib/api/gls_shipit';
import React from 'react';
import { match } from 'ts-pattern';

import { CarrierProviderConfig } from '@/data/warehouse';
import {
	ConnectionSetupList,
	ConnectionSetupItemProps,
	ConnectionEstablishedList,
	ConnectionEstablishedListItemProps,
} from '@/features/connections/components';
import * as DhlPostParcelGermanyShippingV2 from '@/features/connections/dhl_parcel_de_shipping_post_parcel_germany_v2';
import { graphql } from '@/gql/graphql';

const CarrierConnectionsListQuery = graphql(
	`
		query CarrierConnectionsListQuery {
			carrierConnections {
				... on CarrierConnectionDhlPostParcelGermanyShippingV2 {
					# TODO: this should be put into a fragment (maybe reuse the fragment from the form and also export an item based on a more generic component that we can reuse for markets AND carriers)
					displayName
					createdAt
					id
					environment

					carrierId

					carrier {
						description
						displayName
						id
						provider
					}

					disabled

					credentials {
						username
						password
					}

					config {
						defaultLabelFormat
						products {
							Europaket {
								billingNumber
								labelFormat
							}
							Paket {
								billingNumber
								labelFormat
								vas
							}
							PaketInternational {
								billingNumber
								labelFormat
							}
							Retoure {
								billingNumber
							}
							Warenpost {
								billingNumber
								labelFormat
							}
							WarenpostInternational {
								billingNumber
								labelFormat
							}
						}
						profile
					}
					...CarrierConnectionDhlPostParcelGermanyShippingV2Fragment
				}
			}
		}
	`,
	[DhlPostParcelGermanyShippingV2.SetupFormFragment],
);

const CarrierConnectionUpdate = graphql(`
	mutation CarrierConnectionUpdate($input: MutationCarrierConnectionUpdateInput!) {
		carrierConnectionUpdate(input: $input) {
			__typename
		}
	}
`);

export default function SettingsCarriersPage() {
	const [, { toggle }] = useDisclosure(false);

	const [mutate] = useMutation(CarrierConnectionUpdate);

	const { data, loading } = useQuery(CarrierConnectionsListQuery);

	const handleSetup: ConnectionSetupItemProps['onClick'] = React.useCallback((id, environment) => {
		match(id).with(DhlParcelDeShippingPostParcelGermanyV2Sdk.spec.api.id, () => {
			openContextModal({
				centered: true,
				closeOnClickOutside: false,
				closeOnEscape: false,
				innerProps: {
					environment: environment ?? null,
					fragment: null,
				},
				modal: 'DhlPostParcelGermanyShippingV2Modal',
				radius: 'md',
				styles: { body: { height: 'clamp(200px, 75vh, 780px)', padding: 0 }, inner: { paddingBlock: rem(16) } },
				withCloseButton: false,
			});
		});
	}, []);

	const handleEdit: NonNullable<ConnectionEstablishedListItemProps['onEdit']> = React.useCallback(
		(id) => {
			const item = data?.carrierConnections.find((v) => v.id === id);

			if (!item) {
				return;
			}

			if (item.carrierId === DhlPostParcelGermanyShippingV2Spec.api.id) {
				openContextModal({
					centered: true,
					closeOnClickOutside: false,
					closeOnEscape: false,
					innerProps: {
						environment: item.environment,
						fragment: item,
					},
					// TODO update this with the unique api Id
					modal: 'DhlPostParcelGermanyShippingV2Modal',
					radius: 'md',
					styles: { body: { height: 'clamp(200px, 75vh, 780px)', padding: 0 }, inner: { paddingBlock: rem(16) } },
					withCloseButton: false,
				});
			}
		},
		[data?.carrierConnections],
	);

	return (
		<>
			<Stack gap="lg" py="md">
				<Group justify="space-between">
					<Title order={3}>Versanddienstleister</Title>
				</Group>
				<Text size="md">
					Hier verwaltest Du Versanddienstleister, die sich mit Frachter verbinden lassen, um darüber Versandlabels zu
					erstellen. Diese werden über Cloudprint gedruckt und können Marktplätzen übermittelt werden, um Bestellungen
					als versendet zu melden.
				</Text>

				<Divider my="md" />

				<Group justify="space-between">
					<Title order={5}>Speditionsvertrag hinzufügen</Title>
					<Button display={'none'} onClick={toggle} size="sm" variant="light">
						Mehr Versandunternehmen entdecken
					</Button>
				</Group>

				<ConnectionSetupList>
					<ConnectionSetupList.Item
						environment="production"
						id={DhlPostParcelGermanyShippingV2Spec.api.id}
						label={`${DhlPostParcelGermanyShippingV2Spec.api.displayName} Geschäftskundenvertrag`}
						logo={<CarrierProviderConfig.dhl1.LogoSquare />}
						onClick={handleSetup}
						subtitle="DHL Post & Paket Deutschland"
					/>

					<ConnectionSetupList.Item
						environment="sandbox"
						id={DhlPostParcelGermanyShippingV2Spec.api.id}
						label={`${DhlPostParcelGermanyShippingV2Spec.api.displayName} Geschäftskundenvertrag`}
						logo={<CarrierProviderConfig.dhl1.LogoSquare />}
						onClick={handleSetup}
						subtitle="DHL Post & Paket Deutschland"
					/>

					{/* <ConnectionSetupList.Item
						disabled
						id={CarrierProviderConfig.DPD.name}
						label={CarrierProviderConfig.DPD.name}
						logo={<CarrierProviderConfig.DPD.LogoSquare />}
						onClick={handleSetup}
					/> */}

					<ConnectionSetupList.Item
						disabled
						id={GlsShipItSpec.api.id}
						label={GlsShipItSpec.api.displayName}
						logo={<CarrierProviderConfig.gls1.LogoSquare />}
						onClick={handleSetup}
					/>
					{/* 
					<ConnectionSetupList.Item
						disabled
						id={CarrierProviderConfig.HERMES.name}
						label={CarrierProviderConfig.HERMES.name}
						logo={<CarrierProviderConfig.HERMES.LogoSquare />}
						onClick={handleSetup}
					/>

					<ConnectionSetupList.Item
						disabled
						id={CarrierProviderConfig.UPS.name}
						label={CarrierProviderConfig.UPS.name}
						logo={<CarrierProviderConfig.UPS.LogoSquare />}
						onClick={handleSetup}
					/> */}
				</ConnectionSetupList>

				<Group justify="space-between" mt="xl">
					<Title order={5}>Verbundene Versanddienstleister</Title>
				</Group>

				<ConnectionEstablishedList loading={loading} subject="carrier">
					{data?.carrierConnections.map((v) => {
						const apiSpec = getApiById(v.carrierId as any);

						if (!apiSpec) {
							throw new Error(`API Spec not found for carrierId: ${v.carrierId}`);
						}

						// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
						if (!carrierIds.includes(apiSpec.id as unknown as any)) {
							throw new Error(`CarrierId not found in carrierIds: ${apiSpec.id}`);
						}

						const id = apiSpec.id as (typeof carrierIds)[number];

						const description: string | undefined = apiSpec.displayName;

						const config = CarrierProviderConfig[id];

						return (
							<ConnectionEstablishedList.Item
								key={v.id}
								createdAt={v.createdAt}
								description={description ?? ''}
								disabled={v.disabled}
								displayName={v.displayName}
								environment={v.environment}
								id={v.id}
								logo={<config.LogoSquare />}
								name={apiSpec.displayName}
								onChangeDisplayName={(displayName, id) => mutate({ variables: { input: { displayName, id } } })}
								onEdit={handleEdit}
							>
								{v.carrierId === DhlPostParcelGermanyShippingV2Spec.api.id && (
									<>
										{v.config?.products.Paket.billingNumber && (
											<ConnectionEstablishedList.Item.Detail
												label="Paket"
												value={
													<>
														VAS: {v.config.products.Paket.vas ? 'aktiv' : 'inaktiv'}
														{' | '}
														{v.config.products.Paket.billingNumber}
													</>
												}
											/>
										)}
										{v.config?.products.Warenpost.billingNumber && (
											<ConnectionEstablishedList.Item.Detail
												label={DhlParcelDeShippingPostParcelGermanyV2Sdk.spec.schema.productEnum.Enum.Warenpost}
												value={v.config.products.Warenpost.billingNumber}
											/>
										)}
										{v.config?.products.Retoure.billingNumber && (
											<ConnectionEstablishedList.Item.Detail
												label={DhlParcelDeShippingPostParcelGermanyV2Sdk.spec.schema.productEnum.Enum.Retoure}
												value={v.config.products.Retoure.billingNumber}
											/>
										)}
										{v.config?.products.Europaket.billingNumber && (
											<ConnectionEstablishedList.Item.Detail
												label={DhlParcelDeShippingPostParcelGermanyV2Sdk.spec.schema.productEnum.Enum.Europaket}
												value={v.config.products.Europaket.billingNumber}
											/>
										)}
										{v.config?.products.PaketInternational.billingNumber && (
											<ConnectionEstablishedList.Item.Detail
												value={v.config.products.PaketInternational.billingNumber}
												label={
													DhlParcelDeShippingPostParcelGermanyV2Sdk.spec.schema.productEnum.Enum.PaketInternational
												}
											/>
										)}
										{v.config?.products.WarenpostInternational.billingNumber && (
											<ConnectionEstablishedList.Item.Detail
												value={v.config.products.WarenpostInternational.billingNumber}
												label={
													DhlParcelDeShippingPostParcelGermanyV2Sdk.spec.schema.productEnum.Enum.WarenpostInternational
												}
											/>
										)}
									</>
								)}
							</ConnectionEstablishedList.Item>
						);
					})}
				</ConnectionEstablishedList>
			</Stack>
		</>
	);
}
