import { Paper, Stack } from '@mantine/core';

import { ProductsTable } from './products-table/ProductsTable';

export const Page = () => {
	return (
		<Stack
			p="xl"
			style={{
				// backgroundColor: 'white',
				height: 1000,
			}}
		>
			<ProductsTable />
		</Stack>
	);
};
