import { useEffect, useRef, useState } from 'react';

export const useHasChildWithSelector = (containerRef: React.RefObject<HTMLDivElement | null>, selector: string) => {
	const [hasSpecificClass, setHasSpecificClass] = useState(false);

	const observerRef = useRef<MutationObserver | null>(null);

	useEffect(() => {
		observerRef.current = new MutationObserver((mutationsList) => {
			for (const mutation of mutationsList) {
				if (mutation.type === 'childList') {
					const elementWithClass = containerRef.current?.querySelector(selector);
					setHasSpecificClass(!!elementWithClass);
					break;
				}
			}
		});

		if (containerRef.current) {
			observerRef.current.observe(containerRef.current, {
				childList: true,
				subtree: true,
			});

			// Initial check
			const elementWithClass = containerRef.current.querySelector(selector);
			setHasSpecificClass(!!elementWithClass);
		}

		return () => {
			observerRef.current?.disconnect();
		};
	}, [containerRef, selector]);

	useEffect(() => {
		if (hasSpecificClass) {
			return () => {
				observerRef.current?.disconnect();
			};
		}
	}, [hasSpecificClass]);

	return hasSpecificClass;
};
