'use no memo';

import type { DisplayColumnDef } from '@tanstack/react-table';

import { Checkbox, type CheckboxProps } from '@mantine/core';
import React from 'react';

import type { IBaseTableData } from './types';

import { useForwardParentClick } from '@/hooks/use-forward-parent-click';

import { COLUMN_ID_SELECT } from './contants';
import { useTableStore } from './store';

const SelectRow: React.FC<{ id: string } & Pick<CheckboxProps, 'onChange'>> = ({ id, ...props }) => {
	const [ref, targetRef] = useForwardParentClick<HTMLInputElement>();
	const enableRowSelection = useTableStore((store) => store.enableRowSelection);

	const isSelected = useTableStore((store) => {
		return !!store.rowSelection[id]; // Ensure boolean value
	});

	return !enableRowSelection ? null : (
		<Checkbox
			ref={targetRef}
			checked={isSelected}
			radius="xl"
			rootRef={ref}
			size="xs"
			style={{ cursor: 'pointer' }}
			styles={{
				input: {
					cursor: 'pointer',
				},
			}}
			{...props}
		/>
	);
};

const SelectAll: React.FC<{ rowCount?: number } & Pick<CheckboxProps, 'onChange'>> = ({ rowCount, ...props }) => {
	const [ref, targetRef] = useForwardParentClick<HTMLInputElement>();
	const enableRowSelection = useTableStore((store) => store.enableRowSelection);
	const rowSelectionStatus = useTableStore((store) => store.rowSelectionStatus);

	return !enableRowSelection ? null : (
		<Checkbox
			ref={targetRef}
			checked={rowSelectionStatus === 'all'}
			indeterminate={rowSelectionStatus === 'some'}
			radius="xl"
			rootRef={ref}
			size="xs"
			style={{ cursor: 'pointer' }}
			styles={{
				input: {
					cursor: 'pointer',
				},
			}}
			{...props}
		/>
	);
};

export const SelectPlaceholder: React.FC<{ className?: string; style?: React.CSSProperties }> = ({
	className,
	style,
}) => {
	return <Checkbox className={className} radius="xl" size="xs" style={{ visibility: 'hidden', ...style }} />;
};

export const useFeatureSelect = () => {
	const [column] = React.useState<DisplayColumnDef<IBaseTableData>>(() => {
		return {
			cell: ({ row }) => {
				return <SelectRow id={row.id} onChange={row.getToggleSelectedHandler()} />;
			},
			footer: () => {
				return <SelectPlaceholder />;
			},
			header: ({ table }) => {
				const rowCount = table.getRowCount();
				return <SelectAll onChange={table.getToggleAllRowsSelectedHandler()} rowCount={rowCount} />;
			},
			id: COLUMN_ID_SELECT,
			meta: {
				align: 'center',
			},
		};
	});

	return column;
};
