import React from 'react';

import { AppPreloaderToggle } from '@/components/AppPreloader';
import { FrachterContextProvider } from '@/context/FrachterContext';
import { FrachterLocksContextProvider } from '@/context/FrachterLocksContext';
import { FrachterService } from '@/frachter/effect/FrachterService';
import { FrachterRuntime } from '@/frachter/effect/lib/runtime';
import { useOrganizationId } from '@/frachter/hooks/use-organization-id';

/**
 * This component ensures that only one tab in the same browser instance (session)
 * can load Frachter and its organization at the same time.
 *
 * This is currently necessary because
 * - when we switch organizations in clerk an active organization item is written to local storage and it seems that after a while
 *   when the session gets refreshed, this will cause other open tabs with different orgs to provide the wrong backend authentication
 *   token. And therefore Ably won't be able to run operations as the capabilities no longer reflect the current organization.
 *
 * As soon as we've stabilized multi-org-sessions per browser, we could get rid of this.
 *
 * Another important point would be to move ably out into a shared worker (per org) so that we save channels and messages
 * in case multiple tabs with the same org are open. (should not be too hard but will cost some time).
 */
export const Frachter: React.FC<React.PropsWithChildren> = ({ children }) => {
	return (
		<FrachterContextProvider
			autoLoadPathPrefix="/app/org"
			frachterService={FrachterRuntime.runPromise(FrachterService)}
		>
			<Loader />
			<FrachterLocksContextProvider>{children}</FrachterLocksContextProvider>
		</FrachterContextProvider>
	);
};

const Loader = () => {
	const pendingOrganizationId = useOrganizationId((s) => s.pending);
	const isLoading = React.useMemo(() => pendingOrganizationId !== undefined, [pendingOrganizationId]);

	return isLoading ? <AppPreloaderToggle /> : null;
};
