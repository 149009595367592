import type { Header } from '@tanstack/react-table';

import { Box } from '@mantine/core';

import type { IBaseTableData } from '../types';

import tableStyles from '../table.module.scss';

export const ResizeHandler: React.FC<{
	header: Header<IBaseTableData, unknown>;
}> = ({ header }) => {
	return (
		<Box
			className={tableStyles.resizer}
			onDoubleClick={() => header.column.resetSize()}
			onMouseDown={header.getResizeHandler()}
			onTouchStart={header.getResizeHandler()}
		/>
	);
};
