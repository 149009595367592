import React from 'react';

import './use-modal-shake.module.scss';

export function useModalShake<T extends HTMLElement>() {
	const ref = React.useRef<T | null>(null);

	const shake = React.useCallback(() => {
		if (ref.current) {
			ref.current.classList.add('modal-shake');
		}

		setTimeout(() => {
			ref.current?.classList.remove('modal-shake');
		}, 500);
	}, []);

	return { ref, shake };
}
