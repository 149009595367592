function blobToURL(blob: Blob): Promise<string | ArrayBuffer | null> {
	return new Promise((resolve) => {
		const reader = new FileReader();
		reader.readAsDataURL(blob);
		reader.onloadend = function () {
			const base64data = reader.result;
			resolve(base64data);
		};
	});
}

export async function loadPdf(url: string) {
	const response = await fetch(url);

	if (response.ok) {
		const blob = await response.blob();
		return await blobToURL(blob);
	} else {
		throw new Error('Failed to load PDF');
	}
}
